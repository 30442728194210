import { Pipe, PipeTransform } from '@angular/core';
import { EmptyStatePresetType, PRESETS } from './type';

@Pipe({
  name: 'presetPrimaryActionText',
})
export class PresetPrimaryActionTextPipe implements PipeTransform {
  transform(value: EmptyStatePresetType) {
    const result = PRESETS[value].primaryActionText;

    return (
      result ?? $localize`:@@bb-empty-state-ui.bb-empty-state__primary-action-text.unknown_error_personal:Try again`
    );
  }
}
