import { Injectable } from '@angular/core';
import menuConfig from '_menu';

export interface MetadataItem {
  component: string;
  web: string;
  ios: string;
  android: string;
}

export interface MenuJsonItem {
  name: string;
  path?: string;
  children?: MenuJsonItem[];
  ios?: boolean;
  android?: boolean;
  web?: boolean;
  webTheme?: boolean;
  hide?: boolean;
  storybook?: string;
}

@Injectable({ providedIn: 'root' })
export class ComponentMetaDataService {
  private _supportedComponents: MetadataItem[] = [];

  get supportedComponents() {
    if (this._supportedComponents.length) {
      return this._supportedComponents;
    }
    this.getMetadataArrayFromMenuJsonItems(menuConfig, this._supportedComponents);

    return this._supportedComponents;
  }

  private normalizeComponentName(name: string) {
    return name.trim().replace(/\s/g, '-').toLowerCase();
  }

  getMobileComponents() {
    return this.supportedComponents.filter((component) => component.android || component.ios);
  }

  getWebComponents() {
    return this.supportedComponents.filter((component) => component.web);
  }

  getIOSComponents() {
    return this.supportedComponents.filter((component) => component.ios);
  }

  getAndroidComponents() {
    return this.supportedComponents.filter((component) => component.android);
  }

  getSupportInfoForComponent(component: string) {
    return this.supportedComponents.find(
      (supportedComponent) => supportedComponent.component.toLowerCase() === component.toLowerCase(),
    );
  }

  isAndroidComponent(component: string) {
    const supportInfo = this.getSupportInfoForComponent(component);

    return supportInfo && supportInfo.android;
  }

  isIOSComponent(component: string) {
    const supportInfo = this.getSupportInfoForComponent(component);

    return supportInfo && supportInfo.ios;
  }

  isWebComponent(component: string) {
    const supportInfo = this.getSupportInfoForComponent(component);

    return supportInfo && supportInfo.web;
  }

  private getMetadataArrayFromMenuJsonItems(items: MenuJsonItem[], metadataItems: MetadataItem[], parent = ''): void {
    // eslint-disable-next-line complexity
    items.forEach((item: MenuJsonItem) => {
      const parsedName = this.normalizeComponentName(item.name);

      if (item.children) {
        this.getMetadataArrayFromMenuJsonItems(item.children, metadataItems, parent + parsedName + '/');
      } else {
        if (item.web || item.storybook || item.ios || item.android) {
          const metadataItem = metadataItems.find(
            (metadataItemCurrent: MetadataItem) => metadataItemCurrent.component === parsedName,
          );

          if (metadataItem) {
            if (item.web || item.storybook) {
              metadataItem.web = parent;
            }
            if (item.ios) {
              metadataItem.ios = parent;
            }
            if (item.android) {
              metadataItem.android = parent;
            }
          } else {
            metadataItems.push({
              component: parsedName,
              web: !!(item.storybook ?? item.web) ? parent : '',
              ios: !!item.ios ? parent : '',
              android: !!item.android ? parent : '',
            });
          }
        }
      }
    });
  }
}
