import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';

import { LogoComponent } from './logo.component';

@NgModule({
  imports: [NgClass],
  declarations: [LogoComponent],
  exports: [LogoComponent],
})
export class LogoModule {}
