import { Component, ViewChild, ElementRef } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'bb-modal-page-ui',
  templateUrl: './bb-modal-page-ui.component.html',
})
export class ModalPageComponent {
  console = console;
  @ViewChild('modalRef', { static: true }) modalRef: ElementRef | undefined;
  newMessageFormGroup = new UntypedFormGroup({
    topic: new UntypedFormControl(),
    subject: new UntypedFormControl(),
    body: new UntypedFormControl(),
  });
  modal: any = {
    messageModalOpened: false,
    confirmModalOpened: false,
  };
  messageModalOptions: NgbModalOptions = {
    beforeDismiss: () => {
      if (this.newMessageFormGroup && this.newMessageFormGroup.dirty) {
        this.openModal('confirmModalOpened');

        return false;
      }

      return true;
    },
  };

  modalOpen = false;
  customModalOpen = false;
  bodyText = 'Some Modal Body Text';
  customModalWithCustomButtonsOpen = false;
  dialogOpen = false;
  customTextDialogOpen = false;

  openModal(modalName = 'messageModalOpened') {
    this.modal[modalName] = true;
  }

  cancelModal(modalName = 'messageModalOpened', resetForm = true) {
    this.modal[modalName] = false;
    if (resetForm) this.newMessageFormGroup.reset();
  }

  confirmConfirm() {
    this.cancelModal('confirmModalOpened', false);
    this.cancelModal();
  }

  confirmModal(data?: any) {
    console.log('succes: ', data);
  }

  closeModal(reason: any) {
    console.log(reason);
  }
}
