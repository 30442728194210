import { DatePipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';

import { BadgeModule } from '@backbase/ui-ang/badge';
import { CardVendorModule } from '@backbase/ui-ang/card-vendor';
import { IconModule } from '@backbase/ui-ang/icon';
import { LogoModule } from '@backbase/ui-ang/logo';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { PaymentCardComponent } from './payment-card.component';

@NgModule({
  imports: [
    NgClass,
    NgStyle,
    NgTemplateOutlet,
    DatePipe,
    BadgeModule,
    IconModule,
    LogoModule,
    CardVendorModule,
    PaymentCardNumberModule,
  ],
  declarations: [PaymentCardComponent],
  exports: [PaymentCardComponent],
})
export class PaymentCardModule {}
