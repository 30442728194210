import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'bb-showcase-android',
  templateUrl: './showcase-android.component.html',
})
export class ShowcaseAndroidComponent {
  path$: Observable<string | undefined> = this.route.paramMap.pipe(
    map((params: ParamMap) => {
      const componentId = params.get('id');

      return `assets/docs/html/android-${componentId}-page.component.html`;
    }),
  );
  constructor(private route: ActivatedRoute) {}
}
