import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, filter, take } from 'rxjs';
import { EventStatus, EventType, IProgressTrackerConfig } from '../progress-tracker.model';
import { IProgressTrackerItemTemplateConfig, IProgressTrackerTemplateConfig } from './progress-tracker-container.model';
@Component({
  selector: 'bb-progress-tracker-container',
  templateUrl: './progress-tracker-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressTrackerContainerComponent implements OnInit {
  @ViewChild('defaultProgressTrackerTemplate', { static: true }) private _defaultProgressTrackerTemplate:
    | TemplateRef<IProgressTrackerTemplateConfig>
    | undefined;
  /**
   * The reference to a custom template for the Progress Tracker Container.
   *
   * Allows to completely override the way Progress Tracker is displayed.
   *
   */
  @Input() progressTrackerTemplate: TemplateRef<IProgressTrackerTemplateConfig> | undefined;

  /**
   * The reference to a custom template for the Progress Tracker Item.
   *
   * Allows to completely override the way Progress Tracker Item is displayed.
   *
   */
  @Input() progressTrackerItemTemplate: TemplateRef<IProgressTrackerItemTemplateConfig> | undefined;

  /**
   * The reference to a custom template for the Progress Tracker Item Header.
   *
   * Allows to completely override the way Progress Tracker Item heading is displayed.
   *
   */
  @Input() progressTrackerHeaderTemplate: TemplateRef<any> | undefined;

  /**
   * Controls the visibility of the Progress Tracker.
   */
  @Input()
  set show(value: boolean) {
    this.show$.next(value);
  }

  get show(): boolean {
    return this.show$.getValue();
  }

  private readonly show$ = new BehaviorSubject<boolean>(false);

  // change data to use getter and setter
  @Input()
  set eventList(value: IProgressTrackerConfig[]) {
    // set the latest value for eventListSource BehaviorSubject
    this.currentEvents = [...value];
    this.currentEvents = this.currentEvents.filter(
      (item: IProgressTrackerConfig) => item.eventStatus === EventStatus.IN_PROGRESS,
    );
  }

  @Output() showChange = new EventEmitter<boolean>();

  /**
   * Reference to instance of customizable component.
   */
  readonly hostRef = this;

  /**
   * Array containg the all the progress items
   */
  currentEvents: IProgressTrackerConfig[] = [];
  animate = true;
  eventType = EventType;
  constructor(private readonly elementRef: ElementRef) {}

  ngOnInit() {
    if (!this.progressTrackerTemplate) {
      this.progressTrackerTemplate = this._defaultProgressTrackerTemplate;
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    this.show$
      .pipe(
        take(1),
        filter((show) => show && !this.elementRef.nativeElement.contains(targetElement)),
      )
      .subscribe(() => {
        this.show$.next(false);
        this.showChange.emit(false);
      });
  }

  @HostListener('document:keyup.escape')
  public onEsc() {
    if (this.show) {
      this.show = false;
      this.showChange.emit(false);
    }
  }

  public isShow() {
    this.show = !this.show;
    this.showChange.emit(this.show);
  }
}
