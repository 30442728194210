<ng-template #localeListTemplate let-localesList let-isMobileView="isMobileView">
  @for (locale of locales | keyvalue: unSorted; track $index) {
    <button
      data-role="locale-item"
      type="button"
      [attr.role]="isMobileView ? '' : 'menuitem'"
      (bbKeyboardClick)="changeLanguage(locale?.key)"
      [class.first]="$first"
      [class.last]="$last"
      [ngClass]="
        isMobileView ? 'list-group-item list-group-item-action border-bottom-0' : 'dropdown-item text-truncate'
      "
    >
      @if (locale?.value?.icon) {
        <img class="bb-locale-selector__option-icon" [src]="locale?.value?.icon" alt="" role="presentation" />
      }
      {{ locale?.value?.language }}
    </button>
  }
</ng-template>

@if (showLocaleSelector) {
  @if (showModalOnMobileView && (isMobileBreakpoint$ | async)) {
    <button
      bbButton
      [color]="btnColor"
      [block]="fullWidth"
      [buttonSize]="iconSize"
      (bbKeyboardClick)="toggleLocaleModal()"
      data-role="locale-selector-mobile"
      [attr.aria-label]="dropDownButtonLabel"
      class="bb-locale-selector__mobile-button"
    >
      @if (customLabel) {
        <ng-container *ngTemplateOutlet="customLabel"></ng-container>
      } @else {
        <span i18n="Language|Click to switch language label@@locale-selector.label.dropdownSwitchLanguageDefault"
          >Language ({{ currentLanguage }})</span
        >
      }
    </button>
    <bb-modal-ui [isOpen]="showLocaleModal" data-role="locale-modal">
      <bb-modal-header-ui
        title="Select Language"
        i18n-title="Select language modal header text@@locale-selector.modal.selectLanguageHeader"
      >
      </bb-modal-header-ui>
      <bb-modal-body-ui>
        <ng-template bbCustomModalBody>
          <div data-role="locale-list-mobile" class="list-group list-group-flush">
            <ng-container
              [ngTemplateOutlet]="localeListTemplate"
              [ngTemplateOutletContext]="{ $implicit: locales, isMobileView: true }"
            ></ng-container>
          </div>
        </ng-template>
      </bb-modal-body-ui>
      <bb-modal-footer-ui>
        <ng-template bbCustomModalFooter>
          <button
            bbButton
            (click)="toggleLocaleModal()"
            color="secondary"
            data-role="cancel-locale-modal-button"
            i18n="Cancel button text@@locale-selector.modal.cancelLocaleButton"
          >
            Cancel
          </button>
        </ng-template>
      </bb-modal-footer-ui>
    </bb-modal-ui>
  } @else {
    <bb-dropdown-menu-ui
      container="body"
      [btnColor]="btnColor"
      [position]="dropdownPlacement"
      [fullWidth]="fullWidth"
      [icon]="icon"
      [dropDownButtonLabel]="dropDownButtonLabel"
      data-role="locale-selector-menu"
    >
      <ng-template bbDropdownLabel>
        @if (customLabel) {
          <ng-container *ngTemplateOutlet="customLabel"></ng-container>
        } @else {
          <span i18n="Language|Click to switch language label@@locale-selector.label.dropdownSwitchLanguageDefault"
            >Language ({{ currentLanguage }})</span
          >
        }
      </ng-template>
      <ng-template bbDropdownMenuItem>
        <div data-role="locale-list-dropdown" class="bb-locale-selector__menu-item-container">
          <ng-container
            [ngTemplateOutlet]="localeListTemplate"
            [ngTemplateOutletContext]="{ $implicit: locales }"
          ></ng-container>
        </div>
      </ng-template>
    </bb-dropdown-menu-ui>
  }
}
