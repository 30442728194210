import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputRangeShowcaseComponent } from './input-range-showcase.component';
import { InputRangeModule } from '@backbase/ui-ang/input-range';
import { InputNumberModule } from '@backbase/ui-ang/input-number';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [
    CommonModule,
    InputRangeModule,
    InputNumberModule,
    ReactiveFormsModule,
    InputValidationMessageModule,
    ButtonModule,
    FormsModule,
  ],
  declarations: [InputRangeShowcaseComponent],
})
export class InputRangeShowcaseModule {}
