import '@angular/localize/init';

export enum ValueDiffPosition {
  BEFORE = 'before',
  AFTER = 'after',
}

export interface ValueDiffChangedModel {
  newData: string;
}

export const highlightValueClass = 'bb-value-diff--highlighted';
export const crossOutValueClass = 'bb-value-diff--crossed';

export const valueDiffArrowLeftClass = 'bb-value-diff--arrow-left';
export const valueDiffArrowRightClass = 'bb-value-diff--arrow-right';

export const srTextForNewVal = $localize`:@@value-diff-current-value-label:Current value:`;
export const srTextForOldVal = $localize`:@@value-diff-previous-value-label:Previous value:`;
