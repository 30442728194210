<div [class.active]="active" class="bb-product-item product-item">
  <div class="product-item-content">
    @if (customProductItemDebitCardTitle) {
      <ng-container *ngTemplateOutlet="customProductItemDebitCardTitle?.templateRef; context: hostRef"> </ng-container>
    } @else {
      <div class="bb-account-info">
        <div data-role="card-title" class="bb-account-info__title">
          {{ title }}
          @if (favorite) {
            <bb-icon-ui
              size="sm"
              class="bb-account-info__icon"
              [color]="favoriteIconColor"
              data-role="favorite-icon"
              name="star"
            >
            </bb-icon-ui>
          }
        </div>
        @if (productNumber) {
          <div data-role="card-sub-title" class="bb-subtitle bb-text-support bb-stack bb-account-info__product-number">
            <span class="bb-account-info__product-number-content">
              {{ productNumber | paymentCardNumber: productNumberFormat }}
            </span>
          </div>
        }
      </div>
    }
    @if (productDetailsInfo?.owners) {
      <div class="bb-product-item__owners">
        <span data-role="productDetailsInfo-owners">
          {{ productDetailsInfo?.owners }}
        </span>
      </div>
    }
  </div>
</div>
