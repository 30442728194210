import { AlertExampleComponent } from './alert/examples/alert.example.component';
import { AmountExampleComponent } from './amount/examples/amount.example.component';
import { AmountInputExampleComponent } from './amount-input/examples/amount-input.example.component';
import { AvatarExampleComponent } from './avatar/examples/avatar.example.component';
import { BadgeExampleComponent } from './badge/examples/badge.example.component';
import { BadgeCounterExampleComponent } from './badge-counter/examples/badge-counter.example.component';
import { ButtonExampleComponent } from './button/examples/button.example.component';
import { CardVendorExampleComponent } from './card-vendor/examples/card-vendor.example.component';
import { CharCounterExampleComponent } from './char-counter/examples/char-counter.example.component';
import { CheckboxGroupExampleComponent } from './checkbox-group/examples/checkbox-group-example.component';
import { CollapsibleAccordionExampleComponent } from './collapsible-accordion/examples/collapsible-accordion.example.component';
import { CollapsibleCardExampleComponent } from './collapsible-card/examples/collapsible-card-example.component';
import { CollapsibleExampleComponent } from './collapsible/examples/collapsible.example.component';
import { CommonErrorStateExampleComponent } from './common-error-state/examples/common-error-state.example.component';
import { CurrencyInputExampleComponent } from './currency-input/examples/currency-input.example.component';
import { DatePipeExampleComponent } from './date-pipe/examples/date-pipe.example.component';
import { DropdownMenuExampleComponent } from './dropdown-menu/examples/dropdown-menu.example.component';
import { EllipsisExampleComponent } from './ellipsis/examples/ellipsis.example.component';
import { EmptyStateExampleComponent } from './empty-state/examples/empty-state.example.component';
import { FieldsetExampleComponent } from './fieldset/examples/fieldset.example.component';
import { FileAttachmentExampleComponent } from './file-attachment/examples/file-attachment.example.component';
import { HeaderExampleComponent } from './header/examples/header.example.component';
import { IconExampleComponent } from './icon/examples/icon.example.component';
import { InputCheckboxExampleComponent } from './input-checkbox/examples/input-checkbox.example.component';
import { InputDatepickerExampleComponent } from './input-datepicker/examples/input-datepicker.example.component';
import { InputEmailExampleComponent } from './input-email/examples/input-email.example.component';
import { InputNumberExampleComponent } from './input-number/examples/input-number.example.component';
import { InputPhoneExampleComponent } from './input-phone/examples/input-phone.example.component';
import { InputRadioGroupExampleComponent } from './input-radio-group/examples/input-radio-group.example.component';
import { InputTextExampleComponent } from './input-text/examples/input-text.example.component';
import { InputValidationMessageExampleComponent } from './input-validation-message/examples/input-validation-message.example.component';
import { ListMultipleSelectExampleComponent } from './list-multiple-select/examples/list-multiple-select-example.component';
import { LoadButtonExampleComponent } from './load-button/examples/load-button.example.component';
import { LoadingIndicatorExampleComponent } from './loading-indicator/examples/loading-indicator.example.component';
import { LogoExampleComponent } from './logo/examples/logo.example.component';
import { ModalExampleComponent } from './modal/examples/modal.example.component';
import { ModeHeaderExampleComponent } from './mode-header/examples/mode-header.example.component';
import { NotificationExampleComponent } from './notification/examples/notification.example.component';
import { PageHeaderExampleComponent } from './page-header/examples/page-header.example.component';
import { PaginationExampleComponent } from './pagination/examples/pagination.example.component';
import { PaymentCardExampleComponent } from './payment-card/examples/payment-card.example.component';
import { PeriodSelectorExampleComponent } from './period-selector/examples/period-selector.example.component';
import { ProductItemBasicAccountExampleComponent } from './product-item-basic-account/examples/product-item-basic-account.example.component';
import { ProductItemCreditCardExampleComponent } from './product-item-credit-card/examples/product-item-credit-card.example.component';
import { ProductItemCurrentAccountExampleComponent } from './product-item-current-account/examples/product-item-current-account.example.component';
import { ProductItemDebitCardExampleComponent } from './product-item-debit-card/examples/product-item-debit-card.example.component';
import { ProductItemInvestmentAccountExampleComponent } from './product-item-investment-account/examples/product-item-investment-account.example.component';
import { ProductItemLoanExampleComponent } from './product-item-loan/examples/product-item-loan.example.component';
import { ProductItemSavingsAccountExampleComponent } from './product-item-savings-account/examples/product-item-savings-account.example.component';
import { ProductItemTermDepositExampleComponent } from './product-item-term-deposit/examples/product-item-term-deposit.example.component';
import { ProductSelectorExampleComponent } from './product-selector/examples/product-selector.example.component';
import { RichTextEditorExampleComponent } from './rich-text-editor/examples/rich-text-editor.example.component';
import { SearchBoxExampleComponent } from './search-box/examples/search-box.example.component';
import { SwitchExampleComponent } from './switch/examples/switch.example.component';
import { TextareaExampleComponent } from './textarea/examples/textarea.example.component';
import { DropdownSingleSelectExampleComponent } from './dropdown-single-select/examples/dropdown-single-select.example.component';
import { TabExampleComponent } from './tab/examples/tab.example.component';
import { TooltipExampleComponent } from './tooltip-directive/examples/tooltip.example.component';
import { ProgessbarExampleComponent } from './progressbar/examples/progressbar.example.component';
import { TableExampleComponent } from './table/examples/table.example.component';
import { InputPasswordExampleComponent } from './input-password/examples/input-password.example.component';
import { InputInlineEditExampleComponent } from './input-inline-edit/examples/input-inline-edit.example.component';
import { StepperExampleComponent } from './stepper/examples/stepper.example.component';
import { InputRangeExampleComponent } from './input-range/examples/input-range.example.component';
import { IbanPipeExampleComponent } from './iban-pipe/examples/iban-pipe.example.component';
import { ActionStatusExampleComponent } from './action-status/examples/action-status.example.component';
import { InfiniteScrollExampleComponent } from './infinite-scroll/examples/infinite-scroll.example.component';
import { HighlightExampleComponent } from './highlight/examples/highlight.example.component';
import { PaymentCardNumberPipeExampleComponent } from './payment-card-number-pipe/examples/payment-card-number-pipe.example.component';
import { AccountNumberPipeExampleComponent } from './account-number-pipe/examples/account-number-pipe.example.component';
import { PhoneNumberPipeExampleComponent } from './phone-number-format-pipe/examples/phone-number-pipe.example.component';
import { AccountSelectorExampleComponent } from './account-selector/examples/account-selector.example.component';
import { DropdownPanelExampleComponent } from './dropdown-panel/examples/dropdown-panel-example.component';
import { ProgressTrackerExampleComponent } from './progress-tracker/examples/progress-tracker-example.component';
import { DropdownMultiSelectExampleComponent } from './dropdown-multi-select/examples/dropdown-multi-select.example.component';

import { InputTimepickerExampleComponent } from './input-timepicker/examples/input-timepicker-example.component';
import { ItemLogExampleComponent } from './item-log/examples/item-log.example.component';
import { KeyboardClickExampleComponent } from './keyboard-click-directive/examples/keyboard-click.example.component';
import { SelectListExampleComponent } from './select-list/examples/select-list.example.component';
import { InputFileExampleComponent } from './input-file/examples/input-file.example.component';
import { AccountNumberExampleComponent } from './account-number/examples/account-number.example.component';
import { LocaleSelectorExampleComponent } from './locale-selector/examples/locale-selector.example.component';
import { IllustrationExampleComponent } from './illustration/examples/illustration.example.component';

import { DropdownMultiSelectModule } from '@backbase/ui-ang/dropdown-multi-select';
import { DropdownPanelModule } from '@backbase/ui-ang/dropdown-panel';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValueDiffExampleComponent } from './value-diff/examples/value-diff-example.component';
import { ButtonExampleService } from './button/examples/button.example.service';
import { ConfigurationInputComponent } from './configuration/configuration-input.component';
import { ConfigurationInputService } from './configuration/configuration-input.service';
import { InputDateDividedExampleComponent } from './input-date-divided/examples/input-date-divided.example.component';
import { HeadingExampleComponent } from './heading/examples/heading-example.component';

import { SearchBoxModule } from '@backbase/ui-ang/search-box';
import { AvatarModule } from '@backbase/ui-ang/avatar';
import { BadgeModule } from '@backbase/ui-ang/badge';
import { ButtonModule } from '@backbase/ui-ang/button';
import { LoadButtonModule } from '@backbase/ui-ang/load-button';
import { CardVendorModule } from '@backbase/ui-ang/card-vendor';
import { AmountModule } from '@backbase/ui-ang/amount';
import { LogoModule } from '@backbase/ui-ang/logo';
import { ErrorCommonStateModule } from '@backbase/ui-ang/common-error-state';
import { EmptyStateModule } from '@backbase/ui-ang/empty-state';
import { InputDatepickerModule } from '@backbase/ui-ang/input-datepicker';
import { CharCounterModule } from '@backbase/ui-ang/char-counter';
import { CurrencyInputModule } from '@backbase/ui-ang/currency-input';
import { CollapsibleModule } from '@backbase/ui-ang/collapsible';
import { CollapsibleCardModule } from '@backbase/ui-ang/collapsible-card';
import { ModalModule } from '@backbase/ui-ang/modal';
import { PageHeaderModule } from '@backbase/ui-ang/page-header';
import { PaginationModule } from '@backbase/ui-ang/pagination';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { PaymentCardModule } from '@backbase/ui-ang/payment-card';
import { AccountNumberPipeModule } from '@backbase/ui-ang/account-number-pipe';
import { PeriodSelectorModule } from '@backbase/ui-ang/period-selector';
import { SwitchModule } from '@backbase/ui-ang/switch';
import { TabModule } from '@backbase/ui-ang/tab';
import { FileAttachmentModule } from '@backbase/ui-ang/file-attachment';
import { FocusModule } from '@backbase/ui-ang/focus';
import { CollapsibleAccordionModule } from '@backbase/ui-ang/collapsible-accordion';
import { IconModule } from '@backbase/ui-ang/icon';
import { IbanModule } from '@backbase/ui-ang/iban-directive';
import { HeaderModule } from '@backbase/ui-ang/header';
import { AlertModule } from '@backbase/ui-ang/alert';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { NotificationModule } from '@backbase/ui-ang/notification';
import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { InputCheckboxModule } from '@backbase/ui-ang/input-checkbox';
import { InputPhoneModule } from '@backbase/ui-ang/input-phone';
import { InputEmailModule } from '@backbase/ui-ang/input-email';
import { DropdownSingleSelectModule } from '@backbase/ui-ang/dropdown-single-select';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';
import { PhoneNumberModule } from '@backbase/ui-ang/phone-number-format-pipe';
import { ProductItemSavingsAccountModule } from '@backbase/ui-ang/product-item-savings-account';

import { ProductItemCreditCardModule } from '@backbase/ui-ang/product-item-credit-card';
import { ProductItemBasicAccountModule } from '@backbase/ui-ang/product-item-basic-account';
import { ProductItemCurrentAccountModule } from '@backbase/ui-ang/product-item-current-account';
import { ProductItemInvestmentAccountModule } from '@backbase/ui-ang/product-item-investment-account';
import { ProductItemDebitCardModule } from '@backbase/ui-ang/product-item-debit-card';
import { ProductItemTermDepositModule } from '@backbase/ui-ang/product-item-term-deposit';
import { ProductItemLoanModule } from '@backbase/ui-ang/product-item-loan';
import { FieldsetModule } from '@backbase/ui-ang/fieldset';
import { TextareaModule } from '@backbase/ui-ang/textarea';
import { ProductSelectorModule } from '@backbase/ui-ang/product-selector';
import { TooltipModule } from '@backbase/ui-ang/tooltip-directive';
import { InputNumberModule } from '@backbase/ui-ang/input-number';
import { RichTextEditorModule } from '@backbase/ui-ang/rich-text-editor';
import { InputRadioGroupModule } from '@backbase/ui-ang/input-radio-group';
import { InputFileModule } from '@backbase/ui-ang/input-file';
import { BbDatePipeModule } from '@backbase/ui-ang/date-pipe';
import { ListMultipleSelectModule } from '@backbase/ui-ang/list-multiple-select';
import { CheckboxGroupModule } from '@backbase/ui-ang/checkbox-group';
import { EllipsisModule } from '@backbase/ui-ang/ellipsis';

import { HighlightModule } from '@backbase/ui-ang/highlight';
import { ProgressbarModule } from '@backbase/ui-ang/progressbar';
import { ProgressTrackerModule } from '@backbase/ui-ang/progress-tracker';
import { TableModule } from '@backbase/ui-ang/table';
import { InputPasswordModule } from '@backbase/ui-ang/input-password';
import { InputInlineEditModule } from '@backbase/ui-ang/input-inline-edit';
import { StepperModule } from '@backbase/ui-ang/stepper';
import { BbIbanPipeModule } from '@backbase/ui-ang/iban-pipe';
import { ActionStatusModule } from '@backbase/ui-ang/action-status';

import { BadgeCounterModule } from '@backbase/ui-ang/badge-counter';
import { InfiniteScrollModule } from '@backbase/ui-ang/infinite-scroll';
import { AccountSelectorModule } from '@backbase/ui-ang/account-selector';
import { ItemLogModule } from '@backbase/ui-ang/item-log';
import { InputTimepickerModule } from '@backbase/ui-ang/input-timepicker';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';
import { SelectListModule } from '@backbase/ui-ang/select-list';

import { AmountInputModule } from '@backbase/ui-ang/amount-input';
import { ModeHeaderModule } from '@backbase/ui-ang/mode-header';
import { ControlErrorHandlerModule } from '@backbase/ui-ang/control-error-handler';
import { InputRangeModule } from '@backbase/ui-ang/input-range';
import { ValueDiffDirectiveModule } from '@backbase/ui-ang/value-diff';
import { AccountNumberModule } from '@backbase/ui-ang/account-number';
import { HeadingModule } from '@backbase/ui-ang/heading';
import { LocaleSelectorModule } from '@backbase/ui-ang/locale-selector';
import { IllustrationModule } from '@backbase/ui-ang/illustration';

const uiCompModules = [
  AccountNumberPipeModule,
  AccountSelectorModule,
  ActionStatusModule,
  AlertModule,
  AmountInputModule,
  AmountModule,
  AvatarModule,
  BadgeCounterModule,
  BadgeModule,
  BbDatePipeModule,
  BbIbanPipeModule,
  ButtonModule,
  CardVendorModule,
  CharCounterModule,
  CheckboxGroupModule,
  CollapsibleAccordionModule,
  CollapsibleCardModule,
  CollapsibleModule,
  ErrorCommonStateModule,
  CurrencyInputModule,
  DropdownMenuModule,
  DropdownSingleSelectModule,
  EllipsisModule,
  EmptyStateModule,
  FieldsetModule,
  FileAttachmentModule,
  FocusModule,

  HeaderModule,
  HeadingModule,
  HighlightModule,
  IconModule,
  IllustrationModule,
  InfiniteScrollModule,
  InputCheckboxModule,
  InputDatepickerModule,
  InputEmailModule,
  InputFileModule,
  InputInlineEditModule,
  InputNumberModule,

  InputPasswordModule,
  InputPhoneModule,
  InputRadioGroupModule,
  InputRangeModule,
  InputTextModule,
  InputTimepickerModule,
  InputValidationMessageModule,
  ItemLogModule,
  KeyboardClickModule,
  ListMultipleSelectModule,
  LoadButtonModule,
  LoadingIndicatorModule,
  LocaleSelectorModule,
  LogoModule,
  ModalModule,
  ModeHeaderModule,
  NotificationModule,
  PageHeaderModule,
  PaginationModule,
  PaymentCardModule,
  PaymentCardNumberModule,
  PeriodSelectorModule,
  PhoneNumberModule,
  ProductItemBasicAccountModule,
  ProductItemCreditCardModule,
  ProductItemCurrentAccountModule,
  ProductItemDebitCardModule,
  ProductItemInvestmentAccountModule,
  ProductItemLoanModule,
  ProductItemSavingsAccountModule,
  ProductItemTermDepositModule,
  ProductSelectorModule,
  ProgressbarModule,
  ProgressTrackerModule,
  RichTextEditorModule,
  SearchBoxModule,
  SelectListModule,
  StepperModule,
  SwitchModule,
  TabModule,
  TableModule,
  TextareaModule,
  TooltipModule,
  ControlErrorHandlerModule,
  ValueDiffDirectiveModule,
  AccountNumberModule,
  IbanModule,
];

const exampleComponents = [
  AccountNumberPipeExampleComponent,
  AccountSelectorExampleComponent,
  ActionStatusExampleComponent,
  AlertExampleComponent,
  AmountExampleComponent,
  AmountInputExampleComponent,
  AvatarExampleComponent,
  BadgeCounterExampleComponent,
  BadgeExampleComponent,
  ButtonExampleComponent,
  CardVendorExampleComponent,
  CharCounterExampleComponent,
  CheckboxGroupExampleComponent,
  CollapsibleAccordionExampleComponent,
  CollapsibleCardExampleComponent,
  CollapsibleExampleComponent,
  CommonErrorStateExampleComponent,
  CurrencyInputExampleComponent,
  ConfigurationInputComponent,
  DatePipeExampleComponent,
  DropdownMenuExampleComponent,
  DropdownMultiSelectExampleComponent,
  DropdownPanelExampleComponent,
  DropdownSingleSelectExampleComponent,
  EllipsisExampleComponent,
  EmptyStateExampleComponent,
  FieldsetExampleComponent,
  FileAttachmentExampleComponent,
  HeaderExampleComponent,
  HeadingExampleComponent,
  HighlightExampleComponent,
  IbanPipeExampleComponent,
  IconExampleComponent,
  IllustrationExampleComponent,
  InfiniteScrollExampleComponent,
  InputCheckboxExampleComponent,
  InputDatepickerExampleComponent,
  InputEmailExampleComponent,
  InputFileExampleComponent,
  InputInlineEditExampleComponent,
  InputNumberExampleComponent,
  InputPasswordExampleComponent,
  InputPhoneExampleComponent,
  InputRadioGroupExampleComponent,
  InputRangeExampleComponent,
  InputTextExampleComponent,
  InputTimepickerExampleComponent,
  InputValidationMessageExampleComponent,
  ItemLogExampleComponent,
  KeyboardClickExampleComponent,
  ListMultipleSelectExampleComponent,
  LoadButtonExampleComponent,
  LoadingIndicatorExampleComponent,
  LocaleSelectorExampleComponent,
  LogoExampleComponent,
  ModalExampleComponent,
  ModeHeaderExampleComponent,
  NotificationExampleComponent,
  PageHeaderExampleComponent,
  PaginationExampleComponent,
  PaymentCardExampleComponent,
  PaymentCardNumberPipeExampleComponent,
  PeriodSelectorExampleComponent,
  PhoneNumberPipeExampleComponent,
  ProductItemBasicAccountExampleComponent,
  ProductItemCreditCardExampleComponent,
  ProductItemCurrentAccountExampleComponent,
  ProductItemDebitCardExampleComponent,
  ProductItemInvestmentAccountExampleComponent,
  ProductItemLoanExampleComponent,
  ProductItemSavingsAccountExampleComponent,
  ProductItemTermDepositExampleComponent,
  ProductSelectorExampleComponent,
  ProgessbarExampleComponent,
  RichTextEditorExampleComponent,
  SearchBoxExampleComponent,
  SelectListExampleComponent,
  StepperExampleComponent,
  SwitchExampleComponent,
  TabExampleComponent,
  TableExampleComponent,
  TextareaExampleComponent,
  TooltipExampleComponent,
  ValueDiffExampleComponent,
  InputDateDividedExampleComponent,
  ProgressTrackerExampleComponent,
  AccountNumberExampleComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ...uiCompModules,
    DropdownPanelModule,
    DropdownMultiSelectModule,
  ],
  declarations: [...exampleComponents],
  exports: [...exampleComponents],
  providers: [ButtonExampleService, ConfigurationInputService],
})
export class BackbaseUiAngExamplesModule {}

export const COMPONENTS = exampleComponents;
