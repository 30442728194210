import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPasswordShowcaseComponent } from './input-password-showcase.component';
import { InputPasswordModule } from '@backbase/ui-ang/input-password';
import { ReactiveFormsModule } from '@angular/forms';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';

@NgModule({
  imports: [CommonModule, InputPasswordModule, ReactiveFormsModule, InputValidationMessageModule],
  declarations: [InputPasswordShowcaseComponent],
})
export class InputPasswordShowcaseModule {}
