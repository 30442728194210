import {
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  Renderer2,
} from '@angular/core';

import { TableRowDirective } from './table-row.directive';
import { getKeyCode, KEY_CODES } from '@backbase/ui-ang/util';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[bbTableFocus]',
})
export class TableFocusDirective implements AfterViewInit, OnDestroy {
  private focusedItemIndex = -1;
  @ContentChildren(TableRowDirective, { read: ElementRef, descendants: true, emitDistinctChangesOnly: false })
  listItems!: QueryList<ElementRef>;

  @Input() focusFirstRowOnChanges = false;

  private destroy$ = new Subject<void>();

  constructor(private readonly renderer: Renderer2) {}

  @HostListener('keydown', ['$event'])
  onKeyEvent(event: KeyboardEvent) {
    switch (getKeyCode(event)) {
      case KEY_CODES.DOWN:
        this.focusedItemIndex = Math.min(this.focusedItemIndex + 1, this.listItems.length - 1);
        this.focusItem();
        event.preventDefault();
        break;
      case KEY_CODES.UP:
        this.focusedItemIndex = Math.max(this.focusedItemIndex - 1, 0);
        this.focusItem();
        event.preventDefault();
        break;
      case KEY_CODES.ENTER:
        const currentActiveItem = this.listItems.find(
          (item) =>
            // ignore the header and to only "click" on data in the table
            item.nativeElement.tagName === (event.target as HTMLElement)?.tagName &&
            item.nativeElement.classList.contains('active'),
        );

        if (currentActiveItem) {
          currentActiveItem.nativeElement.click();
          event.preventDefault();
        }
        break;
    }
  }

  private focusItem(focusNativeElement = true) {
    this.listItems.forEach((el, index) => {
      this.renderer.setAttribute(el.nativeElement, 'tabIndex', '0');

      if (index === this.focusedItemIndex && focusNativeElement) {
        el.nativeElement.focus();
      }
    });
  }

  /**
   * @internal
   */
  ngAfterViewInit(): void {
    // make first row in a table focusable
    this.listItems.changes.pipe(takeUntil(this.destroy$)).subscribe({
      next: (items) => {
        if (items.first?.nativeElement) {
          this.focusedItemIndex = 0;
          this.focusItem(this.focusFirstRowOnChanges);
        }
      },
    });
  }

  /**
   * @internal
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
