import { InjectionToken } from '@angular/core';

export enum DateFormat {
  short = 'short',
  medium = 'medium',
  long = 'long',
  full = 'full',
  shortDate = 'shortDate',
  mediumDate = 'mediumDate',
  longDate = 'longDate',
  fullDate = 'fullDate',
  shortTime = 'shortTime',
  mediumTime = 'mediumTime',
  longTime = 'longTime',
  fullTime = 'fullTime',
}

export interface DatePipeConfig {
  format: DateFormat | string;
}

export const DATE_PIPE_CONFIG = new InjectionToken<DatePipeConfig>('DATE_FORMAT_CONFIG_TOKEN');
