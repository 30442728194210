<form [formGroup]="form">
  <p id="label-account-type">Account type</p>
  <bb-input-radio-group-ui
    formControlName="accountType"
    aria-labelledby="'label-account-type'"
    [horizontal]="true"
    [preselect]="true"
    [required]="true"
  >
    <bb-input-radio-ui value="cr" label="Credit"></bb-input-radio-ui>
    <bb-input-radio-ui value="dt" label="Debit"></bb-input-radio-ui>
  </bb-input-radio-group-ui>
</form>
