import { Component, OnDestroy, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, ParamMap, UrlSegment } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ThemeDocsService } from '../../../services/theme-docs.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'bb-theme-doc',
  templateUrl: './theme-doc.component.html',
})
export class ThemeDocComponent implements OnInit, OnDestroy {
  path: string | undefined = undefined;
  router$$: Subscription | null = null;

  constructor(
    private route: ActivatedRoute,
    private themeDocsService: ThemeDocsService,
  ) {}

  ngOnInit() {
    const parentSegment$ = this.route.parent?.url.pipe(map((url: UrlSegment[]) => url[0].path));

    const routeParam$ = this.route.paramMap.pipe(
      distinctUntilChanged(),
      map((params: ParamMap) => params.get('id')),
    );

    this.router$$ = combineLatest([parentSegment$, routeParam$]).subscribe((value: any) => {
      const parentSegment = value[0];
      const routeParam = value[1];
      this.themeDocsService.isRendered.next(false);
      this.path = `assets/docs/web-theme/${parentSegment}/${routeParam}.html`;
    });
  }

  onRendered(): void {
    this.themeDocsService.isRendered.next(true);
  }

  ngOnDestroy() {
    this.router$$?.unsubscribe();
  }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: any) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: 'safeResourceUrl' })
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value: any) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}
