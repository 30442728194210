import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { combineLatestWith, filter, map, of, startWith, switchMap } from 'rxjs';
import { ShowcaseService } from '../../services/showcase.service';

@Component({
  selector: 'bb-tabs',
  templateUrl: './tabs.component.html',
  standalone: true,
  imports: [AsyncPipe, RouterLink],
})
export class TabsComponent {
  private routerNavigationEvent$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(this.router),
  );

  componentName$ = this.routerNavigationEvent$.pipe(
    // TODO: please fix the logic for mobile components
    filter((event) => (event as any).url.includes('web-')),
    switchMap(() => {
      return (
        this.route.firstChild?.params.pipe(
          map((params: any) => {
            return params.id;
          }),
        ) ?? of('')
      );
    }),
  );

  private componentMenuItem$ = this.componentName$.pipe(
    map((id: string) => {
      return this.showcaseService.getComponent(id);
    }),
  );

  tabsShown$ = this.componentMenuItem$.pipe(
    map((component) => {
      const tabsShown: {
        name: string;
        path: string;
      }[] = [];

      if (component?.storybook) {
        tabsShown.push({
          name: 'Web',
          path: 'web',
        });
      }

      if (component?.android) {
        tabsShown.push({
          name: 'Android',
          path: 'android',
        });
      }

      if (component?.ios) {
        tabsShown.push({
          name: 'iOS',
          path: 'ios',
        });
      }

      if (component?.designGuidelinesPath) {
        tabsShown.push({
          name: 'Design Guidelines',
          path: 'design',
        });
      }

      return tabsShown;
    }),
  );

  activeTab$ = this.routerNavigationEvent$.pipe(
    combineLatestWith(this.tabsShown$),
    map(([event, tabs]) => {
      const { url, urlAfterRedirects } = event as any;
      const currentPath = (urlAfterRedirects ?? url).split('/').at(-1).toLowerCase();

      return tabs.find((tab) => tab.path === currentPath);
    }),
  );

  constructor(
    private showcaseService: ShowcaseService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}
}
