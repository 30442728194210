/**
 * @name segment
 * @inner
 * @param value - the string to segment
 * @param segmentsSize - the size (or array of sizes) the segments should have
 * @param separator - the character to separate the segments with
 *
 * @description
 * Split a string into segments of specified segmentsSize(s)
 */
export function segment(value: string, segmentsSize: number | number[] = 0, separator = ' '): string {
  const segmentSizes = Array.isArray(segmentsSize) ? segmentsSize : [segmentsSize];
  const segments = [];

  for (let i = 0, chunk; i < value.length; i += chunk.length) {
    chunk = value.slice(i, i + segmentSizes[segments.length % segmentSizes.length] || Infinity);
    segments.push(chunk);
  }

  return segments.join(separator);
}
