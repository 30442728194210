import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionStatusShowcaseComponent } from './action-status-showcase.component';
import { ActionStatusModule } from '@backbase/ui-ang/action-status';

@NgModule({
  imports: [CommonModule, ActionStatusModule],
  declarations: [ActionStatusShowcaseComponent],
})
export class ActionStatusShowcaseModule {}
