import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-validation-message-ui-showcase',
  templateUrl: './input-validation-message-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputValidationMessageShowcaseComponent {
  vForm: UntypedFormGroup | undefined;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      withoutIcon: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
    });
  }

  isFieldValid(fieldName: string) {
    const control = this.getControl(fieldName);

    return control && (control.valid || control.untouched);
  }

  private getControl(fieldName: string) {
    return this.vForm?.controls[fieldName];
  }

  hasError(field: string, type: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors[type];
  }

  hasLengthError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors.minlength;
  }

  hasRequiredError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors.required;
  }
}
