<div class="bb-block bb-block--md">
  <h4>Amount with currency symbol</h4>
  <bb-amount-ui [amount]="12345.67" currency="USD"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Amount with currency code</h4>
  <bb-amount-ui [amount]="12345.67" currency="USD" [mapCurrency]="false"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Amount with 5 decimals and currency rounding</h4>
  <bb-amount-ui [amount]="12345.67891" currency="USD"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Amount with 5 decimals and 7 decimal places override</h4>
  <bb-amount-ui [amount]="12345.67891" currency="USD" [decimalPlaces]="7"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Amount with 5 decimals and 7 decimal places override, removing trailing zeroes</h4>
  <bb-amount-ui [amount]="12345.67891" currency="USD" [decimalPlaces]="7" [trailingZeroes]="false"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Amount with 0 decimal places override</h4>
  <bb-amount-ui [amount]="12345.67891" currency="USD" [decimalPlaces]="0"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Negative amount</h4>
  <bb-amount-ui [amount]="-12345.67"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Negative amount with currency symbol</h4>
  <bb-amount-ui [amount]="-12345.67" currency="USD"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Negative amount with currency code</h4>
  <bb-amount-ui [amount]="-12345.67" currency="USD" [mapCurrency]="false"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Negative amount to the right of currency code <i>(Not viewable in RTL with RTL toggle)</i></h4>
  <bb-amount-ui [amount]="-12345.67" currency="USD" [mapCurrency]="false" [signPosition]="'right'"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Percentages</h4>
  <bb-amount-ui [amount]="15" [showPercent]="true" [decimalPlaces]="0"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Percentages with locale that uses comma</h4>
  <bb-amount-ui [amount]="56.5 | number: '1.2-2'" [showPercent]="true"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md" data-role="amount-with-async-currency">
  <h4>Asynchronous currency</h4>
  <bb-amount-ui [amount]="-12345.67" [currency]="asyncCurrency" [decimalPlaces]="0"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Abbreviated amount</h4>
  <bb-amount-ui [amount]="'-122329323230'" [abbreviate]="true" [mapCurrency]="true" currency="USD"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Very big amount (<code>amount</code> should be passed as a string)</h4>
  <bb-amount-ui [amount]="'999999999999999999999.99999'" [mapCurrency]="true" currency="USD"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Highlight (with bbHighlight directive and custom classes)</h4>
  <div class="bb-block bb-block--sm">
    <bb-amount-ui [amount]="12345.67" currency="USD" [bbHighlight]="true" positiveClass="text-success"></bb-amount-ui>
  </div>
  <div class="bb-block bb-block--sm">
    <bb-amount-ui
      [amount]="-12345.67"
      currency="USD"
      [bbHighlight]="true"
      negativeClass="bb-text-danger"
    ></bb-amount-ui>
  </div>
</div>

<div class="bb-block bb-block--md">
  <h4>Positive amount with sign</h4>
  <bb-amount-ui [amount]="'12345.67'" currency="USD" [mapCurrency]="false" [showPlusSign]="true"></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Positive amount with sign (sign position right)</h4>
  <bb-amount-ui
    [amount]="'12345.67'"
    currency="USD"
    [mapCurrency]="false"
    [showPlusSign]="true"
    [signPosition]="'right'"
  ></bb-amount-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Formatted amount with hidden currency (BHD)</h4>
  <bb-amount-ui [amount]="'1500.2500'" [mapCurrency]="true" currency="BHD" [hideCurrencySymbol]="true"></bb-amount-ui>
</div>
