import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Placement, PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {
  HeadingNavigationButtonItem,
  HeadingNavigationSeparatorItem,
  HeadingNavigationItem,
} from '../../models/heading.models';

@Component({
  selector: 'bb-heading-dropdown',
  templateUrl: './heading-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingDropdownComponent {
  /**
   * Array of representation of the link menu object
   */
  @Input() menu: HeadingNavigationItem<any>[] = [];
  /**
   * Flag to denote if the first link from buttons array should be used as default target
   */
  @Input() useFirstLinkAsDefault = false;

  /**
   * The position of the dropdown, position will be picked in order of feasibility
   */
  @Input() position: Placement | PlacementArray = ['bottom-end', 'bottom-start', 'top-end', 'top-start'];
  /**
   * Button label. Will be used if useFirstLinkAsDefault = false;
   */
  @Input() buttonLabel!: string;

  /**
   * Button icon. Will be used if useFirstLinkAsDefault = false;
   */
  @Input() buttonIcon!: string;
  /**
   * Color of the button. Default `primary`
   */
  @Input() buttonColor = 'primary';
  /**
   * Size of the button. Allowed values are `sm` or `md`. Default `md`
   */
  @Input() buttonSize: 'sm' | 'md' = 'md';

  /**
   * Event on item click
   */
  @Output() navigationAction = new EventEmitter<any>();

  public get defaultMenuItem(): HeadingNavigationButtonItem<any> | null {
    const firstItem =
      this.menu.length === 1 || (this.useFirstLinkAsDefault && this.menu.length > 1) ? this.menu[0] : null;
    if (!firstItem || (firstItem as HeadingNavigationSeparatorItem).isSeparator) {
      return null;
    }

    return firstItem as HeadingNavigationButtonItem<any>;
  }

  public get dropDownButtonLabel() {
    return (!this.useFirstLinkAsDefault && this.buttonLabel) || 'Toggle dropdown';
  }

  onClick(event: Event, value: any) {
    event.preventDefault();
    this.navigationAction.emit(value);
  }
}
