import { InjectionToken } from '@angular/core';

export interface AmountConfig {
  abbreviate?: boolean;
  currency?: string;
  decimalPlaces?: number;
  mapCurrency?: boolean;
  trailingZeroes?: boolean;
  currencyFormat?: 'wide' | 'narrow';
  signPosition?: 'left' | 'right';
}

export const AMOUNT_CONFIG_TOKEN = new InjectionToken<AmountConfig>('AMOUNT_CONFIG_TOKEN');
