<div class="bb-stack bb-block bb-block--md">
  @for (badge of badgeCounterShowCaseSetup; track $index) {
    <bb-badge-counter-ui
      [count]="badge.count"
      [maxCount]="badge.maxCount"
      [color]="badge.type"
      class="bb-stack__item"
    ></bb-badge-counter-ui>
  }
</div>
