import { Component, Input, ChangeDetectionStrategy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IProgressTrackerItemTemplateConfig } from '../../containers/progress-tracker-container.model';
import { IEventPayload } from '../../progress-tracker.model';
import { PubSubService } from '../../pub-sub.service';
@Component({
  selector: 'bb-progress-item',
  templateUrl: './progress-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressItemComponent implements OnInit {
  @ViewChild('defaultProgressTrackerItemTemplate', { static: true }) private _defaultProgressTrackerItemTemplate:
    | TemplateRef<IProgressTrackerItemTemplateConfig>
    | undefined;
  @ViewChild('defaultProgressTrackerHeaderTemplate', { static: true }) private _defaultProgressTrackerHeaderTemplate:
    | TemplateRef<IProgressTrackerItemTemplateConfig>
    | undefined;

  /**
   * The reference to a custom template for the Progress Tracker Item.
   *
   * Allows to completely override the way Progress Item is displayed.
   *
   */
  @Input() progressTrackerItemTemplate: TemplateRef<any> | undefined;

  /**
   * The reference to a custom template for the Progress Tracker Item Header.
   *
   * Allows to completely override the way Progress Item Heading is displayed.
   *
   */
  @Input() progressTrackerHeaderTemplate: TemplateRef<any> | undefined;

  /**
   * Reference to instance of customizable component.
   */
  readonly hostRef = this;
  /**
   * The progress initiated time in millseconds
   */
  @Input() eventPayload?: IEventPayload;

  constructor(public pubsubService: PubSubService) {}

  ngOnInit(): void {
    if (!this.progressTrackerItemTemplate) {
      this.progressTrackerItemTemplate = this._defaultProgressTrackerItemTemplate;
    }
    if (!this.progressTrackerHeaderTemplate) {
      this.progressTrackerHeaderTemplate = this._defaultProgressTrackerHeaderTemplate;
    }
  }
  cancelItem(id: string) {
    this.pubsubService.sendData(id);
  }
}
