<form [formGroup]="vForm">
  <p>
    <bb-textarea-ui
      label="Additional remarks"
      placeholder="Enter text..."
      formControlName="textarea"
      [showCharCounter]="true"
      [minLength]="3"
      [maxLength]="160"
      [rows]="4"
    >
    </bb-textarea-ui>
  </p>
</form>
