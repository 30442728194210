/*
  DSO will handle UI groups, using the key prop of exported object. Ensure that it is plural.
*/
export const productItemMembers: Object = {
  'product-items': [
    'product-item-basic-account',
    'product-item-savings-account',
    'product-item-current-account',
    'product-item-investment-account',
    'product-item-credit-card',
    'product-item-debit-card',
    'product-item-loan',
    'product-item-term-deposit',
  ],
};
