<bb-product-selector-ui [autoClose]="false">
  <ng-container bbDropdownToggle>
    @if (selected) {
      <bb-product-item-basic-account-ui
        class="text-start"
        [title]="selected.name"
        [amount]="selected.amount"
        [productNumber]="selected.productNumber"
        [currency]="selected.currency"
        [productNumberFormat]="productNumberFormat"
        [active]="true"
      >
      </bb-product-item-basic-account-ui>
    }
    @if (!selected) {
      <div class="p-4 d-flex align-items-center">
        <span class="align-items-left py-2"> Select a product </span>
      </div>
    }
  </ng-container>
  <ng-container bbDropdownMenu>
    @for (product of products; track product.id) {
      <button
        (click)="selectProduct(product)"
        type="button"
        role="option"
        class="flex-column align-items-stretch btn btn-block rounded-0 m-0 p-0 text-start bg-white"
      >
        <bb-product-item-basic-account-ui
          [title]="product.name"
          [amount]="product.amount"
          [productNumber]="product.productNumber"
          [currency]="product.currency"
          [productNumberFormat]="productNumberFormat"
          [active]="product.id === selected?.id"
        ></bb-product-item-basic-account-ui>
      </button>
    }
  </ng-container>
</bb-product-selector-ui>
