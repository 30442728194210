import { NgModule } from '@angular/core';

import {
  CustomProductItemLoanDetailsFormatterDirective,
  CustomProductItemLoanTitleDirective,
  ProductItemLoanComponent,
} from './product-item-loan.component';
import { AmountModule } from '@backbase/ui-ang/amount';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { HighlightModule } from '@backbase/ui-ang/highlight';
import { IconModule } from '@backbase/ui-ang/icon';
import { NgTemplateOutlet } from '@angular/common';

@NgModule({
  imports: [AmountModule, PaymentCardNumberModule, HighlightModule, IconModule, NgTemplateOutlet],
  declarations: [
    ProductItemLoanComponent,
    CustomProductItemLoanTitleDirective,
    CustomProductItemLoanDetailsFormatterDirective,
  ],
  exports: [
    ProductItemLoanComponent,
    CustomProductItemLoanTitleDirective,
    CustomProductItemLoanDetailsFormatterDirective,
  ],
})
export class ProductItemLoanModule {}
