<form [formGroup]="form">
  <bb-dropdown-multi-select-ui
    label="Available days"
    placeholder="Please choose one or more days"
    formControlName="days"
    [required]="true"
  >
    <bb-dropdown-multi-select-option-ui value="0" label="Sunday">
    </bb-dropdown-multi-select-option-ui>
    <bb-dropdown-multi-select-option-ui value="1" label="Monday">
    </bb-dropdown-multi-select-option-ui>
    <bb-dropdown-multi-select-option-ui value="2" label="Tuesday">
    </bb-dropdown-multi-select-option-ui
    ><bb-dropdown-multi-select-option-ui value="3" label="Wednesday">
    </bb-dropdown-multi-select-option-ui>
    <bb-dropdown-multi-select-option-ui value="4" label="Thursday">
    </bb-dropdown-multi-select-option-ui
    ><bb-dropdown-multi-select-option-ui value="5" label="Friday">
    </bb-dropdown-multi-select-option-ui>
    <bb-dropdown-multi-select-option-ui value="6" label="Saturday">
    </bb-dropdown-multi-select-option-ui>
  </bb-dropdown-multi-select-ui>
</form>
