import { Injectable } from '@angular/core';
import publicAPI from '../../assets/public-api.json';

export interface DesignToken {
  configurable: boolean;
  default: string;
  description: string;
  groups: string[];
  stable: boolean;
  token: string;
  variable: string;
}

export interface DesignTokens {
  type: string;
  tokens: DesignToken[];
}

const mapTokens = (api: any): DesignToken => {
  const configurable = !api.description.includes('read-only');
  const stable = !api.description.includes('experimental');

  return {
    ...api,
    description: api.description.replace('(read-only)', '').replace('(experimental)', ''),
    token: api.variable,
    configurable,
    stable,
  };
};

const groupTokens = (grouped: any, token: any, index: number, arr: any[]): DesignTokens[] => {
  const group = token.groups[0] || 'other';
  const selectedGroup = grouped[group] || [];

  grouped[group] = [...selectedGroup, token];

  return arr.length - 1 === index ? Object.entries(grouped).map(([type, tokens]: any) => ({ type, tokens })) : grouped;
};

@Injectable({ providedIn: 'root' })
export class DesignTokenService {
  getTokens(tokenType?: string): DesignTokens[] {
    const tokens = publicAPI.global
      .filter((api: any) => !api.groups.includes('implementation'))
      .map(mapTokens)
      .reduce(groupTokens, {});

    return tokenType ? tokens.filter(({ type }: any) => type === tokenType) : tokens;
  }
}
