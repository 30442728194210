import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import {
  CheckboxItem,
  CheckboxList,
  ConfigurationInputService,
  DropdownItem,
  DropdownList,
  InputItem,
  InputList,
  RadioGroup,
  RadioGroupList,
} from './configuration-input.service';

@Component({
  selector: 'bb-configuration-input-ui',
  templateUrl: './configuration-input.component.html',
})
export class ConfigurationInputComponent implements OnInit {
  @Input()
  checkboxList: CheckboxList = [];

  @Input()
  radioList: RadioGroupList = [];

  @Input()
  dropdownList: DropdownList = [];

  @Input()
  inputList: InputList = [];

  vform: UntypedFormGroup;

  ngOnInit() {
    if (this.dropdownList) {
      this.dropdownList.forEach((dropdownItem: DropdownItem) => {
        this.dropdown.addControl(
          dropdownItem.formControlName,
          new UntypedFormControl(dropdownItem.dropdownItemList[0]),
        );
      });
    }

    if (this.radioList) {
      this.radioList.forEach((radioItem: RadioGroup) => {
        this.radio.addControl(radioItem.formControlName, new UntypedFormControl(radioItem.radioItems[0].value));
      });
    }

    if (this.checkboxList) {
      this.checkboxList.forEach((checkboxItem: CheckboxItem) => {
        this.checkbox.addControl(checkboxItem.formControlName, new UntypedFormControl(checkboxItem.value));
      });
    }

    if (this.inputList) {
      this.inputList.forEach((input: InputItem) => {
        this.input.addControl(input.formControlName, new UntypedFormControl(input.value));
      });
    }

    this.configurationInputService.sharedField.next(this.vform.value);

    this.vform.valueChanges.subscribe({
      next: (val) => {
        this.configurationInputService.sharedField.next(val);
      },
    });
  }
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly configurationInputService: ConfigurationInputService,
  ) {
    this.vform = this.formBuilder.group({
      checkbox: new UntypedFormGroup({}),
      radio: new UntypedFormGroup({}),
      dropdown: new UntypedFormGroup({}),
      input: new UntypedFormGroup({}),
    });
  }

  get dropdown(): UntypedFormGroup {
    return this.vform.get('dropdown') as UntypedFormGroup;
  }

  get radio(): UntypedFormGroup {
    return this.vform.get('radio') as UntypedFormGroup;
  }

  get checkbox(): UntypedFormGroup {
    return this.vform.get('checkbox') as UntypedFormGroup;
  }

  get input(): UntypedFormGroup {
    return this.vform.get('input') as UntypedFormGroup;
  }
}
