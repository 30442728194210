import { NgModule } from '@angular/core';
import { NgClass } from '@angular/common';

import { ButtonComponent } from './button.component';
import { ButtonDirective } from './button.directive';

@NgModule({
  imports: [NgClass],
  declarations: [ButtonComponent, ButtonDirective],
  exports: [ButtonComponent, ButtonDirective],
})
export class ButtonModule {}
