import { Inject, Injectable, Optional } from '@angular/core';
import { merge, Subject, of, EMPTY, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, filter, withLatestFrom, switchMap, tap } from 'rxjs/operators';
import { MediaQueryService } from '@backbase/ui-ang/media-query-lib';
import { LayoutConfig, LAYOUT_CONFIG_TOKEN } from './layout.model';
import { Router, NavigationEnd } from '@angular/router';

/**
 * ## Navigation Type Horizontal
 * - On large desktop show the menu in the topbar. On mobile show a side menu
 *
 * ## Navigation Type Vertical
 * - On large desktop, show the sidebar. Toggling collapses the nav items into icons.
 * - On tablet and small desktop, collapse the nav items into icons. Toggling shows the full nav items.
 * - On mobile, don't collapse the sidebar, but hide it. Toggling shows the sidebar.
 */
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private readonly isHighResolutionViewSubject: Subject<boolean> = new Subject<boolean>();
  readonly isHighResolutionView$: Observable<boolean> = this.isHighResolutionViewSubject.asObservable();
  readonly navigationExpanded$: Observable<boolean>;
  private readonly manuallyExpanded$ = new Subject<boolean>();
  private isNavExpanded = false;

  constructor(
    private readonly mediaQueryService: MediaQueryService,
    private readonly router: Router,
    @Optional() @Inject(LAYOUT_CONFIG_TOKEN) private readonly config: LayoutConfig,
  ) {
    /**
     * Default breakpoint used by business
     */
    if (!this.config) {
      this.config = { collapseBreakpoint: 'xl' };
    }

    /**
     * Observing the changes for screen resize
     */
    const isLowResolutionView$ = this.mediaQueryService.isMediaBreakpointMatches(
      'max-width',
      this.config.collapseBreakpoint,
    );

    /**
     * In mobile view after navigation to another route the sidebar should collapse
     */
    const collapseOnRouterNav$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      withLatestFrom(isLowResolutionView$),
      switchMap(([, isLowResolutionView]) => (isLowResolutionView ? of(false) : EMPTY)),
    );

    this.navigationExpanded$ = merge(
      isLowResolutionView$.pipe(
        map((isLowResolutionView) => {
          this.isHighResolutionViewSubject.next(!isLowResolutionView);

          return !isLowResolutionView;
        }),
      ),
      collapseOnRouterNav$,
      this.manuallyExpanded$,
    ).pipe(
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
      tap((isExpanded: boolean) => {
        this.isNavExpanded = isExpanded;
      }),
    );
  }

  /**
   * Toggling navigation visibility
   */
  toggleNav() {
    this.isNavExpanded = !this.isNavExpanded;
    this.manuallyExpanded$.next(this.isNavExpanded);
  }
}
