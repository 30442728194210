import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
/**
 * @name HeaderComponent
 *
 * @description
 * Component that displays a header.
 *
 * @deprecated Component is deprecated as of ui-ang v11. It will be removed in ui-ang v13. Please use PageHeaderComponent to replace your primary header and native HTML h2-h5 to replace smaller headers.
 */
@Component({
  selector: 'bb-header-ui',
  templateUrl: './header.component.html',
  styles: [],
})
export class HeaderComponent {
  /**
   * The heading classes to be added to the component.
   */
  @Input() headingClasses: string | undefined;
  /**
   * The heading type to be rendered in the component.
   */
  @Input() headingType: string | undefined;
  /**
   * The text or label or a `TemplateRef` to be displayed as the header.
   */
  @Input() heading: string | TemplateRef<any> | undefined;

  @ViewChild('defaultTemplate', { static: true }) defaultTemplate: TemplateRef<any> | undefined;

  getHeadingTemplate(): TemplateRef<any> | undefined {
    if (this.heading instanceof TemplateRef) {
      return this.heading;
    }

    return this.defaultTemplate;
  }
}
