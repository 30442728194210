import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputCheckboxShowcaseComponent } from './input-checkbox-showcase.component';
import { InputCheckboxModule } from '@backbase/ui-ang/input-checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { PaymentCardModule } from '@backbase/ui-ang/payment-card';
import { TableModule } from '@backbase/ui-ang/table';

@NgModule({
  imports: [
    CommonModule,
    InputCheckboxModule,
    ReactiveFormsModule,
    InputValidationMessageModule,
    PaymentCardModule,
    TableModule,
  ],
  declarations: [InputCheckboxShowcaseComponent],
})
export class InputCheckboxShowcaseModule {}
