import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryFlag',
})
export class CountryFlagPipe implements PipeTransform {
  /**
   * Transforms the input value to a flag icon class name.
   *
   * @param value The country code to transform.
   * @returns The class name for the flag icon.
   */
  transform(value: string) {
    return 'flag-icon-' + value.toLowerCase();
  }
}
