import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListMultipleSelectShowcaseComponent } from './list-multiple-select-showcase.component';
import { ListMultipleSelectModule } from '@backbase/ui-ang/list-multiple-select';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, ListMultipleSelectModule, ReactiveFormsModule],
  declarations: [ListMultipleSelectShowcaseComponent],
})
export class ListMultipleSelectShowcaseModule {}
