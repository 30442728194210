import { Inject, Injectable, Optional } from '@angular/core';
import {
  ACCOUNTS_DISPLAYING_FORMAT,
  DefaultAccountsDisplayingFormat,
  AccountNumberType,
  AccountsDisplayingFormat,
  AccountsDisplayingFormatConfiguration,
} from './constants';

/**
 * A configuration service for account numbers displaying format
 */
@Injectable({
  providedIn: 'root',
})
export class AccountsDisplayingFormatService {
  /**
   * @name AccountsDisplayingFormatService#constructor
   * @param accountsDisplayingFormat - (Optional) AccountsDisplayingFormatConfiguration
   *
   * @internal
   */
  constructor(
    @Optional()
    @Inject(ACCOUNTS_DISPLAYING_FORMAT)
    private readonly accountsDisplayingFormat: AccountsDisplayingFormatConfiguration,
  ) {}

  /**
   * @name AccountsDisplayingFormatService#getDefaultFormat
   * @param attributeType - numbers format attributeType
   * @param cardProviderName - card provider name
   *
   * @description
   * Gets default account numbers format per attribute type
   */
  getDefaultFormat(attributeType: AccountNumberType, cardProviderName?: string): AccountsDisplayingFormat {
    if (attributeType === 'cardNumber') {
      return this.getCardNumberFormat(cardProviderName);
    }

    return this.accountsDisplayingFormat?.[attributeType] ?? DefaultAccountsDisplayingFormat;
  }

  private getCardNumberFormat(cardProviderName?: string): AccountsDisplayingFormat {
    const cardNumber = this.accountsDisplayingFormat?.cardNumber;
    const providersConfiguration = Object.entries(cardNumber?.cardProviders || {}).reduce(
      (configuration, entry) => ({ ...configuration, [entry[0].toLowerCase()]: entry[1] }),
      {} as Record<string, AccountsDisplayingFormat>,
    );

    if (cardProviderName && providersConfiguration[cardProviderName.toLowerCase()]) {
      return providersConfiguration[cardProviderName.toLowerCase()];
    }

    return cardNumber ?? DefaultAccountsDisplayingFormat;
  }
}
