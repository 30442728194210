<div class="bb-block bb-block--lg">
  <h2>Timepicker with 10 minute step and validation errors</h2>
  <form [formGroup]="vForm">
    <bb-input-timepicker-ui
      id="timePicker-validation"
      [aria-describedby]="'input-timepicker-example-error'"
      [readonly]="false"
      [minuteStep]="10"
      formControlName="time"
      [min]="min"
      [max]="max"
    >
    </bb-input-timepicker-ui>

    <bb-input-validation-message-ui [showErrors]="showErrors()" [id]="'input-timepicker-example-error'">
      @if (hasError('minTime')) {
        <span>Time should be after {{ min }}</span>
      }
      @if (hasError('maxTime')) {
        <span>Time should not exceed {{ max }}</span>
      }
      @if (hasError('minMaxTime')) {
        <span>Time should be between {{ min }} and {{ max }}</span>
      }
    </bb-input-validation-message-ui>
  </form>
</div>
<div class="bb-block bb-block--lg">
  <h2>Readonly timepicker</h2>
  <bb-input-timepicker-ui id="timepicker-readonly" [readonly]="true"></bb-input-timepicker-ui>
</div>
<div class="bb-block bb-block--lg">
  <h2>Disabled timepicker</h2>
  <bb-input-timepicker-ui id="timepicker-readonly" [disabled]="true"></bb-input-timepicker-ui>
</div>
<div class="bb-block bb-block--lg">
  <h2>Small timepicker</h2>
  <bb-input-timepicker-ui id="timepicker-readonly-small" [size]="'small'"></bb-input-timepicker-ui>
</div>
<div class="bb-block bb-block--lg">
  <h2>Timepicker with seconds and meridian</h2>
  <bb-input-timepicker-ui
    id="timepicker-meridian"
    [readonly]="false"
    [seconds]="true"
    clock="12h"
    [(ngModel)]="timeModel"
    [min]="min"
  >
  </bb-input-timepicker-ui>
</div>
