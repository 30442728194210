<ng-template [ngTemplateOutlet]="progressTrackerTemplate" [ngTemplateOutletContext]="hostRef"></ng-template>
<ng-template #defaultProgressTrackerTemplate let-currentEvents="currentEvents" let-show="show" let-animate="animate">
  @if (currentEvents && currentEvents.length > 0) {
    <div class="bb-load-button">
      <button
        bbButton
        [circle]="true"
        color="tertiary"
        (click)="hostRef.isShow()"
        [attr.data-role]="'progress-spinner'"
        [attr.aria-label]="'Show progress'"
      >
        <bb-icon-ui name="cached" size="lg" [animate]="animate"></bb-icon-ui>
      </button>
      @if (show) {
        <div class="bb-progress-tracker-item-container modal-content modal-body">
          <div class="bb-stack">
            <bb-header-ui
              headingType="h4"
              class="bb-stack__item"
              heading="Upload file log"
              i18n-heading="Progress tracker title|Progress tracker heading info@@progress-tracker.tite"
            >
            </bb-header-ui>
            <div
              class="bb-stack__item bb-stack__item--push-right bb-stack__item--align-top bb-text-support"
              i18n="Total files|Progress tracker total count label@@progress-tracker.total.count.label"
            >
              {{ currentEvents.length }} total
            </div>
          </div>
          <div><hr /></div>
          @for (event of currentEvents; track $index) {
            <div class="bb-list">
              @if (event && event.payload) {
                @if (event.payload.actionType === eventType.FILE_UPLOAD) {
                  <div class="bb-list__item bb-list__item--no-separator">
                    <bb-progress-item
                      [eventPayload]="event.payload"
                      [progressTrackerItemTemplate]="progressTrackerItemTemplate"
                      [progressTrackerHeaderTemplate]="progressTrackerHeaderTemplate"
                    >
                    </bb-progress-item>
                  </div>
                }
              }
            </div>
          }
        </div>
      }
    </div>
  }
</ng-template>
