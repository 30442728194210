import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputValidationMessageShowcaseComponent } from './input-validation-message-showcase.component';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [ButtonModule, CommonModule, InputValidationMessageModule, ReactiveFormsModule, InputTextModule],
  declarations: [InputValidationMessageShowcaseComponent],
})
export class InputValidationMessageShowcaseModule {}
