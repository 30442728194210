<div class="paragraph">
<p>Ellipses are used in place of a missing content like missing or redacted words or numbers.</p>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Often used in pagination or to truncate content, this pattern can also be used to redact sensitive information like credit card and social security numbers.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_redacting_numbers">Redacting numbers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Midline ellipses [• • •] are bulleted characters that partially hide sensitive data. They indicate when a number like credit cards or Social Security number arne’t entirely visible.</p>
</div>
<div class="paragraph">
<p><strong>When to use 3 ellipses</strong></p>
</div>
<div class="paragraph">
<p>To redact most numbers, like Social Security numbers, use three midline ellipses [• • •].</p>
</div>
<div class="sect3">
<h4 id="_ssn_5678">SSN • • • 5678</h4>
<div class="paragraph">
<p><strong>When to use 4 ellipses</strong></p>
</div>
<div class="paragraph">
<p>Credit and debit card data must be redacted using four midline ellipses [• • • •]</p>
</div>
</div>
<div class="sect3">
<h4 id="_visa_1234">Visa • • • • 1234</h4>

</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use to truncate content in the center of a title bar</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don&#8217;t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use ellipsis as placeholder copy</p>
</li>
<li>
<p>Use these to hide content or when trailing off a sentence</p>
</li>
</ul>
</div>
</div>
</div>