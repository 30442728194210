<form [formGroup]="vForm">
  <label for="data-divided">
    Date Divided field
    <span class="bb-text-support bb-text-default"
      >(format: 'MM-DD-YYYY')</span
    >
  </label>
  <bb-input-date-divided
    format="MM-DD-YYYY"
    id="data-divided"
    formControlName="inputDateDivided"
  ></bb-input-date-divided>
</form>
