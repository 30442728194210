import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemCurrentAccountShowcaseComponent } from './product-item-current-account-showcase.component';
import { ProductItemCurrentAccountModule } from '@backbase/ui-ang/product-item-current-account';
import { ProductItemBasicAccountModule } from '@backbase/ui-ang/product-item-basic-account';

@NgModule({
  imports: [CommonModule, ProductItemCurrentAccountModule, ProductItemBasicAccountModule],
  declarations: [ProductItemCurrentAccountShowcaseComponent],
})
export class ProductItemCurrentAccountShowcaseModule {}
