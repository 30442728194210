import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardVendorShowcaseComponent } from './card-vendor-showcase.component';
import { CardVendorModule } from '@backbase/ui-ang/card-vendor';

@NgModule({
  imports: [CommonModule, CardVendorModule],
  declarations: [CardVendorShowcaseComponent],
})
export class CardVendorShowcaseModule {}
