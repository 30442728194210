import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IbanPipeShowcaseComponent } from './iban-pipe-showcase.component';
import { BbIbanPipeModule } from '@backbase/ui-ang/iban-pipe';

@NgModule({
  imports: [CommonModule, BbIbanPipeModule],
  declarations: [IbanPipeShowcaseComponent],
})
export class IbanPipeShowcaseModule {}
