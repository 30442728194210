import { Component, OnInit, Input } from '@angular/core';

export type BadgeColor = 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'secondary';

/**
 * @name BadgeComponent
 *
 * @description
 * Component that displays a badge.
 */
@Component({
  selector: 'bb-badge-ui',
  templateUrl: './badge.component.html',
})
export class BadgeComponent implements OnInit {
  /**
   * The (background) color of the badge.  Defaults to 'primary'.
   */
  @Input() color: BadgeColor | string = 'primary';
  /**
   * The text to display in the badge.
   */
  @Input() text: string | undefined;
  /**
   * The abbreviation to show when using circle styling.
   */
  @Input() abbr: string | boolean = false;
  /**
   * Whether circle styling should be applied.
   */
  @Input() circle = false;
  /**
   * Whether text wrap styling should be applied.
   */
  @Input() wrap = false;
  /**
   * Accessible label when control does not need to render label tag.
   */
  @Input() ariaLabel: string | undefined;
  /**
   * Whether the text break class will be applied (adjusting to the parent box width)
   */
  @Input() breakWord?: boolean = false;

  ngOnInit() {
    if (typeof this.text === 'undefined') {
      throw new Error(`"text" input is required in "${this.constructor.name}"`);
    }
  }
}
