<div class="overview-list">
  @if (header) {
    <div class="overview-list__header" [innerHtml]="header | safeHtml"></div>
  }
  @for (item of items; track $index) {
    <bb-overview-list-card
      class="overview-list__body-card"
      [name]="item.name"
      [title]="item.title"
      [subtitle]="item.subtitle"
      [imageSrc]="item.imageSrc"
      [linkTo]="item.linkTo"
    ></bb-overview-list-card>
  }
  @if (footer) {
    <div class="overview-list__footer" [innerHtml]="footer | safeHtml"></div>
  }
</div>
