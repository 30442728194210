import { Component, OnInit, Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { ScrollSpyService } from '../../services/scroll-spy.service';

interface SectionItem {
  text: string;
  hash: string;
}

/*
  Usage (inside your page layout component):

  ngOnInit() {
    this.bindSticky();
  }
  bindSticky() {
    this.scrollSpyService.stickyStore
      .subscribe(() => {
        const headerHeight = this._el.nativeElement.querySelector('.dso-page-header').offsetHeight;

        this.isSticky = -(this._el.nativeElement.getBoundingClientRect().top) > headerHeight;
    });
  }
 */
@Component({
  selector: 'bb-mini-menu',
  templateUrl: './mini-menu.component.html',
})
export class MiniMenuComponent implements OnInit {
  fragmentList: Array<SectionItem> = [];
  parentRoute = '';
  childRoute = '';
  grandchildRoute = '';
  fragment: any = '';
  currentSection: string | null = '';
  isSticky = false;

  // A way to hide the mini menu when there are no fragments
  @Input() miniMenuReference!: HTMLDivElement;
  @Input() contentReference!: HTMLDivElement;
  @Input() pageContentHeaders: any;

  constructor(
    private router: Router,
    private scrollSpyService: ScrollSpyService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.bindScroll();
    this.registerMiniMenu();
  }

  selectSection(sectionItem: SectionItem) {
    this.currentSection = sectionItem.hash;
  }

  setMiniMenuData(data: Array<any>) {
    const headerTreeArray: Element[] = data ? Array.from(data) : [];

    this.fragmentList = headerTreeArray
      .filter((htmlNode) => htmlNode.id)
      .map((htmlNode) => ({ hash: htmlNode.id, text: htmlNode.textContent ?? '' }));

    this.currentSection = this.currentSection || this.fragmentList[0].hash;
  }

  // Initializes the starting URL, whether on refresh or re-routes
  registerMiniMenu() {
    // Gets you list of fragments
    this.scrollSpyService.fragmentStore.subscribe(() => {
      this.setMiniMenuData(this.pageContentHeaders);
    });

    // On refreshes, init with the correct section
    this.activatedRoute.fragment.subscribe((event) => {
      this.currentSection = event;
    });
  }

  bindScroll() {
    this.scrollSpyService.scrollStore.subscribe((event: any) => {
      if (event && event.sectionId) {
        this.currentSection = event.sectionId;
      }
    });
  }

  get currentUrl() {
    return this.router.url.split('#')[0]; // removes any hashes from the url
  }
}
