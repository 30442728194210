<div class="row">
  <div class="col-md-6">
    <div id="currency-input-with-decimals-label" class="bb-text-bold form-label">With decimals</div>
    <div [attr.data-role]="'currency-input-with-decimals-allow-negative-true'" class="bb-block--lg">
      <bb-currency-input-ui
        [currencyLabel]="'Currency'"
        [formControl]="withDecimalControl"
        [currencies]="currencies"
        [aria-labelledby]="'currency-input-with-decimals-label'"
        [currencyListAriaLabel]="'Currency'"
        [isRightAligned]="true"
        [allowNegativeValue]="true"
        [required]="true"
      >
        <div bbValidationMessage>
          <bb-input-validation-message-ui [showErrors]="withDecimalControl.invalid">
            Field is required.
          </bb-input-validation-message-ui>
        </div>
      </bb-currency-input-ui>
    </div>

    <div id="currency-input-without-decimals-label" class="bb-text-bold form-label">Without decimals</div>
    <div data-role="currency-input-without-decimals" class="bb-block--lg">
      <bb-currency-input-ui
        [ngModel]="withoutDecimal"
        [integer]="true"
        [currencies]="currencies"
        #withoutDec="ngModel"
        [aria-labelledby]="'currency-input-without-decimals-label'"
      >
        <div bbValidationMessage>
          <bb-input-validation-message-ui [showErrors]="withoutDec.value?.amount === 0 && !withoutDec.untouched">
            Field is required.
          </bb-input-validation-message-ui>
        </div>
      </bb-currency-input-ui>
    </div>

    <div id="currency-input-without-currency-label" class="bb-text-bold form-label">Without currency code</div>
    <div data-role="currency-input-without-currency-label" class="bb-block--lg">
      <bb-currency-input-ui
        [ngModel]="withoutCurrency"
        #withoutCur="ngModel"
        [aria-labelledby]="'currency-input-without-currency-label'"
      >
        <div bbValidationMessage>
          <bb-input-validation-message-ui [showErrors]="withoutCur.value?.amount === 0 && withoutCur.touched">
            Field is required.
          </bb-input-validation-message-ui>
        </div>
      </bb-currency-input-ui>
    </div>

    <div data-role="currency-input-with-decimals-allow-negative-false" class="bb-block--lg">
      <bb-currency-input-ui
        [currencyLabel]="'Currency'"
        [formControl]="withDecimalControl"
        [currencies]="currencies"
        [currencyListAriaLabel]="'Currency'"
        [isRightAligned]="true"
        [integerLabel]="'Integer'"
        [decimalLabel]="'Decimal'"
      >
        <div bbValidationMessage>
          <bb-input-validation-message-ui [showErrors]="withDecimalControl.invalid">
            Field is required. inclusion ...
          </bb-input-validation-message-ui>
        </div>
      </bb-currency-input-ui>
    </div>

    <div id="currency-input-invalid-label" class="bb-text-bold form-label">Invalid value</div>
    <div data-role="currency-input-invalid" class="bb-block--lg">
      <bb-currency-input-ui
        [ngModel]="withDecimal"
        #invalid="ngModel"
        [aria-invalid]="'true'"
        [currencies]="currencies"
        [aria-labelledby]="'currency-input-invalid-label'"
      >
        <div bbValidationMessage>
          <bb-input-validation-message-ui [showErrors]="invalid.value?.amount === 0 && invalid.touched">
            Field is required.
          </bb-input-validation-message-ui>
        </div>
      </bb-currency-input-ui>
    </div>

    <div id="currency-input-disabled" class="bb-text-bold form-label">Disabled field</div>
    <div data-role="currency-input-disabled" class="bb-block--lg">
      <bb-currency-input-ui
        [disabled]="true"
        [ngModel]="withoutDecimal"
        #disabled="ngModel"
        [currencies]="currencies"
        [aria-labelledby]="'currency-input-disabled'"
        [currencyListAriaLabel]="'Currency'"
      >
      </bb-currency-input-ui>
    </div>

    <div id="currency-input-readonly" class="bb-text-bold form-label">Readonly field</div>
    <div data-role="'currency-input-readonly" class="bb-block--lg">
      <bb-currency-input-ui
        [readonly]="true"
        [ngModel]="withDecimal"
        #readonly="ngModel"
        [currencies]="currencies"
        [aria-labelledby]="'currency-input-readonly'"
        [currencyListAriaLabel]="'Currency'"
      >
      </bb-currency-input-ui>
    </div>

    <div id="currency-input-required" class="bb-text-bold form-label">Required field</div>
    <div [attr.data-role]="'currency-input-required'" class="bb-block--lg">
      <bb-currency-input-ui
        [required]="true"
        [ngModel]="withDecimal"
        #required="ngModel"
        [currencies]="currencies"
        [aria-labelledby]="'currency-input-required'"
        [currencyListAriaLabel]="'Currency'"
      >
        <div bbValidationMessage>
          <bb-input-validation-message-ui [showErrors]="!required.value?.amount && required.touched">
            Field is required.
          </bb-input-validation-message-ui>
        </div>
      </bb-currency-input-ui>
    </div>
  </div>
</div>
