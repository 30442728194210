import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectListShowcaseComponent } from './select-list-showcase.component';
import { SelectListModule } from '@backbase/ui-ang/select-list';

@NgModule({
  imports: [CommonModule, SelectListModule, FormsModule, ReactiveFormsModule],
  declarations: [SelectListShowcaseComponent],
})
export class SelectListShowcaseModule {}
