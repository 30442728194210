<div>
  <div class="m-3">
    <button
      bbButton
      color="secondary"
      (click)="triggerProgressTracker('default')"
      [attr.data-role]="'trigger-process-default'"
    >
      Trigger Progress Tracker With Default Templates
    </button>
  </div>

  <bb-progress-tracker *ngIf="show" [eventList]="eventArr" (cancel)="cancelTracker($event)"></bb-progress-tracker>
</div>

<div>
  <div class="m-3">
    <button
      bbButton
      color="secondary"
      (click)="triggerProgressTracker('custom')"
      data-role="trigger-process-custom-templates"
    >
      Trigger Progress Tracker With Customized Templates for all templates
    </button>
  </div>

  <bb-progress-tracker
    *ngIf="showCustomized"
    [eventList]="eventArr"
    (cancel)="cancelTracker($event)"
    [progressTrackerItemTemplate]="customProgressItemTracker"
    [progressTrackerTemplate]="customProgressTracker"
    [progressTrackerHeaderTemplate]="customProgressHeaderTracker"
  >
  </bb-progress-tracker>

  <ng-template
    #customProgressTracker
    let-hostRef
    let-currentEvents="currentEvents"
    let-show="show"
    let-animate="animate"
  >
    <div class="position-relative" *ngIf="currentEvents.length > 0">
      <div class="bb-progress-tracker-item-container px-3" data-role="upload-log">
        <div class="d-flex justify-content-between border-bottom border-secondary py-2">
          <strong class="text-sm" i18n="Progress tracker heading info@@progress-tracker-heading.info.label"
            >Upload file log</strong
          >
          <div
            class="text-body-secondary text-sm"
            i18n="Progress tracker total count label@@progress-tracker.total.count.label"
          >
            {{ currentEvents.length }} total
          </div>
        </div>

        <div *ngFor="let event of currentEvents" class="bb-list">
          <ng-container *ngIf="event && event.payload">
            <div
              *ngIf="event.payload.actionType === eventType.FILE_UPLOAD"
              class="bb-list__item bb-list__item--no-separator"
            >
              <ng-template
                [ngTemplateOutlet]="customProgressItemTracker"
                [ngTemplateOutletContext]="{ eventPayload: event.payload, cancelItem: cancelTracker }"
              >
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template
    #customProgressItemTracker
    let-hostRef="hostRef"
    let-cancelItem="cancelItem"
    let-eventPayload="eventPayload"
  >
    <div class="bb-progress-item py-2">
      <div class="bb-progress-tracker-header d-flex justify-content-between">
        <div class="title">
          <ng-template [ngTemplateOutlet]="customProgressHeaderTracker" [ngTemplateOutletContext]="hostRef">
          </ng-template>
        </div>
        <div class="title-info text-body-secondary">{{ eventPayload.progressStartTime | date : 'shortTime' }}</div>
      </div>
      <div class="bb-progress-tracker-body text-body-secondary">
        <div class="py-1">{{ eventPayload.name }} jkjk</div>
        <div>
          <div class="py-1">
            <ng-container
              i18n="Progress tracker item estimated time left label@@progress-tracker-item.estimated.time.left.label"
            >
              Estimated time left:</ng-container
            >
            <ng-container
              *ngIf="
                eventPayload.eta.hours > 0 || eventPayload.eta.mins > 0 || eventPayload.eta.secs > 0;
                else calculating
              "
            >
              <ng-container
                *ngIf="eventPayload.eta.hours > 0"
                i18n="Progress tracker eta hours@@progress-tracker-item.estimated.time.left.hours"
              >
                {{ eventPayload.eta.hours }} {eventPayload.eta.hours, plural, =1 {hour} other {hours}}
              </ng-container>
              <ng-container
                *ngIf="eventPayload.eta.mins > 0"
                i18n="Progress tracker eta minutes@@progress-tracker-item.estimated.time.left.minutes"
              >
                {{ eventPayload.eta.mins }} {eventPayload.eta.mins, plural, =1 {minute} other {minutes}}
              </ng-container>
              <ng-container
                *ngIf="eventPayload.eta.hours === 0 && eventPayload.eta.mins === 0 && eventPayload.eta.secs > 0"
                i18n="Progress tracker eta seconds@@progress-tracker-item.estimated.time.left.seconds"
              >
                {{ eventPayload.eta.secs }} {eventPayload.eta.secs, plural, =1 {second} other {seconds}}
              </ng-container>
            </ng-container>
            <ng-template #calculating>
              <ng-container
                i18n="Progress tracker calculating eta label@@progress-tracker-item.calculating.estimated.time.left"
              >
                calculating...</ng-container
              >
            </ng-template>
          </div>
          <div class="py-2">
            <bb-progressbar-ui
              [showValue]="false"
              [value]="eventPayload.current"
              [max]="eventPayload.size"
              height="4px"
            >
            </bb-progressbar-ui>
          </div>
          <div class="d-flex flex-row-reverse py-2">
            <button
              bbButton
              color="secondary"
              (click)="cancelItem(eventPayload.itemId)"
              i18n="Progress tracker item cancel button label@@progress-tracker-item.cancel.btn.label"
              data-role="cancel-upload"
            >
              Cancel upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #customProgressHeaderTracker>
    <strong i18n="Progress tracker heading@@progress-tracker.heading">Batch file is uploadingsdsad...</strong>
  </ng-template>
</div>

<div>
  <div class="m-3">
    <button
      bbButton
      color="secondary"
      (click)="triggerProgressTracker('customItem')"
      [attr.data-role]="'trigger-process-item-template'"
    >
      Trigger Progress Tracker With Customized Template for progress tracker item template
    </button>
  </div>

  <bb-progress-tracker
    *ngIf="showCustomizedItemTemplate"
    [eventList]="eventArr"
    (cancel)="cancelTracker($event)"
    [progressTrackerItemTemplate]="customProgressItemTracker1"
  >
  </bb-progress-tracker>

  <ng-template
    #customProgressItemTracker1
    let-hostRef="hostRef"
    let-cancelItem="cancelItem"
    let-eventPayload="eventPayload"
  >
    <div class="bb-progress-item py-2">
      <div class="bb-progress-tracker-header d-flex justify-content-between">
        <div class="title">
          <h2>Event List</h2>
        </div>
        <div class="title-info text-body-secondary">{{ eventPayload.progressStartTime | date : 'shortTime' }}</div>
      </div>
      <div class="bb-progress-tracker-body text-body-secondary">
        <div class="py-1">{{ eventPayload.name }}</div>
        <div>
          <div class="py-1">
            <ng-container
              i18n="Progress tracker item estimated time left label@@progress-tracker-item.estimated.time.left.label"
            >
              Estimated time left:</ng-container
            >
            <ng-container
              *ngIf="
                eventPayload.eta.hours > 0 || eventPayload.eta.mins > 0 || eventPayload.eta.secs > 0;
                else calculating
              "
            >
              <ng-container
                *ngIf="eventPayload.eta.hours > 0"
                i18n="Progress tracker eta hours@@progress-tracker-item.estimated.time.left.hours"
              >
                {{ eventPayload.eta.hours }} {eventPayload.eta.hours, plural, =1 {hour} other {hours}}
              </ng-container>
              <ng-container
                *ngIf="eventPayload.eta.mins > 0"
                i18n="Progress tracker eta minutes@@progress-tracker-item.estimated.time.left.minutes"
              >
                {{ eventPayload.eta.mins }} {eventPayload.eta.mins, plural, =1 {minute} other {minutes}}
              </ng-container>
              <ng-container
                *ngIf="eventPayload.eta.hours === 0 && eventPayload.eta.mins === 0 && eventPayload.eta.secs > 0"
                i18n="Progress tracker eta seconds@@progress-tracker-item.estimated.time.left.seconds"
              >
                {{ eventPayload.eta.secs }} {eventPayload.eta.secs, plural, =1 {second} other {seconds}}
              </ng-container>
            </ng-container>
            <ng-template #calculating>
              <ng-container
                i18n="Progress tracker calculating eta label@@progress-tracker-item.calculating.estimated.time.left"
              >
                calculating...</ng-container
              >
            </ng-template>
          </div>
          <div class="py-2">
            <bb-progressbar-ui
              [showValue]="false"
              [value]="eventPayload.current"
              [max]="eventPayload.size"
              height="4px"
            >
            </bb-progressbar-ui>
          </div>
          <div class="d-flex flex-row-reverse py-2">
            <button
              bbButton
              color="secondary"
              (click)="cancelTracker(eventPayload.itemId)"
              i18n="Progress tracker item cancel button label@@progress-tracker-item.cancel.btn.label"
            >
              Cancel upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
