<div class="paragraph">
<p>Please note that we are using the term <em>Product</em> but you might encounter a similar term in some of our products:  <em>Account,</em> which is used primarily in non-SEPA regions. For consistency we will use the term <em>Product</em> for widgets and components related to this terms.</p>
</div>
<div class="paragraph">
<p>Product Item is a flexible component that presents to the user the basic information of an product, including name and a main balance. Product Items are linked to a Product Kind, which affects the information displayed.</p>
</div>
<div class="sect1">
<h2 id="_anatomy_of_a_product_item">Anatomy of a Product Item</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Product Items show different information depending on the Product Kind they are assigned, however, they follow the same anatomy.<br>
image::product-item-anatomy.png[Product item anatomy]</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Active marker</strong><br>
This is a visual device to help users identify account types at glance and show selected state.</p>
</li>
<li>
<p><strong>Inactive marker (state)</strong><br>
In the instances where the account is not selected, the active marker identifier does not appear.</p>
</li>
<li>
<p><strong>Account Information</strong><br>
The product name and the product number which can be expressed as an IBAN or an Account Number, depending on the requirements.</p>
</li>
<li>
<p><strong>Main Balance</strong><br>
The amount in the account, this refers to total balance. Depending on the Product Kind the product belong to, different types of balances are displayed.</p>
</li>
<li>
<p><strong>Labels for additional balances</strong><br>
For some types of products like Current Accounts and Credit Cards, additional information such as Available Balance and Credit Limit are necessary, these labels describe to the user what the others amounts refer to.</p>
</li>
<li>
<p><strong>Amounts for additional balances</strong><br>
If additional values are shown, these are placed under the balance, in the same line than their corresponding label.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Account items are linked to Product Kinds, which means that depending on which product kind is linked in the back-end, it will present different cards.</p>
</div>
<div class="paragraph">
<p>For more on products, <a href="https://community.backbase.com/documentation/DBS/latest/product_summary_understand">visit Backbase Community.</a></p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Cards are fully responsive, which means they take all the width of the container were they are placed. This can be customised to have a fixed width.</p>
</div>
</div>
</div>