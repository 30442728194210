<div class="paragraph">
<p>A status tracker is used to highlight the stage in a series of events or process, like the arrival of a new credit card. It should contain specific details about past, current and future states with a date and time for each completed step. It provides users a way to check progress at their own convenience, giving them a sense of autonomy and control.</p>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines*</h2>
<div class="sectionbody">
<div class="paragraph">
<p>This component should only be used after the first step in a process has been completed. Status labels should be displayed in chronological order from left to right (horizontal) or top to bottom (vertical). Each step should be automatically distributed evenly within the horizontal/vertical space they are afforded.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_anatomy">Anatomy</h2>
<div class="sectionbody">
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Status</strong><br>
*This displays the stages in a delivery from beginning to end. Each status is composed of an icon and a labela that manage expectations in each step. When a status has been completed, the date and time details are displayed below.</p>
</li>
<li>
<p><strong>Tracker</strong><br>
*This behaves similarly to a <a href="https://designsystem.backbase.com/components/progressbars/design#_anatomy">progress bar wrapper</a>, filling from left to right according to the current status of the process.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Status Labels</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Keep status labels names clear, short and descriptive.</p>
</li>
<li>
<p>Keep Date and time in a <a href="https://designsystem.backbase.com/formatters/date-and-time">short format.</a></p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Status icons</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Choose an icon that represents what will happen in that step.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Steps</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>The component needs a minimum of two steps to be used.</p>
</li>
<li>
<p>When each step is completed the icon and respective title should reflect this change and a label should appear below with specific date and time for that update.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_placement">Placement</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Status trackers are flexible and can be placed in different containers like widgets or modals. Placement is dependent on use case but always center the status tracker on the page or parent container, taking full-width space and close to the related information in the page.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_animation">Animation</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The status tracker animates automatically when users open the page by expanding along the tracker and steps until it reaches the current status.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>This component cannot be interacted with (not clickable).</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_variations">Variations</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Images to illustrate how the different number steps affect the design of the component both in horizontal and vertical layout.</p>
</div>
<div class="paragraph">
<p><strong>Desktop</strong></p>
</div>
<div class="paragraph">
<p><strong>Mobile Responsive</strong></p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>XLarge Screen (above 1220px)</strong></p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don&#8217;ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use when customers need to track a delivery process that will take several days and no immediate result is expected.</p>
</li>
<li>
<p>Show when the first step of the process is already completed</p>
</li>
<li>
<p>Use as a way to reassure users the process is underway</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don&#8217;t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use when there’s no fixed amount of steps</p>
</li>
<li>
<p>Show when you can’t predict how long the process it&#8217;s gonna take</p>
</li>
</ul>
</div>
</div>
</div>