import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { IconModule } from '@backbase/ui-ang/icon';
import { NgSelectModule } from '@ng-select/ng-select';

import { FocusModule } from '@backbase/ui-ang/focus';
import { SearchBoxModule } from '@backbase/ui-ang/search-box';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CountryFlagPipe } from './country-flag-pipe';
import { InputPhoneComponent } from './input-phone.component';

@NgModule({
  imports: [
    NgClass,
    IconModule,
    FormsModule,
    BaseClassesModule,
    FocusModule,
    NgSelectModule,
    SearchBoxModule,
    NgxMaskDirective,
  ],
  declarations: [InputPhoneComponent, CountryFlagPipe],
  exports: [InputPhoneComponent],
  providers: [provideNgxMask()],
})
export class InputPhoneModule {}
