<div class="btn-group bb-button-bar__button">
  @if (defaultMenuItem) {
    <button
      class="bb-button-bar__button"
      bbButton
      [color]="buttonColor"
      [buttonSize]="buttonSize"
      (bbKeyboardClick)="onClick($event, defaultMenuItem.value)"
    >
      @if (defaultMenuItem.iconName) {
        <bb-icon-ui [name]="defaultMenuItem.iconName"></bb-icon-ui>
      }
      <span>{{ defaultMenuItem.label }}</span>
    </button>
  }
  @if (menu.length > 1) {
    <bb-dropdown-menu-ui
      container="body"
      icon="caret-down"
      [btnColor]="buttonColor"
      [buttonSize]="buttonSize"
      [position]="position"
      [dropDownButtonLabel]="dropDownButtonLabel"
      [class.btn-group]="useFirstLinkAsDefault"
    >
      <ng-template bbDropdownLabel>
        @if (!useFirstLinkAsDefault) {
          <span class="d-flex align-items-center">
            @if (buttonIcon) {
              <bb-icon-ui [name]="buttonIcon"></bb-icon-ui>
            }
            <span>{{ buttonLabel }}</span>
          </span>
        }
      </ng-template>
      <ng-template bbDropdownMenuItem>
        @for (item of menu; track $index) {
          @if (item !== defaultMenuItem) {
            @if (item.isSeparator) {
              <div class="dropdown-divider"></div>
            } @else {
              <a rel="noopener" role="menuitem" href="#" class="dropdown-item" (click)="onClick($event, item.value)">
                <span class="bb-stack">
                  @if (item.iconName) {
                    <bb-icon-ui
                      class="bb-stack__item bb-stack__item--align-top"
                      [name]="item.iconName ? item.iconName : undefined"
                      color="dark"
                    ></bb-icon-ui>
                  }
                  <div class="bb-stack__item">
                    <span>{{ item.label }}</span>
                    @if (item.description) {
                      <div class="bb-subtitle bb-text-support me-4">
                        {{ item.description }}
                      </div>
                    }
                  </div>
                </span>
              </a>
            }
          }
        }
      </ng-template>
    </bb-dropdown-menu-ui>
  }
</div>
