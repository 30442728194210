@if (label) {
  <label for="{{ id }}" class="form-label">{{ label }}</label>
}
<input
  id="{{ id }}"
  #inputEmail
  [attr.aria-autocomplete]="ariaAutocomplete"
  [attr.aria-controls]="ariaControls"
  [attr.aria-label]="(!label && ariaLabel) || null"
  [attr.aria-describedby]="ariaDescribedby"
  [attr.aria-labelledby]="ariaLabelledby"
  [attr.aria-expanded]="ariaExpanded"
  [attr.aria-activedescendant]="ariaActivedescendant"
  [attr.role]="role"
  [attr.aria-invalid]="ariaInvalid"
  [attr.aria-owns]="ariaOwns"
  [ngModel]="value"
  (ngModelChange)="onValueChange($event)"
  (blur)="onBlur()"
  type="email"
  inputmode="email"
  class="form-control"
  (focus)="onFocus()"
  [disabled]="disabled"
  [readOnly]="readonly"
  [placeholder]="placeholder"
  [required]="required"
  [bbFocus]="autofocus"
  [attr.maxlength]="maxLength"
  [attr.minlength]="minLength"
  [attr.autocomplete]="autocomplete"
/>
