import { AsyncPipe, KeyValuePipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';
import { ModalModule } from '@backbase/ui-ang/modal';
import { IconModule } from '@backbase/ui-ang/icon';
import { ButtonModule } from '@backbase/ui-ang/button';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

import { LocaleSelectorComponent, LocaleSelectorLabelDirective } from './locale-selector.component';

@NgModule({
  declarations: [LocaleSelectorComponent, LocaleSelectorLabelDirective],
  imports: [
    NgClass,
    NgTemplateOutlet,
    KeyValuePipe,
    AsyncPipe,
    DropdownMenuModule,
    ModalModule,
    IconModule,
    ButtonModule,
    KeyboardClickModule,
  ],
  exports: [LocaleSelectorComponent, LocaleSelectorLabelDirective],
})
export class LocaleSelectorModule {}
