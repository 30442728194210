<div class="paragraph">
<p>Notifications are messages that communicate information to the user, indicate an event, or show responses to user actions.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Notifications are used to convey information or actions that aren’t critical.</p>
</li>
<li>
<p>They appear at the top right of the screen and overlay any content.</p>
</li>
<li>
<p>They can always be dismissed.</p>
</li>
<li>
<p>They can contain an icon, title, content, and/or actions. Configuration depends on each use case.</p>
</li>
<li>
<p>The notification disappears automatically or is dismissed by the user.</p>
</li>
<li>
<p>The time interval for auto-closing the notification can be set individually for each severity level (Success, Information, Alert and Warning).</p>
</li>
</ul>
</div>
<div class="sect1">
<h2 id="_anatomy_of_a_notification">Anatomy of a notification</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Notifications have been designed to make it easier for users to scan information quickly.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/notification-anatomy.png" alt="Notification anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Priority Level Indicator</strong><br>
An icon appears on the left side of a notification to provide context in a meaningful way.</p>
</li>
<li>
<p><strong>Content Title</strong><br>
A brief headline for the notification. Content title is usually only needed if an app sends notifications from multiple sources, such as the account name for users with multiple accounts.</p>
</li>
<li>
<p><strong>Content Text</strong> (optional)<br>
Supporting information. Use regular text for the message body and keep messages short. If the text overflows, the message body will be truncated with an ellipsis.</p>
</li>
<li>
<p><strong>Dismiss button</strong><br>
A user may dismiss a notification by clicking a <strong>X</strong> icon button, placed in the top right of the notification container.</p>
</li>
<li>
<p><strong>Container</strong><br>
Notification container has a fixed size and styles. Placement should appear on the top-right corner.</p>
</li>
<li>
<p><strong>Primary Action</strong> (optional)<br>
A primary action can be presented as a button, to enable users to act on the notification content.</p>
</li>
<li>
<p><strong>Secondary Action</strong> (optional)<br>
If necessary, also a secondary action can be included. Use scarcely</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_notification_types">Notification Types</h2>
<div class="sectionbody">
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/notification-types.png" alt="Notification Types">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_placement">Placement</h2>
<div class="sectionbody">
<div class="paragraph">
<p>By default, the Notification appears on the top-right corner of the screen.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/notification-placement.png" alt="Notification placement - Top Right Corner">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_notification_states">Notification states</h3>
<div class="paragraph">
<p>We allow our system to assign a severity level to each notification to influence how the application will deliver it. Severity levels should be in the interface represented by icons.</p>
</div>
<div class="paragraph">
<p>The appearance of icons changes depending on the current state. This visual indicator makes it easier for users to interpret the message.</p>
</div>
<div class="paragraph">
<p>The appearance of icons changes depending on the current state. This visual indicator makes it easier for users to interpret the message.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/notification-states.png" alt="Severity Icons">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Success</strong><br>
Informs the recipient that an action is successful. This is a positive announcement that does not require any action.</p>
</li>
<li>
<p><strong>Info</strong><br>
Gives the recipient neutral information unrelated to their previous actions.</p>
</li>
<li>
<p><strong>Error</strong><br>
Tells the recipient something they need to do. This may require an action or decision to prevent undesirable consequences.</p>
</li>
<li>
<p><strong>Warning</strong><br>
Tells the recipient something they need to do. This may require an action or decision to prevent undesirable consequences.</p>
</li>
<li>
<p><strong>Generic</strong><br>
Use different icons to convey different meaning, <em>e.g. piggy bank icon in a budget notification.</em></p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Multiple Notifications</strong><br>
Whenever more than one notification needs to be presented to the user, each notification message is stacked on top of each other in the order in which they were triggered.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/notification-multiple.png" alt="Multiple notifications of the same type" width="stack">
</div>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/notification-multiple-different.png" alt="Multiple notifications of different types" width="are grouped">
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don&#8217;ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When working with notifications, follow this recommendations to keep a consistent experience.</p>
</div>
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>You want to draw attention to important updates or messages.</p>
</li>
<li>
<p>You want to notify users about about time-sensitive information directed specifically at them.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don&#8217;t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>The notification is used to users about information that is currently on screen (e.g. active chat conversations)</p>
</li>
<li>
<p>The notification is used to inform users about technical operations that do not require user involvement (such as syncing)</p>
</li>
<li>
<p>You want to notify users about error messages that can be resolved without user action.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_interaction">Interaction</h2>
<div class="sectionbody">

</div>
</div>
<div class="sect1">
<h2 id="_behavior">Behavior</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Notifications normally appear and, after certain time, they self-dismiss. If the user hovered on the notification then the animation is cancelled and the user will have to click on the close icon <strong>X</strong> to dismiss the notification manually.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/notification-behavior1.gif" alt="A notification appearing and self-dismissing">
</div>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/notification-behavior2.gif" alt="A notification manually dismissed">
</div>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/notification-behavior3.gif" alt="Notifications stacking">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>On small devices, notifications appear at the top of the page and take full width.</p>
</div>
<div class="paragraph img-fluid">
<p><span class="image"><img src="assets/images/notification-responsiveness.png" alt="Notification on mobile"></span></p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="paragraph">
<p>There are no specific accessibility guidelines for this component besides general compliance as specified in our <a href="/accessibility/">accessibility page</a>.</p>
</div>
</div>
</div>