<div class="row">
  <div class="col-md-6">
    <form [formGroup]="vForm">
      <div class="row bb-input-date-divided-format-mm-dd-yy" [attr.data-role]="'date-divided-default'">
        <label for="inputDateDivided">
          Date Divided field <span class="bb-text-support bb-text-default">(format: 'MM-DD-YYYY')</span>
        </label>
        <bb-input-date-divided
          bbFormControl
          format="MM-DD-YYYY"
          id="data-divided-1"
          formControlName="inputDateDivided"
          [required]="true"
          [errorLabels]="errorLabels1"
        ></bb-input-date-divided>
      </div>

      <div class="row mt-5 bb-input-date-divided-format-dd-mm-yy" [attr.data-role]="'date-divided-default'">
        <label for="inputDateDivided">
          Date Divided field <span class="bb-text-support bb-text-default">(format: 'DD-MM-YYYY')</span>
        </label>
        <bb-input-date-divided
          bbFormControl
          format="DD-MM-YYYY"
          id="data-divided-2"
          formControlName="inputDateDivided1"
          required="true"
          [errorLabels]="errorLabels1"
        ></bb-input-date-divided>
      </div>

      <div class="row mt-5 bb-input-date-divided-format-locale" [attr.data-role]="'date-divided-default'">
        <label for="inputDateDivided">
          Date Divided field <span class="bb-text-support bb-text-default">(As per Locale)</span>
        </label>
        <bb-input-date-divided
          bbFormControl
          required="true"
          id="data-divided-3"
          formControlName="inputDateDivided2"
          [errorLabels]="errorLabels1"
        ></bb-input-date-divided>
      </div>
    </form>
  </div>
</div>
