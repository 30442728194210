<table
  [bbTable]="countries$ | async"
  bbTableFocus
  (rowClick)="onRowClick($event)"
  (sort)="onSort($event)"
  class="table table-hover"
>
  <thead>
    <tr>
      <th
        [attr.aria-sort]="getAriaSort('name')"
        [bbSortable]="'name'"
        scope="col"
        tabindex="0"
      >
        Country
      </th>
      <th
        [attr.aria-sort]="getAriaSort('area')"
        [bbSortable]="'area'"
        scope="col"
        tabindex="0"
      >
        Area (km²)
      </th>
      <th
        [attr.aria-sort]="getAriaSort('population')"
        [bbSortable]="'population'"
        scope="col"
        tabindex="0"
      >
        Population
      </th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    @for (country of countries$ | async; track $index) {
      <tr [bbRow]="country">
        <td>
          {{ country.name }}
        </td>
        <td>
          {{ country.area | number }}
        </td>
        <td>
          {{ country.population | number }}
        </td>
        <td>
          <img
            [alt]="'Flag of ' + country.name"
            [src]="pathFlagAssets + country.flag"
            width="32"
          />
        </td>
      </tr>
    }
  </tbody>
</table>

<bb-pagination-ui
  (pageChange)="paginator.onPageChange($event)"
  [boundaryLinks]="paginator.boundaryLinks"
  [directionLinks]="paginator.directionLinks"
  [itemsPerPage]="paginator.itemsPerPage"
  [maxNavPages]="paginator.maxNavPages"
  [page]="paginator.page"
  [totalItems]="paginator.totalItems"
>
</bb-pagination-ui>
