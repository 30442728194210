import {
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  EventEmitter,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { StepperStepComponent } from './stepper-step/stepper-step.component';

@Directive({ selector: 'ng-template[bbCustomStepperStep]' })
export class CustomStepperStepDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name StepperComponent
 *
 * @description
 * Component that represents a navigation stepper.
 */
@Component({
  selector: 'bb-stepper-ui',
  templateUrl: './stepper.component.html',
})
export class StepperComponent {
  /**
   * Represents stepper's steps
   */
  @ContentChildren(StepperStepComponent) steps: QueryList<StepperStepComponent> | undefined;

  /**
   * Represents stepper step's custom template
   */
  @ContentChild(CustomStepperStepDirective, { read: TemplateRef, static: true })
  stepperStepTemplate: TemplateRef<any> | undefined;

  /**
   * Emits an event when a step's link is clicked.
   */
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Returns the current selected step
   */
  get currentStep(): StepperStepComponent | undefined {
    return this.steps && this.steps.find((step) => step.isCurrent);
  }

  onClickHandler(data: any) {
    this.select.emit(data);
  }
}
