import { Component } from '@angular/core';
import { AccountData } from './account-selector.example.model';
import { accounts } from './accounts';

@Component({
  selector: 'bb-account-selector-example-ui',
  templateUrl: './account-selector.example.component.html',
})
export class AccountSelectorExampleComponent {
  items: AccountData[] = accounts;
  selectedItems: AccountData | AccountData[] = this.items[0];

  onChange($event: AccountData | AccountData[]) {
    const count = Array.isArray($event)
      ? $event.length
      : $event
      ? 1
      : 0;

    console.log(`${count} items selected`);
  }

  onSearch($event: string) {
    console.log(`Search for query: ${$event}`);
  }

  onClearSearch() {
    console.log('Search cleared');
  }

  onScrollToEnd() {
    console.log('Scrolled to end');
  }
}
