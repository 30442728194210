<time
  class="custom-day text-center bb-input-datepicker__custom-day"
  [ngClass]="{
    'is-range-selected': isWithinSelectedRange,
    'is-faded': isWithinRangeHover,
    'is-faded-from-date': isWithinRangeHover && !toDateDatepicker && splitRange,
    'is-hovered': isHovered,
    'text-muted': isTextMuted,
    'from-date': isFrom,
    'to-date': isTo
  }"
  [attr.data-role]="'calendar-date-item'"
  [attr.aria-disabled]="disabled"
  [dateTime]="formattedDate"
>
  {{ date?.day }}
</time>
