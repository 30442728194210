<div class="api-body table-responsive">
  <table bbtablefocus class="table table-hover">
    <thead>
      <tr>
        <th>Property</th>
        <th>Type</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      @for (prop of props; track $index) {
        <tr>
          <td>
            {{ prop.input ? '@Input' : '@Output' }}
            <p>{{ prop.name }}</p>
          </td>
          <td>
            <span class="property-type-label">
              <code>
                <a class="code-anchor">{{ prop.type || parseDefaultValue(prop.defaultValue) }}</a>
              </code>
            </span>
          </td>
          <td [innerHtml]="prop.description"></td>
        </tr>
      }
    </tbody>
  </table>
</div>
