<div class="bb-block bb-block--lg">
  <h4>Single character</h4>
  <div class="bb-stack">
    @for (badgeType of badgeShowCaseSetup; track $index) {
      <div class="bb-stack__item bb-block bb-block--lg">
        <bb-badge-ui
          color="{{ badgeType.type }}"
          text="{{ badgeType.text.single }}"
          [attr.data-role]="'badge-' + badgeType.type + '-single'"
        >
        </bb-badge-ui>
      </div>
    }
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>Double character</h4>
  <div class="bb-stack">
    @for (badgeType of badgeShowCaseSetup; track $index) {
      <div class="bb-stack__item bb-block bb-block--lg">
        <bb-badge-ui
          color="{{ badgeType.type }}"
          text="{{ badgeType.text.double }}"
          [attr.data-role]="'badge-' + badgeType.type + '-double'"
        >
        </bb-badge-ui>
      </div>
    }
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>Text</h4>
  <div class="bb-stack">
    @for (badgeType of badgeShowCaseSetup; track $index) {
      <div class="bb-stack__item bb-block bb-block--lg">
        <bb-badge-ui
          color="{{ badgeType.type }}"
          text="{{ badgeType.type | titlecase }}"
          [attr.data-role]="'badge-' + badgeType.type + '-text'"
        >
        </bb-badge-ui>
      </div>
    }
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>Abbreviation</h4>
  <div class="bb-stack">
    @for (badgeType of badgeShowCaseSetup; track $index) {
      <div class="bb-stack__item bb-block bb-block--lg">
        <bb-badge-ui
          color="{{ badgeType.type }}"
          text="{{ badgeType.type | titlecase }}"
          ariaLabel="{{ badgeType.type }}"
          [abbr]="true"
          [attr.data-role]="'badge-' + badgeType.type + '-abbr'"
        >
        </bb-badge-ui>
      </div>
    }
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>Text break</h4>
  <div class="bb-stack">
    @for (badgeType of badgeShowCaseSetup; track $index) {
      <div class="bb-stack__item bb-block bb-block--lg" [ngStyle]="{ width: badgeType.customParentBoxWidth }">
        <bb-badge-ui
          color="{{ badgeType.type }}"
          text="{{ badgeType.text.double }} {{ badgeType.text.single }} {{ badgeType.type | titlecase }}"
          [attr.data-role]="'badge-' + badgeType.type + '-single'"
          [breakWord]="true"
        >
        </bb-badge-ui>
      </div>
    }
  </div>
</div>
