import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableShowcaseComponent } from './table-showcase.component';
import { TableModule } from '@backbase/ui-ang/table';
import { PaginationModule } from '@backbase/ui-ang/pagination';
import { BadgeModule } from '@backbase/ui-ang/badge';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';
import { IconModule } from '@backbase/ui-ang/icon';
import { ButtonModule } from '@backbase/ui-ang/button';
import { SwitchModule } from '@backbase/ui-ang/switch';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    IconModule,
    DropdownMenuModule,
    PaginationModule,
    BadgeModule,
    ButtonModule,
    SwitchModule,
  ],
  declarations: [TableShowcaseComponent],
})
export class TableShowcaseModule {}
