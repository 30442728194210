<div class="bb-block bb-block--lg">
  <h2>Max width 100px:</h2>
  <div [style.width.px]="100">
    <bb-ellipsis-ui [text]="textToEllipse"></bb-ellipsis-ui>
  </div>
</div>
<div class="bb-block bb-block--lg">
  <h2>Max width 200px:</h2>
  <div [style.width.px]="200">
    <bb-ellipsis-ui [text]="textToEllipse" [tooltipTriggers]="'hover'"></bb-ellipsis-ui>
  </div>
</div>
<div class="bb-block bb-block--lg">
  <h2>Line clamp (Truncating Multiple Line Text)</h2>
  <div [style.width.px]="200">
    <bb-ellipsis-ui
      [text]="
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam eligendi iusto aliquid quaerat maxime totam, tenetur unde aut illum minus?'
      "
      [tooltipTriggers]="'hover'"
      [lineClamp]="3"
    ></bb-ellipsis-ui>
  </div>
</div>
<div class="bb-block bb-block--lg">
  <h2>Custom html template via ng-content</h2>
  <div [style.width.px]="200">
    <bb-ellipsis-ui [lineClamp]="3">
      <span
        >Lorem <a href="#">ipsum</a> dolor, sit amet <strong>consectetur adipisicing elit.</strong> Perspiciatis nam
        <strong>otam, vel accusantium aspernatu</strong> quisquam vero cupiditate architecto laudantium molestias.</span
      >
    </bb-ellipsis-ui>
  </div>
</div>
<div class="bb-block bb-block--lg">
  <h2>Checking content truncation</h2>
  <div [style.width.px]="200">
    <bb-ellipsis-ui [lineClamp]="2">
      <div bbIsContentTruncated [hostRef]="hostRef">
        <span
          >Lorem <a href="#">ipsum</a> dolor, sit amet <strong>consectetur adipisicing elit.</strong> Perspiciatis nam
          <strong>otam, vel accusantium aspernatu</strong> quisquam vero cupiditate architecto laudantium
          molestias.</span
        >
      </div>
    </bb-ellipsis-ui>
  </div>
  @if (isMessageTruncated) {
    <button class="btn btn-link-text bb-text-bold">See more</button>
  }
</div>
