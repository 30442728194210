<form [formGroup]="vForm">
  <div class="bb-block bb-block--xl">
    <p>
      <bb-rich-text-editor-ui
        #richTextEditor
        [id]="'modelMessage'"
        label="Message (rich text editor)"
        formControlName="modelMessage"
        placeholder="Please, enter your message"
        [maxLength]="300"
        [readonly]="false"
        [required]="true"
        [aria-describedby]="'a4678b54-18f3-46eb-9f36-542093e5ece2'"
        [attr.data-role]="'rich-text-editor'"
      ></bb-rich-text-editor-ui>
    </p>
    <p id="a4678b54-18f3-46eb-9f36-542093e5ece2">
      {{ richTextEditor.maxLength }} characters is the maximum, but the shorter the better.
    </p>
  </div>
  <div class="bb-block bb-block--xl">
    <h4>Disabled editor</h4>
    <p>
      <bb-rich-text-editor-ui
        [id]="'modelMessage2'"
        label="Message"
        formControlName="modelMessage2"
        placeholder="Please, enter your message"
        [maxLength]="300"
        [disabled]="true"
        [attr.data-role]="'rich-text-editor-disabled'"
      ></bb-rich-text-editor-ui>
    </p>
  </div>
</form>
