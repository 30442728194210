import { InjectionToken } from '@angular/core';

export interface InputDatepickerConfig {
  autocomplete?: string;
  displayMonths?: number;
  firstDayOfWeek?: number;
  overrideDateFormat?: string;
  placeholder?: string;
  rangeSelection?: boolean;
  mask?: any;
}

export const INPUT_DATEPICKER_CONFIG_TOKEN = new InjectionToken<InputDatepickerConfig>('INPUT_DATEPICKER_CONFIG_TOKEN');
