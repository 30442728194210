<div class="dso" [class.dso--with-overlay]="isMenuOpened$ | async">
  <bb-header></bb-header>

  <div class="dso__body" bbScrollSpy>
    <div
      class="dso__side"
      [class.dso__side--expanded]="isMenuOpened$ | async"
      [class.dso__side--hidden]="isMenuClosed$ | async"
    >
      @if ((layoutService.isHighResolutionView$ | async) === false) {
        <bb-side-menu></bb-side-menu>
      }
    </div>

    <router-outlet></router-outlet>
  </div>

  <bb-footer></bb-footer>
</div>

<bb-modal-ui
  [isOpen]="cookieDialog"
  (confirm)="saveAccept()"
  [aria-describedby]="'ariaDescribedById'"
  [aria-labelledby]="'ariaLabelledByID'"
>
  <bb-modal-header-ui title="Cookies on Backbase"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      This website uses cookies to enhance your browsing experience, analyze the traffic, and for marketing purposes.
      All Google Analytics cookies are anonymized. By clicking 'Accept' you accept the use of all cookies as described
      in our <a href="https://www.backbase.com/privacy-policy">privacy-statement</a>.
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Accept" (confirm)="saveAccept()"> </bb-modal-footer-ui>
</bb-modal-ui>
