import { NgModule } from '@angular/core';
import { AccountNumberPipeModule } from '@backbase/ui-ang/account-number-pipe';
import { IconModule } from '@backbase/ui-ang/icon';
import { TooltipModule } from '@backbase/ui-ang/tooltip-directive';
import { ButtonModule } from '@backbase/ui-ang/button';
import { AccountNumberComponent } from './account-number.component';

/**
 * Module containing AccountNumberComponent.
 *
 * The following component are included in this module:
 * - `AccountNumberComponent`
 *
 * @see AccountNumberComponent
 *
 *
 * The following components are exported from this module:
 *
 * - `AccountNumberComponent`
 *
 * @see AccountNumberComponent
 *
 * @usageNotes
 *
 * ### Create module that will import `AccountNumberModule`
 *
 * ```typescript
 * @NgModule({
 *   imports: [
 *    AccountNumberModule,
 *   ],
 *   ...
 * })
 * export class MyModule {}
 * ```
 *
 */
@NgModule({
  declarations: [AccountNumberComponent],
  imports: [AccountNumberPipeModule, TooltipModule, ButtonModule, IconModule],
  exports: [AccountNumberComponent],
})
export class AccountNumberModule {}
