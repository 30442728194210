import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmountInputShowcaseComponent } from './amount-input-showcase.component';
import { AmountInputModule } from '@backbase/ui-ang/amount-input';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, AmountInputModule, ReactiveFormsModule],
  declarations: [AmountInputShowcaseComponent],
})
export class AmountInputShowcaseModule {}
