import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputRadioGroupShowcaseComponent } from './input-radio-group-showcase.component';
import { InputRadioGroupModule } from '@backbase/ui-ang/input-radio-group';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';

@NgModule({
  imports: [CommonModule, InputRadioGroupModule, ReactiveFormsModule, FormsModule, InputValidationMessageModule],
  declarations: [InputRadioGroupShowcaseComponent],
})
export class InputRadioGroupShowcaseModule {}
