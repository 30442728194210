<div id="ariaDescribedById">
  Confirm Changing the color By Clicking on the button
</div>
<button
  #button
  bbButton
  type="button"
  [color]="buttonColor"
  (click)="openDialog()"
  id="ariaLabelledByID"
>
  Click here to change the color!
</button>
<bb-modal-ui
  [isOpen]="showDialog"
  (cancel)="closeDialog()"
  (confirm)="onConfirmDialog()"
  [aria-describedby]="'ariaDescribedById'"
  [aria-labelledby]="'ariaLabelledByID'"
>
  <bb-modal-header-ui
    title="Confirm changing the color"
  ></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      Are you sure you want to change the color?
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="I Agree" (confirm)="closeDialog()">
  </bb-modal-footer-ui>
</bb-modal-ui>
