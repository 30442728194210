<div class="bb-block bb-block--xl">
  <h2>Default</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Vertical</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [vertical]="true"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Small card <small class="bb-text-support">(No content will be rendered)</small></h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    size="sm"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Vertical small card</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [vertical]="true"
    [size]="'sm'"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Partial info card</h2>
  <bb-payment-card-ui [paymentCard]="partialPaymentCard"></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Empty card</h2>
  <bb-payment-card-ui></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>No emblem card</h2>
  <bb-payment-card-ui [paymentCard]="paymentCard" [displayEmblem]="false"></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>With very long name</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCardWithLongName"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>With different background <small class="bb-text-support">(controlled by card name)</small></h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCardWithBackgroundImage"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>With state overlay, icon, and badge</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [displayOverlay]="true"
    overlayIcon="not-interested"
    overlayText="Deactivated"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Vertical card with state overlay, icon, and badge</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [displayOverlay]="true"
    overlayIcon="not-interested"
    overlayText="Deactivated"
    vertical="true"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Small card with overlay and icon <small class="bb-text-support">(No badge will be shown)</small></h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [displayOverlay]="true"
    overlayIcon="not-interested"
    overlayText="Deactivated"
    size="sm"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Vertical small card with overlay and icon <small class="bb-text-support">(No badge will be shown)</small></h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [displayOverlay]="true"
    overlayIcon="not-interested"
    overlayText="Deactivated"
    size="sm"
    vertical="true"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>With state overlay, and icon</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [displayOverlay]="true"
    overlayIcon="not-interested"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>With state overlay, and badge</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [displayOverlay]="true"
    overlayText="Deactivated"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>With state overlay</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [displayOverlay]="true"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Small card with state overlay</h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCard"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [displayOverlay]="true"
    size="sm"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>With Custom background image <small class="bb-text-support">(provided by "imageSrc" input)</small></h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCardWithBackgroundImage"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [imageSrc]="'assets/images/bb-payment-card-ui/payment-card-gold.svg'"
  ></bb-payment-card-ui>
</div>

<div class="bb-block bb-block--xl">
  <h2>Vertical with Custom background image <small class="bb-text-support">(provided by "imageSrc" input)</small></h2>
  <bb-payment-card-ui
    [paymentCard]="paymentCardWithBackgroundImage"
    [paymentCardNumberFormat]="paymentCardNumberFormat"
    [imageSrc]="'assets/images/bb-payment-card-ui/payment-card-gold.svg'"
    vertical="true"
  ></bb-payment-card-ui>
</div>
