import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeShowcaseComponent } from './badge-showcase.component';
import { BadgeModule } from '@backbase/ui-ang/badge';

@NgModule({
  imports: [CommonModule, BadgeModule],
  declarations: [BadgeShowcaseComponent],
})
export class BadgeShowcaseModule {}
