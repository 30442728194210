import { NgModule } from '@angular/core';

import { CharCounterComponent } from './char-counter.component';

/**
 * @name CharCounterModule
 *
 * @deprecated Deprecated in ui-ang@11. To be made into a private module which is used in `bb-textarea-ui` and `bb-rich-text-editor-ui` in ui-ang@13.
 */
@NgModule({
  declarations: [CharCounterComponent],
  exports: [CharCounterComponent],
})
export class CharCounterModule {}
