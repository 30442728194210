import { Inject, Injectable, Optional } from '@angular/core';
import { BB_TIMEZONE_CONFIG_TOKEN } from './constants';

/**
 * @internal
 * A static locale of 'en-US' which is independent of the user's runtime locale or timezone's locale.
 */
const staticLocaleForDateTimeFormatting = 'en-US';
/**
 * Service responsible for handling date transformations between configured timezone and user's system timezone.
 * Timezone can be configured using BB_TIMEZONE_CONFIG_TOKEN
 */
@Injectable({
  providedIn: 'root',
})
export class DateTimezoneTransformationService {
  /**
   * The constructor function takes in the configured timezone as parameter
   *
   * @param configuredTimeZone - The timezone that the user has configured in the application.
   */
  constructor(@Optional() @Inject(BB_TIMEZONE_CONFIG_TOKEN) private _configuredTimeZone: string) {}

  /**
   *
   * It returns the time zone of the user's computer
   *
   * @returns The time zone of the user's computer.
   */
  get userTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * This function returns the value of the configuredTimeZone property.
   *
   * @returns The configuredTimeZone property.
   */
  get configuredTimeZone(): string {
    return this._configuredTimeZone;
  }

  /**
   * It converts the passed date to a date as in the configured time zone
   * The configured time zone is fetched from the value of the BB_TIMEZONE_CONFIG_TOKEN
   * If time zone isn't specified or is invalid, returns the `inputDate` as-is.
   *
   * @param inputDate - The date you want to convert.
   * @returns A date object with date time as in the configured time zone.
   */
  convertToDateInConfiguredTimeZone(inputDate: Date): Date {
    return this.convertDateToDateInTimezone(inputDate, this._configuredTimeZone);
  }

  /**
   * Gets the current date as in the configured time zone
   *
   * @returns The current date as in the configured time zone.
   */
  getCurrentDateInConfiguredTimeZone(): Date {
    return this.convertDateToDateInTimezone(new Date(), this._configuredTimeZone);
  }

  /**
   * This function returns the current date in the user's timezone.
   *
   * @returns The current date in the user's timezone.
   */
  getCurrentDateInUserTimezone(): Date {
    return new Date();
  }

  /**
   * @internal
   * It converts the passed date to a date as in the passed time zone.
   * It uses a static locale of 'en-US' to create a date time string in a format,
   * that is supported by the new Date() constructor
   *
   * @returns A date object with date time as in the passed time zone.
   */
  private convertDateToDateInTimezone(date: Date, timeZone: string): Date {
    if (!date || !timeZone || !this.isValidTimeZone(timeZone)) {
      return date;
    }

    return new Date(
      date.toLocaleString(staticLocaleForDateTimeFormatting, {
        timeZone,
      }),
    );
  }

  /**
   * @internal
   */
  private isValidTimeZone(timeZone: string): boolean {
    try {
      Intl.DateTimeFormat(undefined, { timeZone });

      return true;
    } catch (ex) {
      throw new Error(`Invalid timezone configuration specified: ${ex}`);
    }
  }
}
