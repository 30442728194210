<div class="nav nav-tabs" role="tablist">
  @for (item of items; track $index) {
    <div class="nav-item">
      <button
        class="nav-link"
        role="tab"
        [disabled]="item.disabled"
        [ngClass]="{ active: $index === activeIndex }"
        [attr.aria-selected]="$index === activeIndex"
        (click)="onClick($event, $index)"
        (keydown)="onKeyDown($event, $index)"
        [tabindex]="item.disabled ? -1 : 0"
      >
        <ng-container *ngTemplateOutlet="item.template"></ng-container>
      </button>
    </div>
  }
</div>
