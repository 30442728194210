import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemDebitCardShowcaseComponent } from './product-item-debit-card-showcase.component';
import { ProductItemDebitCardModule } from '@backbase/ui-ang/product-item-debit-card';

@NgModule({
  imports: [CommonModule, ProductItemDebitCardModule],
  declarations: [ProductItemDebitCardShowcaseComponent],
})
export class ProductItemDebitCardShowcaseModule {}
