@if (deprecated) {
  <bb-alert-ui [title]="'Deprecated: ' + deprecated" modifier="warning"></bb-alert-ui>
}

<div id="_import" class="showcase-title">
  <span class="showcase-title__text">Import statement</span>
</div>
<div class="title-padding">
  <bb-showcase-usage [moduleName]="moduleName"></bb-showcase-usage>
</div>
<h2 id="_example_usage" class="showcase-title">Example Usage:</h2>
<div class="example-nav">
  <ul class="nav nav-tabs title-padding">
    <li class="nav-item">
      <a (click)="onTabSelect(0)" [class.active]="activeTab === 0" class="nav-link" href="javascript:void(0)"
        >Component</a
      >
    </li>
    <li class="nav-item">
      <a (click)="onTabSelect(1)" [class.active]="activeTab === 1" class="nav-link" href="javascript:void(0)">HTML</a>
    </li>
    <li class="nav-item">
      <a (click)="onTabSelect(2)" [class.active]="activeTab === 2" class="nav-link" href="javascript:void(0)">TS</a>
    </li>
  </ul>
</div>
<div class="example-usage">
  <div [hidden]="activeTab !== 0"><ng-template #exampleHost></ng-template></div>
  <bb-showcase-example-template [exampleTemplate]="componentTemplate" [hidden]="activeTab !== 1">
  </bb-showcase-example-template>
  <bb-showcase-example-template [hidden]="activeTab !== 2" [exampleTemplate]="exampleSourceCode" [language]="'js'">
  </bb-showcase-example-template>
</div>
<h2 id="_interactive-demo" class="showcase-title">Interactive demo:</h2>
<div class="showcase-interactive-demo"><ng-template #showcaseHost></ng-template></div>
@if (description && description.length > 0) {
  <h2 id="_description" class="showcase-title">
    Description
    <a aria-hidden="true" class="header-link" title="Link to this heading"><i class="material-icons">link</i> </a>
  </h2>
  <bb-showcase-description [description]="description"></bb-showcase-description>
  @if (accessibilityDescription && accessibilityDescription.length > 0) {
    <h2 id="_accessibility" class="showcase-title">
      Accessibility
      <a aria-hidden="true" class="header-link" title="Link to this heading"><i class="material-icons">link</i> </a>
    </h2>
    <bb-showcase-description [description]="accessibilityDescription"></bb-showcase-description>
  }
}
@if (props && props.length > 0) {
  <h2 id="_properties" class="showcase-title">Properties</h2>
  <bb-showcase-api [props]="props"></bb-showcase-api>
}
@if (scssTokensHtml) {
  <h2 id="_scssTokens" class="showcase-title">Tokens</h2>
  <bb-showcase-scss-tokens [scssTokensHtml]="scssTokensHtml"></bb-showcase-scss-tokens>
}
