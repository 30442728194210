import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-loading-indicator-ui-showcase',
  templateUrl: './loading-indicator-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class LoadingIndicatorShowcaseComponent {
  public loadingIndicators = [
    {
      title: 'Small',
      size: 'sm',
      text: null,
    },
    {
      title: 'Medium',
      size: 'md',
      text: null,
    },
    {
      title: 'Large',
      size: 'lg',
      text: null,
    },
    {
      title: 'Page loader',
      size: 'lg',
      text: 'Loading...',
    },
  ];
}
