import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemLogShowcaseComponent } from './item-log-showcase.component';
import { ItemLogModule } from '@backbase/ui-ang/item-log';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [CommonModule, ItemLogModule, IconModule],
  declarations: [ItemLogShowcaseComponent],
})
export class ItemLogShowcaseModule {}
