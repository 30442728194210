<main class="not-found">
  <div class="not-found__hero dso__content">
    <h1 class="not-found__hero-title">
      <small class="not-found__hero-title--small">404</small>
      Page not found
    </h1>

    <p class="not-found__hero-text">The page you are looking for could not be found.</p>

    <p class="not-found__hero-text">Please try to reenter the URL or place a request using the Feedback tab below.</p>

    <a bbButton href="https://backbase.io/products" class="btn-link-text btn not-found__hero-action">
      <span>Products landing page</span>
      <bb-icon-ui [color]="'accent'" [name]="'arrow-forward'" [size]="'sm'" cropped></bb-icon-ui>
    </a>
  </div>
</main>
