import {
  trigger,
  transition,
  animate,
  style,
  animation,
  useAnimation,
  query,
  group,
  AnimationTriggerMetadata,
  AnimationStyleMetadata,
  AnimationAnimateRefMetadata,
} from '@angular/animations';

const timing = '200ms ease-in';
const optional = { optional: true };
const distances = {
  normalX: 'translateX(0%)',
  right: 'translateX(100%)',
  left: 'translateX(-100%)',
  normalY: 'translateY(0%)',
  bottom: 'translateY(100%)',
};
const resetAnimatableView = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  display: 'block',
  top: 0,
};

export function resetableTransform(from: string): AnimationStyleMetadata {
  return style({
    ...resetAnimatableView,
    transform: from,
  });
}

export function slide2D(from: string, to: string): AnimationAnimateRefMetadata {
  return useAnimation(animation([resetableTransform(from), animate(timing, style({ transform: to }))]));
}

// An example for slide up/down animation
export const slideUpAnimation: AnimationTriggerMetadata = trigger('slideUp', [
  transition(':enter', [slide2D(distances.bottom, distances.normalY)]),
  transition(':leave', [slide2D(distances.normalY, distances.bottom)]),
]);

// An example for slide left/right animation
export const slideXAnimation: AnimationTriggerMetadata = trigger('slideX', [
  transition('void => isLeft', [slide2D(distances.right, distances.normalX)]),
  transition('void => isRight', [slide2D(distances.left, distances.normalX)]),
  transition('isRight => void', [slide2D(distances.normalX, distances.left)]),
  transition('isLeft => void', [slide2D(distances.normalX, distances.right)]),
]);

// An example to animate tabs
export const tabsAnim: AnimationTriggerMetadata = trigger('tabsAnimation', [
  // Previous, slide left to right to show left tab
  transition(':decrement', [
    // set new tab X location to be -100%
    query(':enter', resetableTransform(distances.left)),

    group([
      // slide existing page from 0% to 100% to the right
      query(':leave', animate(timing, resetableTransform(distances.right)), optional),
      // slide new page from -100% to 0% to the right
      query(
        ':enter',
        animate(
          timing,
          style({
            opacity: 1,
            transform: distances.normalX,
          }),
        ),
        optional,
      ),
    ]),
  ]),
  // Next, slide right to left to show right page
  transition(':increment', [
    // set new page X location to be 100%
    query(':enter', resetableTransform(distances.right)),

    group([
      // slide existing page from 0% to -100% to the left
      query(':leave', animate(timing, resetableTransform(distances.left))),
      // slide new page from 100% to 0% to the left
      query(
        ':enter',
        animate(
          timing,
          style({
            opacity: 1,
            transform: distances.normalX,
          }),
        ),
      ),
    ]),
  ]),
]);

// Used to animate bb-notification
export const fadeSlideAnimation: AnimationTriggerMetadata = trigger('fadeSlideInOut', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-10px)' }),
    animate(timing, style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [animate(timing, style({ opacity: 0, transform: 'translateY(-10px)' }))]),
]);

// An example for fadi in/out animation
export const fadeInAnimation: AnimationTriggerMetadata = trigger('fadeIn', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate(timing, style({ opacity: 1 })),
  ]),
  transition(':leave', [animate(timing, style({ opacity: 0 }))]),
]);

// An example to animate router
export const routerAnim: AnimationTriggerMetadata = trigger('routeAnimations', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          ...resetAnimatableView,
          transform: 'translateY(-50px)',
          opacity: 0,
        }),
      ],
      optional,
    ),
    query(':enter', [animate(timing, style({ transform: distances.normalY, opacity: 1 }))], optional),
  ]),
]);

export const errorMessageAnimation = trigger('errorMessageAnimation', [
  transition('void => *', [style({ opacity: 0, height: '0px' }), animate(150, style({ opacity: 1, height: '*' }))]),
]);
