<div class="paragraph">
<p>Area charts depict a time-series relationship with continuous data. They are useful for showing trends or progression over time and also data with part-to-whole relationships.</p>
</div>
<div class="paragraph">
<p>In backbase products charts have a specific purpose and are built within widgets that cover specific user flows where data visualization is required. We use Area Chart in Business Banking Widget Collection to display the balance history of a user’s bank account.</p>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_anatomy_of_area_charts">Anatomy of Area Charts</h3>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/area-chart-anatomy.png" alt="Anatomy of an Area Chart">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p>Y-Axis<br>
Displays the values being measured through time. In accounts overview, these values show the minimum and maximum amounts an account has reached in a period of time.</p>
</li>
<li>
<p>Shape Fill<br>
The colored shape of the area that visually represents the data displayed.</p>
</li>
<li>
<p>Grid<br>
Horizontal and vertical lines that help visually guide a point on the shape with a specific value on the Axis.</p>
</li>
<li>
<p>Markers<br>
A shape that highlights an exact data point in the chart.</p>
</li>
<li>
<p>X-Axis<br>
Displays the time values that are being covered in the chart.</p>
</li>
<li>
<p>Legends<br>
A description of the values being measured on the chart.</p>
</li>
</ol>
</div>
</div>
<div class="sect2">
<h3 id="_chart_colors">Chart colors</h3>
<div class="paragraph">
<p>Area Charts follow the general guidelines for color on chart to determine how to choose the right values and order.</p>
</div>
</div>
<div class="sect2">
<h3 id="_behaviour">Behaviour</h3>
<div class="paragraph">
<p>To maintain consistency and improve learnability, we’ve described general guidelines to be used in all chart types, so the user always knows what to expect.</p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_and_donts">Do’s and Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong><br>
* Use to represent data in a sequence, where there is only one value within each X-Axis interval.<br>
* Use different colors for markers to highlight extreme values such as minimum and maximum balance.</p>
</div>
<div class="paragraph">
<p><strong>Don’t</strong><br>
* Use when not comparing values over time, as it might create confusion. For these cases, use a bar graph instead.<br>
* Use area charts to display discrete data since the connected lines imply intermediate values, which only exist with continuous data.<br>
* Display more than four data categories, too many will result in a cluttered visual that is difficult to decipher.</p>
</div>
<div class="sect2">
<h3 id="_responsiveness">Responsiveness</h3>
<div class="paragraph">
<p>Charts are fully responsive, which means they resize to the width of their container. On small devices, the chart will reduce its size to fit as much of the space as possible by tightening the internal padding between elements.</p>
</div>
<div class="paragraph">
<p>On small devices, consider displaying fewer values on the X-Axis to provide a more accurate representation of the data in such limited space. Having too many labels might make the chart more difficult to read.</p>
</div>
</div>
<div class="sect2">
<h3 id="_accessibility">Accessibility</h3>
<div class="paragraph">
<p>When designing an Area Chart be mindful of your color choices, you need to consider sufficient contrast between elements, specially when displaying positive/negative values together.</p>
</div>
<div class="paragraph">
<p>For more on designing with accessibility in mind, review our <a href="/principles/accessibility#references-amp-links">guidelines for designers</a>.</p>
</div>
</div>
</div>
</div>
