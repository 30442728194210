/**
 * Used to annotate an example component with documentation-related metadata.
 *
 * For now, only supports a simple boolean flag that signifies whether a component has been migrated
 * to Theme 2.0 or not.
 */
export function BackbaseDocs(docs: { isTheme2Stable: boolean }) {
  return function (constructor: Function) {
    constructor.prototype._backbaseDocs = {
      isTheme2Stable: docs.isTheme2Stable,
    };
  };
}
