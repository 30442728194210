import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  CustomProductItemCreditCardDetailsFormatterDirective,
  CustomProductItemCreditCardMainSectionDirective,
  CustomProductItemCreditCardSecondarySectionDirective,
  CustomProductItemCreditCardTitleDirective,
  ProductItemCreditCardComponent,
} from './product-item-credit-card.component';
import { AmountModule } from '@backbase/ui-ang/amount';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { HighlightModule } from '@backbase/ui-ang/highlight';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [NgClass, AmountModule, PaymentCardNumberModule, HighlightModule, IconModule, NgTemplateOutlet],
  declarations: [
    ProductItemCreditCardComponent,
    CustomProductItemCreditCardTitleDirective,
    CustomProductItemCreditCardDetailsFormatterDirective,
    CustomProductItemCreditCardMainSectionDirective,
    CustomProductItemCreditCardSecondarySectionDirective,
  ],
  exports: [
    ProductItemCreditCardComponent,
    CustomProductItemCreditCardTitleDirective,
    CustomProductItemCreditCardDetailsFormatterDirective,
    CustomProductItemCreditCardMainSectionDirective,
    CustomProductItemCreditCardSecondarySectionDirective,
  ],
})
export class ProductItemCreditCardModule {}
