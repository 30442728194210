import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_ASSET_PATH } from './illustration.constants';

@Pipe({ name: 'illustrationSrc' })
export class IllustrationSrcPipe implements PipeTransform {
  transform(name: string, assetPath: string, fileType: string) {
    if (assetPath !== DEFAULT_ASSET_PATH) {
      return `${assetPath}/${name}.${fileType}`;
    }

    // strictly map it to our own naming convention which is standardised among platforms
    const snakeCaseName = name
      // split pascal case by capital letters and include those letters in split arrays
      // or split kebab case by "-" and exclude "-" in split arrays
      // if matched with a number, split only if number is not preceeded with "_"
      .split(/\B(?=[A-Z]|(?<!_)[0-9])|\-/g)
      .map((str) => str.toLowerCase())
      .join('_');

    const fileName = snakeCaseName.match(/^ic/) ? snakeCaseName : `ic_${snakeCaseName}`;

    return `${assetPath}/${fileName}.${fileType}`;
  }
}
