import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';

import { HeaderComponent } from './header.component';

/**
 * @name HeaderModule
 *
 * @deprecated Module is deprecated as of ui-ang v11. It will be removed in ui-ang v13.
 */
@NgModule({
  imports: [NgClass, NgTemplateOutlet],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
