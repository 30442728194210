import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbInputDatepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';

import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { NgxMaskPipe } from 'ngx-mask';
import { BehaviorSubject } from 'rxjs';
import { NgbDateStringAdapter } from './input-datepicker-adapter';
import { NgbDateLocaleParserFormatter } from './input-datepicker-formatter';
import { NgbDatepickerI18nDefault } from './input-datepicker-i18n';
import {
  DateRangeModel,
  DateSelectionModel,
  INVALID_DATE_FORMAT_KEY,
  NgDateStructNullable,
} from './input-datepicker.model';
import { InputDatepickerConfig, INPUT_DATEPICKER_CONFIG_TOKEN } from './input-datepicker.token-config';
import { TimezonedTodayCalendar } from './input-datepicker-calendar';
import { getDynamicId } from '@backbase/ui-ang/util';

/**
 * Enumeration of key codes used for keyboard input handling.
 * The numeric values correspond to the ASCII values for the keys.
 */
export enum Key {
  Tab = 9,
  Enter = 13,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
}

/**
 * Type guard function that checks if the given value is a `DateRangeModel`.
 *
 * @param value - The value to check.
 * @returns `true` if the value is a `DateRangeModel`, otherwise `false`.
 */
export const isDateRangeModelType = (value: any): value is DateRangeModel =>
  Object.prototype.hasOwnProperty.call(value || {}, 'from') && Object.prototype.hasOwnProperty.call(value || {}, 'to');

/**
 * @name InputDatepickerComponent
 *
 * @description
 * Component that displays a datepicker
 *
 * ### Custom internationalization
 * It is possible to provide a custom internalization. To do this, a `DATEPICKER_I18` token from `@backbase/ui-ang/input-datepicker` should be provided on the module level.
 * The token should implement the [NgbDatepickerI18n](https://ng-bootstrap.github.io/#/components/datepicker/api#NgbDatepickerI18n) interface.
 *
 * ### Setting and reading the date
 * *Note* Datepicker uses the date *with the timezone* inside its model. And hence to make it work properly in all cases there are some recommendations for setting and reading the date to and from the datepicker.
 *
 * #### Setting the date
 * When setting the date to the datepicker the date must be provided in the format that assumes zero hours and zero minutes in the local timezone. Here are some examples of what the date setting should looks like:
 *
 * ```typescript
 * this.minDate = new Date(2021, 11, 15, 0, 0).toISOString();
 * ```
 *
 * or
 *
 * ```typescript
 * this.minDate = new Date('2021-12-15T00:00').toISOString();
 * ```
 *
 * Note that
 *
 * ```typescript
 * new Date('2021-12-15').toISOString();
 * ```
 *
 * generates a date in GMT0 format and that is not correct for the datepicker input date.
 *
 * #### Reading the date
 * When a date is selected via UI (the datepicker's popup window or browser's input field) its display date is transformed to the ISO string date with the assumption that it’s zero hours and zero minutes in the current time zone. Which leads to the different ISO string values for different time zones for the same date. F.e. "Dec 15 2021" is going to be transformed to "2021-12-14T23:00:00.000Z" for the "GMT+0100 (Central European Standard Time)" time zone and to "2021-12-15T03:00:00.000Z" for "GMT-0300 (West Greenland Standard Time)" time zone. As you can see the _day_ value is different in ISO string based on the timezone. It’s going to be either 14 or 15 in the example above.
 *
 * The recommendation here is to convert the ISO string, that the datepicker returns, into the Date object and read its day value. Here is an example:
 *
 * ```typescript
 * private formatDate(stringDate: string): string {
 *   const date = new Date(stringDate);
 *   if (stringDate && !isNaN(date.valueOf())) {
 *     return `${date.getFullYear()}-${this.appendLeadingZeroes(date.getMonth() + 1)}-${this.appendLeadingZeroes(date.getDate())}`;
 *   }
 *   return '';
 * }
 *
 * private appendLeadingZeroes(value: number): string {
 *   return value > 9 ? value.toString() : `0${value}`;
 * }
 * ```
 *
 * ### Setting the mask
 * When setting the mask, the following rules/expectations apply:
 * - mask can be set to boolean true/false, or to some string pattern like 00/00/0000
 * - when mask is set, if overrideDateFormat is not set in the template, it's set inside the component to be dateFormat
 *
 * Rules when mask is set to true in the template:
 * - when mask is set to true, it will be reset to an overrideDateFormat-compliant-string-pattern that's created in the component
 * - if overrideDateFormat is not mask compliant (e.g., has one digit for days or months), overrideDateFormat is adjusted first
 *
 * Rules when mask is set to some string pattern in the template:
 * - string patterns are made of a subset of allowed ngx-mask characters 0's and S's only - see https://www.npmjs.com/package/ngx-mask
 * - In the above, 0 is used to be a placeholder for digits, and S for letters
 * - Typical date separators such as these in [ / , sp - ] are allowed in the created/provided mask pattern
 * - If created/provided mask pattern is not compliant with overrideDateFormat, component is invalidated and mask is set to be undefined
 *
 * Examples when mask is set to true in the template (e.g., [mask]="true")
 * - if overrideDateFormat = 'dd/MM/yyyy' or 'MM/dd/yyyy', created mask will be '00/00/0000'
 * - if overrideDateFormat = 'MMM dd, yy', created mask will be 'SSS 00, 00'
 * - if dateFormat = 'M/d/yy', then overrideDateFormat is corrected to 'MM/dd/yy' and created mask will be '00/00/00'
 *
 * Examples when mask is set to some string pattern (e.g., [mask]="'00/00/00'")
 * - in this setting, overrideDateFormat is not adjusted, only validation occurs here
 * - if overrideDateFormat = 'yyyy-MMM-00' and template-set mask = '0000-SSS-00', mask is validated and accepted
 * - if overrideDateFormat = 'yyyy-MMM-00' and template-set mask = '0000-000-00', mask is rejected (because there are no three-digit months), set to undefined, and element is errored
 *
 * ### Global configuration token
 * `INPUT_DATEPICKER_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `InputDatepickerComponent` in your project.
 *
 * *Note:* The token overwrites the default value only. If you have provided a value as a property on a specific component, the token is not able to overwrite it.
 *
 * The following properties can be overwritten using the token:
 * - `autocomplete`
 * - `displayMonths`
 * - `firstDayOfWeek`
 * - `overrideDateFormat`
 * - `placeholder`
 * - `rangeSelection`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 *
 * ```typescript
 * import { INPUT_DATEPICKER_CONFIG_TOKEN } from '@backbase/ui-ang/input-datepicker';
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { AppModule } from './app/app.module';
 *
 * const inputDatepickerConfig = {
 *   firstDayOfWeek: 1
 * }
 *
 * platformBrowserDynamic().bootstrapModule(AppModule, {
 *   providers: [{ provide: INPUT_DATEPICKER_CONFIG_TOKEN, useValue: inputDatepickerConfig }]
 * });
 * ```
 *
 * ### Accessibility
 * The component provides options to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case:
 * - `role` describes the role of the element in programs like screen readers
 * - `aria-activedescendant` identifies the currently active element
 * - `aria-describedby` identifies the element that describes the component
 * - `aria-expanded` is set on an element to indicate if the datepicker is expanded or collapsed
 * - `aria-invalid` state indicates the entered value does not conform to the format expected
 * - `aria-label` defines a string value that labels the component
 * - `aria-labelledby` identifies the element that labels the component
 * - `aria-owns` identifies an element in order to define a visual, functional, or contextual relationship
 *
 */
@Component({
  selector: 'bb-input-datepicker-ui',
  templateUrl: './input-datepicker.component.html',
  providers: [
    DatePipe,
    NgbDateLocaleParserFormatter,
    {
      provide: NgbDateAdapter,
      useClass: NgbDateStringAdapter,
    },
    {
      provide: NgbDatepickerI18n,
      useClass: NgbDatepickerI18nDefault,
    },
    {
      provide: NgbDateParserFormatter,
      useExisting: NgbDateLocaleParserFormatter,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputDatepickerComponent),
      multi: true,
    },
    {
      provide: NgxMaskPipe,
      useClass: NgxMaskPipe,
    },
    {
      provide: NgbCalendar,
      useClass: TimezonedTodayCalendar,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDatepickerComponent
  extends InputBaseComponent
  implements OnInit, OnChanges, Validator, AfterViewInit
{
  /**
   * Event that is emitted when a date is focused.
   * The event payload is a `DateSelectionModel`.
   */
  @Output() focusedDate = new EventEmitter<DateSelectionModel>();

  /**
   * The container element that the datepicker should be attached to.
   */
  @Input() container: NgbInputDatepickerConfig['container'] = null;

  /**
   * Indicates whether the datepicker should allow selecting a date range with a single input.
   * The default value is `false`.
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input('rangeSelection')
  get rangeSelection(): boolean {
    return this._rangeSelection ?? this.overrideConfiguration?.rangeSelection ?? false;
  }

  set rangeSelection(value: boolean) {
    this._rangeSelection = value;
  }

  private _rangeSelection: boolean | undefined;

  /**
   * Indicates if the datepicker should a date range with split input. One for the from date and one for the to date.
   * Defaults to false
   */
  @Input() rangeSelectionSplit = false;

  /**
   * Indicates if the datepicker is opened when clicking input.
   * Defaults to false
   */
  @Input() clickOpen = false;

  /**
   * Indicates if the datepicker is opened when focusing input.
   * Defaults to false
   */
  @Input() focusOpen = false;

  _placement: string | undefined = 'bottom-left';

  get placement() {
    return document.documentElement.clientWidth > 320 ? this._placement : 'bottom';
  }

  /**
   * Placement of a popup window
   */
  @Input()
  set placement(value: string | undefined) {
    if (value) {
      this._placement = value;
    }
  }

  defaultDatepickerDescribedby = `defaultDatepickerDescribedbyLabel_${getDynamicId()}`;

  /**
   * Accessible description for datepicker date selection popup.
   */
  @Input() datepickerDescribedby = this.defaultDatepickerDescribedby;

  /**
   * Accessible label for Datepicker button.
   */
  @Input() ariaLabelForButton = $localize`:@@bb-input-datepicker-ui.datepicker-button.aria-label:Toggle Date popup`;

  /**
   * The label for the second input when the datepicker has a ranged split input. Defaults to an empty string.
   */
  @Input() labelTo = '';

  /**
   * Accessible label for the second input when the datepicker has a ranged split input.
   */
  @Input() ariaLabelToDate?: string;

  /**
   * Min date for the navigation. If not provided, 'year' select box will display 10 years
   * before current month
   */
  @Input()
  set minDate(date: NgbDateStruct | string | undefined) {
    this._minDate = typeof date === 'string' ? this.adapter.fromModel(date) : date;
  }

  _minDate: NgDateStructNullable | undefined;

  /**
   * Max date for the navigation. If not provided, 'year' select box will display 10 years
   * after current month.
   */
  @Input()
  set maxDate(date: NgbDateStruct | string | undefined) {
    this._maxDate = typeof date === 'string' ? this.adapter.fromModel(date) : date;
  }

  _maxDate: NgbDateStruct | undefined | null;
  /**
   * Callback to mark a given date as disabled.
   */
  @Input() markDisabled?: Function;

  /**
   * Icon that is displayed in the button.
   * Defaults to calendar
   */
  @Input() icon = 'calendar-today';

  /**
   * The size of the icon to be displayed.
   * Defaults to md
   */
  @Input() iconSize = 'md';

  /**
   * The color of the icon to be displayed.
   */
  @Input() iconColor?: string;

  /**
   * Color of the button.
   *
   * @deprecated in ui-ang@12 and will be removed in ui-ang@14. No replacement provided.
   */
  @Input() btnColor?: string;

  /**
   * A label for the datepicker that will be used to provide an accessible description of the component.
   */
  @Input()
  ariaLabel = 'Datepicker';

  /**
   * Navigation pattern through dates.
   * Default to arrows
   */
  @Input() navigation: 'select' | 'arrows' | 'none' = 'arrows';

  /**
   * The autocomplete value of enclosed input control.
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('autocomplete')
  get autocomplete(): InputDatepickerConfig['autocomplete'] {
    return this._autocomplete ?? this.overrideConfiguration?.autocomplete;
  }

  set autocomplete(value: InputDatepickerConfig['autocomplete']) {
    this._autocomplete = value;
  }

  private _autocomplete: InputDatepickerConfig['autocomplete'];

  /**
   * The first day of the week
   *
   * By default, the calendar uses ISO 8601 and the weekdays are is 1=Mon ... 7=Sun
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input('firstDayOfWeek')
  get firstDayOfWeek(): InputDatepickerConfig['firstDayOfWeek'] {
    return this._firstDayOfWeek ?? this.overrideConfiguration?.firstDayOfWeek;
  }

  set firstDayOfWeek(value: InputDatepickerConfig['firstDayOfWeek']) {
    this._firstDayOfWeek = value;
  }

  private _firstDayOfWeek: InputDatepickerConfig['firstDayOfWeek'];

  /**
   * Indicates how many month will be shown in the picker
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('displayMonths')
  get displayMonths(): number {
    return this._displayMonths ?? this.overrideConfiguration?.displayMonths ?? 1;
  }

  set displayMonths(value: number) {
    this._displayMonths = value;
  }

  private _displayMonths: number | undefined;

  /**
   * Mask configuration (optional). The default value is false
   * Mask can be:
   *  1- enabled: when set from template to boolean true to create mask, or false (default is false if left unset).
   *  2- validated: when set from template to some string date pattern such as `00/00/0000` or `SSS 00, 0000`.
   * This attribute can be overwritten via the global configuration token
   */
  @Input('mask')
  get mask(): InputDatepickerConfig['mask'] {
    return this._mask ?? this.overrideConfiguration?.mask ?? false;
  }

  set mask(value: InputDatepickerConfig['mask']) {
    this._mask = value;
  }

  private _mask: InputDatepickerConfig['mask'];

  /*
   * When mask is set, maskLength is set to the length of the mask pattern,
   * which is used to set the [maxlength] property of the input element in the template;
   * this prevents the element from considering any beyond-the-limit typing
   */
  maskLength: any = undefined;

  /**
   * The placeholder for the datepicker input. Default is Locale Date Format;
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('placeholder')
  set placeholder(value: InputDatepickerConfig['placeholder']) {
    this._placeholder = typeof value !== 'undefined' ? String(value) : undefined;
  }

  get placeholder(): InputDatepickerConfig['placeholder'] {
    const placeholder = this._placeholder ?? this.overrideConfiguration?.placeholder;

    if (typeof placeholder === 'undefined') {
      const pattern = this.dateFormat.toUpperCase();

      return this.rangeSelection ? `${pattern} ${this.inputDateRangeSeparator} ${pattern}` : pattern;
    }

    return placeholder;
  }

  private _placeholder: InputDatepickerConfig['placeholder'];

  /**
   * Override date format (optional). Supported formats are `yyyy/MM/dd`, `MM/dd/yyyy`, `MMMM dd, yyyy`.
   * Note, other formats might work inconsistent in different browsers.
   *
   * This attribute can be overwritten via the global configuration token
   */
  @Input('overrideDateFormat')
  get overrideDateFormat(): InputDatepickerConfig['overrideDateFormat'] {
    return this._overrideDateFormat ?? this.overrideConfiguration?.overrideDateFormat;
  }

  set overrideDateFormat(value: InputDatepickerConfig['overrideDateFormat']) {
    this._overrideDateFormat = value;
    this.formatterHelper.dateFormat = value;
  }

  private _overrideDateFormat: InputDatepickerConfig['overrideDateFormat'];

  /**
   * The reference to the custom template for the datepicker footer.
   */
  @Input() footerTemplate?: ElementRef;

  /**
   * The date to open calendar with.
   */
  @Input() startDate?: NgDateStructNullable;

  /**
   * Defines whether or not the datepicker is opened initially.
   * Defaults to `false`.
   */
  @Input() isOpen = false;

  /**
   * The custom or locale date format that is used to display dates and placeholders
   */
  get dateFormat(): string {
    return this.formatterHelper.localeDateFormat;
  }

  /**
   * The datepicker component for the "from" date.
   */
  @ViewChild('datePicker') datePicker: NgbInputDatepicker | undefined;

  /**
   * The datepicker component for the "to" date in range mode.
   */
  @ViewChild('datePickerTo') datePickerTo: NgbInputDatepicker | undefined;

  /**
   * The input element for the "from" date.
   */
  @ViewChild('datePickerInput') datePickerInput: ElementRef | undefined;

  /**
   * The input element for the "from-to" date range.
   */
  @ViewChild('datePickerInputRange') datePickerInputRange: ElementRef | undefined;

  /**
   * The input element for the "to" date in range mode.
   */
  @ViewChild('datePickerInputTo') datePickerInputTo: ElementRef | undefined;

  /**
   * The button element to trigger the "from" datepicker.
   */
  @ViewChild('datePickerButton') datePickerButton: ElementRef | undefined;

  /**
   * The button element to trigger the "to" datepicker in range mode.
   */
  @ViewChild('datePickerButtonTo') datePickerButtonTo: ElementRef | undefined;

  /**
   * The date input control.
   */
  dateInput: UntypedFormControl;
  /**
   * The "to" date input control for the range mode.
   */
  dateInputTo: UntypedFormControl;
  /**
   * The date input hidden range control
   */
  dateInputRange: UntypedFormControl;

  /**
   * The parent form control, if any.
   */
  parentFormControl: AbstractControl | undefined;

  /**
   * The observable that emits the hovered date.
   */
  hoveredDate$ = new BehaviorSubject<DateSelectionModel>({ date: null });
  pickerHoveredDayDateTo: NgDateStructNullable = null;

  /**
   * The observable that emits the selected "from" date.
   */
  fromDate$ = new BehaviorSubject<NgDateStructNullable>(null);

  /**
   * The observable that emits the selected "to" date in range mode.
   */
  toDate$ = new BehaviorSubject<NgDateStructNullable>(null);

  readonly inputDateRangeSeparator = '-';

  @HostBinding('class') cssClass = 'bb-input-datepicker-ui';

  /**
   *  An unlisten function for disposing document click listener
   */
  unListenDocumentClick: (() => void) | undefined;
  /**
   * An unlisten function for disposing escape key listener
   */
  unListenDocumentEsc: (() => void) | undefined;

  constructor(
    private readonly formatterHelper: NgbDateLocaleParserFormatter,
    private readonly el: ElementRef,
    protected readonly cd: ChangeDetectorRef,
    private readonly adapter: NgbDateAdapter<string>,
    private readonly renderer2: Renderer2,
    private readonly domAttrService: DomAttributesService,
    private maskPipe: NgxMaskPipe,
    @Optional()
    @Inject(INPUT_DATEPICKER_CONFIG_TOKEN)
    private readonly overrideConfiguration: InputDatepickerConfig,
  ) {
    super(cd);
    this.dateInput = new UntypedFormControl(null);
    this.dateInputTo = new UntypedFormControl(null);
    this.dateInputRange = new UntypedFormControl(null);
  }

  ngOnInit() {
    super.ngOnInit();
    this.formatterHelper.dateFormat = this.overrideDateFormat;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { disabled } = changes;

    if (disabled && disabled.currentValue !== disabled.previousValue) {
      if (this.disabled) {
        this.dateInput.disable();
      } else {
        this.dateInput.enable();
      }
    }
  }

  // todo: do we need this?
  ngAfterViewInit(): void {
    const datepickerInput = this.rangeSelection ? this.datePickerInputRange : this.datePickerInput;
    this.domAttrService.moveAriaAttributes(this.el.nativeElement, datepickerInput?.nativeElement, this.renderer2);
    setTimeout(() => {
      // internal validators are not used
      this.dateInput.setValidators([]);
      this.dateInput.updateValueAndValidity();
      this.dateInputTo.setValidators([]);
      this.dateInputTo.updateValueAndValidity();
      this.cd.markForCheck();

      // Open the datepicker on render
      if (!this.isOpen) {
        return;
      }

      this.bindDocumentEvents();

      if (this.datePicker) {
        this.datePicker.open();
      }
      if (this.datePickerTo) {
        this.datePickerTo.open();
      }
    });

    this.createOrValidateMask();
  }

  /**
   * createOrValidateMask
   *  1- sets overrideDateFormat to be dateFormat in case it's undefined
   *  2- if mask property is boolean and value is true, then createMask()
   *  3- else, validateMask()
   */
  createOrValidateMask() {
    this.overrideDateFormat = this.overrideDateFormat ?? this.dateFormat;
    if (this.mask === true) {
      this.createMask();
    } else if (this.mask) {
      this.validateMask();
    }
  }

  /**
   * If the host template has property mask set to boolean true, createMask will:
   *  1- ensure overrideDateFormat has 2 d's; if not, adjust days
   *  2- ensure overrideDateFormat has 2 or 3 M's; if not, adjust months
   *  3- ensure overrideDateFormat has 2 or 4 y's; if not, adjust years
   *  4- with overrideDateFormat adjusted if needed, create compliant mask pattern
   *  5- with mask set, ensure its compliance by calling validateMask on it
   */
  // eslint-disable-next-line complexity
  createMask = () => {
    // 1-
    if (this.overrideDateFormat?.match(/d/g)?.length !== 2) {
      this.overrideDateFormat = this.overrideDateFormat?.replace(/d+/, 'dd');
    }

    // 2-
    const lenMs = this.overrideDateFormat?.match(/M/g)?.length || 0;
    if (!(lenMs === 2 || lenMs === 3)) {
      if (lenMs < 2) {
        this.overrideDateFormat = this.overrideDateFormat?.replace(/M/, 'MM');
      } else {
        this.overrideDateFormat = this.overrideDateFormat?.replace(/M+/, 'MMM');
      }
    }

    // 3-
    const lenYs = this.overrideDateFormat?.match(/y/g)?.length || 0;
    if (!(lenYs === 2 || lenYs === 4)) {
      if (lenYs < 2) {
        this.overrideDateFormat = this.overrideDateFormat?.replace(/y/, 'yy');
      } else {
        this.overrideDateFormat = this.overrideDateFormat?.replace(/y+/, 'yyyy');
      }
    }

    // 4-
    this.mask = this.overrideDateFormat?.replace(/[d|y]/g, '0').replace(/MMM/, 'SSS').replace(/MM/, '00') ?? '';

    // 5-
    this.validateMask();
  };

  /**
   * If the host template has property mask set to some string pattern, validateMask will
   *  1- ensure it has no BB unsupported chars out of [0 S - / , . sp]
   *  2- ensure it has 3 items in a prescribed pattern
   *  3- ensure the 3 items above indeed has days, months and years parts
   *  4- create a mock mask out of dateFormat
   *  5- ensure mock mask is compliant with dateFormat
   *  6- if any of the steps above don't validate, set mask to undefined and exit
   *  7- if rangeSelection is true, adjust mask accordingly to accept a range
   *  8- set maskLength to enforce a limit on over-the-limit typing
   */
  validateMask = () => {
    let valid = true;

    // 1-
    const invalidMaskChars = this.mask.replace(/[0|S|\-|/|,|\.| ]/g, '');
    if (invalidMaskChars) {
      valid = false;
    }

    // 2-
    if (this.overrideDateFormat?.match(/\b(dd|(MM|MMM)|(yy|yyyy))\b/g)?.length !== 3) {
      valid = false;
    }

    // 3-
    if (
      !this.overrideDateFormat?.includes('d') ||
      !this.overrideDateFormat?.includes('M') ||
      !this.overrideDateFormat?.includes('y')
    ) {
      valid = false;
    }

    // 4-
    const mockMask = this.overrideDateFormat?.replace(/[d|y]/g, '0').replace(/MMM/, 'SSS').replace(/MM/, '00');

    // 5-
    if (mockMask !== this.mask) {
      valid = false;
    }

    // 6-
    if (!valid) {
      this.makeMaskUndefined();

      return;
    }

    // 7-
    if (this.rangeSelection) {
      this.mask += ` - ${this.mask}`;
    }

    // 8-
    this.maskLength = this.mask.length;
  };

  private makeMaskUndefined() {
    this.mask = undefined;
    this.maskLength = undefined;
  }

  /**
   * if mask for element is set, transform (constrain) input pattern accordingly
   */
  autoMask(element: HTMLInputElement) {
    if (!this.mask) {
      return;
    }

    const { name } = element;
    if (name === 'date') {
      if (this.rangeSelection) {
        this.autoFormatMaskedComponent(this.datePickerInputRange);
      } else {
        this.autoFormatMaskedComponent(this.datePickerInput);
      }
    } else if (name === 'dateTo') {
      this.autoFormatMaskedComponent(this.datePickerInputTo);
    }
  }

  /*
   * When mask is set to some string pattern, the input is transformed
   * using the ngx-mask's ngxMaskPipe. After the transformation, the cursor
   * is moved to the end of the input string regardless of where the
   * user was editing. A simple resetting for the cursor position
   * after the transformation removes a lot of such side effects.
   */
  private autoFormatMaskedComponent(field: ElementRef | undefined) {
    const nativeEl = field?.nativeElement;
    let cursorPos = nativeEl.selectionStart;
    const prevInputLen = nativeEl.value.length;
    nativeEl.value = this.maskPipe.transform(nativeEl.value, this.mask);
    cursorPos += nativeEl.value.length - prevInputLen;
    nativeEl.selectionStart = cursorPos;
    nativeEl.selectionEnd = cursorPos;
  }

  /**
   * @description
   * Passing template date to the range template to identify when it is used in the "to" date
   * (the second HTML date input of the split datepicker).
   * Check https://ng-bootstrap.github.io/#/components/datepicker/api "dayTemplateData" for details
   */
  rangeTemplateData = () => ({
    isTo: true,
  });

  /****************************************
   *
   *        EVENT HANDLERS
   *
   ***************************************/

  /**
   * @description
   * Input change handler for single date
   *
   * @param element
   */
  onInputChange(element: HTMLInputElement) {
    // Checking for "browser bug", for example,
    // In IE `new Date()` for short date format YY gives 19YY, for modern browser in looks like 20YY
    // for this reason we're adding 100 years to browser understand that it is 21st century
    const dateInputToUpdate = element.name === 'date' ? this.dateInput : this.dateInputTo;

    const { value } = element;

    const browserDate = new Date(value);

    const parsedDate = this.formatterHelper.interpretDate(value);

    if (parsedDate && parsedDate.getTime() !== browserDate.getTime()) {
      dateInputToUpdate.setValue(parsedDate);
    }
  }

  /**
   * Update datepicker value on input value change for same input date range selection
   *
   * @param value
   */
  onInputChangeRange(element: HTMLInputElement) {
    const { value } = element;

    const [dateFrom, dateTo] = value.split(this.inputDateRangeSeparator).slice(0, 2);

    this.fromDate$.next(this.formatterHelper.parse(dateFrom?.trim()));
    this.toDate$.next(this.formatterHelper.parse(dateTo?.trim()));

    if (this.fromDate$.getValue() || this.toDate$.getValue()) {
      this.updateRangeModel(this.fromDate$.getValue(), this.toDate$.getValue());
    } else {
      this.onChange(value);
    }
  }

  /**
   * Update datepicker value on input value change for split input date range selection, from date
   *
   * @param value
   */
  onInputChangeSplitRange(element: HTMLInputElement) {
    const { value } = element;

    this.fromDate$.next(this.formatterHelper.parse(value && value.trim()));

    this.updateRangeModel(this.fromDate$.getValue(), this.toDate$.getValue());
  }

  /**
   * Update datepicker value on input value change for split input date range selection, to date.
   *
   * @param value
   */
  onInputChangeSplitRangeTo(element: HTMLInputElement) {
    const { value } = element;

    this.toDate$.next(this.formatterHelper.parse(value && value.trim()));

    this.updateRangeModel(this.fromDate$.getValue(), this.toDate$.getValue());
  }

  /**
   * Event handler for the native input.
   */
  onInputFocus(open: boolean) {
    super.onFocus();

    if (open && this.datePicker) {
      this.bindDocumentEvents();
      this.datePicker.open();

      if (this.rangeSelection || this.rangeSelectionSplit) {
        this.datePicker.startDate = this.toDate$.getValue() as NgbDateStruct;
      }
    }
  }

  /**
   * Event handler for the native input
   */
  onInputFocusTo(open: boolean) {
    super.onFocus();

    if (open && this.datePickerTo) {
      this.datePickerTo.open();
    }
  }

  /**
   * Event handler for the picker toggle button to
   */
  onPickerTogglerClick(event: Event) {
    if (!this.disabled && this.datePicker) {
      this.bindDocumentEvents();
      this.setStartDateOnPicker(this.datePicker);

      this.datePicker.toggle();
    }
  }

  /**
   * Event handler for the picker toggle button to (split range selection)
   */
  onPickerTogglerClickTo(event: Event) {
    if (!this.disabled && this.datePickerTo) {
      this.bindDocumentEvents();
      this.setStartDateOnPicker(this.datePickerTo);

      this.datePickerTo.toggle();
    }
  }

  /**
   *
   * Set disable state for the host control
   *
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.dateInput.disable();
    } else {
      this.dateInput.enable();
    }
    super.setDisabledState(isDisabled);
  }

  /**
   * Event handler blur on internal inputs and buttons
   *
   * @param $event
   */
  onControlBlur($event: FocusEvent) {
    if (!$event.relatedTarget || !this.el.nativeElement.contains($event.relatedTarget)) {
      this.onBlur($event);
    }

    if (this.rangeSelection) {
      if (!this.toDate$.getValue() || !this.fromDate$.getValue()) {
        this.setDatesTheSame();
      } else {
        this.setRangeInput();
      }
    }
  }

  /**
   * Event handler for blur on the host control.
   *
   * @param $event
   */
  onBlur($event?: FocusEvent) {
    this.blur.emit($event);
    this.onTouched();
  }

  /**
   * @description
   *
   * Event handler for closing `from` or single date picker.
   *
   */
  onClosed() {
    const hoveredDateClosedValue = this.hoveredDate$.getValue();
    const hoveredDate = hoveredDateClosedValue?.date;

    if (hoveredDate) {
      this.setSelectedDate({ date: hoveredDate, isSelecting: false });
    }

    if (this.rangeSelectionSplit && this.datePickerTo && this.datePickerInputTo) {
      this.datePickerInputTo.nativeElement.focus();
    } else if (this.datePickerButton) {
      this.datePickerButton.nativeElement.focus();
    }

    // validates date input after date picker is closed
    // date input automatically exits focus when date picker is clicked
    this.onBlur();
  }

  /**
   * Event handler for closing picker for `to` date.
   */
  onClosedTo() {
    if (this.datePickerButtonTo !== undefined && this.datePicker && !this.datePicker.isOpen()) {
      this.datePickerButtonTo.nativeElement.focus();
    }
  }

  /**
   * Event handler for date setting from picker for `to` date when split input range selection
   *
   * @param date
   */
  onRangeDateSelectSplitTo(date: NgbDate) {
    this.toDate$.next(this.copyDateElement(date));

    this.updateRangeModel(this.fromDate$.getValue(), this.toDate$.getValue());

    this.onTouched();
  }

  /**
   * Event handler for date setting from picker for `from` date when split input range selection
   *
   * @param date
   */
  onRangeDateSelectSplit(date: NgbDate) {
    this.fromDate$.next(this.copyDateElement(date));

    this.updateRangeModel(this.fromDate$.getValue(), this.toDate$.getValue());

    this.onTouched();
  }

  /**
   *  Event handler for date setting from picker when single input range selection
   *
   * @param date
   */
  onRangeDateSelect(date: NgbDate) {
    if (!this.fromDate$.getValue() && !this.toDate$.getValue()) {
      this.fromDate$.next(this.copyDateElement(date));
    } else if (
      this.fromDate$.getValue() &&
      !this.toDate$.getValue() &&
      date &&
      (date.after(this.fromDate$.getValue()) || date.equals(this.fromDate$.getValue()))
    ) {
      this.toDate$.next(this.copyDateElement(date));
      setTimeout(() => this.closeDatePicker(), 0);
    } else {
      /* eslint-disable-next-line no-null/no-null */
      this.toDate$.next(null);
      this.fromDate$.next(this.copyDateElement(date));
    }

    this.updateRangeModel(this.fromDate$.getValue(), this.toDate$.getValue());

    this.setRangeInput();
  }

  /**
   *
   * Handling day-hover from day-template on range selection.
   *
   * @param event
   */
  onDayHover(event: { date: NgDateStructNullable; isTo: boolean; isSelecting: boolean }) {
    if (event.isTo) {
      this.pickerHoveredDayDateTo = event.date;
    } else {
      this.setSelectedDate({ date: event.date, isSelecting: event.isSelecting });
    }
  }

  /***************************************8
   *
   *  CONTROLVALUEACCESSOR OVERRIDES
   *
   **************************************/

  /**
   * Implements custom validation for the control
   *
   * @param control
   */
  validate = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!this.parentFormControl) {
      this.parentFormControl = control;
    }

    if (!value) {
      return null;
    }

    if (control.errors) {
      // reset the errors before re-validating
      // this prevents conflict when used with max/min-date-validator-directive
      // e.g. when user delete a character and types a new one, it keeps the invalidFormat error from the character removal
      control.setErrors(null, { emitEvent: false });
    }

    if (this.rangeSelection) {
      return this.validateDateRange(value as DateRangeModel);
    }

    // Due to the fact that range selection with split is using two separate inputs
    // we need to perform this additional check for cases where input itself contains
    // wrong formatted value.
    if (this.rangeSelectionSplit) {
      const valueValidity = this.validateDateRange(value as DateRangeModel);
      const fromValidity = this.dateInput.value ? this.validateSingleDate(this.dateInput.value) : null;
      const toValidity = this.dateInputTo.value ? this.validateSingleDate(this.dateInputTo.value) : null;

      return valueValidity ?? fromValidity ?? toValidity;
    }

    return this.validateSingleDate(value as string | Date);
  };

  /**
   *
   * Implements the value update for the control
   *
   * @param model
   */

  /* eslint-disable-next-line complexity */
  writeValue(model: DateRangeModel | string | null): void {
    if (!this.rangeSelection && !this.rangeSelectionSplit) {
      this.dateInput.setValue(typeof model === 'string' ? model : null);
    } else {
      if (isDateRangeModelType(model)) {
        if (model.from && this.validateSingleDate(model.from) === null) {
          this.fromDate$.next(this.adapter.fromModel(model.from));
        } else {
          this.fromDate$.next(model.from as any);
        }

        if (model.to && this.validateSingleDate(model.to) === null) {
          this.toDate$.next(this.adapter.fromModel(model.to));
        } else {
          this.toDate$.next(model.to as any);
        }
      } else {
        this.fromDate$.next(null);
        this.toDate$.next(null);
      }

      if (this.rangeSelection) {
        this.setRangeInput();
      } else if (this.rangeSelectionSplit) {
        this.dateInput.setValue(this.fromDate$.getValue() ? this.adapter.toModel(this.fromDate$.getValue()) : null);
        this.dateInputTo.setValue(this.toDate$.getValue() ? this.adapter.toModel(this.toDate$.getValue()) : null);
      }
    }
  }

  /*********************************
   *
   *        PRIVATE UTILS
   *
   **********************************/

  /**
   *
   * Sets the value for `this.hoveredDate$` and emits a value to @Output focusedDate
   *
   * @param date Takes a value with type `DateSelectionModel`
   */

  private setSelectedDate(date: DateSelectionModel): void {
    this.hoveredDate$.next(date);
    this.focusedDate.emit(date);
  }

  private setDatesTheSame() {
    const fromDate = this.fromDate$.getValue();
    if (fromDate) {
      this.toDate$.next(fromDate);
      this.updateRangeModel(this.fromDate$.getValue(), this.toDate$.getValue());
      this.setRangeInput();
    }
  }

  private closeDatePicker() {
    if (this.datePicker) {
      this.datePicker.close();
      if (this.rangeSelection) {
        this.parentFormControl?.updateValueAndValidity();
      }
      this.onBlur();
    }

    if (this.datePickerTo) {
      this.datePickerTo.close();
      this.onBlur();
    }

    // Unbind the global document events once the date picker is closed.
    if (this.unListenDocumentClick) {
      this.unListenDocumentClick();
    }
    if (this.unListenDocumentEsc) {
      this.unListenDocumentEsc();
    }
  }

  private isValidRange(from: string, to: string): ValidationErrors | null {
    if (new Date(from).getTime() > new Date(to).getTime()) {
      return { [INVALID_DATE_FORMAT_KEY]: true };
    }

    return this.isValidMaskPattern();
  }

  private validateSingleDate(value: string | Date): ValidationErrors | null {
    const date = typeof value === 'string' ? value : value.toISOString();
    // check if value is in ISO format
    // this means that it has passed parsing and that it is in date format for current locale
    // This check is for IE11
    const ISODateRegExp = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/;

    return ISODateRegExp.test(date) ? this.isValidMaskPattern() : { [INVALID_DATE_FORMAT_KEY]: true };
  }

  private isValidMaskPattern(): ValidationErrors | null {
    return this.mask === undefined ? { maskPatternError: true } : null;
  }

  /**
   * Document Event handler
   */
  handleDocumentEvent(event: KeyboardEvent | MouseEvent): void {
    if (event.target && (!this.el.nativeElement.contains(event.target) || event.type === 'keyup')) {
      this.closeDatePicker();
    }
  }

  /**
   * Binds event on the global document when the datepicker is not opened
   */
  bindDocumentEvents() {
    if (this.datePicker && !this.datePicker.isOpen()) {
      this.unListenDocumentClick = this.renderer2.listen('document', 'click', this.handleDocumentEvent.bind(this));
      this.unListenDocumentEsc = this.renderer2.listen('document', 'keyup.escape', this.handleDocumentEvent.bind(this));
    }
  }

  /* eslint-disable-next-line complexity */
  private validateDateRange(value: DateRangeModel) {
    let validateTo = null;
    let validateFrom = null;
    let validateRange = null;

    if (!isDateRangeModelType(value)) {
      return { [INVALID_DATE_FORMAT_KEY]: true };
    }
    if (!value.from && !value.to && !this.isRequired()) {
      return null;
    }
    if ((!value.to && value.from) || (value.to && !value.from) || (!value.from && !value.to && this.isRequired())) {
      return { required: true };
    }

    if (value.from) {
      validateFrom = this.validateSingleDate(value.from);
    }
    if (value.to) {
      validateTo = this.validateSingleDate(value.to);
    }
    if (!validateFrom && !validateTo && value.from && value.to) {
      validateRange = this.isValidRange(value.from, value.to);
    }

    return validateTo || validateFrom || validateRange;
  }

  private setRangeInput() {
    if (this.fromDate$.getValue() || this.toDate$.getValue()) {
      const formattedFrom = this.formatterHelper.format(this.fromDate$.getValue());
      const formattedTo = this.formatterHelper.format(this.toDate$.getValue());

      this.dateInputRange.setValue(this.fromDate$.getValue() ? this.adapter.toModel(this.fromDate$.getValue()) : null);

      this.dateInput.setValue(
        `${formattedFrom === null ? this.fromDate$.getValue() : formattedFrom} ${this.inputDateRangeSeparator} ${
          formattedTo === null ? this.toDate$.getValue() : formattedTo
        }`,
      );
    } else {
      /* eslint-disable-next-line no-null/no-null */
      this.dateInput.setValue(null);
      this.dateInputRange.setValue(null);
    }

    if (this.parentFormControl?.touched) {
      this.onTouched();
    }
  }

  private copyDateElement(sourceDate: NgbDate | NgbDateStruct): NgbDateStruct {
    return { year: sourceDate.year, month: sourceDate.month, day: sourceDate.day } as NgbDateStruct;
  }

  private updateRangeModel(from: NgDateStructNullable, to: NgDateStructNullable) {
    this.onChange({
      from: from ? this.adapter.toModel(from) : null,
      to: to ? this.adapter.toModel(to) : null,
    });
  }

  private isRequired() {
    if (this.parentFormControl?.validator) {
      const validator = this.parentFormControl?.validator({} as AbstractControl);

      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  private setStartDateOnPicker(picker: NgbInputDatepicker) {
    let startDate: NgDateStructNullable = this.startDate as NgDateStructNullable;

    if (this.rangeSelection && this.fromDate$.getValue()) {
      startDate = this.fromDate$.getValue() as NgbDateStruct;
    } else if (this.rangeSelectionSplit) {
      if (this.fromDate$.getValue()?.year) {
        startDate = this.fromDate$.getValue() as NgbDateStruct;
      } else if (this.toDate$.getValue()?.year) {
        startDate = this.toDate$.getValue() as NgbDateStruct;
      }
    }

    if (!!startDate) {
      picker.startDate = startDate;
    }
  }

  toggleCalendarAriaLabel(isDateTo?: boolean): string {
    const value: string | Date = (isDateTo ? this.dateInputTo?.value : this.dateInput?.value) ?? '';

    if (!value) {
      return this.ariaLabelForButton;
    }

    const formatToLongDate = (value: string) =>
      this.formatterHelper.format(this.formatterHelper.parse(value), 'longDate');

    const date: string[] = value.toString().split(' - ') ?? '';
    const humanizedDate = date.length ? `Selected date ${formatToLongDate(date[0])}` : '';

    if (date[1]) {
      return `${humanizedDate} to ${formatToLongDate(date[1])}; ${this.ariaLabelForButton}`;
    }

    return `${humanizedDate}; ${this.ariaLabelForButton}`;
  }

  protected get btnColorClass() {
    if (!this.btnColor) {
      return null;
    }

    return `btn-${this.btnColor}`;
  }
}
