<div class="row">
  <div class="col-md-6">
    <form [formGroup]="vForm">
      <p>
        <bb-input-text-ui
          [id]="'inputText'"
          [attr.data-role]="'input-text'"
          label="Enabled text input label"
          formControlName="inputText"
          [readonly]="false"
          [aria-invalid]="vForm && vForm.invalid"
          placeholder="Please enter a value, at most 35 chars..."
          [maxLength]="35"
          [autocomplete]="'name'"
        >
        </bb-input-text-ui>
      </p>
      <p>
        <bb-input-text-ui
          [id]="'inputTextDisabled'"
          [attr.data-role]="'input-text-disabled'"
          label="Disabled text input label"
          formControlName="inputText2"
          [disabled]="true"
          placeholder="Disabled input"
        >
        </bb-input-text-ui>
      </p>
      <p>
        <bb-input-text-ui
          [id]="'inputTextMask3'"
          [attr.data-role]="'input-text'"
          label="Text input with a 5-digit mask '00000'"
          formControlName="inputText3"
          [readonly]="false"
          [aria-invalid]="vForm && vForm.invalid"
          [mask]="'0{5}'"
          [maskValidator]="true"
          placeholder="Only up to 5 digits can be entered (e.g., zipcode)"
        >
        </bb-input-text-ui>
      </p>
      <p>
        <bb-input-text-ui
          [id]="'inputTextMask4'"
          [attr.data-role]="'input-text'"
          label="Text input with a phone mask '(000) 000-0000'"
          formControlName="inputText4"
          [readonly]="false"
          [aria-invalid]="vForm && vForm.invalid"
          [mask]="'(000) 000-0000'"
          placeholder="Only 10 digits can be entered, with/without separators"
        >
        </bb-input-text-ui>
      </p>
    </form>
  </div>
</div>
