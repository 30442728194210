import { AccountData } from './account-selector.example.model';

export const accounts: AccountData[] = [
  {
    id: '1',
    name: 'Mrs J. Smith',
    balance: 102.1,
    number: 'GB29NWBK60161331926819',
    currency: 'EUR',
    favorite: true,
  },
  {
    id: '2',
    name: 'Mr J. Simposon',
    balance: 131230.1,
    number: 'GB29NWBK60161331926819',
    currency: 'EUR',
    favorite: false,
  },
  {
    id: '3',
    name: 'Eddie',
    balance: 133,
    number: 'GB29NWBK60161331926819',
    currency: 'USD',
    favorite: false,
  },
  {
    id: '4',
    name: 'Mr and Mrs J. Smith',
    balance: 126453.321,
    number: 'GB29NWBK60161331926819',
    currency: 'EUR',
    favorite: true,
  },
  {
    id: '5',
    name: 'SmallSmith',
    balance: 432.321,
    number: 'GB29NWBK60161331926819',
    currency: 'EUR',
    favorite: true,
  },
  {
    id: '6',
    name: 'Mr and SmallSmith',
    balance: 23443.321,
    number: 'GB29NWBK60161331926819',
    currency: 'EUR',
    favorite: false,
  },
  {
    id: '7',
    name: 'Mr and Small Smith',
    balance: 12423.31,
    number: 'GB29NWBK60161331926819',
    currency: 'EUR',
    favorite: false,
  },
];
