import { Component } from '@angular/core';
import { ButtonModule } from '@backbase/ui-ang/button';
import { IconModule } from '@backbase/ui-ang/icon';

@Component({
  selector: 'bb-not-found',
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [ButtonModule, IconModule],
})
export class NotFoundComponent {}
