import { getCurrencySymbol } from '@angular/common';
import { LOCALE_ID, Pipe, PipeTransform, inject } from '@angular/core';

@Pipe({
  name: 'bbAmountInputCurrencyRemap',
})
export class AmountInputCurrencyRemapPipe implements PipeTransform {
  private readonly locale = inject(LOCALE_ID);

  transform(currency: string, mapCurrency: boolean) {
    if (!currency || !mapCurrency) {
      return currency;
    }

    return getCurrencySymbol(currency, 'wide', this.locale);
  }
}
