<div class="p-3 border-bottom">
  <span>Configuration</span>
</div>
<div class="p-3">
  <form [formGroup]="vform">
    <div formGroupName="checkbox" class="mb-4">
      @for (item of checkbox.controls | keyvalue; track item.key; let i = $index) {
        <div class="mb-3">
          <bb-input-checkbox-ui
            label="{{ this.checkboxList[i].label }}"
            formControlName="{{ item.key }}"
          ></bb-input-checkbox-ui>
        </div>
      }
    </div>

    <div formGroupName="radio">
      @for (item of radio.controls | keyvalue; track item.key; let i = $index) {
        <div>
          <label>{{ radioList[i].label }}</label>
          <bb-input-radio-group-ui
            formControlName="{{ item.key }}"
            [horizontal]="true"
            [preselect]="true"
            [required]="true"
          >
            @for (radioData of radioList[i].radioItems; track radioData.value) {
              <bb-input-radio-ui [label]="radioData.label" [value]="radioData.value"></bb-input-radio-ui>
            }
          </bb-input-radio-group-ui>
        </div>
      }
    </div>

    <div formGroupName="dropdown">
      @for (item of dropdown.controls | keyvalue; track i; let i = $index) {
        <div>
          <bb-dropdown-single-select-ui
            [label]="dropdownList[i].label"
            [formControlName]="dropdownList[i].formControlName"
          >
            @for (dropdownData of dropdownList[i].dropdownItemList; track dropdownData) {
              <bb-dropdown-single-select-option-ui
                class="text-capitalize"
                [value]="dropdownData"
                [label]="dropdownData"
              ></bb-dropdown-single-select-option-ui>
            }
          </bb-dropdown-single-select-ui>
        </div>
      }
    </div>

    <div formGroupName="input">
      @for (item of input.controls | keyvalue; track i; let i = $index) {
        <div class="mt-3">
          <bb-input-text-ui
            [label]="inputList[i].label"
            [formControlName]="inputList[i].formControlName"
          ></bb-input-text-ui>
        </div>
      }
    </div>
  </form>
</div>
