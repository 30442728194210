import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-load-button-ui-showcase',
  templateUrl: './load-button-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class LoadButtonShowcaseComponent {
  showLoadingIndicator = false;
  isButtonLoading = false;
  isLoadMore = [false, false, false];
  loadMoreParams: any = [{ from: 0 }, { from: 0 }, { from: 0 }];
  localDisabled = false;

  onLoadButtonPress() {
    if (this.isButtonLoading) {
      return;
    }

    this.isButtonLoading = true;
    setTimeout(() => {
      this.isButtonLoading = false;
    }, 1500);
  }

  setLoadingParams(params: any): void {
    this.isLoadMore[0] = true;
    this.loadMoreParams[0] = params;
    window.setTimeout(() => {
      this.isLoadMore[0] = false;
    }, 1500);
  }

  setLoadingParamsWithIcon(params: any): void {
    this.isLoadMore[1] = true;
    this.loadMoreParams[1] = params;
    window.setTimeout(() => {
      this.isLoadMore[1] = false;
    }, 1500);
  }

  setLoadingParamsOnlyIcon(params: any): void {
    this.isLoadMore[2] = true;
    this.loadMoreParams[2] = params;
    window.setTimeout(() => {
      this.isLoadMore[2] = false;
    }, 1500);
  }
}
