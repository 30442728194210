import '@angular/localize/init';

export const defaultAriaLabels = {
  accept: $localize`:Aria label for accept button@@input-inline-edit-ui.button.aria-label.accept:Accept`,
  edit: $localize`:Aria label for edit button@@input-inline-edit-ui.button.aria-label.edit:Edit`,
  cancel: $localize`:Aria label for cancel button@@input-inline-edit-ui.button.aria-label.cancel:Cancel`,
  input: $localize`:Aria label for input@@input-inline-edit-ui.input.aria-label:Input`,
};

export const defaultTooltips = { ...defaultAriaLabels };
