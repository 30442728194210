import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * @name ModeHeaderComponent
 *
 * @description
 * Component that displays a mode header.
 */
@Component({
  selector: 'bb-mode-header-ui',
  templateUrl: './mode-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModeHeaderComponent {
  /**
   * The main title to display.
   */
  @Input() title?: string;

  /**
   * The smaller, secondary title to display.
   */
  @Input() secondaryTitle?: string;

  /**
   * Whether or not to show the close button. Defaults to true.
   */
  @Input() showCloseButton = true;

  /**
   * The aria label to describe the close button to screen reader users.
   */
  @Input() closeButtonAriaLabel?: string;

  /**
   * Whether or not the order of title elements is reversed.
   */
  @Input() reverseTitleOrder = true;

  /**
   * Event fired when the close button is clicked.
   */
  @Output() close = new EventEmitter<void>();
}
