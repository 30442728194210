import { NgModule } from '@angular/core';
import { IconModule } from '@backbase/ui-ang/icon';
import { FormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { FocusModule } from '@backbase/ui-ang/focus';

import { DropdownSingleSelectComponent } from './dropdown-single-select.component';
import { DropdownSingleSelectOptionComponent } from './dropdown-single-select-option.component';

@NgModule({
  imports: [IconModule, FormsModule, BaseClassesModule, FocusModule],
  declarations: [DropdownSingleSelectComponent, DropdownSingleSelectOptionComponent],
  exports: [DropdownSingleSelectComponent, DropdownSingleSelectOptionComponent],
})
export class DropdownSingleSelectModule {}
