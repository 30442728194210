import { Component, ContentChild, Directive, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';

@Directive({ selector: 'ng-template[bbCustomProductItemDebitCardTitle]' })
export class CustomProductItemDebitCardTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemDebitCardComponent
 *
 * @description
 * A component that renders a styled-container or card for debit card.
 */
@Component({
  selector: 'bb-product-item-debit-card-ui',
  templateUrl: './product-item-debit-card.component.html',
})
export class ProductItemDebitCardComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemDebitCardTitleDirective, { static: true })
  customProductItemDebitCardTitle: CustomProductItemDebitCardTitleDirective | undefined;

  readonly hostRef: ProductItemDebitCardComponent = this;
}
