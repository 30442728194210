<h2>Small</h2>
<div class="bb-list bb-block bb-block--md">
  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <h3>Variants</h3>
    @for (button of buttons; track $index) {
      <button
        bbButton
        aria-label="A button with an icon"
        [color]="button.color"
        buttonSize="sm"
        [attr.data-role]="'btn-' + button.color + '-sm-def'"
        class="m-2"
        (click)="showNotification(button.color)"
      >
        {{ button.text }}
      </button>
    }
    @for (button of buttonsWithIcon; track $index) {
      <button
        bbButton
        aria-label="A button with an icon"
        [color]="button.color"
        buttonSize="sm"
        [attr.data-role]="'btn-icon-' + button.color + '-sm-def'"
        class="m-2"
      >
        <bb-icon-ui [color]="button.icon.color" [name]="button.icon.name" [size]="'sm'" cropped></bb-icon-ui>
        <span>{{ button.text }}</span>
      </button>
    }
    <div>
      @for (button of disabledButtons; track $index) {
        <button
          bbButton
          aria-label="A button with an icon"
          buttonSize="sm"
          [attr.data-role]="'btn-' + button.color + '-sm-disabled'"
          [disabled]="true"
          class="m-2"
        >
          {{ button.text }}
        </button>
      }
      @for (button of disabledButtonsWithIcon; track $index) {
        <button
          bbButton
          aria-label="A button with an icon"
          buttonSize="sm"
          [attr.data-role]="'btn-icon-' + button.color + '-sm-disabled'"
          [disabled]="true"
          class="m-2"
        >
          <bb-icon-ui [name]="'add'" [size]="'sm'" cropped></bb-icon-ui>
          <span>{{ button.text }}</span>
        </button>
      }
    </div>
  </div>
  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <h3>Icon</h3>
  </div>
  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <button
      bbButton
      aria-label="A button with an icon"
      color="primary"
      circle="true"
      buttonSize="sm"
      class="m-2"
      [attr.data-role]="'icon-primary-sm-def'"
    >
      <bb-icon-ui name="search" aria-label="Search icon"></bb-icon-ui>
    </button>
    <button
      bbButton
      aria-label="A button with an icon"
      color="secondary"
      [circle]="true"
      buttonSize="sm"
      class="m-2"
      [attr.data-role]="'icon-secondary-sm-def'"
    >
      <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
    </button>
    <button
      bbButton
      aria-label="A button with an icon"
      color="tertiary"
      circle="true"
      buttonSize="sm"
      class="m-2"
      [attr.data-role]="'icon-tertiary-sm-def'"
    >
      <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
    </button>
    <button
      [attr.data-role]="'icon-dark-sm-def'"
      bbButton
      aria-label="A button with an icon"
      buttonSize="sm"
      [circle]="true"
      class="m-2"
      color="dark"
    >
      <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
    </button>
    <button
      [attr.data-role]="'icon-neutral-sm-def'"
      bbButton
      aria-label="A button with an icon"
      buttonSize="sm"
      [circle]="true"
      class="m-2"
      color="neutral"
    >
      <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
    </button>
    <button
      bbButton
      aria-label="A button with an icon"
      color="success"
      circle="true"
      buttonSize="sm"
      class="m-2"
      [attr.data-role]="'icon-success-sm-def'"
    >
      <bb-icon-ui name="check"></bb-icon-ui>
    </button>
    <button
      bbButton
      aria-label="A button with an icon"
      color="danger"
      circle="true"
      buttonSize="sm"
      class="m-2"
      [attr.data-role]="'icon-danger-sm-def'"
    >
      <bb-icon-ui name="times"></bb-icon-ui>
    </button>
    <div>
      <button
        bbButton
        aria-label="A button with an icon"
        color="primary"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-primary-sm-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="search"></bb-icon-ui>
      </button>
      <button
        bbButton
        aria-label="A button with an icon"
        color="secondary"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-secondary-sm-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
      </button>
      <button
        [attr.data-role]="'icon-tertiary-sm-disabled'"
        [disabled]="true"
        bbButton
        aria-label="A button with an icon"
        [circle]="true"
        class="m-2"
        color="tertiary"
      >
        <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
      </button>
      <button
        bbButton
        aria-label="A button with an icon"
        color="dark"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-dark-sm-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
      </button>
      <button
        bbButton
        aria-label="A button with an icon"
        color="success"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-success-sm-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="check"></bb-icon-ui>
      </button>
      <button
        bbButton
        aria-label="A button with an icon"
        color="danger"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-danger-sm-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="times"></bb-icon-ui>
      </button>
    </div>
  </div>
  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <h3>On Color</h3>
  </div>
  <div class="bb-list__item--no-separator bb-bock bb-block--lg showcase-button-on-color btn-on-color">
    @for (button of buttonsOnColor; track $index) {
      <button
        bbButton
        aria-label="A button with an icon"
        [color]="button.color"
        buttonSize="sm"
        [attr.data-role]="'btn-' + button.color + 'on-color-sm-def'"
        class="m-2"
        (click)="showNotification(button.color)"
      >
        {{ button.text }}
      </button>
    }
    @for (button of buttonsOnColor; track $index) {
      <button
        bbButton
        aria-label="A button with an icon"
        [color]="button.color"
        circle="true"
        buttonSize="sm"
        [attr.data-role]="'btn-' + button.color + 'on-color-sm-def'"
        class="m-2"
        (click)="showNotification(button.color)"
      >
        <bb-icon-ui name="search"></bb-icon-ui>
      </button>
    }
  </div>

  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <h3>Button group</h3>
    <div class="bb-stack bb-stack">
      <div class="btn-group btn-group-sm bb-stack__item" data-role="btn-group-sm-icons">
        <button (click)="setActive(0, 'sm-1')" [color]="groups['sm-1'][0]" bbButton aria-label="A button with an icon">
          <bb-icon-ui name="list" [size]="'sm'"></bb-icon-ui>
        </button>
        <button (click)="setActive(1, 'sm-1')" [color]="groups['sm-1'][1]" bbButton aria-label="A button with an icon">
          <bb-icon-ui name="view-module" [size]="'sm'"></bb-icon-ui>
        </button>
      </div>
      <div class="btn-group btn-group-sm bb-stack__item" data-role="btn-group-sm-2-options">
        <button (click)="setActive(0, 'sm-2')" [color]="groups['sm-2'][0]" bbButton aria-label="A button with an icon">
          Left
        </button>
        <button (click)="setActive(1, 'sm-2')" [color]="groups['sm-2'][1]" bbButton aria-label="A button with an icon">
          Right
        </button>
      </div>
      <div class="btn-group btn-group-sm bb-stack__item" data-role="btn-group-sm-3-options">
        <button (click)="setActive(0, 'sm-3')" [color]="groups['sm-3'][0]" bbButton aria-label="A button with an icon">
          Left
        </button>
        <button (click)="setActive(1, 'sm-3')" [color]="groups['sm-3'][1]" bbButton aria-label="A button with an icon">
          Center
        </button>
        <button (click)="setActive(2, 'sm-3')" [color]="groups['sm-3'][2]" bbButton aria-label="A button with an icon">
          Right
        </button>
      </div>
    </div>
  </div>
  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <h3>Button bar</h3>
    <div class="bb-button-bar bb-block bb-block-md">
      @for (button of barButtons; track $index) {
        <button
          bbButton
          aria-label="A button with an icon"
          class="bb-button-bar__button"
          [color]="button.color"
          buttonSize="sm"
          [attr.data-role]="'btn-' + button.color + '-sm-def'"
        >
          {{ button.text }}
        </button>
      }
    </div>
  </div>
  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <h3>Button bar reverse</h3>
    <div class="bb-button-bar bb-button-bar--reverse">
      @for (button of barButtons; track $index) {
        <button
          bbButton
          aria-label="A button with an icon"
          class="bb-button-bar__button"
          [color]="button.color"
          buttonSize="sm"
          [attr.data-role]="'btn-' + button.color + '-sm-def'"
        >
          {{ button.text }}
        </button>
      }
    </div>
  </div>
  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <h3>Button bar center</h3>
    <div class="bb-button-bar bb-button-bar--center bb-bock bb-block--md">
      @for (button of barButtons; track $index) {
        <button
          bbButton
          aria-label="A button with an icon"
          class="bb-button-bar__button"
          [color]="button.color"
          buttonSize="sm"
          [attr.data-role]="'btn-' + button.color + '-sm-def'"
        >
          {{ button.text }}
        </button>
      }
    </div>
    <div class="bb-button-bar bb-button-bar--center bb-block bb-block--md">
      <bb-load-button-ui [color]="'primary'" [isLoading]="true" [size]="8" [from]="1" [totalCount]="2" class="m-3">
        Load more
      </bb-load-button-ui>
    </div>
  </div>

  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <h3>Button bar with bigger space between button elements</h3>
    <div class="bb-button-bar bb-button-bar--reverse bb-button-bar--density-lg">
      @for (button of barButtons; track $index) {
        <button
          bbButton
          aria-label="A button with an icon"
          class="bb-button-bar__button"
          [color]="button.color"
          [attr.data-role]="'btn-' + button.color + '-sm-def'"
        >
          {{ button.text }}
        </button>
      }
    </div>
  </div>
</div>
<h2>Medium</h2>
<div class="bb-list">
  <div class="bb-list__item--no-separator">
    <h3>Variants</h3>
    @for (button of buttons; track $index) {
      <button
        bbButton
        aria-label="A button with an icon"
        [color]="button.color"
        buttonSize="md"
        [attr.data-role]="'btn-' + button.color + '-md-def'"
        class="m-2"
        (click)="showNotification(button.color)"
      >
        {{ button.text }}
      </button>
    }
    @for (button of buttonsWithIcon; track $index) {
      <button
        bbButton
        aria-label="A button with an icon"
        [color]="button.color"
        buttonSize="md"
        [attr.data-role]="'btn-icon-' + button.color + '-md-def'"
        class="m-2"
      >
        <bb-icon-ui [color]="button.icon.color" [name]="button.icon.name" [size]="'md'" cropped></bb-icon-ui>
        <span>{{ button.text }}</span>
      </button>
    }
    <div>
      @for (button of disabledButtons; track $index) {
        <button
          bbButton
          aria-label="A button with an icon"
          buttonSize="md"
          [attr.data-role]="'btn-' + button.color + '-md-disabled'"
          [disabled]="true"
          class="m-2"
        >
          {{ button.text }}
        </button>
      }
      @for (button of disabledButtonsWithIcon; track $index) {
        <button
          bbButton
          aria-label="A button with an icon"
          buttonSize="md"
          [attr.data-role]="'btn-icon-' + button.color + '-md-disabled'"
          [disabled]="true"
          class="m-2"
        >
          <bb-icon-ui [name]="'add'" [size]="'md'" cropped></bb-icon-ui>
          <span>{{ button.text }}</span>
        </button>
      }
    </div>
  </div>
  <div class="bb-list__item--no-separator">
    <h3>Icon</h3>
    <button
      bbButton
      aria-label="A button with an icon"
      color="primary"
      circle="true"
      buttonSize="md"
      class="m-2"
      [attr.data-role]="'icon-primary-md-def'"
    >
      <bb-icon-ui name="search"></bb-icon-ui>
    </button>
    <button
      bbButton
      aria-label="A button with an icon"
      color="secondary"
      [circle]="true"
      buttonSize="md"
      class="m-2"
      [attr.data-role]="'icon-secondary-md-def'"
    >
      <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
    </button>
    <button
      bbButton
      aria-label="A button with an icon"
      color="tertiary"
      circle="true"
      buttonSize="md"
      class="m-2"
      [attr.data-role]="'icon-tertiary-md-def'"
    >
      <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
    </button>
    <button
      [attr.data-role]="'icon-dark-md-def'"
      bbButton
      aria-label="A button with an icon"
      buttonSize="md"
      [circle]="true"
      class="m-2"
      color="dark"
    >
      <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
    </button>
    <button
      [attr.data-role]="'icon-neutral-md-def'"
      bbButton
      aria-label="A button with an icon"
      buttonSize="md"
      [circle]="true"
      class="m-2"
      color="neutral"
    >
      <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
    </button>
    <button
      bbButton
      aria-label="A button with an icon"
      color="success"
      circle="true"
      buttonSize="md"
      class="m-2"
      [attr.data-role]="'icon-success-md-def'"
    >
      <bb-icon-ui name="check"></bb-icon-ui>
    </button>
    <button
      bbButton
      aria-label="A button with an icon"
      color="danger"
      circle="true"
      buttonSize="md"
      class="m-2"
      [attr.data-role]="'icon-danger-md-def'"
    >
      <bb-icon-ui name="times"></bb-icon-ui>
    </button>
    <div>
      <button
        bbButton
        aria-label="A button with an icon"
        color="primary"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-primary-md-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="search"></bb-icon-ui>
      </button>
      <button
        bbButton
        aria-label="A button with an icon"
        color="secondary"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-secondary-md-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
      </button>
      <button
        [attr.data-role]="'icon-tertiary-md-disabled'"
        [disabled]="true"
        bbButton
        aria-label="A button with an icon"
        [circle]="true"
        class="m-2"
        color="tertiary"
      >
        <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
      </button>
      <button
        bbButton
        aria-label="A button with an icon"
        color="dark"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-dark-md-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="ellipsis-h"></bb-icon-ui>
      </button>
      <button
        bbButton
        aria-label="A button with an icon"
        color="success"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-success-md-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="check"></bb-icon-ui>
      </button>
      <button
        bbButton
        aria-label="A button with an icon"
        color="danger"
        [circle]="true"
        class="m-2"
        [attr.data-role]="'icon-danger-md-disabled'"
        [disabled]="true"
      >
        <bb-icon-ui name="times"></bb-icon-ui>
      </button>
    </div>
  </div>
  <div class="bb-list__item--no-separator bb-bock bb-block--lg">
    <h3>On Color</h3>
  </div>
  <div class="bb-list__item--no-separator bb-bock bb-block--lg btn-on-color" style="background: #274fc4">
    @for (button of buttonsOnColor; track $index) {
      <button
        bbButton
        aria-label="A button with an icon"
        [color]="button.color"
        buttonSize="md"
        [attr.data-role]="'btn-' + button.color + 'on-color-md-def'"
        class="m-2"
        (click)="showNotification(button.color)"
      >
        {{ button.text }}
      </button>
    }
    @for (button of buttonsOnColor; track $index) {
      <button
        bbButton
        aria-label="A button with an icon"
        [color]="button.color"
        circle="true"
        buttonSize="md"
        [attr.data-role]="'btn-' + button.color + 'on-color-md-def'"
        class="m-2"
        (click)="showNotification(button.color)"
      >
        <bb-icon-ui name="search"></bb-icon-ui>
      </button>
    }
  </div>
  <div class="bb-list__item--no-separator">
    <h3>Button group</h3>
    <div class="bb-stack bb-stack">
      <div class="btn-group btn-group-md bb-stack__item" data-role="btn-group-md-icons">
        <button (click)="setActive(0, 'md-1')" [color]="groups['md-1'][0]" bbButton aria-label="A button with an icon">
          <bb-icon-ui name="list"></bb-icon-ui>
        </button>
        <button (click)="setActive(1, 'md-1')" [color]="groups['md-1'][1]" bbButton aria-label="A button with an icon">
          <bb-icon-ui name="view-module"></bb-icon-ui>
        </button>
      </div>
      <div class="btn-group btn-group-md bb-stack__item" data-role="btn-group-md-2-options">
        <button (click)="setActive(0, 'md-2')" [color]="groups['md-2'][0]" bbButton aria-label="A button with an icon">
          Left
        </button>
        <button (click)="setActive(1, 'md-2')" [color]="groups['md-2'][1]" bbButton aria-label="A button with an icon">
          Right
        </button>
      </div>
      <div class="btn-group btn-group-md bb-stack__item" data-role="btn-group-md-3-options">
        <button (click)="setActive(0, 'md-3')" [color]="groups['md-3'][0]" bbButton aria-label="A button with an icon">
          Left
        </button>
        <button (click)="setActive(1, 'md-3')" [color]="groups['md-3'][1]" bbButton aria-label="A button with an icon">
          Center
        </button>
        <button (click)="setActive(2, 'md-3')" [color]="groups['md-3'][2]" bbButton aria-label="A button with an icon">
          Right
        </button>
      </div>
    </div>
  </div>
</div>
