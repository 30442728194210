<h4>Default downloadable file attachments</h4>
<div class="row">
  @for (attachment of defaultFileAttachmentsList; track $index) {
    <div class="col-auto">
      <div class="bb-block bb-block--md">
        <bb-file-attachment-ui
          [name]="attachment.name"
          [size]="attachment.size"
          [loading]="attachment.loading"
          [fileContent]="attachment.fileContent"
        ></bb-file-attachment-ui>
      </div>
    </div>
  }
</div>
<br />
<h4>Disabled file attachments</h4>
<div class="row">
  @for (attachment of defaultFileAttachmentsList; track $index) {
    <div class="col-auto">
      <div class="bb-block bb-block--md">
        <bb-file-attachment-ui
          disabled="true"
          [name]="attachment.name"
          [size]="attachment.size"
        ></bb-file-attachment-ui>
      </div>
    </div>
  }
</div>
<br />
<h4>Deletable file attachments with disabled download</h4>
<div class="row">
  @for (attachment of deletableFileAttachmentsList; track i; let i = $index) {
    <div class="col-auto">
      <div class="bb-block bb-block--md">
        <bb-file-attachment-ui
          [name]="attachment.name"
          [deletable]="attachment.deletable"
          [disabled]="attachment.disabled"
          [size]="attachment.size"
          [loading]="attachment.loading"
          (delete)="deleteAttachment(deletableFileAttachmentsList, i)"
          [fileContent]="attachment.fileContent"
        ></bb-file-attachment-ui>
      </div>
    </div>
  }
</div>
<br />
<h4>File attachments that fill their container</h4>
<div class="row">
  @for (attachment of defaultFileAttachmentsList; track i; let i = $index) {
    <div class="col-md-4">
      <div class="bb-block bb-block--md">
        <bb-file-attachment-ui
          [name]="attachment.name"
          [size]="attachment.size"
          [loading]="attachment.loading"
          [fileContent]="attachment.fileContent"
          [block]="true"
        ></bb-file-attachment-ui>
      </div>
    </div>
  }
</div>
