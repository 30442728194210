<div class="paragraph">
<p>These guidelines aim to provide best practices when working with data in Backbase Products  with the purpose of keeping consistency in both content and code.<br>
By default, we use the en-US locale, which means we use American English and formats as a base before translating to other locales,  so be mindful that the following guidelines are subject to change according to locale settings in the app.</p>
</div>
<div class="sect1">
<h2 id="_format_types">Format types</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Amounts in Backbase products can be changed to fit different use cases, however, formatting of amounts change according to locale settings configured in the application.</p>
</div>
<div class="sect2">
<h3 id="_whole_and_decimal_amounts">Whole and decimal amounts</h3>
<div class="paragraph">
<p>Any amount can be displayed as whole or decimal. Amounts are grouped in three digits. Right of the decimal point, usual practice is to have two digits.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/amount-whole-decimal.png" alt="Amount Whole and Decimal">
</div>
</div>
<div class="paragraph">
<p>The decimal separator also follows local countries' standards. In Backbase we use points, following US format, but commas <em>(e.g. €5,00)</em> are common separators used in other countries, so be mindful when working with different locales.</p>
</div>
</div>
<div class="sect2">
<h3 id="_displaying_currencies">Displaying currencies</h3>
<div class="paragraph">
<p>When displaying amounts with currency, the display format will also be based on the local preferences, however, depending on the use case, this currency can be displayed as a symbol, as an ISO Code or as a mix of both.</p>
</div>
</div>
<div class="sect2">
<h3 id="_amounts_with_currency_symbol">Amounts with currency symbol</h3>
<div class="paragraph">
<p>When displaying a currency symbol with amounts, the symbol is placed <strong>before</strong> the amount <strong>without spaces</strong>.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/amount-currency-symbol.png" alt="Amount with a currency symbol">
</div>
</div>
<div class="paragraph">
<p>This follows most format for currencies in the English-speaking world and Latin America, but again be mindful that in many European countries such as France, Germany and Scandinavian countries, the symbol is usually placed after the amount <em>(e.g., 20,50 €)</em>.</p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_amounts_with_currency_code">Amounts with currency code</h2>
<div class="sectionbody">
<div class="paragraph">
<p>For amounts displaying the currency with a code, Backbase follows the ISO 4217 standards, showing the three digit code <strong>before the amount</strong>, separated by <strong>a hard space.</strong></p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/amount-currency-code.png" alt="Amount with a currency code">
</div>
</div>
<div class="paragraph">
<p>Note that, same as for the currency symbol, the position of this code is determined not by the currency, but by the locale, in several European Countries the order is reversed; the amount is followed by a hard space and the ISO code (e.g., 20,50 EUR)</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_amounts_with_both_currency_code_and_symbol">Amounts with both currency code and symbol</h2>
<div class="sectionbody">
<div class="paragraph">
<p>There might be special use cases where the type of a specific currency, such as the <em>peso</em> or the <em>dollar</em> need to be defined. In this cases both Currency code and Symbol can be displayed together.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/amount-currency-symbol-code.png" alt="An amount with currency symbol + code">
</div>
</div>
<div class="paragraph">
<p>For this format, write the <strong>first two letters of the code</strong>, immediately followed by <strong>the symbol</strong> and the amount. Don’t add hard spaces between the letters, the symbol and/or the amount.</p>
</div>
<div class="paragraph">
<p><strong>Use this format sparingly</strong>. If possible, always choose to display the full ISO Code before considering this option.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_negative_amounts">Negative amounts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>All amounts can be styled to represent negative values. Whenever this is the case, a <a href="https://www.fileformat.info/info/unicode/char/2212/index.htm"><em>minus sign</em></a> is displayed before the whole or decimal amount.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/amount-negative.png" alt="Negative amount">
</div>
</div>
<div class="paragraph">
<p>When displaying negative amounts with a currency symbol, place the <strong>negative sign before currency symbol</strong> immediately followed by the amount, <strong>without spaces</strong>.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/amount-negative-symbol.png" alt="Negative amount with currency symbol">
</div>
</div>
<div class="paragraph">
<p>When displaying negative amounts with a currency code, place the <strong>negative sign after currency code</strong> and <strong>before</strong> the amount, separated <strong>with a space</strong>.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/amount-negative-code.png" alt="Negative amount with code">
</div>
</div>
<div class="paragraph">
<p>Please note that for accessibility reasons, we recommend you always choose the <em>minus sign</em> instead of a <em>minus hyphen</em> or <em>en dash.</em> Also, by mindful that some screen readers might misread negative amounts when accompanied by a code rather than a symbol <em>(e.g. USD −30.10</em> can be read as <em>“USD minus thirty point one zero</em>” instead of <em>“minus thirty US dollars and 10 cents”.</em></p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_long_amounts">Long amounts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Amounts that are greater than 100,000 can be abbreviated so they are easier to read. Whenever you abbreviate long amounts, truncate the number after three digits.</p>
</div>
<div class="paragraph">
<p>Units of measurement are displayed in <strong>uppercase,</strong> as an abbreviation such as <strong>K</strong> (thousand), <strong>M</strong> (million), <strong>B</strong> (billion), and <strong>T</strong> (trillion), separated by <strong>a space</strong> between them and the amount.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/amount-long.png" alt="Long amounts">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_percentages">Percentages</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Amounts can be displayed also as a percentage. In this cases, the <strong>percentage sign</strong> is placed <strong>after the amount</strong>, <strong>without spaces</strong> separating the numbers from the sign.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/amount-percentages.png" alt="Percentages">
</div>
</div>
</div>
</div>