<div class="bb-input-date-divided">
  @for (text of textOrder; track text) {
    @if (text === this.dateFormatParts.DD) {
      <div class="bb-input-date-divided-day">
        <label
          class="form-label"
          i18n="Day|Day label @@bb-input-date-divided-day.label"
          for="{{ id }}-day"
          [attr.data-role]="'label'"
        >
          Day
        </label>
        <input
          #inputDay
          type="number"
          inputmode="numeric"
          placeholder="DD"
          id="{{ id }}-day"
          [formControl]="dayControl"
          class="form-control"
          [readonly]="readonly"
          i18n-placeholder="DD|DD placeholder@@bb-input-date-divided-day.placeholder"
          (blur)="checkErrors(dayControl)"
        />
      </div>
    }
    @if (text === this.dateFormatParts.MM) {
      <div class="form-label bb-input-date-divided-month">
        <label
          class="form-label"
          i18n="Month|Month label @@bb-input-date-divided-month.label"
          for="{{ id }}-month"
          [attr.data-role]="'label'"
        >
          Month
        </label>
        <input
          #inputMonth
          type="number"
          inputmode="numeric"
          placeholder="MM"
          id="{{ id }}-month"
          [formControl]="monthControl"
          class="form-control"
          [readonly]="readonly"
          i18n-placeholder="MM|MM placeholder@@bb-input-date-divided-month.placeholder"
          (blur)="checkErrors(monthControl)"
        />
      </div>
    }
    @if (text === this.dateFormatParts.YY) {
      <div class="bb-input-date-divided-year">
        <label
          i18n="Year|Year label @@bb-input-date-divided-year.label"
          for="{{ id }}-year"
          [attr.data-role]="'label'"
          class="form-label"
        >
          Year
        </label>
        <input
          #inputYear
          type="number"
          inputmode="numeric"
          placeholder="YYYY"
          id="{{ id }}-year"
          [formControl]="yearControl"
          class="form-control"
          [readonly]="readonly"
          i18n-placeholder="YYYY|YYYY placeholder@@bb-input-date-divided-year.placeholder"
          (blur)="checkErrors(yearControl)"
        />
      </div>
    }
  }
</div>
