import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'bb-showcase-api',
  templateUrl: './showcase-api.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowcaseApiComponent {
  @Input() props: any[] = [];

  // TODO: decrease cyclomatic complexity
  // eslint-disable-next-line complexity
  parseDefaultValue(value: string) {
    if (value === 'true' || value === 'false') {
      return 'boolean';
    } else if (value === '' || (typeof value === 'string' && (value !== '0' || typeof Number(value) !== 'number'))) {
      return 'string';
    } else if (value === '0' || value === '1' || (Number(value) && typeof Number(value) === 'number')) {
      return 'number';
    } else {
      return '';
    }
  }
}
