// Requires Promise and Array polyfills for older browsers
let savedVariables: { [key: string]: string } | null = null;

function findStyleBlocks(): NodeListOf<Element> {
  return document.querySelectorAll('style:not(.inserted),link[rel="stylesheet"]');
}

async function getStyleBlocksTexts(styleBlocks: NodeListOf<Element>) {
  return Promise.all(Array.from(styleBlocks).map((block) => getStyleBlockText(block).catch(ignoreErrorForBlock)));
}

// the error for specific block should be ignored, to enable search in other blocks
function ignoreErrorForBlock(err: Error): '' {
  console.error(err);

  return '';
}

async function getStyleBlockText(styleBlock: Element): Promise<string> {
  switch (styleBlock.nodeName) {
    case 'STYLE':
      return getTextForStyleNode(styleBlock);
    case 'LINK':
      return getTextForLinkNode(styleBlock);
    default:
      return '';
  }
}

function getTextForStyleNode(styleBlock: Element): string {
  return styleBlock.innerHTML;
}

async function getTextForLinkNode(styleBlock: Element): Promise<string> {
  const url: string | null = styleBlock.getAttribute('href');
  if (!url) {
    return Promise.resolve('');
  }

  return new Promise<string>((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.overrideMimeType('text/css;');
    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        resolve(request.responseText);
      } else {
        reject(`Error returned from: ${url}`);
      }
    };

    request.onerror = function () {
      reject(`Got nothing from: ${url}`);
    };

    request.send();
  });
}

function extractVariablesListFromCssText(css: string): RegExpMatchArray | null {
  return css.match(/(-{2}\w[\w-]*)(?:\s*:\s*)([^;]*)(;|})/g);
}

function reduceVariablesList(variablesList: RegExpMatchArray) {
  return variablesList.reduce((variables, variable) => {
    const variablesWithValues = variable.split(/;\s*/);

    return {
      ...variables,
      ...variablesWithValues.reduce((variableSet, variableWithValue) => {
        const splitVariable = variableWithValue.split(/:\s*/);
        // Note: For minified css files, the semicolon from last declaration statement is removed by angular cli
        // for optimization purposes. For example below will be sample code in minified css file,
        // :root{--chart-color-6:#c22327;--chart-color-2:#00a1e0}.chart-positive-color{color:#2e7d32}
        // variableWithValue will gives us something like below for '--chart-color-2':-
        // "--chart-color-2: #00a1e0}.chart-positive-color{color:#2e7d32"
        // console.log(splitVariable); // ["--chart-color-2", "#00a1e0}.chart-positive-color{color:#2e7d32"]
        // splitVariable has wrong value for --chart-color-2 variable.
        // we need to split the splitVariable further with "}" so that we can get the right value.
        const variableValue = splitVariable[1] ? splitVariable[1].split('}')[0] : splitVariable[1];

        return {
          ...variableSet,
          [splitVariable[0]]: variableValue,
        };
      }, {} as { [key: string]: string }),
    };
  }, {} as { [key: string]: string });
}

async function getCssVariableForOldBrowsers(name: string): Promise<string | undefined> {
  if (savedVariables) {
    return savedVariables[name];
  }

  const styleBlocks = findStyleBlocks();
  if (!styleBlocks || !styleBlocks.length) {
    return Promise.resolve(undefined);
  }

  const styleBlocksTexts = await getStyleBlocksTexts(styleBlocks);
  const variablesList = extractVariablesListFromCssText(styleBlocksTexts.join(''));
  if (!variablesList || !variablesList.length) {
    return Promise.resolve(undefined);
  }

  const cssVariables = reduceVariablesList(variablesList);
  savedVariables = cssVariables;

  return cssVariables[name];
}

function getCssVariableForModernBrowsers(name: string, elementName: string = ':root'): string {
  const element = document.querySelector(elementName);
  if (!element) {
    return '';
  }
  const computedStyle = getComputedStyle(element);

  return computedStyle.getPropertyValue(name);
}

export async function getCssVariable(
  name: string,
  elementName: string = ':root',
  searchEverywhere = false,
): Promise<string | undefined> {
  if (!searchEverywhere && modernCSSIsSupported()) {
    return getCssVariableForModernBrowsers(name, elementName);
  }

  return getCssVariableForOldBrowsers(name);
}

export function modernCSSIsSupported() {
  const CSS = (window as any).CSS;

  return !!(CSS && CSS.supports);
}
