<div class="bb-block bb-block--lg">
  <h2>App configuration</h2>
  <div>
    <code>iban: {{ configuration.iban | json }}</code>
  </div>
  <div>
    <code>bban: {{ configuration.bban | json }}</code>
  </div>
  <div>
    <code>cardNumber: {{ configuration.cardNumber | json }}</code>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h3>With account number and type</h3>
  @for (account of accounts; track $index) {
    <h4>{{ account.description }}</h4>
    <bb-account-number-ui
      [number]="account.number"
      [unmaskable]="true"
      [type]="account.typeOrFormat"
      [masked]="account.masked$ | async"
      (unmask)="onUnMask(account)"
      (mask)="onMask(account)"
    >
    </bb-account-number-ui>
  }
</div>
<div class="bb-block bb-block--lg">
  @for (arrangementItem of arrangementItems; track $index) {
    <h3>{{ arrangementItem.description }}</h3>
    <bb-account-number-ui
      [account]="arrangementItem.account"
      [unmaskable]="arrangementItem.unmaskable"
      [masked]="arrangementItem.masked$ | async"
      (mask)="onAccountMask(arrangementItem)"
      (unmask)="onAccountUnMask(arrangementItem)"
    >
    </bb-account-number-ui>
  }
</div>
