import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

export const pathV2Guard: CanActivateFn = (_, state: RouterStateSnapshot) => {
  const urlWithoutV2 = (url: string) => url.replace(/^\/v2/, '').replace(/#.*/, ''); // replace v2 in path and hash

  if (state.url.search(/^\/v2/) !== -1) {
    return inject(Router).createUrlTree([urlWithoutV2(state.url)], { preserveFragment: true });
  }

  return true;
};
