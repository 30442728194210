import { Component } from '@angular/core';
import { IProgressTrackerConfig, IEventPayload, EventStatus, EventType, Eta } from '@backbase/ui-ang/progress-tracker';
@Component({
  selector: 'bb-progress-tracker-ui-showcase',
  templateUrl: './progress-tracker-showcase.component.html',
})
export class ProgressTrackerShowcaseComponent {
  show = false;
  showCustomized = false;
  showCustomizedItemTemplate = false;
  eventId = 0;
  eventArr: IProgressTrackerConfig[] = [];
  eventType = EventType;
  hostRef = this;
  triggerProgressTracker(progressTrackerType: string) {
    if (progressTrackerType === 'default') {
      this.show = true;
      this.showCustomized = false;
      this.showCustomizedItemTemplate = false;
    } else if (progressTrackerType === 'custom') {
      this.showCustomized = true;
      this.show = false;
      this.showCustomizedItemTemplate = false;
    } else if (progressTrackerType === 'customItem') {
      this.showCustomized = false;
      this.show = false;
      this.showCustomizedItemTemplate = true;
    }
    this.eventId++;
    let eta = 75;
    const data: IEventPayload = {
      eta: this.getEta(''),
      progressStartTime: new Date().getTime(),
      current: 10,
      size: 100,
      name: 'sample_file.csv',
      actionType: EventType.FILE_UPLOAD,
      itemId: this.eventId.toString(),
    };
    const obj: IProgressTrackerConfig = {
      payload: data,
      id: this.eventId.toString(),
      eventStatus: EventStatus.IN_PROGRESS,
    };
    this.eventArr = [
      ...this.eventArr.filter((item: IProgressTrackerConfig) => item.eventStatus === EventStatus.IN_PROGRESS),
      obj,
    ];
    const interval = setInterval(
      (id: string, eventPayload: IEventPayload) => {
        eta -= 5;
        eventPayload.eta = this.getEta(this.secondsToEta(eta));
        eventPayload.current += 20;
        this.eventArr = this.eventArr.map((item: IProgressTrackerConfig) => {
          if (item.id === id) {
            item.eventStatus = EventStatus.IN_PROGRESS;
            item.payload = data;
          }

          return item;
        });
        if (eventPayload.current >= eventPayload.size) {
          this.eventArr = this.eventArr.map((item: IProgressTrackerConfig) => {
            if (item.id === id) {
              item.eventStatus = EventStatus.COMPLETED;
              item.payload = data;
            }

            return item;
          });
          clearInterval(interval);
        }
      },
      1000,
      this.eventId.toString(),
      data,
    );
  }

  public cancelTracker = (id: string) => {
    const index = this.eventArr.findIndex((item: any) => item.id === id);
    if (index > -1) {
      this.eventArr.splice(index, 1);
    }
  };

  secondsToEta(sec: number): string {
    return Number.isFinite(sec) ? new Date(sec * 1000).toISOString().substr(11, 8) : '';
  }

  getEta(etaString: string): Eta {
    const defaultValue = etaString !== '' ? 0 : -1;
    const etaArray = etaString.split(':');

    return {
      hours: Number(etaArray[0]) || defaultValue,
      mins: Number(etaArray[1]) || defaultValue,
      secs: Number(etaArray[2]) || defaultValue,
    };
  }
}
