@for (modeHeader of modeHeaders; track $index) {
  <div class="bb-block bb-block--xl">
    <bb-mode-header-ui
      [title]="modeHeader.title"
      [secondaryTitle]="modeHeader.secondaryTitle"
      [showCloseButton]="modeHeader.showCloseButton"
      [closeButtonAriaLabel]="modeHeader.closeButtonAriaLabel"
      [reverseTitleOrder]="modeHeader.reverseTitleOrder"
    >
      @if (modeHeader.displayIcon) {
        <bb-icon-ui mode-header-icon name="search" size="sm" color="neutral"></bb-icon-ui>
      }
      @if (modeHeader.displayStatus) {
        <bb-badge-ui mode-header-status text="Sample Text" color="success"> </bb-badge-ui>
      }
      @if (modeHeader.displayUtilityArea) {
        <bb-dropdown-menu-ui
          mode-header-utility-area
          container="body"
          icon="caret-down"
          btnColor="neutral"
          iconSize="md"
        >
          <ng-template bbDropdownLabel> <span>Actions</span></ng-template>
          <ng-template bbDropdownMenuItem>
            <button class="dropdown-item" type="button">Action One</button>
            <button class="dropdown-item" type="button">Action Two</button>
          </ng-template>
        </bb-dropdown-menu-ui>
      }
      @if (modeHeader.multiButtonUtilityArea) {
        <ng-container mode-header-utility-area>
          <button bbButton buttonSize="md" type="button" color="neutral" class="bb-button-bar__button" [circle]="true">
            <bb-icon-ui name="add" size="md" cropped></bb-icon-ui>
          </button>
          <button bbButton buttonSize="md" type="button" color="neutral" class="bb-button-bar__button" [circle]="true">
            <bb-icon-ui name="user" size="md" cropped></bb-icon-ui>
          </button>
          <button bbButton buttonSize="md" type="button" color="neutral" class="bb-button-bar__button" [circle]="true">
            <bb-icon-ui name="payment" size="md" cropped></bb-icon-ui>
          </button>
        </ng-container>
      }
    </bb-mode-header-ui>
  </div>
}
