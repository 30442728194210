import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichTextEditorShowcaseComponent } from './rich-text-editor-showcase.component';
import { RichTextEditorModule } from '@backbase/ui-ang/rich-text-editor';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, RichTextEditorModule, ReactiveFormsModule],
  declarations: [RichTextEditorShowcaseComponent],
})
export class RichTextEditorShowcaseModule {}
