<div class="paragraph">
<p>Dropdown menus present a list of options that can be used to filter content in a specific context. Use to let users select an action or values from a set of choices.</p>
</div>
<div class="sect1">
<h2 id="_anatomy_of_a_dropdown_menu">Anatomy of a dropdown menu</h2>
<div class="sectionbody">
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/dropdown-menu-anatomy.png" alt="Dropdown Menu anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Dropdown trigger</strong><br>
A button, icon or selector that displays the dropdown list.</p>
</li>
<li>
<p><strong>Dropdown list</strong><br>
Contains selectable items that you can group into sub-categories.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dropdown_variations">Dropdown variations</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_dropdown_triggers">Dropdown triggers</h3>
<div class="paragraph">
<p><strong>Select Input</strong></p>
</div>
<div class="paragraph">
<p>A selection input that allow users to choose one option from a list of values. Use mostly on forms.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/dropdown-menu-select-input.png" alt="Dropdown Menu Select input">
</div>
</div>
<div class="paragraph">
<p><strong>Dropdown Button</strong></p>
</div>
<div class="paragraph">
<p>A button that triggers a dropdown list, and displays a set of options for a singular action.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/dropdown-menu-button.png" alt="Dropdown Menu button">
</div>
</div>
<div class="paragraph">
<p><strong>Split Button</strong></p>
</div>
<div class="paragraph">
<p>A button with an attached dropdown button of related actions.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/dropdown-menu-split-button.png" alt="Dropdown Menu split button">
</div>
</div>
</div>
<div class="sect2">
<h3 id="_dropdown_lists">Dropdown lists</h3>
<div class="paragraph">
<p>Opening a dropdown presents a list of options. These may be divided into logically related groups.</p>
</div>
<div class="paragraph">
<p><strong>Single Select</strong></p>
</div>
<div class="paragraph">
<p>Use a single-select to give users the ability to search and make a single choice from multiple options.<br>
image::dropdown-menu-single-select.png[Dropdown Menu single select]</p>
</div>
<div class="paragraph">
<p>📍In the future, this feature will also be available in this component:</p>
</div>
<div class="paragraph">
<p><strong>Multiple Select</strong></p>
</div>
<div class="paragraph">
<p>Use multi select to allow users to choose between multiple options from a list.<br>
image::dropdown-menu-multiple-select.png[Dropdown Menu multiple select]</p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p>Clicking outside the dropdown area closes the element.</p>
</li>
<li>
<p>Dropdown should be the topmost element on screen when open.</p>
</li>
<li>
<p>Only one dropdown is visible on screen at a time.</p>
</li>
<li>
<p>When possible, allow users to close one dropdown and open a new one with one click.</p>
</li>
<li>
<p>The height of a dropdown is not constrained.</p>
</li>
<li>
<p>A header can be added to label sections of actions in any dropdown menu.</p>
</li>
<li>
<p>Use a selector to trigger a dropdown when the user needs to choose a value.</p>
</li>
<li>
<p>Use a button to trigger a dropdown when the user needs to choose an action.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Alignment</strong><br>
By default, a dropdown menu is automatically positioned 100% from the top and along the left side of its parent, otherwise it will display right.</p>
</div>
<div class="paragraph">
<p><strong>Disabled actions</strong><br>
Displaying actions as disabled, rather than removing them, let the user know they exist under the right conditions. For example, delete is disabled when an item cannot be deleted due to a status change or entitlements setting.</p>
</div>
<div class="paragraph">
<p><strong>List style</strong><br>
When displaying the list with one single item, it&#8217;s recommended to not style the list with separators. In case the list has multiple items (account name, number and balance concatenated), it is recommended to display a horizontal separator after each item.</p>
</div>
<div class="paragraph">
<p><strong>Grouping items in a Dropdown list</strong><br>
Logically related options may be grouped together for easy scanning.</p>
</div>
<div class="paragraph">
<p><strong>Selected option</strong><br>
When a dropdown list contains values that edit some piece of data, a selected state to the item is used to indicate the current state of the value being edited.</p>
</div>
<div class="paragraph">
<p><strong>Scrolling</strong><br>
In most cases you should avoid putting an excessive number of items in the dropdown list. If necessary, the length of the menu may be capped and a scroll bar will then allow access to all items.</p>
</div>
<div class="paragraph">
<p><strong>Writing Labels</strong><br>
Labels inform users what to expect in a dropdown menu. Use clear labels for the Dropdown trigger so that users understand the purpose. Keep the label short and concise by limiting it to a single line of text.</p>
</div>
<div class="paragraph">
<p><strong>Dropdown options</strong><br>
Describe the Dropdown option succinctly in one line of text. We recommend presenting the options in alphabetical order.</p>
</div>
</div>
</div>