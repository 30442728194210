<bb-heading-ui
  heading="Transfers"
  customClassName="bb-heading-widget--de-elevated"
  headingType="h1"
  headingClasses="bb-heading-widget__heading"
  [useFirstLinkAsDefault]="true"
  [navigationItems]="navigationHeaderItems"
  (navigationAction)="navigationAction($event)"
>
</bb-heading-ui>

Navigation counter - {{ counter }}
