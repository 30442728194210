<div>
  <strong>Alphanumeric IBAN formated</strong>
  <div>{{ iban | bbIban }}</div>
</div>

<div>
  <strong>Number only IBAN formated </strong>
  <div>
    <span>{{ iban2 | bbIban }}</span>
  </div>
</div>
