/**
 * Avoid any leading or trailing "-" characters, avoid adjacent dash characters
 */
export const escapeBemModifier = (str: string) => {
  if (str.length > 1000) {
    throw new Error('Input string is too large');
  }

  return str.replace(/(^[^a-z]+|[^a-z]+$)/gi, '').replace(/[^a-z]+/gi, '-');
};

/**
 * Safely create BEM modifier class names for a BEM block:
 *
 * Example usage:
 *
 *     const iconComponent = { size: 'extra small', animate: false };
 *     getBemModifiers('bb-icon', [
 *       () => iconComponent.size,
 *       () => iconComponent.animate ? 'animate' : undefined
 *     ]);
 *
 * The above will result in: "bb-icon--extra-small"
 */
export const getBemModifiers = (blockOrElement: string, modifierFunctions: Array<() => string>) =>
  modifierFunctions
    .map((fn) => fn())
    .filter(Boolean)
    .map(escapeBemModifier)
    .map((modifier) => `${blockOrElement}--${modifier}`);
