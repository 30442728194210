import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertShowcaseComponent } from './alert-showcase.component';
import { AlertModule } from '@backbase/ui-ang/alert';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [CommonModule, AlertModule, ButtonModule],
  declarations: [AlertShowcaseComponent],
})
export class AlertShowcaseModule {}
