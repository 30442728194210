<div class="paragraph">
<p>A Card Vendor is the company group or bank who issues a user their card. It visually displays to the user their Card Number, the Cardholder’s Name and Expiry Date.</p>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Card vendors usually have their own brand guidelines on the usage and placement of these logos.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_anatomy_of_a_card_vendor">Anatomy of a Card Vendor</h2>
<div class="sectionbody">
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Card Vendor Logo</strong><br>
Logo of the payment processor like Mastercard, Visa, Maestro and so forth.</p>
</li>
<li>
<p><strong>Card background</strong><br>
Solid colors, gradients or images can be used as background for the payment card.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Ensure the background of the card has sufficient contrast with text and other visual elements in the foreground so that it doesn’t break accessibility rules.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Make color changes to card vendors as they usually have their own brand guidelines.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Ensure the background of the card has sufficient contrast with text and other visual elements in the foreground so that it’s accessible to low visibility users.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Card vender logos will resize accordingly as the card widths are fixed</strong></p>
</div>
</div>
</div>