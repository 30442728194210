<div [class.active]="active" class="bb-product-item product-item">
  <div class="product-item-content">
    <div class="bb-stack bb-stack--wrap" [ngClass]="{ 'bb-block bb-block--md': mainSectionAmount !== undefined }">
      <div class="bb-stack__item">
        @if (customProductItemTermDepositTitle) {
          <ng-container *ngTemplateOutlet="customProductItemTermDepositTitle?.templateRef; context: hostRef">
          </ng-container>
        } @else {
          <div data-role="card-title" class="bb-text-bold">
            {{ title }}
            @if (favorite) {
              <bb-icon-ui size="sm" [color]="favoriteIconColor" data-role="favorite-icon" name="star"> </bb-icon-ui>
            }
          </div>
          @if (productNumber) {
            <div
              data-role="card-sub-title"
              class="bb-subtitle bb-text-support bb-stack bb-account-info__product-number"
            >
              <span class="bb-account-info__product-number-content bb-stack__item">
                {{ productNumber | paymentCardNumber: productNumberFormat }}
              </span>
            </div>
          }
        }
      </div>
      <div class="bb-stack__break bb-stack__break--xs"></div>
      <div class="bb-stack__item bb-stack__item--push-right bb-stack__item--spacing-none bb-product-item__details">
        @if (customProductItemTermDepositDetailsFormatter) {
          <ng-container *ngTemplateOutlet="customProductItemTermDepositDetailsFormatter?.templateRef; context: hostRef">
          </ng-container>
        } @else {
          <bb-amount-ui
            class="bb-highlight bb-product-item__amount"
            [amount]="amount"
            [currency]="currency"
            [bbHighlight]="highlight"
          ></bb-amount-ui>
        }
      </div>
    </div>

    @if (customProductItemTermDepositMainSection) {
      <ng-container *ngTemplateOutlet="customProductItemTermDepositMainSection?.templateRef; context: hostRef">
      </ng-container>
    } @else {
      @if (mainSectionAmount !== undefined) {
        <div class="bb-stack bb-stack--wrap bb-subtitle bb-text-support">
          <span class="bb-stack__item bb-product-item__main-section-title">
            {{ mainSectionTitle }}
          </span>
          <bb-amount-ui
            [amount]="mainSectionAmount"
            [currency]="mainSectionCurrency"
            [bbHighlight]="highlight"
            class="bb-stack__item bb-stack__item--push-right bb-stack__item--spacing-none bb-product-item__main-section-amount"
          ></bb-amount-ui>
        </div>
      }
    }
    @if (productDetailsInfo?.owners) {
      <div class="bb-text-default bb-product-item__owners">
        <span data-role="productDetailsInfo-owners">
          {{ productDetailsInfo?.owners }}
        </span>
      </div>
    }
  </div>
</div>
