@if (collapsibleHeader) {
  <ng-container *ngTemplateOutlet="collapsibleHeader; context: headerContext"> </ng-container>
}
@if (collapsibleBody && !recreateBodyContent) {
  <div [ngbCollapse]="!isOpen">
    <ng-container *ngTemplateOutlet="collapsibleBody; context: headerContext"> </ng-container>
  </div>
}
@if (collapsibleBody && recreateBodyContent) {
  @if (!isOpen) {
    <ng-container *ngTemplateOutlet="collapsibleBody; context: headerContext"> </ng-container>
  }
}
