@if (fragmentList.length > 1) {
  <dl class="mini-menu">
    <dt class="mini-menu__title">On this page</dt>
    @for (sectionItem of fragmentList; track $index) {
      <dd class="mini-menu__item" [class.mini-menu__item--active]="currentSection === sectionItem.hash">
        <a
          class="mini-menu__item-link"
          [class.mini-menu__item-link--active]="currentSection === sectionItem.hash"
          (click)="selectSection(sectionItem)"
          [routerLink]="currentUrl"
          [fragment]="sectionItem.hash"
        >
          {{ sectionItem.text | titleclean | titlecaseExcept }}
        </a>
      </dd>
    }
  </dl>
}
