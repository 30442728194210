import { NgModule } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '@backbase/ui-ang/icon';
import { AlertComponent } from './alert.component';
import { AlertIconPipe } from './alert-icon.pipe';
import { AlertTypePipe } from './alert-type.pipe';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [NgbAlertModule, IconModule, ButtonModule],
  declarations: [AlertComponent, AlertIconPipe, AlertTypePipe],
  exports: [AlertComponent],
})
export class AlertModule {}
