import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type ProgressbarType = 'primary' | 'success' | 'info' | 'warning' | 'danger';
/**
 * @name ProgressbarComponent
 *
 * @description
 * Component that represents the progress of a task.
 */
@Component({
  selector: 'bb-progressbar-ui',
  templateUrl: './progressbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressbarComponent {
  /**
   * Accessible label for the progressbar.
   */
  @Input() ariaLabel = 'progress bar';
  /**
   * Height of the progress bar. Can take values in `px`, `em`, `rem`.
   */
  @Input() height: string | undefined;
  /**
   * Maximum value of the progressbar. Defaults to 100.
   */
  @Input() max = 100;
  /**
   * A flag indicating if the current value should be displayed within the progressbar. Defaults to false.
   */
  @Input() showValue = false;
  /**
   * A flag indicating if the progress bar should be striped. Defaults to false.
   */
  @Input() striped = false;
  /**
   * A flag indicating if the stripes of the progress bar should be animated. Defaults to false.
   */
  @Input() animated = false;
  /**
   * Type of progress bar. Takes the values `primary`, `success`, `info`, `warning` & `danger`.
   */
  @Input() type: ProgressbarType | undefined;
  /**
   * Current value of the progressbar. Should always be less or equal to `max`. Defaults to 0.
   */
  @Input() value = 0;
}
