export type EmptyStatePresetType =
  | 'unknown_error_personal'
  | 'consents'
  | 'sweeps'
  | 'batches'
  | 'payment_not_found'
  | 'transactions'
  | 'budgets'
  | 'permissions'
  | 'upcoming_payments'
  | 'results_found'
  | 'notifications'
  | 'unknown_error_organisation'
  | 'cards'
  | 'cashflow'
  | 'data_found'
  | 'no_internet'
  | 'location'
  | 'user'
  | 'pockets'
  | 'insights'
  | 'transaction_limits'
  | 'no_contacts'
  | 'no_conversations'
  | 'nothing_to_approve'
  | 'operation_timeout';

interface PresetConfig {
  title: string;
  subtitle?: string;
  image: string;
  primaryActionText?: string;
}

// eslint-disable-next-line max-len
const primaryActionTextPreset = $localize`:@@bb-empty-state-ui.bb-empty-state__primary-action-text.unknown_error_personal:Try again`;

export const PRESETS: { [key: string]: PresetConfig } = {
  unknown_error_personal: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.unknown_error_personal:Something went wrong`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.unknown_error_personal:
    Try again or contact us if the problem continues`,
    image: 'ic_asset_coffee',
    primaryActionText: primaryActionTextPreset,
  },
  consents: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.consents:No consents`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.consents:
    Try to reload the page or contact the system administrator`,
    image: 'ic_asset_lock_error',
    primaryActionText: primaryActionTextPreset,
  },
  sweeps: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.sweeps:No sweeps`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.sweeps:There are no sweeps to be displayed`,
    image: 'ic_asset_calendar',
    primaryActionText: $localize`:@@bb-empty-state-ui.bb-empty-state__primary-action-text.sweeps:Create sweep`,
  },
  batches: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.batches:No batches`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.batches:There are no batches to show`,
    image: 'ic_scenario_box',
    primaryActionText: primaryActionTextPreset,
  },
  payment_not_found: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.payment_not_found:Payment not found`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.payment_not_found:There are no payments to show`,
    primaryActionText: primaryActionTextPreset,
    image: 'ic_asset_money',
  },
  transactions: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.transactions:No transactions`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.transactions:
    You haven’t made any transactions yet`,
    image: 'ic_scenario_coin_empty',
    primaryActionText: primaryActionTextPreset,
  },
  budgets: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.budgets:No budgets created`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.budgets:
    You don’t have any budgets to be displayed`,
    image: 'ic_scenario_graphs',
    primaryActionText: $localize`:@@bb-empty-state-ui.bb-empty-state__primary-action-text.budgets:Create budget`,
  },
  permissions: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.permissions:No permissions`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.permissions:
    You don’t have the required permission`,
    image: 'ic_asset_lock_error',
    primaryActionText: primaryActionTextPreset,
  },
  upcoming_payments: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.upcoming_payments:No upcoming payments`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.upcoming_payments:
    There are no upcoming payments to show`,
    image: 'ic_asset_money',
    primaryActionText: primaryActionTextPreset,
  },
  results_found: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.results_found:No results to show`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.results_found:
    We couldn’t find anything to show here`,
    image: 'ic_asset_laptop',
    primaryActionText: primaryActionTextPreset,
  },
  notifications: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.notifications:No notifications`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.notifications:
    You don’t have any notifications at the moment`,
    image: 'ic_asset_bell',
    primaryActionText: primaryActionTextPreset,
  },
  unknown_error_organisation: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.unknown_error_organisation:Something went wrong`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.unknown_error_organisation:
    Try again or contact the system administrator if the problem continues`,
    image: 'ic_asset_light_bulb',
    primaryActionText: primaryActionTextPreset,
  },
  cards: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.cards:No cards yet`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.cards:You don’t have a card yet`,
    image: 'ic_asset_credit_card',
    primaryActionText: primaryActionTextPreset,
  },
  cashflow: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.cashflow:Nothing to show`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.cashflow:We couldn’t find anything to show here`,
    image: 'ic_graph_line_chart',
    primaryActionText: primaryActionTextPreset,
  },
  data_found: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.data_found:Nothing to show`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.data_found:
    We couldn’t find anything to show here`,
    image: 'ic_graph_donut',
    primaryActionText: primaryActionTextPreset,
  },
  no_internet: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.no_internet:No internet connection`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.no_internet:It seems that you’re not online`,
    image: 'ic_scenario_satellite',
    primaryActionText: primaryActionTextPreset,
  },
  location: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.location:No branches`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.location:There are no branches in this area`,
    image: 'ic_attribute_dog',
    primaryActionText: primaryActionTextPreset,
  },
  user: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.user:No users`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.user:There are no users to show`,
    image: 'ic_portrait_casual_woman_1_neutral',
    primaryActionText: primaryActionTextPreset,
  },
  pockets: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.pockets:Create Pockets to save more`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.pockets:
    Pockets let you put money aside for your savings goals. 
    When you've set up your Pockets, you'll manage them from here.`,
    image: 'ic_asset_wallet',
    primaryActionText: $localize`:@@bb-empty-state-ui.bb-empty-state__primary-action-text.pockets:Create a Pocket`,
  },
  insights: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.insights:Nothing to show`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.insights:We couldn’t find anything to show here`,
    image: 'ic_graph_bar_chart',
    primaryActionText: primaryActionTextPreset,
  },
  transaction_limits: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.transaction_limits:No transaction limits`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.transaction_limits:
    This user can transact with any amount`,
    image: 'ic_asset_lock_success',
    primaryActionText: primaryActionTextPreset,
  },
  no_contacts: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.no_contacts:No contacts yet`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.no_contacts:
    You haven’t set up any contacts to pay`,
    image: 'ic_scenario_mobile_banking',
    primaryActionText: $localize`:@@bb-empty-state-ui.bb-empty-state__primary-action-text.no_contacts:Add contact`,
  },
  no_conversations: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.no_conversations:No conversations`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.no_conversations:
    We couldn't find any conversation to show here. For help, please contact us.`,
    image: 'ic_asset_messages',
    primaryActionText: primaryActionTextPreset,
  },
  nothing_to_approve: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.nothing_to_approve:Nothing to approve`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.nothing_to_approve:
    If anything is waiting for your approval, it will appear here`,
    image: 'ic_asset_approval',
    primaryActionText: primaryActionTextPreset,
  },
  operation_timeout: {
    title: $localize`:@@bb-empty-state-ui.bb-empty-state__title.operation_timeout:Operation timed out`,
    subtitle: $localize`:@@bb-empty-state-ui.bb-empty-state__message.operation_timeout:
    This operation timed out and has been canceled`,
    image: 'ic_asset_alarm',
    primaryActionText: primaryActionTextPreset,
  },
};
