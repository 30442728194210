<bb-load-button-ui
  color="primary"
  [size]="itemsPerPage"
  [from]="page"
  [totalCount]="totalItems"
  [isLoading]="isLoading | async"
  buttonSize="md"
  (paramChange)="onLoadButtonPress($event)"
>
  <bb-icon-ui name="search" size="lg" cropped></bb-icon-ui>
  Show more results...
</bb-load-button-ui>
