import { Injectable } from '@angular/core';
import { loadSpace } from '@usersnap/browser';

@Injectable({
  providedIn: 'root',
})
export class UsersnapService {
  private USERSNAP_PROJECT_KEY = '4fed78a0-37e3-45f5-bd2b-7f4d742db7e6';
  private USERSNAP_SPACE_KEY = '20ea7370-c810-40ae-bd85-84e61eac35f6';

  async initialize() {
    const api = await loadSpace(this.USERSNAP_SPACE_KEY);

    await api.init();
    await api.show(this.USERSNAP_PROJECT_KEY);
  }
}
