<div class="row">
  <div class="col-md-6">
    <form [formGroup]="vForm" class="mb-3">
      <div class="bb-block bb-block--md" data-role="inputNumberWrapper-default">
        <div class="bb-text-bold" id="numberFieldLabel1">
          Default Number field
          <span class="bb-text-support bb-text-default bb-d-inline-block bb-text-align-left">(min 2, max 20)</span>
        </div>
        <bb-input-number-ui
          placeholder="Enter a number"
          formControlName="inputNumber"
          [min]="2"
          [max]="20"
          [required]="true"
          [aria-describedby]="'input-number-showcase-error-default'"
          [aria-labelledby]="'numberFieldLabel1'"
          [aria-invalid]="!!(vForm?.get('inputNumber')?.touched && vForm?.get('inputNumber')?.errors)"
          aria-errormessage="'input-number-showcase-error-default'"
          [ariaLabel]="'Count'"
          [allowNonDigits]="true"
        >
        </bb-input-number-ui>
        <bb-input-validation-message-ui
          [showErrors]="vForm?.get('inputNumber')?.touched && vForm?.get('inputNumber')?.errors"
          [id]="'input-number-showcase-error-default'"
        >
          @if (hasRequiredError('inputNumber')) {
            <span> Field is required. </span>
          }
          @if (hasMinError('inputNumber')) {
            <span> Minimum number allowed is 2. </span>
          }
          @if (hasMaxError('inputNumber')) {
            <span> Maximum number allowed is 20. </span>
          }
        </bb-input-validation-message-ui>
      </div>
      <div class="bb-block bb-block--md">
        <div class="bb-text-bold" id="numberFieldLabel2">
          Number field with only digits
          <span class="bb-text-support bb-text-default bb-d-inline-block bb-text-align-left">(min 2, max 20)</span>
        </div>
        <bb-input-number-ui
          placeholder="Enter a number"
          formControlName="inputNumberOnlyDigits"
          [min]="2"
          [max]="20"
          [required]="true"
          [aria-describedby]="'input-number-only-error'"
          [aria-labelledby]="'numberFieldLabel2'"
          [aria-invalid]="
            !!(vForm?.get('inputNumberOnlyDigits')?.touched && vForm?.get('inputNumberOnlyDigits')?.errors)
          "
          aria-errormessage="'input-number-only-error'"
          [ariaLabel]="'Count'"
        >
        </bb-input-number-ui>
        <bb-input-validation-message-ui
          [showErrors]="vForm?.get('inputNumberOnlyDigits')?.touched && vForm?.get('inputNumberOnlyDigits')?.errors"
          [id]="'input-number-only-error'"
        >
          @if (hasRequiredError('inputNumberOnlyDigits')) {
            <span> Field is required. </span>
          }
          @if (hasMinError('inputNumberOnlyDigits')) {
            <span> Minimum number allowed is 2. </span>
          }
          @if (hasMaxError('inputNumberOnlyDigits')) {
            <span> Maximum number allowed is 20. </span>
          }
        </bb-input-validation-message-ui>
      </div>
      <div class="bb-block bb-block--md">
        <div class="bb-text-bold" id="numberFieldLabel3">
          Number field with negative sign allowed
          <span class="bb-text-support bb-text-default bb-d-inline-block bb-text-align-left">(min -25, max 20)</span>
        </div>
        <bb-input-number-ui
          placeholder="Enter a number"
          formControlName="inputNumberNegative"
          [min]="-25"
          [max]="20"
          [required]="true"
          [aria-describedby]="'input-negative-number-error'"
          [aria-labelledby]="'numberFieldLabel3'"
          [aria-invalid]="!!(vForm?.get('inputNumberNegative')?.touched && vForm?.get('inputNumberNegative')?.errors)"
          aria-errormessage="'input-negative-number-error'"
          [ariaLabel]="'Count'"
          [allowNegativeSign]="true"
        >
        </bb-input-number-ui>
        <bb-input-validation-message-ui
          [showErrors]="vForm?.get('inputNumberNegative')?.touched && vForm?.get('inputNumberNegative')?.errors"
          [id]="'input-negative-number-error'"
        >
          @if (hasRequiredError('inputNumberNegative')) {
            <span> Field is required. </span>
          }
          @if (hasMinError('inputNumberNegative')) {
            <span> Minimum number allowed is -25. </span>
          }
          @if (hasMaxError('inputNumberNegative')) {
            <span> Maximum number allowed is 20. </span>
          }
        </bb-input-validation-message-ui>
      </div>
      <div class="bb-block bb-block--md">
        <div class="bb-text-bold" id="numberFieldLabel4">
          Number field
          <span class="bb-text-support bb-text-default bb-d-inline-block bb-text-align-left">(min 2, max 20)</span>
        </div>
        <bb-input-number-ui
          placeholder="Enter a number"
          formControlName="inputNumberWithAreaLabelledBy"
          [min]="2"
          [max]="20"
          [required]="true"
          [aria-describedby]="'input-number-showcase-error'"
          [aria-labelledby]="'numberFieldLabel4'"
          [aria-invalid]="
            !!(
              vForm?.get('inputNumberWithAreaLabelledBy')?.touched &&
              vForm?.get('inputNumberWithAreaLabelledBy')?.errors
            )
          "
          aria-errormessage="'input-number-showcase-error'"
          [ariaLabel]="'Count'"
        >
        </bb-input-number-ui>
        <bb-input-validation-message-ui
          [showErrors]="
            vForm?.get('inputNumberWithAreaLabelledBy')?.touched && vForm?.get('inputNumberWithAreaLabelledBy')?.errors
          "
          [id]="'input-number-showcase-error'"
        >
          @if (hasRequiredError('inputNumberWithAreaLabelledBy')) {
            <span> Field is required. </span>
          }
          @if (hasMinError('inputNumberWithAreaLabelledBy')) {
            <span> Minimum number allowed is 2. </span>
          }
          @if (hasMaxError('inputNumberWithAreaLabelledBy')) {
            <span> Maximum number allowed is 20. </span>
          }
        </bb-input-validation-message-ui>
      </div>
    </form>
  </div>
</div>
