<div class="paragraph">
<p>Buttons allow users to take action within a given context with only one click. They express what action will occur when the user clicks on it and are an essential component to interactive forms, dialogs and other interfaces within Backbase.</p>
</div>
<div class="sect1">
<h2 id="_button_types">Button types</h2>
<div class="sectionbody">
<div class="paragraph">
<p>We have a variety of button sizes, styles and types, designed for specific contexts.  Which button to use is determined by its purpose and the action being taken.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/button-types.png" alt="Button Types">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Default button</strong><br>
A default button is used to highlight the call to actions on a page, as they represent an action the user can take.</p>
</li>
<li>
<p><strong>Dropdown button</strong><br>
A button that triggers a dropdown list, and displays a set of options for a singular action. Visit our <em>dropdown</em> documentation on more on how to use this button as part of the dropdown menu component.</p>
</li>
<li>
<p><strong>Block button</strong><br>
Used on XS screens, a block button uses 100% of the available width of the container or parent element.</p>
</li>
<li>
<p><strong>Default button with an Icon</strong><br>
A default button that includes an icon before the label. Use this button to draw more attention to an action, or help convey more meaning.</p>
</li>
<li>
<p><strong>Icon button</strong><br>
A default buttons that contains an icon only, and no text label.</p>
</li>
<li>
<p><strong>Group button</strong><br>
A group of buttons together on a single line, providing different options.</p>
</li>
<li>
<p><strong>Split button</strong><br>
A button with an attached dropdown button of related actions. The labeled section should show the majority use case, while the drop menu shows a list of related actions. For more on this button, review it&#8217;s guidelines bellow.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_button_style">Button style</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The button style is determined by its purpose and the action being taken.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/button-styles.png" alt="Button Styles">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Primary</strong><br>
Use to call attention to highlight the strongest call to action on a page or form. Primary buttons should only appear once per screen. Not every screen requires a primary button.</p>
</li>
<li>
<p><strong>Secondary</strong><br>
The standard button for most use cases, use for all secondary actions on a page.</p>
</li>
<li>
<p><strong>Links</strong><br>
Use a link style button when you want to use link styled text in place of a button to de-emphasize an action.</p>
</li>
<li>
<p><strong>Danger</strong><br>
The danger button appears as a final confirmation for a destructive action such as deleting or rejecting a payment. These buttons are found mostly in confirmation modals or in conjunction with Success buttons in user flows that require approvals.</p>
</li>
<li>
<p><strong>Success</strong><br>
Use to inform the user that there will be a positive outcome from an action, for example, approving a payment.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_button_states">Button states</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The appearance of a button also changes in relation of its current state.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/button-states.png" alt="Button States">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Default</strong><br>
This is the default button in a page as it is rendered before any interaction.</p>
</li>
<li>
<p><strong>Hover</strong><br>
On hover the button shows a darker shade from its original color.</p>
</li>
<li>
<p><strong>Focus</strong><br>
This state appears as a visual marker to highlight the area to focused on, and where the user is on the page.</p>
</li>
<li>
<p><strong>Active</strong><br>
When a button is pressed or clicked it is displayed in a darker shade than hover.</p>
</li>
<li>
<p><strong>Loading</strong><br>
An active button with a loading indicator replacing the text label. During this state the button cannot be pressed or clicked.</p>
</li>
<li>
<p><strong>Disabled</strong><br>
Use when another action has to be completed before, for example in <a href="https://designsystem.backbase.com/ui-components/forms/">forms</a>, a submit button is never disabled <em>until</em> an action has been triggered. A disabled button will always be displayed in the same visual style, regardless of its type.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_hierarchy">Hierarchy</h3>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/button-hierarchy.png" alt="Hierarchy">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Use a primary button for High Emphasis</strong><br>
A page should contain a single prominent button that makes it clear that other buttons have less importance in the hierarchy. This high emphasis button commands the most attention.</p>
</li>
<li>
<p><strong>Use other buttons for Medium and Low emphasis</strong><br>
An app can show more than one button in a layout at a time, so a high-emphasis button can be accompanied by medium and low emphasis buttons that perform less important actions.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_text_labels">Text labels</h3>
<div class="paragraph">
<p>Button labels tell users what will happen when they click the button, so be mindful of the meaning you are trying to convey by choosing the right words.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use verbs that describe the action, such as <em>Save</em> or <em>Delete</em>.</p>
</li>
<li>
<p>Use sentence case and no more than one or two words.</p>
</li>
<li>
<p>Be specific, use words like <em>Save</em> or <em>Discard</em>, instead of using <em>OK</em> and <em>Cancel</em>. This is particularly helpful when the user is confirming an action.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_sizes">Sizes</h3>
<div class="paragraph">
<p>To provide flexibility when designing with different visual density, consider choosing between two available options.<br>
image::button-sizes.png[Sizes]</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Small</strong><br>
Use for low density or when space is limited, like tables.</p>
</li>
<li>
<p><strong>Medium</strong><br>
Use for standard actions. This is the preferred size for most buttons, use it for standard actions in pages and forms.</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_placement">Placement</h2>
<div class="sectionbody">
<div class="paragraph">
<p>There can be multiple button types in a screen at a given time to express different emphasis levels within a flow. Buttons are aligned depending on context:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>For single page forms and focused tasks, left-align buttons and sort by importance from left to right.</p>
</li>
<li>
<p>When using buttons to prompt a user to move through a sequence of screens, right-align the primary button to visually support navigation.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Buttons themselves are not responsive and placement is relative to their parent container.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>As a rule of thumb, on XS containers, buttons representing primary actions are displayed in a block button to provide visual prominence and comfort on touch interfaces (<em>1</em>).</p>
</li>
<li>
<p>Exception for this rule is buttons in list items, (<em>2</em>) __ where default button is preferred.</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/button-responsive.png" alt="Button responsiveness">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_additional_considerations">Additional considerations</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_split_button">Split button</h3>
<div class="paragraph">
<p>A split button should be used when there are a number of related actions a user can perform, but one can be singled out as the most important action.</p>
</div>
<div class="paragraph">
<p>The split button consists of two main parts:</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/button-split.png" alt="Split button">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Main action button</strong><br>
The main action button should always be the most important action available for the user.</p>
</li>
<li>
<p><strong>Dropdown button</strong><br>
The dropdown button triggers a dropdown list containing related actions a user can also perform.</p>
</li>
</ol>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_interaction">Interaction</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p><strong>Triggering the dropdown</strong><br>
Clicking/pressing the dropdown button shows the dropdown list</p>
</li>
<li>
<p><strong>Dismissing the dropdown</strong><br>
Clicking anywhere outside or tabbing away from the dropdown area dismisses it</p>
</li>
<li>
<p><strong>Navigating the dropdown</strong><br>
Tabbing through the list shows the list item hover state on focus</p>
</li>
<li>
<p><strong>Selecting an action</strong><br>
Hovering anywhere inside a list item shows the hover state. Clicking/pressing anywhere on a list item selects it and the dropdown will be automatically dismissed.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use this component when there are many related actions a user can choose from</p>
</li>
<li>
<p>Use this component when exposing multiple action buttons in the interface might be confusing or overwhelming</p>
</li>
<li>
<p>Use a clear, concise label for actions both in the main action button and dropdown list</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use this component when the contained actions are unrelated</p>
</li>
<li>
<p>Use the component for less than three actions</p>
</li>
<li>
<p>Hide primary actions inside the dropdown list</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_split_button_responsiveness">Split-button Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When displaying a split button as a block button, the width of the main action button increases but the dropdown button remains a fixed width. The label for the main action button should be centered in the button area.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p><strong>Button navigation</strong><br>
The user can navigate to the component and between the individual main and dropdown parts using keyboard controls.</p>
</li>
<li>
<p><strong>Selecting an action</strong><br>
The user should be able to select an item using a keypress event. The dropdown list is dismissed automatically when a list item is selected using the keyboard.</p>
</li>
</ul>
</div>
</div>
</div>