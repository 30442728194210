<div class="bb-block bb-block--xl">
  <h4>Default</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-debit-card-ui [title]="'John Doe'" [productNumber]="4322" [highlight]="false" [favorite]="true">
      </bb-product-item-debit-card-ui>
    </div>
  </div>
</div>

<div class="bb-block bb-block--xl">
  <h4>With owners</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-debit-card-ui
        [title]="'John Doe'"
        [productNumber]="4322"
        [highlight]="false"
        [favorite]="true"
        [productDetailsInfo]="{
          owners: 'Ricardo Norton'
        }"
      >
      </bb-product-item-debit-card-ui>
    </div>
  </div>
</div>
