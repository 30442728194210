<h5>Below are usage illustrations for the following</h5>
<ul style="font-weight: normal">
  <li>Single date</li>
  <li>Range selection with single input</li>
  <li>Range selection with split input</li>
  <li>All the above, with mask patterns enabled</li>
</ul>

<h2 class="bb-block bb-block--sm">Single Date</h2>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerWithCustomizedAccessibilityOptions">
    <label [attr.for]="defaultDatePicker6Element?.id">Customized accessibility options</label>
  </h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
    <div class="visually-hidden" aria-live="polite" id="customDatepickerDescribedbyLabel">
      @if (focusedDate$$ | async; as focusDate) {
        <div>
          @if (focusDate.isSelecting) {
            <span
              >Current date in focus is
              {{
                focusDate.date?.year + '-' + focusDate.date?.month + '-' + focusDate.date?.day | date: 'longDate'
              }}</span
            >
          }
        </div>
      }
    </div>
    <bb-input-datepicker-ui
      #defaultDatePicker6="ngModel"
      #defaultDatePicker6Element
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="predefinedDate"
      [ariaLabelForButton]="'Show calendar dropdown'"
      [aria-labelledby]="'datePickerWithCustomizedAccessibilityOptions'"
      [datepickerDescribedby]="'customDatepickerDescribedbyLabel'"
      [firstDayOfWeek]="0"
      (focusedDate)="focusedDate($event)"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker6.hasError('invalidDateFormat') && defaultDatePicker6.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerDefaultLabel">
    <label [attr.for]="defaultDatePickerElement?.id">Default</label>
  </h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
    <bb-input-datepicker-ui
      #defaultDatePicker="ngModel"
      #defaultDatePickerElement
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="predefinedDate"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'datePickerDefaultLabel'"
      [firstDayOfWeek]="0"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker.hasError('invalidDateFormat') && defaultDatePicker.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerDefaultLabel2">
    <label [attr.for]="defaultDatePicker2?.id">Default with first day of the week set to Sunday</label>
  </h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
    <bb-input-datepicker-ui
      #defaultDatePicker2
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="dateFirstDayOfWeekSet"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'datePickerDefaultLabel2'"
      [firstDayOfWeek]="7"
    ></bb-input-datepicker-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerDefaultLabel3">
    <label [attr.for]="defaultDatePicker3?.id">Default with override date format</label>
  </h4>
  <div class="datepicker-default bb-block bb-block--md" [attr.data-role]="'datepicker-default'">
    <bb-input-datepicker-ui
      #defaultDatePicker3
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="dateOverrideDateFormat"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'datePickerDefaultLabel3'"
      [overrideDateFormat]="'yyyy/MM/dd'"
    ></bb-input-datepicker-ui>
  </div>

  get default format from locale
  <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
    <bb-input-datepicker-ui
      #defaultDatePicker3
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="dateOverrideDateFormat2"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'datePickerDefaultLabel3'"
      [overrideDateFormat]="'yyyy/MM/dd'"
    ></bb-input-datepicker-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerPredefinedLabel">With pre-defined date</h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-predefinedDate'">
    <bb-input-datepicker-ui
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="predefinedDate"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'datePickerPredefinedLabel'"
    ></bb-input-datepicker-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerDisabledLabel">Disabled</h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-disabled'">
    <bb-input-datepicker-ui
      name="startDate2"
      [disabled]="isDisabled"
      [(ngModel)]="datePickerDisabled"
      [aria-labelledby]="'datePickerDisabledLabel'"
    ></bb-input-datepicker-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerCustomPlaceholder">
    <label [attr.for]="customPlaceholderDatePicker?.id">Custom placeholder</label>
  </h4>
  <div class="datepicker-default">
    <bb-input-datepicker-ui
      #customPlaceholderDatePicker
      labelClasses="fw-bold"
      name="startDate"
      [placeholder]="'You can add custom placeholder'"
      [(ngModel)]="date"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'datePickerCustomPlaceholder'"
    ></bb-input-datepicker-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerReadonlyLabel">Readonly</h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-readonly'">
    <bb-input-datepicker-ui
      name="startDate3"
      [readonly]="true"
      [(ngModel)]="datePickerReadonly"
      [aria-labelledby]="'datePickerReadonlyLabel'"
    ></bb-input-datepicker-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerMinMaxLabel">With minimum and maximum date selection</h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-minMax'">
    <bb-input-datepicker-ui
      name="startDate"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [(ngModel)]="dateDefault"
      [aria-labelledby]="'datePickerMinMaxLabel'"
      [aria-describedby]="'datePickerMinMaxValues'"
    ></bb-input-datepicker-ui>
  </div>
  <div class="visually-hidden" aria-live="polite" id="datePickerMinMaxValues">
    Minimum value for this input is {{ minDate | date: 'short' }} maximum value is {{ maxDate | date: 'short' }}
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerCustomFooter">With footer template</h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-footer'">
    <bb-input-datepicker-ui
      name="startDate"
      [(ngModel)]="footerTemplateDate"
      [aria-labelledby]="'datePickerCustomFooter'"
      [footerTemplate]="footerTemplate"
      #footerTemplateDatepicker
    ></bb-input-datepicker-ui>
  </div>
  <ng-template #footerTemplate>
    <hr class="my-0" />
    <button
      class="btn btn-primary btn-sm m-2 float-start"
      (click)="resetFooterDate(footerTemplateDatepicker)"
      [attr.data-role]="'datepicker-footer-button'"
    >
      Reset
    </button>
  </ng-template>
</div>

<h2 class="bb-block bb-block--sm">Range selection single input</h2>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerRangeWithCustomisedAccessibilityOptions">
    <label [attr.for]="defaultDatePicker51Element?.id">Range: Customized accessibility options</label>
  </h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
    <div class="visually-hidden" aria-live="polite" id="customRangeDatepickerDescribedbyLabel">
      @if (focusedRangeDate$$ | async; as focusRangeDate) {
        <div>
          @if (focusRangeDate.isSelecting) {
            <span
              >Currently selecting date
              {{
                focusRangeDate.date?.year + '-' + focusRangeDate.date?.month + '-' + focusRangeDate.date?.day
                  | date: 'longDate'
              }}</span
            >
          }
        </div>
      }
    </div>
    <bb-input-datepicker-ui
      #defaultDatePicker51="ngModel"
      #defaultDatePicker51Element
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="dateRange"
      [ariaLabelForButton]="'Show datepicker calendars'"
      [aria-labelledby]="'datePickerRangeWithCustomisedAccessibilityOptions'"
      [datepickerDescribedby]="'customRangeDatepickerDescribedbyLabel'"
      [firstDayOfWeek]="0"
      [rangeSelection]="true"
      (focusedDate)="focusedRangeDate($event)"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker51.hasError('required') && defaultDatePicker51.touched"
    >
      Field is required.
    </bb-input-validation-message-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker51.hasError('invalidDateFormat') && defaultDatePicker51.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerRangeDefaultWithNgModel">
    <label [attr.for]="defaultDatePicker4?.id">Default with ngModel</label>
  </h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
    <bb-input-datepicker-ui
      #defaultDatePicker4
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="dateRange"
      [ariaLabelForButton]="'Show datepicker calendars'"
      [aria-labelledby]="'datePickerRangeDefaultWithNgModel'"
      [firstDayOfWeek]="0"
      [rangeSelection]="true"
    ></bb-input-datepicker-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerRangeSelectionSingleDefault5">
    <label>Default with ngModel, label required set</label>
  </h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
    <bb-input-datepicker-ui
      #defaultDatePicker5="ngModel"
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="dateRangeRequired"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'datePickerRangeSelectionSingleDefault5'"
      [firstDayOfWeek]="0"
      label="Label"
      [rangeSelection]="true"
      [required]="true"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker5.hasError('required') && defaultDatePicker5.touched"
    >
      Field is required.
    </bb-input-validation-message-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker5.hasError('invalidDateFormat') && defaultDatePicker5.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div [formGroup]="formGroup">
  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRange1">
      <label [attr.for]="defaultDatePicker7?.id">Reactive</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePicker7
        formControlName="reactiveDateRangeFormControlName1"
        labelClasses="fw-bold"
        name="startDate"
        [ariaLabelForButton]="'button'"
        [aria-labelledby]="'datePickerDefaultLabelRange1'"
        [firstDayOfWeek]="0"
        [rangeSelection]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName1'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName1'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName1'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName1'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRange2">
      <label [attr.for]="defaultDatePicker8?.id">Reactive with empty initial and required</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePicker8
        formControlName="reactiveDateRangeFormControlName2"
        labelClasses="fw-bold"
        name="startDate"
        [ariaLabelForButton]="'button'"
        [aria-labelledby]="'datePickerDefaultLabelRange2'"
        [firstDayOfWeek]="0"
        [rangeSelection]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName2'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName2'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName2'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName2'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRange3">
      <label [attr.for]="defaultDatePicker9?.id">Reactive with missing from and required</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePicker9
        labelClasses="fw-bold"
        name="startDate"
        formControlName="reactiveDateRangeFormControlName3"
        [ariaLabelForButton]="'button'"
        [aria-labelledby]="'datePickerDefaultLabelRange3'"
        [firstDayOfWeek]="0"
        [rangeSelection]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName3'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName3'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName3'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName3'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRange6">
      <label [attr.for]="defaultDatePickerRange?.id">Reactive with missing to</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePickerRange
        labelClasses="fw-bold"
        name="startDate"
        formControlName="reactiveDateRangeFormControlName6"
        [ariaLabelForButton]="'button'"
        [aria-labelledby]="'datePickerDefaultLabelRange6'"
        [firstDayOfWeek]="0"
        [rangeSelection]="true"
      ></bb-input-datepicker-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRange4">
      <label [attr.for]="defaultDatePicker10?.id">Reactive with missing from and not required</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePicker10
        labelClasses="fw-bold"
        name="startDate"
        formControlName="reactiveDateRangeFormControlName4"
        [ariaLabelForButton]="'button'"
        [aria-labelledby]="'datePickerDefaultLabelRange4'"
        [firstDayOfWeek]="0"
        [rangeSelection]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName4'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName4'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName4'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName4'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRange5">
      <label [attr.for]="defaultDatePicker11?.id">Reactive with wrong from</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePicker11
        labelClasses="fw-bold"
        name="startDate"
        formControlName="reactiveDateRangeFormControlName5"
        [ariaLabelForButton]="'button'"
        [aria-labelledby]="'datePickerDefaultLabelRange5'"
        [firstDayOfWeek]="0"
        [rangeSelection]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName5'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName5'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName5'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName5'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
    </div>
  </div>
</div>

<h2 class="bb-block bb-block--sm">Range selection split input</h2>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerDefaultLabelRangeSplit">
    <label [attr.for]="defaultDatePicker12Element?.id">Default with ngModel</label>
  </h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
    <bb-input-datepicker-ui
      #defaultDatePicker12="ngModel"
      #defaultDatePicker12Element
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="dateRangeSplit"
      [ariaLabelForButton]="'button'"
      [ariaLabel]="'Default with ngModel from date'"
      [ariaLabelToDate]="'Default with ngModel to date'"
      [firstDayOfWeek]="0"
      [rangeSelectionSplit]="true"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker12.hasError('required') && defaultDatePicker12.touched"
    >
      Field is required.
    </bb-input-validation-message-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker12.hasError('invalidDateFormat') && defaultDatePicker12.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="datePickerRangeSelectionSplitDefault6">
    <label>Default with ngModel, label required set</label>
  </h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
    <bb-input-datepicker-ui
      #defaultDatePicker21="ngModel"
      labelClasses="fw-bold"
      name="startDate"
      [(ngModel)]="dateRangeRequiredSplit"
      [ariaLabelForButton]="'button'"
      [firstDayOfWeek]="0"
      [label]="'Label from date'"
      [labelTo]="'Label to date'"
      [rangeSelectionSplit]="true"
      [required]="true"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker21.hasError('required') && defaultDatePicker21.touched"
    >
      Field is required.
    </bb-input-validation-message-ui>
    <bb-input-validation-message-ui
      [showErrors]="defaultDatePicker21.hasError('invalidDateFormat') && defaultDatePicker21.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div [formGroup]="formGroup">
  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRangeSplit1">
      <label [attr.for]="defaultDatePickerReactive?.id">Reactive</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePickerReactive
        formControlName="reactiveDateRangeFormControlName1"
        labelClasses="fw-bold"
        name="startDate"
        [ariaLabelForButton]="'button'"
        [ariaLabel]="'Reactive from date'"
        [ariaLabelToDate]="'Reactive to date'"
        [firstDayOfWeek]="0"
        [rangeSelectionSplit]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName1'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName1'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName1'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName1'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRangeSplit2">
      <label [attr.for]="defaultDatePickerEmptyInitialAndRequired?.id">Reactive with empty initial and required</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePickerEmptyInitialAndRequired
        formControlName="reactiveDateRangeFormControlName2"
        labelClasses="fw-bold"
        name="startDate"
        [ariaLabelForButton]="'button'"
        [ariaLabel]="'Reactive with empty initial and required from date'"
        [ariaLabelToDate]="'Reactive with empty initial and required to date'"
        [firstDayOfWeek]="0"
        [rangeSelectionSplit]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName2'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName2'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName2'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName2'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRangeSplit3">
      <label [attr.for]="defaultDatePickerReactiveMissingFrom?.id">Reactive with missing from and required</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePickerReactiveMissingFrom
        labelClasses="fw-bold"
        name="startDate"
        formControlName="reactiveDateRangeFormControlName3"
        [ariaLabelForButton]="'button'"
        [ariaLabel]="'Reactive with missing from and required from date'"
        [ariaLabelToDate]="'Reactive with missing from and required to date'"
        [firstDayOfWeek]="0"
        [rangeSelectionSplit]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName3'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName3'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName3'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName3'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRangeSplit6">
      <label [attr.for]="defaultDatePickerReactiveMissingTo?.id">Reactive with missing to</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePickerReactiveMissingTo
        labelClasses="fw-bold"
        name="startDate"
        formControlName="reactiveDateRangeFormControlName6"
        [ariaLabelForButton]="'button'"
        [ariaLabel]="'Reactive with missing to from date'"
        [ariaLabelToDate]="'Reactive with missing to to date'"
        [firstDayOfWeek]="0"
        [rangeSelectionSplit]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName6'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName6'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName6'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName6'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRangeSplit4">
      <label [attr.for]="defaultDatePickerReactiveMissingFromNotRequired?.id"
        >Reactive with missing from and not required</label
      >
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePickerReactiveMissingFromNotRequired
        labelClasses="fw-bold"
        name="startDate"
        formControlName="reactiveDateRangeFormControlName4"
        [ariaLabelForButton]="'button'"
        [ariaLabel]="'Reactive with missing from and not required from date'"
        [ariaLabelToDate]="'Reactive with missing from and not required to date'"
        [firstDayOfWeek]="0"
        [rangeSelectionSplit]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName4'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName4'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName4'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName4'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4 id="datePickerDefaultLabelRangeSplit5">
      <label [attr.for]="defaultDatePickerReactiveWrongFrom?.id">Reactive with wrong from</label>
    </h4>
    <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
      <bb-input-datepicker-ui
        #defaultDatePickerReactiveWrongFrom
        labelClasses="fw-bold"
        name="startDate"
        formControlName="reactiveDateRangeFormControlName5"
        [ariaLabelForButton]="'button'"
        [ariaLabel]="'Reactive with wrong from from date'"
        [ariaLabelToDate]="'Reactive with wrong from to date'"
        [firstDayOfWeek]="0"
        [rangeSelectionSplit]="true"
      ></bb-input-datepicker-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName5'].hasError('invalidDateFormat') &&
          formGroup.controls['reactiveDateRangeFormControlName5'].touched
        "
      >
        Invalid date format.
      </bb-input-validation-message-ui>
      <bb-input-validation-message-ui
        [showErrors]="
          formGroup.controls['reactiveDateRangeFormControlName5'].hasError('required') &&
          formGroup.controls['reactiveDateRangeFormControlName5'].touched
        "
      >
        Field is required.
      </bb-input-validation-message-ui>
    </div>
  </div>
</div>

<h2 class="bb-block bb-block--sm">Mask enabled</h2>

<div class="bb-block bb-block--lg">
  <h4 id="maskDatePicker1">
    <label [attr.for]="maskDatePicker1Element?.id"
      >Single: [mask]="'SSS 00, 0000'" and [overrideDateFormat]="'MMM dd, yyyy'"</label
    >
  </h4>
  <div class="datepicker-default" [attr.data-role]="'maskDatePicker1'">
    <bb-input-datepicker-ui
      #maskDatePicker1="ngModel"
      #maskDatePicker1Element
      labelClasses="fw-bold"
      [(ngModel)]="dateMask"
      [mask]="'SSS 00, 0000'"
      [overrideDateFormat]="'MMM dd, yyyy'"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'maskDatePicker1'"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui [showErrors]="maskDatePicker1.hasError('required') && maskDatePicker1.touched">
      Field is required.
    </bb-input-validation-message-ui>
    <bb-input-validation-message-ui
      [showErrors]="maskDatePicker1.hasError('invalidDateFormat') && maskDatePicker1.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="maskDatePicker2">
    <label [attr.for]="maskDatePicker2Element?.id"
      >Range (enabled): [mask]="true" and [overrideDateFormat]="'MM/dd/yyyy'"</label
    >
  </h4>
  <div class="datepicker-default" [attr.data-role]="'maskDatePicker2'">
    <bb-input-datepicker-ui
      #maskDatePicker2="ngModel"
      #maskDatePicker2Element
      labelClasses="fw-bold"
      [(ngModel)]="dateMaskRange1"
      [mask]="true"
      [overrideDateFormat]="'MM/dd/yyyy'"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'maskDatePicker2'"
      [rangeSelection]="true"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui [showErrors]="maskDatePicker2.hasError('required') && maskDatePicker2.touched">
      Field is required.
    </bb-input-validation-message-ui>
    <bb-input-validation-message-ui
      [showErrors]="maskDatePicker2.hasError('invalidDateFormat') && maskDatePicker2.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="maskDatePicker5">
    <label [attr.for]="maskDatePicker5Element?.id"
      >Range (enabled): [mask]="true" and [overrideDateFormat]="'dd.MM.yyyy'"</label
    >
  </h4>
  <div class="datepicker-default" [attr.data-role]="'datepicker-with-mask'">
    <bb-input-datepicker-ui
      #maskDatePicker5="ngModel"
      #maskDatePicker5Element
      labelClasses="fw-bold"
      [(ngModel)]="dateMask1"
      [mask]="true"
      [overrideDateFormat]="'dd.MM.yyyy'"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'maskDatePicker5'"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui [showErrors]="maskDatePicker5.hasError('required') && maskDatePicker5.touched">
      Field is required.
    </bb-input-validation-message-ui>
    <bb-input-validation-message-ui
      [showErrors]="maskDatePicker5.hasError('invalidDateFormat') && maskDatePicker5.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="maskDatePicker3">
    <label [attr.for]="maskDatePicker3Element?.id"
      >Range (set): [mask]="'00/00/00'" and [overrideDateFormat]="'MM/dd/yy'"</label
    >
  </h4>
  <div class="datepicker-default" [attr.data-role]="'maskDatePicker3'">
    <bb-input-datepicker-ui
      #maskDatePicker3="ngModel"
      #maskDatePicker3Element
      labelClasses="fw-bold"
      [(ngModel)]="dateMaskRange2"
      [mask]="'00/00/00'"
      [overrideDateFormat]="'MM/dd/yy'"
      [ariaLabelForButton]="'button'"
      [aria-labelledby]="'maskDatePicker3'"
      [rangeSelection]="true"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui [showErrors]="maskDatePicker3.hasError('required') && maskDatePicker3.touched">
      Field is required.
    </bb-input-validation-message-ui>
    <bb-input-validation-message-ui
      [showErrors]="maskDatePicker3.hasError('invalidDateFormat') && maskDatePicker3.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="maskDatePicker4">
    <label [attr.for]="maskDatePicker4Element?.id">Split: [mask]="true" and [overrideDateFormat]="'yyyy-MM-dd'"</label>
  </h4>
  <div class="datepicker-default" [attr.data-role]="'maskDatePicker4'">
    <bb-input-datepicker-ui
      #maskDatePicker4="ngModel"
      #maskDatePicker4Element
      labelClasses="fw-bold"
      [(ngModel)]="dateMaskRangeSplit"
      [mask]="true"
      [overrideDateFormat]="'yyyy-MM-dd'"
      [ariaLabelForButton]="'button'"
      [ariaLabel]="'Split: mask=true and overrideDateFormat=yyyy-MM-dd from date'"
      [ariaLabelToDate]="'Split: mask=true and overrideDateFormat=yyyy-MM-dd to date'"
      [rangeSelectionSplit]="true"
    ></bb-input-datepicker-ui>
    <bb-input-validation-message-ui [showErrors]="maskDatePicker4.hasError('required') && maskDatePicker4.touched">
      Field is required.
    </bb-input-validation-message-ui>
    <bb-input-validation-message-ui
      [showErrors]="maskDatePicker4.hasError('invalidDateFormat') && maskDatePicker4.touched"
    >
      Invalid date format.
    </bb-input-validation-message-ui>
  </div>
</div>
