import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  Output,
  inject,
  ContentChild,
  TemplateRef,
  Directive,
} from '@angular/core';
import { KeyValue } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { Locale, LocaleCatalog } from './locale-selector.model';
import { LOCALE_LIST } from './constants';

@Directive({ selector: 'ng-template[bbLocaleSelectorLabel]' })
export class LocaleSelectorLabelDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'bb-locale-selector-ui',
  templateUrl: './locale-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocaleSelectorComponent {
  @ContentChild(LocaleSelectorLabelDirective, { read: TemplateRef, static: true })
  customLabel?: TemplateRef<any>;
  /**
   * Assigning the value of the `LOCALE_ID` injection token to the `currentLanguage` property.
   */
  currentLanguage: string = inject(LOCALE_ID);

  /**
   * This variable is used to control the visibility of the locale modal in the component.
   * When `showLocaleModal` is `true`, the locale modal is shown, and vice versa on `false`.
   */
  showLocaleModal: boolean = false;

  /**
   * The `isMobileBreakpoint$` property is an Observable that represents whether the current viewport
   * width matches the mobile breakpoint.
   */
  readonly isMobileBreakpoint$: Observable<boolean> = inject(BreakpointObserver)
    .observe('(max-width: 991px)')
    .pipe(map((result) => result.matches));

  /**
   * A variable is used to conditionally render the locale selector control on the UI.
   */
  readonly showLocaleSelector: boolean;

  /**
   * A compare function which is been passed as aurgument to the `keyvalue` pipe to keep the
   * original order of the object list while iterating.
   */
  readonly unSorted = (a: KeyValue<string, Locale>, b: KeyValue<string, Locale>): number => 0;

  /**
   * The position of the dropdown for the locale selector, position will be picked in order of feasibility.
   */
  @Input() dropdownPlacement: PlacementArray = ['bottom-end', 'bottom-start', 'top-end', 'top-start'];
  /**
   * Color of the button. Defaults to primary.
   */
  @Input() btnColor = 'primary';
  /**
   * Icon that is displayed in the button.
   */
  @Input() icon?: string;
  /**
   * The size of the icon to be displayed. Defaults to md.
   */
  @Input() iconSize = 'md';
  /**
   * If true it will stretch the button inside to 100% width.
   */
  @Input() fullWidth = false;
  /**
   * If true it will show the locale selection inside a modal on mobile view.
   */
  @Input() showModalOnMobileView = false;
  /**
   * Button aria label.
   */
  @Input() dropDownButtonLabel?: string;

  /**
   * An output property which will emit a selected Locale code.
   */
  @Output() selectLocale = new EventEmitter<string>();

  /**
   * The constructor function takes "locales" parameter of type "LocaleCatalog" and it is being
   * injected with a dependency called "LOCALE_LIST".
   */
  constructor(@Inject(LOCALE_LIST) public locales: LocaleCatalog) {
    this.showLocaleSelector = Object.keys(locales).length > 1;
  }

  /**
   * Changes the current language to the specified locale, toggles locale modal
   * and triggers a output event with selected locale code.
   */
  changeLanguage(localeCode: string): void {
    this.showLocaleModal = false;
    this.selectLocale.emit(localeCode);
  }

  /**
   * Toggles the visibility of the locale selector modal.
   */
  toggleLocaleModal(): void {
    this.showLocaleModal = !this.showLocaleModal;
  }
}
