<div class="bb-block bb-block--lg">
  <h2>Pending item log</h2>
  <bb-item-log-ui [records]="pendingRecords" [isPending]="true"></bb-item-log-ui>
</div>
<div class="bb-block bb-block--lg">
  <h2>Success item log</h2>
  <bb-item-log-ui [records]="successRecords"></bb-item-log-ui>
</div>
<div class="bb-block bb-block--lg">
  <h2>Rejected item log</h2>
  <bb-item-log-ui [records]="rejectedRecords"></bb-item-log-ui>
</div>
<div class="bb-block bb-block--lg">
  <h2>Custom item log with description</h2>
  <bb-item-log-ui [records]="rejectedRecords">
    <ng-template bbItemLogRecord let-record>
      <bb-icon-ui
        class="bb-item-log-record__icon"
        size="md"
        [name]="record?.status === 'REJECTED' ? 'cancel' : 'success'"
        [color]="record?.status === 'REJECTED' ? 'danger' : 'success'"
      ></bb-icon-ui>
      <div class="bb-item-log-record__details">
        <span class="bb-text-bold">
          {{ record?.status | titlecase }}
        </span>
        <ng-container> by {{ record?.userFullName }}</ng-container>
        <div class="bb-item-log-record__time">
          <time> {{ record?.createdAt | date }}</time>
          <ng-container> at</ng-container>
          <time> {{ record?.createdAt | date: 'shortTime' }}</time>
        </div>
        @if (record?.reason) {
          <div class="bb-stack">
            <bb-icon-ui color="secondary" name="forum" size="md" class="bb-stack__item"></bb-icon-ui>
            <div class="bb-stack__item">
              User hasn’t posted all his/her details to complete the payment. <br aria-hidden="true" />
              Unfortunately, we will need to request more payment details now <br aria-hidden="true" />
              to approve this transaction.
            </div>
          </div>
        }
      </div>
    </ng-template>
  </bb-item-log-ui>
</div>
