import { Inject, Injectable, InjectionToken } from '@angular/core';

export function navigatorFactory(): Navigator {
  return navigator;
}

export const NAVIGATOR_TOKEN = new InjectionToken<Navigator>('Navigator object');

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  // 'any' due to issue https://github.com/angular/angular/issues/15640
  constructor(@Inject(NAVIGATOR_TOKEN) private readonly navigator: any) {}

  browserIsMSIE(): boolean {
    return this.navigator.userAgent.indexOf('MSIE') > -1 || this.navigator.userAgent.indexOf('Trident/') > -1;
  }
}
