import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationShowcaseComponent } from './notification-showcase.component';
import { NotificationModule } from '@backbase/ui-ang/notification';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [CommonModule, NotificationModule, ButtonModule],
  declarations: [NotificationShowcaseComponent],
})
export class NotificationShowcaseModule {}
