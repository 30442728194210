import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';

import { AmountInputComponent } from './amount-input.component';
import { AmountInputCurrencyRemapPipe } from './amount-input-currency-remap.pipe';

@NgModule({
  imports: [NgClass, FormsModule, BaseClassesModule],
  declarations: [AmountInputComponent, AmountInputCurrencyRemapPipe],
  exports: [AmountInputComponent],
})
export class AmountInputModule {}
