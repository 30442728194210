export const DEFAULT_ASSET_PATH = 'assets/images/bb-illustration-ui';

export const ILLUSTRATION_SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
} as const;

export const ILLUSTRATION_SIZE_MAP = {
  [ILLUSTRATION_SIZES.XS]: {
    width: 96,
    height: 96,
  },
  [ILLUSTRATION_SIZES.SM]: {
    width: 144,
    height: 144,
  },
  [ILLUSTRATION_SIZES.MD]: {
    width: 240,
    height: 240,
  },
  [ILLUSTRATION_SIZES.LG]: {
    width: 304,
    height: 304,
  },
  [ILLUSTRATION_SIZES.XL]: {
    width: 800,
    height: 400,
  },
};
