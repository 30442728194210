import { Injectable } from '@angular/core';
import { DateTimezoneTransformationService } from '@backbase/ui-ang/date-timezone-transformation';
import { NgbCalendarGregorian, NgbDate } from '@ng-bootstrap/ng-bootstrap';

export function fromJSDate(jsDate: Date) {
  return new NgbDate(jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate());
}
@Injectable()
export class TimezonedTodayCalendar extends NgbCalendarGregorian {
  constructor(private dateTimeZoneService: DateTimezoneTransformationService) {
    super();
  }
  getToday() {
    const currentConfiguredDate = this.dateTimeZoneService.getCurrentDateInConfiguredTimeZone();

    if (currentConfiguredDate) {
      return fromJSDate(currentConfiguredDate);
    } else {
      return super.getToday();
    }
  }
}
