import { NgModule } from '@angular/core';

import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginatorComponent } from './pagination.component';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [NgbPaginationModule, IconModule],
  declarations: [PaginatorComponent],
  exports: [PaginatorComponent],
})
export class PaginationModule {}
