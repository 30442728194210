import { JsonPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TableModule } from '@backbase/ui-ang/table';
import { StringToColorVariablePipe } from '../../../pipes/string-to-color-variable.pipe';
import { DesignToken } from '../../../services/design-token.service';

@Component({
  standalone: true,
  selector: 'bb-design-token-list-table',
  templateUrl: './design-token-list-table.component.html',
  imports: [TableModule, JsonPipe, StringToColorVariablePipe],
})
export class DesignTokenListTableComponent {
  @Input() tokens: DesignToken[] = [];

  getStatus(token: DesignToken): string {
    if (!token.stable) {
      return 'LOCKED';
    }

    if (token.configurable) {
      return '';
    } else {
      return 'READ-ONLY';
    }
  }

  isVariable(color: string) {
    return /[\$|(]/.test(color);
  }
}
