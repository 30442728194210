<form [formGroup]="vForm">
  <bb-input-number-ui
    label="Count"
    placeholder="Enter number..."
    formControlName="count"
    [aria-describedby]="'input-number-showcase-error'"
    [aria-invalid]="!!(vForm?.dirty && vForm?.get('count')?.errors)"
    aria-errormessage="input-number-showcase-error"
    [min]="2"
    [max]="42"
    [required]="true"
    #numberInput
  >
  </bb-input-number-ui>
  <bb-input-validation-message-ui
    id="input-number-showcase-error"
    [showErrors]="vForm?.dirty && vForm?.get('count')?.errors"
  >
    @if (hasRequiredError('count')) {
      <p>Field is required.</p>
    }
    @if (hasMinError('count')) {
      <p>Minimum number allowed is: {{ numberInput.min }}</p>
    }
    @if (hasMaxError('count')) {
      <p>Maximum number allowed is: {{ numberInput.max }}</p>
    }
  </bb-input-validation-message-ui>
</form>
