<ngb-timepicker
  #timepicker
  id="{{ id }}"
  class="bb-input-timepicker"
  [attr.aria-labelledby]="ariaLabelledby"
  [attr.aria-describedby]="ariaDescribedby"
  [attr.aria-expanded]="ariaExpanded"
  [attr.role]="role"
  [attr.aria-invalid]="ariaInvalid"
  [attr.aria-owns]="ariaOwns"
  [readonlyInputs]="readonly"
  [disabled]="disabled"
  [spinners]="false"
  [hourStep]="hourStep"
  [meridian]="meridian"
  [minuteStep]="minuteStep"
  [seconds]="seconds"
  [secondStep]="secondStep"
  [size]="getKeywordBySize(size)"
  [attr.aria-label]="(!label && ariaLabel) || null"
  [ngModel]="value"
  (ngModelChange)="onValueChange($event)"
  data-role="timepicker"
  [attr.autcomplete]="autocomplete"
>
</ngb-timepicker>
