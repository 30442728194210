<div class="paragraph">
<p>Icons are used across our products as a visual aid to help highlight key information and support navigation and, they can also be used as triggers for actions.</p>
</div>
<div class="paragraph">
<p>There is a predefined list of icons available to use across our products and they have been made available as an icon font set with the Backbase Theme. For more information about icons used within our products, go to the <a href="/web-components/icon-set">web icon set page</a>.</p>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Our icons can be displayed in five different sizes.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/icons-sizes.png" alt="Icons sizes">
</div>
</div>
<div class="paragraph">
<p>They can appear in different colours, for example: in support of system notifications and the appropriate support colour.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/icons-usage.png" alt="Icons usage">
</div>
</div>
<div class="paragraph">
<p>In a specific categorisation set, defined with the secondary background color.</p>
</div>
<div class="paragraph img-fluid">
<p><span class="image"><img src="assets/images/icons-color.png" alt="Icons color"></span></p>
</div>
<div class="paragraph">
<p>And in conjunction with a <a href="/components/tooltip/">tooltip</a>.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/icons-component.png" alt="imIcons component">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do:</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Do use icons with purpose, for example: to aid navigation or highlight information.</p>
</li>
<li>
<p>Do use icons to support and indicate actions.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t:</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Don’t use too many icons, as it can confuse and distract users.</p>
</li>
<li>
<p>Don’t use standalone icons, with no context without a tool tip.</p>
</li>
<li>
<p>Don’t use icons to decorate content.</p>
</li>
<li>
<p>Don’t use obscure icons to convey simple things, if the icon doesn’t visually work as you intended, leave it out.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="paragraph">
<p>As we want to reach as many people as possible, it’s important that we use icons correctly to convey the right information. In our design system, we have two use cases for icons:<br>
<strong>Decorative</strong>: As visuals with copy to support / convey the meaning. If your icons are decorative, you’ll need to manually add an aria-hidden attribute to each of your icons so they’re accessible.<br>
<strong>Semantic</strong>: Conveys meaning (of action or item) without the support of copy. If your icons are semantic, you’ll need to manually add a few things so that your icon is appropriately accessible.</p>
</div>
</div>
</div>