import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorCommonStateModule } from '@backbase/ui-ang/common-error-state';
import { CommonErrorStateShowcaseComponent } from './common-error-state-showcase.component';

@NgModule({
  imports: [CommonModule, ErrorCommonStateModule],
  declarations: [CommonErrorStateShowcaseComponent],
})
export class CommonErrorStateShowcaseModule {}
