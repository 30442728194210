import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemBasicAccountShowcaseComponent } from './product-item-basic-account-showcase.component';
import { ProductItemBasicAccountModule } from '@backbase/ui-ang/product-item-basic-account';

@NgModule({
  imports: [CommonModule, ProductItemBasicAccountModule],
  declarations: [ProductItemBasicAccountShowcaseComponent],
})
export class ProductItemBasicAccountShowcaseModule {}
