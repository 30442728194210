<bb-product-item-basic-account-ui
  [title]="product.name"
  [amount]="product.amount"
  [productNumber]="product.productNumber"
  [currency]="product.currency"
  [productNumberFormat]="productNumberFormat"
  [highlight]="false"
  [favorite]="false"
  [active]="false"
  balanceLabel="Available balance"
></bb-product-item-basic-account-ui>
