<div class="card card--shadow-level-1">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr class="align-middle">
            <th scope="col"><span class="th-content">Country</span></th>
            <th scope="col">
              <span class="th-content">Unformatted Number</span>
            </th>
            <th scope="col"><span class="th-content">Formated Number</span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>CA</td>
            <td>16045555555</td>
            <td>{{ '16045555555' | phoneNumber: 'CA' }}</td>
          </tr>
          <tr>
            <td>NL</td>
            <td>0612345622</td>
            <td>{{ '0612345622' | phoneNumber: 'NL' }}</td>
          </tr>
          <tr>
            <td>GB</td>
            <td>07744556677</td>
            <td>{{ '07744556677' | phoneNumber: 'GB' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
