<div class="bb-item-log">
  @for (itemLogRecord of records; track $index) {
    <div class="bb-item-log__record" data-role="item-log-details-record">
      @if (customItemLog) {
        <ng-container *ngTemplateOutlet="customItemLog?.templateRef; context: { $implicit: itemLogRecord }">
        </ng-container>
      } @else {
        <div class="bb-stack bb-stack--align-top">
          <bb-icon-ui
            class="bb-item-log-record__icon"
            size="md"
            [name]="itemLogRecord?.status === 'REJECTED' ? 'cancel' : 'success'"
            [color]="itemLogRecord?.status === 'REJECTED' ? 'danger' : 'success'"
          ></bb-icon-ui>
          <div class="bb-item-log-record__details" role="group">
            <span class="bb-text-bold" i18n="@@item-log-waiting-for-approvals.status">
              {itemLogRecord?.status, select, REJECTED {Rejected} APPROVED {Approved} other {}}
            </span>
            <span>
              <ng-container i18n="@@item-log-approved-by.label"> by {{ itemLogRecord.userFullName }}</ng-container>
              @if (itemLogRecord?.externalUserId) {
                ({{ itemLogRecord.externalUserId }})
              }
            </span>
            <div class="bb-item-log-record__time">
              <time> {{ itemLogRecord?.createdAt | date }}</time>
              <ng-container i18n="@@item.log.details.created.at"> at</ng-container>
              <time> {{ itemLogRecord?.createdAt | date: 'shortTime' }}</time>
            </div>
          </div>
          @if (itemLogRecord?.comment) {
            <div class="bb-item-log-record__comment bb-stack bb-stack--density-sm">
              <div class="bb-stack__item bb-stack__item--align-top">
                <bb-icon-ui color="secondary" name="forum" size="sm"></bb-icon-ui>
              </div>
              <div class="bb-stack__item">
                {{ itemLogRecord?.comment }}
              </div>
            </div>
          }
        </div>
      }
    </div>
  }
  @if (isPending) {
    <div class="bb-item-log__pending-record" data-role="item-log-details-pending-row">
      <bb-icon-ui class="bb-text-support bb-item-log-record__icon" name="watch-later" size="md"></bb-icon-ui>
      @if (records?.length) {
        <div class="bb-item-log__pending-record--details bb-text-bold" i18n="@@item-log-waiting-for-approvals.label">
          Waiting for further approval(s)
        </div>
      } @else {
        <div class="bb-item-log__pending-record--details bb-text-bold" i18n="@@item-log-no-approvals.label">
          No approvals yet
        </div>
      }
    </div>
  }
</div>
