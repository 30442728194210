<main>
  <section class="home--dark">
    <div class="home__hero dso__content">
      <h1 class="home__hero-title">
        <small class="home__hero-title--small">Backbase</small>
        Design System
      </h1>

      <p class="home__hero-text">
        A bundle of guidelines, tools, and resources to help designers and developers co-create superior banking
        experiences.
      </p>

      <div>
        <a bbButton class="btn btn-primary home__hero-action" routerLink="/get-started">Get started</a>
      </div>

      <img class="home__hero-image" src="./assets/images/pages/home-hero.svg" alt="" role="presentation" />
    </div>
  </section>

  <section>
    <div class="home__main dso__content">
      @for (panel of mainPanels; track $index) {
        <div
          class="home__main-panel btn-on-color"
          [class.home__main-panel--design-tokens]="panel.isDesignTokens"
          [class.home__main-panel--components]="panel.isComponents"
        >
          <h2 class="home__main-panel-title">{{ panel.title }}</h2>
          <p class="home__main-panel-text">{{ panel.description }}</p>
          <a bbButton class="btn btn-secondary home__main-panel-action" [routerLink]="panel.link.path">
            {{ panel.link.text }}
          </a>
        </div>
      }
    </div>
  </section>

  <section class="home--colored">
    <div class="dso__content">
      <h2 class="home__title">Design & Build</h2>

      <div class="home__sub-section">
        @for (panel of navigationPanels; track $index) {
          <div class="home__sub-section-panel">
            @if (panel.icon) {
              <bb-icon-ui [name]="panel.icon" [color]="panel.iconColor" size="lg"></bb-icon-ui>
            }
            @if (panel.imageSrc) {
              <img [src]="panel.imageSrc" alt="" role="presentation" />
            }
            <h3 class="home__sub-section-panel-title">{{ panel.title }}</h3>
            <p class="home__sub-section-panel-text">{{ panel.description }}</p>
            @for (link of panel.links; track $index) {
              @if (link.isExternal) {
                <a
                  bbbutton
                  class="home__sub-section-panel-link btn-link-text"
                  [href]="link.path"
                  target="_blank"
                  rel="noopener"
                >
                  {{ link.text }}
                </a>
              }
              @if (!link.isExternal) {
                <a bbbutton class="home__sub-section-panel-link btn-link-text" [routerLink]="link.path">
                  {{ link.text }}
                </a>
              }
            }
          </div>
        }
      </div>
    </div>
  </section>
</main>
