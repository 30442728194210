import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AccountNumberPipeModule } from '@backbase/ui-ang/account-number-pipe';
import { AccountNumberPipeShowcaseComponent } from './account-number-pipe-showcase.component';
import {
  ACCOUNTS_DISPLAYING_FORMAT,
  AccountsDisplayingFormatConfiguration,
} from '@backbase/ui-ang/account-number-pipe';

export const accountsDisplayingFormatConfiguration: AccountsDisplayingFormatConfiguration = {
  iban: { segments: 4 },
  bban: { segments: 4 },
  cardNumber: {
    segments: [6, 8, 6],
    cardProviders: {
      visa: { segments: [3, 9, 9] },
    },
  },
};

@NgModule({
  imports: [CommonModule, FormsModule, AccountNumberPipeModule],
  declarations: [AccountNumberPipeShowcaseComponent],
  providers: [{ provide: ACCOUNTS_DISPLAYING_FORMAT, useValue: accountsDisplayingFormatConfiguration }],
})
export class AccountNumberPipeShowcaseModule {}
