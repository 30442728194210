<table [bbTable]="tokens" bbTableFocus class="design-token-list-table">
  <thead class="design-token-list-table__head">
    <tr class="design-token-list-table__row">
      <th></th>
      <th scope="col" tabindex="0" class="design-token-list-table__head-item">Name</th>
      <th scope="col" tabindex="0" class="design-token-list-table__head-item">Value</th>
      <th scope="col" tabindex="0" class="design-token-list-table__head-item">Status</th>
    </tr>
  </thead>
  <tbody class="design-token-list-table__body">
    @for (token of tokens; track $index) {
      <tr [bbRow]="token" class="design-token-list-table__row">
        <td class="design-token-list-table__body-item--wide">
          <div
            class="design-token-list-table__body-item-showcase"
            [style.backgroundColor]="token.token | stringToColorVariable"
          ></div>
        </td>
        <td class="design-token-list-table__body-item">
          <code class="design-token-list-table__body-item-token">{{ token.token }}</code>
        </td>
        <td
          class="design-token-list-table__body-item"
          [class.design-token-list-table__body-item--variable]="isVariable(token.default)"
        >
          {{ token.default }}
        </td>
        <td class="design-token-list-table__body-item">
          <span class="design-token-list-table__body-item-status">{{ getStatus(token) }}</span>
        </td>
      </tr>
    }
  </tbody>
</table>
