<bb-input-number-ui
  label="Mask Start"
  aria-label="Mask Start Index"
  placeholder="Mask Start Index"
  [(ngModel)]="maskStart"
>
</bb-input-number-ui>
<bb-input-number-ui label="Mask End" aria-label="Mask End Index" placeholder="Mask End Index" [(ngModel)]="maskEnd">
</bb-input-number-ui>
<hr />
{{ paymentCard?.number | paymentCardNumber : paymentCardNumberFormat }}
