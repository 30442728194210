import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

interface Frequency {
  value: string;
  every: number;
}

@Component({
  selector: 'bb-dropdown-single-select-example-ui',
  templateUrl: './dropdown-single-select.example.component.html',
})
export class DropdownSingleSelectExampleComponent {
  objectDropdownOptions = [
    { title: 'DuckDuckGo', url: 'https://www.duckduckgo.com' },
    { title: 'Google', url: 'https://google.com' },
    { title: 'Yahoo', url: 'https://yahoo.com' },
  ];

  stringDropdownOptions = ['DuckDuckGo', 'Google', 'Yahoo'];

  frequencies: Frequency[] = [
    {
      value: 'ONCE',
      every: 1,
    },
    {
      value: 'DAILY',
      every: 1,
    },
    {
      value: 'WEEKLY',
      every: 1,
    },
    {
      value: 'BIWEEKLY',
      every: 1,
    },
    {
      value: 'MONTHLY',
      every: 1,
    },
    {
      value: 'QUARTERLY',
      every: 1,
    },
    {
      value: 'YEARLY',
      every: 1,
    },
  ];

  vForm: UntypedFormGroup = this.formBuilder.group({
    websiteStrings: [
      this.stringDropdownOptions[0],
      Validators.required,
    ],
    websiteObjects: [
      this.objectDropdownOptions[0],
      Validators.required,
    ],
    frequency: [
      {
        value: 'ONCE',
        every: 1,
      },
      Validators.required,
    ],
  });

  constructor(private formBuilder: UntypedFormBuilder) {}

  compareByValue(p1: Frequency, p2: Frequency) {
    if (!p1 || !p2) return;

    return p1.value === p2.value;
  }
}
