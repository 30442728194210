import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'bb-char-counter-example-ui',
  templateUrl: './char-counter.example.component.html',
})
export class CharCounterExampleComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    textarea: ['', Validators.maxLength(160)],
  });

  constructor(private readonly formBuilder: UntypedFormBuilder) {}
}
