<div class="code-snippet-wrapper">
  <div [class.expanded]="showMore">
    <pre>
<code class="language-{{_language}}" [innerHTML]="_exampleTemplate"></code>
    </pre>
  </div>
  <bb-icon-ui (click)="copyToClipboard(codeContainer)" name="content-copy"> file_copy </bb-icon-ui>
  <input
    #codeContainer
    [value]="exampleTemplateRaw"
    aria-label="Example Code Container"
    class="visually-hidden"
    readonly="true"
  />
</div>
