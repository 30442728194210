<div class="paragraph">
<p>A card number is the digit identifier found on payment cards, such as credit cards and debit cards. This card number prefix identifies the issuer of the card, and the digits that follow are used by the issuing organization, such as a bank, to identify the cardholder as a customer with their designated accounts.</p>
</div>
<div class="sect1">
<h2 id="_grouping_card_numbers">Grouping Card Numbers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>By default, Card Numbers that are 16 digits long, are displayed in groups of four:</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/card-number-grouping.png" alt="Grouping card number">
</div>
</div>
<div class="paragraph">
<p>For cases with <strong>15 digits long</strong>, grouping changes to three groups of <strong>four</strong>, <strong>six</strong> and <strong>five</strong> respectively:</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/card-number-grouping2.png" alt="Grouping card number 15 digits">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_masking_card_numbers">Masking Card Numbers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>To reduce the risk of credit card fraud we use PAN truncation to prevent the dissemination of Card Numbers. This means that only the last four digits of a Card Number will be displayed and the remainder are replaced with bullet characters. When using this component, these bullets can be replaced by a custom character.</p>
</div>
<div class="paragraph">
<p><span class="image"><img src="assets/images/card-number-mask.png" alt="Car number mask"></span></p>
</div>
</div>
</div>