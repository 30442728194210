//Not exported to foundation
// DocPages handles design guidelines for UI Groups
export * from './src/ui-ang-doc-pages.module';
// UiDocs handles technical API docs for UI Group Members
export { bbUiDocs } from './src/bb-ui-docs';

// Exports the group member definitions published in DSO
import { collapsibleMembers } from './src/assets/adocs/grouped-by-collapsibles/group-list';
import { dropdownMembers } from './src/assets/adocs/grouped-by-dropdowns/group-list';
import { formMembers } from './src/assets/adocs/grouped-by-forms/group-list';
import { notificationMembers } from './src/assets/adocs/grouped-by-notifications/group-list';
import { productItemMembers } from './src/assets/adocs/grouped-by-product-items/group-list';
import { stateMembers } from './src/assets/adocs/grouped-by-states/group-list';
import { loaderMembers } from './src/assets/adocs/grouped-by-loaders/group-list';
import { areaChartMembers } from './src/assets/adocs/grouped-by-area-charts/group-list';
import { badgesMembers } from './src/assets/adocs/grouped-by-badges/group-list';
import { layoutMembers } from './src/assets/adocs/grouped-by-layouts/group-list';

export const uiGroups: Array<any> = [
  collapsibleMembers,
  dropdownMembers,
  formMembers,
  loaderMembers,
  notificationMembers,
  productItemMembers,
  stateMembers,
  areaChartMembers,
  badgesMembers,
  layoutMembers,
];

// In case no design guidelines are ready, add component to this list
export const noDesign: Array<any> = [
  'account-selector',
  'avatar',
  'header',
  'heading',
  'infinite-scroll',
  'period-selector',
  'logo',
  'rich-text-editor',
  'file-attachment',
  'account-number',
];

// In case of no live example, add component to this list
export const noWeb: Array<any> = [];

// tree handles the DSO design guideline html id values
export { tree } from './src/assets/menu-tree';
