import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { menuTree } from '../../../assets/menu-tree';
import { ShowcaseService } from '../../services/showcase.service';
import { MenuTree } from '../../../assets/menu-tree.interface';

@Component({
  selector: 'bb-side-menu',
  templateUrl: './side-menu.component.html',
  animations: [
    trigger('toggleChapter', [
      state(
        'open',
        style({
          height: '*',
          overflow: 'hidden',
        }),
      ),
      state(
        'closed',
        style({
          opacity: '0',
          overflow: 'hidden',
          height: '0px',
        }),
      ),
      state('expand', style({})),
      state('collapse', style({})),
      transition('open => closed', [animate('250ms linear')]),
      transition('closed => open', [animate('250ms linear')]),
    ]),
  ],
})
export class SideMenuComponent implements OnInit {
  /**
   * The callback fired upon closing the alert.
   */
  @Output() mobileToggle = new EventEmitter();
  @Output() navigationOccurred = new EventEmitter();

  menuTree: MenuTree[] = menuTree;

  constructor(public showcaseService: ShowcaseService, private route: Router) {}

  ngOnInit() {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRouteIndex = this.menuTree.findIndex((menuItem: any) => event.url.includes(menuItem.section));

        if (currentRouteIndex > -1) {
          this.menuTree[currentRouteIndex].isOpen = true;
        }

        if (event.id > 1) {
          this.navigationOccurred.emit();

          // handle subsequent navigations only
          this.mobileToggle.emit();
        }
      }
    });
  }

  toggle(parentMenuItem: any) {
    parentMenuItem.isOpen = !parentMenuItem.isOpen;
  }

  toggleKeyUp(parentMenuItem: any, $event: KeyboardEvent) {
    if ($event.code === 'Enter' || $event.code === 'Space') {
      parentMenuItem.isOpen = !parentMenuItem.isOpen;
    }
  }
}
