import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabShowcaseComponent } from './tab-showcase.component';
import { TabModule } from '@backbase/ui-ang/tab';
import { BadgeCounterModule } from '@backbase/ui-ang/badge-counter';

@NgModule({
  imports: [CommonModule, TabModule, BadgeCounterModule],
  declarations: [TabShowcaseComponent],
})
export class TabShowcaseModule {}
