<form [formGroup]="vForm">
  <bb-textarea-ui
    [id]="'textarea'"
    label="What's happening?"
    placeholder="Enter text..."
    formControlName="textarea"
    [showCharCounter]="true"
    [minLength]="3"
    [maxLength]="20"
    [rows]="3"
    [cols]="50"
    [aria-describedby]="'textAreaError'"
    wrap="hard"
  >
  </bb-textarea-ui>
  <bb-input-validation-message-ui [id]="'textAreaError'" [showErrors]="vForm?.dirty && vForm?.get('textarea')?.errors">
    <p role="alert">This field is not correct.</p>
  </bb-input-validation-message-ui>
</form>
