export const DROPDOWN_MENU_OPTIONS = ['PDF', 'RTF', 'PDF', 'RTF', 'PDF', 'RTF', 'PDF', 'RTF', 'PDF', 'RTF'];

export const DROPDOWN_TEMPLATE_MENU_OPTIONS = [
  { name: 'google', url: 'https://google.com' },
  { name: 'twitter', url: 'https://twitter.com' },
  { name: 'facebook', url: 'https://www.facebook.com' },
  { name: 'google', url: 'https://google.com' },
  { name: 'twitter', url: 'https://twitter.com' },
  { name: 'facebook', url: 'https://www.facebook.com' },
  { name: 'google', url: 'https://google.com' },
  { name: 'twitter', url: 'https://twitter.com' },
  { name: 'facebook', url: 'https://www.facebook.com' },
  { name: 'google', url: 'https://google.com' },
  { name: 'twitter', url: 'https://twitter.com' },
];
