import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@backbase/ui-ang/icon';
import { FormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';

import { InputCheckboxComponent } from './input-checkbox.component';

@NgModule({
  imports: [NgClass, IconModule, FormsModule, BaseClassesModule],
  declarations: [InputCheckboxComponent],
  exports: [InputCheckboxComponent],
})
export class InputCheckboxModule {}
