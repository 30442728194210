import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  booleanAttribute,
} from '@angular/core';

interface ButtonAction {
  text: string;
  icon?: string;
}

@Directive({ selector: 'ng-template[bbPageHeaderCustomAction]' })
export class PageHeaderCustomActionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name PageHeaderComponent
 *
 * @description
 * A component which provides a layout template for default responsive page headers in Web.
 * It can feature a title and an optional subtitle, as well as up to two default call-to-action buttons (or a custom action) and a navigation link.
 *
 * #### Usage notes
 * If you would like to use your custom action element, you can do the following:
 *
 * ```typescript
 * <bb-page-header-ui title="Page title" subtitle="Page subtitle">
 *   <ng-template bbPageHeaderCustomAction>
 *     <button>Action</button>
 *   </ng-template>
 *
 *   <div>Content</div>
 * </bb-page-header-ui>
 * ```
 *
 */
@Component({
  selector: 'bb-page-header-ui',
  templateUrl: './page-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  @ContentChild(PageHeaderCustomActionDirective, { read: TemplateRef, static: true })
  pageHeaderCustomAction?: TemplateRef<any>;

  /**
   * The main text to be displayed.
   *
   * Input is required.
   */
  @Input({ required: true }) title!: string;

  /**
   * Whether a colored background is rendered behind the component.
   * Changes the text and buttons to `on-color` elements.
   *
   * Defaults to false.
   */
  @Input({ transform: booleanAttribute }) showBackground = false;

  /**
   * The label of the navigation button.
   * Renders a button meant for navigating to the previous page if provided.
   */
  @Input() navigationLinkText?: string;

  /**
   * Supplementary text to accommodate the title.
   */
  @Input() subtitle?: string;

  /**
   * An object with properties of a button that takes the primary role.
   * Renders a button with `primary` style if provided.
   * Overwrites any custom action if provided.
   *
   * Accepts the properties: `text: string`, `icon: string`.
   */
  @Input() primaryAction?: ButtonAction;

  /**
   * An object with properties of a button that takes the secondary role.
   * Renders a button with `secondary` style if provided.
   * Overwrites any custom action if provided.
   *
   * Accepts the properties: `text: string`, `icon: string`.
   */
  @Input() secondaryAction?: ButtonAction;

  /**
   * An event fired when the navigation button is clicked.
   */
  @Output() navigationLinkClick = new EventEmitter<void>();

  /**
   * An event fired when the primary button is clicked.
   */
  @Output() primaryActionClick = new EventEmitter<void>();

  /**
   * An event fired when the secondary button is clicked.
   */
  @Output() secondaryActionClick = new EventEmitter<void>();
}
