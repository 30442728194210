<div class="bb-toolbar">
  <div class="text-center">
    <bb-load-button-ui
      color="primary"
      [id]="'load-more-button'"
      [isLoading]="isLoadMore[0]"
      [size]="8"
      [from]="loadMoreParams[0]?.from"
      [totalCount]="48"
      (paramChange)="setLoadingParams($event)"
      class="m-3"
      [disabled]="localDisabled"
    >
      Load more
    </bb-load-button-ui>
    <p class="mt-3">Counter &#x200F;(max 5):{{ loadMoreParams[0]?.from }}</p>
    <button (click)="localDisabled = !localDisabled">disable load button</button>
  </div>
  <div class="text-center">
    <bb-load-button-ui
      class="m-3"
      color="primary"
      [isLoading]="isLoadMore[1]"
      [size]="8"
      [from]="loadMoreParams[1]?.from"
      [totalCount]="48"
      (paramChange)="setLoadingParamsWithIcon($event)"
    >
      <span>Download file</span>
      <bb-icon-ui name="download" size="lg" cropped></bb-icon-ui>
    </bb-load-button-ui>
    <p class="mt-3">Counter &#x200F;(max 5):{{ loadMoreParams[1]?.from }}</p>
  </div>
  <div class="text-center">
    <bb-load-button-ui
      class="m-3"
      color="primary"
      [isLoading]="isLoadMore[2]"
      (paramChange)="setLoadingParamsOnlyIcon($event)"
      [circle]="true"
      [aria-label]="'Search'"
    >
      <bb-icon-ui name="search" size="lg"></bb-icon-ui>
    </bb-load-button-ui>
    <p class="mt-3">Infinite loading</p>
  </div>
</div>
