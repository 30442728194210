@if (label) {
  <label for="{{ id }}" [attr.data-role]="'label'" class="form-label">{{ label }}</label>
}
<input
  id="{{ id }}"
  #inputText
  [attr.aria-autocomplete]="ariaAutocomplete"
  [attr.aria-controls]="ariaControls"
  [attr.aria-describedby]="idListAttr(ariaDescribedby, maxLength && showCharCounter ? charCounterId : null)"
  [attr.aria-labelledby]="ariaLabelledby || null"
  [attr.aria-expanded]="ariaExpanded"
  [attr.role]="role"
  [attr.aria-invalid]="ariaInvalid"
  [attr.aria-owns]="ariaOwns"
  [attr.aria-label]="(!label && ariaLabel) || null"
  [attr.aria-activedescendant]="ariaActivedescendant"
  [ngModel]="value"
  (ngModelChange)="onValueChange($event)"
  (blur)="onBlur($event)"
  type="text"
  [inputMode]="inputMode"
  class="form-control"
  (focus)="onFocus($event)"
  [mask]="mask"
  [validation]="maskValidator"
  [disabled]="disabled"
  [readOnly]="readonly"
  [placeholder]="placeholder"
  [required]="required"
  [attr.size]="size"
  [attr.maxlength]="maxLength"
  [attr.minlength]="minLength"
  [attr.autocomplete]="autocomplete"
  [bbFocus]="autofocus"
  [ngClass]="inputClassName"
  [attr.data-role]="'input'"
/>
@if (maxLength && showCharCounter) {
  <bb-char-counter-ui
    [attr.id]="charCounterId"
    [element]="inputText"
    [inputValue]="value"
    [maxLength]="maxLength"
    [attr.data-role]="'text-input-char-counter'"
  >
  </bb-char-counter-ui>
}
