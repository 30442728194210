<form [formGroup]="vForm">
  <p>
    <bb-input-checkbox-ui
      label="I wish to remain anonymous"
      formControlName="anonymous"
    ></bb-input-checkbox-ui>
  </p>
  <bb-fieldset-ui
    heading="Personal information"
    [disabled]="vForm?.get('anonymous')?.value"
    [formGroup]="vForm?.get('personalInformation')"
  >
    <p>
      <bb-input-text-ui
        label="Name"
        formControlName="name"
      ></bb-input-text-ui>
    </p>
    <p>
      <bb-input-text-ui
        label="Surname"
        formControlName="surname"
      ></bb-input-text-ui>
    </p>
  </bb-fieldset-ui>
</form>
