import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-item-log-ui-showcase',
  templateUrl: './item-log-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ItemLogShowcaseComponent {
  pendingRecords = [
    {
      userFullName: 'Robin Green',
      status: 'APPROVED',
      createdAt: '2018-01-22T13:44:02Z',
    },
  ];
  rejectedRecords = [
    {
      userFullName: 'Green Goblet',
      status: 'REJECTED',
      createdAt: '2018-01-23T13:44:02Z',
      reason: 'Limit exceeded',
    },
    {
      userFullName: 'Robin Green',
      status: 'APPROVED',
      createdAt: '2018-01-22T13:44:02Z',
    },
  ];
  successRecords = [
    {
      userFullName: 'Green Goblet',
      status: 'APPROVED',
      createdAt: '2018-01-23T13:44:02Z',
    },
    {
      userFullName: 'Robin Green',
      status: 'APPROVED',
      createdAt: '2018-01-22T13:44:02Z',
    },
  ];
}
