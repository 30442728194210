import {
  Directive,
  ElementRef,
  Renderer2,
  Injector,
  ViewContainerRef,
  NgZone,
  Input,
  TemplateRef,
  ChangeDetectorRef,
  Inject,
  ApplicationRef,
  Optional,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import { TOOLTIP_CONFIG_TOKEN, TooltipConfig } from './tooltip.token-config';

let uniqueClassSuffix = 0;

/**
 * @name TooltipDirective
 *
 * @description
 * Directive that displays a tooltip.
 * <br><br>
 *
 * ### Global configuration token
 * `TOOLTIP_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `TooltipComponent` in your project.
 *
 * *Note:* The token overwrites the default value only. If you have provided a value as a property on a specific component, the token is not be able to overwrite it.
 *
 * The following properties can be overwritten using the token:
 *  - `triggers`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 *
 * ```typescript
 * import { TOOLTIP_CONFIG_TOKEN } from '@backbase/ui-ang/tooltip-directive';
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { AppModule } from './app/app.module';
 *
 * const tooltipConfig = {
 *   triggers: 'click',
 *   closeDelay: 400
 * };
 *
 * platformBrowserDynamic().bootstrapModule(AppModule, {
 *   providers: [{ provide: TOOLTIP_CONFIG_TOKEN, useValue: tooltipConfig }]
 * });
 * ```
 *
 */
@Directive({
  selector: '[bbTooltip]',
})
export class TooltipDirective extends NgbTooltip implements OnInit, OnDestroy {
  /**
   * Content to be displayed as tooltip. If falsy, the tooltip won't open. Accepts a string or TemplateRef.
   */
  @Input()
  set bbTooltip(value: string | TemplateRef<any> | null | undefined) {
    this.ngbTooltip = value;
  }

  /**
   * Specifies the events that should trigger the tooltip (space separated strings). Defaults to 'click'.
   *
   * This component extends `NgbTooltip`.
   * All available properties of `NgbTooltip` can be used with this component as well.
   * More information about it and more examples can be found in
   * [NgBootstrap documentation](https://ng-bootstrap.github.io/#/components/tooltip/api).
   *
   * This attribute can be overwritten via the global configuration token.
   */
  @Input() triggers = 'click';

  /**
   * Whether tooltip is initially opened with a given context.
   * If falsy, the tooltip will not open on render.
   *
   * Accepts any context that will be passed into NgTooltip `open()`.
   *
   * Defaults to `false`.
   */
  @Input()
  set isOpenWithContext(value: any) {
    if (!value) {
      return;
    }

    this.open(value);
  }

  // FIXME: this interface used to come from ng-bootstrap/utils
  // However this is internal api, so we can't rely on it.
  // There should be a proper solution for that issue, better then redeclaring the class,
  // so in fact this component is broken at the moment
  // @see https://github.com/ng-bootstrap/ng-bootstrap/issues/1043

  constructor(
    public el: ElementRef,
    renderer2: Renderer2,
    injector: Injector,
    viewContainerRef: ViewContainerRef,
    config: NgbTooltipConfig,
    _ngZone: NgZone,
    @Inject(DOCUMENT) _document: any,
    ref: ChangeDetectorRef,
    appref: ApplicationRef,
    @Optional() @Inject(TOOLTIP_CONFIG_TOKEN) private readonly overrideConfig: TooltipConfig,
  ) {
    // @ts-ignore
    super(el, renderer2, injector, viewContainerRef, config, _ngZone, _document, ref, appref);

    // has to be assigned here before @Input is initialised
    // this is equivalent to: setter ?? overrideConfig ?? 'click'
    this.triggers = this.overrideConfig?.triggers ?? this.triggers;
    this.closeDelay = this.overrideConfig?.closeDelay ?? this.closeDelay;
    this.openDelay = this.overrideConfig?.openDelay ?? this.openDelay;
  }

  private readonly uniqueTooltipClass = `bb-tooltip-${++uniqueClassSuffix}`;
  private element = this.el.nativeElement as HTMLElement;
  private manualTriggers: string[] = [];
  private cleanupFns: (() => void)[] = [];
  private timeout: any;

  /**
   * Event handler to set aria-expanded on opening the tooltip
   */
  open(context?: any) {
    if (this.disableTooltip) {
      return;
    }

    this.tooltipClass = !this.tooltipClass
      ? this.uniqueTooltipClass
      : this.tooltipClass.split(' ').includes(this.uniqueTooltipClass)
        ? this.tooltipClass
        : `${this.tooltipClass} ${this.uniqueTooltipClass}`;
    super.open(context);
    this.el.nativeElement.setAttribute('aria-expanded', true);
    if (this.manualTriggers.length > 0) {
      const tooltipEl = this.findTooltipElement();
      if (tooltipEl) {
        this.addEventListener('mouseenter', () => this.withDelay(() => this.open(), this.openDelay), tooltipEl);
        this.addEventListener('mouseleave', () => this.withDelay(() => this.close(), this.closeDelay), tooltipEl);
      }
    }
  }
  /**
   * Event handler to set aria-expanded on closing the tooltip
   */
  close() {
    super.close();
    this.el.nativeElement.setAttribute('aria-expanded', false);
  }

  /**
   * Find the tooltip element that is sibling to the directive element
   */
  private findTooltipElement(): HTMLElement | null {
    return document.querySelector(`.${this.uniqueTooltipClass}`);
  }

  /**
   * Add the given event listener to the directive element or the tooltip element, if provided
   * @param name The event to listen for
   * @param listener The function to call when the event is triggered
   * @param tooltipEl The tooltip element to add the event listener to. Optional, if not provided, the directive element will be used
   */
  private addEventListener(name: string, listener: () => void, tooltipEl: HTMLElement | undefined = undefined) {
    if (tooltipEl) {
      tooltipEl.addEventListener(name, listener);
      this.cleanupFns.push(() => tooltipEl.removeEventListener(name, listener));
    } else {
      this.element.addEventListener(name, listener);
      this.cleanupFns.push(() => this.element.removeEventListener(name, listener));
    }
  }

  /**
   * Clear the timeout and set a new one
   * @param fn The function to call after the delay
   * @param delayMs The delay in milliseconds
   */
  private withDelay(fn: () => void, delayMs: number) {
    clearTimeout(this.timeout);
    if (delayMs > 0) {
      this.timeout = setTimeout(fn, delayMs);
    } else {
      fn();
    }
  }

  /**
   * Set up event listeners for the manual triggers
   */
  private setUpListeners() {
    if (this.manualTriggers.includes('hover') || this.manualTriggers.includes('mouseenter:mouseleave')) {
      this.addEventListener('mouseleave', () => this.withDelay(() => this.close(), this.closeDelay));
      this.addEventListener('mouseenter', () => this.withDelay(() => this.open(), this.openDelay));
    }

    if (this.manualTriggers.includes('focus') || this.manualTriggers.includes('focusin:focusout')) {
      this.addEventListener('focusout', () => this.withDelay(() => this.close(), this.closeDelay));
      this.addEventListener('focusin', () => this.withDelay(() => this.open(), this.openDelay));
    }
  }

  /**
   * Check if the directive should use manual triggers
   */
  private useManualTriggers(): boolean {
    const triggersSplit = this.triggers.trim().split(' ');

    return (
      !triggersSplit.includes('click') &&
      (triggersSplit.includes('hover') || this.manualTriggers.includes('mouseenter:mouseleave')) &&
      this.closeDelay > 0
    );
  }

  ngOnInit(): void {
    if (this.useManualTriggers()) {
      this.manualTriggers = this.triggers.trim().split(' ');
      this.triggers = 'manual';
      this.setUpListeners();
    }
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this.cleanupFns.forEach((fn) => fn());
    super.ngOnDestroy();
  }
}
