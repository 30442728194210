import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-illustration-ui-showcase',
  templateUrl: './illustration-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class IllustrationShowcaseComponent {
  illustrations = [
    {
      name: 'ic_graph_bar_chart',
      size: 'xs',
    },
    {
      name: 'ic_attribute_bench',
      size: 'sm',
    },
    {
      name: 'ic_scenario_mobile_banking',
      size: 'md',
      priority: true,
    },
    {
      name: 'ic_character_casual_man_1_metro_sitting',
      size: 'lg',
    },
    {
      name: 'ic_background_dynamic',
      size: 'xl',
    },
  ];
}
