@switch (headingType) {
  @case ('h1') {
    <h1 [ngClass]="headingClasses" data-role="headings">
      <ng-container *ngTemplateOutlet="getHeadingTemplate()"></ng-container>
    </h1>
  }
  @case ('h2') {
    <h2 [ngClass]="headingClasses" data-role="headings">
      <ng-container *ngTemplateOutlet="getHeadingTemplate()"></ng-container>
    </h2>
  }
  @case ('h3') {
    <h3 [ngClass]="headingClasses" data-role="headings">
      <ng-container *ngTemplateOutlet="getHeadingTemplate()"></ng-container>
    </h3>
  }
  @case ('h4') {
    <h4 [ngClass]="headingClasses" data-role="headings">
      <ng-container *ngTemplateOutlet="getHeadingTemplate()"></ng-container>
    </h4>
  }
  @case ('h5') {
    <h5 [ngClass]="headingClasses" data-role="headings">
      <ng-container *ngTemplateOutlet="getHeadingTemplate()"></ng-container>
    </h5>
  }
  @case ('h6') {
    <h6 [ngClass]="headingClasses" data-role="headings">
      <ng-container *ngTemplateOutlet="getHeadingTemplate()"></ng-container>
    </h6>
  }
}

<ng-template #defaultTemplate>
  {{ heading }}
</ng-template>
