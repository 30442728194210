import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AmountShowcaseComponent } from './amount-showcase.component';
import { HighlightModule } from '@backbase/ui-ang/highlight';
import { AmountModule } from '@backbase/ui-ang/amount';

@NgModule({
  imports: [CommonModule, AmountModule, HighlightModule],
  declarations: [AmountShowcaseComponent],
})
export class AmountShowcaseModule {}
