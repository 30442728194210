import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyStateShowcaseComponent } from './empty-state-showcase.component';
import { EmptyStateModule } from '@backbase/ui-ang/empty-state';
import { ButtonModule } from '@backbase/ui-ang/button';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [CommonModule, EmptyStateModule, ButtonModule, IconModule],
  declarations: [EmptyStateShowcaseComponent],
})
export class EmptyStateShowcaseModule {}
