<div class="bb-block bb-block--lg">
  <h4>Default usage</h4>
  <p>
    <small
      >Aria-labelledby used with the header bellow. Screen readers should read out both the placeholder and the content
      of the header bellow</small
    >
  </p>
  <div class="bb-block bb-block--sm">
    <h5 id="D231FE2E-A39B-4EE3-9937-751C955C4D2C">Select a number</h5>
    <bb-dropdown-multi-select-ui
      [aria-labelledby]="'D231FE2E-A39B-4EE3-9937-751C955C4D2C'"
      [attr.data-role]="'dropdown-default'"
      [placeholder]="'No numbers selected'"
      [filtering]="true"
      [required]="true"
    >
      @for (option of options; track $index) {
        <bb-dropdown-multi-select-option-ui [label]="option.label" [value]="option.value">
        </bb-dropdown-multi-select-option-ui>
      }
    </bb-dropdown-multi-select-ui>
  </div>

  <div class="bb-block bb-block--sm">
    <h5 id="D231FE2E-A39B-4EE3-9937-751C955C4D2C-full-width">Select a number (full-width)</h5>
    <bb-dropdown-multi-select-ui
      [aria-labelledby]="'D231FE2E-A39B-4EE3-9937-751C955C4D2C-full-width'"
      [attr.data-role]="'dropdown-default-full-width'"
      [placeholder]="'No numbers selected'"
      [filtering]="true"
      [required]="true"
      [bbDropdownToggleFullWidth]="true"
    >
      @for (option of options; track $index) {
        <bb-dropdown-multi-select-option-ui [label]="option.label" [value]="option.value">
        </bb-dropdown-multi-select-option-ui>
      }
    </bb-dropdown-multi-select-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>Default usage with inline listbox header</h4>
  <p>
    <small
      >Aria-labelledby used with the header bellow. Screen readers should read out both the placeholder and the content
      of the header bellow.</small
    >
  </p>
  <p>
    <small>
      Additional inline header provided for the inner listbox. Screen readers should read out the inline header togheter
      with the first selected item. Voice over will fail doing so, while NVDA behaves as expected.
    </small>
  </p>
  <h5 id="D231FE2E-A39B-4EE3-9937-751C955C4D2D">Select another number</h5>
  <bb-dropdown-multi-select-ui
    [aria-labelledby]="'D231FE2E-A39B-4EE3-9937-751C955C4D2D'"
    [dropdownHeaderText]="'Ordered numbers'"
  >
    @for (option of options; track $index) {
      <bb-dropdown-multi-select-option-ui [label]="option.label" [value]="option.value">
      </bb-dropdown-multi-select-option-ui>
    }
  </bb-dropdown-multi-select-ui>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="D231FE2E-A39B-4EE3-9937-751C955C4D2DD">Using embed label</h4>
  <p>
    <small
      >Label content is used in order to indicate information about the control. Screen readers should read out both the
      placeholder and the label bellow.</small
    >
  </p>
  <bb-dropdown-multi-select-ui [label]="'Select another number'" [placeholder]="''">
    @for (option of options; track $index) {
      <bb-dropdown-multi-select-option-ui [label]="option.label" [value]="option.value">
      </bb-dropdown-multi-select-option-ui>
    }
  </bb-dropdown-multi-select-ui>
</div>

<div class="bb-block bb-block--lg">
  <h4 id="B4DD9EEF-2963-47A9-B2F0-374BC217DA9E">Disabled control</h4>
  <bb-dropdown-multi-select-ui
    [aria-labelledby]="'B4DD9EEF-2963-47A9-B2F0-374BC217DA9E'"
    [formControl]="disabledDropdownMultiSelect"
    [attr.data-role]="'dropdown-disabled'"
  >
  </bb-dropdown-multi-select-ui>
</div>

<div [formGroup]="form">
  <h4>Using reactive form control</h4>
  <div class="bb-block bb-block--lg">
    <bb-dropdown-multi-select-ui
      formControlName="categories"
      [required]="true"
      data-role="categories-filter"
      placeholder="– select a category –"
      label="Category"
    >
      @for (option of options; track $index) {
        <bb-dropdown-multi-select-option-ui [label]="option.label" [value]="option.value">
        </bb-dropdown-multi-select-option-ui>
      }
    </bb-dropdown-multi-select-ui>
  </div>
  <div class="bb-button-bar">
    <button bbButton (click)="resetForm()" class="bb-button-bar__button">reset form</button>
  </div>
</div>

<div class="bb-block bb-block--lg" [formGroup]="form">
  <h4>Dropdown with values updated after options change</h4>
  <div class="bb-block bb-block--lg">
    <bb-dropdown-multi-select-ui
      formControlName="dynamicCategories"
      data-role="dynamic-categories-filter"
      placeholder="Select options"
      label="Category"
    >
      @for (option of dynamicOptions; track $index) {
        <bb-dropdown-multi-select-option-ui [label]="option.label" [value]="option.value">
        </bb-dropdown-multi-select-option-ui>
      }
    </bb-dropdown-multi-select-ui>
  </div>
  <div class="bb-button-bar">
    <button bbButton (click)="changeOptions()" class="bb-button-bar__button">change options</button>
  </div>
</div>
