import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadButtonShowcaseComponent } from './load-button-showcase.component';
import { LoadButtonModule } from '@backbase/ui-ang/load-button';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [CommonModule, LoadButtonModule, IconModule],
  declarations: [LoadButtonShowcaseComponent],
})
export class LoadButtonShowcaseModule {}
