/*
  DSO will handle UI groups, using the key prop of exported object. Ensure that it is plural.
*/
export const formMembers: Object = {
  forms: [
    'amount-input',
    'input-checkbox',
    'checkbox-group',
    'currency-input',
    'input-datepicker',
    'input-email',
    'fieldset',
    'input-inline-edit',
    'list-multiple-select',
    'input-number',
    'input-phone',
    'input-password',
    'input-radio-group',
    'input-range',
    'switch',
    'input-timepicker',
    'input-text',
    'textarea',
    'input-validation-message',
  ],
};
