import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '@backbase/ui-ang/icon';

import { NotificationComponent } from './notification.component';
import { NotificationIconPipe } from './notification-icon.pipe';
import { ButtonModule } from '@backbase/ui-ang/button';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';
import { FocusModule } from '@backbase/ui-ang/focus';
import { NotificationIconColorPipe } from './notification-icon-color.pipe';

@NgModule({
  imports: [
    NgClass,
    NgTemplateOutlet,
    NgbAlertModule,
    IconModule,
    ButtonModule,
    KeyboardClickModule,
    FocusModule,
    A11yModule,
  ],
  declarations: [NotificationComponent, NotificationIconPipe, NotificationIconColorPipe],
  exports: [NotificationComponent],
})
export class NotificationModule {}
