<bb-product-item-current-account-ui
  [title]="product.name"
  [amount]="product.amount"
  [productNumber]="product.productNumber"
  [currency]="product.currency"
  [productNumberFormat]="productNumberFormat"
  [mainSectionCurrency]="interest.currency"
  [mainSectionAmount]="interest.amount"
  [mainSectionTitle]="interest.name"
  [secondarySectionCurrency]="credit.currency"
  [secondarySectionAmount]="credit.amount"
  [secondarySectionTitle]="credit.name"
  [productDetailsInfo]="productDetails"
  [highlight]="false"
  [favorite]="true"
>
</bb-product-item-current-account-ui>
