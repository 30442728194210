import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

interface Frequency {
  value: string;
  every: number;
}

const frequencies = [
  {
    value: 'ONCE',
    every: 1,
  },
  {
    value: 'DAILY',
    every: 1,
  },
  {
    value: 'WEEKLY',
    every: 1,
  },
  {
    value: 'BIWEEKLY',
    every: 1,
  },
  {
    value: 'MONTHLY',
    every: 1,
  },
  {
    value: 'QUARTERLY',
    every: 1,
  },
  {
    value: 'YEARLY',
    every: 1,
  },
];

@Component({
  selector: 'bb-dropdown-single-select-ui-showcase',
  templateUrl: './dropdown-single-select-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class DropdownSingleSelectShowcaseComponent {
  vForm: UntypedFormGroup | undefined;

  stringDropdownOptions = ['Option One', 'Option Two', 'Option Three'];

  objectDropdownOptions = [{ title: 'Option One' }, { title: 'Option Two' }, { title: 'Option Three' }];

  frequencies = frequencies;

  modelDropdownOptions = [
    { model: { displayNames: ['Selection A'], value: 'A' } },
    { model: { displayNames: ['Selection B'], value: 'B' } },
    { model: { displayNames: ['Selection C'], value: 'C' } },
  ];

  assetClassesOptions = [
    { name: 'Stock', id: 'stock' },
    { name: 'Bond', id: 'bonds' },
    { name: ' Liquid Assets', id: 'liquidassets' },
    { name: 'Real Estate', id: 'realestate' },
    { name: 'Cash', id: 'cash' },
  ];

  dropdownMenuOptions = [
    { name: 'google', url: 'https://google.com' },
    { name: 'twitter', url: 'https://twitter.com' },
    { name: 'facebook', url: 'https://www.facebook.com' },
  ];

  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      stringDropdownOptions: [this.stringDropdownOptions[0], Validators.required],
      objectDropdownOptions: [this.objectDropdownOptions[0], Validators.required],
      modelSingleSelect: [undefined, Validators.required],
      customSingleSelect: undefined,
      frequency: [
        {
          value: 'ONCE',
          every: 1,
        },
      ],
      i18nSingleSelect: ['one'],
      modelSingleSelectDisabled: [{ value: this.modelDropdownOptions[0], disabled: true }, Validators.required],
    });
  }

  isFieldValid(fieldName: string): boolean | undefined {
    const control = this.getControl(fieldName);
    if (control) {
      return control.valid || control.untouched;
    }

    return undefined;
  }

  private getControl(fieldName: string): AbstractControl | undefined {
    if (this.vForm) {
      return this.vForm.controls[fieldName];
    }

    return undefined;
  }

  compareByValue(p1: Frequency, p2: Frequency) {
    if (!p1 || !p2) return;

    return p1.value === p2.value;
  }

  hasRequiredError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors.required;
  }
}
