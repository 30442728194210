import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorShowcaseComponent } from './loading-indicator-showcase.component';
import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';

@NgModule({
  imports: [CommonModule, LoadingIndicatorModule],
  declarations: [LoadingIndicatorShowcaseComponent],
})
export class LoadingIndicatorShowcaseModule {}
