<form [formGroup]="vForm">
  <p>
    <textarea
      placeholder="Enter text..."
      formControlName="textarea"
      maxLength="160"
      rows="4"
      #remarksInput
    >
    </textarea>
  </p>
  <p>
    <bb-char-counter-ui
      [element]="remarksInput"
      [maxLength]="remarksInput.maxLength"
      [blockTyping]="true"
    ></bb-char-counter-ui>
  </p>
</form>
