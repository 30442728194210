import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AccountSelectorShowcaseComponent } from './account-selector-showcase.component';
import { AccountSelectorModule } from '@backbase/ui-ang/account-selector';
import { ButtonModule } from '@backbase/ui-ang/button';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';

const uiModules = [AccountSelectorModule, ButtonModule, InputValidationMessageModule];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ...uiModules],
  declarations: [AccountSelectorShowcaseComponent],
})
export class AccountSelectorShowcaseModule {}
