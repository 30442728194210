<div class="bb-period-selector">
  <div>
    <button
      bbButton
      circle="“true”"
      (click)="changePeriod(false)"
      color="dark"
      size="sm"
      aria-label="Go to the previous period"
      id="previous-button"
    >
      <bb-icon-ui size="md" [name]="chevronPrev"></bb-icon-ui>
    </button>
  </div>
  <div class="bb-period-selector__date">
    <div class="bb-period-selector__month">{{ formattedTitle }}</div>
    <div class="bb-period-selector__period bb-force-ltr">
      <time>{{ periodLeft }}</time
      >&lrm;&nbsp;- <time>{{ periodRight }}</time>
    </div>
  </div>
  <div>
    <button
      bbButton
      [circle]="true"
      (click)="changePeriod(true)"
      [ngClass]="{ disabled: isLastPeriod }"
      color="dark"
      size="sm"
      aria-label="Go to the next period"
      [attr.aria-disabled]="isLastPeriod"
      id="next-button"
    >
      <bb-icon-ui size="md" [name]="chevronNext"></bb-icon-ui>
    </button>
  </div>
</div>
