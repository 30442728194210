import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { NotificationService } from '@backbase/ui-ang/notification';

@Component({
  selector: 'bb-keyboard-click-example-ui',
  templateUrl: './keyboard-click.example.component.html',
})
export class KeyboardClickExampleComponent {
  constructor(private notificationService: NotificationService) {}

  action() {
    this.notificationService.showNotification({
      message: 'bbKeyboardClick event',
      modifier: 'info',
    });
  }
}
