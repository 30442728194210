<div class="bb-avatar" [ngClass]="[size ? 'bb-avatar--' + size : '', image ? 'bb-avatar--image' : '']">
  @if (!image) {
    <span
      class="bb-avatar__initials"
      [textContent]="initials || name | avatarInitials"
      data-role="avatar-initials"
      role="presentation"
    ></span>
  }
  @if (image) {
    <div
      [attr.aria-label]="imgAlt || name"
      class="bb-avatar__image"
      data-role="avatar-image"
      role="img"
      [style.backgroundImage]="'url(' + image + ')'"
    ></div>
  }
</div>
