import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { ControlErrorHandlerShowcaseComponent } from './control-error-handler-showcase.component';
import { ControlErrorHandlerModule } from '@backbase/ui-ang/control-error-handler';
import { InputWithCustomErrorComponent } from './custom-error/input-with-custom-errors-showcase.component';
import { CustomErrorShowcaseComponent } from './custom-error/custom-error-showcase.component';
import { CurrencyInputModule } from '@backbase/ui-ang/currency-input';
import { DropdownSingleSelectModule } from '@backbase/ui-ang/dropdown-single-select';
import { AmountInputModule } from '@backbase/ui-ang/amount-input';
import { RichTextEditorModule } from '@backbase/ui-ang/rich-text-editor';

const components = [ControlErrorHandlerShowcaseComponent, InputWithCustomErrorComponent, CustomErrorShowcaseComponent];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ControlErrorHandlerModule,
    InputTextModule,
    CurrencyInputModule,
    DropdownSingleSelectModule,
    RichTextEditorModule,
    AmountInputModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class ControlErrorHandlerShowcaseModule {}
