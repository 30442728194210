import { InjectionToken } from '@angular/core';

/**
 * Injection token used to provide static Timezone configuration.
 * Configuration value must match the time zone names of the IANA time zone database,
 * such as "Asia/Shanghai", "Asia/Kolkata", "America/New_York", "America/Los_Angeles"
 */
export const BB_TIMEZONE_CONFIG_TOKEN = new InjectionToken<string>('BB_TIMEZONE_CONFIGURATION_INJECTION_TOKEN', {
  providedIn: 'root',
  factory: () => '',
});
