import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { DEFAULT_ASSET_PATH, ILLUSTRATION_SIZES, ILLUSTRATION_SIZE_MAP } from './illustration.constants';
import { ILLUSTRATION_CONFIG_TOKEN, IllustrationConfig } from './illustration.token-config';

export interface IllustrationSizeObject {
  width: number | string;
  height: number | string;
}

export type IllustrationSize =
  | typeof ILLUSTRATION_SIZES.XS
  | typeof ILLUSTRATION_SIZES.SM
  | typeof ILLUSTRATION_SIZES.MD
  | typeof ILLUSTRATION_SIZES.LG
  | typeof ILLUSTRATION_SIZES.XL;

/**
 * @name IllustrationComponent
 *
 * @description
 * Component that displays an illustration from the Design System visual asset library.
 *
 * ### Usage
 * If you already have a Backbase app, please follow
 * https://backbase.io/developers/documentation/web-devkit/app-development/configure-static-assets/#_configure_an_application_to_add_static_assets to configure static assets inclusion.
 *
 * For this component in particular, you may need to add:
 *
 * ```ts
 * "assets": [
 *   {
 *     "glob": "**\/*",
 *     "input": "node_modules/@backbase/ui-ang/assets",
 *     "output": "/assets/design-system"
 *   }
 * ]
 * ```
 *
 * ### Global configuration token
 * `ILLUSTRATION_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `IllustrationComponent` in your project.
 *
 * *Note:* The token overwrites the default value only. If you have provided a value as a property on a specific component, the token is not be able to overwrite it.
 *
 * The following properties can be overwritten using the token:
 *  - `assetPath`
 *  - `fileType`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 *
 * ```ts
 * import { ILLUSTRATION_CONFIG_TOKEN } from '@backbase/ui-ang/illustration';
 * import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 * import { AppModule } from './app/app.module';
 *
 * const illustrationConfig = {
 *   fileType: 'png'
 * }
 *
 * platformBrowserDynamic().bootstrapModule(AppModule, {
 *   providers: [{ provide: ILLUSTRATION_CONFIG_TOKEN, useValue: illustrationConfig }]
 * });
 * ```
 *
 */
@Component({
  selector: 'bb-illustration-ui',
  templateUrl: './illustration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IllustrationComponent {
  /**
   * The name of the illustration.
   * This corresponds to the file name. Supports camelCase, snake_case or kebab-case.
   *
   * Input is required.
   */
  @Input({ required: true }) name!: string;

  /**
   * The class of the image. Used for additional styling.
   */
  @Input() classes?: string;

  /**
   * Indicates whether this image should have a high priority.
   */
  @Input() priority: NgOptimizedImage['priority'] = false;

  /**
   * The desired loading behavior (lazy, eager, or auto).
   *
   * Defaults to `lazy` which is recommended for most images.
   */
  @Input() loading: NgOptimizedImage['loading'];

  /**
   * Location of your assets folder.
   * Used for overriding or theming the illustrations.
   * This attribute can be overwritten via the global configuration token.
   *
   * Defaults to the `ui-ang` visual asset library folder.
   */
  @Input()
  get assetPath(): string {
    return this._assetPath ?? this.overrideConfig?.assetPath ?? DEFAULT_ASSET_PATH;
  }
  set assetPath(value: IllustrationConfig['assetPath']) {
    this._assetPath = value?.replace(/\/$/, '');
  }
  /**
   * The alt attribute value of the illustration HTML <img> tag.
   * Set the value to empty string `""` so that screen readers won't read it.
   * This useful when the image does not convey any meaning and avoid disturbing
   * a screen reader user
   *
   * Defaults to `undefined`. It will set `alt` to `name | illustrationAlt` in the html template.
   */
  @Input() alt?: string;

  private _assetPath: IllustrationConfig['assetPath'];

  /**
   * The file type.
   * This attribute can be overwritten via the global configuration token.
   *
   * Defaults to `svg`.
   */
  @Input()
  get fileType(): string {
    return this._fileType ?? this.overrideConfig?.fileType ?? 'svg';
  }
  set fileType(value: IllustrationConfig['fileType']) {
    this._fileType = value;
  }
  private _fileType: IllustrationConfig['fileType'];

  /**
   * Either
   *  - a string indicating the size. For example: `"sm"`, `"md"`
   *  - an object with dimensions: `{ width, height }`
   *
   *  Input is required.
   */
  @Input({
    required: true,
    transform: (val: IllustrationSize | IllustrationSizeObject) => {
      const invalidSizeFallback = { width: undefined, height: undefined };

      if (typeof val === 'string') {
        return ILLUSTRATION_SIZE_MAP[val] ?? invalidSizeFallback;
      }

      return val?.width && val?.height ? val : invalidSizeFallback;
    },
  })
  size!: IllustrationSizeObject;

  constructor(@Optional() @Inject(ILLUSTRATION_CONFIG_TOKEN) private readonly overrideConfig: IllustrationConfig) {}
}
