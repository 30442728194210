import { NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { IllustrationAltPipe } from './illustration-alt.pipe';
import { IllustrationComponent } from './illustration.component';
import { IllustrationSrcPipe } from './illustration-src.pipe';

@NgModule({
  declarations: [IllustrationComponent, IllustrationAltPipe, IllustrationSrcPipe],
  imports: [NgOptimizedImage],
  exports: [IllustrationComponent],
})
export class IllustrationModule {}
