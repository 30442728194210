<form [formGroup]="vForm">
  <div class="bb-block bb-block--xl">
    <h4>Char counter</h4>
    <bb-textarea-ui
      [id]="'charCounterTextareaEmpty'"
      label="Description"
      placeholder="Enter text..."
      formControlName="textarea"
      [showCharCounter]="true"
      [minLength]="3"
      [maxLength]="160"
      [rows]="3"
      [cols]="50"
      wrap="hard"
    ></bb-textarea-ui>
  </div>

  <div class="bb-block bb-block--xl">
    <h4>Char counter (almost full)</h4>
    <bb-textarea-ui
      [id]="'charCounterTextareaAlmostFull'"
      label="Description"
      placeholder="Enter text..."
      formControlName="textareaAlmostFull"
      [showCharCounter]="true"
      [minLength]="3"
      [maxLength]="20"
      [rows]="3"
      [cols]="50"
      wrap="hard"
    ></bb-textarea-ui>
  </div>

  <div class="bb-block bb-block--xl">
    <h4>Char counter (full)</h4>
    <bb-textarea-ui
      [id]="'charCounterTextareaFull'"
      label="Description"
      placeholder="Enter text..."
      formControlName="textareaFull"
      [showCharCounter]="true"
      [minLength]="3"
      [maxLength]="20"
      [rows]="3"
      [cols]="50"
      wrap="hard"
    ></bb-textarea-ui>
    <button class="btn btn-primary" (click)="reset()">CLEAR VALUE</button>
  </div>
</form>
