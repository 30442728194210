import { NgModule } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectListComponent } from './select-list.component';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';

@NgModule({
  imports: [AsyncPipe, NgSelectModule, FormsModule, ReactiveFormsModule, BaseClassesModule],
  declarations: [SelectListComponent],
  exports: [SelectListComponent],
})
export class SelectListModule {}
