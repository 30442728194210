@if (tabsShown$ | async; as tabsShown) {
  @if (tabsShown.length > 1) {
    <ul class="tabs">
      @for (tab of tabsShown; track $index) {
        <li class="tabs__tab">
          <a
            class="tabs__tab-link"
            [class.tabs__tab-link--active]="(activeTab$ | async)?.name === tab.name"
            [routerLink]="tab.path"
          >
            {{ tab.name }}
          </a>
        </li>
      }
    </ul>
  }
}
