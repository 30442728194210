import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bb-input-phone-example-ui',
  templateUrl: './input-phone.example.component.html',
})
export class InputPhoneExampleComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    workPhone: '4151234567',
  });

  country = [
    {
      countryCode: '+91',
      isoCode: 'IN',
      countryName: 'India',
    },
  ];

  countries = [
    {
      countryCode: '+1',
      isoCode: 'US',
      countryName: 'United States',
    },
    {
      countryCode: '+31',
      isoCode: 'NL',
      countryName: 'Netherlands',
    },
    {
      countryCode: '+34',
      isoCode: 'NZ',
      countryName: 'New Zealand',
    },
    {
      countryCode: '+355',
      isoCode: 'AL',
      countryName: 'Albania',
    },
    {
      countryCode: '+91',
      isoCode: 'IN',
      countryName: 'India',
    },
    {
      countryCode: '+55',
      isoCode: 'BR',
      countryName: 'Brazil',
    },
    {
      countryCode: '+420',
      isoCode: 'CZ',
      countryName: 'Czech Republic',
    },
    {
      countryCode: '+49',
      isoCode: 'DE',
      countryName: 'Germany',
    },
  ];

  constructor(private formBuilder: UntypedFormBuilder) {}
}
