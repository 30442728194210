import { InjectionToken } from '@angular/core';

/**
 * Defines the configuration options for avatar
 */
export interface AvatarConfig {
  /**
   * Function used to obtain the initials of an user when given their name
   */
  initialsFn?: (text: string) => string;
}

/**
 * Avatar configuration token
 */
export const AVATAR_CONFIG_TOKEN = new InjectionToken<AvatarConfig>('AVATAR_CONFIG_TOKEN');

/**
 * This function retrieves the initials that are shown in the avatar when given a full name
 */
export const defaultAvatarInitialsFn: (text: string) => string = (text) => {
  const textArr = text.split(' ');
  let result = '';

  if (textArr.length > 1) {
    result = textArr
      .filter((_, index, arr) => index === 0 || index === arr.length - 1)
      .reduce((initials, word) => `${initials}${word.charAt(0)}`, '');
  } else {
    result = textArr[0].slice(0, 2);
  }

  return result.toUpperCase();
};
