import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeriodSelectorShowcaseComponent } from './period-selector-showcase.component';
import { PeriodSelectorModule } from '@backbase/ui-ang/period-selector';

@NgModule({
  imports: [CommonModule, PeriodSelectorModule],
  declarations: [PeriodSelectorShowcaseComponent],
})
export class PeriodSelectorShowcaseModule {}
