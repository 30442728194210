import Quill from 'quill';
import { isRtl } from '@backbase/ui-ang/util';

const orderedList = isRtl() ? `bb-icon-format-list-numbered-rtl` : `bb-icon-format-list-numbered`;
type Icons = Record<string, string | Record<string, string>>;
export function registerCustomIconsForQuill(): void {
  const iconsPath = 'ui/icons';
  const icons: Icons = Quill.import(iconsPath) as Icons;
  icons['bold'] = '<i role="img" class="bb-icon bb-icon-format-bold bb-icon--md" aria-hidden="true"></i>';
  icons['italic'] = '<i role="img" class="bb-icon bb-icon-format-italic bb-icon--md" aria-hidden="true"></i>';
  icons['underline'] = '<i role="img" class="bb-icon bb-icon-format-underlined bb-icon--md" aria-hidden="true"></i>';
  icons['strike'] = '<i role="img" class="bb-icon bb-icon-format-strikethrough bb-icon--md" aria-hidden="true"></i>';
  icons['list'] = {
    ordered: `<i role="img" class="bb-icon ${orderedList} bb-icon--md" aria-hidden="true"></i>`,
    bullet:
      '<i role="img" class="bb-icon bb-icon--rtl bb-icon-format-list-bulleted bb-icon--md" aria-hidden="true"></i>',
  };
  icons['indent'] = {
    '+1': '<i role="img" class="bb-icon bb-icon-subject bb-icon--md" aria-hidden="true"></i>',
    '-1': '<i role="img" class="bb-icon bb-icon-subject flip-horizontal bb-icon--md" aria-hidden="true"></i>',
  };
  icons['code'] = '<i role="img" class="bb-icon bb-icon-code bb-icon--md" aria-hidden="true"></i>';
  icons['link'] = '<i role="img" class="bb-icon bb-icon-link bb-icon--md" aria-hidden="true"></i>';
  icons['image'] = '<i role="img" class="bb-icon bb-icon-image bb-icon--md" aria-hidden="true"></i>';
}
