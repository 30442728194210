<bb-tab-group-ui
  [initialSelection]="0"
  (click)="onClick($event)"
  (select)="onSelect($event)"
>
  <bb-tab-ui>First</bb-tab-ui>
  <bb-tab-ui>Second</bb-tab-ui>
  <bb-tab-ui>Third</bb-tab-ui>
  <bb-tab-ui [disabled]="true">Fourth</bb-tab-ui>
</bb-tab-group-ui>
