import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@backbase/ui-ang/icon';
import { FormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { CharCounterModule } from '@backbase/ui-ang/char-counter';

import { InputTextComponent } from './input-text.component';
import { FocusModule } from '@backbase/ui-ang/focus';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@NgModule({
  imports: [NgClass, IconModule, FormsModule, BaseClassesModule, FocusModule, CharCounterModule, NgxMaskDirective],
  declarations: [InputTextComponent],
  exports: [InputTextComponent],
  providers: [provideNgxMask()],
})
export class InputTextModule {}
