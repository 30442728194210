<div [class.bb-stepper__label]="currentStep?.label" class="bb-stepper" role="list">
  @for (step of steps; track $index) {
    <ng-container
      *ngTemplateOutlet="
        stepperStepTemplate || defaultStepperStepTemplate;
        context: {
          $implicit: step,
          number: $index + 1,
          isLast: $last,
          isChild: false
        }
      "
    >
    </ng-container>
    @if (!$last) {
      <div aria-hidden="true" class="bb-stepper__separator"></div>
    }
  }
</div>

<ng-template #defaultStepperStepTemplate let-step let-number="number" let-isLast="isLast" let-isChild="isChild">
  <div
    [class.bb-step--active]="step.isActive"
    [class.bb-step--current]="step.isCurrent"
    [class.bb-step--child]="isChild"
    [class.bb-step--last]="isLast"
    [class.bb-step--container]="step.steps?.length"
    [attr.aria-label]="step.ariaLabel !== step.label ? step.ariaLabel : null"
    [attr.aria-current]="step.isCurrent ? true : null"
    role="listitem"
    class="bb-step"
  >
    <div class="bb-step__icon">
      @if (step.isCurrent || !step.isChecked) {
        {{ number }}
      } @else {
        <bb-icon-ui name="check" size="sm"></bb-icon-ui>
      }
    </div>
    @if (step.label) {
      <div [class.bb-text-support]="!step.isActive" class="bb-step__label">
        @if (step.data && !step.isCurrent) {
          <a (click)="onClickHandler(step.data)" href="javascript:void(0)">
            <ng-container *ngTemplateOutlet="simpleStep; context: { $implicit: step }"></ng-container>
          </a>
        }
        @if (!step.data || step.isCurrent) {
          <ng-container *ngTemplateOutlet="simpleStep; context: { $implicit: step }"></ng-container>
        }
      </div>
    }
  </div>
  @for (subStep of step.steps; track $index) {
    <ng-container
      *ngTemplateOutlet="
        defaultStepperStepTemplate;
        context: {
          $implicit: subStep,
          isChild: true,
          isLast: !isLast && $last
        }
      "
    >
    </ng-container>
  }
</ng-template>

<ng-template #simpleStep let-step>
  {{ step.label }}
  @if (!step.steps?.length) {
    <span class="bb-step__state-label">{{ step.stateLabel }}</span>
  }
</ng-template>
