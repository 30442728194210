import { Injectable, Inject, LOCALE_ID, InjectionToken, Optional } from '@angular/core';
import { formatDate, FormStyle, getLocaleDayNames, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const DATEPICKER_I18 = new InjectionToken<NgbDatepickerI18n>('datepickerI18');

@Injectable()
export class NgbDatepickerI18nDefault extends NgbDatepickerI18n {
  private readonly weekdaysShort: Array<string>;
  private readonly monthsShort: ReadonlyArray<string>;
  private readonly monthsFull: ReadonlyArray<string>;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Optional() @Inject(DATEPICKER_I18) private datepickerI18: NgbDatepickerI18n,
  ) {
    super();

    const weekdaysStartingOnSunday = getLocaleDayNames(this.locale, FormStyle.Standalone, TranslationWidth.Short);
    this.weekdaysShort = weekdaysStartingOnSunday.map((day, index) => weekdaysStartingOnSunday[(index + 1) % 7]);
    this.monthsShort = getLocaleMonthNames(this.locale, FormStyle.Standalone, TranslationWidth.Abbreviated);
    this.monthsFull = getLocaleMonthNames(this.locale, FormStyle.Standalone, TranslationWidth.Wide);
  }

  getWeekdayLabel(weekday: number): string {
    return this.datepickerI18 ? this.datepickerI18.getWeekdayLabel(weekday) : this.weekdaysShort[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.datepickerI18 ? this.datepickerI18.getMonthShortName(month) : this.monthsShort[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.datepickerI18 ? this.datepickerI18.getMonthFullName(month) : this.monthsFull[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return this.datepickerI18
      ? this.datepickerI18.getDayAriaLabel(date)
      : formatDate(new Date(date.year, date.month - 1, date.day), 'fullDate', this.locale);
  }
}
