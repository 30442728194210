import { Pipe, PipeTransform } from '@angular/core';
import { segment } from '@backbase/ui-ang/util';
import { AccountsDisplayingFormatService } from '@backbase/ui-ang/account-number-pipe';

/**
 * @name bbIban
 *
 * @description
 * Pipe for formatting iban
 */
@Pipe({
  name: 'bbIban',
})
export class BbIbanPipe implements PipeTransform {
  private readonly defaultFormat = this.accountsDisplayingFormatService.getDefaultFormat('iban');
  /**
   * @name BbIbanPipe#constructor
   * @param accountsDisplayingFormatService - AccountsDisplayingFormatService
   *
   * @internal
   */
  constructor(private readonly accountsDisplayingFormatService: AccountsDisplayingFormatService) {}

  /**
   * @name BbIbanPipe#transform
   * @param value - the iban that should be formatted
   *
   * @description
   * Splits the iban up with spaces
   * Applies global numbers format configuration for IBAN attribute type
   */
  transform(value: string) {
    return `\u200e${segment(value, this.defaultFormat.segments)}`;
  }
}
