import { NgModule } from '@angular/core';

import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';
import { IconModule } from '@backbase/ui-ang/icon';

import { ActionStatusComponent } from './action-status.component';

@NgModule({
  imports: [LoadingIndicatorModule, IconModule],
  declarations: [ActionStatusComponent],
  exports: [ActionStatusComponent],
})
export class ActionStatusModule {}
