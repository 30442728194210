import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderShowcaseComponent } from './header-showcase.component';
import { HeaderModule } from '@backbase/ui-ang/header';

@NgModule({
  imports: [CommonModule, HeaderModule],
  declarations: [HeaderShowcaseComponent],
})
export class HeaderShowcaseModule {}
