<div>
  <h4>Collapsible (basic component without predefined markup)</h4>
  <bb-collapsible-ui>
    <ng-template bbCollapsibleHeader let-toggle="toggle" let-isOpen="isOpen">
      <div class="bb-stack bb-block bb-block--md">
        <span class="bb-stack__item">Custom template for collapsible header</span>
        <button
          bbButton
          color="primary"
          (click)="toggle()"
          class="bb-stack__item bb-stack__item--push-right"
          data-role="collapsible-toggle"
          [attr.aria-expanded]="isOpen"
          aria-controls="collapseExample"
        >
          <span>Custom toggle button</span>
          <bb-icon-ui [name]="isOpen ? 'toggle-up' : 'toggle-down'" [inverse]="true" size="md"></bb-icon-ui>
        </button>
      </div>
    </ng-template>
    <ng-template bbCollapsibleBody>
      <div class="card" data-role="collapsible-card" id="collapseExample">
        <div class="card-body">
          <p>Example of default content inside.</p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam consectetur doloremque ea excepturi,
            fugiat maxime minima nesciunt odit omnis.
          </p>
        </div>
      </div>
    </ng-template>
  </bb-collapsible-ui>
</div>
