<div
  class="infinite-scroll-wrapper"
  #scrollWrapper
  bbInfiniteScrollDirective
  [fromRoot]="fromRoot"
  [infiniteScrollContainer]="scrollContainer"
  (scrolled)="scrollEnd.emit()"
  [scrollWindow]="scrollWindow"
  [infiniteScrollDistance]="scrollDistance()"
  [infiniteScrollDisabled]="disableScrollEnd"
  [infiniteScrollThrottle]="debounce"
  [tabindex]="focusable ? '0' : '-1'"
  [attr.aria-label]="ariaLabel"
>
  <ng-content></ng-content>
</div>
