import { ElementRef, Injectable, Renderer2 } from '@angular/core';

/**
 * Utility service for DOM manipulations
 */
@Injectable({
  providedIn: 'root',
})
export class DomAttributesService {
  private idPrefix = 'bb_element_';
  private nextId = 0;

  public generateId() {
    return `${this.idPrefix}${this.nextId++}`;
  }

  moveAriaAttributes(hostEl: HTMLElement, childEl: HTMLElement, renderer2: Renderer2) {
    const nodeMap: NamedNodeMap = hostEl.attributes;
    // CUT every aria attribute from the host element and PASTE it into the specified element
    Object.keys(nodeMap)
      .map((key: string) => nodeMap.item(Number(key)))
      .filter((item) => item && (item.name.startsWith('aria-') || item.name.toLowerCase() === 'arialabel'))
      .forEach((item) => {
        const { name: attrName, value: attrVal } = item as Attr;
        if (attrName.toLowerCase() === 'arialabel') {
          renderer2.setAttribute(childEl, 'aria-label', attrVal);
        } else {
          renderer2.setAttribute(childEl, attrName, attrVal);
        }
        renderer2.removeAttribute(hostEl, attrName);
      });
  }

  copyAriaAttributes(hostEl: HTMLElement, childEl: ElementRef<any>, renderer2: Renderer2) {
    const nodeMap: NamedNodeMap = hostEl.attributes;
    Object.keys(nodeMap)
      .map((key: string) => nodeMap.item(Number(key)))
      .filter((item) => item && item.name.startsWith('aria-'))
      .forEach((item) => item && renderer2.setAttribute(childEl, item.name, item.value));
  }
}
