<div
  class="bb-textarea-ui"
  [ngClass]="{ 'ng-invalid': parentFormControl?.invalid, 'ng-touched': parentFormControl?.touched }"
>
  @if (label) {
    <label for="{{ id }}" [attr.data-role]="'textarea-label'" class="form-label">{{ label }}</label>
  }
  <textarea
    #textarea
    id="{{ id }}"
    [attr.aria-label]="label ? null : ariaLabel || null"
    [attr.aria-describedby]="idListAttr(ariaDescribedby, maxLength && showCharCounter ? charCounterId : null)"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-expanded]="ariaExpanded"
    [attr.role]="role"
    [attr.aria-invalid]="ariaInvalid"
    [attr.aria-owns]="ariaOwns"
    [attr.aria-activedescendant]="ariaActivedescendant"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    (blur)="onBlur()"
    class="form-control"
    [rows]="rows"
    [cols]="cols"
    (focus)="onFocus()"
    [disabled]="disabled"
    [readOnly]="readonly"
    [bbFocus]="autofocus"
    [placeholder]="placeholder"
    [required]="required"
    [attr.maxlength]="maxLength"
    [attr.minlength]="minLength"
    [wrap]="wrap"
    [attr.data-role]="'textarea-text-field'"
    autocomplete="off"
  ></textarea>
  @if (maxLength && showCharCounter) {
    <bb-char-counter-ui
      [attr.id]="charCounterId"
      [element]="textarea"
      [inputValue]="value"
      [maxLength]="maxLength"
      [attr.data-role]="'textarea-char-counter'"
    >
    </bb-char-counter-ui>
  }
</div>
