<div class="paragraph">
<p>Tables are used to logically structure content in a grid. This enables users to easily visualize information or compare data sets. In some examples, filters can be applied to sort and order items when comparison is not a priority.</p>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Anatomy of a Table</strong></p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Title</strong><br>
The title of a table should be clear and descriptive, explaining what the table is showing</p>
</li>
<li>
<p><strong>Text / Labels</strong><br>
Descriptive labels should be applied to tell users what appears in a column or row</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use when you want to visualise data and help users scan multiple values.</p>
</li>
<li>
<p>Show values across multiple categories and measures.</p>
</li>
<li>
<p>Allow for filtering and ordering when comparison is not a priority.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Center align the content.</p>
</li>
<li>
<p>Use this as an actionable list of items that link to details pages.</p>
</li>
<li>
<p>Truncate content. Instead the content should be but wrapped.</p>
</li>
</ul>
</div>
</div>
</div>