import { InjectionToken } from '@angular/core';

/**
 * Defines the configuration options for an email input field.
 */
export interface InputEmailConfig {
  /**
   * Specifies whether or not the browser should show autocomplete suggestions for email addresses.
   * Possible values are 'email', 'off', and 'on'.
   */
  autocomplete?: 'email' | 'off' | 'on';
  /**
   * The maximum number of characters that can be entered into the email input field.
   */
  maxLength?: number;
  /**
   * The minimum number of characters that must be entered into the email input field.
   */
  minLength?: number;
  /**
   * The placeholder text to display in the email input field when it is empty.
   */
  placeholder?: string;
  /**
   * The number of visible characters in the email input field.
   */
  size?: number;
  /**
   * A regular expression pattern that the entered email must match.
   */
  pattern?: string | RegExp;
}

export const INPUT_EMAIL_CONFIG_TOKEN = new InjectionToken<InputEmailConfig>('INPUT_EMAIL_CONFIG_TOKEN');
