import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertModule } from '@backbase/ui-ang/alert';
import { IconModule } from '@backbase/ui-ang/icon';
import { CollapsibleAccordionModule } from '@backbase/ui-ang/collapsible-accordion';
import { CollapsibleCardModule } from '@backbase/ui-ang/collapsible-card';

import { ShowcaseApiComponent } from './showcase/api/showcase-api.component';
import { ShowcaseScssTokensComponent } from './showcase/scss-tokens/showcase-scss-tokens.component';
import { ShowcaseDescriptionComponent } from './showcase/description/showcase-description.component';
import { ShowcaseExampleTemplateComponent } from './showcase/example-template/showcase-example-template.component';
import { ShowcaseMethodsComponent } from './showcase/methods/showcase-methods.component';
import { ShowcaseSelectorComponent } from './showcase/selector/showcase-selector.component';
import { ShowcaseUsageComponent } from './showcase/usage/showcase-usage.component';
import { ShowcaseComponent } from './showcase.component';
import { MetadataService } from './metadata.service';

export interface BbDsDocs {
  components?: any;
  directives?: any;
  pipes?: any;
  interfaces?: any;
}

export interface DsConfig {
  lib: any;
  exampleLib?: any;
  showcaseLib?: any;
  docs: BbDsDocs;
}

const components = [
  ShowcaseScssTokensComponent,
  ShowcaseApiComponent,
  ShowcaseDescriptionComponent,
  ShowcaseExampleTemplateComponent,
  ShowcaseMethodsComponent,
  ShowcaseSelectorComponent,
  ShowcaseUsageComponent,
  ShowcaseComponent,
];

/**
 * DynamicDsToolkitModule can be removed in favor of
 * importing the modules (BacbaseUiModule, BackbaseExamplesUiModule)
 * in the app level because this will kill AOT and/or build-optimization
 */
@NgModule({
  imports: [CommonModule, IconModule, CollapsibleAccordionModule, CollapsibleCardModule, AlertModule],
  declarations: [...components],
  providers: [MetadataService],
  exports: [...components],
})
export class DsToolkitModule {
  static forRoot(config: DsConfig): ModuleWithProviders<DsToolkitModule> {
    // @NgModule({
    //   imports: [
    //     CommonModule,
    //     IconModule,
    //     CollapsibleAccordionModule,
    //     CollapsibleCardModule,
    //     config.exampleLib,
    //     config.lib,
    //   ],
    //   declarations: [...components],
    //   exports: [...components],
    // })
    // class DynamicDsToolkitModule {}
    return {
      ngModule: DsToolkitModule,
      providers: [MetadataService, { provide: 'config', useValue: config }],
    };
  }
}

export {
  ShowcaseScssTokensComponent,
  ShowcaseApiComponent,
  ShowcaseDescriptionComponent,
  ShowcaseExampleTemplateComponent,
  ShowcaseMethodsComponent,
  ShowcaseSelectorComponent,
  ShowcaseUsageComponent,
  ShowcaseComponent,
};
