import {
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  Optional,
  Output,
  TemplateRef,
} from '@angular/core';
import { CollapsibleAccordionComponent, ICollapsible } from '@backbase/ui-ang/collapsible-accordion';

@Directive({ selector: 'ng-template[bbCollapsibleHeader]' })
export class CollapsibleHeaderDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCollapsibleBody]' })
export class CollapsibleBodyDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name CollapsibleComponent
 *
 * @description
 * Provides an expandable details-summary/body-header view.
 */
@Component({
  selector: 'bb-collapsible-ui',
  templateUrl: './collapsible.component.html',
})
export class CollapsibleComponent implements ICollapsible, OnDestroy {
  @ContentChild(CollapsibleHeaderDirective, { read: TemplateRef, static: true })
  collapsibleHeader: CollapsibleHeaderDirective | undefined;
  @ContentChild(CollapsibleBodyDirective, { read: TemplateRef, static: true })
  collapsibleBody: CollapsibleBodyDirective | undefined;

  /**
   * If true, it will use the ngIf approach to create the content
   * which is less performant but removes the extra div.
   * In case of styling issues, set this to true.
   */
  @Input() recreateBodyContent = false;
  /**
   * Whether chevron should be displayed. Defaults to true.
   *
   * @deprecated Deprecated in ui-ang@11. To be removed in ui-ang@13.
   */
  @Input() hasChevron = true;

  private open = false;

  /**
   * Toggles the content.
   */
  @Input()
  set isOpen(v: boolean) {
    if (this.open === v) {
      return;
    }

    this.open = v;
    this.headerContext.isOpen = v;
  }
  get isOpen() {
    return this.open;
  }

  /**
   * Emits the toggle content event.
   */
  @Output() isOpenChange = new EventEmitter<boolean>();

  headerContext: {
    toggle: () => void;
    isOpen: boolean;
  };

  toggle = () => {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.open);
  };

  //TODO: review approach with such dependency on Accordion
  constructor(
    @Optional()
    @Host()
    private readonly bbCollapsibleAccordion: CollapsibleAccordionComponent,
  ) {
    if (this.bbCollapsibleAccordion) {
      this.bbCollapsibleAccordion.registerNestedPanel(this);
    }

    this.headerContext = {
      toggle: this.toggle,
      isOpen: this.isOpen,
    };
  }

  ngOnDestroy() {
    if (this.bbCollapsibleAccordion) {
      this.bbCollapsibleAccordion.unregisterNestedPanel(this);
    }
  }
}
