import { Component } from '@angular/core';
@Component({
  selector: 'bb-modal-example-ui',
  templateUrl: './modal.example.component.html',
})
export class ModalExampleComponent {
  buttonColor = 'primary';

  showDialog = false;

  openDialog() {
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;
  }

  changeColor() {
    this.buttonColor =
      this.buttonColor === 'primary' ? 'secondary' : 'primary';
  }

  onConfirmDialog() {
    this.changeColor();
    this.closeDialog();
  }
}
