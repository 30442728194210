import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextareaShowcaseComponent } from './textarea-showcase.component';
import { TextareaModule } from '@backbase/ui-ang/textarea';
import { ReactiveFormsModule } from '@angular/forms';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';

@NgModule({
  imports: [CommonModule, TextareaModule, ReactiveFormsModule, InputValidationMessageModule],
  declarations: [TextareaShowcaseComponent],
})
export class TextareaShowcaseModule {}
