import { AsyncPipe, NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@backbase/ui-ang/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownMultiSelectComponent } from './dropdown-multi-select.component';
import { InputCheckboxModule } from '@backbase/ui-ang/input-checkbox';
import { DropdownPanelModule } from '@backbase/ui-ang/dropdown-panel';
import { DropdownMultiSelectOptionComponent } from './dropdown-multi-select-option.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  imports: [
    NgClass,
    AsyncPipe,
    IconModule,
    InputCheckboxModule,
    DropdownPanelModule,
    KeyboardClickModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  declarations: [DropdownMultiSelectComponent, DropdownMultiSelectOptionComponent],
  exports: [DropdownMultiSelectComponent, DropdownMenuModule, DropdownMultiSelectOptionComponent],
})
export class DropdownMultiSelectModule {}
