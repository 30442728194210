import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@backbase/ui-ang/icon';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@backbase/ui-ang/button';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { InputInlineEditComponent } from './input-inline-edit.component';
import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';
import { TooltipModule } from '@backbase/ui-ang/tooltip-directive';
import { CharCounterModule } from '@backbase/ui-ang/char-counter';
import { AmountModule } from '@backbase/ui-ang/amount';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  imports: [
    NgClass,
    NgTemplateOutlet,
    IconModule,
    ReactiveFormsModule,
    BaseClassesModule,
    ButtonModule,
    InputTextModule,
    InputValidationMessageModule,
    LoadingIndicatorModule,
    TooltipModule,
    CharCounterModule,
    AmountModule,
    KeyboardClickModule,
  ],
  declarations: [InputInlineEditComponent],
  exports: [InputInlineEditComponent],
})
export class InputInlineEditModule {}
