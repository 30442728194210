import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductSelectorShowcaseComponent } from './product-selector-showcase.component';
import { ProductSelectorModule } from '@backbase/ui-ang/product-selector';
import { ProductItemBasicAccountModule } from '@backbase/ui-ang/product-item-basic-account';
import { AmountModule } from '@backbase/ui-ang/amount';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [CommonModule, ProductSelectorModule, ProductItemBasicAccountModule, AmountModule, ButtonModule],
  declarations: [ProductSelectorShowcaseComponent],
})
export class ProductSelectorShowcaseModule {}
