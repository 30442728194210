<form [formGroup]="vForm">
  <bb-list-multiple-select-ui
    formGroupName="listMultipleSelect"
    [config]="listMultipleSelectItemsConfig"
    aria-label="List multiple select"
  >
    <ng-template bbListMultipleSelectAction>
      <button class="btn btn-tertiary btn-sm" (click)="onListMultipleSelectClearAll()">Clear All</button>
    </ng-template>
  </bb-list-multiple-select-ui>
</form>
