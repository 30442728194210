import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
  ListMultipleSelectComponent,
  ListMultipleSelectActionDirective,
  ListMultipleSelectItemDirective,
} from './list-multiple-select.component';
import { InputCheckboxModule } from '@backbase/ui-ang/input-checkbox';
import { IconModule } from '@backbase/ui-ang/icon';
import { CheckboxGroupModule } from '@backbase/ui-ang/checkbox-group';

/**
 * @name ListMultipleSelectModule
 *
 * @deprecated Module is deprecated as of ui-ang v12. It will be removed in ui-ang v14.
 */
@NgModule({
  imports: [NgClass, NgTemplateOutlet, ReactiveFormsModule, InputCheckboxModule, IconModule, CheckboxGroupModule],
  declarations: [ListMultipleSelectComponent, ListMultipleSelectActionDirective, ListMultipleSelectItemDirective],
  exports: [ListMultipleSelectComponent, ListMultipleSelectActionDirective, ListMultipleSelectItemDirective],
})
export class ListMultipleSelectModule {}
