import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'bb-showcase-ios',
  templateUrl: './showcase-ios.component.html',
})
export class ShowcaseIOSComponent {
  path$: Observable<string | undefined> = this.route.paramMap.pipe(
    map((params: ParamMap) => {
      const componentId = params.get('id');

      return `assets/docs/html/ios-${componentId}-page.component.html`;
    }),
  );
  constructor(private route: ActivatedRoute) {}
}
