import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, BehaviorSubject, of, Subject } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { SelectListItemBase } from '@backbase/ui-ang/select-list';

interface SelectListItemValue extends SelectListItemBase {
  value: string;
}

interface SelectListItemCode extends SelectListItemBase {
  code: string;
}

@Component({
  selector: 'bb-select-list-ui-showcase',
  templateUrl: './select-list-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class SelectListShowcaseComponent implements OnInit {
  form!: UntypedFormGroup;

  /** Examples for standard data structure - e.g. { name: '', value: '' } */
  singleSelectItems: SelectListItemValue[] = [
    { name: 'Single One', value: 'single-one' },
    { name: 'Single Two', value: 'single-two' },
    { name: 'Single Three', value: 'single-three' },
  ];

  multiSelectItems: SelectListItemValue[] = [
    { name: 'Multi One', value: 'multi-one' },
    { name: 'Multi Two', value: 'multi-two' },
    { name: 'Multi Three', value: 'multi-three' },
    { name: 'Multi Four', value: 'multi-four' },
    { name: 'Multi Five', value: 'multi-five' },
  ];

  /** Example for non-standard data structure - e.g. { name: '', code: '' } */
  countries: SelectListItemCode[] = [
    { name: 'France', code: 'FRA' },
    { name: 'Germany', code: 'GER' },
    { name: 'Italy', code: 'ITA' },
    { name: 'United Kingdom', code: 'UK' },
  ];

  itemsObservable$ = of([
    { name: 'Item One', value: 'item-one' },
    { name: 'Item Two', value: 'item-two' },
  ]);

  private readonly searchOnLoadSubject = new BehaviorSubject<string>('');
  private readonly searchAfterValueSubject = new Subject<string>();
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  searchOnLoadItems$ = this.searchOnLoadSubject.pipe(
    tap(() => this.loadingSubject.next(true)),
    delay(1000),
    switchMap(() => this.itemsObservable$),
    tap(() => this.loadingSubject.next(false)),
  );
  loading: Observable<boolean> = this.loadingSubject.asObservable();

  searchAfterValueItems$ = this.searchAfterValueSubject.pipe(switchMap((term) => this.getResultsFromApi(term)));

  ngOnInit() {
    this.form = new UntypedFormGroup({
      singleSelect: new UntypedFormControl(null),
      multipleSelect: new UntypedFormControl([]),
      multipleSelectDestinations: new UntypedFormControl([]),
      multipleSelectObservable: new UntypedFormControl([]),
      searchOnLoad: new UntypedFormControl(undefined),
      searchAfterValue: new UntypedFormControl(undefined),
    });
  }

  searchOnLoad(value: string) {
    this.searchOnLoadSubject.next(value);
  }

  searchAfterValue(value: string) {
    this.searchAfterValueSubject.next(value);
  }

  private getResultsFromApi(searchTerm: string): Observable<SelectListItemValue[] | undefined> {
    // Simulates calling an API to get results that match a given search term (server-side filtering)
    return this.itemsObservable$.pipe(
      map((results) =>
        results.filter((item) => !!searchTerm && item.name.toLowerCase().includes(searchTerm.toLowerCase())),
      ),
      delay(1000),
    );
  }
}
