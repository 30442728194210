import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bb-header-page-ui',
  templateUrl: './bb-header-page-ui.component.html',
  styles: [],
})
export class BbHeaderPageUiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
