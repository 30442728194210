<div class="modal-footer" [attr.data-role]="'modal-footer'">
  @if (customModalFooter) {
    <ng-container *ngTemplateOutlet="customModalFooter?.templateRef; context: hostRef"> </ng-container>
  } @else {
    <div class="bb-button-bar">
      <button
        (click)="onConfirm()"
        class="bb-button-bar__button btn btn-primary btn-md"
        type="button"
        [attr.data-role]="confirmText ? confirmText.trim().toLowerCase().replace(' ', '-') : 'confirm'"
      >
        @if (!confirmText) {
          <ng-container i18n="Accept|Agree with the message presented in the dialog @@bb-dialog-ui.yes">
            Confirm
          </ng-container>
        }
        @if (confirmText) {
          {{ confirmText }}
        }
      </button>
      <button
        (click)="onCancel()"
        class="bb-button-bar__button btn btn-secondary btn-md"
        type="button"
        [attr.data-role]="cancelText ? cancelText.trim().toLowerCase().replace(' ', '-') : 'cancel'"
      >
        @if (!cancelText) {
          <ng-container i18n="Reject|Do not agree with the message presented in the dialog @@bb-dialog-ui.no">
            Cancel
          </ng-container>
        }
        @if (cancelText) {
          {{ cancelText }}
        }
      </button>
    </div>
  }
</div>
