<span
  class="badge"
  [class.break-word]="breakWord"
  [ngClass]="[color ? 'badge-' + color : '', circle ? 'rounded-pill' : '', wrap ? 'text-wrap' : '']"
>
  @if (!abbr) {
    {{ text }}
  }
  @if (abbr) {
    <abbr [attr.aria-label]="ariaLabel" title="{{ text }}">
      {{ abbr === true && text ? text[0] : abbr }}
    </abbr>
  }
</span>
