import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-switch-ui-showcase',
  templateUrl: './switch-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class SwitchShowcaseComponent {
  vForm: UntypedFormGroup | undefined;
  value = true;
  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      defaultSwitch1: false,
      defaultSwitch2: false,
      defaultSwitch3: false,
      defaultSwitch4: true,
      defaultSwitch5: { value: false, disabled: true },
      defaultSwitch6: { value: true, disabled: true },
    });
  }
}
