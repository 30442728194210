<h4>Tab group - Light</h4>

<div class="bb-block bb-block--lg">
  <bb-tab-group-ui [initialSelection]="0" (select)="onTabSelect($event)" (click)="onClick($event)">
    <bb-tab-ui>First</bb-tab-ui>
    <bb-tab-ui>Second</bb-tab-ui>
    <bb-tab-ui>
      <div class="bb-stack">
        <span class="bb-stack__item bb-stack__item--spacing-xs">Third</span>
        <bb-badge-counter-ui
          class="bb-stack__item"
          [count]="3"
          [maxCount]="10"
          [color]="'danger'"
        ></bb-badge-counter-ui>
      </div>
    </bb-tab-ui>
    <bb-tab-ui [disabled]="true">Fourth</bb-tab-ui>
    <bb-tab-ui [disabled]="true">
      <div class="bb-stack">
        <span class="bb-stack__item bb-stack__item--spacing-xs">Fifth</span>
        <bb-badge-counter-ui
          class="bb-stack__item"
          [count]="2"
          [maxCount]="10"
          [color]="'danger'"
        ></bb-badge-counter-ui>
      </div>
    </bb-tab-ui>
  </bb-tab-group-ui>
</div>
<h4>Tab group - Dark</h4>

<div class="bg-dark bb-block bb-block--lg">
  <bb-tab-group-ui
    class="bb-tab--inverse"
    [initialSelection]="0"
    (select)="onTabSelect($event)"
    (click)="onClick($event)"
  >
    <bb-tab-ui>First</bb-tab-ui>
    <bb-tab-ui [disabled]="true">Second</bb-tab-ui>
    <bb-tab-ui>
      <div class="bb-stack">
        <span class="bb-stack__item bb-stack__item--spacing-xs">Third</span>
        <bb-badge-counter-ui
          class="bb-stack__item"
          [count]="3"
          [maxCount]="10"
          [color]="'danger'"
        ></bb-badge-counter-ui>
      </div>
    </bb-tab-ui>
    <bb-tab-ui>Fourth</bb-tab-ui>
    <bb-tab-ui [disabled]="true">
      <div class="bb-stack">
        <span class="bb-stack__item bb-stack__item--spacing-xs">Fifth</span>
        <bb-badge-counter-ui
          class="bb-stack__item"
          [count]="2"
          [maxCount]="10"
          [color]="'danger'"
        ></bb-badge-counter-ui>
      </div>
    </bb-tab-ui>
  </bb-tab-group-ui>
</div>

<h4>Tab On Color</h4>

<div class="bg-primary bb-block bb-block--lg">
  <bb-tab-group-ui
    class="bb-tab--on-color"
    [initialSelection]="0"
    (select)="onTabSelect($event)"
    (click)="onClick($event)"
  >
    <bb-tab-ui>First</bb-tab-ui>
    <bb-tab-ui [disabled]="true">Second</bb-tab-ui>
    <bb-tab-ui>
      <div class="bb-stack">
        <span class="bb-stack__item bb-stack__item--spacing-xs">Third</span>
        <bb-badge-counter-ui
          class="bb-stack__item"
          [count]="3"
          [maxCount]="10"
          [color]="'danger'"
        ></bb-badge-counter-ui>
      </div>
    </bb-tab-ui>
    <bb-tab-ui>Fourth</bb-tab-ui>
    <bb-tab-ui [disabled]="true">
      <div class="bb-stack">
        <span class="bb-stack__item bb-stack__item--spacing-xs">Fifth</span>
        <bb-badge-counter-ui
          class="bb-stack__item"
          [count]="2"
          [maxCount]="10"
          [color]="'danger'"
        ></bb-badge-counter-ui>
      </div>
    </bb-tab-ui>
  </bb-tab-group-ui>
</div>

<h4>Animated tabs</h4>

<div class="bb-quick-transfer__tabs-container">
  <bb-tab-group-ui [initialSelection]="0" (select)="onTabSelect($event)" (click)="onClick($event)">
    <bb-tab-ui>First</bb-tab-ui>
    <bb-tab-ui>Second</bb-tab-ui>
    <bb-tab-ui>Third</bb-tab-ui>
  </bb-tab-group-ui>
  <div class="scrollable-box-content position-relative" [@tabsAnimation]="index">
    @if (index === 0) {
      <div data-role="first-tab">First tab content here</div>
    }
    @if (index === 1) {
      <div data-role="second-tab">Second tab content here</div>
    }
    @if (index === 2) {
      <div data-role="third-tab">Third tab content here</div>
    }
    @if (index === 3) {
      <div data-role="fourth-tab">Fourth tab content here</div>
    }
    @if (index === 4) {
      <div data-role="fifth-tab">Fifth tab content here</div>
    }
  </div>
</div>
