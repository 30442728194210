<div class="paragraph">
<p>An empty state is used to display feedback to the user when expected content is missing for any reason. Use this component to provide guidance to users, clearly explain why they are seeing this state, and what they can do next.</p>
</div>
<div class="paragraph">
<p>There are different use cases in which an empty state can be shown, however, the structure of an empty state is always the same, regardless of the widget or platform.</p>
</div>
<div class="sect1">
<h2 id="_anatomy_of_an_empty_state">Anatomy of an Empty State</h2>
<div class="sectionbody">
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/empty-state-anatomy.png" alt="Empty state anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Icon</strong> (optional)<br>
An icon to visually support the message is displayed to give more context of the empty state. The chosen icon should clearly relate either to the corresponding widget it is being displayed in, or the action being performed by the user, <em>e.g. show a ‘magnifier’ icon for empty search results</em>.</p>
</li>
<li>
<p><strong>Title</strong><br>
A clear line of text, explaining what’s going on on the page.</p>
</li>
<li>
<p><strong>Message</strong> (optional)<br>
A short and concise text message providing more details on the issue and what the user can do next.</p>
</li>
<li>
<p><strong>Actions</strong> (optional)<br>
One or two actions available to the user to take, given the empty state. According to each use case where this component is used, this could be only <em>primary</em> action or none.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_placement">Placement</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Empty states are placed in the very center of the container where content or data is missing.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/empty-state-page.png" alt="Example of an empty state in a page">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_content_guidelines">Content guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Content in empty state titles and messages depend entirely on each use case, however we recommend to follow these tips when writing content:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Keep titles to single sentence and avoid using punctuation such as periods, commas, or semicolons</p>
</li>
<li>
<p>Titles are written in sentence case so capitalize the first letter.</p>
</li>
<li>
<p>Make the content descriptive by using concise and clear language that is easy to scan.</p>
</li>
<li>
<p>Don’t repeat in the message what is said in the title.</p>
</li>
<li>
<p>Replace singular with plural form when talking about more than one item.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Empty states can have different icons and messages to fit different use cases such as no results from a search or no data to display (like an account with no transactions).</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don&#8217;ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When working with empty states, follow this recommendations to keep a consistent experience.</p>
</div>
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Keep messages short and clear.</p>
</li>
<li>
<p>Choose an icon that represents visually the information needed to convey in the message.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Hide the title of an empty state, this is the most important part of the component.</p>
</li>
</ul>
</div>
<div class="sect2">
<h3 id="_use_when">Use when</h3>
<div class="ulist">
<ul>
<li>
<p>There are no items to display on a view</p>
</li>
<li>
<p>Search or filtering output has no results</p>
</li>
<li>
<p>User does not have permission to view content</p>
</li>
<li>
<p>In case of connectivity issues</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_dont_use">Don’t use</h3>
<div class="ulist">
<ul>
<li>
<p>When the app is loading, in that case, use the <a href="/ui-components/loading-state/">loading state</a> component.</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Empty states are placed at the center of their parent container and adapt to all breakpoints in the Backbase Grid and behave in the same way as in all devices.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/empty-state-responsive.png" alt="Responsive example of an empty state">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The icon part of the empty state is non-functional and should be hidden from screen readers using an attribute such as <a href="https://www.w3.org/WAI/PF/aria/states_and_properties#aria-hidden" class="bare">https://www.w3.org/WAI/PF/aria/states_and_properties#aria-hidden</a>.</p>
</div>
</div>
</div>