import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CurrencyInputShowcaseComponent } from './currency-input-showcase.component';
import { CurrencyInputModule } from '@backbase/ui-ang/currency-input';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';

@NgModule({
  imports: [CommonModule, CurrencyInputModule, ReactiveFormsModule, FormsModule, InputValidationMessageModule],
  declarations: [CurrencyInputShowcaseComponent],
})
export class CurrencyInputShowcaseModule {}
