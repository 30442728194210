import { NgModule } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { InfiniteScrollComponent } from './infinite-scroll.component';
// The module `ngx-infinite-scroll` (ngx-infinite-scroll@14.0.0) has been implemented  as nested directive as result
// of this decision:
// https://backbase.atlassian.net/wiki/spaces/GUIL/pages/3888447689/Introducing+new+NPM+module+ngx-infinite-scroll
import { bbInfiniteScrollDirective } from './ngx-infinite-scroll-directive/ngx-infinite-scroll.directive';

@NgModule({
  imports: [AsyncPipe],
  declarations: [InfiniteScrollComponent, bbInfiniteScrollDirective],
  exports: [InfiniteScrollComponent],
})
export class InfiniteScrollModule {}
