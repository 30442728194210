<ng-template [ngTemplateOutlet]="progressTrackerItemTemplate" [ngTemplateOutletContext]="hostRef"></ng-template>
<ng-template
  #defaultProgressTrackerItemTemplate
  let-progressStartTime="eventPayload?.progressStartTime"
  let-name="eventPayload?.name"
  let-eta="eventPayload?.eta"
  let-itemId="eventPayload?.itemId"
  let-cancelItem="cancelItem"
  let-size="eventPayload?.size"
  let-current="eventPayload?.current"
>
  <div class="bb-stack">
    <div class="bb-stack__item">
      <ng-template [ngTemplateOutlet]="progressTrackerHeaderTemplate" [ngTemplateOutletContext]="hostRef"></ng-template>
    </div>
    <div class="bb-stack__item bb-stack__item--push-right bb-text-support">
      {{ eventPayload?.progressStartTime | date: 'shortTime' }}
    </div>
  </div>
  <div class="bb-block bb-block--md bb-text-support">
    <div class="bb-block bb-block--xs">{{ eventPayload?.name }}</div>
    <div class="bb-block bb-block--xs">
      <div class="bb-inline-stack bb-block bb-block--md">
        <div
          class="bb-inline-stack__item"
          i18n="
            Estimated time label|Progress tracker item estimated time left
            label@@progress-tracker-item.estimated.time.left.label"
        >
          Estimated time left:
        </div>
        <div class="bb-inline-stack__item">
          @if (eventPayload?.eta.hours > 0 || eventPayload?.eta.mins > 0 || eventPayload?.eta.secs > 0) {
            @if (eventPayload?.eta.hours > 0) {
              <ng-container
                i18n="Estimated time hours|Progress tracker eta hours@@progress-tracker-item.estimated.time.left.hours"
              >
                {{ eta.hours }} {eta.hours, plural, =1 {hour} other {hours}}
              </ng-container>
            }
            @if (eventPayload?.eta.mins > 0) {
              <ng-container
                i18n="
                  Estimated time minutes|Progress tracker eta minutes@@progress-tracker-item.estimated.time.left.minutes"
              >
                {{ eta.mins }} {eta.mins, plural, =1 {minute} other {minutes}}
              </ng-container>
            }
            @if (eventPayload?.eta.hours === 0 && eventPayload?.eta.mins === 0 && eventPayload?.eta.secs > 0) {
              <ng-container
                i18n="
                  Estimated time seconds|Progress tracker eta seconds@@progress-tracker-item.estimated.time.left.seconds"
              >
                {{ eta.secs }} {eta.secs, plural, =1 {second} other {seconds}}
              </ng-container>
            }
          } @else {
            <ng-container
              i18n="
                Calculating time label|Progress tracker calculating eta
                label@@progress-tracker-item.calculating.estimated.time.left"
            >
              calculating...
            </ng-container>
          }
        </div>
      </div>
      <div class="bb-block bb-block--md">
        <bb-progressbar-ui [showValue]="false" [value]="eventPayload?.current" [max]="eventPayload?.size" height="4px">
        </bb-progressbar-ui>
      </div>
      <div class="bb-button-bar">
        <button
          bbButton
          class="bb-button-bar__button"
          color="secondary"
          (click)="hostRef.cancelItem(itemId)"
          i18n="Stop file upload button|Progress tracker item stop button label@@progress-tracker-item.stop.btn.label"
        >
          Cancel upload
        </button>
      </div>
    </div>
  </div>
  <ng-template #calculating>
    <ng-container
      i18n="
        Calculating time label|Progress tracker calculating eta
        label@@progress-tracker-item.calculating.estimated.time.left"
    >
      calculating...
    </ng-container>
  </ng-template>
  <ng-template #defaultProgressTrackerHeaderTemplate>
    <span class="bb-text-bold" i18n="Uploading file label|Progress tracker heading@@progress-tracker.heading">
      Batch file is uploading...
    </span>
  </ng-template>
</ng-template>
