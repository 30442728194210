import { NgModule } from '@angular/core';
import { NgClass, NgTemplateOutlet } from '@angular/common';

import { TabGroupComponent } from './tab-group.component';
import { TabComponent } from './tab.component';

@NgModule({
  imports: [NgClass, NgTemplateOutlet],
  declarations: [TabGroupComponent, TabComponent],
  exports: [TabGroupComponent, TabComponent],
})
export class TabModule {
  /**
   * Creates a module that exports `bb-tab-group-ui` and `bb-tab-ui` components
   *
   * @usageNotes
   *
   * ### Example
   *
   * ```
   * @NgModule({
   *   imports: [TabModule]
   * })
   * class MyModule {}
   * ```
   */
}
