import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'bb-file-attachment-example-ui',
  templateUrl: './file-attachment.example.component.html',
})
export class FileAttachmentExampleComponent {
  attachment: {
    name: string;
    size: number;
    fileContent: Observable<ArrayBuffer>;
  } = {
    name: 'examplePDF.pdf',
    size: 10000,
    fileContent: of(new ArrayBuffer(2000000)),
  };
}
