import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollShowcaseComponent } from './infinite-scroll-showcase.component';
import { InfiniteScrollModule } from '@backbase/ui-ang/infinite-scroll';
import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';
import { AmountModule } from '@backbase/ui-ang/amount';

@NgModule({
  imports: [CommonModule, InfiniteScrollModule, LoadingIndicatorModule, AmountModule],
  declarations: [InfiniteScrollShowcaseComponent],
})
export class InfiniteScrollShowcaseModule {}
