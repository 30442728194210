import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import '@angular/localize/init';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ElementViewInitDirective, ModalComponent } from './modal.component';
import { CustomModalFooterDirective, ModalFooterComponent } from './modal-templates/modal-footer.component';
import { CustomModalBodyDirective, ModalBodyComponent } from './modal-templates/modal-body.component';
import { CustomModalHeaderDirective, ModalHeaderComponent } from './modal-templates/modal-header.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { IconModule } from '@backbase/ui-ang/icon';
import { NgTemplateOutlet } from '@angular/common';

const components = [
  ModalComponent,
  ModalFooterComponent,
  CustomModalFooterDirective,
  ModalBodyComponent,
  CustomModalBodyDirective,
  ModalHeaderComponent,
  CustomModalHeaderDirective,
];
@NgModule({
  imports: [ButtonModule, IconModule, NgbModalModule, A11yModule, NgTemplateOutlet],
  declarations: [...components, ElementViewInitDirective],
  exports: [...components],
})
export class ModalModule {}
