import { Component, Input, ContentChildren, QueryList, AfterContentChecked, forwardRef } from '@angular/core';

/**
 * @name StepperStepComponent
 *
 * @description
 * Component that represents a single step that is a part of the stepper component.
 */
@Component({
  selector: 'bb-stepper-step-ui',
  template: '',
})
export class StepperStepComponent implements AfterContentChecked {
  /**
   * Represents stepper's steps.children
   */
  @ContentChildren(forwardRef(() => StepperStepComponent))
  private readonly _steps: QueryList<StepperStepComponent> | undefined;

  steps: StepperStepComponent[] | undefined;

  /**
   * Represents step's label
   */
  @Input() label?: string;

  /**
   * Represents step's state label
   */
  @Input() stateLabel?: string;

  /**
   * Represents step's data
   */
  @Input() data?: any;

  /**
   * Represents step's area-label
   */
  @Input() ariaLabel?: string;

  /**
   * Marks step as current
   */
  @Input() isCurrent = false;

  /**
   * Marks step as checked
   */
  @Input() isChecked = false;

  /**
   * Marks step as active
   */
  @Input() isActive = false;

  ngAfterContentChecked() {
    // TODO: workaround for angular bug https://github.com/angular/angular/issues/10098#issuecomment-235157642
    // an infinite loop takes place in banking schematics
    // it requires more investigation to see the root cause
    this.steps = this._steps?.filter((item) => item !== this);
  }
}
