import { InjectionToken } from '@angular/core';
import { NumberFormat } from 'libphonenumber-js';
import { CountryList } from './input-phone.component';

export interface InputPhoneConfig {
  maxLength?: number;
  minLength?: number;
  validationPattern?: string;
  autocomplete?: 'tel' | 'on' | 'off';
  mask?: string;
  displayFormat?: NumberFormat | Capitalize<Lowercase<NumberFormat>>;
  hideSelectedCountryFlag?: boolean;
  defaultCountryIsoCode?: string;
  countryList?: CountryList;
  enableCountryCode?: boolean;
}
export const INPUT_PHONE_CONFIG_TOKEN = new InjectionToken<InputPhoneConfig>('INPUT_PHONE_CONFIG_TOKEN');

export interface CountryCodeFormatConfig {
  mask?: string;
  minLength?: number;
  maxLength?: number;
}
export interface CountryCodeFormatConfigMap {
  [key: string]: CountryCodeFormatConfig;
}
export const COUNTRY_CODE_FORMAT_CONFIG_TOKEN = new InjectionToken<CountryCodeFormatConfigMap>(
  'COUNTRY_CODE_FORMAT_CONFIG_TOKEN',
);
