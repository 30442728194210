import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { defaultAriaLabels, defaultTooltips } from './input-inline-edit.model';
import { idListAttr } from '@backbase/ui-ang/util';
import { debounceTime, merge, Subject, takeUntil } from 'rxjs';

export enum InputInlineEditState {
  IDLE,
  LOADING,
  EDITING,
}

/**
 * @name InputInlineEditComponent
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 *
 * @description
 * Component that enables inline input editing.
 */
@Component({
  selector: 'bb-input-inline-edit-ui',
  templateUrl: './input-inline-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputInlineEditComponent),
      multi: true,
    },
  ],
})
export class InputInlineEditComponent extends InputBaseComponent implements AfterContentInit, OnInit, OnDestroy {
  private _state = InputInlineEditState.IDLE;
  private _inputText: string | undefined;
  private destroy$ = new Subject<void>();

  /**
   * State for inline edit
   */
  @Input() set state(value: InputInlineEditState) {
    this._state = value;

    if (this.vForm && value === InputInlineEditState.IDLE) {
      this.vForm.controls.inputInline.setValue(this._inputText);
    }
  }
  /**
   * Emit on edit state changes
   */
  @Output() stateChange = new EventEmitter<InputInlineEditState>();
  /**
   * Flag represents visibility of edit button
   *
   * @default: true
   */
  @Input() canEdit = true;
  /**
   * Applies additional loading state for edit flow
   *
   * @default: false
   */
  @Input() hasLoadingState = false;
  /**
   * Template for custom and styling text
   */
  @Input() inputInlineTemplate: TemplateRef<InputInlineEditComponent> | undefined;
  /**
   * string for editing
   */
  @Input() set inputText(value: string | undefined) {
    this._inputText = value;

    this.vForm?.controls.inputInline.setValue(this._inputText);
  }
  get inputText(): string | undefined {
    return this._inputText;
  }
  /**
   * The maxLength for the text input.
   */
  @Input() maxLength = Infinity;

  /**
   * The minLength for the text input.
   */
  @Input() minLength = 0;

  /**
   *  The max number value of the text input
   */
  @Input() maxValue?: number;

  /**
   *  The min number value of the text input
   */
  @Input() minValue?: number;

  /**
   * Whether the text input should be auto-focused when shown.
   */
  @Input() autofocus = false;

  /**
   * Displays custom error message for pattern validation failure.
   */
  @Input() patternErrorMessage = 'Input value provided is invalid';

  /**
   * The event that's fired after on Cancel.
   */
  @Output() cancel = new EventEmitter<void>();
  /**
   * The event that's fired after on Accept.
   */
  @Output() accept = new EventEmitter<string>();

  /**
   * string for aria label on Accept button
   */
  @Input() ariaLabelAccept = defaultAriaLabels.accept;

  /**
   * string for aria label on Cancel button
   */
  @Input() ariaLabelCancel = defaultAriaLabels.cancel;

  /**
   * string for aria label on Edit button
   */
  @Input() ariaLabelEdit = defaultAriaLabels.edit;

  /**
   * string for aria label on edit input
   */
  @Input() ariaLabelInput = defaultAriaLabels.input;

  /**
   * string for tooltip on Accept button
   */
  @Input() tooltipAccept = defaultTooltips.accept;

  /**
   * string for tooltip on Cancel button
   */
  @Input() tooltipCancel = defaultTooltips.cancel;

  /**
   * string for tooltip on Edit button
   */
  @Input() tooltipEdit = defaultTooltips.edit;

  /**
   * The autocomplete value of the enclosed input control.
   */
  @Input() autocomplete?: string = 'off';

  /**
   * Whether the text input should follow a pattern.
   */
  @Input() pattern?: RegExp | string;

  /**
   * Hint to be displayed in edit mode
   */
  @Input() hint?: string;

  /**
   * Shows character counter based on `maxLength`
   */
  @Input() showCharCounter = false;

  /**
   * Whether to consider the input value as a currency
   */
  @Input() currency?: string;

  @ViewChild('editButton')
  private editButton?: ElementRef;

  vForm: UntypedFormGroup | undefined;

  /**
   * Id of input validation message dom element
   */
  readonly validationMessagesId: string;
  /**
   * Id of input description dom element
   */
  readonly inlineEditDescriptionId: string;

  /**
   * Utility function for use in template
   */
  public idListAttr = idListAttr;

  constructor(
    cd: ChangeDetectorRef,
    private readonly domAttributesService: DomAttributesService,
  ) {
    super(cd);
    this.validationMessagesId = this.domAttributesService.generateId();
    this.inlineEditDescriptionId = this.domAttributesService.generateId();
  }

  ngOnInit() {
    super.ngOnInit();
    this.focusEditButtonListener();
  }

  ngAfterContentInit() {
    const validators = [Validators.maxLength(this.maxLength), Validators.minLength(this.minLength)];
    if (this.required) {
      validators.push(Validators.required);
    }

    if (this.pattern) {
      validators.push(Validators.pattern(this.pattern));
    }

    if (this.minValue) {
      validators.push(Validators.min(this.minValue));
    }

    if (this.maxValue) {
      validators.push(Validators.max(this.maxValue));
    }

    this.vForm = new UntypedFormGroup({
      inputInline: new UntypedFormControl(this._inputText, Validators.compose(validators)),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(inputValue: Object | string | null): void {
    if (typeof inputValue === 'string') {
      this._inputText = inputValue;
    }
    super.writeValue(inputValue);
  }

  onEdit(event: MouseEvent): void {
    event.stopPropagation();

    this.updateState(InputInlineEditState.EDITING);
  }

  onCancel(event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }

    this.vForm?.controls.inputInline.setValue(this._inputText);
    this.updateState(InputInlineEditState.IDLE);
    this.cancel.emit();
  }

  get isLoading(): boolean {
    return this._state === InputInlineEditState.LOADING;
  }

  get editMode(): boolean {
    return this._state === InputInlineEditState.EDITING;
  }

  get isIdleMode(): boolean {
    return this._state === InputInlineEditState.IDLE;
  }

  /**
   * Emits the updated value from input
   *
   * If the {@link InputInlineEditComponent.hasLoadingState } set to true -> the state is not changed,
   * as component state should be updated from outside, otherwise the value will be updated with the
   * new one and state will be switched to `IDLE`
   *
   */
  onAccept(): void {
    const newString = this.vForm ? this.vForm.controls.inputInline.value : '';

    if (!this.hasLoadingState) {
      this.updateState(InputInlineEditState.IDLE);
      this._inputText = newString;
      this.onValueChange(newString);
    }

    this.accept.emit(newString);
  }

  hasError(type?: string): boolean | undefined {
    const fieldControl = this.vForm ? this.vForm.controls.inputInline : null;
    if (!fieldControl || !fieldControl.errors) {
      return undefined;
    }

    return type ? fieldControl.errors[type] : fieldControl.errors;
  }

  /**
   * Cancels the edit mode when the escape key is pressed.
   *
   * @param evt The keyboard event.
   */
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(evt: KeyboardEvent): void {
    if (this.editMode) {
      this.onCancel();
    }
  }

  private updateState(value: InputInlineEditState): void {
    this._state = value;
    this.stateChange.emit(value);
  }

  private focusEditButtonListener() {
    merge(this.cancel.asObservable(), this.accept.asObservable())
      // Due to the fact that edit button is removed with ngIf
      // we need to wait for change detection cycle to finish
      // before accessing it. for that purpose debounceTime was added
      .pipe(debounceTime(0), takeUntil(this.destroy$))
      .subscribe(() => {
        this.editButton?.nativeElement.focus();
      });
  }
}
