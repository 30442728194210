import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTimepickerShowcaseComponent } from './input-timepicker-showcase.component';
import { InputTimepickerModule } from '@backbase/ui-ang/input-timepicker';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, InputTimepickerModule, InputValidationMessageModule, ReactiveFormsModule, FormsModule],
  declarations: [InputTimepickerShowcaseComponent],
})
export class InputTimepickerShowcaseModule {}
