import { NgModule } from '@angular/core';

import { InputValidationMessageComponent } from './input-validation-message.component';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [IconModule],
  declarations: [InputValidationMessageComponent],
  exports: [InputValidationMessageComponent],
})
export class InputValidationMessageModule {}
