<h2>Dates</h2>

<div class="col-12 mb-5">
  <div class="card shadow-sm">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-hover" bbTable>
          <thead>
            <tr>
              <th>Pipe usage</th>
              <th class="bb-text-align-end">Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Shorter:</td>
              <td class="bb-text-align-end">
                {{ dateToTransform | bbDate : 'd/MM' : 'GMT+0100' }}
              </td>
            </tr>
            <tr>
              <td>Short:</td>
              <td class="bb-text-align-end">
                {{ dateToTransform | bbDate : 'd/MM/yy' : 'GMT+0100' }}
              </td>
            </tr>
            <tr>
              <td>MediumShort:</td>
              <td class="bb-text-align-end">
                {{ dateToTransform | bbDate : 'd/MM/y' : 'GMT+0100' }}
              </td>
            </tr>
            <tr>
              <td>Medium:</td>
              <td class="bb-text-align-end">
                {{ dateToTransform | bbDate : 'mediumDate' : 'GMT+0100' }}
              </td>
            </tr>
            <tr>
              <td>Long:</td>
              <td class="bb-text-align-end">
                {{ dateToTransform | bbDate : 'longDate' : 'GMT+0100' }}
              </td>
            </tr>
            <tr>
              <td>Full:</td>
              <td class="bb-text-align-end">
                {{ dateToTransform | bbDate : 'fullDate' : 'GMT+0100' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<h2>Times</h2>
<div class="card shadow-sm">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table table-hover" bbTable>
        <thead>
          <tr>
            <th>Pipe usage</th>
            <th class="bb-text-align-end">Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Short:</td>
            <td class="bb-text-align-end">
              {{ dateToTransform | bbDate : 'shortTime' : 'GMT+0100' }}
            </td>
          </tr>
          <tr>
            <td>Medium:</td>
            <td class="bb-text-align-end">
              {{ dateToTransform | bbDate : 'mediumTime' : 'GMT+0100' }}
            </td>
          </tr>
          <tr>
            <td>Long:</td>
            <td class="bb-text-align-end">
              {{ dateToTransform | bbDate : 'longTime' : 'GMT+0100' }}
            </td>
          </tr>
          <tr>
            <td>Full:</td>
            <td class="bb-text-align-end">
              {{ dateToTransform | bbDate : 'fullTime' : 'GMT+0100' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
