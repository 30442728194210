import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValueDiffShowcaseComponent } from './value-diff-showcase.component';
import { ValueDiffDirectiveModule } from '@backbase/ui-ang/value-diff';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  declarations: [ValueDiffShowcaseComponent],
  exports: [ValueDiffShowcaseComponent],
  imports: [CommonModule, ValueDiffDirectiveModule, ButtonModule],
})
export class ValueDiffShowcaseModule {}
