import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnDestroy,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { Subscription } from 'rxjs';
import { getDynamicId } from '@backbase/ui-ang/util';
import { InputRadioComponent } from './input-radio.component';

/**
 * @name InputRadioGroupComponent
 *
 * @description
 * Component that represents a group of radio buttons.
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 *
 */
@Component({
  selector: 'bb-input-radio-group-ui',
  templateUrl: './input-radio-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioGroupComponent),
      multi: true,
    },
  ],
})
export class InputRadioGroupComponent extends InputBaseComponent implements AfterContentInit, OnDestroy {
  /**
   * Set horizontal placement of the radio elements.
   */
  @Input() horizontal = false;
  /**
   * Set to true to select the first radio button onInit.
   */
  @Input() preselect = true;

  /**
   * The unique name of the radio group. This would the the name used for the descending radio buttons.
   */
  @Input() name: string | undefined = `input_radio_group_${getDynamicId()}`;

  /**
   * Vertical align of the radio buttons
   */
  @Input() verticalAlign: 'middle' | 'top' | 'bottom' = 'middle';

  /**
   * Group label template context.  In case with providing both (labelTemplate and label), labelTemplate will be showed.
   */
  @Input() labelTemplateContext: any | undefined;
  /**
   * Custom group label template. In case with providing both (labelTemplate and label), labelTemplate will be showed.
   */
  @Input() labelTemplate: TemplateRef<any> | undefined;
  /**
   * Display the radio group labels as a block element.
   * @deprecated in ui-ang@12. To be removed in ui-ang@14. No replacements. Introduced as a quick fix to layout issues.
   */
  @Input() isBlock = false;

  /**
   * The child radio components of type InputRadioComponent.
   */
  @ContentChildren(InputRadioComponent) radios: QueryList<InputRadioComponent> | undefined;

  private isComponentDestroyed = false;
  inputRadio: UntypedFormControl = new UntypedFormControl('');
  inputRadio$$: Subscription;
  focusedValue: string | undefined;

  constructor(protected readonly cd: ChangeDetectorRef) {
    super(cd);
    this.inputRadio$$ = this.inputRadio.valueChanges.subscribe((val: any) => this.onChange(val));
  }

  ngAfterContentInit() {
    if (!this.preselect) {
      return;
    }
    setTimeout(() => {
      if (!this.value && this.radios && this.radios.first) {
        this.value = this.radios.first.value || null;
        this.inputRadio.setValue(this.value);
      }
    });

    if (this.autofocus) {
      // Fix NG0100: ExpressionChangedAfterItHasBeenCheckedError which focus due to
      // focus being updated after initialisation in ng-bootstrap: NgbButtonLabel_HostBindings
      this.cd.detectChanges();
    }
  }

  focusValue(radio: InputRadioComponent) {
    if (!radio.disabled && !this.disabled) {
      this.focusedValue = radio.value;
    }
  }

  setValue(radio: InputRadioComponent) {
    if (!radio.disabled && !this.disabled) {
      this.inputRadio.setValue(radio.value);
      this.value = radio.value ?? '';
    }
  }

  writeValue(value: any) {
    this.inputRadio.setValue(value);
    super.writeValue(value);

    if (!this.isComponentDestroyed) {
      this.cd.detectChanges();
    }
  }

  trackByFn(_: number, item: InputRadioComponent) {
    return item.value;
  }

  focusOut() {
    this.focusedValue = undefined;
  }

  ngOnDestroy(): void {
    this.isComponentDestroyed = true;
    this.inputRadio$$.unsubscribe();
  }
}
