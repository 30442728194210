import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@backbase/ui-ang/icon';
import { LocaleSelectorModule, LOCALE_LIST, LocaleCatalog } from '@backbase/ui-ang/locale-selector';
import { LocaleSelectorShowcaseComponent } from './locale-selector.showcase.component';

const localeCatalog: LocaleCatalog = {
  ['en-US']: { language: 'English (US)', icon: '../assets/images/flags/1x1/us.svg' },
  ['en-GB']: { language: 'English', icon: '../assets/images/flags/1x1/gb.svg' },
  ['de-DE']: { language: 'Deutsch', icon: '../assets/images/flags/1x1/de.svg' },
  ['es-ES']: { language: 'Español', icon: '../assets/images/flags/1x1/es.svg' },
};

@NgModule({
  declarations: [LocaleSelectorShowcaseComponent],
  imports: [CommonModule, LocaleSelectorModule, IconModule],
  exports: [LocaleSelectorShowcaseComponent],
  providers: [
    {
      provide: LOCALE_LIST,
      useValue: localeCatalog,
    },
  ],
})
export class LocaleSelectorShowcaseModule {}
