import { Component, Inject } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import {
  AccountNumberType,
  ACCOUNTS_DISPLAYING_FORMAT,
  AccountsDisplayingFormat,
  AccountsDisplayingFormatConfiguration,
} from '@backbase/ui-ang/account-number-pipe';

@Component({
  selector: 'bb-account-number-pipe-ui-showcase',
  templateUrl: './account-number-pipe-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class AccountNumberPipeShowcaseComponent {
  private readonly customConfiguration: AccountsDisplayingFormat = { segments: [1, 2, 3, 1, 2, 3, 1, 2, 3] };

  accounts: {
    description: string;
    typeOrFormat?: AccountNumberType | AccountsDisplayingFormat;
    number: string;
    cardProviderName?: string;
  }[] = [
    {
      description: 'iban',
      typeOrFormat: 'iban',
      number: 'IBAN1234567891234567',
    },
    {
      description: 'bban',
      typeOrFormat: 'bban',
      number: 'BBAN1234567891234567',
    },
    {
      description: 'cardNumber',
      typeOrFormat: 'cardNumber',
      number: 'num1234567891234567',
    },
    {
      description: 'cardNumber (visa provider)',
      typeOrFormat: 'cardNumber',
      number: 'num1234567891234567',
      cardProviderName: 'Visa',
    },
    {
      description: `Custom format [${this.customConfiguration.segments}]`,
      typeOrFormat: this.customConfiguration,
      number: 'num1234567891234567',
    },
  ];

  constructor(@Inject(ACCOUNTS_DISPLAYING_FORMAT) readonly configuration: AccountsDisplayingFormatConfiguration) {}
}
