import { NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '@backbase/ui-ang/icon';
import { CustomStepperStepDirective, StepperComponent } from './stepper.component';
import { StepperStepComponent } from './stepper-step/stepper-step.component';

@NgModule({
  imports: [NgTemplateOutlet, IconModule],
  declarations: [StepperComponent, StepperStepComponent, CustomStepperStepDirective],
  exports: [StepperComponent, StepperStepComponent, CustomStepperStepDirective],
})
export class StepperModule {}
