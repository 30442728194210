<div class="bb-block bb-block--lg">
  <h4>Not Found Error - inside a card + state container</h4>
  <div class="card">
    <div class="card-body">
      <div class="bb-state-container">
        <bb-common-error-state-ui [error]="getErrorFromString('notFoundError')"></bb-common-error-state-ui>
      </div>
    </div>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>Bad Request Error - inside a card + state container</h4>
  <div class="card">
    <div class="card-body">
      <div class="bb-state-container">
        <bb-common-error-state-ui [error]="getErrorFromString('badRequestError')"></bb-common-error-state-ui>
      </div>
    </div>
  </div>
</div>

<div class="mb-3">
  <h4>Connectivity Error - inside a card + state container</h4>
  <div class="card">
    <div class="card-body">
      <div class="bb-state-container">
        <bb-common-error-state-ui [error]="getErrorFromString('connectivityError')"></bb-common-error-state-ui>
      </div>
    </div>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>Internal Server Error - inside a card + state container</h4>
  <div class="card">
    <div class="card-body">
      <div class="bb-state-container">
        <bb-common-error-state-ui [error]="getErrorFromString('internalServerError')"></bb-common-error-state-ui>
      </div>
    </div>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>Text Error - inside a card + state container</h4>
  <div class="card">
    <div class="card-body">
      <div class="bb-state-container">
        <bb-common-error-state-ui [error]="getErrorFromString('textError')"></bb-common-error-state-ui>
      </div>
    </div>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>Custom i18n Template - inside a card + state container</h4>
  <div class="card">
    <div class="card-body">
      <div class="bb-state-container">
        <bb-common-error-state-ui [error]="i18nCustomError"></bb-common-error-state-ui>
      </div>
    </div>
  </div>
</div>

<ng-template #i18nCustomError>
  <p>Custom error with i18n support</p>
</ng-template>
