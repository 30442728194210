import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isObservable, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { getDynamicId } from '@backbase/ui-ang/util';

export interface FileSize {
  value: number;
  unit: string;
}

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

/**
 * @name FileAttachmentComponent
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes:
 *  - id
 *  - aria-label
 *  - aria-describedby
 *
 * @description
 * Component displays file attachment.
 *
 * @dynamic (to suppress error with resolving statics declarations during compilation)
 */

@Component({
  selector: 'bb-file-attachment-ui',
  templateUrl: './file-attachment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileAttachmentComponent implements OnInit {
  /**
   * Set file name (required).
   */
  @Input()
  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    if (value?.trim()) {
      this._name = value;
      this.fileType = this.getFileType(value);
    } else {
      throw new Error(`"name" input is invalid in "${this.constructor.name}"`);
    }
  }

  /**
   * Set file size (required). Accepts `number` or `string`.
   */
  @Input()
  set size(value: number | string | undefined) {
    if (value !== undefined) {
      const valueAsNumber = Math.trunc(Number(value));

      this._sizeInUnits = this.formatBytes(valueAsNumber, 2);
    }
  }

  /**
   * Get file size in information units (Bytes, Kbs, etc.).
   */
  get sizeInUnits() {
    return this._sizeInUnits;
  }

  /**
   * Set file content (required if disabled property is not set to true).
   */
  @Input()
  get fileContent(): Observable<ArrayBuffer> | undefined {
    return this._fileContent;
  }
  set fileContent(value: Observable<ArrayBuffer> | undefined) {
    if (value && isObservable(value)) {
      this._fileContent = value;
    } else {
      throw new Error(`"fileContent" stream input is invalid in "${this.constructor.name}"`);
    }
  }

  /**
   * Loading indicator flag. Defaults to false.
   */
  @Input() loading = false;

  /**
   * Show delete button flag. Defaults to false.
   */
  @Input() deletable = false;

  /**
   * Disabled state flag. Defaults to false.
   */
  @Input() disabled = false;

  /**
   * The flag to indicate whether the file-attachment should fill the container that it is in. Defaults to 'false'.
   */
  @Input() block = false;

  /**
   * Set aria-describedby  with an element id that contains a detailed decription of the widget.
   */
  @Input('aria-describedby') ariaDescribedby?: string = 'file-attachment-card-body';

  /**
   * The id for attachment card.
   */
  @Input('id') cardId?: string = 'file-attachment-card-body_' + getDynamicId();

  /**
   * Accessible label for delete button when control does not need to render label tag.
   */
  @Input('aria-label') ariaLabel?: string = 'Delete file';

  /**
   * Event emitted on delete button click.
   */
  @Output() delete = new EventEmitter<void>();

  /**
   * File type which is determined by file extension. Defaults to 'unknown'.
   */
  fileType = 'unknown';

  private _sizeInUnits: FileSize | undefined;
  private _name: string | undefined;
  private _fileContent: Observable<ArrayBuffer> | undefined;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit() {
    if (!this._name) {
      throw new Error(`"name" input is required in "${this.constructor.name}"`);
    }

    if (!this.disabled && !this._fileContent) {
      throw new Error(`"fileContent" input is required in "${this.constructor.name}"`);
    }
  }

  /**
   * Download attachment method.
   *
   * @param $event Download button click event
   */
  onDownload($event: Event) {
    $event.stopPropagation();

    if (this.fileContent) {
      this.fileContent.pipe(take(1)).subscribe({
        next: (response: ArrayBuffer) => {
          if (!this.document.defaultView) {
            return;
          }
          const window = this.document.defaultView;
          const blob = new window.Blob([response]);

          if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, this.name);
          } else {
            const fileURL = window.URL.createObjectURL(blob);
            const link = this.renderer.createElement('a');

            link.setAttribute('href', fileURL);
            link.setAttribute('target', '_blank');
            link.setAttribute('download', this.name || '');
            link.click();
            URL.revokeObjectURL(fileURL);
          }
        },
      });
    }
  }

  /**
   * Delete attachment event emitter.
   *
   * @param event Delete button click event.
   */
  onDelete(event: MouseEvent) {
    event.stopPropagation();
    this.delete.emit();
  }

  private getFileType(fileName: string): string {
    return fileName.includes('.') ? fileName.toLowerCase().split('.').pop() || 'unknown' : 'unknown';
  }

  private formatBytes(bytes: number, decimals: number): FileSize {
    if (bytes === 0) {
      return {
        value: 0,
        unit: 'Bytes',
      };
    }

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return {
      value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
      unit: sizes[i],
    };
  }
}
