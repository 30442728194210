import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bb-period-selector-page-ui',
  template: '',
  styles: [],
})
export class BbPeriodSelectorPageUiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
