import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-textarea-ui-showcase',
  templateUrl: './textarea-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class TextareaShowcaseComponent {
  vForm: UntypedFormGroup | undefined;
  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      textarea: ['', Validators.minLength(3)],
    });
  }
}
