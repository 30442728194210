<bb-input-checkbox-ui
  [label]="label"
  [aria-label]="(!label && ariaLabel) || null"
  [(indeterminate)]="indeterminate"
  [(ngModel)]="value"
  [disabled]="isDisabled()"
  (change)="onValueChange()"
>
  @if (!label) {
    <ng-content></ng-content>
  }
</bb-input-checkbox-ui>
