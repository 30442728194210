<table class="table">
  <tr>
    <th>Balance</th>
    <td
      bbHighlight
      [amount]="balance"
      positiveClass="bg-primary"
      negativeClass="bg-danger"
    >
      <bb-amount-ui [amount]="balance"></bb-amount-ui>
    </td>
  </tr>
  <tr>
    <th>Credit limit</th>
    <td
      bbHighlight
      [amount]="creditLimit"
      positiveClass="bg-primary"
      negativeClass="bg-danger"
    >
      <bb-amount-ui [amount]="creditLimit"></bb-amount-ui>
    </td>
  </tr>
</table>
