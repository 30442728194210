/*eslint no-trailing-spaces: ["error", { "ignoreComments": true }]*/
/*eslint max-len: off*/

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { Placement, PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { from, Observable, of, toArray } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';
import {
  HeadingNavigationItem,
  HeadingNavigationItemWithPermission,
  PermissionsStrategy,
  PERMISSIONS_STRATEGY,
} from './models/heading.models';

/**
 * @name HeadingComponent
 *
 * @description
 * Component that displays a header with navigation
 *
 * ### Displaying navigation options based on entitlements
 * Heading component could render the navigation items based on entitlements. To enable this feature PERMISSIONS_STRATEGY token from `@backbase/ui-ang/heading` should be privided. F.e.
 * ```
 * import { PermissionsStrategy } from '@backbase/foundation-ang/web-sdk';
 *
 * //...
   providers: [
     {
       provide: PERMISSIONS_STRATEGY,
       useClass: PermissionsStrategy
     }
   ]
   //....
   ```
 *
 * @deprecated Component is deprecated as of ui-ang v11. It will be removed in ui-ang v13. Please use PageHeaderComponent to replace your primary header and native HTML h2-h5 to replace smaller headers.
 */
@Component({
  selector: 'bb-heading-ui',
  templateUrl: './heading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent implements OnInit {
  /**
   * Custom class name
   */
  @Input() customClassName = '';

  /**
   * Heading Classes
   */
  @Input() headingClasses = '';

  /**
   * Heading type (h1-h6)
   */
  @Input() headingType = '';

  /**
   * Heading
   */
  @Input() heading = '';

  /**
   * Determines whether or not to show the navigation section.
   */
  @Input() showNavigation = true;

  /**
   * An array of objects representing the navigation menu items.
   */
  @Input() navigationItems: HeadingNavigationItem<any>[] = [];

  /**
   * Determines whether or not to use the first link as the default target.
   */
  @Input() useFirstLinkAsDefault = false;

  /**
   * The position of the dropdown, position will be picked in order of feasibility
   */
  @Input() position: Placement | PlacementArray = ['bottom-end', 'bottom-start', 'top-end', 'top-start'];

  /**
   * Button label. Will be used if useFirstLinkAsDefault = false;
   */
  @Input() buttonLabel!: string;

  /**
   * Button icon. Will be used if useFirstLinkAsDefault = false;
   */
  @Input() buttonIcon!: string;

  /**
   * Color of the button. Default `primary`
   */
  @Input() buttonColor = 'primary';

  /**
   * Size of the button. Allowed values are `sm` or `md`. Default `md`
   */
  @Input() buttonSize: 'sm' | 'md' = 'md';

  /**
   * Event on item click
   */
  @Output() navigationAction = new EventEmitter<any>();

  menuItems!: Observable<HeadingNavigationItem<any>[]>;

  private hasPermissions(item: HeadingNavigationItem<any>): Observable<HeadingNavigationItemWithPermission<any>> {
    const hasPermissionsPromise = item.canView ? this.permissionsStrategy.resolveEntitlements(item.canView) : of(true);

    return from(hasPermissionsPromise).pipe(map((hasPermissions) => ({ hasPermissions, ...item })));
  }

  private menuSetup(): Observable<HeadingNavigationItem<any>[]> {
    return from(this.navigationItems).pipe(
      concatMap(this.hasPermissions.bind(this)),
      filter((item) => item.hasPermissions),
      map(({ hasPermissions, ...item }) => item as HeadingNavigationItem<any>),
      toArray(),
    );
  }

  constructor(@Optional() @Inject(PERMISSIONS_STRATEGY) private readonly permissionsStrategy: PermissionsStrategy) {}

  ngOnInit(): void {
    this.menuItems = this.menuSetup();
  }

  onClick(value: any) {
    this.navigationAction.emit(value);
  }
}
