import { NgModule } from '@angular/core';
import { FocusDirective } from './focus.directive';
import { FocusService } from './focus.service';

@NgModule({
  declarations: [FocusDirective],
  providers: [FocusService],
  exports: [FocusDirective],
})
export class FocusModule {}
