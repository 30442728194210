import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  booleanAttribute,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';
import { EmptyStatePresetType } from './type';

/**
 * @name EmptyStateComponent
 *
 * @description
 * Component that displays an empty state.
 */
@Component({
  selector: 'bb-empty-state-ui',
  templateUrl: './empty-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent implements OnInit {
  /**
   * The image to be displayed for empty state.
   * Required. Will throw an error if unset.
   */
  @Input()
  image?: string;
  /**
   * The title of the empty state.
   * Required. Will throw an error if unset.
   */
  @Input() title?: string;
  /**
   * The sub-title of the empty state. Defaults to an empty string.
   */
  @Input() subtitle?: string;
  /**
   * Use the primaryBtnTemplate to add any extra elements to the secondary button, such as icons or dropdowns.
   * When using this template, do not use primaryActionText instead, include it within the template itself.
   */
  @Input() primaryBtnTemplate: TemplateRef<any> | undefined;
  /**
   * Use the secondaryBtnTemplate to add any extra elements to the secondary button, such as icons or dropdowns.
   * When using this template, do not use secondaryActionText instead, include it within the template itself.
   */
  @Input() secondaryBtnTemplate: TemplateRef<any> | undefined;
  @Input()
  preset?: EmptyStatePresetType;

  @Input({ transform: booleanAttribute })
  showPrimaryAction = false;

  @Input({ transform: booleanAttribute })
  showSecondaryAction = false;

  @Input()
  primaryActionText?: string;

  @Input()
  secondaryActionText?: string;

  @Output()
  primaryActionClick = new EventEmitter<void>();

  @Output()
  secondaryActionClick = new EventEmitter<void>();

  ngOnInit() {
    if (typeof this.image === 'undefined' && typeof this.preset === 'undefined') {
      throw new Error(`"image" or "preset" inputs are required in "${this.constructor.name}"`);
    }

    if (typeof this.title === 'undefined' && typeof this.preset === 'undefined') {
      throw new Error(`"title" input is required in "${this.constructor.name}" in case if "preset" is not specified`);
    }
  }
}
