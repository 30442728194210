import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'bb-input-checkbox-example-ui',
  templateUrl: './input-checkbox.example.component.html',
})
export class InputCheckboxExampleComponent {
  vForm: UntypedFormGroup | undefined = this.formBuilder.group({
    termsConditions: { value: false },
  });

  constructor(private readonly formBuilder: UntypedFormBuilder) {}
}
