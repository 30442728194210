<div class="paragraph">
<p>A SearchBox is an input field for searching through content, allowing users to locate something specific within a website or native app.</p>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Anatomy of a search box</strong></p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Label</strong><br>
These should indicate what sort of information the field requires and should be short and concise. Put labels directly above their input and align them to the left.</p>
</li>
<li>
<p><strong>Placeholder text</strong><br>
This lets people know how they should use the field (e.g. 'Start typing to see names'). Only supply where clarification is required, try not to overuse it.</p>
</li>
<li>
<p><strong>Input area</strong><br>
This is where people enter text.</p>
</li>
<li>
<p><strong>Primary Action</strong><br>
The primary action of a search box should relate to the label. These usually appear as buttons.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use placeholder text in the SearchBox to describe what users can search for.</p>
</li>
<li>
<p>Provide autocomplete suggestions to help the user search quickly. These suggestions can be from past searches or auto-completions of the user&#8217;s query text.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Leave the SearchBox blank because it&#8217;s too ambiguous.</p>
</li>
<li>
<p>Provide too many autocomplete suggestions as that will overwhelm the user.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility*</h2>
<div class="sectionbody">
<div class="paragraph">
<p><em>Standards a component or pattern needs to meet in order to be perceivable, operable, and understandable to users.</em></p>
</div>
</div>
</div>