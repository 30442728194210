import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputFileModule } from '@backbase/ui-ang/input-file';
import { InputFileShowcaseComponent } from './input-file-showcase.component';

@NgModule({
  imports: [InputFileModule, ReactiveFormsModule],
  declarations: [InputFileShowcaseComponent],
})
export class InputFileShowCaseModule {}
