<div class="row bb-stack">
  <div class="col-md-6">
    <div class="bb-block--md">
      <bb-input-with-custom-error-showcase></bb-input-with-custom-error-showcase>
    </div>
  </div>
</div>
<hr />
<h2>Form</h2>
<form [formGroup]="form" bbFormSubmit (ngSubmit)="onSubmit()">
  <div class="row bb-stack bb-block--md">
    <div class="col-md-6">
      <div class="bb-block--md">
        <bb-input-text-ui
          bbFormControl
          [errorLabels]="errorLabels1"
          label="Input with custom error labels"
          formControlName="inputField"
          placeholder="Please enter a value, at most 35 chars..."
        ></bb-input-text-ui>
      </div>

      <div class="bb-block--md">
        <bb-input-text-ui
          bbFormControl
          [showCharCounter]="true"
          [maxLength]="10"
          label="Input with custom error component using errorComponent Input"
          formControlName="inputField2"
          placeholder="Please enter a value, at most 35 chars..."
        ></bb-input-text-ui>
      </div>

      <div class="bb-block--md">
        <label for="currency-input" class="form-label">Currency label</label>
        <bb-currency-input-ui
          [currencies]="currencies"
          [required]="true"
          id="currency-input"
          bbFormControl
          formControlName="currencyField"
        ></bb-currency-input-ui>
      </div>

      <div class="bb-block--md">
        <bb-dropdown-single-select-ui
          bbFormControl
          [options]="objectDropdownOptions"
          label="Dropdown"
          displayAttributePath="title"
          [id]="'dropdownSingleSelectObject'"
          formControlName="dropdownField"
        ></bb-dropdown-single-select-ui>
      </div>

      <div class="bb-block--md">
        <label for="amountInput" class="form-label">Amount</label>
        <bb-amount-input-ui
          bbFormControl
          [errorLabels]="errorLabels2"
          formControlName="amountField"
          [id]="'amountInput'"
          placeholder="0.00"
          [autoDecimal]="true"
          [mapCurrency]="false"
          [currency]="'USD'"
        ></bb-amount-input-ui>
      </div>

      <div class="bb-block--md">
        <bb-rich-text-editor-ui
          bbFormControl
          [errorLabels]="errorLabels2"
          [maxLength]="300"
          [required]="true"
          formControlName="textareaField"
          placeholder="Please, enter your message"
          label="Textarea with custom errors labels"
        ></bb-rich-text-editor-ui>
      </div>
    </div>
  </div>

  <button type="submit" bbButton>Submit</button>
</form>
