<bb-collapsible-accordion-ui [closeOthers]="true" [openFirst]="true">
  <bb-collapsible-card-ui>
    <div bbCollapsibleTitle>Panel #1</div>
    <div bbCollapsibleBody>
      The first panel is expanded automatically.
    </div>
  </bb-collapsible-card-ui>
  <bb-collapsible-card-ui>
    <div bbCollapsibleTitle>Panel #2</div>
    <div bbCollapsibleBody>This is the second panel with content.</div>
  </bb-collapsible-card-ui>
  <bb-collapsible-card-ui>
    <div bbCollapsibleTitle>Panel #3</div>
    <div bbCollapsibleBody>Last but not least, the third panel.</div>
  </bb-collapsible-card-ui>
</bb-collapsible-accordion-ui>
