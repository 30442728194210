import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPhoneShowcaseComponent } from './input-phone-showcase.component';
import { InputPhoneModule } from '@backbase/ui-ang/input-phone';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { PhoneNumberModule } from '@backbase/ui-ang/phone-number-format-pipe';

@NgModule({
  imports: [
    CommonModule,
    InputPhoneModule,
    InputTextModule,
    InputValidationMessageModule,
    ReactiveFormsModule,
    PhoneNumberModule,
  ],
  declarations: [InputPhoneShowcaseComponent],
})
export class InputPhoneShowcaseModule {}
