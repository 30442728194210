import { environment } from '../environments/environment';

declare global {
  interface Window {
    gtag: any;
    dataLayer: Array<any>;
  }
}
window.dataLayer = window.dataLayer || [];
window.gtag = function (property: string, gaId: string, config: any) {
  window.dataLayer.push(arguments);
};

export function googleAnalyticsHeadScripts() {
  const head = document.getElementsByTagName('head')[0];

  const googleAnalyticsFirstScript = document.createElement('script');
  googleAnalyticsFirstScript.async = true;
  googleAnalyticsFirstScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.google_analytics_code;

  const googleAnalyticsSecondScript = document.createElement('script');
  googleAnalyticsSecondScript.innerHTML = `
    gtag(\'js\', new Date());
    gtag(\'config\', \'${environment.google_analytics_code}\');
  `;

  head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
  head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
}

export function googleAnalytics(url: any) {
  window.gtag('config', environment.google_analytics_code, { page_path: url });
}
