import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MenuTree, MenuTreeSubSection } from 'apps/design-system-app/src/assets/menu-tree.interface';
import { SideMenuConfig } from '../side-menu/side-menu-config';

const PARENT_SECTION_CONFIG_MAP = new Map(SideMenuConfig.map((item) => [item.parent, item]));
@Component({
  selector: 'bb-sidebar-search',
  templateUrl: './search-bar.component.html',
})
export class SearchBarComponent implements OnInit {
  @Input() menuTree!: MenuTree[];
  searchableNavigation: string[] = [];
  readonly typeaheadOptions = {
    ngbTypeahead: this.search.bind(this),
    selectItem: this.navigateToQuery.bind(this),
    inputFormatter: (input: string) => {
      if (input === this.FALLBACK_MSG) {
        return;
      }

      return input
        .split(this.SEPARATOR)
        .filter(Boolean)
        .map((item) => item.replace('-', ' '))
        .pop();
    },
  };

  private SEPARATOR = ' › ';
  private FALLBACK_MSG = 'No results found';

  constructor(private router: Router) {}

  ngOnInit() {
    const flattenMenuTree = (menuTree: MenuTree[]) => {
      const getSearchableItem = (parent: string, item: string) => (parent ? `${parent}${this.SEPARATOR}${item}` : item);
      const flattenSection = (treeItem: MenuTreeSubSection, parent = ''): string[] => {
        if (typeof treeItem === 'string') {
          return [getSearchableItem(parent, treeItem)];
        } else if (treeItem.children?.length < 1) {
          return [getSearchableItem(parent, treeItem.name)];
        } else {
          return [
            getSearchableItem(parent, treeItem.name),
            ...treeItem.children.reduce(
              (result: any[], item) => [...result, ...flattenSection(item, getSearchableItem(parent, treeItem.name))],
              [],
            ),
          ];
        }
      };

      return (menuTree ?? []).reduce((result: string[], section) => [...result, ...flattenSection(section)], []);
    };

    this.searchableNavigation = flattenMenuTree(this.menuTree);
  }

  search(text: Observable<string>): Observable<string[]> {
    return text.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => {
        const found = this.searchableNavigation.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1);

        return found.length > 0 ? found : [this.FALLBACK_MSG];
      }),
    );
  }

  navigateToQuery(query: any) {
    const queryValue = typeof query === 'string' ? query : query.item;

    if (queryValue === this.FALLBACK_MSG) {
      return;
    }

    const sections = queryValue.split(this.SEPARATOR);
    const fullItemKey = queryValue.replaceAll(this.SEPARATOR, '-');

    if (PARENT_SECTION_CONFIG_MAP.has(fullItemKey)) {
      const childData = PARENT_SECTION_CONFIG_MAP.get(fullItemKey);
      sections.push(childData?.defaultChild);
    }

    this.router.navigate(
      sections.filter((section?: string) => section).map((section: string) => section.replace(/\s/g, '-')),
    );
  }
}
