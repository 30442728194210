<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/design-guideline-placeholder.png" alt="Design Guidelines coming soon">
</div>
</div>
<div class="sect1">
<h2 id="_these_guidelines_are_in_progress">These guidelines are in progress</h2>
<div class="sectionbody">
<div class="paragraph">
<p>We’re pushing the last few pixels in place to get this document ready for you. Please hold tight as we find the right words.</p>
</div>
<div class="paragraph">
<p>In the meantime, you can see the live example and use the component in your project!</p>
</div>
</div>
</div>