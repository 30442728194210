<h2 id="ariaLabelledById">Account Selector</h2>
<div id="ariaDescribedById">
  Account selector Component that supports single select, multi select
  and auto complete
</div>
<bb-account-selector-ui
  [items]="items"
  [disableScrollEnd]="false"
  [multiple]="true"
  [closeOnSelect]="true"
  [filterItems]="true"
  [selectedItems]="selectedItems"
  [dropdownPosition]="'bottom'"
  (change)="onChange($event)"
  (clearSearch)="onClearSearch()"
  (scrollToEnd)="onScrollToEnd()"
  (search)="onSearch($event)"
  placeholder="Select Accounts"
  [aria-describedby]="'ariaDescribedById'"
>
</bb-account-selector-ui>
