<bb-stepper-ui>
  <bb-stepper-step-ui
    [isActive]="true"
    [isChecked]="true"
    ariaLabel="Step 1 out of 3"
    label="Step 1"
  ></bb-stepper-step-ui>
  <bb-stepper-step-ui
    label="Step 2"
    [isActive]="true"
    [isChecked]="false"
    [isCurrent]="true"
    ariaLabel="Step 2 out of 3"
  >
    <bb-stepper-step-ui
      label="Step 2.1"
      [isActive]="true"
      [isChecked]="false"
      [isCurrent]="true"
      ariaLabel="Sub step 1 out of 1"
    >
    </bb-stepper-step-ui>
  </bb-stepper-step-ui>
  <bb-stepper-step-ui
    ariaLabel="Step 3 out of 3"
    label="Step 3"
  ></bb-stepper-step-ui>
</bb-stepper-ui>
