import { Component } from '@angular/core';
import { ConfigurationInputService } from '../../configuration/configuration-input.service';

const MILLISECONDS_PER_SECOND = 1000;

@Component({
  selector: 'bb-alert-example-ui',
  templateUrl: './alert.example.component.html',
})
export class AlertExampleComponent {
  restoreDelay = 5;
  showAlert = true;

  alertCheckoxList = [
    {
      label: 'Dismissible',
      value: true,
      formControlName: 'dismissible',
    },
  ];

  alertDropdownList = [
    {
      label: 'Modifier',
      formControlName: 'modifier',
      dropdownItemList: ['info', 'warning', 'success', 'error'],
    },
  ];

  alertInputList = [
    {
      label: 'Primary action button text',
      formControlName: 'primaryActionButtonText',
    },
    {
      label: 'Supportive link text',
      formControlName: 'supportiveLinkText',
    },
  ];

  alertValues = {
    checkbox: {
      dismissible: true,
    },
    dropdown: {
      modifier: 'primary',
    },
    input: {
      primaryActionButtonText: '',
      supportiveLinkText: '',
    },
  };
  constructor(
    private readonly configurationService: ConfigurationInputService,
  ) {
    this.configurationService.sharedField.subscribe({
      next: (val: any) => {
        this.alertValues.checkbox.dismissible =
          val.checkbox.dismissible;
        this.alertValues.dropdown.modifier = val.dropdown.modifier;
        this.alertValues.input.primaryActionButtonText =
          val.input.primaryActionButtonText;
        this.alertValues.input.supportiveLinkText =
          val.input.supportiveLinkText;
      },
    });
  }

  hideAlert() {
    this.showAlert = false;

    // Restore alert after 5 seconds for demo purposes
    setTimeout(
      () => this.displayAlert(),
      this.restoreDelay * MILLISECONDS_PER_SECOND,
    );
  }

  displayAlert() {
    this.showAlert = true;
  }
}
