import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputPasswordComponent } from './input-password.component';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { FocusModule } from '@backbase/ui-ang/focus';
import { IconModule } from '@backbase/ui-ang/icon';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [NgClass, FormsModule, BaseClassesModule, FocusModule, IconModule, ButtonModule],
  declarations: [InputPasswordComponent],
  exports: [InputPasswordComponent],
})
export class InputPasswordModule {}
