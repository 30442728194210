import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperShowcaseComponent } from './stepper-showcase.component';
import { StepperModule } from '@backbase/ui-ang/stepper';

@NgModule({
  imports: [CommonModule, StepperModule],
  declarations: [StepperShowcaseComponent],
})
export class StepperShowcaseModule {}
