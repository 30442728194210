@if (+itemsPerPage < +totalItems) {
  <ngb-pagination
    (pageChange)="onPageChange($event)"
    [boundaryLinks]="boundaryLinks"
    [collectionSize]="totalItems"
    [directionLinks]="directionLinks"
    [maxSize]="maxNavPages"
    [pageSize]="itemsPerPage"
    [page]="page + 1"
    [rotate]="true"
    class="d-flex justify-content-center bb-pagination"
  >
    <ng-template ngbPaginationNumber let-page>
      <span aria-hidden="true">{{ page }}</span>
      <span class="visually-hidden" i18n="Link Label for Screen Reader@@bb-pagination-ui.pagination.link.number"
        >page {{ page }}</span
      >
    </ng-template>
    <ng-template ngbPaginationFirst>
      <bb-icon-ui data-role="pagination-icon-first" size="md" [name]="paginationIcons.first"></bb-icon-ui>
    </ng-template>
    <ng-template ngbPaginationPrevious>
      <bb-icon-ui data-role="pagination-icon-previous" size="md" [name]="paginationIcons.previous"></bb-icon-ui>
    </ng-template>
    <ng-template ngbPaginationNext>
      <bb-icon-ui data-role="pagination-icon-next" size="md" [name]="paginationIcons.next"></bb-icon-ui>
    </ng-template>
    <ng-template ngbPaginationLast>
      <bb-icon-ui data-role="pagination-icon-last" size="md" [name]="paginationIcons.last"></bb-icon-ui>
    </ng-template>
    <ng-template ngbPaginationEllipsis>
      <span class="bb-pagination__ellipsis" data-role="pagination-ellipsis">&hellip;</span>
    </ng-template>
  </ngb-pagination>
}
