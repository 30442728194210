import { Injectable } from '@angular/core';

/**
 * A configuration service for the [NotificationComponent] component.
 *
 * You can inject this service, typically in your root component, and customize its properties
 * to provide default values for all alerts used in the application.
 */
@Injectable({ providedIn: 'root' })
export class BBNotificationConfig {
  animation = true;
}
