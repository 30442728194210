<div class="paragraph">
<p>Modals are used when a decision is required from a user to either, perform a particular task or, continue using the system.</p>
</div>
<div class="paragraph">
<p>Modals block the interface and prevent users from using the system until an explicit action has been performed, an example of this would be a warning modal, to prevent users from accidentally performing a destructive action without proper understanding of the result.</p>
</div>
<div class="sect1">
<h2 id="_anatomy_of_a_modal">Anatomy of a Modal</h2>
<div class="sectionbody">
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/modal-anatomy.png" alt="image">
</div>
</div>
<div class="paragraph">
<p>Modals consist of three areas, where all elements are placed: <strong>Header</strong>, <strong>Body</strong> and <strong>Footer</strong>.</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Title</strong><br>
A heading that communicates, in short and clear wording, the most important information in the modal.</p>
</li>
<li>
<p><strong>Message</strong> (optional)<br>
Content that provides more details on the task to perform and the available options.</p>
</li>
<li>
<p><strong>Icon buttons</strong> (optional)<br>
Additional controls are placed on the header of the modal. If the modal can be dismissed, a close icon button <strong>X</strong> is placed in the top right of the modal container.<br>
If in addition to that, a modal has more actions available, an <strong>⋯</strong> icon button is placed in the top right corner of the modal container either on its own or before an <strong>X</strong> icon button.</p>
</li>
<li>
<p><strong>Actions</strong><br>
Main available actions are placed at the bottom, in the modal footer. Refer to <a routerLink="/components/button/design">button guidelines</a> for usage.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_modal_sizes">Modal sizes</h3>
<div class="paragraph">
<p>Modals are available in three width sizes. <strong>Small</strong> (300px), <strong>Default</strong> (600px) and <strong>Large</strong> (900px). By default you should use normal size on desktop and small on small viewports.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/modal-small.png" alt="Small modal (300px)">
</div>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/modal-normal.png" alt="Default modal (600px)">
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don&#8217;ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When working with modals, follow these recommendations to keep a consistent experience.</p>
</div>
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Keep the title simple, so the user can understand what is being asked from them just by reading the title.</p>
</li>
<li>
<p>Text in message area should be short, providing additional information with minimal wording.</p>
</li>
<li>
<p>Button labels should clearly tell the user what will happen when they click them.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don&#8217;t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Make the title too complicated as users will look to this for instructions.</p>
</li>
<li>
<p>Force the user to read the message area for more information and instructions because the title isn&#8217;t clear.</p>
</li>
<li>
<p>Make the copy in message area too long with lots of unnecessary words.</p>
</li>
<li>
<p>Label the primary button ambiguously which will force the user to question what action to take.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_interaction">Interaction</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Dismissing a modal</strong><br>
If the modal can be dismissed, the <strong>X</strong> icon button will be seen in the modal container. The user can click on it and the modal will disappear. Modals can also be dismissed by pressing the ESC key or clicking outside of the modal area.</p>
</div>
<div class="paragraph">
<p><strong>More actions within a modal</strong><br>
If the modal has more actions for the user to take, the <strong>⋯</strong> button will be seen in the modal container. On click, the user see available actions in a dropdown and choose a prefered option. Note that <em>Small</em> modals can include up to <strong>two</strong> icon buttons, whilst <em>Default</em> and <em>Large</em> can have <strong>up to three</strong>.</p>
</div>
<div class="paragraph">
<p><strong>Modal on modal</strong><br>
In some cases, it might be required to have a modal on top of another modal. This use case could range from approving a payments to displaying more information. Given that modals can be used in different sizes and context, limit this to a maximum of 2 modals. Since this isn&#8217;t a good user experience, try to avoid this scenario by working on the flow.</p>
</div>
<div class="paragraph">
<p><strong>Scrolling</strong><br>
The height of a dialog is determined by the content. Once it reaches a certain threshold, the body content will scroll while the header and footer remain fixed until the bottom of the modal dialog is reached.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="paragraph">
<p>There are no specific accessibility guidelines for this component besides general compliance as specified in our <a routerLink="/accessibility/">accessibility section</a>.</p>
</div>
</div>
</div>