import { NgModule } from '@angular/core';

import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CollapsibleBodyDirective, CollapsibleComponent, CollapsibleHeaderDirective } from './collapsible.component';
import { NgTemplateOutlet } from '@angular/common';

@NgModule({
  imports: [NgbCollapseModule, NgTemplateOutlet],
  declarations: [CollapsibleComponent, CollapsibleHeaderDirective, CollapsibleBodyDirective],
  exports: [CollapsibleComponent, CollapsibleHeaderDirective, CollapsibleBodyDirective],
})
export class CollapsibleModule {}
