<div class="bb-block bb-block--md">
  <h4>Default collapsible card</h4>
  <bb-collapsible-card-ui>
    <span bbCollapsibleTitle>Collapsible card</span>
    <div bbCollapsibleBody>I am expandable content!</div>
  </bb-collapsible-card-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Small collapsible card</h4>
  <bb-collapsible-card-ui size="sm">
    <span bbCollapsibleTitle>Small collapsible card</span>
    <div bbCollapsibleBody>I am expandable content!</div>
  </bb-collapsible-card-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Large collapsible card</h4>
  <bb-collapsible-card-ui size="lg">
    <span bbCollapsibleTitle>Large collapsible card</span>
    <div bbCollapsibleBody>I am expandable content!</div>
  </bb-collapsible-card-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Without chevron</h4>
  <bb-collapsible-card-ui [hasChevron]="false">
    <span bbCollapsibleTitle>Collapsible card</span>
    <div bbCollapsibleBody>I am expandable content!</div>
  </bb-collapsible-card-ui>
</div>

<div class="bb-block bb-block--md">
  <h4>Highlighted collapsible card</h4>
  <bb-collapsible-card-ui [highlight]="true">
    <span bbCollapsibleTitle>Highlighted collapsible card</span>
    <div bbCollapsibleBody>I am expandable content!</div>
  </bb-collapsible-card-ui>
</div>
