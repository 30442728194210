import { Component } from '@angular/core';

@Component({
  selector: 'bb-heading-example-ui',
  templateUrl: './heading-example.component.html',
})
export class HeadingExampleComponent {
  counter = 0;
  navigationHeaderItems = [
    {
      label: 'New transfer',
      value: 'new-transfer-easy',
      canView:
        'Payments.USDomesticWire.create OR Payments.USForeignWire.create',
    },
    {
      label: 'Internal transfer',
      value: 'new-transfer-internal',
      canView: 'Payments.A2ATransfer.create',
    },
  ];

  navigationAction(value: string) {
    this.counter++;
  }
}
