<div class="api-body table-responsive">
  <table bbtablefocus class="table table-hover">
    <thead>
      <tr>
        <th>Method</th>
        <th>Return type</th>
        <th>Description</th>
        <!-- <th>Inheritance</th> -->
      </tr>
    </thead>
    <tbody>
      @for (method of methods; track $index) {
        <tr>
          <td>
            {{ method.name }}(
            @if (method.args?.length > 0) {
              <div>&nbsp;</div>
            }
            @for (arg of method.args; track $index; let last = $last) {
              <span
                >{{ arg.name }}
                @if (!last) {
                  <span>,&nbsp;</span>
                }
              </span>
            }
            @if (method.args?.length > 0) {
              <div>&nbsp;</div>
            }
            )
          </td>
          <td>
            <code>{{ method.returnType }}</code>
          </td>
          <td [innerHTML]="method.description || '-'"></td>
          <!-- <td>{{ method.inheritance?.file || '-' }}</td> -->
        </tr>
      }
    </tbody>
  </table>
</div>
