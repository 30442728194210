import { NgModule } from '@angular/core';
import { IconModule } from '@backbase/ui-ang/icon';
import { FormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';

import { InputEmailComponent } from './input-email.component';
import { FocusModule } from '@backbase/ui-ang/focus';

@NgModule({
  imports: [IconModule, FormsModule, BaseClassesModule, FocusModule],
  declarations: [InputEmailComponent],
  exports: [InputEmailComponent],
})
export class InputEmailModule {}
