import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightShowcaseComponent } from './highlight-showcase.component';
import { HighlightModule } from '@backbase/ui-ang/highlight';
import { AmountModule } from '@backbase/ui-ang/amount';

@NgModule({
  imports: [CommonModule, HighlightModule, AmountModule],
  declarations: [HighlightShowcaseComponent],
})
export class HighlightShowcaseModule {}
