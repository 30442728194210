import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchShowcaseComponent } from './switch-showcase.component';
import { SwitchModule } from '@backbase/ui-ang/switch';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, SwitchModule, ReactiveFormsModule],
  declarations: [SwitchShowcaseComponent],
})
export class SwitchShowcaseModule {}
