import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';
import { AsYouType, CountryCode, NumberFormat, parsePhoneNumber } from 'libphonenumber-js';
import {
  COUNTRY_CODE_FORMAT_CONFIG_TOKEN,
  CountryCodeFormatConfigMap,
  INPUT_PHONE_CONFIG_TOKEN,
  InputPhoneConfig,
} from '@backbase/ui-ang/input-phone';
import { NgxMaskService } from 'ngx-mask';
export { CountryCode } from 'libphonenumber-js';

/**
 * @name PhoneNumberFormatPipe
 *
 * @description
 * Pipe for formatting phone numbers to various international formats
 *
 * ### Global configuration token
 * `INPUT_PHONE_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `InputPhoneComponent` in your project.
 *
 * The following properties can be overwritten using the token:
 *  - `maxLength`
 *  - `minLength`
 *  - `autocomplete`
 *  - `mask`
 *  - `displayFormat`
 *  - `validationPattern`
 *  - `hideSelectedCountryFlag`
 *  - `defaultCountryIsoCode`
 *  - `countryList`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 *
 * ```typescript
 *  import { INPUT_PHONE_CONFIG_TOKEN } from '@backbase/ui-ang/input-phone';
 *  import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *  import { AppModule } from './app/app.module';
 *
 *  const inputPhoneConfig = {
 *    maxLength: 10,
 *    minLength: 5,
 *    autocomplete: 'off',
 *    displayFormat: 'E.164',
 *  }
 *
 *  providers: [
 *    {
 *      provide: INPUT_PHONE_CONFIG_TOKEN,
 *      useValue: inputPhoneConfig,
 *    },
 *  ]
 * ```
 *
 * ### Country Code Format Config Token
 * `COUNTRY_CODE_FORMAT_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `InputPhoneComponent` in your project.
 *
 * #### Usage notes
 * The following is an example of how to use the token.
 * Note: the `minLength` and `maxLength` properties do not account for the country dialling code:
 *
 * ```typescript
 *  import { COUNTRY_CODE_FORMAT_CONFIG_TOKEN } from '@backbase/ui-ang/input-phone';
 *  import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
 *  import { AppModule } from './app/app.module';
 *
 *  const countryCodeFormatConfig = {
 *    '+1': {
 *      mask: '+0 (000) 000-0000',
 *      minLength: 8,
 *      maxLength, 12,
 *    },
 *  };
 *
 *  providers: [
 *    {
 *      provide: COUNTRY_CODE_FORMAT_CONFIG_TOKEN,
 *      useValue: countryCodeFormatConfig,
 *    },
 *  ]
 * ```
 *
 * #### IMPORTANT NOTE:
 *  - If you wish to validate & format ALL phone numbers using the SAME `minLength`, `maxLength` and/or `mask`, please configure these values using the `INPUT_PHONE_CONFIG_TOKEN`.
 *  - If you need to support phone numbers for multiple countries (DIFFERENT `minLength`, `maxLength` & `mask`), we advise to use the `COUNTRY_CODE_FORMAT_CONFIG_TOKEN` instead.
 *
 */
@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  constructor(
    private maskService: NgxMaskService,
    @Optional()
    @Inject(INPUT_PHONE_CONFIG_TOKEN)
    private readonly overrideConfiguration: InputPhoneConfig,
    @Optional()
    @Inject(COUNTRY_CODE_FORMAT_CONFIG_TOKEN)
    private readonly countryCodeFormatConfig: CountryCodeFormatConfigMap,
  ) {}
  /**
   * @name PhoneNumberFormatPipe#transform
   * @param value - the phone number string to transform
   * @param countryCode - the two letter CDLR code for the country to format to
   * @param isInputElement - whether the value was entered in an input element
   * @description
   * Formats a phone number to any entered country code international format
   */
  transform(value: string | number, countryCode?: CountryCode, isInputElement?: boolean): string {
    // try to use country-specific mask before trying anything else
    if (!isInputElement) {
      for (let i = 2; i <= 4; i++) {
        const potentialMatch = value.toString().substring(0, i);
        const maskFoundForPhoneNumber =
          this.countryCodeFormatConfig && this.countryCodeFormatConfig[potentialMatch]?.mask;
        if (maskFoundForPhoneNumber) {
          return `\u200e${this.maskService.applyMask(value.toString(), maskFoundForPhoneNumber)}`;
        }
      }
    }

    try {
      if (!countryCode) {
        return `\u200e${value.toString()}`;
      }

      if (isInputElement) {
        value = new AsYouType(countryCode).input(value.toString());
      }
      const phoneNumber = parsePhoneNumber(value.toString(), countryCode);
      const displayFormat = this.overrideConfiguration?.displayFormat?.toUpperCase() as NumberFormat;

      const formattedPhoneNumber = displayFormat
        ? phoneNumber.format(displayFormat)
        : phoneNumber.formatInternational();

      if (this.overrideConfiguration?.mask) {
        const diallingCodePrefixMask = '0'.repeat(phoneNumber.countryCallingCode.length);
        const mask = `+${diallingCodePrefixMask} ${this.overrideConfiguration?.mask}`;

        return `\u200e${this.maskService.applyMask(formattedPhoneNumber, mask)}`;
      }

      return `\u200e${formattedPhoneNumber}`;
    } catch (e) {
      return `\u200e${value.toString()}`;
    }
  }
}
