import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputEmailShowcaseComponent } from './input-email-showcase.component';
import { InputEmailModule } from '@backbase/ui-ang/input-email';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, InputEmailModule, InputValidationMessageModule, ReactiveFormsModule],
  declarations: [InputEmailShowcaseComponent],
})
export class InputEmailShowcaseModule {}
