<button
  bbButton
  data-role="file-input-button"
  class="bb-block bb-block--xs"
  [autofocus]="autofocus"
  [color]="color"
  [buttonSize]="buttonSize"
  (focus)="onFocus($event)"
  (click)="fileInput.click()"
  [disabled]="disabled"
  [attr.aria-describedby]="ariaDescribedby"
  [attr.aria-labelledby]="ariaLabelledby"
  [attr.role]="role"
  [attr.aria-owns]="ariaOwns"
  [attr.aria-activedescendant]="ariaActivedescendant"
  [attr.aria-label]="(!label && ariaLabel) || null"
  [attr.data-id]="id ? id + '_input-file_button' : undefined"
>
  <bb-icon-ui cropped="true" [name]="iconName"></bb-icon-ui>
  @if (buttonText) {
    <span>{{ buttonText }}</span>
  } @else {
    <ng-container i18n="Attach file@@fileInputButton.text">Attach file</ng-container>
  }
  <input
    [accept]="accept"
    [multiple]="multiple"
    hidden
    data-role="file-input"
    type="file"
    #fileInput
    (change)="onValueChange(fileInput)"
    [disabled]="disabled"
    [required]="required"
  />
</button>
