import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'illustrationAlt' })
export class IllustrationAltPipe implements PipeTransform {
  transform(text: string) {
    return text
      .replace(/^ic/, '')
      .replace(/\-|_/g, ' ')
      .replace(/\s?[0-9]/g, '')
      .trim();
  }
}
