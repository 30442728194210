import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'bb-input-date-divided-example-ui',
  templateUrl: './input-date-divided.example.component.html',
})
export class InputDateDividedExampleComponent {
  vForm = this.formBuilder.group({
    inputDateDivided: [
      { day: 20, month: 6, year: 1990 },
      Validators.required,
    ],
  });

  constructor(private formBuilder: UntypedFormBuilder) {}
}
