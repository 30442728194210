import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileAttachmentComponent } from './file-attachment.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';
import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';
import { IconModule } from '@backbase/ui-ang/icon';

const uiModules = [ButtonModule, IconModule, KeyboardClickModule, LoadingIndicatorModule];

@NgModule({
  imports: [NgClass, ...uiModules],
  declarations: [FileAttachmentComponent],
  exports: [FileAttachmentComponent],
})
export class FileAttachmentModule {}
