import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemSavingsAccountShowcaseComponent } from './product-item-savings-account-showcase.component';
import { ProductItemSavingsAccountModule } from '@backbase/ui-ang/product-item-savings-account';

@NgModule({
  imports: [CommonModule, ProductItemSavingsAccountModule],
  declarations: [ProductItemSavingsAccountShowcaseComponent],
})
export class ProductItemSavingsAccountShowcaseModule {}
