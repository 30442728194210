import { InjectionToken } from '@angular/core';

/**
 * Product number types
 */
export type AccountNumberType = 'cardNumber' | 'iban' | 'bban';

/**
 * Account numbers displaying format
 */
export const DefaultAccountsDisplayingFormat: AccountsDisplayingFormat = {
  segments: 4,
};

/**
 * Account numbers displaying format
 */
export interface AccountsDisplayingFormat {
  /**
   * The size (or array of sizes) the segments should have.
   */
  segments: number | Array<number>;
}

/**
 * Accounts displaying format configuration per attribute type
 */
export type AccountsDisplayingFormatConfiguration = {
  /**
   * Account displaying formats for iban, bban values
   */
  [key in AccountNumberType]?: AccountsDisplayingFormat;
} & {
  /**
   * Account displaying format for card numbers
   */
  cardNumber?: AccountsDisplayingFormat & {
    cardProviders?: Record<string, AccountsDisplayingFormat>;
  };
};

/**
 * Accounts displaying format Token
 */
export const ACCOUNTS_DISPLAYING_FORMAT = new InjectionToken<AccountsDisplayingFormatConfiguration>(
  'ACCOUNTS_DISPLAYING_FORMAT_TOKEN',
);
