import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  Pipe,
  PipeTransform,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { DropdownSingleSelectOptionComponent } from '@backbase/ui-ang/dropdown-single-select';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { idListAttr } from '@backbase/ui-ang/util';
import { KEY_CODES } from '@backbase/ui-ang/util';
import {
  BB_MULTI_SELECT_CHANGE_DETECTION_REF_TOKEN,
  DropdownMultiSelectOptionComponent,
} from './dropdown-multi-select-option.component';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

/**
 * @name DropdownMultiSelectComponent
 *
 * @description
 * Component that use for DropDown Multi Select.
 */
@Component({
  selector: 'bb-dropdown-multi-select-ui',
  templateUrl: './dropdown-multi-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownMultiSelectComponent),
      multi: true,
    },
    {
      provide: BB_MULTI_SELECT_CHANGE_DETECTION_REF_TOKEN,
      useExisting: ChangeDetectorRef,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMultiSelectComponent
  extends InputBaseComponent
  implements AfterContentInit, ControlValueAccessor, OnDestroy
{
  private readonly unsubscribe$ = new Subject<void>();
  /**
   * The dropdown header text.
   */
  @Input() dropdownHeaderText: string | undefined;

  /**
   * The placeholder for the multi select.
   */
  @Input() placeholder = 'No items selected';

  /**
   * The label for the Dropdown multi select. Defaults to an empty string.
   */
  @Input() label = '';

  /**
   * Enable filtering; Defaults to false.
   */
  @Input() filtering = false;

  /**
   * Whether the Dropdown multi select is read only. Defaults to false.
   */
  @Input() readonly = false;

  /**
   * The position of the dropdown
   */
  @Input() dropdownPosition: PlacementArray = ['bottom-end', 'bottom-start', 'top-end', 'top-start'];

  /**
   * Specifies which element the dropdown should be appended to.
   */
  @Input() container: '' | 'body' = '';

  /**
   * The child option components of type DropDownMultiSelectSingleOption
   */
  isIE = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;

  @ContentChildren(DropdownMultiSelectOptionComponent)
  contentOptions: QueryList<DropdownMultiSelectOptionComponent> | undefined;

  /**
   * The list of options.
   */
  @ViewChildren('option') options!: QueryList<ElementRef>;

  @ViewChild('clearOptions') clearAllButton!: ElementRef;
  @ViewChild('dropDownMenu') dropDownMenu!: ElementRef;

  /**
   * The toggle button of the dropdown
   */
  @ViewChild('toggleButton', { read: ElementRef, static: true }) toggleButton!: ElementRef;

  @ViewChild('listbox') listbox!: ElementRef;

  activeOptionIndex = -1;
  isOptionsFocused = false;

  readonly formGroup = new UntypedFormGroup({});

  readonly formValue$: Observable<string[]> = this.formGroup.valueChanges.pipe(
    map((formValue: { [key: string]: boolean }) => this.getSelectedValues(formValue)),
    takeUntil(this.unsubscribe$),
  );

  readonly labelsMap: {
    [key: string]: string;
  } = {};

  readonly buttonLabelId = this.domAttributesService.generateId();

  readonly clearOptions$ = new Subject<void>();

  private selectedValuesSubject$ = new BehaviorSubject<string[]>([]);
  readonly selectedValues$ = this.selectedValuesSubject$.asObservable();

  private searchKey = '';
  private cachedValue: { [key: string]: boolean } = {};

  isOpen = false;

  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly domAttributesService: DomAttributesService,
  ) {
    super(cd);
    this.buttonLabelId = this.domAttributesService.generateId();
    this.formValue$
      .pipe(
        tap((value) => {
          this.selectedValuesSubject$.next(value);
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (value) => {
          if (value && value.length === 0) {
            this.onChange(undefined);
          } else {
            this.onValueChange(value);
          }
        },
      });

    this.clearOptions$.pipe(withLatestFrom(this.selectedValues$), takeUntil(this.unsubscribe$)).subscribe({
      next: ([, items]) => {
        items.forEach((item: string) => {
          this.formGroup.patchValue({ [item]: false }, { emitEvent: false });
        });
        this.formGroup.updateValueAndValidity();
      },
    });
  }

  ngAfterContentInit(): void {
    this.buildForm((this.contentOptions ?? []) as QueryList<DropdownMultiSelectOptionComponent>);
    this.contentOptions?.changes
      .pipe(debounceTime(0), takeUntil(this.unsubscribe$))
      .subscribe((changes: QueryList<DropdownMultiSelectOptionComponent>) => {
        this.resetAndBuildForm(changes);
      });
  }

  private buildForm(items: QueryList<DropdownMultiSelectOptionComponent>) {
    items.forEach(({ value, label }) => {
      this.formGroup.registerControl(value, new UntypedFormControl(this.cachedValue[value] || false));
      Object.assign(this.labelsMap, { [value]: label });
    });

    if (Object.keys(this.cachedValue).length) {
      this.formGroup.updateValueAndValidity();
    }
  }

  /**
   * Method to reset listbox group based on new items passed through contentOptions.
   *
   * @param items
   */
  private resetAndBuildForm(items: QueryList<DropdownMultiSelectOptionComponent>) {
    // 1. Reset labels map in preparation for new formGroup controls
    Object.keys(this.labelsMap).forEach((key) => {
      delete this.labelsMap[key];
    });
    // 2. Remove all form controls in preparation for new content items
    Object.keys(this.formGroup.controls).forEach((key) => {
      this.formGroup.removeControl(key, { emitEvent: false });
    });
    // 3. clear cache in case of overlapping id's
    this.cachedValue = {};
    // 4. recreate form form labelsMap
    this.buildForm(items);
    // 5. reset value and notify parent through onChange
    this.formGroup.reset();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Unselect all selected options from the listbox
   *
   * @param $event
   */
  clearAll($event: Event) {
    $event.preventDefault();
    this.clearOptions$.next();
  }

  /**
   *
   * @param index
   * @param item
   */
  trackByValueFn(_: number, item: DropdownMultiSelectOptionComponent) {
    return item.value;
  }

  /**
   *
   * @param value
   */
  writeValue(value: string[] | null): void {
    const valuesMap = (value || []).reduce(
      (acc, item) => Object.assign(acc, { [item]: true }),
      {} as {
        [key: string]: boolean;
      },
    );

    this.cachedValue = valuesMap;

    Object.keys(this.formGroup.controls).forEach((key) => {
      this.formGroup.controls[key].setValue(valuesMap[key] || false, { emitEvent: false });
    });

    this.formGroup.updateValueAndValidity({ emitEvent: false });

    const selectedValues = this.getSelectedValues(this.formGroup.value);
    this.selectedValuesSubject$.next(selectedValues);
  }

  /**
   * Updating labels while searching
   *
   * @param label
   */
  getLabel(label: string): string {
    return this.searchKey.toLocaleLowerCase() === label[0].toLocaleLowerCase() && this.filtering
      ? `<b>${label[0]}</b>${label.substring(1)}`
      : label;
  }

  onListboxFocus() {
    this.activeOptionIndex = 0;
    this.isOptionsFocused = true;
  }

  /* eslint-disable complexity */
  onListboxKeyDown(event: KeyboardEvent) {
    if (!this.disabled && this.isOpen && this.isOptionsFocused) {
      const keyCode = this.isIE ? event.keyCode : event.key;

      switch (keyCode) {
        case 40:
        case KEY_CODES.DOWN:
          this.activeOptionIndex = Math.min(this.activeOptionIndex + 1, this.options.length - 1);

          break;

        case 38:
        case KEY_CODES.UP:
          this.activeOptionIndex = Math.max(this.activeOptionIndex - 1, 0);

          break;

        case 13:
        case 32:
        case KEY_CODES.ENTER:
        case KEY_CODES.SPACE:
          this.selectActiveOption();
          break;

        default:
          if (this.filtering) {
            this.searchKey = event.key;
            this.setActiveOptionBaseOnSearchKey(event.key);
          }
      }

      // let Tab key to jump out and close the dropdown menu
      if (keyCode !== 9 && keyCode !== KEY_CODES.TAB) {
        event.preventDefault();
      }
    }
  }

  /* eslint-enable complexity */

  /**
   *
   */
  onListboxBlur() {
    this.activeOptionIndex = -1;
    this.isOptionsFocused = false;
  }

  /**
   *
   * @param isOpen
   */
  onDropdownToggle(isOpen: boolean) {
    this.activeOptionIndex = -1;
    this.isOptionsFocused = false;
    this.isOpen = isOpen;
  }

  getLabeledByIds(...tokens: Array<string | undefined>): string | undefined {
    return idListAttr(...tokens);
  }

  // only taking consideration the focusable elements of the control
  onFocusOut(event: FocusEvent) {
    const nextFocusedElement = event.relatedTarget;
    const focusOutElement = event.target;

    const toggleButton = this.toggleButton.nativeElement.querySelector('[data-role="dropdown-toggle"]');

    if (
      (focusOutElement === toggleButton &&
        nextFocusedElement !== this.clearAllButton.nativeElement &&
        nextFocusedElement !== this.listbox.nativeElement) ||
      (focusOutElement === this.clearAllButton.nativeElement &&
        nextFocusedElement !== toggleButton &&
        nextFocusedElement !== this.listbox.nativeElement) ||
      (focusOutElement === this.listbox.nativeElement &&
        nextFocusedElement !== toggleButton &&
        nextFocusedElement !== this.clearAllButton.nativeElement)
    ) {
      super.onBlur();
    }
  }

  private getSelectedValues(formValue: { [key: string]: boolean }) {
    return (
      this.contentOptions?.reduce((acc: string[], { value }) => {
        if (formValue[value]) {
          acc.push(value);
        }

        return acc;
      }, []) ?? []
    );
  }

  private selectActiveOption() {
    const el = this.options.toArray()[this.activeOptionIndex];

    el?.nativeElement.click();
  }

  private setActiveOptionBaseOnSearchKey(key: string) {
    this.activeOptionIndex = this.options
      .toArray()
      .findIndex((item) => key.toLocaleLowerCase() === item.nativeElement.innerText[0].toLocaleLowerCase());
  }
}
