import { Component, Input } from '@angular/core';
import { map, of } from 'rxjs';
import { ComponentMetaDataService, MetadataItem } from '../../../services/component-meta-data.service';
import { TitleCleanPipe } from '../../../pipes/title-clean.pipe';
import { TitleCaseExceptPipe } from '../../../pipes/title-case-except.pipe';
import { OverviewListComponent } from '../overview-list/overview-list.component';
import { AsyncPipe } from '@angular/common';

enum Context {
  WEB = 'web-components',
  FORMATTERS = 'web-formatters',
  MOBILE = 'mobile-components',
}

@Component({
  standalone: true,
  selector: 'bb-component-list',
  templateUrl: './component-list.component.html',
  imports: [OverviewListComponent, AsyncPipe],
  providers: [TitleCaseExceptPipe, TitleCleanPipe, ComponentMetaDataService],
})
export class ComponentListComponent {
  @Input() context: Context = Context.WEB;

  @Input() header?: string;

  readonly displayedComponents = of(this.componentMetadataService.supportedComponents).pipe(
    map((components: MetadataItem[]) =>
      components.filter(({ component, web, ios, android }) => {
        if (this.context === Context.FORMATTERS) {
          return web && component.includes('-pipe');
        }

        if (this.context === Context.MOBILE) {
          return ios || android;
        }

        return web && !component.includes('-pipe');
      }),
    ),
    map((components: MetadataItem[]) =>
      components.map((component) => ({
        name: component.component,
        title: this.titleClean.transform(this.titleCaseExcept.transform(component.component)),
        imageSrc: this.buildImageSrc(component.component),
        linkTo: this.buildLink(component),
      })),
    ),
    map((components) => JSON.stringify(components)),
  );

  constructor(
    private componentMetadataService: ComponentMetaDataService,
    private titleClean: TitleCleanPipe,
    private titleCaseExcept: TitleCaseExceptPipe,
  ) {}

  buildImageSrc(componentName: MetadataItem['component']) {
    return `./assets/images/${this.context}/${componentName}-overview.png`;
  }

  buildLink(component: MetadataItem) {
    let platform = 'web';

    if (this.context === Context.MOBILE) {
      if (component.android) {
        platform = 'android';
      } else {
        platform = 'ios';
      }
    }

    return `/${this.context}/${component.component}/${platform}`;
  }
}
