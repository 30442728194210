<h2>Collapsible cards</h2>
<div class="bb-block bb-block--lg">
  <bb-collapsible-accordion-ui #collapsibleAccordion1 [closeOthers]="true" [openFirst]="false">
    <div class="bb-block bb-block--sm">
      <bb-collapsible-card-ui>
        <span bbCollapsibleTitle class="bb-stack">
          <bb-icon-ui name="account" class="bb-stack__item"></bb-icon-ui>
          <span>Collapsible Panel #1</span>
        </span>
        <div bbCollapsibleBody>I am expandable content!</div>
      </bb-collapsible-card-ui>
    </div>
    <div class="bb-block bb-block--sm">
      <bb-collapsible-card-ui class="bb-block bb-block--sm">
        <span bbCollapsibleTitle class="bb-stack">
          <bb-icon-ui name="account" class="bb-stack__item"></bb-icon-ui>
          <span>Collapsible Panel #2</span>
        </span>
        <div bbCollapsibleBody>I am expandable content!</div>
      </bb-collapsible-card-ui>
    </div>
    <div class="bb-block bb-block--sm">
      <bb-collapsible-card-ui class="bb-block bb-block--sm">
        <span bbCollapsibleTitle class="bb-stack">
          <bb-icon-ui name="account" class="bb-stack__item"></bb-icon-ui>
          <span>Collapsible Panel #3</span>
        </span>
        <div bbCollapsibleBody>I am expandable content!</div>
      </bb-collapsible-card-ui>
    </div>
  </bb-collapsible-accordion-ui>
</div>
<h2>Collapsible elements</h2>
<div>
  <bb-collapsible-accordion-ui #collapsibleAccordion1 [closeOthers]="true" [openFirst]="false">
    <div class="bb-block bb-block--lg">
      <bb-collapsible-ui>
        <ng-template bbCollapsibleHeader let-toggle="toggle" let-isOpen="isOpen">
          <div class="bb-stack bb-block bb-block--md">
            <span class="bb-stack__item">Custom template for collapsible header</span>
            <button
              bbButton
              color="primary"
              (click)="toggle()"
              class="bb-stack__item bb-stack__item--push-right"
              data-role="custom-toggle-button-one"
            >
              <span>Custom toggle button</span>
              <bb-icon-ui cropped name="expand-more" [class.fa-chevron-up]="isOpen"></bb-icon-ui>
            </button>
          </div>
        </ng-template>
        <ng-template bbCollapsibleBody>
          <div class="card">
            <div class="card-body">
              <p>Example of default content inside.</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam consectetur doloremque ea excepturi,
                fugiat maxime minima nesciunt odit omnis.
              </p>
            </div>
          </div>
        </ng-template>
      </bb-collapsible-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-collapsible-ui>
        <ng-template bbCollapsibleHeader let-toggle="toggle" let-isOpen="isOpen">
          <div class="bb-stack bb-block bb-block--md">
            <span class="bb-stack__item">Custom template for collapsible header</span>
            <button
              bbButton
              color="primary"
              (click)="toggle()"
              class="bb-stack__item bb-stack__item--push-right"
              data-role="custom-toggle-button-two"
            >
              <span>Custom toggle button</span>
              <bb-icon-ui cropped name="expand-more" [class.fa-chevron-up]="isOpen"></bb-icon-ui>
            </button>
          </div>
        </ng-template>
        <ng-template bbCollapsibleBody>
          <div class="card">
            <div class="card-body">
              <p>Example of default content inside.</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam consectetur doloremque ea excepturi,
                fugiat maxime minima nesciunt odit omnis.
              </p>
            </div>
          </div>
        </ng-template>
      </bb-collapsible-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-collapsible-ui>
        <ng-template bbCollapsibleHeader let-toggle="toggle" let-isOpen="isOpen">
          <div class="bb-stack bb-block bb-block--md">
            <span class="bb-stack__item">Custom template for collapsible header</span>
            <button
              bbButton
              color="primary"
              (click)="toggle()"
              class="bb-stack__item bb-stack__item--push-right"
              data-role="custom-toggle-button-three"
            >
              <span>Custom toggle button</span>
              <bb-icon-ui cropped name="expand-more" [class.fa-chevron-up]="isOpen"></bb-icon-ui>
            </button>
          </div>
        </ng-template>
        <ng-template bbCollapsibleBody>
          <div class="card">
            <div class="card-body">
              <p>Example of default content inside.</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam consectetur doloremque ea excepturi,
                fugiat maxime minima nesciunt odit omnis.
              </p>
            </div>
          </div>
        </ng-template>
      </bb-collapsible-ui>
    </div>
  </bb-collapsible-accordion-ui>
</div>
