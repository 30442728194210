import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountNumberType } from '@backbase/ui-ang/account-number-pipe';
import { ArrangementItem } from './account-number-model';
import { getAccountNumber, getAccountNumberType } from './helpers';

/**
 * @name AccountNumberComponent
 *
 * @description
 * Component that displays account number according to the IBAN, BBAN, cardNumber and number.
 *
 * ### Usage
 * By default, The masking and unmasking of the account number is not implemented and requires custom implementation.
 * In order to have a fully working component with masking capabilities, map the `mask` and `unmask` outputs to functions such that it toggles between the masked and unmasked number.
 *
 * ```typescript
 * @Component({
 *   selector: 'bb-account-number-ui-showcase',
 *   template: `
 *     <bb-account-number-ui
 *       [number]="number"
 *       [unmaskable]="true"
 *       [masked]="masked"
 *       (unmask)="onUnmask()"
 *       (mask)="onMask()"
 *     >
 *     </bb-account-number-ui>
 *   `,
 * })
 * export class AccountNumberShowcaseComponent {
 *   number = '1234';
 *   masked = false;
 *
 *   onMask() {
 *     this.number = '**34';
 *     this.masked = true;
 *   }
 *
 *   onUnmask() {
 *     this.number = '1234';
 *     this.masked = false;
 *   }
 *
 *   constructor() {}
 * }
 * ```
 *
 */
@Component({
  selector: 'bb-account-number, bb-account-number-ui',
  templateUrl: './account-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountNumberComponent {
  /**
   * Account that contains account number, a format will be picked automatically
   * Used when `number` unset
   */
  @Input() account: ArrangementItem = {};
  /**
   * Account number,
   * Use with `type` to specify which format to use
   */
  @Input() number: string | number | undefined;
  /**
   * Account number type ('bban', 'iban' or 'cardNumber')
   */
  @Input() type: AccountNumberType | undefined;
  /**
   * Defines whether the attribute is unmaskable
   */
  @Input() unmaskable = false;
  /**
   * Defines whether the attribute is masked
   */
  @Input() masked = false;
  /**
  /**
   * Defines toolitp trigger behavior for masking/unmasking buttons
   */
  @Input() tooltipTrigger = 'hover focus';
  /**
   * Emits a `AccountNumberType` to be masked
   */
  @Output() mask: EventEmitter<AccountNumberType> = new EventEmitter<AccountNumberType>();
  /**
   * Emits a `AccountNumberType` to be unmasked
   */
  @Output() unmask: EventEmitter<AccountNumberType> = new EventEmitter<AccountNumberType>();

  /**
   * Event handler for the `mask` button
   * Emits the `masked` output with a `MaskedAttribute`
   *
   */
  onMask() {
    this.mask.emit(this.getAccountNumberType());
  }
  /**
   * Event handler for the `unmask` button
   * Emits the `unmasked` output with a `MaskedAttribute`
   *
   */
  onUnmask() {
    this.unmask.emit(this.getAccountNumberType());
  }
  /**
   * Gives either number if provided. Otherwise, try to get from account.
   *
   * @returns Either account number or iban or bban
   */
  getAccountNumber(): string | number {
    return this.number || getAccountNumber(this.account);
  }

  /**
   * Gives either type if provided. Otherwise, try to get from account.
   *
   * @returns 'cardNumber' | 'iban' | 'bban'
   */
  getAccountNumberType(): AccountNumberType {
    return this.type || getAccountNumberType(this.account);
  }
}
