<div class="bb-block bb-block--xl">
  <h4>Default</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-term-deposit-ui
        [title]="'John Doe'"
        [amount]="200000"
        [currency]="'EUR'"
        [highlight]="false"
        [productNumber]="'NL75 INGB 2000 1200 56'"
        [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
        [favorite]="true"
      >
      </bb-product-item-term-deposit-ui>
    </div>
  </div>
</div>

<div class="bb-block bb-block--xl">
  <h4>With mainSection</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-term-deposit-ui
        [title]="'John Doe'"
        [amount]="200000"
        [currency]="'EUR'"
        [highlight]="false"
        [productNumber]="'NL75 INGB 2000 1200 56'"
        [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
        [favorite]="true"
        [mainSectionCurrency]="'EUR'"
        [mainSectionAmount]="880.54"
        [mainSectionTitle]="'Available balance'"
      >
      </bb-product-item-term-deposit-ui>
    </div>
  </div>
</div>
