import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';

import { ButtonComponent } from '@backbase/ui-ang/button';

export interface LoadMoreParams {
  from: number;
}

/**
 * @name LoadButtonComponent
 *
 * @description
 * Component that displays a button with integrated loading indicator.
 * Refer to [Button Component](https://designsystem.backbase.com/web-components/button/web) for button colors and sizes.
 *
 * ### Use Case
 * There are two use-cases for this component:
 *    - Finite loading - button is disabled but remains displayed after a certain limit
 *    - Infinite loading - button is always functional
 * <br><br>
 *
 * #### Finite Loading
 * This is used primarily when paginating a list of items and the number of items is known.
 * The component is enabled and allows loading as long as the current number of displayed results (determined by `from` and `size`) is under the `totalCount`.
 *
 * ```html
 *    <bb-load-button-ui [size]="8" [from]="0" [totalCount]="48" ...></bb-load-button-ui>
 * ```
 *
 * #### Infinite Loading
 * This is used primarily when you are loading a content only once or endlessly.
 * This is the default behaviour.
 * This behaviour is available as long as the `totalCount` is 1.
 *
 * ```html
 *    <bb-load-button-ui [totalCount]="1" ...></bb-load-button-ui>
 * ```
 */
@Component({
  selector: 'bb-load-button-ui',
  templateUrl: './load-button.component.html',
})
export class LoadButtonComponent extends ButtonComponent implements AfterViewInit {
  /**
   * Indicates if loading indicator should be shown instead of button content. Defaults to false.
   */
  @Input() isLoading = false;
  /**
   * The page to list from.
   */
  @Input() from = 0;
  /**
   * The number of results per page.
   */
  @Input() size = 0;
  /**
   * Whether it should render the button as a circle. Defaults to false i.e. oblong shape.
   */
  @Input() circle = false;
  /**
   * The total item count. Defaults to 1 to allow infinite loading.
   */
  @Input() totalCount = 1;
  /**
   * Accessible label when button does not contain a discernible text.
   */
  @Input('aria-label') ariaLabel?: string;
  /**
   * This emits new load more params to parent component
   */
  @Output() paramChange: EventEmitter<LoadMoreParams> = new EventEmitter();

  @ViewChild('button', { static: true }) buttonComponent?: ElementRef;

  constructor(
    private readonly elementRef: ElementRef,
    renderer: Renderer2,
  ) {
    super(elementRef, renderer);
    this.elementRef.nativeElement.addEventListener('click', this.onHostElementClick.bind(this), true);
  }

  ngAfterViewInit() {
    this.moveAriaAttributes(this.elementRef.nativeElement, this.buttonComponent && this.buttonComponent.nativeElement);
  }

  isLoadMore() {
    return this.size + this.totalCount + this.from > 0;
  }

  hasMore(): boolean {
    return this.from * this.size + this.size < this.totalCount;
  }

  get isLoadingDisabled(): boolean {
    return this.disabled || (this.isLoadMore() && !this.isLoading ? !this.hasMore() : false);
  }

  onClick($event: MouseEvent) {
    if (this.isLoading && $event) {
      $event.stopPropagation();
    }
    if (this.isLoadMore()) {
      this.onLoadMore();
    }
  }

  onLoadMore(): void {
    if (this.hasMore() && !this.isLoading) {
      this.paramChange.emit({
        from: this.from + 1,
      });
    }
  }

  onHostElementClick($event: MouseEvent) {
    if (this.disabled) {
      // Stop the "click" event propogation for the host component
      // when the template button is disabled
      $event.stopPropagation();
      $event.stopImmediatePropagation();

      return false;
    }

    return true;
  }
}
