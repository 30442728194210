<div class="bb-block bb-block--lg">
  <h2>App configuration</h2>
  <div>
    <code>iban: {{ configuration.iban | json }}</code>
  </div>
  <div>
    <code>bban: {{ configuration.bban | json }}</code>
  </div>
  <div>
    <code>cardNumber: {{ configuration.cardNumber | json }}</code>
  </div>
</div>

<div class="bb-block bb-block--lg">
  @for (account of accounts; track $index) {
    <h4>{{ account.description }}</h4>
    <p>{{ account.number | bbAccountNumber: account?.typeOrFormat : account.cardProviderName }}</p>
  }
</div>
