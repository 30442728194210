<div class="mb-4">
  <h2>With labels</h2>
  <div class="mt-4 col-8">
    <bb-stepper-ui>
      @for (step of steps; track $index) {
        <bb-stepper-step-ui
          [label]="step.label"
          [ariaLabel]="step.ariaLabel"
          [isCurrent]="step.isCurrent"
          [isChecked]="step.isChecked"
          [isActive]="step.isActive"
        ></bb-stepper-step-ui>
      }
    </bb-stepper-ui>
  </div>
</div>
<div class="mb-4">
  <h2>Without labels</h2>
  <div class="mt-4 col-3">
    <bb-stepper-ui>
      @for (step of steps; track $index) {
        <bb-stepper-step-ui
          [isCurrent]="step.isCurrent"
          [isChecked]="step.isChecked"
          [isActive]="step.isActive"
        ></bb-stepper-step-ui>
      }
    </bb-stepper-ui>
  </div>
</div>
<div class="mb-4">
  <h2>Vertical with sub-steps, state labels and links</h2>
  <p>Steps 2, 3.1, 3.3 and 4 are links and change state on hover. URL: {{ data || 'click on links...' }}</p>
  <div class="mt-4 col-8">
    <bb-stepper-ui class="bb-stepper--vertical" (select)="showData($event)">
      @for (step of stepsWithChildren; track $index) {
        <bb-stepper-step-ui
          [label]="step.label"
          [ariaLabel]="step.ariaLabel"
          [stateLabel]="step.stateLabel"
          [data]="step.data"
          [isCurrent]="step.isCurrent"
          [isChecked]="step.isChecked"
          [isActive]="step.isActive"
        >
          @for (step of step.children; track $index) {
            <bb-stepper-step-ui
              [label]="step.label"
              [ariaLabel]="step.label"
              [data]="step.data"
              [isCurrent]="step.isCurrent"
              [isActive]="step.isActive"
              [isChecked]="step.isChecked"
            ></bb-stepper-step-ui>
          }
        </bb-stepper-step-ui>
      }
    </bb-stepper-ui>
  </div>
</div>
