import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { ActionState } from '@backbase/ui-ang/action-status';

@Component({
  selector: 'bb-action-status-example-ui',
  templateUrl: './action-status.example.component.html',
})
export class ActionStatusExampleComponent {
  actionState: ActionState = 'success';
}
