<form [formGroup]="vForm">
  <bb-input-text-ui
    label="First Name"
    formControlName="name"
    [aria-describedby]="'name-errors'"
    [aria-invalid]="!isFieldValid('name')"
    aria-errormessage="name-errors"
  ></bb-input-text-ui>
  <bb-input-validation-message-ui
    id="name-errors"
    [showErrors]="!isFieldValid('name')"
  >
    @if (hasRequiredError('name')) {
      <span> Field is required. </span>
    }
    @if (hasLengthError('name')) {
      <span> Field has minlength of 5. </span>
    }
  </bb-input-validation-message-ui>
</form>
