<div class="bb-block bb-block--md">
  <bb-dropdown-panel-ui [bbDropdownToggleFullWidth]="true">
    <ng-template bbDropdownToggle> Toggle without arrow </ng-template>
    <ng-template bbDropdownMenu>
      <div class="card">
        <div class="card-body">Custom content</div>
      </div>
    </ng-template>
  </bb-dropdown-panel-ui>
</div>
<bb-dropdown-panel-ui [bbDropdownToggleFullWidth]="true">
  <ng-template bbDropdownToggle let-isOpen="isOpen">
    <div class="bb-stack">
      <span class="bb-stack__item">Toggle with arrow</span>
      <bb-icon-ui class="bb-stack__item--push-right" [name]="isOpen ? 'toggle-up' : 'toggle-down'"></bb-icon-ui>
    </div>
  </ng-template>
  <ng-template bbDropdownMenu>
    <div class="card">
      <div class="card-body">Custom content</div>
    </div>
  </ng-template>
</bb-dropdown-panel-ui>
