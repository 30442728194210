import { Inject, Injectable } from '@angular/core';
import { BbDsDocs, DsConfig } from './ds-toolkit.module';
import { SELECTORS } from './selectors';

export interface BbComponentRef {
  template: string;
  selector: string;
}

function getModuleAnnotations(module: any) {
  if (module?.decorators?.[0]?.args?.[0]) {
    return module.decorators[0].args[0];
  }

  if (module.ɵcmp) {
    return (module.ɵcmp.selectors && module.ɵcmp.selectors[0]) || [];
  }

  return undefined;
}

@Injectable({ providedIn: 'root' })
export class MetadataService {
  libExamplesModule: any;
  libModule: any;
  libDocs: BbDsDocs | undefined;
  libShowcaseModule: any;

  constructor(@Inject('config') config: DsConfig) {
    this.libDocs = config.docs;
    this.libModule = config.lib;
    this.libExamplesModule = config.exampleLib;
    this.libShowcaseModule = config.showcaseLib;
  }

  getModuleAnnotations(module: any): any {
    return getModuleAnnotations(module);
  }

  getParsedSelectors(module?: any) {
    return SELECTORS;
  }

  formatMenuTitle(itemName: string): string {
    return this.capitalizeFirstLetter(itemName).replace('-', ' ');
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
