<div class="bb-block bb-block--xl">
  <h4>Automatically setting focus</h4>
  <p>Are you sure you want to delete something?</p>
  <div class="bb-stack">
    <div class="bb-stack__item">
      <button bbButton bbFocus>Cancel</button>
    </div>
    <button bbButton>OK</button>
  </div>
</div>

<div clsas="bb-block bb-block--xl">
  <h4>Manually setting focus</h4>
  <div class="bb-stack">
    <div class="bb-stack__item">
      <button bbButton [bbFocus]="" #focusThis="bbFocus">Button with bbFocus</button>
    </div>
    <button bbButton (click)="focusThis.focus()">Click to focus the other button</button>
  </div>
</div>
