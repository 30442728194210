<div class="paragraph">
<p>An Accordion is a simple way of showing, hiding and breaking down content.  It can be expanded or collapsed, which helps you read or find information, as well as reduce page length.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/accordion-example.png" alt="Accordion example">
</div>
</div>
<div class="sect2">
<h3 id="_anatomy_of_an_accordion">Anatomy of an Accordion</h3>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/accordion-anatomy.png" alt="Anatomy of an accordion">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Header Area</strong><br>
This is the top area of the accordion, this is where the title and Panel Toggle sit. This area is always visible. This area complete can be clickable to expand and collapse the Panel.</p>
</li>
<li>
<p><strong>Panel Title</strong><br>
A Heading that tells the user what the content in the panel is about. It can contain any type of text and icons.</p>
</li>
<li>
<p><strong>Accordion Panel Toggle (optional)</strong><br>
An icon or button can be used to provide a specific point in the screen for the user to collapse or expand the panel.</p>
</li>
<li>
<p><strong>Panel Area</strong><br>
The collapsable container where other components are placed and information is displayed.</p>
</li>
</ol>
</div>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Follow these guidelines for the most suitable and consistent experience:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>An Accordion must always expand information below the title.</p>
</li>
<li>
<p>The title or toggle must clearly show there&#8217;s an interaction available to the user to expand or collapse.</p>
</li>
<li>
<p>If you are using arrows for toggle, they must face the way the content will expand (when in the default state) or collapse (when in it’s expanded).</p>
</li>
<li>
<p>An Accordion must always have the same width.</p>
</li>
<li>
<p>The title and content needs to feel part of the same thing. Use of subtle colour, shadows or lines can help distinguish between them if needed.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Panels within an Accordion have three different states; <strong>collapsed</strong>, <strong>hover</strong> and <strong>expanded</strong>.</p>
</div>
<div class="sect2">
<h3 id="_collapsed_state">Collapsed state</h3>
<div class="paragraph">
<p>When the Accordion is collapsed, the Title, Header or Toggle should clearly show that the panel can be expanded. When using an arrow, this should be pointing down, showing the direction where content will expand.</p>
</div>
</div>
<div class="sect2">
<h3 id="_hover_state">Hover state</h3>
<div class="paragraph">
<p>On web, when a user hovers over an Accordion panel, the element used to show the possibility to expand, are displayed in their native hover state.</p>
</div>
</div>
<div class="sect2">
<h3 id="_expanded_state">Expanded state</h3>
<div class="paragraph">
<p>When the Panel has been expanded, the content is displayed.</p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When working with Accordion, follow these recommendations to keep a consistent experience.</p>
</div>
</div>
</div>