/*eslint max-len: off*/

import { InjectionToken } from '@angular/core';

export interface HeadingNavigationButtonItem<T> {
  label: string;
  value: T;
  canView?: string;
  iconName?: string;
  description?: string;
  isSeparator?: false;
}

export interface HeadingNavigationSeparatorItem {
  isSeparator: true;
  canView?: string;
}

export type HeadingNavigationItem<T> = HeadingNavigationButtonItem<T> | HeadingNavigationSeparatorItem;

export type HeadingNavigationItemWithPermission<T> = HeadingNavigationItem<T> & {
  readonly hasPermissions: boolean;
};

export interface PermissionsStrategy {
  /**
   * The method is called for the every navigation item in the menu. It is used to render items based on provided parameter
   *
   * @param triplets {string} - `canView` property of a HeadingNavigationItem
   * @returns {Promise<boolean>} HeadingNavigationItem is rendered when the returned value is resolved with `true`
   */
  resolveEntitlements(triplets: string): Promise<boolean>;
}

export const PERMISSIONS_STRATEGY = new InjectionToken<PermissionsStrategy>('PermissionsStrategy');
