import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-amount-input-ui-showcase',
  templateUrl: './amount-input-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class AmountInputShowcaseComponent {
  test = 'egy';
  vForm: UntypedFormGroup | undefined;
  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      amount: [''],
      amount2: [''],
      amount3: [''],
    });
  }

  isFieldValid(fieldName: string) {
    const control = this.vForm?.get(fieldName);

    return control && (control.valid || control.untouched);
  }
}
