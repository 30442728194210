import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.

@Component({
  selector: 'bb-select-list-example-ui',
  templateUrl: './select-list.example.component.html',
})
export class SelectListExampleComponent implements OnInit {
  form!: UntypedFormGroup;

  items = [
    { name: 'One', value: 'one' },
    { name: 'Two', value: 'two' },
    { name: 'Three', value: 'three' },
  ];

  ngOnInit() {
    this.form = new UntypedFormGroup({
      number: new UntypedFormControl([]),
    });
  }
}
