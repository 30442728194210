import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldsetShowcaseComponent } from './fieldset-showcase.component';
import { FieldsetModule } from '@backbase/ui-ang/fieldset';
import { ReactiveFormsModule } from '@angular/forms';
import { InputPasswordModule } from '@backbase/ui-ang/input-password';
import { CurrencyInputModule } from '@backbase/ui-ang/currency-input';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { AlertModule } from '@backbase/ui-ang/alert';

@NgModule({
  imports: [
    CommonModule,
    FieldsetModule,
    ReactiveFormsModule,
    InputPasswordModule,
    CurrencyInputModule,
    InputTextModule,
    AlertModule,
  ],
  declarations: [FieldsetShowcaseComponent],
})
export class FieldsetShowcaseModule {}
