import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchBoxComponent } from './search-box.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { IconModule } from '@backbase/ui-ang/icon';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { FocusModule } from '@backbase/ui-ang/focus';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  imports: [
    NgClass,
    NgbTypeaheadModule,
    ButtonModule,
    IconModule,
    FormsModule,
    BaseClassesModule,
    FocusModule,
    KeyboardClickModule,
  ],
  declarations: [SearchBoxComponent],
  exports: [SearchBoxComponent],
})
export class SearchBoxModule {}
