import { InjectionToken } from '@angular/core';

export interface InputTimepickerConfig {
  clock?: '12h' | '24h';
  hourStep?: number;
  max?: string;
  min?: string;
  minuteStep?: number;
}

export const INPUT_TIMEPICKER_CONFIG_TOKEN = new InjectionToken<InputTimepickerConfig>('INPUT_TIMEPICKER_CONFIG_TOKEN');
