import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsibleShowcaseComponent } from './collapsible-showcase.component';
import { CollapsibleModule } from '@backbase/ui-ang/collapsible';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [CommonModule, CollapsibleModule, IconModule],
  declarations: [CollapsibleShowcaseComponent],
})
export class CollapsibleShowcaseModule {}
