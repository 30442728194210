<div class="row">
  <div class="col-md-6">
    <form>
      <p>
        <label [for]="nameInput.id" class="form-label">Full name: (medium tooltip top) </label>
        <button
          bbButton
          circle="true"
          color="tertiary"
          buttonSize="sm"
          class="m-2"
          [bbTooltip]="fullNameTip"
          [placement]="['top', 'top-right']"
          aria-label="Tooltip toggle for full name"
        >
          <bb-icon-ui name="help-outline"></bb-icon-ui>
        </button>
        <bb-input-text-ui #nameInput placeholder="Full name"></bb-input-text-ui>
      </p>
      <p>
        <label [for]="addressInput.id" class="form-label">Address: (tooltip right) </label>
        <button
          bbButton
          circle="true"
          color="tertiary"
          buttonSize="sm"
          class="m-2"
          [bbTooltip]="addressTip"
          placement="right"
          aria-label="Tooltip toggle for address"
        >
          <bb-icon-ui name="help-outline"></bb-icon-ui>
        </button>
        <bb-input-text-ui #addressInput placeholder="Last name and suffix"></bb-input-text-ui>
      </p>
      <p>
        <label [for]="phoneInput.id" class="form-label">Phone number: (tooltip bottom) </label>
        <button
          bbButton
          circle="true"
          color="tertiary"
          buttonSize="sm"
          class="m-2"
          [bbTooltip]="spacesInputTip"
          placement="bottom"
          aria-label="Tooltip toggle for phone number"
        >
          <bb-icon-ui name="help-outline"></bb-icon-ui>
        </button>
        <bb-input-text-ui #phoneInput placeholder="+0000000000"></bb-input-text-ui>
      </p>
      <p>
        <label [for]="ibanInput.id" class="form-label"> IBAN: (tooltip left) </label>
        <button
          bbButton
          circle="true"
          color="tertiary"
          buttonSize="sm"
          [bbTooltip]="spacesInputTip"
          placement="left"
          class="m-2"
          aria-label="Tooltip toggle for IBAN"
        >
          <bb-icon-ui name="help-outline"></bb-icon-ui>
        </button>
        <bb-input-text-ui #ibanInput placeholder="IBAN"></bb-input-text-ui>
      </p>
    </form>
  </div>
</div>

<ng-template #fullNameTip>Do not abbreviate given names or infix</ng-template>
<ng-template #addressTip>Include street and number</ng-template>
<ng-template #spacesInputTip>Do not include spaces</ng-template>
