import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import '@angular/localize/init';

import { IconModule } from '@backbase/ui-ang/icon';
import { ButtonModule } from '@backbase/ui-ang/button';
import { DropdownMenuOptionDirective } from './directives/dropdown-menu-option.directive';
import { DropdownLabelDirective, DropdownMenuComponent, DropdownMenuItemDirective } from './dropdown-menu.component';
import { DropdownMenuFullWidthDirective } from './directives/dropdown-menu-full-width.directive';

@NgModule({
  imports: [NgClass, NgbDropdownModule, NgTemplateOutlet, IconModule, ButtonModule],
  declarations: [
    DropdownMenuComponent,
    DropdownMenuItemDirective,
    DropdownLabelDirective,
    DropdownMenuOptionDirective,
    DropdownMenuFullWidthDirective,
  ],
  exports: [
    DropdownMenuComponent,
    DropdownMenuItemDirective,
    DropdownLabelDirective,
    DropdownMenuOptionDirective,
    DropdownMenuFullWidthDirective,
  ],
})
export class DropdownMenuModule {}
