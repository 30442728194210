import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { PermissionsStrategy, HeadingNavigationItem } from '@backbase/ui-ang/heading';

@Component({
  selector: 'bb-heading-ui-showcase',
  templateUrl: './heading-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class HeadingShowcaseComponent {
  navigationTo: any;
  navigationHeaderItems: HeadingNavigationItem<string>[] = [
    {
      label: 'New transfer',
      value: 'new-transfer-easy',
      canView: 'Payments.USDomesticWire.create OR Payments.USForeignWire.create',
    },
    {
      label: 'Internal transfer',
      value: 'new-transfer-internal',
      canView: 'Payments.A2ATransfer.create',
    },
    {
      isSeparator: true,
      canView: 'Payments.NoRender',
    },
    {
      label: 'Item with no permission',
      value: 'new-transfer-internal',
      canView: 'Payments.NoRender',
    },
  ];

  navigationHeaderItemsNoPermissions = [
    {
      label: 'Item 1',
      value: 'item_1',
    },
    {
      label: 'Item 2',
      value: 'item_2',
    },
    {
      label: 'Item 3',
      value: 'item_3',
    },
    {
      isSeparator: true,
    },
    {
      label: 'Item 4',
      value: 'item_4',
    },
  ];

  navigationAction(value: string) {
    this.navigationTo = value;
  }
}

export class PermissionsStrategyMock implements PermissionsStrategy {
  resolveEntitlements(triplets: string): Promise<boolean> {
    let hasPermission = false;
    if (
      triplets === 'Payments.USDomesticWire.create OR Payments.USForeignWire.create' ||
      triplets === 'Payments.A2ATransfer.create'
    ) {
      hasPermission = true;
    }

    return Promise.resolve(hasPermission);
  }
}
