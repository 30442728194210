<form [formGroup]="form">
  <div bbInputRange formGroupName="range">
    <bb-input-number-ui
      bbInputRangeMin
      formControlName="min"
      label="Minimum"
      [aria-describedby]="'error-min'"
      [aria-invalid]="!isFieldValid(form.get('range')?.get('min'))"
      aria-errormessage="'error-min'"
    ></bb-input-number-ui>
    <bb-input-number-ui
      bbInputRangeMax
      formControlName="max"
      label="Maximum"
      [aria-describedby]="'error-max'"
      [aria-invalid]="!isFieldValid(form.get('range')?.get('max'))"
      aria-errormessage="'error-max'"
    ></bb-input-number-ui>
  </div>
  <ng-container
    *ngTemplateOutlet="
      errors;
      context: { $implicit: form.get('range') }
    "
  ></ng-container>
</form>

<ng-template #errors let-control>
  @if (control?.errors; as errors) {
    <bb-input-validation-message-ui [showErrors]="control?.touched">
      @if (errors?.min; as min) {
        <div id="error-min">Minimum value is {{ min.min }}</div>
      }
      @if (errors?.max; as max) {
        <div id="error-max">Maximum value is {{ max.max }}</div>
      }
    </bb-input-validation-message-ui>
  }
</ng-template>
