import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownMultiSelectShowcaseComponent } from './dropdown-multi-select-showcase.component';
import { DropdownMultiSelectModule } from '@backbase/ui-ang/dropdown-multi-select';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [CommonModule, DropdownMultiSelectModule, ReactiveFormsModule, ButtonModule],
  declarations: [DropdownMultiSelectShowcaseComponent],
})
export class DropdownMultiSelectShowcaseModule {}
