import { Component } from '@angular/core';
//Don't include buttonService while developing this component.
import { ButtonExampleService } from './button.example.service';

@Component({
  selector: 'bb-button-example-ui',
  templateUrl: './button.example.component.html',
})
export class ButtonExampleComponent {
  //Don't include buttonService while developing this component.
  constructor(public readonly buttonService: ButtonExampleService) {}
}
