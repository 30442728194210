<h4>Icon sizes and colors</h4>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui
    name="search"
    class="bb-stack__item--fill"
    size="sm"
    color="primary"
    aria-label="Search icon"
    [attr.data-role]="'search-sm-primary'"
  ></bb-icon-ui>
  <bb-icon-ui
    name="search"
    class="bb-stack__item--fill"
    size="md"
    color="danger"
    [attr.data-role]="'search-md-danger'"
  ></bb-icon-ui>
  <bb-icon-ui
    name="search"
    class="bb-stack__item--fill"
    size="lg"
    color="warning"
    [attr.data-role]="'search-lg-warning'"
  ></bb-icon-ui>
  <bb-icon-ui
    name="search"
    class="bb-stack__item--fill"
    size="xl"
    color="info"
    [attr.data-role]="'search-xl-info'"
  ></bb-icon-ui>
  <bb-icon-ui
    name="search"
    class="bb-stack__item--fill"
    size="xxl"
    color="success"
    [attr.data-role]="'search-xxl-success'"
  ></bb-icon-ui>
</div>
<h4>Icons - Background Circle</h4>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="directions-car"
    size="sm"
    backgroundType="circle"
    [attr.data-role]="'directions-car-sm-background-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="local-play"
    size="md"
    color="info"
    backgroundType="circle"
    [attr.data-role]="'local-play-md-background-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="favorite"
    size="lg"
    color="danger"
    backgroundType="circle"
    [attr.data-role]="'favorite-lg-background-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="shopping-cart"
    size="xl"
    color="success"
    backgroundType="circle"
    [attr.data-role]="'shopping-cart-xl-background-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="assignment-returned"
    size="xxl"
    color="warning"
    backgroundType="circle"
    [attr.data-role]="'assignment-returned-xxl-background-circle'"
  ></bb-icon-ui>
</div>
<h4>Icons - Background Square</h4>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="directions-car"
    size="sm"
    backgroundType="square"
    [attr.data-role]="'directions-car-sm-background-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="local-play"
    size="md"
    color="info"
    backgroundType="square"
    [attr.data-role]="'local-play-md-background-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="favorite"
    size="lg"
    color="danger"
    backgroundType="square"
    [attr.data-role]="'favorite-lg-background-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="shopping-cart"
    size="xl"
    color="success"
    backgroundType="square"
    [attr.data-role]="'shopping-cart-xl-background-square'"
  >
  </bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    name="assignment-returned"
    size="xxl"
    color="warning"
    backgroundType="square"
    [attr.data-role]="'assignment-returned-xxl-background-square'"
  >
  </bb-icon-ui>
</div>
<h4>Icons - Categories</h4>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="category-home"
    backgroundType="circle"
    [attr.data-role]="'category-home-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="category-transfers"
    backgroundType="circle"
    [attr.data-role]="'category-transfers-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="category-interest-income"
    backgroundType="circle"
    [attr.data-role]="'category-interest-income-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="category-beauty"
    backgroundType="circle"
    [attr.data-role]="'category-beauty-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="category-restaurants"
    backgroundType="circle"
    [attr.data-role]="'category-restaurants-circle'"
  ></bb-icon-ui>
</div>
<h4>Icons - Product Kinds</h4>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="current-accounts"
    color="current-account"
    backgroundType="square"
    [attr.data-role]="'product-kind-current-accounts-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="savings-accounts"
    color="savings-account"
    backgroundType="square"
    [attr.data-role]="'product-kind-savings-accounts-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="credit-cards"
    color="credit-card"
    backgroundType="square"
    [attr.data-role]="'product-kind-credit-cards-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="loans"
    color="loan"
    backgroundType="square"
    [attr.data-role]="'product-kind-loans-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    size="md"
    class="bb-stack__item--fill"
    name="investment-accounts"
    color="investment-account"
    backgroundType="square"
    [attr.data-role]="'product-kind-investment-accounts-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    name="account"
    color="current-account"
    backgroundType="square"
    [attr.data-role]="'product-kind-custom-accounts-square'"
  ></bb-icon-ui>
</div>
<h4>Icons - Inverse Product Kinds</h4>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="current-accounts"
    color="current-account"
    backgroundType="square"
    [attr.data-role]="'product-kind-current-accounts-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="savings-accounts"
    color="savings-account"
    backgroundType="square"
    [attr.data-role]="'product-kind-savings-accounts-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="credit-cards"
    color="credit-card"
    backgroundType="square"
    [attr.data-role]="'product-kind-credit-cards-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="loans"
    color="loan"
    backgroundType="square"
    [attr.data-role]="'product-kind-loans-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    size="md"
    [inverse]="true"
    class="bb-stack__item--fill"
    name="investment-accounts"
    color="investment-account"
    backgroundType="square"
    [attr.data-role]="'product-kind-investment-accounts-square'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="account"
    color="current-account"
    backgroundType="square"
    [attr.data-role]="'product-kind-custom-accounts-square'"
  ></bb-icon-ui>
</div>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="current-accounts"
    color="current-account"
    backgroundType="circle"
    [attr.data-role]="'product-kind-current-accounts-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="savings-accounts"
    color="savings-account"
    backgroundType="circle"
    [attr.data-role]="'product-kind-savings-accounts-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="credit-cards"
    color="credit-card"
    backgroundType="circle"
    [attr.data-role]="'product-kind-credit-cards-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="loans"
    color="loan"
    backgroundType="circle"
    [attr.data-role]="'product-kind-loans-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    size="md"
    [inverse]="true"
    class="bb-stack__item--fill"
    name="investment-accounts"
    color="investment-account"
    backgroundType="circle"
    [attr.data-role]="'product-kind-investment-accounts-circle'"
  ></bb-icon-ui>
  <bb-icon-ui
    class="bb-stack__item--fill"
    size="md"
    [inverse]="true"
    name="account"
    color="current-account"
    backgroundType="circle"
    [attr.data-role]="'product-kind-custom-accounts-circle'"
  ></bb-icon-ui>
</div>
<h4>Icons - Files</h4>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui name="file-bb-sepa" size="lg" class="bb-stack__item--fill" [attr.data-role]="'file-bb-sepa'"></bb-icon-ui>
  <bb-icon-ui name="file-xls" size="lg" class="bb-stack__item--fill" [attr.data-role]="'file-xls'"></bb-icon-ui>
  <bb-icon-ui name="file-csv" size="lg" class="bb-stack__item--fill" [attr.data-role]="'file-csv'"></bb-icon-ui>
  <bb-icon-ui name="file-blk" size="lg" class="bb-stack__item--fill" [attr.data-role]="'file-blk'"></bb-icon-ui>
  <bb-icon-ui name="file-mts" size="lg" class="bb-stack__item--fill" [attr.data-role]="'file-mts'"></bb-icon-ui>
</div>
<h4>Icons - Animated</h4>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui name="cached" size="lg" [animate]="true" class="bb-stack__item--fill"></bb-icon-ui>
</div>
<h4>Outlined Icons</h4>
<div class="bb-stack bb-block--lg">
  <bb-icon-ui name="warning-amber" size="md"></bb-icon-ui>
</div>
<h4>RTL rotate icons</h4>
<bb-icon-ui name="shopping-cart" flipOnRtl="true" class="bb-stack__item--fill" size="md" color="primary"></bb-icon-ui>
<bb-icon-ui name="angle-left" flipOnRtl="true" class="bb-stack__item--fill" size="md" color="primary"></bb-icon-ui>
<bb-icon-ui name="angle-right" flipOnRtl="true" class="bb-stack__item--fill" size="md" color="primary"></bb-icon-ui>
