import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarShowcaseComponent } from './avatar-showcase.component';
import { AvatarModule } from '@backbase/ui-ang/avatar';

@NgModule({
  imports: [CommonModule, AvatarModule],
  declarations: [AvatarShowcaseComponent],
})
export class AvatarShowcaseModule {}
