import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemInvestmentAccountShowcaseComponent } from './product-item-investment-account-showcase.component';
import { ProductItemInvestmentAccountModule } from '@backbase/ui-ang/product-item-investment-account';

@NgModule({
  imports: [CommonModule, ProductItemInvestmentAccountModule],
  declarations: [ProductItemInvestmentAccountShowcaseComponent],
})
export class ProductItemInvestmentAccountShowcaseModule {}
