import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';

import { BadgeComponent } from './badge.component';

@NgModule({
  imports: [NgClass],
  declarations: [BadgeComponent],
  exports: [BadgeComponent],
})
export class BadgeModule {}
