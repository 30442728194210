import { Component, Input } from '@angular/core';

import { OverviewListCardComponent } from '../overview-list-card/overview-list-card.component';
import { SafeHtmlPipe } from '../../../pipes/safe-html.pipe';

interface ListItems {
  name: string;
  title: string;
  subtitle?: string;
  imageSrc: string;
  linkTo: string;
}

@Component({
  standalone: true,
  selector: 'bb-overview-list',
  templateUrl: './overview-list.component.html',
  imports: [OverviewListCardComponent, SafeHtmlPipe],
})
export class OverviewListComponent {
  @Input() itemsJson: string = '[]';

  @Input() header?: string;

  @Input() footer?: string;

  get items(): ListItems[] {
    return JSON.parse(this.itemsJson);
  }
}
