import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemTermDepositShowcaseComponent } from './product-item-term-deposit-showcase.component';
import { ProductItemTermDepositModule } from '@backbase/ui-ang/product-item-term-deposit';

@NgModule({
  imports: [CommonModule, ProductItemTermDepositModule],
  declarations: [ProductItemTermDepositShowcaseComponent],
})
export class ProductItemTermDepositShowcaseModule {}
