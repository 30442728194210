import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, NgForm, UntypedFormControl } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-radio-group-ui-showcase',
  templateUrl: './input-radio-group-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputRadioGroupShowcaseComponent implements OnInit {
  radioGroupForm: UntypedFormGroup;
  radioGroupWithErrorState: UntypedFormControl = new UntypedFormControl('');
  paymentType = 'US_FOREIGN_WIRE';

  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.radioGroupForm = this.formBuilder.group({
      accountType: [{ value: '', disabled: false }, Validators.required],
    });
  }

  bank = 'ABN';
  radiosTemplateData = [
    {
      label: 'ABN AMRO Bank',
      value: 'ABN',
    },
    {
      label: 'ING Bank',
      value: 'ING',
    },
  ];

  ngOnInit() {
    this.radioGroupWithErrorState.markAsTouched();
  }

  onRadioTemplateFormSubmit(form: NgForm) {
    console.log(form.value);
  }
}
