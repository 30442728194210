import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ConfigurationInputService {
  sharedField = new Subject();
}
export interface RadioItem {
  label: string;
  value: boolean | string;
}

export type CheckboxItem = RadioItem & {
  formControlName: string;
};
export type CheckboxList = Array<CheckboxItem>;

export interface RadioGroup {
  label: string;
  formControlName: string;
  radioItems: RadioItem[];
}

export type RadioGroupList = Array<RadioGroup>;

export type InputList = Array<InputItem>;

export interface DropdownItem {
  label: string;
  formControlName: string;
  dropdownItemList: string[];
}

export interface InputItem {
  label: string;
  formControlName: string;
  value: string;
}

export type DropdownList = Array<DropdownItem>;
