<form [formGroup]="vForm">
  <h2 id="ariaLabelledbyId">Amount with regular size</h2>
  <bb-amount-input-ui
    formControlName="name"
    [id]="'billpay-amount-input'"
    currency="USD"
    placeholder="0.00"
    [aria-labelledby]="'ariaLabelledbyId'"
  >
  </bb-amount-input-ui>
</form>
