import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonShowcaseComponent } from './button-showcase.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { IconModule } from '@backbase/ui-ang/icon';
import { LoadButtonModule } from '@backbase/ui-ang/load-button';
@NgModule({
  imports: [ButtonModule, CommonModule, IconModule, LoadButtonModule],
  declarations: [ButtonShowcaseComponent],
})
export class ButtonShowcaseModule {}
