<div class="paragraph">
<p>Payment Card is a component that visually displays to the user their available cards and the data attached to it such as the Card Number, Cardholder&#8217;s Name and Expiry Date.</p>
</div>
<div class="sect1">
<h2 id="_anatomy_of_a_payment_card">Anatomy of a Payment Card</h2>
<div class="sectionbody">
<div class="imageblock">
<div class="content">
<img src="assets/images/payment-card-anatomy.png" alt="Payment card anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Logo</strong><br>
The issuing bank or institution logo. +</p>
</li>
<li>
<p><strong>Card Number</strong><br>
Number embossed on a card that links the card to the account owner and account servicer (sometimes called personal account number or PAN). +</p>
</li>
<li>
<p><strong>Cardholder Name</strong><br>
Name of the person linked to this card. +</p>
</li>
<li>
<p><strong>Valid Thru Label</strong><br>
Text that indicates the date below refers to the validity of the card. +</p>
</li>
<li>
<p><strong>Card Expiry Date</strong><br>
Date for the validity of the card, always expressed MM/YY. +</p>
</li>
<li>
<p><strong>Card Vendor Logo</strong><br>
Logo of the payment processor such as Mastercard, Visa, Maestro and so forth. +</p>
</li>
<li>
<p><strong>Card background</strong><br>
Solid colors, gradients or images can be used as background for the payment card.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Payment Cards can be displayed differently in the interface depending on the state of the card, as well as any customization on a project level, in the following way.</p>
</div>
<div class="sect2">
<h3 id="_card_logo">Card Logo</h3>
<div class="paragraph">
<p>The logo from the bank can be placed in a different position by just updating CSS.<br>
image::payment-card-logo.png[Example of a reposition of the Card Logo]</p>
</div>
</div>
<div class="sect2">
<h3 id="_card_vendor">Card Vendor</h3>
<div class="paragraph">
<p>Vendor Logos, such as <em>Mastercard</em>, <em>Visa</em>, <em>Maestro</em> and so forth, can be placed at the bottom right or a the top left. However, note that vendors usually have their own brand guidelines on the usage and placement of these logos.<br>
image::payment-card-vendor.png[Example of a reposition of the Card Vendor]</p>
</div>
</div>
<div class="sect2">
<h3 id="">+</h3>
<div class="paragraph">
<p>Card Background Styles</p>
</div>
<div class="paragraph">
<p>Cards can be styled to have colors or images as background.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/payment-card-background.png" alt="Example of a different card background">
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_2">+</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Do&#8217;s &amp; Don’ts</p>
</div>
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use formatters to mask card numbers, but do not completely rely on them for security. Make sure that data sent from the back-end is pre-masked.</p>
</li>
<li>
<p>Ensure the background of the card has sufficient contrast with text and other visual elements in the foreground so that it doesn’t break accessibility rules.</p>
</li>
<li>
<p>Follow standards to display data such as the valid thru date.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Cards are fully responsive, which means they take all the width of the container were they are placed. This can be customized to have a fixed width.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_resources">Resources</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p><a href="https://designsystem.backbase.com/formatters/card-number/">Card Number (format</a>)</p>
</li>
<li>
<p><a href="https://community.backbase.com/documentation/Retail-Banking/latest/card_management">Developer Docs in Community</a></p>
</li>
</ul>
</div>
</div>
</div>