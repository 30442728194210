<div class="bb-block bb-block--xl">
  <h4>Default</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-basic-account-ui
        [title]="'Ricardo Norton\'s Savings Account'"
        [amount]="83652.32"
        [productNumber]="'NL75INGB3400120056'"
        [currency]="'EUR'"
        [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
        [highlight]="false"
        [favorite]="false"
        [active]="false"
      ></bb-product-item-basic-account-ui>
    </div>
  </div>
</div>

<div class="bb-block bb-block--xl">
  <h4>Inside a list</h4>
  <div class="card">
    <div class="card-body">
      <div class="bb-list">
        <div class="bb-list__item">
          <bb-product-item-basic-account-ui
            [title]="'Ricardo Norton\'s Savings Account'"
            [amount]="83652.32"
            [productNumber]="'NL75INGB3400120056'"
            [currency]="'EUR'"
            [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
            [highlight]="false"
            [favorite]="false"
            [active]="false"
          ></bb-product-item-basic-account-ui>
        </div>
        <div class="bb-list__item">
          <bb-product-item-basic-account-ui
            [title]="'Ricardo Norton\'s Master Card'"
            [amount]="6719.64"
            [productNumber]="8927"
            [productNumberFormat]="{ length: 18, maskRange: [0, 14], segments: 4 }"
            [currency]="'EUR'"
            [highlight]="false"
            [active]="false"
          ></bb-product-item-basic-account-ui>
        </div>
        <div class="bb-list__item">
          <bb-product-item-basic-account-ui
            [title]="'Ricardo Norton\'s Master Card'"
            [amount]="6719.64"
            [productNumber]="'NL75INGB3400120056'"
            [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
            [currency]="'EUR'"
            [highlight]="false"
            [active]="false"
            [productDetailsInfo]="{
              owners: 'Ricardo Norton'
            }"
          ></bb-product-item-basic-account-ui>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bb-block bb-block--xl">
  <h4>Multiple accounts with selection</h4>
  <div class="card">
    <div class="card-body">
      <div class="bb-list">
        <div class="bb-list__item">
          <bb-product-item-basic-account-ui
            [title]="'Ricardo Norton\'s Savings Account'"
            [amount]="83652.32"
            [productNumber]="'NL75INGB3400120056'"
            [currency]="'EUR'"
            [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
            [highlight]="false"
            [favorite]="false"
            [active]="false"
          ></bb-product-item-basic-account-ui>
        </div>
        <div class="bb-list__item">
          <bb-product-item-basic-account-ui
            [title]="'Ricardo Norton\'s Master Card'"
            [productNumber]="8927"
            [productNumberFormat]="{ length: 18, maskRange: [0, 14], segments: 4 }"
            [amount]="6719.64"
            [currency]="'EUR'"
            [highlight]="false"
            [favorite]="false"
          ></bb-product-item-basic-account-ui>
        </div>
        <div class="bb-list__item">
          <bb-product-item-basic-account-ui
            [title]="'Ricardo Norton\'s Credit Card'"
            [productNumber]="1278"
            [productNumberFormat]="{ length: 18, maskRange: [0, 14], segments: 4 }"
            [amount]="9004.98"
            [currency]="'EUR'"
            [highlight]="false"
            [active]="false"
          ></bb-product-item-basic-account-ui>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bb-block bb-block--xl">
  <h4>With account status</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-basic-account-ui
        [title]="'Ricardo Norton\'s Savings Account'"
        [amount]="83652.32"
        [productNumber]="'NL75INGB3400120056'"
        [currency]="'EUR'"
        [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
        [highlight]="false"
        [favorite]="false"
        [active]="false"
        [status]="'inactive'"
      ></bb-product-item-basic-account-ui>
    </div>
  </div>
</div>

<div class="bb-block bb-block--xl">
  <h4>With account status</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-basic-account-ui
        [title]="'Ricardo Norton\'s Savings Account'"
        [amount]="83652.32"
        [productNumber]="'NL75INGB3400120056'"
        [currency]="'EUR'"
        [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
        [highlight]="false"
        [favorite]="false"
        [active]="false"
        [status]="'inactive'"
      ></bb-product-item-basic-account-ui>
    </div>
  </div>
</div>

<div class="bb-block bb-block--xl">
  <h4>With amount label</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-basic-account-ui
        [title]="'Ricardo Norton\'s Savings Account'"
        [amount]="83652.32"
        [productNumber]="'NL75INGB3400120056'"
        [currency]="'EUR'"
        [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
        [highlight]="false"
        [favorite]="false"
        [active]="false"
        balanceLabel="Available balance"
      ></bb-product-item-basic-account-ui>
    </div>
  </div>
</div>

<div class="bb-block bb-block--xl">
  <h4>With amount label</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-basic-account-ui
        [title]="'Ricardo Norton\'s Savings Account'"
        [amount]="83652.32"
        [productNumber]="'NL75INGB3400120056'"
        [currency]="'EUR'"
        [bankBranchCode]="'055770134'"
        [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
        [highlight]="false"
        [favorite]="false"
        [active]="false"
        balanceLabel="Available balance"
      ></bb-product-item-basic-account-ui>
    </div>
  </div>
</div>
