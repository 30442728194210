import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-date-divided-ui-showcase',
  templateUrl: './input-date-divided-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputDateDividedShowcaseComponent {
  vForm: UntypedFormGroup | undefined;
  errors: any = {};
  errorLabels1 = {
    required: () => 'Input is required',
    dayRequired: () => 'Day is required',
    dayMax: () => 'Day cannot be more than 31',
    dayMin: () => 'Day cannot be lesser than 1',
    monthRequired: () => 'Month is required',
    monthMax: () => 'Month cannot be more than 12',
    monthMin: () => 'Month cannot be lesser than 1',
    yearRequired: () => 'Year is required',
    yearMax: () => 'Invalid year value',
    yearMin: () => 'Invalid year value',
  };
  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      inputDateDivided: [{ day: 20, month: 6, year: 1990 }, Validators.required],

      inputDateDivided1: [{ day: 20, month: 6, year: 1990 }, Validators.required],

      inputDateDivided2: [{ day: null, month: null, year: null }, Validators.required],
    });

    this.vForm.statusChanges.subscribe(() => {
      this.errors = this.vForm?.get('inputDateDivided')?.errors;
    });
  }
  isFieldValid(fieldName: string) {
    const control = this.getControl(fieldName);

    return control && (control.valid || control.untouched);
  }

  private getControl(fieldName: string) {
    return this.vForm?.controls[fieldName];
  }

  hasRequiredError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return (
      fieldControl.errors.required ||
      fieldControl.errors['day']?.required ||
      fieldControl.errors['month']?.required ||
      fieldControl.errors['year']?.required
    );
  }

  hasError(field: string, error: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl?.errors) {
      return true;
    }

    return fieldControl.errors[error];
  }

  hasDayError(field: string, error: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl?.errors) {
      return true;
    }

    return fieldControl.errors['day'][error];
  }

  hasMonthError(field: string, error: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl?.errors) {
      return true;
    }

    return fieldControl.errors['month'][error];
  }
}
