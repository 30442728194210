import { Component } from '@angular/core';

@Component({
  selector: 'bb-item-log-page-ui',
  templateUrl: './bb-item-log-page-ui.component.html',
  styles: [],
})
export class BbItemLogPageUiComponent {
  constructor() {}
}
