import { Directive, ContentChildren, AfterContentInit, QueryList, NgZone, ChangeDetectorRef } from '@angular/core';
import { RouterLinkActive, Router, NavigationEnd } from '@angular/router';
import { filter, first, switchMap } from 'rxjs/operators';

@Directive({ selector: '[bbLayoutNavDropdown]', exportAs: 'bbLayoutNavDropdown' })
export class LayoutNavDropdownDirective implements AfterContentInit {
  isActive = false;

  @ContentChildren(RouterLinkActive, { descendants: true }) routerLinks?: QueryList<RouterLinkActive>;

  constructor(private readonly router: Router, private readonly ngZone: NgZone) {}

  ngAfterContentInit(): void {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        switchMap((_: NavigationEnd) => this.ngZone.onStable.pipe(first())),
      )
      .subscribe({
        next: () => {
          this.ngZone.run(() => {
            if (this.routerLinks) {
              this.isActive = this.routerLinks.toArray().some((navLink) => navLink.isActive);
            }
          });
        },
      });
  }
}
