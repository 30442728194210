import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsibleAccordionShowcaseComponent } from './collapsible-accordion-showcase.component';
import { CollapsibleAccordionModule } from '@backbase/ui-ang/collapsible-accordion';
import { IconModule } from '@backbase/ui-ang/icon';
import { CollapsibleCardModule } from '@backbase/ui-ang/collapsible-card';
import { CollapsibleModule } from '@backbase/ui-ang/collapsible';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [
    CommonModule,
    CollapsibleAccordionModule,
    IconModule,
    CollapsibleCardModule,
    CollapsibleModule,
    ButtonModule,
  ],
  declarations: [CollapsibleAccordionShowcaseComponent],
})
export class CollapsibleAccordionShowcaseModule {}
