import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { TimepickerStringAdapter } from './input-timepicker.service';
import { InputTimepickerComponent } from './input-timepicker.component';

@NgModule({
  imports: [NgbTimepickerModule, FormsModule, ReactiveFormsModule, BaseClassesModule],
  declarations: [InputTimepickerComponent],
  exports: [InputTimepickerComponent],
  providers: [TimepickerStringAdapter],
})
export class InputTimepickerModule {}
