import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownSingleSelectShowcaseComponent } from './dropdown-single-select-showcase.component';
import { DropdownSingleSelectModule } from '@backbase/ui-ang/dropdown-single-select';
import { ReactiveFormsModule } from '@angular/forms';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';

@NgModule({
  imports: [CommonModule, DropdownSingleSelectModule, ReactiveFormsModule, InputValidationMessageModule],
  declarations: [DropdownSingleSelectShowcaseComponent],
})
export class DropdownSingleSelectShowcaseModule {}
