@if (!inline) {
  @if (showLoadingState) {
    <div
      [ngClass]="{ 'bb-loading-indicator--with-background': hasBackground }"
      data-role="loading-indicator"
      class="bb-state-container"
    >
      <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
    </div>
  }
} @else {
  @if (showLoadingState) {
    <ng-container *ngTemplateOutlet="loadingIndicator"></ng-container>
  }
}

<ng-template #loadingIndicator>
  <div
    data-role="loading-indicator-wrapper"
    class="bb-loading-indicator bb-loading-indicator--{{ loaderSize }}"
    [ngClass]="{ 'bb-loading-indicator--inline': inline }"
    role="status"
  >
    <svg
      aria-label="Loading"
      i18n-aria-label="Loading| Label for the loading indicator @@bb-loading-indicator-ui.indicator"
      class="bb-loading-indicator__circle"
      preserveAspectRatio="xMinYMin meet"
    >
      <circle class="bb-loading-indicator__path" r="40%" cx="50%" cy="50%" fill="none" stroke="currentColor"></circle>
    </svg>
    @if (text) {
      <div data-role="loading-indicator-text" class="bb-loading-indicator__text">{{ text }}</div>
    }
  </div>
</ng-template>
