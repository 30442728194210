<a [routerLink]="linkTo" [fragment]="fragment" class="overview-list-card">
  <div class="overview-list-card__header">
    <img class="overview-list-card__header-image" [src]="imageSrc" [alt]="name" />
  </div>
  <div class="overview-list-card__body">
    <div class="overview-list-card__body-title">{{ title || name }}</div>
    @if (subtitle) {
      <div class="overview-list-card__body-subtitle">{{ subtitle }}</div>
    }
  </div>
</a>
