import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

export const BB_DROPDOWN_CHANGE_DETECTION_REF_TOKEN: InjectionToken<ChangeDetectorRef> = new InjectionToken(
  'BB_DROPDOWN_SINGLE_SELECT',
);

/**
 * @name DropdownSingleSelectOption
 *
 * @description
 * Component that represents a single option that is part of the dropdown single select component.
 */
@Component({
  selector: 'bb-dropdown-single-select-option-ui',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
})
export class DropdownSingleSelectOptionComponent implements OnChanges {
  /**
   * The value of the native option element
   */
  @Input() value: string | undefined;
  /**
   * The label of the option item
   */
  @Input() label: string | undefined;

  constructor(@Inject(BB_DROPDOWN_CHANGE_DETECTION_REF_TOKEN) private readonly dropdownCDRef: ChangeDetectorRef) {}

  // NOTES: trigger of detectChanges is needed
  // because DropdownSingleSelectComponent has changeDetection
  // strategy onPush, hence it reacts only to changes
  // of its own @Inputs, but not @Inputs() of options component
  ngOnChanges({ value = {} as SimpleChange, label = {} as SimpleChange }: SimpleChanges) {
    const { firstChange: firstChangeValue } = value;
    const { firstChange: firstChangeLabel } = label;

    if (firstChangeLabel && firstChangeValue) {
      return;
    }

    this.dropdownCDRef.detectChanges();
  }
}
