<div class="row">
  <div class="col-md-6">
    <ng-container [formGroup]="form">
      <div class="form-group mb-3">
        <h3>Range 1</h3>
        <div class="visually-hidden" id="input-range-label-one">Two inputs for setting range between two values</div>
        <div bbInputRange class="bb-stack bb-stack--align-top" formGroupName="range1">
          <div class="bb-stack__item">
            <bb-input-number-ui
              [aria-describedby]="'input-range-error-one-min'"
              [aria-labelledby]="'input-range-label-one'"
              [aria-invalid]="!isFieldValid(form.get('range1')?.get('min'))"
              aria-errormessage="'input-range-error-one-min'"
              bbInputRangeMin
              label="Minimum"
              formControlName="min"
            ></bb-input-number-ui>
            @if (form.get('range1')?.errors; as errors) {
              <bb-input-validation-message-ui
                [showErrors]="form.get('range1')?.touched"
                [id]="'input-range-error-one-min'"
              >
                @if (errors?.min; as min) {
                  <span>Minimum value is {{ min.min }}</span>
                }
              </bb-input-validation-message-ui>
            }
          </div>
          <div>
            <bb-input-number-ui
              [aria-describedby]="'input-range-error-one-max'"
              [aria-invalid]="!isFieldValid(form.get('range1')?.get('max'))"
              aria-errormessage="'input-range-error-one-max'"
              bbInputRangeMax
              label="Maximum"
              formControlName="max"
            ></bb-input-number-ui>
            @if (form.get('range1')?.errors; as errors) {
              <bb-input-validation-message-ui
                [showErrors]="form.get('range1')?.touched"
                [id]="'input-range-error-one-max'"
              >
                @if (errors?.max; as max) {
                  <span>Maximum value is {{ max.max }}</span>
                }
              </bb-input-validation-message-ui>
            }
          </div>
        </div>
      </div>
      <div class="form-group mb-3">
        <h3>Range 2</h3>
        <div bbInputRange class="bb-stack bb-stack--align-top" formGroupName="range2">
          <div class="bb-stack__item">
            <bb-input-number-ui
              [aria-describedby]="'input-range-error-two-min'"
              [aria-invalid]="!isFieldValid(form.get('range2')?.get('min'))"
              aria-errormessage="'input-range-error-two-min'"
              bbInputRangeMin
              label="Minimum"
              formControlName="min"
            ></bb-input-number-ui>
            <bb-input-validation-message-ui
              [showErrors]="showErrors('range2', 'min')"
              [id]="'input-range-error-two-min'"
            >
              @if (hasError('range2', 'min'); as min) {
                <span>Minimum value is {{ min.min }}</span>
              }
            </bb-input-validation-message-ui>
          </div>
          <div>
            <bb-input-number-ui
              [aria-describedby]="'input-range-error-two-max'"
              [aria-invalid]="!isFieldValid(form.get('range2')?.get('max'))"
              aria-errormessage="'input-range-error-two-max'"
              bbInputRangeMax
              label="Maximum"
              formControlName="max"
            ></bb-input-number-ui>
            <bb-input-validation-message-ui
              [showErrors]="showErrors('range2', 'max')"
              [id]="'input-range-error-two-max'"
            >
              @if (hasError('range2', 'max'); as max) {
                <span>Maximum value is {{ max.max }}</span>
              }
            </bb-input-validation-message-ui>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="form-group mb-3">
      <h3>Range 3</h3>
      <div bbInputRange class="bb-stack bb-stack--align-top">
        <div class="bb-stack__item">
          <bb-input-number-ui
            [aria-describedby]="'input-range-error-three-min'"
            [aria-invalid]="!isFieldValid(form.get('range3')?.get('min'))"
            aria-errormessage="'input-range-error-three-min'"
            bbInputRangeMin
            [formControl]="form.get('range3')?.get('min')"
            label="Minimum"
          ></bb-input-number-ui>
          <bb-input-validation-message-ui
            [showErrors]="showErrors('range3', 'min')"
            [id]="'input-range-error-three-min'"
          >
            @if (hasError('range3', 'min'); as min) {
              <span>Minimum value is {{ min.min }}</span>
            }
          </bb-input-validation-message-ui>
        </div>
        <div>
          <bb-input-number-ui
            [aria-describedby]="'input-range-error-three-max'"
            [aria-invalid]="!isFieldValid(form.get('range3')?.get('max'))"
            aria-errormessage="'input-range-error-two-max'"
            bbInputRangeMax
            [formControl]="form.get('range3')?.get('max')"
            label="Maximum"
          ></bb-input-number-ui>
          <bb-input-validation-message-ui
            [showErrors]="showErrors('range3', 'max')"
            [id]="'input-range-error-three-max'"
          >
            @if (hasError('range3', 'max'); as max) {
              <span>Maximum value is {{ max.max }}</span>
            }
          </bb-input-validation-message-ui>
        </div>
      </div>
    </div>

    <div class="bb-block--md">
      @if (form.enabled) {
        <bb-button-ui (click)="form.disable()">Disable</bb-button-ui>
      } @else {
        <bb-button-ui (click)="form?.enable()">Enable</bb-button-ui>
      }
      <ng-template #enable>
        <bb-button-ui (click)="form?.enable()">Enable</bb-button-ui>
      </ng-template>
    </div>

    <div class="form-group mb-3">
      <h3>Range 4</h3>
      <div bbInputRange class="bb-stack bb-stack--align-top">
        <div class="bb-stack__item">
          <bb-input-number-ui
            name="range4Min"
            [bbInputRangeMin]="form.get('range3')?.value?.min"
            [(ngModel)]="range4Min"
            [aria-invalid]="range4Min < form.get('range3')?.value?.min"
            aria-errormessage="'input-range-error-four-min'"
            label="Range with template driven form (min)"
          ></bb-input-number-ui>
          <bb-input-validation-message-ui
            [showErrors]="range4Min < form.get('range3')?.value?.min"
            [id]="'input-range-error-four-min'"
          >
            <span>Maximum value is {{ form.get('range3')?.value?.min }}</span>
          </bb-input-validation-message-ui>
        </div>
        <div class="bb-stack__item">
          <bb-input-number-ui
            name="range4Max"
            [bbInputRangeMax]="form.get('range3')?.value?.max"
            [(ngModel)]="range4Max"
            [aria-invalid]="range4Max < form.get('range3')?.value?.max"
            aria-errormessage="'input-range-error-four-max'"
            label="Range with template driven form (max)"
          ></bb-input-number-ui>
          <bb-input-validation-message-ui
            [showErrors]="range4Max < form.get('range3')?.value?.max"
            [id]="'input-range-error-four-max'"
          >
            <span>Maximum value is {{ form.get('range3')?.value?.max }}</span>
          </bb-input-validation-message-ui>
        </div>
      </div>
    </div>
  </div>
</div>
