export const tree: any = {
	'account-selector': [],
	alert: [
		'_anatomy_of_an_alert',
		'_placement',
		'_modifiers',
		'_dos_donts',
		'_interaction',
		'_responsiveness',
		'_accessibility',
	],
	amount: [
		'_format_types',
		'_whole_and_decimal_amounts',
		'_displaying_currencies',
		'_amounts_with_currency_symbol',
		'_amounts_with_currency_code',
		'_amounts_with_both_currency_code_and_symbol',
		'_negative_amounts',
		'_long_amounts',
		'_percentages',
	],
	avatar: [],
	button: [
		'_button_types',
		'_button_style',
		'_button_states',
		'_general_guidelines',
		'_hierarchy',
		'_text_labels',
		'_sizes',
		'_placement',
		'_responsiveness',
		'_additional_considerations',
		'_split_button',
		'_interaction',
		'_dos_donts',
		'_split_button_responsiveness',
		'_accessibility',
	],
	'card-vendor': [
		'_general_guidelines',
		'_anatomy_of_a_card_vendor',
		'_dos_donts',
		'_accessibility',
		'_responsiveness',
	],
	date: [
		'_format_types',
		'_simple_format',
		'_dates',
		'_time',
		'_dates_with_time',
		'_natural_language',
		'_time_and_date_ranges',
	],
	ellipsis: [
		'_general_guidelines',
		'_redacting_numbers',
		'_ssn_5678',
		'_visa_1234',
		'_dos_donts',
	],
	'grouped-by-area-charts': [
		'_general_guidelines',
		'_anatomy_of_area_charts',
		'_chart_colors',
		'_behaviour',
		'_dos_and_donts',
		'_responsiveness',
		'_accessibility',
	],
	'grouped-by-badges': [
		'_badge_types',
		'_badge',
		'_badge_counter',
		'_colors',
		'_content',
		'_modifiers',
		'_dos_donts',
		'_responsiveness',
		'_accessibility',
	],
	'grouped-by-collapsibles': [
		'_anatomy_of_an_accordion',
		'_general_guidelines',
		'_modifiers',
		'_collapsed_state',
		'_hover_state',
		'_expanded_state',
		'_dos_donts',
	],
	'grouped-by-dropdowns': [
		'_anatomy_of_a_dropdown_menu',
		'_dropdown_variations',
		'_dropdown_triggers',
		'_dropdown_lists',
		'_general_guidelines',
	],
	'grouped-by-forms': [
		'_anatomy_of_a_form',
		'_general_guidelines',
		'_error_handling_and_validation',
		'_fieldset_and_legend',
		'_form_controls',
		'_text_field',
		'_text_area',
		'_number_input',
		'_date_picker',
		'_selectors',
		'_radio_buttons',
		'_checkboxes',
		'_switch',
		'_select_input',
		'_complex_inputs',
		'_input_group',
		'_amount_input',
		'_modifiers',
		'_accessibility',
		'_focus_state',
		'_error_handling',
	],
	'grouped-by-layouts': [
		'_layout_and_navigation',
		'_app_layout_sections',
		'_horizontal_navigation',
		'_horizontal_navigation_design_examples',
		'_large_screen_with_collapsed_menu',
		'_large_screen_with_menu_item_expanded',
		'_small_screen_with_collapsed_menu',
		'_small_screen_with_menu_expanded',
		'_small_screen_with_menu_item_expanded',
		'_vertical_navigation',
		'_vertical_navigation_design_examples',
		'_large_screen_with_collapsed_menu_2',
		'_large_screen_with_menu_expanded',
		'_small_screen_with_collapsed_menu_2',
		'_small_screen_with_expanded_menu',
	],
	'grouped-by-loaders': [
		'_placement',
		'_page_loading',
		'_dos_donts',
		'_interaction',
		'_responsiveness',
		'_accessibility',
	],
	'grouped-by-notifications': [
		'_anatomy_of_a_notification',
		'_notification_types',
		'_placement',
		'_modifiers',
		'_notification_states',
		'_dos_donts',
		'_interaction',
		'_behavior',
		'_responsiveness',
		'_accessibility',
	],
	'grouped-by-product-items': [
		'_anatomy_of_a_product_item',
		'_modifiers',
		'_responsiveness',
	],
	'grouped-by-states': [
		'_anatomy_of_an_empty_state',
		'_placement',
		'_content_guidelines',
		'_modifiers',
		'_dos_donts',
		'_use_when',
		'_dont_use',
		'_responsiveness',
		'_accessibility',
	],
	header: [
		'_dos_donts',
	],
	iban: [
		'_grouping_iban_digits',
	],
	icon: [
		'_modifiers',
		'_dos_donts',
		'_accessibility',
	],
	'infinite-scroll': [
		'_these_guidelines_are_in_progress',
	],
	'item-log': [
		'_general_guidelines',
		'_anatomy',
		'_modifiers',
		'_placement',
		'_animation',
		'_variations',
		'_responsiveness',
		'_dos_donts',
	],
	logo: [],
	modal: [
		'_anatomy_of_a_modal',
		'_general_guidelines',
		'_modal_sizes',
		'_dos_donts',
		'_interaction',
		'_accessibility',
	],
	pagination: [
		'_general_guidelines',
		'_behaviors',
		'_placement',
		'_dos_donts',
		'_do',
		'_dont',
		'_responsiveness',
		'_native_mobile',
	],
	'payment-card': [
		'_anatomy_of_a_payment_card',
		'_modifiers',
		'_card_logo',
		'_card_vendor',
		'',
		'_2',
		'_responsiveness',
		'_resources',
	],
	'payment-card-number': [
		'_grouping_card_numbers',
		'_masking_card_numbers',
	],
	'period-selector': [],
	'phone-number': [
		'_format',
		'_phone_number_styling',
	],
	'product-selector': [
		'_high_level_anatomy',
		'_detailed_anatomy',
		'_trigger_button',
		'_deselected_state',
		'_selected_state',
		'_product_list',
		'_list_item_states',
		'_modifiers',
		'_list_with_section_headings',
		'_dos_donts',
		'_interaction',
		'_triggering_the_product_list',
		'_dismissing_the_product_list',
		'_navigating_the_product_list',
		'_scrolling',
		'_selecting_a_product',
		'_responsiveness',
		'_trigger_button_2',
		'_product_list_items',
		'_accessibility',
		'_trigger_button_3',
		'_product_information_labeling',
		'_selecting_a_product_item',
		'_color_contrast',
	],
	progressbar: [
		'_anatomy_of_a_progress_bar',
		'_general_guidelines',
		'_placement',
		'_sizes',
		'_colors',
		'_stripes',
		'_dos_donts',
		'_use_when',
		'_dont_use_when',
		'_behaviour',
		'_responsiveness',
		'_accessibility',
	],
	'rich-text-editor': [],
	'search-box': [
		'_general_guidelines',
		'_dos_donts',
		'_accessibility',
	],
	stepper: [
		'_general_guidelines',
		'_anatomy_of_a_stepper',
		'_types_of_steppers',
		'_placement',
		'_interaction',
		'_dos_and_donts',
		'_responsiveness',
		'_accessibility',
	],
	tab: [
		'_anatomy_of_a_tab_component',
		'_behavior',
		'_style_guidelines',
		'_content_guidelines',
		'_best_practices',
		'_modifiers',
		'_dos_donts',
		'_responsiveness',
		'_accessibility',
		'_considerations_for_screen_readers',
	],
	table: [
		'_general_guidelines',
		'_dos_donts',
	],
	tooltip: [
		'_general_guidelines',
		'_dos_donts',
		'_behavior',
		'_responsiveness',
	],
};
