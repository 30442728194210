import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProductItemBasicAccountModule } from '@backbase/ui-ang/product-item-basic-account';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { InputCheckboxModule } from '@backbase/ui-ang/input-checkbox';
import { SearchBoxModule } from '@backbase/ui-ang/search-box';
import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';
import { ButtonModule } from '@backbase/ui-ang/button';
import {
  AccountSelectorComponent,
  CustomGroupItemsHeaderDirective,
  CustomLoadingTemplateDirective,
  CustomMultiSelectedItemsLabelDirective,
  CustomOptionItemDirective,
  CustomOptionsHeaderDirective,
  CustomSingleSelectedItemLabelDirective,
} from './account-selector.component';
import { EllipsisModule } from '@backbase/ui-ang/ellipsis';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  imports: [
    NgClass,
    NgTemplateOutlet,
    NgSelectModule,
    FormsModule,
    ProductItemBasicAccountModule,
    InputTextModule,
    InputCheckboxModule,
    SearchBoxModule,
    LoadingIndicatorModule,
    ButtonModule,
    EllipsisModule,
    KeyboardClickModule,
  ],
  declarations: [
    AccountSelectorComponent,
    CustomGroupItemsHeaderDirective,
    CustomLoadingTemplateDirective,
    CustomMultiSelectedItemsLabelDirective,
    CustomOptionItemDirective,
    CustomOptionsHeaderDirective,
    CustomSingleSelectedItemLabelDirective,
  ],
  exports: [
    AccountSelectorComponent,
    CustomGroupItemsHeaderDirective,
    CustomLoadingTemplateDirective,
    CustomMultiSelectedItemsLabelDirective,
    CustomOptionItemDirective,
    CustomOptionsHeaderDirective,
    CustomSingleSelectedItemLabelDirective,
  ],
})
export class AccountSelectorModule {}
