import { Component, OnDestroy } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-progressbar-ui-showcase',
  templateUrl: './progressbar-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ProgressbarShowcaseComponent implements OnDestroy {
  animate = true;
  value = 50;
  fulfilledValue = 100;
  interval: any;

  constructor() {}

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  incrementProgress() {
    if (!this.interval) {
      this.interval = setInterval(() => {
        if (this.value < 100) {
          this.value += 5;
        } else if (this.value >= 100) {
          this.value = Math.round(Math.random() * 100);
        }
      }, 300);
    } else {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }
}
