<div class="bb-product-selector">
  <div
    ngbDropdown
    #bbDropdown="ngbDropdown"
    [autoClose]="autoClose"
    [container]="container"
    (blur)="onBlur()"
    (openChange)="onOpenChange($event)"
    [placement]="position"
    [open]="isOpen"
    class="bb-product-selector__dropdown"
  >
    <button
      ngbDropdownToggle
      #dropdownToggle
      bbButton
      color="unstyled"
      class="bb-product-selector__item"
      role="combobox"
      [attr.data-role]="'product-selector-expand-button'"
      type="button"
      [attr.aria-controls]="isOpen ? dropdownId : null"
      [attr.aria-describedby]="ariaDescribedby"
      [attr.aria-label]="ariaLabelForToggleButton"
      [attr.data-id]="id ? id + '_product-selector-toggle' : undefined"
    >
      <div class="bb-product-selector__item-content" [attr.data-role]="'product-selector-selected-content'">
        <ng-content select="[bbDropdownToggle]"></ng-content>
      </div>
      <bb-icon-ui cropped class="bb-product-selector__icon" [name]="isOpen ? 'toggle-up' : 'toggle-down'"></bb-icon-ui>
    </button>
    <div
      ngbDropdownMenu
      class="bb-product-selector__dropdown-menu-panel"
      [attr.aria-label]="ariaLabelForDropdown"
      [attr.id]="dropdownId"
      role="listbox"
      (bbKeyboardClick)="onClose()"
      [attr.data-role]="'product-selector-dropdown-menu'"
      [attr.aria-activedescendant]="ariaActivedescendant"
    >
      <div class="bb-product-selector__dropdown-menu">
        <ng-content select="[bbDropdownMenu]"></ng-content>
      </div>
    </div>
  </div>
</div>
