<label
  [attr.for]="id"
  class="bb-input-checkbox form-label"
  [ngClass]="{ 'ng-invalid': parentFormControl?.invalid, 'ng-touched': parentFormControl?.touched }"
>
  <input
    #input
    type="checkbox"
    class="bb-input-checkbox__input"
    [attr.id]="id"
    [attr.aria-describedby]="ariaDescribedby"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-expanded]="ariaExpanded"
    [attr.role]="role"
    [attr.aria-invalid]="ariaInvalid"
    [attr.aria-owns]="ariaOwns"
    [attr.aria-activedescendant]="ariaActivedescendant"
    [disabled]="disabled"
    [required]="required"
    [indeterminate]="indeterminate"
    [attr.aria-label]="(!!label ? label : ariaLabel) || null"
    [(ngModel)]="value"
    (change)="onValueChange()"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    [attr.data-role]="'checkbox-input'"
    [attr.hidden]="hideCheckbox ? true : null"
  />
  <span
    class="bb-input-checkbox__content"
    [attr.data-role]="'checkbox-label'"
    [attr.aria-hidden]="label || ariaLabel ? 'true' : 'false'"
  >
    @if (label) {
      {{ label }}
    } @else {
      <ng-content></ng-content>
    }
  </span>
  @if (indeterminate || value) {
    <span class="bb-input-checkbox__content-icon" aria-hidden="true"></span>
  }
</label>
