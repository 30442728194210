import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'titleclean' })
export class TitleCleanPipe implements PipeTransform {
  transform(value: any) {
    return value
      .replace(/(1-variables|2-tools|3-generic|4-elements|5-components|6-utilities').+\/(bb-)?(.+)/, '$3')
      .replace(/((product-item)(?!s))/, '')
      .replace(/((-pipe)(?!s))/, '')
      .replace(/[!@#$%^*(),.?":{}|\-_<>]/g, ' ')
      .replace(/(message)/gi, '')
      .replace(/^[ \t]+/g, '')
      .replace(/(iban)/gi, 'IBAN')
      .replace(/(ios)/gi, 'iOS')
      .replace(/(ux)/gi, 'UX')
      .replace(/(wcag)/gi, 'WCAG')
      .replace(/(omni channel)/gi, 'omni-channel');
  }
}
