<div
  class="bb-notification"
  [ngClass]="dynamicClassNames"
  data-role="notification-alert"
  #notificationContent
  [bbFocus]="autofocus"
  [attr.data-autofocus]="autofocus"
  [cdkTrapFocus]="autofocus || hasPrimaryAction() || hasSecondaryAction()"
  [attr.tabindex]="hostTabIndex"
  (bbKeyboardClick)="onNotificationClick()"
  [attr.aria-label]="autofocus ? modifier + 'Notification' : modifier"
>
  <bb-icon-ui
    [name]="modifier | notificationIcon"
    [color]="modifier | notificationIconColor"
    class="bb-notification__icon"
    [attr.data-role]="'notification-icon'"
  ></bb-icon-ui>
  <div class="bb-notification__body">
    <div class="bb-notification__title" data-role="notification-heading">
      <ng-container *ngTemplateOutlet="getHeaderTemplate(); context: headerContext"></ng-container>
    </div>
    <div data-role="notification-message" class="bb-text-support">
      <ng-container *ngTemplateOutlet="getMessageTemplate(); context: messageContext"></ng-container>
    </div>
    @if (primaryActionTemplate || primaryActionText || secondaryActionTemplate || secondaryActionText) {
      <div data-role="notification-actions">
        <div class="bb-button-bar">
          @if (hasPrimaryAction()) {
            <button
              bbButton
              type="button"
              data-role="notification-primary-button"
              class="bb-button-bar__button"
              (click)="onPrimaryPress()"
            >
              @if (primaryActionTemplate) {
                <ng-container *ngTemplateOutlet="primaryActionTemplate; context: this"></ng-container>
              } @else {
                {{ primaryActionText }}
              }
            </button>
          }
          @if (hasSecondaryAction()) {
            <button
              bbButton
              color="secondary"
              data-role="notification-secondary-button"
              class="bb-button-bar__button"
              (click)="onSecondaryPress()"
            >
              @if (secondaryActionTemplate) {
                <ng-container *ngTemplateOutlet="secondaryActionTemplate; context: this"></ng-container>
              } @else {
                {{ secondaryActionText }}
              }
            </button>
          }
        </div>
      </div>
    }
  </div>
  @if (dismissible) {
    <button
      bbButton
      color="dark"
      [circle]="true"
      buttonSize="sm"
      data-role="notification-close-button"
      class="bb-notification__close"
      [attr.tabindex]="autofocus ? 0 : -1"
      aria-label="Close"
      i18n-aria-label="Close notification button| Text for the close notification button @@bb-notification-ui.close"
      (click)="onNotificationClose()"
    >
      <bb-icon-ui name="clear"></bb-icon-ui>
    </button>
  }
</div>

<ng-template #defaultNotificationHeaderTemplate>
  <div [ngClass]="{ 'bb-block bb-block--sm break-word': !!header }">
    {{ header }}
  </div>
</ng-template>

<ng-template #defaultNotificationMessageTemplate>
  <div [ngClass]="{ 'break-word': !!message }">
    {{ message }}
  </div>
</ng-template>
