<form [formGroup]="vForm">
  <div class="bb-block bb-block--lg">
    <h4>Default</h4>
    <bb-input-file-ui
      label="Default file input"
      (change)="onUpload($event)"
      formControlName="fileInput1"
      data-role="file-input-default"
    >
    </bb-input-file-ui>
    <h4>Multiple</h4>
    <bb-input-file-ui
      label="Multiple files input"
      (change)="onUpload($event)"
      formControlName="fileInput2"
      data-role="file-input-multiple"
      multiple="true"
    >
    </bb-input-file-ui>
    <h4>Custom button color</h4>
    <div class="bb-stack">
      <bb-input-file-ui
        class="bb-stack__item"
        label="File input primary"
        (change)="onUpload($event)"
        color="primary"
        formControlName="fileInput3"
        data-role="file-input-primary"
      >
      </bb-input-file-ui>
      <bb-input-file-ui
        class="bb-stack__item"
        label="File input danger"
        (change)="onUpload($event)"
        color="danger"
        formControlName="fileInput4"
        data-role="file-input-danger"
      >
      </bb-input-file-ui>
    </div>
    <h4>Accept only image formats, custom icon and text</h4>
    <bb-input-file-ui
      label="IMG file input"
      accept=".png, .jpg, .jpeg, .jpeg"
      (change)="onUpload($event)"
      iconName="image"
      buttonText="Upload image"
      formControlName="fileInput5"
      data-role="file-input-image"
    >
    </bb-input-file-ui>
    <h4>File size limited to 10 Mbs</h4>
    <bb-input-file-ui
      maxSizeInMb="10"
      label="File input limited to 10 Mbs"
      (change)="onUpload($event)"
      formControlName="fileInput6"
      data-role="file-input-image-with-size-limit"
    ></bb-input-file-ui>

    <h4>Disabled</h4>
    <bb-input-file-ui
      label="Disabled file input"
      disabled="true"
      (change)="onUpload($event)"
      formControlName="fileInput7"
      data-role="file-input-disabled"
    >
    </bb-input-file-ui>
  </div>
</form>
