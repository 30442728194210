import { NgModule } from '@angular/core';

import { ProgressbarComponent } from './progressbar.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [NgbProgressbarModule],
  declarations: [ProgressbarComponent],
  exports: [ProgressbarComponent],
})
export class ProgressbarModule {}
