import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bb-pagination-page-ui',
  templateUrl: './bb-pagination-page-ui.component.html',
})
export class BbPaginationUiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
