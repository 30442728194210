import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'bb-showcase-methods',
  templateUrl: './showcase-methods.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowcaseMethodsComponent {
  @Input() methods: { name: string; args: { name: string }[]; returnType: string; description: string }[] = [];
}
