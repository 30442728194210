import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentCardNumberPipeShowcaseComponent } from './payment-card-number-pipe-showcase.component';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { InputNumberModule } from '@backbase/ui-ang/input-number';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, PaymentCardNumberModule, InputNumberModule, FormsModule],
  declarations: [PaymentCardNumberPipeShowcaseComponent],
})
export class PaymentCardNumberPipeShowcaseModule {}
