import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IllustrationShowcaseComponent } from './illustration-showcase.component';
import { IllustrationModule } from '@backbase/ui-ang/illustration';

@NgModule({
  imports: [CommonModule, IllustrationModule],
  declarations: [IllustrationShowcaseComponent],
})
export class IllustrationShowcaseModule {}
