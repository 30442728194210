@if (label) {
  <label for="{{ id }}" class="form-label"> {{ label }} </label>
}
<div
  class="bb-amount-input__field bb-force-ltr"
  [ngClass]="wrapperClasses"
  [class.bb-amount-input__field--with-currency]="currency && !swapCurrencyAndValue"
  [class.bb-amount-input__field--with-currency--swap]="currency && swapCurrencyAndValue"
>
  <input
    id="{{ id }}"
    type="text"
    inputmode="decimal"
    class="form-control"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    data-role="bb-amount-input-ui"
    #amountInput
    [attr.maxlength]="maxLength"
    [readOnly]="readonly"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="placeholder"
    [attr.aria-describedby]="idListAttr(ariaDescribedby, currency ? componentUniqueId : null)"
    [attr.aria-invalid]="ariaInvalid"
    [attr.aria-labelledby]="idListAttr(ariaLabelledby, textHint ? textHintId : null)"
    [class.bb-text-align-end]="!swapCurrencyAndValue"
    (keydown)="onPress($event)"
    (keydown.backspace)="onBackspace(amountInput)"
    (blur)="correctInputValue()"
    (input)="onInput()"
  />
  @if (currency) {
    <span
      data-role="bb-amount-input-currency"
      [id]="componentUniqueId"
      [class.bb-amount-input__symbol]="!swapCurrencyAndValue"
      [class.bb-amount-input__symbol--swap]="swapCurrencyAndValue"
    >
      <span
        class="visually-hidden"
        i18n="
          Currency|Text to explain that currency will be read by screen reader@@bb-amount-input-ui.currency-description"
        >Currency</span
      >
      {{ currency | bbAmountInputCurrencyRemap: mapCurrency }}
    </span>
  }
</div>
@if (textHint) {
  <div
    class="bb-d-flex bb-amount-input__text-hint"
    data-role="bb-amount-input-text-hint"
    i18n="Text hint for input field@@bb-amount-input-ui.text-hint"
    [id]="textHintId"
  >
    <span class="overflow-hidden">{{ textHint }}</span>
  </div>
}
