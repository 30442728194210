import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';
import { ProductItem } from '@backbase/ui-ang/product-selector';

@Component({
  selector: 'bb-product-item-basic-account-example-ui',
  templateUrl: './product-item-basic-account.example.component.html',
})
export class ProductItemBasicAccountExampleComponent {
  product: ProductItem = {
    id: 'b387bfa5-75fd-410e-bb86-7f764f8ef69c',
    name: 'Ricardo Norton’s Savings Account',
    currency: 'EUR',
    amount: '83652.32',
    productNumber: 'NL75INGB3400120056',
  };

  productNumberFormat: PaymentCardNumberFormat = {
    length: 18,
    maskRange: [0, 0],
    segments: 4,
  };
}
