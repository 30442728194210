import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressTrackerShowcaseComponent } from './progress-tracker-showcase.component';
import { ProgressTrackerModule } from '@backbase/ui-ang/progress-tracker';

@NgModule({
  imports: [CommonModule, ProgressTrackerModule],
  declarations: [ProgressTrackerShowcaseComponent],
})
export class ProgressTrackerShowcaseModule {}
