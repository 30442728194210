import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberShowcaseComponent } from './input-number-showcase.component';
import { InputNumberModule } from '@backbase/ui-ang/input-number';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, InputNumberModule, InputValidationMessageModule, ReactiveFormsModule],
  declarations: [InputNumberShowcaseComponent],
})
export class InputNumberShowcaseModule {}
