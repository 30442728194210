<form [formGroup]="form">
  <div class="bb-block bb-block--xl">
    <bb-select-list-ui
      [id]="'select-list'"
      formControlName="singleSelect"
      placeholder="Start typing. e.g. single"
      [items]="singleSelectItems"
      minTermLength="3"
      label="Single Select"
    >
    </bb-select-list-ui>
  </div>

  <div class="bb-block bb-block--xl">
    <bb-select-list-ui
      [id]="'select-list2'"
      formControlName="singleSelect"
      placeholder="Start typing. e.g. single"
      [items]="singleSelectItems"
      minTermLength="3"
      label="Embed label"
    >
    </bb-select-list-ui>
  </div>

  <div class="bb-block bb-block--xl">
    <bb-select-list-ui
      [id]="'select-list-multi'"
      formControlName="multipleSelect"
      placeholder="Start typing. e.g. multi"
      [items]="multiSelectItems"
      minTermLength="3"
      [multiple]="true"
      maxSelectedItems="3"
      label="Multiple Select - Max. 3 selections"
    >
    </bb-select-list-ui>
  </div>

  <div class="bb-block bb-block--xl">
    <bb-select-list-ui
      [id]="'select-list-multi-destinations'"
      formControlName="multipleSelectDestinations"
      placeholder="Start typing. e.g. germany"
      [items]="countries"
      minTermLength="3"
      [multiple]="true"
      label="Multiple Select - Non-Standard Data Structure"
    >
    </bb-select-list-ui>
  </div>

  <div class="bb-block bb-block--xl">
    <bb-select-list-ui
      [id]="'select-list-multi-observable'"
      formControlName="multipleSelectObservable"
      placeholder="Start typing. e.g. item"
      [items]="itemsObservable$ | async"
      minTermLength="3"
      [multiple]="true"
      label="Multiple Select - Items from Observable"
    >
    </bb-select-list-ui>
  </div>

  <div class="bb-block bb-block--xl">
    <bb-select-list-ui
      [id]="'select-list-search-on-load'"
      label="Single Select - Dynamic items list, retrieved on page load"
      formControlName="searchOnLoad"
      placeholder="Click to open dropdown, items are loaded when page is. Typing will return back the input value"
      [items]="searchOnLoadItems$ | async"
      [filterEnabled]="false"
      minTermLength="3"
      [loading]="loading | async"
      (search)="searchOnLoad($event)"
    >
    </bb-select-list-ui>
  </div>

  <div class="bb-block bb-block--xl">
    <bb-select-list-ui
      [id]="'select-list-search-after-value'"
      label="Single Select - Dynamic items list, retrieved after first search"
      formControlName="searchAfterValue"
      placeholder="Items are loaded when you search. Typing will return back the input value"
      [items]="searchAfterValueItems$ | async"
      [filterEnabled]="false"
      minTermLength="3"
      (search)="searchAfterValue($event)"
    >
    </bb-select-list-ui>
  </div>
</form>
