@for (accountSelector of accountSelectors; track $index) {
  <div class="bb-block bb-block--xl">
    <h4 [id]="accountSelector.labelId">{{ accountSelector.heading }}</h4>
    <bb-account-selector-ui
      [disableScrollEnd]="false"
      [items]="accountSelector.items"
      [multiple]="accountSelector.multiple"
      (change)="accountSelector.change(accountSelector, $event)"
      (clearSearch)="accountSelector.clearSearch(accountSelector, $event)"
      [selectedItems]="accountSelector.selectedItems"
      (scrollToEnd)="accountSelector.scrollToEnd(accountSelector, $event)"
      (search)="accountSelector.search(accountSelector, $event)"
      [closeOnSelect]="accountSelector.closeOnSelect"
      [showCurrencySymbol]="accountSelector.showCurrencySymbol"
      [dropdownPosition]="accountSelector.position"
      [groupByFn]="groupByItems"
      [placeholder]="accountSelector.placeholder"
      [disabled]="accountSelector.disabled"
      [notFoundText]="accountSelector.notFoundText"
      [productNumberFormat]="accountSelector.productNumberFormat"
      [highlight]="false"
      [aria-describedby]="accountSelector.errorMessageId"
      [aria-invalid]="accountSelector.invalid"
      [attr.data-role]="accountSelector.dataRole"
      [showBankBranchCode]="accountSelector.showBankBranchCode"
      [typeahead]="accountSelector.typeahead"
      [minTermLength]="accountSelector.minTermLength"
      [typeToSearchText]="accountSelector.typeToSearchText"
      [filterItems]="accountSelector.filterItems"
      [showAvailableBalance]="accountSelector.showAvailableBalance"
    >
    </bb-account-selector-ui>
    <p [attr.id]="accountSelector.errorMessageId">{{ accountSelector.errorMessage }}</p>
  </div>
}
<div class="bb-block bb-block--xl">
  <h4>Showcase with custom search through 'number' and 'balance' fields (small size)</h4>
  <div class="row">
    <div class="col-md-6">
      <bb-account-selector-ui
        [searchFunc]="searchFunc"
        [disableScrollEnd]="false"
        [items]="accountSelectors[0].items"
        [multiple]="accountSelectors[0].multiple"
        (change)="accountSelectors[0].change(accountSelectors[0], $event)"
        (clearSearch)="accountSelectors[0].clearSearch(accountSelectors[0], $event)"
        [selectedItems]="accountSelectors[0].selectedItems"
        (scrollToEnd)="accountSelectors[0].scrollToEnd(accountSelectors[0], $event)"
        (search)="accountSelectors[0].search(accountSelectors[0], $event)"
        [closeOnSelect]="false"
        [dropdownPosition]="accountSelectors[0].position"
        [groupByFn]="groupByItems"
        [placeholder]="accountSelectors[0].placeholder"
        [productNumberFormat]="accountSelectors[0].productNumberFormat"
        [highlight]="false"
        data-role="account-selector-number-one"
        size="sm"
      >
      </bb-account-selector-ui>
    </div>
    <div class="col-md-6">
      <bb-account-selector-ui
        [searchFunc]="searchFunc"
        [disableScrollEnd]="false"
        [items]="accountSelectors[0].items"
        [multiple]="accountSelectors[0].multiple"
        (change)="accountSelectors[0].change(accountSelectors[0], $event)"
        (clearSearch)="accountSelectors[0].clearSearch(accountSelectors[0], $event)"
        [selectedItems]="accountSelectors[0].selectedItems"
        (scrollToEnd)="accountSelectors[0].scrollToEnd(accountSelectors[0], $event)"
        (search)="accountSelectors[0].search(accountSelectors[0], $event)"
        [closeOnSelect]="false"
        [dropdownPosition]="accountSelectors[0].position"
        [groupByFn]="groupByItems"
        [placeholder]="accountSelectors[0].placeholder"
        [productNumberFormat]="accountSelectors[0].productNumberFormat"
        [highlight]="false"
        data-role="account-selector-number-two"
        size="sm"
      >
      </bb-account-selector-ui>
    </div>
  </div>
</div>
<div class="bb-block bb-block--xl">
  <h4>Account selector without groupBy function</h4>
  <bb-account-selector-ui
    [disableScrollEnd]="false"
    [items]="accountSelectors[0].items"
    [multiple]="accountSelectors[0].multiple"
    (change)="accountSelectors[0].change(accountSelectors[0], $event)"
    (clearSearch)="accountSelectors[0].clearSearch(accountSelectors[0], $event)"
    [selectedItems]="accountSelectors[0].selectedItems"
    (scrollToEnd)="accountSelectors[0].scrollToEnd(accountSelectors[0], $event)"
    (search)="accountSelectors[0].search(accountSelectors[0], $event)"
    [closeOnSelect]="accountSelectors[0].closeOnSelect"
    [dropdownPosition]="accountSelectors[0].position"
    [placeholder]="accountSelectors[0].placeholder"
    [disabled]="false"
    [productNumberFormat]="accountSelectors[0].productNumberFormat"
    [highlight]="false"
    data-role="account-selector-without-groupBy"
  >
  </bb-account-selector-ui>
</div>

<div class="bb-block bb-block--xl">
  <h4 [id]="accountSelectorValidation.labelId">{{ accountSelectorValidation.heading }}</h4>
  <form action="" (ngSubmit)="accountSelectorValidation.submit(accountSelectorValidation)">
    <bb-account-selector-ui
      #accountSelectorWithValidation
      [disableScrollEnd]="false"
      [items]="accountSelectorValidation.items"
      [multiple]="accountSelectorValidation.multiple"
      (change)="accountSelectorValidation.change(accountSelectorValidation, $event)"
      (clearSearch)="accountSelectorValidation.clearSearch(accountSelectorValidation, $event)"
      [selectedItems]="accountSelectorValidation.selectedItems.value"
      (scrollToEnd)="accountSelectorValidation.scrollToEnd(accountSelectorValidation, $event)"
      (search)="accountSelectorValidation.search(accountSelectorValidation, $event)"
      [closeOnSelect]="accountSelectorValidation.closeOnSelect"
      [dropdownPosition]="accountSelectorValidation.position"
      [groupByFn]="groupByItems"
      [placeholder]="accountSelectorValidation.placeholder"
      [disabled]="false"
      [productNumberFormat]="accountSelectorValidation.productNumberFormat"
      [highlight]="false"
      (blur)="accountSelectorValidation.onBlur(accountSelectorValidation)"
      [required]="accountSelectorValidation.required"
      [showError]="accountSelectorValidation.showError"
      [attr.data-role]="accountSelectorValidation.dataRole"
      [aria-describedby]="
        accountSelectorValidation.showError || accountSelectorValidation.selectedItems?.touched
          ? 'accountSelectorValidationerrorMessageId'
          : ''
      "
      [aria-invalid]="accountSelectorValidation.showError"
    >
    </bb-account-selector-ui>
    <bb-input-validation-message-ui
      role="alert"
      [showErrors]="accountSelectorValidation.showError"
      [messageId]="'accountSelectorValidationerrorMessageId'"
    >
      @if (accountSelectorValidation.selectedItems?.errors?.required) {
        <span data-role="account-selector-required-validation-message"> Account is required. </span>
      }
    </bb-input-validation-message-ui>
    <div class="text-end">
      <button
        type="submit"
        data-role="account-selector-required-validation-submit"
        bbButton
        class="m-2 btn-primary btn-sm btn"
        color="primary"
      >
        Submit
      </button>
      <button
        (click)="accountSelectorValidation.reset(accountSelectorValidation)"
        data-role="account-selector-required-validation-reset"
        bbButton
        class="m-2 btn-secondary btn-sm btn"
        color="primary"
      >
        Reset
      </button>
    </div>
  </form>
</div>

<div class="bb-block bb-block--xl">
  <h4 [id]="accountSelectorTypeAhead.labelId">
    {{ accountSelectorTypeAhead.heading }} - TypeaheadSearchTerm: {{ typeaheadSearchTerm }}
  </h4>
  <bb-account-selector-ui
    #accountSelectorTypeahead
    [disableScrollEnd]="false"
    [items]="typeaheadItems$ | async"
    [multiple]="accountSelectorTypeAhead.multiple"
    (change)="accountSelectorTypeAhead.change(accountSelectorTypeAhead, $event)"
    (clearSearch)="accountSelectorTypeAhead.clearSearch(accountSelectorTypeAhead, $event)"
    (scrollToEnd)="accountSelectorTypeAhead.scrollToEnd(accountSelectorTypeAhead, $event)"
    (search)="accountSelectorTypeAhead.search(accountSelectorTypeAhead, $event)"
    [closeOnSelect]="accountSelectorTypeAhead.closeOnSelect"
    [dropdownPosition]="accountSelectorTypeAhead.position"
    [groupByFn]="groupByItems"
    [placeholder]="accountSelectorTypeAhead.placeholder"
    [disabled]="false"
    [productNumberFormat]="accountSelectorTypeAhead.productNumberFormat"
    [highlight]="false"
    [attr.data-role]="accountSelectorTypeAhead.dataRole"
    [typeahead]="accountSelectorTypeAhead.typeahead"
    [minTermLength]="accountSelectorTypeAhead.minTermLength"
    [typeToSearchText]="accountSelectorTypeAhead.typeToSearchText"
  >
  </bb-account-selector-ui>
</div>

<div class="bb-block bb-block--xl">
  <h4 [id]="accountSelectorWithDisabledSearch.labelId">Single select with disabled search</h4>
  <bb-account-selector-ui
    [disableScrollEnd]="false"
    [items]="typeaheadItems$ | async"
    [multiple]="accountSelectorWithDisabledSearch.multiple"
    (change)="accountSelectorWithDisabledSearch.change(accountSelectorWithDisabledSearch, $event)"
    (clearSearch)="accountSelectorWithDisabledSearch.clearSearch(accountSelectorWithDisabledSearch, $event)"
    (scrollToEnd)="accountSelectorWithDisabledSearch.scrollToEnd(accountSelectorWithDisabledSearch, $event)"
    (search)="accountSelectorWithDisabledSearch.search(accountSelectorWithDisabledSearch, $event)"
    [closeOnSelect]="accountSelectorWithDisabledSearch.closeOnSelect"
    [dropdownPosition]="accountSelectorWithDisabledSearch.position"
    [groupByFn]="groupByItems"
    [placeholder]="accountSelectorWithDisabledSearch.placeholder"
    [disabled]="false"
    [productNumberFormat]="accountSelectorWithDisabledSearch.productNumberFormat"
    [highlight]="false"
    [searchable]="accountSelectorWithDisabledSearch.searchable"
    [attr.data-role]="accountSelectorWithDisabledSearch.dataRole"
    [typeahead]="accountSelectorWithDisabledSearch.typeahead"
    [minTermLength]="accountSelectorWithDisabledSearch.minTermLength"
    [typeToSearchText]="accountSelectorWithDisabledSearch.typeToSearchText"
  >
  </bb-account-selector-ui>
</div>
