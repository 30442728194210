<bb-dropdown-menu-ui
  container="body"
  position="bottom"
  icon="caret-down"
  btnColor="primary"
  iconSize="lg"
  [options]="dropdownMenuOptionsLabel"
  (select)="onButtonDropdownSelect($event)"
>
  <ng-template bbDropdownLabel>
    <bb-icon-ui name="time" size="md"></bb-icon-ui>
    &nbsp;
    <span>Snooze notification</span>
  </ng-template>
</bb-dropdown-menu-ui>
