import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { idListAttr } from '@backbase/ui-ang/util';

/**
 * @name InputTextComponent
 *
 * @description
 * Component that displays a text input.
 *
 * ### Accessibility
 * Current component provide option to pass needed accessibility
 * attributes. You need to take care of properties that are required in your case :
 *  - role
 *  - aria-activedescendant
 *  - aria-describedby
 *  - aria-expanded
 *  - aria-invalid
 *  - aria-label
 *  - aria-labelledby
 *  - aria-owns
 *
 */
@Component({
  selector: 'bb-input-text-ui',
  templateUrl: './input-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true,
    },
  ],
})
export class InputTextComponent extends InputBaseComponent implements AfterViewInit {
  /**
   * The placeholder for the text input. Defaults to an empty string;
   */
  @Input() placeholder = '';
  /**
   * The maxLength for the text input.
   */
  @Input() maxLength: number | undefined;
  /**
   * The minLength for the text input.
   */
  @Input() minLength: number | undefined;
  /**
   * A string specifying whether the browser should autocomplete the input.
   * Possible values: 'on', 'off', or any string that is not empty.
   */
  @Input() autocomplete: string | undefined = 'off';

  /**
   * Whether the text input is readonly.
   * If true, the input cannot be edited.
   * Defaults to false.
   */
  @Input() readonly = false;

  /**
   * ngx-mask related; default value imply no mask is used
   */
  @Input() mask = '';
  /**
   * ngx-mask related; default value imply validation is applied on mask pattern
   */
  @Input() maskValidator = true;

  /**
   * Shows character counter based on `maxLength`
   */
  @Input() showCharCounter = false;

  /**
   * Emits an event whenever the input value changes.
   */
  @Output() valueChange: EventEmitter<any> = this._valueChange;

  /**
   * The inputMode for the text input.
   */
  @Input() inputMode: string = 'text';

  /**
   * The ID of the character counter element.
   */
  public charCounterId: string;

  /**
   * A utility function for use in templates.
   * Generates a list of IDs for use with aria-labelledby or aria-describedby.
   */
  public idListAttr = idListAttr;

  /**
   * A utility function for use in templates.
   * Generates a list of IDs for use with aria-labelledby or aria-describedby.
   */
  @ViewChild('inputText') inputEl: ElementRef | undefined;
  constructor(
    private readonly domAttrService: DomAttributesService,
    private readonly elem: ElementRef,
    private readonly renderer2: Renderer2,
    protected readonly cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.charCounterId = this.domAttrService.generateId();
  }

  ngAfterViewInit(): void {
    if (!this.ariaLabel) {
      this.domAttrService.moveAriaAttributes(
        this.elem.nativeElement,
        this.inputEl && this.inputEl.nativeElement,
        this.renderer2,
      );
    }
  }

  focusEditableElement() {
    if (this.inputEl) {
      this.inputEl.nativeElement.focus();
    }
  }
}
