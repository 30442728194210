import { Component, OnInit, Input } from '@angular/core';
import { DesignTokenService } from '../../../services/design-token.service';

@Component({
  selector: 'bb-design-tokens',
  templateUrl: './design-tokens.component.html',
  styleUrls: ['./design-tokens.component.scss'],
})
export class DesignTokensComponent implements OnInit {
  @Input() tokenType!: string;

  designTokens: any;

  constructor(private designTokenService: DesignTokenService) {}

  private sortColorGroup(tokenA: any, tokenB: any) {
    const isChartColor = (token: any) => token.token.includes('chart');
    if (isChartColor(tokenA) && !isChartColor(tokenB)) {
      return 1;
    }

    if (!isChartColor(tokenA) && isChartColor(tokenB)) {
      return -1;
    }

    return tokenA.token === tokenB.token ? 0 : tokenA.token > tokenB.token ? 1 : -1;
  }

  ngOnInit() {
    this.designTokens = this.designTokenService.getTokens(this.tokenType).map((group: any) => {
      if (group.type === 'color') {
        group.tokens.sort(this.sortColorGroup);
      }

      return group;
    });
  }
}
