import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderShowcaseComponent } from './page-header-showcase.component';
import { PageHeaderModule } from '@backbase/ui-ang/page-header';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [CommonModule, PageHeaderModule, DropdownMenuModule, ButtonModule],
  declarations: [PageHeaderShowcaseComponent],
})
export class PageHeaderShowcaseModule {}
