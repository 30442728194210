import { NgModule } from '@angular/core';

import { InputBaseComponent } from './input-base.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    // temporary fix to resolve https://github.com/angular/angular/issues/48350
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
  ],
  declarations: [InputBaseComponent],
  exports: [InputBaseComponent],
})
export class InputBaseModule {}
