import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'bb-showcase-description',
  templateUrl: './showcase-description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowcaseDescriptionComponent {
  @Input() description = '';
}
