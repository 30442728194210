<bb-tabs></bb-tabs>
<bb-heading-container></bb-heading-container>
<div class="example-nav">
  <ul class="nav nav-tabs title-padding">
    @if (tabsShown.includes('android')) {
      <li class="nav-item">
        <button class="nav-link" routerLinkActive="active" routerLink="./android">Android</button>
      </li>
    }
    @if (tabsShown.includes('ios')) {
      <li class="nav-item">
        <button class="nav-link" routerLinkActive="active" routerLink="./ios">iOS</button>
      </li>
    }
    @if (tabsShown.includes('design')) {
      <li class="nav-item">
        <button class="nav-link" routerLinkActive="active" routerLink="./design">Design Guidelines</button>
      </li>
    }
  </ul>
</div>
<router-outlet></router-outlet>
