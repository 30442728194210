<div class="paragraph">
<p>Pagination is used to allow users to move through pages or data or content. On web pagination uses buttons to move back and forth between pages. On iOS and Android, pagination uses infinite scrolling.</p>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Pagination is used when more than 25 items are displayed in one view. Users can navigate to the previous or next page using the chevrons on the sides. Additionally, each page is a link that allows them to navigate directly to a specific page.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/pagination-anatomy.png" alt="Pagination anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Previous and next</strong><br>
Navigate backwards and forwards from the current page.</p>
</li>
<li>
<p><strong>Active page</strong><br>
The page the user is currently at.</p>
</li>
<li>
<p><strong>Page navigation</strong><br>
Navigate to a specific page by selecting the page number.</p>
</li>
<li>
<p><strong>Truncation</strong><br>
When the number of pages exceeds the maximum display limit, ellipsis are used to truncate the remaining pages.</p>
</li>
</ol>
</div>
<div class="sect2">
<h3 id="_behaviors">Behaviors</h3>
<div class="paragraph">
<p>Disable the first arrow icon when the first page is selected and disable the last arrow icon when the last page is selected.</p>
</div>
</div>
<div class="sect2">
<h3 id="_placement">Placement</h3>
<div class="paragraph">
<p>By default, pagination is center aligned.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/pagination-placement.png" alt="Pagination placement">
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_do">Do</h3>
<div class="ulist">
<ul>
<li>
<p>Clearly identify which page the user is on by displaying the current page number.</p>
</li>
<li>
<p>Provide context into how many pages are there in total to provide the user clarity around the data displayed.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_dont">Don’t</h3>
<div class="ulist">
<ul>
<li>
<p>Use pagination in iOS and Android, use infinite scrolling instead.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_responsiveness">Responsiveness</h3>
<div class="paragraph">
<p>In smaller devices, pagination is placed in the center of the screen, taking up to full width of the screen space.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/pagination-responsive.png" alt="Responsive web pagination">
</div>
</div>
</div>
<div class="sect2">
<h3 id="_native_mobile">Native Mobile</h3>
<div class="paragraph">
<p>On iOS and Android devices use infinite scroll instead of pagination.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/pagination-ios.png" alt="Mobile pagination">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>Start loading items when a user is close to the bottom, roughly 5 items.</p>
</li>
<li>
<p>Show a loading indicator below the list to indicate that items are being loaded</p>
</li>
</ul>
</div>
</div>
</div>
</div>