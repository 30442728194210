import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-iban-pipe-ui-showcase',
  templateUrl: './iban-pipe-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class IbanPipeShowcaseComponent {
  iban = 'NL81TRIO0212471066';
  iban2 = '569873 1';
}
