import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-text-ui-showcase',
  templateUrl: './input-text-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputTextShowcaseComponent {
  test = 'egy';
  vForm: UntypedFormGroup | undefined;
  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      inputText: [''],
      inputText2: [''],
      inputText3: [''],
      inputText4: [''],
    });
  }

  isFieldValid(fieldName: string) {
    const control = this.vForm?.get(fieldName);

    return control && (control.valid || control.untouched);
  }
}
