<div class="bb-block bb-block--xl">
  <h4>Default</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-credit-card-ui
        [title]="'John Doe'"
        [productNumber]="4322"
        [amount]="1425.99"
        [currency]="'EUR'"
        [mainSectionCurrency]="'EUR'"
        [mainSectionAmount]="880.54"
        [mainSectionTitle]="'Available balance'"
        [secondarySectionCurrency]="'EUR'"
        [secondarySectionAmount]="7280.86"
        [secondarySectionTitle]="'Credit Limit'"
        [productDetailsInfo]="{ owners: 'Richard Norton' }"
        [highlight]="false"
        [favorite]="true"
      >
      </bb-product-item-credit-card-ui>
    </div>
  </div>
</div>

<div>
  <h4>Inside a list</h4>
  <div class="card">
    <div class="card-body">
      <div class="bb-list">
        <div class="bb-list__item">
          <bb-product-item-credit-card-ui
            [title]="'John Doe'"
            [productNumber]="4322"
            [amount]="-1425.99"
            [currency]="'EUR'"
            [secondarySectionCurrency]="'EUR'"
            [secondarySectionAmount]="7280.86"
            [secondarySectionTitle]="'Credit Limit'"
            [highlight]="true"
            [favorite]="true"
          >
          </bb-product-item-credit-card-ui>
        </div>
        <div class="bb-list__item">
          <bb-product-item-basic-account-ui
            [title]="'Ricardo Norton\'s Master Card'"
            [amount]="6719.64"
            [productNumber]="8927"
            [productNumberFormat]="{ length: 18, maskRange: [0, 14], segments: 4 }"
            [currency]="'EUR'"
            [highlight]="true"
            [active]="false"
          ></bb-product-item-basic-account-ui>
        </div>
      </div>
    </div>
  </div>
</div>
