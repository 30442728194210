<!-- Reactive form -->
<div class="bb-block bb-block--xl">
  <form [formGroup]="radioGroupForm">
    <bb-input-radio-group-ui
      [required]="true"
      name="radioGroupRegular"
      formControlName="accountType"
      [attr.data-role]="'radio-group'"
      [label]="'Regular'"
    >
      <bb-input-radio-ui value="cr" label="Label"> </bb-input-radio-ui>
      <bb-input-radio-ui value="dt" label="Label 2"> </bb-input-radio-ui>
    </bb-input-radio-group-ui>
  </form>
</div>

<div class="bb-block bb-block--xl">
  <!-- Template driven form -->
  <form name="radiotemplateForm" #f="ngForm" (ngSubmit)="onRadioTemplateFormSubmit(f)">
    <bb-input-radio-group-ui
      [(ngModel)]="paymentType"
      [required]="true"
      name="radioGroupWithCustomTemplate"
      label="With custom label"
    >
      <bb-input-radio-ui [labelTemplate]="customLabelTemplate1" value="US_FOREIGN_WIRE1"></bb-input-radio-ui>
      <bb-input-radio-ui [labelTemplate]="customLabelTemplate2" value="US_FOREIGN_WIRE2"></bb-input-radio-ui>
    </bb-input-radio-group-ui>
  </form>

  <ng-template #customLabelTemplate1>
    <div>
      <span class="bb-block"> Label with sub text </span>
      <span class="bb-text-support bb-text-default bb-subtitle"> Help text goes here </span>
    </div>
  </ng-template>
  <ng-template #customLabelTemplate2>
    <div>
      <span class="bb-block"> Label with sub text </span>
      <span class="bb-text-support bb-text-default bb-subtitle">
        Help text for the second label goes here and wraps when needed.
      </span>
    </div>
  </ng-template>
</div>

<div class="bb-block bb-block--xl">
  <!-- Template driven form -->
  <form name="radiotemplateForm" #f="ngForm" (ngSubmit)="onRadioTemplateFormSubmit(f)">
    <bb-input-radio-group-ui
      [(ngModel)]="bank"
      [required]="true"
      name="radioGroupWithCustomLabelAndContext"
      label="With custom label and context"
    >
      @for (radioData of radiosTemplateData; track radioData.value) {
        <bb-input-radio-ui
          [labelTemplate]="customLabelTemplateWithData"
          [labelTemplateContext]="radioData.label"
          [value]="radioData.value"
        ></bb-input-radio-ui>
      }
    </bb-input-radio-group-ui>
  </form>

  <ng-template #customLabelTemplateWithData let-context="labelTemplateContext">
    <div>
      <span class="bb-block">
        {{ context }}
      </span>
    </div>
  </ng-template>
  Selected Bank: {{ bank }}
</div>

<div class="bb-block bb-block--xl">
  <bb-input-radio-group-ui
    [required]="true"
    name="radioGroupWithVerticalAlignedToTheTop"
    verticalAlign="top"
    label="With radio vertical aligned to the top"
  >
    @for (radioData of radiosTemplateData; track radioData.value) {
      <bb-input-radio-ui
        [labelTemplate]="customLabelTemplateVerticalAlignedTop"
        [labelTemplateContext]="radioData.label"
        [value]="radioData.value"
      ></bb-input-radio-ui>
    }
  </bb-input-radio-group-ui>

  <ng-template #customLabelTemplateVerticalAlignedTop let-context="labelTemplateContext">
    <div>
      <span class="bb-block">
        {{ context }} <br />
        more content <br />
        even more content
      </span>
    </div>
  </ng-template>
</div>

<div class="bb-block bb-block--xl">
  <bb-input-radio-group-ui
    [required]="true"
    name="radioGroupWithVerticalAlignedToTheBottom"
    verticalAlign="bottom"
    label="With radio vertical aligned to the bottom"
  >
    @for (radioData of radiosTemplateData; track radioData.value) {
      <bb-input-radio-ui
        [labelTemplate]="customLabelTemplateVerticalAlignedBottom"
        [labelTemplateContext]="radioData.label"
        [value]="radioData.value"
      ></bb-input-radio-ui>
    }
  </bb-input-radio-group-ui>

  <ng-template #customLabelTemplateVerticalAlignedBottom let-context="labelTemplateContext">
    <div>
      <span class="bb-block">
        {{ context }} <br />
        more content <br />
        even more content
      </span>
    </div>
  </ng-template>
</div>

<div class="bb-block bb-block--xl">
  <bb-input-radio-group-ui
    [required]="true"
    name="radioGroupWithVerticalAlignedToTheMiddle"
    verticalAlign="middle"
    label="With radio vertical aligned to the middle"
  >
    @for (radioData of radiosTemplateData; track radioData.value) {
      <bb-input-radio-ui
        [labelTemplate]="customLabelTemplateVerticalAlignedMiddle"
        [labelTemplateContext]="radioData.label"
        [value]="radioData.value"
      ></bb-input-radio-ui>
    }
  </bb-input-radio-group-ui>

  <ng-template #customLabelTemplateVerticalAlignedMiddle let-context="labelTemplateContext">
    <div>
      <span class="bb-block">
        {{ context }} <br />
        more content <br />
        even more content
      </span>
    </div>
  </ng-template>
</div>

<div class="bb-block bb-block--xl">
  <form [formGroup]="radioGroupForm">
    <bb-input-radio-group-ui
      [required]="true"
      formControlName="accountType"
      label="Horizontal Regular"
      [horizontal]="true"
      name="radioGroupHorizontalRegular"
    >
      <bb-input-radio-ui value="cr" label="Label"> </bb-input-radio-ui>
      <bb-input-radio-ui value="dt" label="Label"> </bb-input-radio-ui>
    </bb-input-radio-group-ui>
  </form>
</div>

<div class="bb-block bb-block--xl">
  <bb-input-radio-group-ui [required]="true" label="Some items disabled" name="radioGroupSomeItemsDisabled">
    <bb-input-radio-ui value="cr" label="Label 1"> </bb-input-radio-ui>
    <bb-input-radio-ui value="dt" [disabled]="true" label="Label 2 (disabled)"> </bb-input-radio-ui>
    <bb-input-radio-ui value="aa" [disabled]="true" label="Label 3 (disabled)"> </bb-input-radio-ui>
    <bb-input-radio-ui value="xx" label="Label 4"> </bb-input-radio-ui>
  </bb-input-radio-group-ui>
</div>

<div class="bb-block bb-block--xl">
  <bb-input-radio-group-ui [required]="true" label="All disabled" [disabled]="true" name="radioGroupAllDisabled">
    <bb-input-radio-ui value="cr" label="Label 1"> </bb-input-radio-ui>
    <bb-input-radio-ui value="dt" label="Label 2"> </bb-input-radio-ui>
    <bb-input-radio-ui value="aa" label="Label 3"> </bb-input-radio-ui>
    <bb-input-radio-ui value="xx" label="Label 4"> </bb-input-radio-ui>
  </bb-input-radio-group-ui>
</div>

<div class="bb-block bb-block--xl">
  <bb-input-radio-group-ui
    id="radioGroupWithErrorState"
    [required]="true"
    [preselect]="false"
    label="With error state"
    name="radioGroupWithErrorState"
    [formControl]="radioGroupWithErrorState"
  >
    <bb-input-radio-ui value="1" label="Label 1"> </bb-input-radio-ui>
    <bb-input-radio-ui value="2" label="Label 2"> </bb-input-radio-ui>
    <bb-input-radio-ui value="3" label="Label 3"> </bb-input-radio-ui>
    <bb-input-radio-ui value="4" label="Label 4"> </bb-input-radio-ui>
  </bb-input-radio-group-ui>
  <bb-input-validation-message-ui [showErrors]="radioGroupWithErrorState.invalid" [id]="'radioGroupWithErrorState'">
    <span> Field is required. </span>
  </bb-input-validation-message-ui>
</div>

<div class="bb-block bb-block--xl">
  <bb-input-radio-group-ui
    name="radioGroupWithCustomGroupTemplate"
    [labelTemplate]="customGroupLabelTemplate"
    [labelTemplateContext]="{ label: 'group label' }"
  >
    <bb-input-radio-ui [label]="'Label radio 1'" value="US_FOREIGN_WIRE1"></bb-input-radio-ui>
    <bb-input-radio-ui [label]="'Label radio 2'" value="US_FOREIGN_WIRE2"></bb-input-radio-ui>
  </bb-input-radio-group-ui>

  <ng-template #customGroupLabelTemplate let-context="labelTemplateContext">
    <legend class="bb-input-radio-group__label">
      <span class="bb-block">With custom {{ context.label }} and context </span>
      <span class="bb-text-support bb-text-default bb-subtitle">(Custom Group Label)</span>
    </legend>
  </ng-template>
</div>

<div class="bb-block bb-block--xl">
  <bb-input-radio-group-ui [required]="true" label="Block labels" [isBlock]="true" name="radioGroupBlockLabels">
    <bb-input-radio-ui value="cr" label="Label 1"> </bb-input-radio-ui>
    <bb-input-radio-ui value="dt" label="Label 2"> </bb-input-radio-ui>
    <bb-input-radio-ui value="aa" label="Label 3"> </bb-input-radio-ui>
    <bb-input-radio-ui value="xx" label="Label 4"> </bb-input-radio-ui>
  </bb-input-radio-group-ui>
</div>
