<form [formGroup]="vForm">
  <div class="bb-block bb-block--lg">
    <h4>List of vertically stacked checkboxes</h4>
    <div class="bb-block bb-block--sm">
      <bb-input-checkbox-ui
        #inputCheckbox
        [id]="'inputCheckbox'"
        data-role="checkbox-default"
        formControlName="inputCheckbox"
        label="I accept the Terms of Service"
        class="bb-block bb-block--sm"
      ></bb-input-checkbox-ui>
    </div>
    <div class="bb-block bb-block--sm">
      <bb-input-checkbox-ui
        #inputCheckbox2
        [id]="'inputCheckbox2'"
        data-role="checkbox-default-2"
        formControlName="inputCheckbox2"
        label="I accept the Terms of Conditions"
      ></bb-input-checkbox-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>List of horizontally stacked checkboxes</h4>
    <div class="bb-stack">
      <bb-input-checkbox-ui
        #inputCheckbox3
        [id]="'inputCheckbox3'"
        data-role="checkbox-default-3"
        formControlName="inputCheckbox3"
        label="I accept the Terms of Service"
        class="bb-stack__item"
      ></bb-input-checkbox-ui>
      <bb-input-checkbox-ui
        #inputCheckbox4
        [id]="'inputCheckbox4'"
        data-role="checkbox-default-4"
        formControlName="inputCheckbox4"
        label="I accept the Terms of Conditions"
      ></bb-input-checkbox-ui>
    </div>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>Checkbox with image</h4>
    <bb-input-checkbox-ui
      [id]="'inputCheckboxWithImage'"
      formControlName="inputCheckbox5"
      class="bb-block bb-block--sm"
    >
      <bb-payment-card-ui
        [paymentCard]="{ name: 'Ricardo Peterson', number: '1234', vendor: 'mastercard', expirationDate: '1/1/2004' }"
        [paymentCardNumberFormat]="{
          length: 16,
          maskRange: [0, 12],
          segments: 4
        }"
      ></bb-payment-card-ui>
    </bb-input-checkbox-ui>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>Disabled</h4>
    <bb-input-checkbox-ui
      [id]="'inputCheckboxDisabled'"
      data-role="checkbox-disabled"
      formControlName="inputCheckboxDisabled"
      label="I accept the Terms of Service"
    ></bb-input-checkbox-ui>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>Disabled, checked</h4>
    <bb-input-checkbox-ui
      [id]="'inputCheckboxDisabledChecked'"
      data-role="checkbox-disabled-selected"
      formControlName="inputCheckboxDisabledChecked"
      label="I accept the Terms of Service"
    ></bb-input-checkbox-ui>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>Invalid checkbox</h4>
    <bb-input-checkbox-ui
      [id]="'inputCheckboxError'"
      [aria-describedby]="'inputCheckboxExampleError'"
      data-role="checkbox-validated"
      [required]="true"
      formControlName="inputCheckboxError"
      label="I accept the Terms of Service"
    ></bb-input-checkbox-ui>
    <bb-input-validation-message-ui
      [id]="'inputCheckboxExampleError'"
      data-role="checkbox-validation-error"
      [showErrors]="!isFieldValid('inputCheckboxError')"
    >
      <span>This field is required.</span>
    </bb-input-validation-message-ui>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>Indeterminate</h4>
    <bb-input-checkbox-ui
      [id]="'inputCheckboxIndeterminate'"
      data-role="checkbox-indeterminate"
      [indeterminate]="true"
      formControlName="inputCheckboxIndeterminate"
      label="I accept the Terms of Service"
    ></bb-input-checkbox-ui>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>Labelled by child node with aria-labelledby</h4>
    <bb-input-checkbox-ui
      [id]="'inputCheckboxChildNode'"
      data-role="checkbox-child-node"
      formControlName="inputCheckbox6"
      [aria-labelledby]="'inputCheckboxChildNodeLabelId'"
    >
      <span id="inputCheckboxChildNodeLabelId">
        By selecting "Accept", you agree that you have read and understood the terms and conditions set forth in this
        agreement and you accept this agreement without modification. By accepting this agreement, you consent to the
        electronic delivery of statements and other bank related documents. You understand this agreement is effective
        at the date and time of the Bank's receipt of this electronic agreement and signature.
      </span>
    </bb-input-checkbox-ui>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>Labelled by child node aria-label content</h4>
    <bb-input-checkbox-ui
      #inputCheckboxAriaLabel
      [id]="'inputCheckboxAriaLabel'"
      data-role="checkbox-aria-labelled"
      formControlName="inputCheckbox7"
      [aria-label]="'I accept the Terms of Service'"
    >
      <span>{{ inputCheckboxAriaLabel?.ariaLabel }}</span>
    </bb-input-checkbox-ui>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>Labelled by non child with aria-labelledby</h4>
    <bb-input-checkbox-ui
      [id]="'inputCheckboxAriaLabelledby'"
      data-role="checkbox-aria-labelledby"
      formControlName="inputCheckbox8"
      [aria-labelledby]="'ariaLabeledById'"
      class="bb-block bb-block--sm"
    >
      Default label.
    </bb-input-checkbox-ui>
    <span id="ariaLabeledById">&nbsp;This label will be read out: I accept the Terms of Service</span>
  </div>

  <div class="bb-block bb-block--lg">
    <h4>Checkboxes in table</h4>
    <table [bbTable]="tableOptions" bbTableFocus class="table table-hover">
      <thead>
        <tr>
          <th scope="col" tabindex="0"></th>
          <th scope="col" tabindex="0">Option</th>
        </tr>
      </thead>
      <tbody>
        @for (option of tableOptions; track $index) {
          <tr [bbRow]="option">
            <td>
              <bb-input-checkbox-ui
                formControlName="inputCheckbox8"
                [aria-labelledby]="'inputCheckboxTable' + option.name"
                class="bb-block bb-block--sm"
              >
              </bb-input-checkbox-ui>
            </td>
            <td>
              <span [attr.id]="'inputCheckboxTable' + option.name">{{ option.name }}</span>
            </td>
            <td></td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</form>
