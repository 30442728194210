import { NgModule } from '@angular/core';
import { NgClass, DatePipe } from '@angular/common';

import { PeriodSelectorComponent } from './period-selector.component';
import { IconModule } from '@backbase/ui-ang/icon';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [NgClass, IconModule, ButtonModule],
  declarations: [PeriodSelectorComponent],
  exports: [PeriodSelectorComponent],
  providers: [DatePipe],
})
export class PeriodSelectorModule {}
