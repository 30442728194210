import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CharCounterShowcaseComponent } from './char-counter-showcase.component';
import { CharCounterModule } from '@backbase/ui-ang/char-counter';
import { TextareaModule } from '@backbase/ui-ang/textarea';

@NgModule({
  imports: [CommonModule, CharCounterModule, ReactiveFormsModule, TextareaModule],
  declarations: [CharCounterShowcaseComponent],
})
export class CharCounterShowcaseModule {}
