import { Component } from '@angular/core';
import { ListMultipleSelectItems } from '@backbase/ui-ang/list-multiple-select';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-list-multiple-select-ui-showcase',
  templateUrl: './list-multiple-select-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ListMultipleSelectShowcaseComponent {
  listMultipleSelectItemsConfig: ListMultipleSelectItems = {
    'List Item 1': {},
    'List Item 2': {
      items: {
        q2: {
          icon: 'warning',
        },
      },
    },
  };

  vForm: UntypedFormGroup | undefined;

  constructor(private readonly formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      listMultipleSelect: this.formBuilder.group({
        'List item 1': true,
        'List item 2': this.formBuilder.group({
          'Sub item 1': false,
          'Sub item 2': true,
          'Sub item 3': this.formBuilder.group({
            'Sub sub item 1': { value: true, disabled: false },
            'Disabled sub item 2': { value: false, disabled: true },
          }),
        }),
      }),
    });
  }

  onListMultipleSelectClearAll() {
    this.vForm?.reset();
  }
}
