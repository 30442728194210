<div class="sect1">
<h2 id="_layout_and_navigation">Layout and navigation</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Module: <strong>LayoutModule</strong><br>
CSS: <strong>bb-layout</strong></p>
</div>
<div class="paragraph">
<p>App layout combines the initial structure of the page and the main navigation. These can be combined either with a horizontal navigation or a vertical navigation. The navigation is not a separate component in the design system as the app layout and the navigation work together and react responsively as one.</p>
</div>
<div class="paragraph">
<p>Both examples come with some angular helper directives and a default theme. We only provide the atoms or the Layout and Navigation and some example templates so that in your project you can easily extend and add new behaviour.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout_sections.png" alt="layout sections">
</div>
</div>
<div class="sect2">
<h3 id="_app_layout_sections">App Layout Sections</h3>
<div class="ulist">
<ul>
<li>
<p><strong>Top Bar</strong> - Required - Used with and without a horizontal navigation</p>
</li>
<li>
<p><strong>Main Section</strong> - Required - Contains the main routable area of the application</p>
</li>
<li>
<p><strong>Side bar</strong> - Optional - Only required if using a vertical navigation like the above example</p>
</li>
<li>
<p><strong>Header Section</strong> - Optional - Can be used to place a notice/notification/message about everything in the app. Used by business banking (to do find example)</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_horizontal_navigation">Horizontal Navigation</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Horizontal Navigation puts the navigation menu horizontally on the top on the large screens and it puts it vertically on the left on the small screens. Horizontal navigation has two levels, level one as a standard row and an optional second level as sub menus where links, text and images can be added. This navigation is used as the default for our <a href="https://community.backbase.com/documentation/Retail-Apps/latest/index" target="_blank" rel="noopener">Retail Banking Web App</a></p>
</div>
<div class="sect2">
<h3 id="_horizontal_navigation_design_examples">Horizontal Navigation Design Examples</h3>
<div class="sect3">
<h4 id="_large_screen_with_collapsed_menu">Large screen with collapsed menu</h4>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout-horitontal-collapsed.png" alt="layout horitontal collapsed">
</div>
</div>
</div>
<div class="sect3">
<h4 id="_large_screen_with_menu_item_expanded">Large screen with menu item expanded</h4>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout-horizontal-one-column.png" alt="layout horizontal one column">
</div>
</div>
</div>
<div class="sect3">
<h4 id="_small_screen_with_collapsed_menu">Small screen with collapsed menu</h4>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout-horizontal-mobile.png" alt="layout horizontal mobile">
</div>
</div>
</div>
<div class="sect3">
<h4 id="_small_screen_with_menu_expanded">Small screen with menu expanded</h4>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout-horizontal-mobile-expanded.png" alt="layout horizontal mobile expanded">
</div>
</div>
</div>
<div class="sect3">
<h4 id="_small_screen_with_menu_item_expanded">Small screen with menu item expanded</h4>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout-horizontal-mobile-submenu-expanded.png" alt="layout horizontal mobile submenu expanded">
</div>
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_vertical_navigation">Vertical Navigation</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Vertical Navigation puts the navigation menu vertically as a column in the left hand sidebar. Vertical navigation has only a single level of links and the possibility to group together and name sections. It does not cater for additional paragraphs of text and images etc. This navigation is used as the default for our <a href="https://community.backbase.com/documentation/Business-Apps/latest/index" target="_blank" rel="noopener">Business Banking Web App</a></p>
</div>
<div class="sect2">
<h3 id="_vertical_navigation_design_examples">Vertical Navigation Design Examples</h3>
<div class="sect3">
<h4 id="_large_screen_with_collapsed_menu_2">Large screen with collapsed menu</h4>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout-vertical-large-screen-collapsed-menu.png" alt="layout vertical large screen collapsed menu">
</div>
</div>
</div>
<div class="sect3">
<h4 id="_large_screen_with_menu_expanded">Large screen with menu expanded</h4>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout-vertical.png" alt="layout vertical">
</div>
</div>
</div>
<div class="sect3">
<h4 id="_small_screen_with_collapsed_menu_2">Small screen with collapsed menu</h4>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout-vertical-small-screen-collapsed-menu.png" alt="layout vertical small screen collapsed menu">
</div>
</div>
</div>
<div class="sect3">
<h4 id="_small_screen_with_expanded_menu">Small screen with expanded menu</h4>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/layout-vertical-small-screen-expanded-menu.png" alt="layout vertical small screen expanded menu">
</div>
</div>
</div>
</div>
</div>
</div>