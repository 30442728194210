import { Component } from '@angular/core';
import { ValidationErrorComponentModel } from '../../control-error-handler.const';
import { errorMessageAnimation } from '@backbase/ui-ang/util';

let _uniqueId = 0;

/**
 * @name ValidationMessageComponent
 *
 * @description
 * Dynamic component that displays a control validation message dependence on validation.
 */
@Component({
  selector: 'bb-validation-message-ui',
  templateUrl: 'validation-message.component.html',
  animations: [errorMessageAnimation],
})
export class ValidationMessageComponent implements ValidationErrorComponentModel {
  readonly errorId = `bb-error-message-${_uniqueId++}`;

  text: string | undefined;
}
