<div class="paragraph">
<p>These guidelines aim to provide best practices when working with data in Backbase Products with the purpose of keeping consistency in both content and code. By default, we use the en-US locale, which means we use American English and formats as a base before translating to other locales, so be mindful that the following guidelines are subject to change according to locale settings in the app.</p>
</div>
<div class="sect1">
<h2 id="_format">Format</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Phone Numbers in Backbase products are <strong>displayed in International Format</strong>, following <a href="https://en.wikipedia.org/wiki/E.164" target="_blank" rel="noopener">E.164 phone number formatting</a>. This is an internationally-recognized standard phone number format that will help to ensure numbers are displayed in a consistent and familiar way to users across the globe.</p>
</div>
<div class="paragraph">
<p>Formatting for phone numbers include:</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>A + (plus) sign</strong><br>
Always placed before the number to indicate the following number is a<br>
Country Code.</p>
</li>
<li>
<p><strong>Country code</strong><br>
One to three digits long, depending on location.</p>
</li>
<li>
<p><strong>Local Area code</strong><br>
Length also varies by location</p>
</li>
<li>
<p><strong>Phone number</strong><br>
The local phone number.</p>
</li>
</ol>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/phone-numbers.png" alt="Phone numbers formatter">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_phone_number_styling">Phone number styling</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Besides formatting phone numbers with E.164 standards, to improve readability of the numbers, we <strong>add a space</strong> to separate International + sign and Country code, Local Area code and Phone number.</p>
</div>
<div class="paragraph">
<p>For example, a US-based number in standard local formatting would be:</p>
</div>
<div class="paragraph">
<p><em>(415) 123-4567</em></p>
</div>
<div class="paragraph">
<p>This number with Backbase format would be displayed as:</p>
</div>
<div class="paragraph">
<p><em>+1 415 123 4567</em></p>
</div>
<div class="paragraph">
<p>Note that since the local phone number is parsed using the country code, when using a different locale than US, the grouping of these numbers might be different. This is an example using a Dutch number:</p>
</div>
<div class="paragraph">
<p><em>+31 020 555 1111</em></p>
</div>
</div>
</div>