import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const INVALID_DATE_FORMAT_KEY = 'invalidDateFormat';

export type NgDateStructNullable = NgbDateStruct | null;

export interface DateSelectionModel {
  date: NgDateStructNullable;
  isSelecting?: boolean;
}

export interface DateRangeSelectionModel extends DateSelectionModel {
  isTo: boolean;
}

export interface DateRangeModel {
  from: string;
  to: string;
}

export interface RangeSelectModel {
  from: NgDateStructNullable;
  to: NgDateStructNullable;
  closeDatePicker: boolean;
}
