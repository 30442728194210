import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';

@Component({
  selector: 'bb-payment-card-number-pipe-example-ui',
  templateUrl: './payment-card-number-pipe.example.component.html',
})
export class PaymentCardNumberPipeExampleComponent {
  productNumber = 'NL75INGB3400120056';

  productNumberFormat: PaymentCardNumberFormat = {
    length: 18,
    maskRange: [0, 12],
    segments: 4,
  };
}
