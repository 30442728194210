import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsisShowcaseComponent } from './ellipsis-showcase.component';
import { EllipsisModule } from '@backbase/ui-ang/ellipsis';

@NgModule({
  imports: [CommonModule, EllipsisModule],
  declarations: [EllipsisShowcaseComponent],
})
export class EllipsisShowcaseModule {}
