<div class="paragraph">
<p>Alerts are used in Backbase products to provide contextual feedback messages that provide relevant information to users. According to different use cases, alerts can be used to display related optional actions and be styled in a way that reflects different severity levels.</p>
</div>
<div class="sect1">
<h2 id="_anatomy_of_an_alert">Anatomy of an Alert</h2>
<div class="sectionbody">
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/alert-anatomy.png" alt="Anatomy of an alert">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Icon</strong><br>
An icon that relates to the message and severity level.</p>
</li>
<li>
<p><strong>Title</strong><br>
A heading that communicates, in short, and clear wording, the most important information on the alert.</p>
</li>
<li>
<p><strong>Message</strong> (optional)<br>
Body content that provides more detail and possible actions.</p>
</li>
<li>
<p><strong>Dismiss button</strong> (optional)<br>
If an alert may be dismissed, an <strong>X</strong> icon button is placed in the top right of the alert container, making it optional to the user to click on it to dismiss the alert.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_placement">Placement</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Alerts are flexible to be placed in different containers such as widgets, cards or modals. Placement is dependent on use case but the general guideline is for them to sit <strong>at the top of the container</strong>, taking <strong>full-width</strong> of the space and, close to related information in the page.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>We allow our system to assign a severity level to each alert to influence how the application will deliver it. Severity levels should be in the interface represented by color and icons.</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Success</strong><br>
Informs the recipient that there is a positive outcome from a previous action. It may not require any action.</p>
</li>
<li>
<p><strong>Info</strong><br>
Gives the recipient neutral information unrelated to their previous actions, such as the status of an item.</p>
</li>
<li>
<p><strong>Error</strong><br>
Tells the recipient something they need to pay attention to. This may require an action or decision to prevent undesirable consequences.</p>
</li>
<li>
<p><strong>Warning</strong><br>
Tells the recipient something they need to do. This may require an action or decision to prevent undesirable consequences.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don&#8217;ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When working with alerts, follow this recommendations to keep a consistent experience.</p>
</div>
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use alerts sparingly.</p>
</li>
<li>
<p>Keep messages short.</p>
</li>
<li>
<p>If needed, include a link to let the user perform an action.</p>
</li>
<li>
<p>Place alerts in an appropriate context. Alerts related to a specific section or element of a page (such as a card or modal) should show inside of that element.</p>
</li>
<li>
<p>Focus on a single theme, a piece of information, or required action to avoid overwhelming users.</p>
</li>
<li>
<p>Limit the alert to only provide one primary action, when necessary.</p>
</li>
<li>
<p>Use the default icon for success, info, warning, and critical statuses.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use alerts as a feedback mechanism. Look into<br>
<a href="/web-components/notification/web">notifications</a> instead.</p>
</li>
<li>
<p>Make dismiss available in alerts if the message remains relevant to the user while they are in a certain context.</p>
</li>
<li>
<p>Use alerts for marketing information.</p>
</li>
<li>
<p>Place alerts overlaying or floating over content.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_interaction">Interaction</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Dismissing an alert<br>
</strong>If the alert can be dismissed, the X button will be seen in the alert. The user can click on it and the alert will disappear.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>On small devices, alerts take 100% width. Alerts should look and behave in the same way as in desktop.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="paragraph">
<p>There are no specific accessibility guidelines for this component besides general compliance as specified in our <a href="/principles/accessibility/">accessibility page</a>.</p>
</div>
</div>
</div>
