<header class="header">
  <div class="header__content">
    <div class="header__logo-and-menu">
      <a class="header__logo-wrapper" [routerLink]="'/'" title="Backbase Design System">
        <bb-logo-ui class="header__logo" [isInverse]="true" type="full"></bb-logo-ui>
      </a>
      @if ((layoutService.isHighResolutionView$ | async) === false) {
        <button
          bbButton
          aria-label="Toggle menu"
          color="tertiary"
          circle="true"
          buttonSize="md"
          class="header__toggle-menu"
          [attr.aria-expanded]="layoutService.navigationExpanded$ | async"
          (click)="layoutService.toggleNav()"
        >
          <bb-icon-ui [name]="(layoutService.navigationExpanded$ | async) ? 'clear' : 'menu'" size="xl"></bb-icon-ui>
        </button>
      }
    </div>
  </div>
</header>
