<h4>Small numbers untruncated</h4>
<bb-pagination-ui
  class="bb-pagination d-flex justify-content-start align-items-center mt-4 mb-3"
  [page]="paginatorOne.page"
  [boundaryLinks]="paginator.boundaryLinks"
  [directionLinks]="paginator.directionLinks"
  [totalItems]="paginatorOne.totalItems"
  [itemsPerPage]="paginator.itemsPerPage"
  [maxNavPages]="paginatorOne.maxNavPages"
  (pageChange)="paginator.onPageChange($event)"
  [attr.data-role]="paginatorOne.type"
></bb-pagination-ui>

<h4>Small numbers left truncated</h4>
<bb-pagination-ui
  class="bb-pagination d-flex justify-content-start align-items-center mt-4 mb-3"
  [page]="paginatorTwo.page"
  [boundaryLinks]="paginator.boundaryLinks"
  [directionLinks]="paginator.directionLinks"
  [totalItems]="paginatorTwo.totalItems"
  [itemsPerPage]="paginator.itemsPerPage"
  [maxNavPages]="paginatorTwo.maxNavPages"
  (pageChange)="paginator.onPageChange($event)"
  [attr.data-role]="paginatorTwo.type"
></bb-pagination-ui>

<h4>Small numbers right truncated</h4>
<bb-pagination-ui
  class="bb-pagination d-flex justify-content-start align-items-center mt-4 mb-3"
  [page]="paginatorThree.page"
  [boundaryLinks]="paginator.boundaryLinks"
  [directionLinks]="paginator.directionLinks"
  [totalItems]="paginatorThree.totalItems"
  [itemsPerPage]="paginator.itemsPerPage"
  [maxNavPages]="paginatorThree.maxNavPages"
  (pageChange)="paginator.onPageChange($event)"
  [attr.data-role]="paginatorThree.type"
></bb-pagination-ui>

<h4>Big numbers left truncated</h4>
<bb-pagination-ui
  class="bb-pagination d-flex justify-content-start align-items-center mt-4 mb-3"
  [page]="paginatorFour.page"
  [boundaryLinks]="paginator.boundaryLinks"
  [directionLinks]="paginator.directionLinks"
  [totalItems]="paginatorFour.totalItems"
  [itemsPerPage]="paginator.itemsPerPage"
  [maxNavPages]="paginatorFour.maxNavPages"
  (pageChange)="paginator.onPageChange($event)"
  [attr.data-role]="paginatorFour.type"
></bb-pagination-ui>
