import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type,
} from '@angular/core';
import { NotificationComponent } from './notification.component';

export const DEFAULT_ROOT = document.body;

interface IAppendedComponent {
  componentInstance: any;
  unmountComponent: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class AppendToDomService {
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector,
  ) {
    this.generateListElement(DEFAULT_ROOT);
  }

  appendComponentToRoot(
    component: Type<NotificationComponent>,
    autofocus = false,
    projectableNodes: any[] = [],
    root: Node = DEFAULT_ROOT,
  ): IAppendedComponent {
    // 1. Create a component reference from the component
    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector, [projectableNodes ?? []]);

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // 4. Append notification list if missing
    const listElement = this.generateListElement(root);

    // 5. Add item class
    domElem.setAttribute('class', 'bb-notification-list__item');

    componentRef.instance.autofocus = autofocus;

    // 6. Append new element to list of elements and push old ones down.
    if (listElement) {
      listElement.insertBefore(domElem, listElement.firstChild);
    }

    return {
      componentInstance: componentRef.instance,
      unmountComponent: () => {
        this.destroyElement(componentRef);
      },
    };
  }

  private generateListElement(root: Node): Element {
    let listElement = document.getElementsByClassName('bb-notification-list')[0];
    if (!listElement) {
      listElement = document.createElement('div');
      listElement.setAttribute('class', 'bb-notification-list');
      listElement.setAttribute('aria-atomic', 'true');
      listElement.setAttribute('aria-live', 'polite');
      listElement.setAttribute('role', 'status');
      listElement.setAttribute('aria-relevant', 'additions');
      root.appendChild(listElement);
    }

    return listElement;
  }

  private destroyElement(componentRef: ComponentRef<any>) {
    componentRef.instance.beforeDestroy();
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
