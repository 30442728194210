import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-payment-card-ui-showcase',
  templateUrl: './payment-card-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class PaymentCardShowcaseComponent {
  get paymentCard() {
    return {
      name: 'Ricardo Peterson',
      number: '1234',
      vendor: 'mastercard',
      expirationDate: new Date(2021, 2),
      type: 'Credit',
      virtual: true,
    };
  }

  get partialPaymentCard() {
    return {
      name: 'Ricardo Peterson',
      expirationDate: new Date(2021, 2),
    };
  }

  get paymentCardWithBackgroundImage() {
    return {
      name: 'Ricardo Peterson',
      number: '1234',
      vendor: 'mastercard',
      expirationDate: new Date(2021, 2),
      cardName: 'Blue Card',
      type: 'Debit',
      virtual: true,
    };
  }

  get paymentCardNumberFormat() {
    return {
      length: 16,
      maskRange: [0, 12],
      segments: 4,
    };
  }

  get paymentCardWithLongName() {
    return {
      name: 'Hubert Blaine Wolfeschlegelsteinhausenbergerdorff Sr.',
      number: '1234',
      vendor: 'mastercard',
      expirationDate: new Date(2021, 2),
      type: 'Credit',
      virtual: true,
    };
  }
}
