<h4>Emblem</h4>
<div class="bb-stack bb-block bb-block--xl row">
  <bb-logo-ui type="emblem" class="bb-stack__item col-2"></bb-logo-ui>
  <div class="col-10">
    <span>Backbase</span>
  </div>
</div>
<h4>Full</h4>
<div class="bb-stack bb-block bb-block--xl">
  <bb-logo-ui type="full" class="bb-stack__item"></bb-logo-ui>
</div>
<h4>Inverse</h4>
<div class="bb-stack bb-block--xl row">
  <bb-logo-ui type="emblem" [isInverse]="true" class="bb-stack__item col-2"></bb-logo-ui>
  <div class="col-10">
    <span>Backbase</span>
  </div>
</div>
<div class="bb-stack bb-block">
  <bb-logo-ui type="full" class="bb-stack__item" [isInverse]="true"></bb-logo-ui>
</div>
