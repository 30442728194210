import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-char-counter-ui-showcase',
  templateUrl: 'char-counter-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class CharCounterShowcaseComponent {
  vForm: UntypedFormGroup | undefined;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.vForm = this.formBuilder.group({
      textarea: '',
      textareaAlmostFull: '123456789012345678',
      textareaFull: '12345678901234567890',
    });
  }

  reset() {
    if (this.vForm) {
      const control = this.vForm.get('textareaFull');
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      control && control.reset();
    }
  }
}
