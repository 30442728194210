import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

/**
 * @name CharCounterComponent
 *
 * @description
 * Component for counting characters typed inside a form element
 *
 * @deprecated Deprecated in ui-ang@11. To be made into a private component which is used in `bb-textarea-ui` and `bb-rich-text-editor-ui` in ui-ang@13.
 */
@Component({
  selector: 'bb-char-counter-ui',
  templateUrl: './char-counter.component.html',
})
export class CharCounterComponent implements OnInit, OnChanges {
  /**
   * Passed value from parent input, it gives option to count
   * char event if value was programmatically changed
   */
  @Input() inputValue: string | undefined;
  /**
   * The element reference which the characters are typed in.
   */
  @Input() element: HTMLInputElement | HTMLTextAreaElement | undefined;
  /**
   * Indicator for maximum character count.
   */
  @Input() maxLength = 150;
  /**
   * Additional text to display when when maximum character count has been exceeded.
   * Defaults to `''`.
   */
  @Input() limitExceededText = '';
  /**
   * Whether the element should be blocked when maximum character count is reached.
   */
  @Input() set blockTyping(val: boolean) {
    this._blockTyping = val;
    if (this.blockTyping && this.element) {
      this.renderer.setAttribute(this.element, 'maxlength', this.maxLength.toString());
    } else {
      this.renderer.removeAttribute(this.element, 'maxlength');
    }
  }

  get blockTyping() {
    return this._blockTyping;
  }
  private _blockTyping = false;
  counter = 0;
  valid = true;

  constructor(
    private readonly renderer: Renderer2,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.blockTyping && this.element) {
      this.renderer.setAttribute(this.element, 'maxlength', this.maxLength.toString());
    }
    // Cover by setTimeout for handling value change in input/textarea
    // This change cannot be checked by lifecycle hooks.
    setTimeout(() => {
      this.calculateElementValue();
    }, 0);

    this.renderer.listen(this.element, 'input', () => this.calculateElementValue());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { inputValue } = changes;
    if (inputValue && inputValue.previousValue !== inputValue.currentValue) {
      this.counter = inputValue.currentValue.length;
      this.valid = this.counter <= this.maxLength;
    }
  }
  // It should be deprecated, because it doesn't allow to count
  // value that was updated programmatically, for instance by patchValue
  calculateElementValue(): void {
    this.counter = this.element?.value?.length ?? this.inputValue?.length ?? 0;
    this.valid = this.counter <= this.maxLength;
    this.cd.detectChanges();
  }
}
