import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-empty-state-ui-showcase',
  templateUrl: 'empty-state-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class EmptyStateShowcaseComponent {
  reload() {
    window.location.reload();
  }
}
