import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import '@angular/localize/init';

import { InputNumberComponent } from './input-number.component';
import { FocusModule } from '@backbase/ui-ang/focus';
import { DigitOnlyDirective } from './digit-only.directive';

@NgModule({
  imports: [FormsModule, BaseClassesModule, FocusModule],
  declarations: [InputNumberComponent, DigitOnlyDirective],
  exports: [InputNumberComponent],
})
export class InputNumberModule {}
