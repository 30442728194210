import { Pipe, PipeTransform } from '@angular/core';
import type { NotificationType } from './notification.component';

@Pipe({ name: 'notificationIconColor' })
export class NotificationIconColorPipe implements PipeTransform {
  transform(modifier: NotificationType) {
    if (modifier === 'error') {
      return 'danger';
    }

    return modifier;
  }
}
