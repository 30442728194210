import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipeShowcaseComponent } from './date-pipe-showcase.component';
import { BbDatePipeModule } from '@backbase/ui-ang/date-pipe';

@NgModule({
  imports: [CommonModule, BbDatePipeModule],
  declarations: [DatePipeShowcaseComponent],
})
export class DatePipeShowcaseModule {}
