import {
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateLocaleParserFormatter } from './input-datepicker-formatter';
import { DatePipe } from '@angular/common';
import { KEY_CODES } from '@backbase/ui-ang/util';
import { BrowserService, NAVIGATOR_TOKEN, navigatorFactory } from '@backbase/ui-ang/services';
import { NgDateStructNullable } from './input-datepicker.model';
import { DateRangeSelectionModel, RangeSelectModel } from './input-datepicker.model';

/* eslint-disable */
export enum Key {
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
}

@Component({
  selector: 'bb-input-datepicker-range-day-template-ui',
  templateUrl: './input-datepicker-day-template-range.component.html',
  providers: [
    DatePipe,
    NgbDateLocaleParserFormatter,
    {
      provide: NgbDateParserFormatter,
      useExisting: NgbDateLocaleParserFormatter,
    },
    BrowserService,
    { provide: NAVIGATOR_TOKEN, useFactory: navigatorFactory },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDatepickerDayTemplateRangeComponent implements OnChanges {
  private static readonly hangledKeyCodes: string[] = [KEY_CODES.LEFT, KEY_CODES.UP, KEY_CODES.RIGHT, KEY_CODES.DOWN];

  /**
   * Date to render.
   */
  @Input() date!: NgbDate;

  /**
   * When true the date is rendered as disabled.
   */
  @Input() disabled = false;

  /**
   * CurrentMonth on the datepicker window.
   */
  @Input() currentMonth!: string;

  /**
   * Selected "from" date.
   */
  @Input() fromDate: NgDateStructNullable = null;

  /**
   * Selected "to" date
   */
  @Input() toDate: NgDateStructNullable = null;

  /**
   * If "true" the datepicker is displayed with two input fields for "from" and "to" dates
   */
  @Input() splitRange = false;

  /**
   * "true" when "splitRange" is "true" and the parent datepicker belongs to
   * second datepicker input (the one for "to" date)
   */
  @Input() toDateDatepicker = false;

  /**
   * Hovered date by a user
   */
  @Input() hoveredDate: NgDateStructNullable = null;

  /**
   * Emits an event when a range is selected
   */
  @Output() rangeSelect = new EventEmitter<RangeSelectModel>();

  /**
   * Event to trigger on date hover.
   */
  @Output() hovered = new EventEmitter<DateRangeSelectionModel>();

  /**
   * Prevent keypress to be called on toggle button enter
   */
  firstKey = true;

  /**
   * Indicates if the date is between the from-date and the hovered date.
   */
  isWithinRangeHover = false;

  /**
   * Indicates if the date is between the from-date and the to-date date.
   */
  isWithinSelectedRange = false;

  /**
   * Indicates if the date displayed as greyed out
   */
  isTextMuted = false;

  /**
   * Indicates if the date is hovered the range
   */
  isHovered = false;

  /**
   * Indicates if the date is the from-date of the range.
   */
  isFrom = false;

  /**
   * Indicates if the the date is the to-date of the range.
   */
  isTo = false;

  /**
   * Formatted date
   */
  formattedDate = '';

  /**
   * Indicates if the browser is IE.
   */
  isIE: boolean;

  private get withinHoverRangeSingle() {
    return (
      !this.toDate &&
      (this.date.after(this.fromDate) || this.date.equals(this.fromDate)) &&
      (this.date.before(this.hoveredDate) || this.date.equals(this.hoveredDate))
    );
  }

  private get withinHoverRangeSplitTo() {
    return (
      (this.date.before(this.hoveredDate) || this.date.equals(this.hoveredDate)) &&
      (this.date.after(this.toDate) ||
        this.date.equals(this.toDate) ||
        (this.date.before(this.toDate) && this.date.after(this.fromDate))) &&
      this.toDateDatepicker
    );
  }

  private get withinHoverRangeSplitFrom() {
    return (
      (this.date.after(this.hoveredDate) || this.date.equals(this.hoveredDate)) &&
      this.date.before(this.toDate) &&
      !this.toDateDatepicker &&
      this.toDate
    );
  }

  constructor(
    private readonly formatterHelper: NgbDateLocaleParserFormatter,
    private readonly browserService: BrowserService,
  ) {
    this.isIE = this.browserService.browserIsMSIE();
  }

  /* eslint-disable complexity */
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.hoveredDate) {
      this.isWithinRangeHover = !!(
        this.isValidRange() &&
        this.date &&
        this.hoveredDate &&
        (((this.withinHoverRangeSplitTo || this.withinHoverRangeSplitFrom) && this.splitRange) ||
          (this.withinHoverRangeSingle && this.toDateDatepicker && this.splitRange) ||
          (this.withinHoverRangeSingle && !this.splitRange))
      );

      this.isHovered = this.date?.equals(this.hoveredDate);
    }

    if (changes?.fromDate || changes?.toDate || changes?.date) {
      this.isWithinSelectedRange = this.date && this.date.before(this.toDate) && this.date.after(this.fromDate);
      this.isTo = this.date?.equals(this.toDate);
      this.formattedDate = this.formatterHelper.format(this.date);
      this.isFrom = this.date?.equals(this.fromDate);
    }

    this.isTextMuted =
      !this.isWithinRangeHover &&
      !this.isWithinSelectedRange &&
      !this.isFrom &&
      !this.isTo &&
      (this.disabled || this.date?.month !== +this.currentMonth);
  }

  /**
   *
   * @description
   * Handle mouseover. Emit the hovered date with null.
   *
   */
  @HostListener('mouseout')
  onMouseOut() {
    this.hovered.emit({ date: null, isTo: this.toDateDatepicker, isSelecting: false });
  }

  /**
   *
   * @description
   * Handle mouseover. Emit the hovered date with date.
   *
   */
  @HostListener('mouseover')
  onMouseOver() {
    this.hovered.emit({ date: this.date, isTo: this.toDateDatepicker, isSelecting: true });
  }

  /**
   * @description
   * Keypress handler
   *
   * @param evt
   * @param curMonth
   * @param curDay
   * @param date
   */
  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const day = (event.target as HTMLElement).textContent;
    const matchKey = this.isIE
      ? Object.values(Key).includes(event?.keyCode)
      : InputDatepickerDayTemplateRangeComponent.hangledKeyCodes.includes(event?.key);

    if (Number(day) === this.date.day && Number(this.currentMonth) === this.date.month && matchKey) {
      this.hovered.emit({ date: this.date, isTo: this.toDateDatepicker, isSelecting: true });
    }
  }

  private isValidRange(): boolean {
    return (
      !this.fromDate ||
      !this.toDate ||
      new NgbDate(this.fromDate.year, this.fromDate.month, this.fromDate.day).before(this.toDate)
    );
  }
}
