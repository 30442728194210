<div class="card shadow-sm">
  <div class="card-body">
    <bb-switch-ui (click)="toggleSortDisabled()" label="Disable Sorting" labelPosition="right" />
    <div class="table-responsive">
      <table
        #tableRef
        aria-label="Country data"
        (rowClick)="onRowClick($event)"
        (selectRow)="onRowSelect($event)"
        (sort)="onSort($event)"
        [bbTable]="countries$ | async"
        bbTableFocus
        class="table table-hover"
        data-role="table-showcase"
      >
        <!--Add empty caption to fix sonar warning-->
        <caption></caption>
        <thead>
          <tr>
            <th bbSortable="name" [sortDisabled]="sortDisabled" scope="col" data-role="table-sort-name">
              <span class="th-content"> Data left aligned </span>
            </th>
            <th bbSortable [sortDisabled]="sortDisabled" scope="col" data-role="table-sort-dropdown">
              <span class="th-content"> Nested Table </span>
              <!--Add th-dropdown-menu class on dropdown to make it work with <th> tag-->
              <!--Set role as presentation for dropdown so that screen reader will not read this dropdown.-->
              <!--bbSortable directive will add necessary role to dropdown button so that button will be actually read as colunmheader by screen readers-->
              <bb-dropdown-menu-ui
                btnColor="unstyled"
                buttonSize="sm"
                class="th-dropdown-menu"
                dropDownButtonLabel="Nested Table"
                role="presentation"
                position="bottom-left"
                [disabled]="sortDisabled"
              >
                <ng-template bbDropdownMenuItem>
                  <div class="dropdown-header">
                    <h3 class="bb-subheader bb-text-support">SORT BY</h3>
                  </div>
                  <!--bbTableMenuitem directive is added to facilitate the common behaviour of dropdown item such as active class and click event-->
                  @for (option of sortingOptions; track option.value) {
                    <button role="menuitem" class="dropdown-item bb-stack" [bbDropdownMenuOption]="option.value">
                      <span>
                        {{ option.label }}
                      </span>
                      @if (activeSortingOption?.value === option.value) {
                        <bb-icon-ui class="bb-stack__item--push-right" [name]="iconMap[activeSortingOption?.direction]">
                        </bb-icon-ui>
                      }
                    </button>
                  }
                </ng-template>
              </bb-dropdown-menu-ui>
            </th>
            <th class="text-center" scope="col">
              <span class="th-content"> Data center aligned </span>
            </th>
            <th bbSortable="area" [sortDisabled]="sortDisabled" scope="col" data-role="table-sort-area">
              <span class="th-content"> Area (left aligned) </span>
              <button bbButton color="unstyled" aria-label="Area" class="th-button" [disabled]="sortDisabled"></button>
            </th>
            <th scope="col">
              <span class="th-content"> Data center aligned </span>
            </th>
            <th class="text-end" scope="col">
              <span class="th-content"> Data right aligned </span>
            </th>
          </tr>
        </thead>
        <tbody>
          @for (country of countries$ | async; track $index) {
            <tr [bbRow]="country">
              <th scope="row">
                <span class="th-content"> Data left aligned </span>
              </th>
              <td>
                <div class="bb-stack">
                  <img
                    [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag"
                    [style.width]="'20px'"
                    [style.verticalAlign]="'baseline'"
                    alt="flag"
                    class="bb-stack__item--spacing-sm"
                    src="#"
                  />
                  {{ country.name }}
                </div>
              </td>
              <th scope="row">
                <span class="th-content"> Nested Table </span>
              </th>
              <td class="p-0">
                <table class="table mb-0">
                  <caption class="visually-hidden">
                    Capital and population
                  </caption>
                  <tbody>
                    <tr class="border-0">
                      <td class="bb-text-support">Capital:&lrm;</td>
                      <td>
                        {{ country.capital }}
                      </td>
                    </tr>
                    <tr class="border-0">
                      <td class="bb-text-support">Population:&lrm;</td>
                      <td>
                        {{ country.capitalPopulation | number }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <th scope="row">
                <span class="th-content"> Data center aligned </span>
              </th>
              <td class="text-center">Some random text here</td>
              <th scope="row">
                <span class="th-content"> Area (left aligned) </span>
              </th>
              <td>Area: {{ country.area }}</td>
              <th scope="row">
                <span class="th-content"> Data center aligned </span>
              </th>
              <td class="bb-text-align-center">
                <bb-badge-ui color="info" text="test"></bb-badge-ui>
              </td>
              <th scope="row" class="bb-text-align-right">
                <span class="th-content"> Data right aligned </span>
              </th>
              <td class="bb-text-align-right">
                {{ country.population | number }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <bb-pagination-ui
      (pageChange)="paginator.onPageChange($event)"
      [boundaryLinks]="paginator.boundaryLinks"
      [directionLinks]="paginator.directionLinks"
      [itemsPerPage]="paginator.itemsPerPage"
      [maxNavPages]="paginator.maxNavPages"
      [page]="paginator.page"
      [totalItems]="paginator.totalItems"
    >
    </bb-pagination-ui>
  </div>
</div>
