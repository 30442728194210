import { NgModule } from '@angular/core';
import { EllipsisComponent } from './ellipsis.component';
import { TooltipModule } from '@backbase/ui-ang/tooltip-directive';
import { NgClass } from '@angular/common';
import { IsContentTruncatedDirective } from './directives/is-content-truncated.directive';

const directives = [IsContentTruncatedDirective];

@NgModule({
  imports: [TooltipModule, NgClass],
  declarations: [EllipsisComponent, ...directives],
  exports: [EllipsisComponent, ...directives],
})
export class EllipsisModule {}
