import { InjectionToken } from '@angular/core';

export interface AvailableFeature {
  name: string;
  description: string;
}

/**
 * The configuration that determines how a payment card number should be
 * formatted.
 */
export interface PaymentCardNumberFormat {
  /**
   * Total length of the payment card number.
   */
  length: number;
  /**
   * Index range of characters that should be masked.
   */
  maskRange: [number, number];
  /**
   * The size (or array of sizes) the segments should have.
   */
  segments: number | Array<number>;
}

/**
 * Default payment card number format.
 */
export const DefaultPaymentCardNumberFormat: PaymentCardNumberFormat = {
  length: 16,
  maskRange: [0, 12],
  segments: 4,
};

/**
 * Lookup object containing payment card number formats for common payment card
 * vendors.
 */
export const PaymentCardNumberVendors: { [vendor: string]: PaymentCardNumberFormat } = {
  mastercard: {
    length: 16,
    maskRange: [0, 12],
    segments: 4,
  },
  visa: {
    length: 16,
    maskRange: [0, 12],
    segments: 4,
  },
  'visa-debit': {
    length: 16,
    maskRange: [0, 12],
    segments: 4,
  },
  'diners-club': {
    length: 16,
    maskRange: [0, 12],
    segments: 4,
  },
  'american-express': {
    length: 15,
    maskRange: [0, 11],
    segments: [4, 6, 5],
  },
};

/**
 * A token that enables numbers masking synchronization with backend
 * If set FE should not apply any masking and rely on the backend masking behavior
 *
 * @internal
 */
export const SYNCHRONIZE_NUMBERS_MASKING: InjectionToken<boolean> = new InjectionToken<boolean>(
  'Enables accounts masking synchronization with backend',
  {
    providedIn: 'root',
    factory() {
      return false;
    },
  },
);
