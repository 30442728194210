import { Injectable } from '@angular/core';

/**
 * Metadata/Reflect API will not work with optimizeBuild:true compilation
 * https://github.com/angular/angular/issues/17595
 */
interface MiniMenuItem {
  [key: string]: Array<string>;
}

export interface BbComponentRef {
  template: string;
  selector: string;
}

@Injectable({ providedIn: 'root' })
export class MetadataService {
  constructor() {}

  getModuleExports(module: any): any[] {
    if (module.uiComponents) {
      return module.uiComponents;
    }

    return module.decorators ? module.decorators[0].args[0].exports : [];
  }

  getParsedSelectors(module: any): string {
    const component = this.getComponent(module);

    return component ? component.selector.replace('bb-', '').replace('-example-ui', '').replace('-page-ui', '') : '';
  }

  getComponent(module: any): BbComponentRef | undefined {
    try {
      return module.decorators[0].args[0];
    } catch (err) {
      return undefined;
    }
  }
}
