import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentCardShowcaseComponent } from './payment-card-showcase.component';
import { PaymentCardModule } from '@backbase/ui-ang/payment-card';

@NgModule({
  imports: [CommonModule, PaymentCardModule],
  declarations: [PaymentCardShowcaseComponent],
})
export class PaymentCardShowcaseModule {}
