<form [formGroup]="vForm">
  <div class="row mb-3">
    <div class="col-md-3">
      <bb-input-text-ui
        [id]="'input-validation-example-input'"
        label="Validation on Blur"
        formControlName="name"
        aria-describedby="input-validation-example-error"
        [aria-invalid]="!isFieldValid('name')"
        aria-errormessage="input-validation-example-error"
      >
      </bb-input-text-ui>
      <bb-input-validation-message-ui [showErrors]="!isFieldValid('name')" [id]="'input-validation-example-error'">
        @if (hasRequiredError('name')) {
          <span> Field is required. </span>
        }
        @if (hasLengthError('name')) {
          <span> Field has minlength of 5. </span>
        }
      </bb-input-validation-message-ui>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-4">
      <bb-input-text-ui
        [id]="'input-validation-example-input-without-icon'"
        label="Validation on Blur without icon"
        formControlName="withoutIcon"
        aria-describedby="input-validation-example-error-without-icon"
        [aria-invalid]="!isFieldValid('withoutIcon')"
        aria-errormessage="input-validation-example-error-without-icon"
      >
      </bb-input-text-ui>
      <bb-input-validation-message-ui
        [showErrors]="!isFieldValid('withoutIcon')"
        [showIcon]="false"
        [id]="'input-validation-example-error-without-icon'"
      >
        @if (hasRequiredError('withoutIcon')) {
          <span> Field is required. </span>
        }
        @if (hasLengthError('withoutIcon')) {
          <span> Field has minlength of 5. </span>
        }
      </bb-input-validation-message-ui>
    </div>
  </div>
  <!-- below button is to have another interactive element -->
  <button bbButton buttonSize="sm" class="m-2">Submit</button>
</form>
