@if (html$ | async; as html) {
  <div class="dso__main-content" [innerHtml]="html | safeHtml"></div>
} @else {
  @if (!failed) {
    <bb-loading-indicator-ui class="dso__loading-indicator" [loaderSize]="'md'"></bb-loading-indicator-ui>
  }
}
<ng-template #loading>
  @if (!failed) {
    <bb-loading-indicator-ui class="dso__loading-indicator" [loaderSize]="'md'"></bb-loading-indicator-ui>
  }
</ng-template>
@if (failed) {
  <div class="bb-header--not-found">
    <div class="bb-header--not-found__title">Oops...</div>
    <div class="bb-header--not-found__content">This page does not exist, please try again later.</div>
    <div class="bb-header--not-found__btn">
      <button bbButton [color]="'primary'" [buttonSize]="'md'" class="m-2 home__hero-action" (click)="reload()">
        Try again
      </button>
      <button bbButton [color]="'secondary'" [buttonSize]="'md'" class="m-2 home__hero-action" (click)="goToHomePage()">
        Go to Homepage
      </button>
    </div>
  </div>
}
