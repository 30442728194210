import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
/**
 * Handles a data store for passing scrollspy info
 */

@Injectable({ providedIn: 'root' })
export class ScrollSpyService {
  scrollStore = new ReplaySubject();
  stickyStore = new ReplaySubject();
  fragmentStore = new ReplaySubject();
  initialFragment: Array<any> = [];
  constructor() {}
}
