<div class="paragraph">
<p>Product selectors are used in Backbase products for two purposes:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Switching to a different product view</p>
</li>
<li>
<p>Choosing a product to perform an action with</p>
</li>
</ul>
</div>
<div class="sect1">
<h2 id="_high_level_anatomy">High-level anatomy</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The product selector consists of two main parts: +</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/product-selector-anatomy.png" alt="Product selector anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p>Trigger button</p>
</li>
<li>
<p>Popover containing product list +</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_detailed_anatomy">Detailed anatomy</h2>
<div class="sectionbody">
<div class="imageblock">
<div class="content">
<img src="assets/images/product-selector-detailed.png" alt="Product selector detailed anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic" start="3">
<li>
<p>Text label</p>
</li>
<li>
<p>Product list item</p>
</li>
<li>
<p>Product name/nickname</p>
</li>
<li>
<p>Account number</p>
</li>
<li>
<p>Account balance</p>
</li>
<li>
<p>Selected indicator</p>
</li>
</ol>
</div>
<div class="sect2">
<h3 id="_trigger_button">Trigger button</h3>
<div class="paragraph">
<p>The trigger button toggles the product list popover view.</p>
</div>
</div>
<div class="sect2">
<h3 id="_deselected_state">Deselected state</h3>
<div class="paragraph">
<p>The default state for the trigger button if no products have been selected.<br>
image::product-selector-deselected.png[Product selector deselected]</p>
</div>
</div>
<div class="sect2">
<h3 id="_selected_state">Selected state</h3>
<div class="paragraph">
<p>The state for the trigger button after a user has selected a product or the default state if a product has been pre-selected in the widget.<br>
image::product-selector-selected.png[Product selector selected]</p>
</div>
</div>
<div class="sect2">
<h3 id="_product_list">Product list</h3>
<div class="paragraph">
<p>The product list is displayed in a popover component beneath the trigger button. The popover container height adjusts to the content of the list when there are four products or fewer.</p>
</div>
<div class="paragraph">
<p><strong>List with no selected items</strong></p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/product-selector-list.png" alt="Product selector list">
</div>
</div>
<div class="paragraph">
<p><strong>List with single selected item</strong></p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/product-selector-list-selected.png" alt="Product selector list selected">
</div>
</div>
<div class="paragraph">
<p><strong>List with many items</strong></p>
</div>
<div class="paragraph">
<p>When the user has five or more products they can switch between or perform an action with, the popover height fixes and a scroll interaction is introduced. Where possible, the scrollbar should persist and the last visible item should be only partially displayed to hint there are more items below in the list.<br>
image::product-selector-list-many.png[Product selector list many selected]</p>
</div>
</div>
<div class="sect2">
<h3 id="_list_item_states">List item states</h3>
<div class="paragraph">
<p><strong>Deselected state</strong></p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/product-selector-item-deselected.png" alt="Product selector list item deselected">
</div>
</div>
<div class="paragraph">
<p><strong>Selected state</strong></p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/product-selector-item-selected.png" alt="Product selector list item selected">
</div>
</div>
<div class="paragraph">
<p><strong>Hover state</strong></p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/product-selector-item-hover.png" alt="Product selector list item hovered">
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_list_with_section_headings">List with section headings</h3>
<div class="paragraph">
<p>When there is more than one type or category of item in the list, section headings can be used to separate them. For example, when the user is creating a SEPA payment, the user can choose to move money to one of their own products or a contact’s account. The section heading will keep these list items clearly separate.<br>
image::product-selector-heading.png[Product selector with heading]</p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use this component when you require a user to select one of their products to perform an action.</p>
</li>
<li>
<p>Use this component when a user might want a quick way to switch the view to data for another product.</p>
</li>
<li>
<p>Use a clear, concise label for the trigger button component so the user knows what to expect when they interact with the product selector.</p>
</li>
<li>
<p>Allow the information in the trigger button and list item to wrap to multiple lines.Use the component at it’s recommended dimensions (unless scaling down width for smaller screens or increasing height to accommodate line-wrapping).</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use this component when you need to choose multiple products.</p>
</li>
<li>
<p>Use the component without a label indicating what the selector is for.</p>
</li>
<li>
<p>Truncate the product name/nickname, the distinguishing information might be out of view.</p>
</li>
<li>
<p>Stretch the selector to the full width of its container if it is larger than the maximum width of the selector.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_interaction">Interaction</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_triggering_the_product_list">Triggering the product list</h3>
<div class="paragraph">
<p>Clicking anywhere on the trigger button activates the product list popover. The popover appears directly beneath the trigger button.</p>
</div>
</div>
<div class="sect2">
<h3 id="_dismissing_the_product_list">Dismissing the product list</h3>
<div class="paragraph">
<p>Clicking anywhere outside or tabbing away from the popover area dismisses it. If no products were selected initially, there will still be no products selected. If a product was selected initially, this selection will remain.</p>
</div>
</div>
<div class="sect2">
<h3 id="_navigating_the_product_list">Navigating the product list</h3>
<div class="paragraph">
<p>Tabbing through the list shows the hover state on focus. If in a scrollable popover, the tabbing action automatically scrolls to have the focused item in view.</p>
</div>
</div>
<div class="sect2">
<h3 id="_scrolling">Scrolling</h3>
<div class="paragraph">
<p>The user should be able to use native OS scroll gestures in scrollable popovers. The scrollbar should remain visible and clicking and dragging the bar will allow the user to adjust the scroll position.</p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_selecting_a_product">Selecting a product</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Hovering anywhere inside a list item shows the hover state. Clicking anywhere on a list item selects it and the popover will be automatically dismissed.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_trigger_button_2">Trigger button</h3>
<div class="paragraph">
<p><strong>Deselected state</strong></p>
</div>
<div class="paragraph">
<p>The height of the trigger button is maintained but the width is now 100% width of its container.<br>
image::product-selector-button-deselected.png[Product selector button deselected]</p>
</div>
<div class="paragraph">
<p><strong>Selected state</strong></p>
</div>
<div class="paragraph">
<p>The account balance is now stacked underneath the account information and left aligned. The height of the trigger button increases to accommodate this change.<br>
image::product-selector-button-selected.png[Product selector button selected]</p>
</div>
</div>
<div class="sect2">
<h3 id="_product_list_items">Product list items</h3>
<div class="paragraph">
<p>As with the trigger button, the account balance stacks below the account information. The product name/nickname can break into multiple lines and the height of the list item increases to accommodate it.<br>
image::product-selector-responsiveness.png[Product selector]</p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_trigger_button_3">Trigger button</h3>
<div class="paragraph">
<p>The user can navigate to the trigger button using keyboard controls. There must be a label for the button, preferably visible, which provides context on the component purpose and the label is read by a screen reader when the button is is focus.</p>
</div>
</div>
<div class="sect2">
<h3 id="_product_information_labeling">Product information labeling</h3>
<div class="paragraph">
<p>For the information in the trigger button and list item, the supporting content should have an appropriate aria label and the priority should be:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Product name/nickname</p>
</li>
<li>
<p>Account number</p>
</li>
<li>
<p>Account balance<br>
image::product-selector-accessibility.png[Product selector accessibility]</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>The content in this item should read:</p>
</div>
<div class="quoteblock">
<blockquote>
<div class="paragraph">
<p>“Ricardo Norton’s High interest savings account. Account number, LV two zero[…]. Account balance, eighty nine million[…] dollars and fifty-four cents.”</p>
</div>
</blockquote>
</div>
</div>
<div class="sect2">
<h3 id="_selecting_a_product_item">Selecting a product item</h3>
<div class="paragraph">
<p>The user should be able to select an item using a keypress event. The popover is dismissed automatically when a list item is selected using the keyboard.</p>
</div>
</div>
<div class="sect2">
<h3 id="_color_contrast">Color contrast</h3>
<div class="paragraph">
<p>The text styles used in this component have been tested and all pass a color contrast test at AA standard or higher.</p>
</div>
</div>
</div>
</div>