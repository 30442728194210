<div class="design-token-list">
  <p class="design-token-list__description">
    Design tokens are the visual design atoms of the design system — specifically, they are named entities that store
    visual design attributes. We use them in place of hard-coded values (such as hex values for color or pixel values
    for spacing) in order to maintain a scalable and consistent visual system for UI development.
  </p>

  <bb-tab-group-ui #tabs class="design-token-list__tabs" (select)="onTabSelect($event)" [initialSelection]="currentTab">
    @for (token of designTokens; track $index) {
      <bb-tab-ui>{{ humaniseString(token.type) | titlecase }}</bb-tab-ui>
    }
  </bb-tab-group-ui>

  <div class="design-token-list__filters" [formGroup]="designTokensFilter">
    <bb-dropdown-single-select-ui
      class="design-token-list__filters-dropdown"
      [options]="platforms"
      formControlName="platforms"
      [disabled]="true"
    ></bb-dropdown-single-select-ui>
  </div>

  <div class="design-token-list__table">
    @for (token of designTokens; track $index) {
      <div #tableContainer class="design-token-list__table-list" (scroll)="onScrollTableList($event)">
        @if (currentTab === $index) {
          <bb-design-token-list-table [tokens]="designTokens[currentTab].tokens"></bb-design-token-list-table>
        }
      </div>
    }
    @if (!scrollTableEnd) {
      <div class="design-token-list__table--overflow"></div>
    }
  </div>
</div>
