import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { PaymentCard } from '@backbase/ui-ang/payment-card';
import { PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';

@Component({
  selector: 'bb-payment-card-example-ui',
  templateUrl: './payment-card.example.component.html',
})
export class PaymentCardExampleComponent {
  paymentCard: PaymentCard = {
    name: 'Ricardo Peterson',
    number: '5678',
    vendor: 'mastercard',
    expirationDate: new Date(2021, 2),
    cardName: 'Backbase Blue Card',
  };

  numberFormat: PaymentCardNumberFormat = {
    length: 16,
    maskRange: [0, 12],
    segments: 4,
  };
}
