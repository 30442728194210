import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { SwitchComponent } from './switch.component';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  imports: [NgClass, FormsModule, KeyboardClickModule],
  declarations: [SwitchComponent],
  exports: [SwitchComponent],
})
export class SwitchModule {}
