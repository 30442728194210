import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { InputBaseComponent } from '@backbase/ui-ang/base-classes';
import { idListAttr } from '@backbase/ui-ang/util';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

import { DateFormatParts } from './input-date-divided-helper';

/**
 * @name InputTextComponent
 *
 * @description
 * Component that displays a date input with different inputs for day, month, year .
 *
 * @deprecated Component is deprecated as of ui-ang v11. It will be removed in ui-ang v13. Please use InputDatepickerComponent instead.
 */
@Component({
  selector: 'bb-input-date-divided',
  templateUrl: './input-date-divided.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateDividedComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputDateDividedComponent),
      multi: true,
    },
  ],
})
export class InputDateDividedComponent extends InputBaseComponent implements OnInit, OnDestroy {
  /**
   * Whether the text input is readonly.
   */
  @Input() readonly = false;

  /**
   * date format to order the text boxes
   */
  @Input() format?: string;

  textOrder: Array<string> = [];

  dateFormatParts = DateFormatParts;

  dayControl = new UntypedFormControl(null, [Validators.required, Validators.max(31), Validators.min(1)]);

  monthControl = new UntypedFormControl(null, [Validators.required, Validators.max(12), Validators.min(1)]);

  yearControl = new UntypedFormControl(null, [Validators.required, Validators.min(1000), Validators.max(9999)]);

  /**
   * Utility function for use in template
   */
  public idListAttr = idListAttr;

  destroySubject: Subject<any> = new Subject<any>();

  @ViewChild('inputDay') inputDay: ElementRef | undefined;
  @ViewChild('inputMonth') inputMonth: ElementRef | undefined;
  @ViewChild('inputYear') inputYear: ElementRef | undefined;
  constructor(
    protected readonly cd: ChangeDetectorRef,
    private readonly formBuilder: UntypedFormBuilder,
    @Inject(LOCALE_ID) private locale: string,
  ) {
    super(cd);
  }

  ngOnInit(): void {
    const locale = this.getLocaleFormat();
    this.textOrder = this.format ? this.format.split('-') : this.getTextOrderFromLocales(locale);

    combineLatest([
      this.dayControl.valueChanges.pipe(startWith(null)),
      this.monthControl.valueChanges.pipe(startWith(null)),
      this.yearControl.valueChanges.pipe(startWith(null)),
    ])
      .pipe(takeUntil(this.destroySubject), distinctUntilChanged())
      .subscribe(([day, month, year]) => {
        this.onChange({ day, month, year });
      });
  }

  writeValue(data: { day: number; month: number; year: number }) {
    if (data) {
      this.dayControl.patchValue(data.day);
      this.dayControl.markAsTouched();
      this.monthControl.patchValue(data.month);
      this.yearControl.patchValue(data.year);
    } else {
      this.checkErrors(this.dayControl);
      this.checkErrors(this.monthControl);
      this.checkErrors(this.yearControl);
    }
  }

  getInputsValid(): boolean {
    return this.dayControl.valid && this.monthControl.valid && this.yearControl.valid;
  }

  getInputsTouched(): boolean {
    return this.dayControl.touched || this.monthControl.touched || this.yearControl.touched;
  }

  showDayErrors() {
    const errors = this.dayControl.errors;
    if (errors?.required) {
      return 'dayRequired';
    } else if (errors?.min) {
      return 'dayMin';
    } else if (errors?.max) {
      return 'dayMax';
    } else {
      return;
    }
  }

  showMonthErrors() {
    const errors = this.monthControl.errors;
    if (errors?.required) {
      return 'monthRequired';
    } else if (errors?.min) {
      return 'monthMin';
    } else if (errors?.max) {
      return 'monthMax';
    } else {
      return;
    }
  }

  showYearErrors() {
    const errors = this.yearControl.errors;
    if (errors?.required) {
      return 'yearRequired';
    } else if (errors?.min) {
      return 'yearMin';
    } else if (errors?.max) {
      return 'yearMax';
    } else {
      return;
    }
  }

  validate(control: UntypedFormControl) {
    if (!this.getInputsValid() && this.getInputsTouched()) {
      control.markAsTouched();
      const errors: any = {};
      this.textOrder.forEach((value) => {
        if (value === this.dateFormatParts.DD) {
          const dayError = this.showDayErrors();
          if (dayError) {
            errors[dayError] = true;
          }
        }

        if (value === this.dateFormatParts.MM) {
          const monthError = this.showMonthErrors();
          if (monthError) {
            errors[monthError] = true;
          }
        }

        if (value === this.dateFormatParts.YY) {
          const yearError = this.showYearErrors();
          if (yearError) {
            errors[yearError] = true;
          }
        }
      });

      if (!this.dayControl.value) {
        this.dayControl.markAsTouched();
      }

      if (!this.monthControl.value) {
        this.monthControl.markAsTouched();
      }

      if (!this.yearControl.value) {
        this.yearControl.markAsTouched();
      }

      return errors;
    }

    return null;
  }

  getTextOrderFromLocales(localFormat: string): Array<string> {
    const order = localFormat.split(' ').map((localePart: string) => {
      if (localePart.toLowerCase().indexOf('d') >= 0) {
        return this.dateFormatParts.DD;
      } else if (localePart.toLowerCase().indexOf('m') >= 0) {
        return this.dateFormatParts.MM;
      } else {
        return this.dateFormatParts.YY;
      }
    });

    return order;
  }

  getLocaleFormat(): string {
    return getLocaleDateFormat(this.locale, FormatWidth.Medium);
  }

  checkErrors(control: UntypedFormControl) {
    control.markAllAsTouched();
    control.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.destroySubject.next(true);
    this.destroySubject.unsubscribe();
  }
}
