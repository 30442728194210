<div class="bb-block bb-block--xl">
  <h4>Default</h4>
  <bb-amount-ui [amount]="12345.47" bbHighlight></bb-amount-ui>
</div>
<div class="bb-block bb-block--xl">
  <h4>Highlight with positive and negative</h4>
  <bb-amount-ui
    [amount]="12345.47"
    positiveClass="bb-amount--positive"
    negativeClass="bb-amount--negative"
    bbHighlight
  ></bb-amount-ui>
  <br />
  <bb-amount-ui
    [amount]="-12345.47"
    positiveClass="bb-amount--positive"
    negativeClass="bb-amount--negative"
    bbHighlight
  ></bb-amount-ui>
</div>
<div>
  <h4>Highlight off</h4>
  <bb-amount-ui [amount]="12345.47" [bbHighlight]="false"></bb-amount-ui>
</div>
