<div class="bb-block bb-block--xl">
  <h4>Default</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-savings-account-ui
        [title]="'John Doe'"
        [productNumber]="'NL75 INGB 2000 1200 56'"
        [amount]="177425.99"
        [currency]="'EUR'"
        [highlight]="false"
        [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
        [favorite]="true"
      >
      </bb-product-item-savings-account-ui>
    </div>
  </div>
</div>

<div>
  <h4>With owners and with main section</h4>
  <div class="card">
    <div class="card-body">
      <bb-product-item-savings-account-ui
        [title]="'John Doe'"
        [productNumber]="'NL75 INGB 2000 1200 56'"
        [amount]="177425.99"
        [currency]="'EUR'"
        [highlight]="false"
        [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
        [favorite]="true"
        [mainSectionCurrency]="'EUR'"
        [mainSectionAmount]="880.54"
        [mainSectionTitle]="'Available balance'"
        [productDetailsInfo]="{
          owners: 'Ricardo Norton'
        }"
      >
      </bb-product-item-savings-account-ui>
    </div>
  </div>
</div>
