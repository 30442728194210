import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeCounterShowcaseComponent } from './badge-counter-showcase.component';
import { BadgeCounterModule } from '@backbase/ui-ang/badge-counter';

@NgModule({
  imports: [CommonModule, BadgeCounterModule],
  declarations: [BadgeCounterShowcaseComponent],
})
export class BadgeCounterShowcaseModule {}
