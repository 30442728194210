import { Component, Input } from '@angular/core';

export type ActionState = 'loading' | 'success' | 'info' | 'warning' | 'error' | 'message';

/**
 * @name ActionStatusComponent
 *
 * @description
 * Component that displays a loading indicator or icon to indicate an action response.
 */
@Component({
  selector: 'bb-action-status-ui',
  templateUrl: './action-status.component.html',
})
export class ActionStatusComponent {
  /**
   * The current state of the action.
   */
  @Input() state?: ActionState;

  isLoading() {
    return this.state === 'loading';
  }

  getColor(): string | undefined {
    switch (this.state) {
      case 'message':
        return 'info';
      case 'error':
        return 'danger';
      default:
        return this.state;
    }
  }
}
