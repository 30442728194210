import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * @name ErrorCommonStateComponent
 *
 * @description
 * Component that displays error state based on error value that was provided.
 */
@Component({
  selector: 'bb-common-error-state-ui',
  templateUrl: 'common-error-state.component.html',
})
export class ErrorCommonStateComponent {
  /**
   * Error that should be displayed. Could be HttpErrorResponse, Error, Template reference or string.
   * Defaults to empty string.
   */
  @Input() error: HttpErrorResponse | Error | TemplateRef<any> | string = '';
  /**
   * Bad Request error template reference
   */
  @ViewChild('errorBadRequest', { static: true }) errorBadRequest: TemplateRef<any> | undefined;
  /**
   * Unauthorized error template reference
   */
  @ViewChild('errorUnauthorized', { static: true }) errorUnauthorized: TemplateRef<any> | undefined;
  /**
   * Forbidden error template reference
   */
  @ViewChild('errorForbidden', { static: true }) errorForbidden: TemplateRef<any> | undefined;
  /**
   * Not Found error template reference
   */
  @ViewChild('errorNotFound', { static: true }) errorNotFound: TemplateRef<any> | undefined;
  /**
   * Connectivity error template reference
   */
  @ViewChild('errorConnectivityTemplate', { static: true }) errorConnectivityTemplate: TemplateRef<any> | undefined;
  /**
   * Unexpected error template reference
   */
  @ViewChild('errorUnexpectedTemplate', { static: true }) errorUnexpectedTemplate: TemplateRef<any> | undefined;
  /**
   * Message error template reference
   */
  @ViewChild('errorMessageTemplate', { static: true }) errorMessageTemplate: TemplateRef<any> | undefined;
  /**
   * Text template reference
   */
  @ViewChild('errorTextTemplate', { static: true }) errorTextTemplate: TemplateRef<any> | undefined;
  /**
   * Custom template reference
   */
  @ViewChild('errorCustomTemplate', { static: true }) errorCustomTemplate: TemplateRef<any> | undefined;

  getErrorTemplate() {
    if (this.error instanceof HttpErrorResponse) {
      switch (this.error.status) {
        case 400:
          return this.errorBadRequest;
        case 401:
          return this.errorUnauthorized;
        case 403:
          return this.errorForbidden;
        case 404:
          return this.errorNotFound;
        case 0:
          return this.errorConnectivityTemplate;
        default:
          return this.errorUnexpectedTemplate;
      }
    } else if (this.error instanceof Error) {
      return this.errorMessageTemplate;
    } else if (typeof this.error === 'string') {
      return this.errorTextTemplate;
    } else if (this.error instanceof TemplateRef) {
      return this.errorCustomTemplate;
    }

    return undefined;
  }

  getMessage() {
    return this.error instanceof Error ? this.error.message : this.error;
  }
}
