@if (label) {
  <span
    (bbKeyboardClick)="$event.preventDefault()"
    [id]="'bb-dropdown-multi-select-label' + id"
    class="form-label bb-dropdown-multi-select__label"
  >
    {{ label }}
  </span>
}

<div #dropDownMenu>
  <bb-dropdown-panel-ui
    #toggleButton
    (open)="onDropdownToggle($event)"
    [ngClass]="{ 'bb-dropdown-multi-select--disabled': disabled || readonly }"
    class="bb-dropdown-multi-select"
    [required]="required ? true : undefined"
    [aria-expanded]="isOpen"
    [aria-labelledby]="label ? 'bb-dropdown-multi-select-label' + id : getLabeledByIds(ariaLabelledby, buttonLabelId)"
    [aria-describedby]="ariaDescribedby"
    [aria-activedescendant]="ariaActivedescendant"
    [aria-invalid]="ariaInvalid"
    [aria-owns]="ariaOwns"
    [aria-label]="ariaLabel"
    [disabled]="disabled"
    aria-haspopup="listbox"
    (focus)="onFocus()"
    (focusout)="onFocusOut($event)"
    [position]="dropdownPosition"
    [container]="container"
    buttonClass="bb-stack--wrap bb-dropdown-multi-select__toggle bb-stack bb-text-align-start form-control"
    id="{{ id }}"
  >
    <ng-template bbDropdownToggle let-isOpen="isOpen">
      <span class="bb-dropdown-multi-select__result" [id]="buttonLabelId" [attr.data-role]="'dropdown-result'">
        @if ((selectedValues$ | async)?.length) {
          <span>
            @for (item of selectedValues$ | async; track item) {
              {{ labelsMap[item] + ($last ? '' : ', ') }}
            }
          </span>
        } @else {
          <span class="bb-text-support">{{ placeholder }}</span>
        }
      </span>
      <span class="bb-stack__item bb-stack__item--push-right">
        <bb-icon-ui [cropped]="true" [name]="isOpen ? 'toggle-up' : 'toggle-down'"></bb-icon-ui>
      </span>
    </ng-template>
    <ng-template bbDropdownMenu>
      <div>
        <button
          [attr.data-role]="'clear-btn'"
          #clearOptions
          bbButton
          color="unstyled"
          class="bb-dropdown-multi-select__clear-button"
          (click)="clearAll($event)"
          i18n="Clear selection button text@@dropdown-multiple-select.clear-selection.button"
          (focusout)="onFocusOut($event)"
        >
          Clear selection
        </button>
      </div>
      <div class="dropdown-divider"></div>
      @if (dropdownHeaderText) {
        <div [id]="'bb-dropdown-multi-select-header_' + id" class="dropdown-header">
          {{ dropdownHeaderText }}
        </div>
      }
      @if (contentOptions?.length && formGroup.controls[contentOptions?.first.value]) {
        <div
          #listbox
          role="listbox"
          aria-multiselectable="true"
          tabindex="0"
          aria-label="Dropdown multi select option list"
          i18n-aria-label="Dropdown multi select option list@@dropdown-multi-select-ui.options-list.aria-label"
          [attr.aria-labelledby]="dropdownHeaderText ? getLabeledByIds('bb-dropdown-multi-select-header_' + id) : null"
          class="bb-dropdown-multi-select__options-list"
          [attr.aria-activedescendant]="
            activeOptionIndex >= 0 ? 'bb-dropdown-multi-select-option-' + (id + activeOptionIndex) : undefined
          "
          (keydown)="onListboxKeyDown($event)"
          (focus)="onListboxFocus()"
          (blur)="onListboxBlur()"
          (focusout)="onFocusOut($event)"
        >
          @for (contentOption of contentOptions; track trackByValueFn($index, contentOption)) {
            <div
              #option
              (bbKeyboardClick)="label.click()"
              role="option"
              class="dropdown-item dropdown-option form-label"
              [ngClass]="{
                'active dropdown-option--active': activeOptionIndex === $index,
                'dropdown-option--selected': !!formGroup.controls[contentOption.value].value
              }"
              [attr.data-role]="'option-' + $index"
              [attr.aria-selected]="formGroup.controls[contentOption.value].value"
              [id]="'bb-dropdown-multi-select-option-' + id + $index"
            >
              <label
                class="bb-text-default"
                (bbKeyboardClick)="activeOptionIndex = $index; $event.stopPropagation()"
                #label
              >
                <input
                  class="dropdown-option__input"
                  type="checkbox"
                  [attr.data-role]="'option-' + (id + $index + 1)"
                  [formControl]="formGroup.controls[contentOption.value]"
                />
                <span [innerHTML]="getLabel(contentOption.label)"></span>
              </label>
            </div>
          }
        </div>
      }
    </ng-template>
  </bb-dropdown-panel-ui>
</div>
