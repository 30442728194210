<form [formGroup]="vForm">
  <bb-input-phone-ui
    label="Work Phone"
    formControlName="workPhone"
    placeholder="Phone number without country code"
    autoComplete="tel-national"
  ></bb-input-phone-ui>

  <bb-input-phone-ui
    [id]="'phoneNumberExample'"
    label="Phone Number"
    formControlName="workPhone"
    placeholder="Enter a phone number"
    [required]="true"
    [enableCountryCode]="true"
    defaultCountryIsoCode="BR"
    [countryList]="countries"
  >
  </bb-input-phone-ui>

  <bb-input-phone-ui
    [id]="'phoneNumberExampleDisabledCountryCode'"
    label="Phone Number"
    formControlName="workPhone"
    placeholder="Enter a phone number"
    [required]="true"
    [enableCountryCode]="true"
    [countryList]="country"
  >
  </bb-input-phone-ui>
</form>
