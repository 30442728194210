<div class="paragraph">
<p>Badges are labels which hold small amount of information to help the user’s attention to new, relevant or updated content within the system.</p>
</div>
<div class="sect1">
<h2 id="_badge_types">Badge types</h2>
<div class="sectionbody">
<div class="paragraph">
<p>There are 2 types of badges in our product: <strong>badge</strong> and <strong>badge counter.</strong></p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/badge-type.png" alt="Badge Type">
</div>
</div>
<div class="sect2">
<h3 id="_badge">Badge</h3>
<div class="paragraph">
<p>Badges can be used to represent categories of an item, such as in the status of a Payment.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use Badge when content is mapped to multiple categories, and the user needs a way to differentiate between them.</p>
</li>
<li>
<p>Badge can also be used as a method of filtering data, to show only items within that particular category.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_badge_counter">Badge counter</h3>
<div class="paragraph">
<p>Badge counter contain alphanumeric values and can be used to indicate a running counter, such as number of unread messages or notifications.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use badges in conjunction with a single item so that there is no ambiguity around which item is indicated.</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_colors">Colors</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When using badges for categories, use established color patterns so that users can quickly identify the meaning you are trying to convey.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_content">Content</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p>Use a single word to describe the status of an object.</p>
</li>
<li>
<p>Only use two words if you need to describe a complex state. <em>E.g. “Pending Approval”</em>.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Badges change appearance in relation to the context and the message you are trying to convey. By using available modifier classes, badges background color will change.</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Info</strong><br>
Gives the recipient neutral information unrelated to their previous actions, such as the status of an item.</p>
</li>
<li>
<p><strong>Success</strong> (positive status)<br>
Informs the recipient that there is a positive outcome from a previous action. It may not require any action.</p>
</li>
<li>
<p><strong>Error</strong> (negative status)<br>
Tells the recipient something they need to pay attention to. This may require an action or decision to prevent undesirable consequences.</p>
</li>
<li>
<p><strong>Warning</strong> (in progress status)<br>
Tells the recipient something they need to do. This may require an action or decision to prevent undesirable consequences.</p>
</li>
<li>
<p><strong>Secondary</strong><br>
This is a neutral badge, you probably want to use this one unless you fall into a specific use-case.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don&#8217;ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When working with Badges, follow this recommendations to keep a consistent experience.</p>
</div>
<div class="paragraph">
<p><strong>Do&#8217;s</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use the badge counter with only numbers</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don&#8217;t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use red for notifications</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>There are no responsive specific guidelines for mobile devices. Badges should look and behave in the same way, regardless of the screen size.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="paragraph">
<p>There are no specific accessibility guidelines for this component besides general compliance as specified in our <a href="/accessibility/">accessibility page</a>.</p>
</div>
</div>
</div>