import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ComponentMetaDataService } from '../../../services/component-meta-data.service';
import { ShowcaseService, MenuComponent } from '../../../services/showcase.service';

type Platform = 'android' | 'ios' | 'design';
enum Platforms {
  ANDROID = 'android',
  IOS = 'ios',
  DESIGN = 'design',
}

@Component({
  selector: 'bb-mobile-showcase',
  templateUrl: './mobile-showcase.component.html',
})
export class MobileShowcaseComponent implements OnInit {
  tabsShown: Platform[] = [];
  activeComponent = '';

  constructor(
    private showcaseService: ShowcaseService,
    private componentMetadataService: ComponentMetaDataService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      this.setActiveComponent(paramMap);
    });
  }

  setActiveComponent(paramMap: any) {
    this.activeComponent = paramMap.params.id;
    const component: MenuComponent | undefined = this.showcaseService.getMobileComponent(this.activeComponent);
    const isAndroidComponent = this.componentMetadataService.isAndroidComponent(this.activeComponent);
    const isIOSComponent = this.componentMetadataService.isIOSComponent(this.activeComponent);
    this.tabsShown = [];

    if (isAndroidComponent) {
      this.tabsShown.push(Platforms.ANDROID);
    }

    if (isIOSComponent) {
      this.tabsShown.push(Platforms.IOS);
    }

    if (component?.designGuidelinesPath) {
      this.tabsShown.push('design');
    }

    // Only route when no specific platform is included in URL
    if (
      !(
        this.router.url.includes(Platforms.ANDROID) ||
        this.router.url.includes(Platforms.IOS) ||
        this.router.url.includes(Platforms.DESIGN)
      )
    ) {
      let defaultRoute: Platform | '' = '';

      if (isIOSComponent) {
        defaultRoute = Platforms.IOS;
      }
      if (isAndroidComponent) {
        defaultRoute = Platforms.ANDROID;
      }

      this.router.navigate([`${defaultRoute}`], { relativeTo: this.route });
    }
  }
}
