<div class="paragraph">
<p>Use steppers to help users keep track of their progress when completing a specific task divided in a set of steps. Steppers display to users where they are in the process, and can be used to navigate through the process by selecting steps.</p>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_anatomy_of_a_stepper">Anatomy of a stepper</h3>
<div class="imageblock">
<div class="content">
<img src="assets/images/steppers-anatomy.png" alt="Stepper anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Completed step</strong><br>
Steps that have already been visited display a checkmark icon. If the stepper is non-linear, labels will be clickable, so that users can navigate back to them.</p>
</li>
<li>
<p><strong>Current step</strong><br>
Shows the current step that the user is on by displaying the number of the step and the label in bold.</p>
</li>
<li>
<p><strong>Unvisited step</strong><br>
Shows steps that user has not visited. The step and label are more subtle to indicate this.</p>
</li>
</ol>
</div>
</div>
<div class="sect2">
<h3 id="_types_of_steppers">Types of steppers</h3>
<div class="paragraph">
<p><strong>Linear steppers</strong><br>
Linear steppers require users to complete one step in order to move on to the next. Steps follow a specific order and users cannot skip a step or navigate back to a previous step.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/steppers-linear.png" alt="Linear stepper">
</div>
</div>
<div class="paragraph">
<p><strong>Non-linear steppers</strong><br>
Non-linear steppers allow users to complete the steps in random order. Users can also navigate to a previously completed step.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/steppers-nonlinear.png" alt="Non-linear stepper">
</div>
</div>
</div>
<div class="sect2">
<h3 id="_placement">Placement</h3>
<div class="paragraph">
<p>Steppers are flexible to be placed in different containers such as widgets or modals. Placement is dependent on use case but the general guideline is for them to sit at the top of the container, taking full-width space and close to related information in the page.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Always center a stepper above content.</p>
</li>
<li>
<p>Steppers automatically distribute steps evenly within the horizontal space they are afforded.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_interaction">Interaction</h3>
<div class="ulist">
<ul>
<li>
<p>Allow the user to navigate the stepper by placing buttons at the bottom of the container.</p>
</li>
<li>
<p>Always use a Primary Button to progress to the next step and a Secondary Button to move to the previous step.</p>
</li>
<li>
<p>Use a button link to allow the user to cancel the workflow.</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/steppers-interaction.png" alt="Stepper interaction">
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_and_donts">Do&#8217;s and Don&#8217;ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong><br>
- Use labels that clearly indicate the purpose of the step.<br>
- Always indicate the current step. Keeping the user informed of where they currently are within the process or task at hand will give them a sense of control.<br>
- Display the steps in logical progression, from left to right.</p>
</div>
<div class="paragraph">
<p><strong>Don’t</strong><br>
- Use long names for steps, instead be short and concise.<br>
- Use a stepper for workflows with fewer than 3 or more than 6 steps. If a task needs more than 6 steps, consider simplifying the process or breaking it up into smaller tasks.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>On small devices the stepper takes full width of the mobile container but since labels no longer fit comfortably, only the label for the active step is displayed, above the stepper.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/steppers-responsive.png" alt="Stepper on Responsive Mobile">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p>When <strong>designing</strong> a stepper, consider sufficient color contrast and clear iconography, for more on design, review <a href="https://designsystem.backbase.com/accessibility/for-designers">key considerations for Accessibility</a>.</p>
</li>
<li>
<p>When <strong>developing</strong>, we recommend to include <code>aria-current="step"</code> to represent the active step.</p>
</li>
</ul>
</div>
</div>
</div>