import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDatepickerShowcaseComponent } from './input-datepicker-showcase.component';
import { InputDatepickerModule } from '@backbase/ui-ang/input-datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';

@NgModule({
  imports: [CommonModule, InputDatepickerModule, ReactiveFormsModule, FormsModule, InputValidationMessageModule],
  declarations: [InputDatepickerShowcaseComponent],
})
export class InputDatepickerShowcaseModule {}
