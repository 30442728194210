<bb-product-item-savings-account-ui
  [title]="product.name"
  [amount]="product.amount"
  [productNumber]="product.productNumber"
  [currency]="product.currency"
  [productNumberFormat]="productNumberFormat"
  [mainSectionCurrency]="interest.currency"
  [mainSectionAmount]="interest.amount"
  [mainSectionTitle]="interest.name"
  [productDetailsInfo]="productDetails"
  [highlight]="false"
  [favorite]="true"
></bb-product-item-savings-account-ui>
