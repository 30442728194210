import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemCreditCardShowcaseComponent } from './product-item-credit-card-showcase.component';
import { ProductItemCreditCardModule } from '@backbase/ui-ang/product-item-credit-card';
import { ProductItemBasicAccountModule } from '@backbase/ui-ang/product-item-basic-account';

@NgModule({
  imports: [CommonModule, ProductItemCreditCardModule, ProductItemBasicAccountModule],
  declarations: [ProductItemCreditCardShowcaseComponent],
})
export class ProductItemCreditCardShowcaseModule {}
