import { Component, Input } from '@angular/core';
import { DefaultPaymentCardNumberFormat, PaymentCardNumberFormat } from '@backbase/ui-ang/payment-card-number-pipe';

export type AccountStatus = 'closed' | 'inactive' | 'active';

/**
 * @name ProductItemBaseComponent
 *
 * @description
 * Base component for product items.
 */
@Component({
  template: '',
})
export class ProductItemBaseComponent {
  /**
   * The card title.
   */
  @Input() title?: string;
  /**
   * The product number.
   */
  @Input() productNumber?: string;
  /**
   * Configuration of how the product number should be formatted.
   * Defaults to the following format:
   * `{ length: 16, maskRange: [0, 12], segments: 4 }`
   */
  @Input() productNumberFormat: PaymentCardNumberFormat = DefaultPaymentCardNumberFormat;
  /**
   * Whether the amount and currency value should be highlighted.
   */
  @Input() highlight?: boolean;
  /**
   * The product details information.
   */
  @Input() productDetailsInfo?: Record<string, any>;
  /**
   * Whether the card should have a check icon. Defaults to true.
   */
  @Input() active = true;
  /**
   * Whether the card should be marked as favourite. Defaults to false.
   */
  @Input() favorite = false;
  /**
   * The color of the favourite icon when `favorite=true`. Defaults to primary.
   */
  @Input() favoriteIconColor = 'primary';
}
