<div class="paragraph">
<p>Tooltips are floating labels that are triggered when a user hovers or focuses on a page element such as an interface element or control without a label to provide a short description or useful information.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/tooltip.png" alt="Tooltip">
</div>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p>Use a tooltip to clarify the action or name or an interactive element such as an icon button whenever you consider it necessary. Content in these tooltips should be limited to one or two words.</p>
</li>
<li>
<p>Use when you need to provide additional information on a non-interactive element, such as displaying content behind truncated text or defining a term in your interface. For these tooltips, content should be brief and limited to plain text.</p>
</li>
<li>
<p>Use tooltips sparingly. If a screen requires a lot of tooltips, work on clarifying the interface design and the content.</p>
</li>
<li>
<p>Tooltips replace standard desktop browser tooltips where more emphasis is required.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When working with tooltips, follow this recommendations to keep a consistent experience.</p>
</div>
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use tooltips when you need to provide a short description of a page element or control</p>
</li>
<li>
<p>Place tooltips close to the element they are related to, it needs to be clear which element they are associated with.</p>
</li>
<li>
<p>Write content inside a tooltip in sentence case style.</p>
</li>
<li>
<p>Make content concise, tooltips should only include short, descriptive text.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>To communicate critical information, including errors in forms or other interaction feedback. For this use a <a href="/components/notification/">Notification</a> or <a href="/components/alert/">Alert</a> instead.</p>
</li>
<li>
<p>To display information on Charts and Graphs. Use Popover instead.</p>
</li>
<li>
<p>When you need to provide a description with more than a word or two. For these cases, use Popover as well.</p>
</li>
<li>
<p>The default trigger behavior (<em>click</em>) on interactive elements such as buttons and anchor links, for this cases choose <em>hover</em> instead.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_behavior">Behavior</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Displaying the tooltip</strong><br>
For truncated text, descriptions, system icons and other non-interactive elements, tooltips should be displayed on hover or keyboard focus on desktop, and on click on mobile devices.</p>
</div>
<div class="paragraph">
<p>For buttons, links and other interactive elements in a page, tooltips should displayed upon hover or keyboard focus on desktop, as to not interfere with the default behavior of their host element. On mobile devices, these tooltips will not be available for users.</p>
</div>
<div class="paragraph">
<p><strong>Size</strong></p>
</div>
<div class="paragraph">
<p>Tooltips grow horizontally to a maximum of 200px, and are flexible to grow vertically to fit the whole length of the title. Tooltips with zero-length titles are never displayed.</p>
</div>
<div class="paragraph">
<p><strong>Placement</strong><br>
The position of tooltips is flexible and will change depending on how close the element is to the edge of a screen or container. By default four options are available: <strong>top</strong>, <strong>right</strong>, <strong>bottom</strong>, and <strong>left</strong> aligned.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/tooltip-behaviour.png" alt="Tooltip behaviour">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Because we cannot use hover for touch devices, tooltips for interactive elements such as a button is not possible. For non-interactive elements such as a piece of truncated text, tooltips are triggered on click.</p>
</div>
</div>
</div>