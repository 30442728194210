import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';

import { CollapsibleCardComponent } from './collapsible-card.component';
import { CollapsibleModule } from '@backbase/ui-ang/collapsible';
import { IconModule } from '@backbase/ui-ang/icon';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  imports: [NgClass, CollapsibleModule, IconModule, KeyboardClickModule],
  declarations: [CollapsibleCardComponent],
  exports: [CollapsibleCardComponent],
})
export class CollapsibleCardModule {}
