import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductItemLoanShowcaseComponent } from './product-item-loan-showcase.component';
import { ProductItemLoanModule } from '@backbase/ui-ang/product-item-loan';

@NgModule({
  imports: [CommonModule, ProductItemLoanModule],
  declarations: [ProductItemLoanShowcaseComponent],
})
export class ProductItemLoanShowcaseModule {}
