<div class="paragraph">
<p>Tabs are an intuitive navigation element used to organize content by grouping similar information in a page.  Tabs are intuitive due to being a commonly used convention. Use tabs to group content and connect related information.</p>
</div>
<div class="sect1">
<h2 id="_anatomy_of_a_tab_component">Anatomy of a tab component</h2>
<div class="sectionbody">
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Tab Item</strong><br>
A small, labelled item that can be interacted with and selected. These control what information is shown.</p>
</li>
<li>
<p><strong>Tab List</strong><br>
This is where you can see all the available tabs in a single, <em>non-scrollable</em> row, above a separator <em>(3)</em> which separates Tab Item navigation and content.</p>
</li>
</ol>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/tabs-anatomy.png" alt="Tabs anatomy">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_behavior">Behavior</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When a user selects a tab, the related content appears. That content is unique and independent from what appears when the user selects other tabs.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_style_guidelines">Style guidelines</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p>The label text for each tab should have the same font size and be aligned centrally.</p>
</li>
<li>
<p>Allow enough padding on each side of the tab to ease readability.</p>
</li>
<li>
<p>The width of each tab is dependent on its label.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_content_guidelines">Content guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Tab labels provide clear and concise explanations of the content within.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use clear and brief labels on tabs, usually one or two words.</p>
</li>
<li>
<p>They shouldn’t be longer than one or two words and be accurate in their description.</p>
</li>
<li>
<p>Each tab&#8217;s content is independently categorized and mutually exclusive of the content of other tabs. They should also make sense when read in isolation (for example, by a screen reader).</p>
</li>
<li>
<p>Use sentence case for tab labels, <em>e.g. Own accounts, Incoming direct debits.</em></p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_best_practices">Best practices</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p>The minimum number of tabs you can use is two.</p>
</li>
<li>
<p>Avoid using more than five tabs.</p>
</li>
<li>
<p>It must always be clear which tab is the active tab.</p>
</li>
<li>
<p>In a Tab List, only use one row of tabs.</p>
</li>
<li>
<p>Try to place tabs in order, with the most relevant or useful content first.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Tab style changes according to modifier classes for each state:</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Active</strong><br>
This tab is selected by default, showing related content.</p>
</li>
<li>
<p><strong>Inactive</strong><br>
These tabs are ones that haven’t been selected yet. These will not be highlighted but they should still be clear.</p>
</li>
<li>
<p><strong>Hover</strong><br>
Style a tab gets when the user hovers over it.</p>
</li>
<li>
<p><strong>With a badge</strong><br>
Tabs can contain badges. These indicate there is a item, in the content behind the tab, that requires attention from the user. <em>E.g. Manage Payments</em></p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don&#8217;ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use tabs to separate content into different views that users can select one at a time without needing to go to a new page.</p>
</li>
<li>
<p>Use tabs when content or views are closely related and mutually exclusive.</p>
</li>
<li>
<p>Use tabs when users don’t need to see more than one section of content at a time.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Content from multiple tabs needs to be seen simultaneously</p>
</li>
<li>
<p>Content is sequential.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>On mobile devices and tablets, or whenever on mobile breakpoints, the tab list remains on place and <strong>horizontal scroll is introduced</strong>. To reveal any tabs not visible on the screen, the user taps and scrolls to the sides.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_considerations_for_screen_readers">Considerations for screen readers</h3>
<div class="paragraph">
<p>If you are using a screen reader with Javascript turned on, you should be able to navigate the tabs pattern in the following way:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use the tab key on your keyboard to access the tab labels.</p>
</li>
<li>
<p>Use the return key to select the tab.</p>
</li>
<li>
<p>On selection, keyboard focus is pushed to content under the tab list.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>The content under a tab list should always have a heading to reduce confusion for people using screen readers. This heading can be visually hidden.</p>
</div>
<div class="paragraph">
<p>Remember, the key questions that people need answering are:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>What tab am I on?</p>
</li>
<li>
<p>How many tabs are there?</p>
</li>
<li>
<p>How do I change the tab?</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>We would recommend using the ARIA tab model. This works across all major screen readers and browser combinations. <a href="http://heydonworks.com/practical_aria_examples/#tab-interface">Read more about ARIA and tabs here.</a></p>
</div>
</div>
</div>
</div>