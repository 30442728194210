<div class="paragraph">
<p>Use a progress bar to express the progress of a task of known duration, such as: upload, download, export or report generation; or to express the progress achieved towards a financial goal, like savings.</p>
</div>
<div class="sect1">
<h2 id="_anatomy_of_a_progress_bar">Anatomy of a progress bar</h2>
<div class="sectionbody">
<div class="imageblock">
<div class="content">
<img src="assets/images/progressbar-anatomy.png" alt="Progress bar anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Progress fill</strong><br>
A color fill that animates from left to right along the wrapper as the task progresses.</p>
</li>
<li>
<p><strong>Wrapper</strong><br>
A fixed width container for the fill to travel along, representing the whole of the process.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_placement">Placement</h3>
<div class="paragraph">
<p>A progress bar will fill its wrapper over time to show what proportion of the time or amount has been completed and how much is left.</p>
</div>
</div>
<div class="sect2">
<h3 id="_sizes">Sizes</h3>
<div class="paragraph">
<p>There are three height sizes to choose from, width is always flexible.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Small - 4px</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/progressbar-small.png" alt="Progress bar small">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>Default - 8px</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/progressbar-default.png" alt="Progress bar default">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>Large - 16px</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/progressbar-large.png" alt="Progress bar large">
</div>
</div>
</div>
<div class="sect2">
<h3 id="_colors">Colors</h3>
<div class="paragraph">
<p>Progress indicators can be assigned a severity level to display any of the colors in our support palette.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/progressbar-success.png" alt="Progress bar with _Success_ color">
</div>
</div>
<div class="paragraph">
<p>For more information on how we approach color, see <a routerLink="/foundations/color">how colors are structured in Design System</a></p>
</div>
</div>
<div class="sect2">
<h3 id="_stripes">Stripes</h3>
<div class="paragraph">
<p>For situations where the process is likely to take a long time or stall at a given point, an animated striped fill can be used to indicate that the system is still functioning.</p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don’ts</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_use_when">Use when</h3>
<div class="ulist">
<ul>
<li>
<p>The current status of a process needs to be visually shown</p>
</li>
<li>
<p>A user has started an activity with a duration longer than 5 seconds</p>
</li>
<li>
<p>A user needs to track a specific task or process</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_dont_use_when">Don&#8217;t use when</h3>
<div class="ulist">
<ul>
<li>
<p>The action is quick and the user doesn’t need to track the progress of it</p>
</li>
<li>
<p>The progress is indeterminate or the tasks performed have a short load time, for this cases, use a Loading indicator instead</p>
</li>
<li>
<p>Showing full page loads, for this, also use a Loading indicator</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_behaviour">Behaviour</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The progress bar animates automatically by expanding the fill along the wrapper as the task or process is being completed.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The progress bar itself is not responsive, but since three height sizes are supported, this component can be configured to the size that is more comfortable for users on a given device.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When <strong>designing</strong> a progress bar, consider there should be sufficient color contrast between the container background, the wrapper and the fill.</p>
</div>
<div class="paragraph">
<p>For more details on color contrast, check our <a routerLink="/accessibility/">accessibility tips for designers</a>.</p>
</div>
</div>
</div>