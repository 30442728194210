<div class="bb-list-multiple-select">
  @if (listMultipleSelectAction) {
    <div class="bb-list-multiple-select__action">
      <ng-container *ngTemplateOutlet="listMultipleSelectAction?.templateRef"> </ng-container>
    </div>
    <div class="bb-list-multiple-select__dropdown-divider"></div>
  }
  @if (control) {
    <ng-container
      [ngTemplateOutlet]="groupTemplate"
      [ngTemplateOutletContext]="{
        control: control,
        config: config,
        root: true
      }"
    ></ng-container>
  }

  <ng-template #groupTemplate let-control="control" let-config="config" let-root="root">
    <ul [formGroup]="control" class="bb-list-multiple-select__list" [ngClass]="{ 'bb-checkbox-group': !root }">
      @for (key of getKeys(control?.controls); track key) {
        <li>
          @if ((control?.controls)[key].controls) {
            <bb-checkbox-group-ui [formGroup]="(control?.controls)[key]" [aria-label]="key">
              <ng-container
                [ngTemplateOutlet]="listMultipleSelectItem?.templateRef || itemContent"
                [ngTemplateOutletContext]="{
                  config: config,
                  key: key
                }"
              ></ng-container>
            </bb-checkbox-group-ui>
            <ng-container
              [ngTemplateOutlet]="groupTemplate"
              [ngTemplateOutletContext]="{
                control: (control?.controls)[key],
                config: config && config[key]?.items
              }"
            ></ng-container>
          } @else {
            <bb-input-checkbox-ui [formControlName]="key" [aria-label]="key">
              <ng-container
                [ngTemplateOutlet]="listMultipleSelectItem?.templateRef || itemContent"
                [ngTemplateOutletContext]="{
                  config: config,
                  key: key
                }"
              ></ng-container>
            </bb-input-checkbox-ui>
          }
          <ng-template #itemContent let-config="config" let-key="key">
            @if (config && config[key]?.icon) {
              <bb-icon-ui [name]="config[key].icon" [color]="config[key].iconColor"></bb-icon-ui>
            }
            <span data-role="bb-list-multiple-select-item">{{ key }}</span>
          </ng-template>
        </li>
      }
    </ul>
  </ng-template>
</div>
