import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsibleCardShowcaseComponent } from './collapsible-card-showcase.component';
import { CollapsibleCardModule } from '@backbase/ui-ang/collapsible-card';

@NgModule({
  imports: [CommonModule, CollapsibleCardModule],
  declarations: [CollapsibleCardShowcaseComponent],
})
export class CollapsibleCardShowcaseModule {}
