<div class="code-container">
  <code class="language-js" [innerHTML]="_importStr"></code>
  <bb-icon-ui (click)="copyToClipboard(codeContainer)" name="content-copy"> file_copy </bb-icon-ui>
  <label for="import-container" class="visually-hidden">Plain text code snippet</label>
  <input
    #codeContainer
    [value]="importStrRaw"
    class="visually-hidden"
    id="import-container"
    readonly="true"
    type="text"
  />
</div>
