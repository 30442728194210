import { NgModule } from '@angular/core';

import { TableDirective } from './directives/table.directive';
import { TableRowDirective } from './directives/table-row.directive';
import { TableSortableDirective } from './directives/table-sortable.directive';
import { TableFocusDirective } from './directives/table-focus.directive';

const directives = [TableRowDirective, TableDirective, TableSortableDirective, TableFocusDirective];

@NgModule({
  declarations: [...directives],
  exports: [...directives],
})
export class TableModule {}
