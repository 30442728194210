<div class="bb-stack bb-stack--center">
  <div [style.width.px]="39" class="bb-stack__item">
    <bb-card-vendor-ui vendor="mastercard"></bb-card-vendor-ui>
  </div>
  <div [style.width.px]="48" class="bb-stack__item">
    <bb-card-vendor-ui vendor="visa-debit"></bb-card-vendor-ui>
  </div>
  <div [style.width.px]="38" class="bb-stack__item">
    <bb-card-vendor-ui vendor="union-pay"></bb-card-vendor-ui>
  </div>
  <div [style.width.px]="39" class="bb-stack__item">
    <bb-card-vendor-ui vendor="maestro"></bb-card-vendor-ui>
  </div>
  <div [style.width.px]="31" class="bb-stack__item">
    <bb-card-vendor-ui vendor="diners-club"></bb-card-vendor-ui>
  </div>
  <div [style.width.px]="80" class="bb-stack__item">
    <bb-card-vendor-ui vendor="visa"></bb-card-vendor-ui>
  </div>
</div>
