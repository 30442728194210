import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingShowcaseComponent, PermissionsStrategyMock } from './heading-showcase.component';
import { HeadingModule, PERMISSIONS_STRATEGY } from '@backbase/ui-ang/heading';

@NgModule({
  imports: [CommonModule, HeadingModule],
  declarations: [HeadingShowcaseComponent],
  providers: [
    {
      provide: PERMISSIONS_STRATEGY,
      useClass: PermissionsStrategyMock,
    },
  ],
})
export class HeadingShowcaseModule {}
