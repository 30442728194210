import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DatePipe, FormatWidth, getLocaleDateFormat } from '@angular/common';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isNgbDateStruct } from './input-datepicker-adapter';

// TODO: get rid of non null assertions. They are added due to problem with NgbDateParserFormatter.
// It specifies result of parse function as NgbDateStruct, but allows to return null if value can not be parsed.
// They should have defined parse method with NgbDateStruct | null return, since now compilation
// fails due to strict null checks.
@Injectable()
export class NgbDateLocaleParserFormatter extends NgbDateParserFormatter {
  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly datePipe: DatePipe,
  ) {
    super();
  }

  private _dateFormat: string | undefined;

  get localeDateFormat() {
    return this._dateFormat || getLocaleDateFormat(this.locale, FormatWidth.Short);
  }

  set dateFormat(format: string | undefined) {
    this._dateFormat = format;
  }

  static getAdaptedYear(year: number, month: number, day: number, yearValue: string): number {
    const date = new Date(year, month - 1, day);
    year = date.getFullYear();

    if (yearValue && yearValue.length < 3 && year < 1950) {
      year += 100;
    }

    return year;
  }

  static getIndexFromFormat(regex: RegExp, formatArray: string[]) {
    const index = formatArray.findIndex((item: string) => !Boolean(item.replace(regex, '')));

    return index;
  }

  interpretDate(value: string): Date | undefined {
    const date = new Date(value);
    if (isNaN(date.getTime())) return;

    const year = date.getFullYear();
    const yearLength = this.getYearLength(value);

    if (
      yearLength < 3 && // prevent transformation of 1902 to 2002
      year < 1950 &&
      !value.includes(String(year)) &&
      new Date('01/01/00').getFullYear() === 1900
    ) {
      date.setFullYear(year + 100);
    }

    return date;
  }

  parse(value: string | undefined): NgbDateStruct {
    if (!value) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return null!;
    }

    const dateElementsRegex = /[^GyMLwWdE]/;
    const dateFormatDelimiterMatches = this.localeDateFormat.match(dateElementsRegex);
    const dateFormatDelimiter = dateFormatDelimiterMatches && dateFormatDelimiterMatches[0];
    const dateValueRegex = new RegExp('[\\d' + dateFormatDelimiter + ']+');

    // Try to parse manually the value in case if contains only numbers and the delimiter.
    // If no delimiter found or the value has other than number characters,
    // the the parsing will fallback to the angular date pipe
    if (dateFormatDelimiter && !Boolean(value.replace(dateValueRegex, ''))) {
      const formatParsedDate = this.getFormatParsedDate(value, this.localeDateFormat, dateFormatDelimiter);

      if (formatParsedDate) {
        // the formatter has all three date elements

        return formatParsedDate;
      }
    }

    return this.getAngularPipeFormatParseDate(value);
  }

  /**
   *
   * Parse date value with angular date pipe
   *
   * @param value The string date value
   *
   */
  getAngularPipeFormatParseDate(value: string) {
    let parsedValue = '';
    try {
      parsedValue = this.datePipe.transform(value, this.localeDateFormat, undefined, this.locale) || '';
    } catch (e) {
      // there is no error handling, because transform fails only with temporary and invalid values like '22/'
      //  during manual input to datepicker
    }
    const parsedDate = this.interpretDate(value);

    if (!parsedValue || !parsedDate) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return null!;
    }

    return {
      year: parsedDate.getFullYear(),
      month: parsedDate.getMonth() + 1,
      day: parsedDate.getDate(),
    };
  }

  /**
   *
   * @description
   * Proprietary parse to be able to use date override in the input field.
   *
   * Parse the string date value if the date format only contains y, M, d with one single format delimiter.
   *
   * @param value The string date value
   * @param format The date format
   * @param delimiter The delimiter character between date elements (e.g. - or /)
   *
   */
  getFormatParsedDate(value: string, format: string, delimiter: string): NgbDateStruct {
    const dateValueArr = value.split(delimiter); // split date string value by the delimiter
    const dateFormatArr = format.split(delimiter);

    if (dateFormatArr.length === 3) {
      const dayFormatIndex = NgbDateLocaleParserFormatter.getIndexFromFormat(/d{1,2}/, dateFormatArr);
      const monthFormatIndex = NgbDateLocaleParserFormatter.getIndexFromFormat(/M{1,5}/, dateFormatArr);
      const yearFormatIndex = NgbDateLocaleParserFormatter.getIndexFromFormat(/[yY]{1,4}/, dateFormatArr);

      if (dayFormatIndex > -1 && monthFormatIndex > -1 && yearFormatIndex > -1) {
        const today = new Date();

        const year = dateValueArr[yearFormatIndex] ? Number(dateValueArr[yearFormatIndex]) : today.getFullYear();
        const month = dateValueArr[monthFormatIndex] ? Number(dateValueArr[monthFormatIndex]) : today.getMonth() + 1;
        const day = dateValueArr[dayFormatIndex] ? Number(dateValueArr[dayFormatIndex]) : today.getDate();

        return {
          day,
          month,
          year: NgbDateLocaleParserFormatter.getAdaptedYear(year, month, day, dateValueArr[yearFormatIndex]),
        };
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return null!;
  }

  getYearLength(value: string): number {
    const separator = String(this.localeDateFormat.match(/[^\w\*]/));
    const dateArray = value.split(separator);
    const yearPosition = this.localeDateFormat.toLocaleLowerCase().split(separator);
    const yearIndex = yearPosition.findIndex((val: string) => val.includes('y'));

    return yearIndex >= 0 && yearIndex < dateArray.length ? dateArray[yearIndex].length : 0;
  }

  format(date: NgbDateStruct | undefined | null, format = this.localeDateFormat): string {
    let formattedDate = '';
    if (date && isNgbDateStruct(date)) {
      formattedDate =
        this.datePipe.transform(new Date(date.year, date.month - 1, date.day), format, undefined, this.locale) || '';
    }

    return formattedDate;
  }
}
