<div class="paragraph">
<p>These guidelines aim to provide best practices when working with data in Backbase Products with the purpose of keeping consistency in both content and code. By default, we use the en-US locale, which means we use American English and formats as a base before translating to other locales, so be mindful that the following guidelines are subject to change according to locale settings in the app.</p>
</div>
<div class="sect1">
<h2 id="_format_types">Format types</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Date and time can be changed to fit different use cases. Formatting of date and time values change according to locale settings configured in the application.</p>
</div>
<div class="paragraph">
<p>There are currently three groups for displaying date and time data:</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Simple format</strong><br>
Which includes different formats for date and time separately;</p>
</li>
<li>
<p><strong>Date with time</strong><br>
For whenever date and time need to be shown together to provide more detailed information</p>
</li>
<li>
<p><strong>Natural language</strong><br>
To be used in cases when you need to display an approximate time, expressed in a more <em>human</em> way.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_simple_format">Simple format</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_dates">Dates</h3>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 25%;">
<col style="width: 25%;">
<col style="width: 25%;">
<col style="width: 25%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top">Format</th>
<th class="tableblock halign-left valign-top">Date</th>
<th class="tableblock halign-left valign-top">Use When</th>
<th class="tableblock halign-left valign-top">Example</th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Shorter</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MM/YY</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">To show Credit Cards valid thru date</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">02/19</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Short</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MM/DD/YY</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Dates in the short format are displayed as two digits for the day, month, and year.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">02/09/19</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">MediumShort</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MM/DD/YYYY</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Dates in the MediumShort format are displayed as digits, with two for the day and month, and four for the year.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">02/09/2019</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Medium</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MMM/DD/YYYY</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Dates in medium format show the abbreviation of the month as text.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Feb 09, 2019</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Long</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MMMM/DD/YYYY</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Dates in medium format show the full names of months and the four digits of the year.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">February 09, 2019</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Full</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">EEEE/MMMM/DD/YYYY</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">For dates that need to be displayed including the day of the week (e.g. Message Center).</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Monday, February 09, 2019</p></td>
</tr>
</tbody>
</table>
</div>
<div class="sect2">
<h3 id="_time">Time</h3>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 25%;">
<col style="width: 25%;">
<col style="width: 25%;">
<col style="width: 25%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top">Format</th>
<th class="tableblock halign-left valign-top">Time</th>
<th class="tableblock halign-left valign-top">Use when</th>
<th class="tableblock halign-left valign-top">Example</th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Short</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">h:mm a</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Time is displayed in hours and minutes.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">9:15 AM</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Medium</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">h:mm:ss a</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Time is displayed in hours, minutes and seconds.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">9:15:24 AM</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Long</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">h:mm:ss a z</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Use this format for time if you need to display a time zone in short format.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">9:15:24 AM GMT+1</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Full</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">h:mm:ss zzzz</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Use this format for time if you need to display a time zone in full format.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">9:15:24 AM GMT+01:00</p></td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dates_with_time">Dates with time</h2>
<div class="sectionbody">
<div class="paragraph">
<p>To display date and time together you can mix the formats to cater for specific use cases when different levels of detail need to be displayed. When using this format, an “<strong>at</strong>” is added to bridge the information:</p>
</div>
<div class="quoteblock">
<blockquote>
<div class="paragraph">
<p>Monday March 28th, 2018 at 9:15 AM</p>
</div>
</blockquote>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_natural_language">Natural language</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Use this format whenever an approximate date or time is sufficient to solve the user’s case. Round down to the largest and most recent date or time within the range available.</p>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 25%;">
<col style="width: 25%;">
<col style="width: 25%;">
<col style="width: 25%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top">Data type</th>
<th class="tableblock halign-left valign-top">Range</th>
<th class="tableblock halign-left valign-top">Use when</th>
<th class="tableblock halign-left valign-top">Example</th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Date</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">-1 and 1 day relative to the current date.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">A more human way of speaking is enough for the use case.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Yesterday Today Tomorrow</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Time</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">&lt;5 min</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">An approximate time is enough for the use case.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">A moment ago</p></td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="sect1">
<h2 id="_time_and_date_ranges">Time and date ranges</h2>
<div class="sectionbody">
<div class="paragraph">
<p>To display a date or time range, show an <strong>en dash</strong> <strong>(without spaces)</strong> between a range of dates or times. The <a href="https://www.thepunctuationguide.com/en-dash.html" target="_blank" rel="noopener">en dash</a> is used to represent a span or range of numbers, dates, or time. There should be no space between the en dash and content. Depending on the context, the en dash is read as “to” or “through.”</p>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 33.3333%;">
<col style="width: 33.3333%;">
<col style="width: 33.3334%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top">Range</th>
<th class="tableblock halign-left valign-top">When and how to use</th>
<th class="tableblock halign-left valign-top">Example</th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Date</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">If date range covers the same year or current year show the year on both the start and end. If both dates have the current year, show the year only at the end of the range</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Dec 6, 2013–Jan 2, 2014 January 4–6, 2014</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Time</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Use a single AM or PM at the end of the range, if both times have the same AM/PM.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">8:00–10:30 AM</p></td>
</tr>
</tbody>
</table>
</div>
</div>