import { NgModule } from '@angular/core';
import { InputFileComponent } from './input-file.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [ButtonModule, IconModule],
  declarations: [InputFileComponent],
  exports: [InputFileComponent],
})
export class InputFileModule {}
