<div class="dso-navigation">
  <div class="dso-navigation__title">
    <a [routerLink]="'/'" class="dso-navigation__title-link">Design System</a>
  </div>

  <div class="dso-navigation__search">
    <bb-sidebar-search></bb-sidebar-search>
  </div>

  <nav class="dso-navigation__tree" aria-label="Design System Side Menu">
    <ul class="dso-navigation__tree-list">
      @for (parentMenuItem of menuTree; track $index) {
        <li class="dso-navigation__tree-item">
          <a
            class="dso-navigation__tree-link heyo"
            [class.dso-navigation__tree-link--expanded]="parentMenuItem.isOpen"
            [class.dso-navigation__tree-link--disabled-expand]="!parentMenuItem.showExpand"
            [routerLink]="'../' + parentMenuItem.url"
            routerLinkActive="dso-navigation__tree-link--active"
            ariaCurrentWhenActive="page"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="toggle(parentMenuItem)"
            (keyup)="toggleKeyUp(parentMenuItem, $event)"
          >
            @if (parentMenuItem.showExpand) {
              <span>{{ parentMenuItem.name | titlecaseExcept | titleclean }}</span>
              @if (parentMenuItem.showExpand) {
                <bb-icon-ui
                  [name]="parentMenuItem.isOpen ? 'remove' : 'add'"
                  [@toggleChapter]="parentMenuItem.isOpen ? 'expand' : 'collapse'"
                ></bb-icon-ui>
              }
            } @else {
              {{ parentMenuItem.name | titlecaseExcept | titleclean }}
            }
          </a>
          @if (parentMenuItem.children) {
            <ul class="dso-navigation__tree-list">
              @for (childMenuItem of parentMenuItem.children; track $index) {
                <li
                  [@toggleChapter]="parentMenuItem.isOpen ? 'open' : 'closed'"
                  [class.dso-navigation__tree-item--active]="parentMenuItem.isOpen"
                  class="dso-navigation__tree-item"
                >
                  @if (parentMenuItem.collapsible && childMenuItem.collapsible) {
                    <a
                      [routerLink]="'../' + childMenuItem.url"
                      routerLinkActive="dso-navigation__tree-link--active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      ariaCurrentWhenActive="page"
                      class="dso-navigation__tree-link bye"
                      [class.dso-navigation__tree-link--expanded]="childMenuItem.isOpen"
                      [tabIndex]="parentMenuItem.isOpen ? 0 : -1"
                      (click)="toggle(childMenuItem)"
                      (keyup)="toggleKeyUp(childMenuItem, $event)"
                    >
                      <span>{{ childMenuItem.name | titlecaseExcept | titleclean }}</span>
                      <bb-icon-ui
                        [name]="childMenuItem.isOpen ? 'remove' : 'add'"
                        [@toggleChapter]="childMenuItem.isOpen ? 'expand' : 'collapse'"
                      ></bb-icon-ui>
                    </a>
                    @if (childMenuItem.children) {
                      <ul class="list-unstyled">
                        @for (grandChildMenuItem of childMenuItem.children; track $index) {
                          <li
                            [@toggleChapter]="childMenuItem.isOpen ? 'open' : 'closed'"
                            [class.dso-navigation__tree-item--active]="childMenuItem.isOpen"
                            class="dso-navigation__tree-item"
                          >
                            <a
                              [routerLink]="
                                '../' +
                                (grandChildMenuItem.url ||
                                  parentMenuItem.name + '/' + childMenuItem.name + '/' + grandChildMenuItem.name ||
                                  grandChildMenuItem)
                              "
                              routerLinkActive="dso-navigation__tree-link--active"
                              ariaCurrentWhenActive="page"
                              class="dso-navigation__tree-link"
                              [tabIndex]="childMenuItem.isOpen ? 0 : -1"
                            >
                              {{ grandChildMenuItem.name || grandChildMenuItem | titleclean | titlecaseExcept }}
                            </a>
                          </li>
                        }
                      </ul>
                    }
                  } @else {
                    <a
                      [routerLink]="'../' + parentMenuItem.name + '/' + childMenuItem.name"
                      routerLinkActive="dso-navigation__tree-link--active"
                      ariaCurrentWhenActive="page"
                      class="dso-navigation__tree-link"
                      [tabIndex]="parentMenuItem.isOpen ? 0 : -1"
                    >
                      {{ childMenuItem.name | titlecaseExcept | titleclean }}
                    </a>
                    @if (childMenuItem.children) {
                      <ul class="dso-navigation__tree-list">
                        @for (grandChildMenuItem of childMenuItem.children; track $index) {
                          <li class="dso-navigation__tree-item">
                            <a
                              [routerLink]="
                                '../' +
                                parentMenuItem.name +
                                '/' +
                                (parentMenuItem.name === 'mobile'
                                  ? childMenuItem.name + '/' + grandChildMenuItem
                                  : grandChildMenuItem)
                              "
                              routerLinkActive="dso-navigation__tree-link--active"
                              ariaCurrentWhenActive="page"
                              class="dso-navigation__tree-link"
                              [tabIndex]="childMenuItem.isOpen ? 0 : -1"
                            >
                              {{ grandChildMenuItem | titleclean | titlecaseExcept }}
                            </a>
                          </li>
                        }
                      </ul>
                    }
                  }
                </li>
              }
            </ul>
          }
        </li>
      }
    </ul>
  </nav>
</div>
