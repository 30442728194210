import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { CharCounterModule } from '@backbase/ui-ang/char-counter';

import { TextareaComponent } from './textarea.component';
import { FocusModule } from '@backbase/ui-ang/focus';

@NgModule({
  imports: [NgClass, FormsModule, BaseClassesModule, CharCounterModule, FocusModule],
  declarations: [TextareaComponent],
  exports: [TextareaComponent],
})
export class TextareaModule {}
