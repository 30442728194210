<form [formGroup]="vForm">
  <div class="bb-block bb-block--lg">
    <bb-input-file-ui
      label="File input"
      formControlName="fileInput"
      data-role="file-input"
    >
    </bb-input-file-ui>
  </div>
</form>
