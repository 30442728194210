import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stringToColorVariable', standalone: true })
export class StringToColorVariablePipe implements PipeTransform {
  constructor() {}

  transform(value: string) {
    return `var(--${value.slice(1)})`;
  }
}
