import { Injectable } from '@angular/core';
import { ConfigurationInputService } from '../../configuration/configuration-input.service';

@Injectable({ providedIn: 'root' })
export class ButtonExampleService {
  btnCheckboxList = [
    {
      label: 'Block',
      value: false,
      formControlName: 'block',
    },
    {
      label: 'Disabled',
      value: false,
      formControlName: 'disabled',
    },
  ];

  btnRadioList = [
    {
      label: 'Button Size',
      formControlName: 'buttonSize',
      radioItems: [
        {
          label: 'Small',
          value: 'sm',
        },
        {
          label: 'Medium',
          value: 'md',
        },
      ],
    },
  ];

  btnDropdownList = [
    {
      label: 'Color',
      formControlName: 'color',
      dropdownItemList: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'link', 'link-text'],
    },
  ];

  btnValues = {
    checkbox: {
      block: false,
      disabled: false,
    },
    radio: {
      buttonSize: 'sm',
    },
    dropdown: {
      color: 'primary',
    },
  };
  constructor(private readonly configurationService: ConfigurationInputService) {
    this.configurationService.sharedField.subscribe({
      next: (val: any) => {
        this.btnValues.checkbox.block = val.checkbox.block;
        this.btnValues.checkbox.disabled = val.checkbox.disabled;
        this.btnValues.radio.buttonSize = val.radio.buttonSize;
        this.btnValues.dropdown.color = val.dropdown.color;
      },
    });
  }
}
