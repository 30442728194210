import { NgModule } from '@angular/core';
import { InputCheckboxModule } from '@backbase/ui-ang/input-checkbox';
import { FormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';

import { CheckboxGroupComponent } from './checkbox-group.component';

@NgModule({
  imports: [InputCheckboxModule, FormsModule, BaseClassesModule],
  declarations: [CheckboxGroupComponent],
  exports: [CheckboxGroupComponent],
})
export class CheckboxGroupModule {}
