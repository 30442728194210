@for (loadingIndicator of loadingIndicators; track $index) {
  <div class="bb-block bb-block--lg">
    <h4 class="bb-block bb-block--lg">{{ loadingIndicator.title }}</h4>
    <bb-loading-indicator-ui
      [loaderSize]="loadingIndicator.size"
      [text]="loadingIndicator.text"
    ></bb-loading-indicator-ui>
  </div>
}

<div class="bb-block bb-block--lg">
  <h4 class="bb-block bb-block--lg">Inline</h4>
  <p>
    Some text
    <bb-loading-indicator-ui [inline]="true" loaderSize="sm"></bb-loading-indicator-ui>
  </p>
</div>

<div class="bb-block bb-block--lg">
  <h4 class="bb-block bb-block--lg">Inline with text</h4>
  <p>
    Lorem ipsum
    <bb-loading-indicator-ui [inline]="true" loaderSize="sm" text="Loading..."></bb-loading-indicator-ui>
  </p>
</div>
