import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxGroupShowcaseComponent } from './checkbox-group-showcase.component';
import { CheckboxGroupModule } from '@backbase/ui-ang/checkbox-group';
import { InputCheckboxModule } from '@backbase/ui-ang/input-checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, CheckboxGroupModule, FormsModule, InputCheckboxModule],
  declarations: [CheckboxGroupShowcaseComponent],
})
export class CheckboxGroupShowcaseModule {}
