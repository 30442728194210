import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumberFormatPipeShowcaseComponent } from './phone-number-format-pipe-showcase.component';
import { PhoneNumberModule } from '@backbase/ui-ang/phone-number-format-pipe';

@NgModule({
  imports: [CommonModule, PhoneNumberModule],
  declarations: [PhoneNumberFormatPipeShowcaseComponent],
})
export class PhoneNumberFormatPipeShowcaseModule {}
