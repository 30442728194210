import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { BadgeCounterComponent } from './badge-counter.component';

@NgModule({
  imports: [NgClass],
  declarations: [BadgeCounterComponent],
  exports: [BadgeCounterComponent],
})
export class BadgeCounterModule {}
