<bb-progressbar-ui
  type="primary"
  [striped]="true"
  [animated]="true"
  [showValue]="true"
  [value]="value"
  [max]="1"
  ariaLabel="custom progress tracker"
>
</bb-progressbar-ui>
