import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextShowcaseComponent } from './input-text-showcase.component';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, InputTextModule, ReactiveFormsModule],
  declarations: [InputTextShowcaseComponent],
})
export class InputTextShowcaseModule {}
