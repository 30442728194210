import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';

@Directive({ selector: 'ng-template[bbCustomProductItemCreditCardTitle]' })
export class CustomProductItemCreditCardTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemCreditCardDetailsFormatter]' })
export class CustomProductItemCreditCardDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemCreditCardMainSection]' })
export class CustomProductItemCreditCardMainSectionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemCreditCardSecondarySection]' })
export class CustomProductItemCreditCardSecondarySectionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemCreditCardComponent
 *
 * @description
 * A component that renders a styled-container or card for credit card.
 */
@Component({
  selector: 'bb-product-item-credit-card-ui',
  templateUrl: './product-item-credit-card.component.html',
})
export class ProductItemCreditCardComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemCreditCardTitleDirective, { static: true })
  customProductItemCreditCardTitle?: CustomProductItemCreditCardTitleDirective;

  @ContentChild(CustomProductItemCreditCardDetailsFormatterDirective, { static: true })
  customProductItemCreditCardDetailsFormatter?: CustomProductItemCreditCardDetailsFormatterDirective;

  @ContentChild(CustomProductItemCreditCardMainSectionDirective, { static: true })
  customProductItemCreditCardMainSection?: CustomProductItemCreditCardMainSectionDirective;

  @ContentChild(CustomProductItemCreditCardSecondarySectionDirective, { static: true })
  customProductItemCreditCardSecondarySection?: CustomProductItemCreditCardSecondarySectionDirective;

  readonly hostRef: ProductItemCreditCardComponent = this;
  /**
   * The card currency.
   */
  @Input() currency?: string;
  /**
   * The card amount value.
   */
  @Input() amount?: number;
  /**
   * The card additional main section currency.
   */
  @Input() mainSectionCurrency?: string;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionAmount?: number;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionTitle?: string;
  /**
   * The card additional secondary section currency.
   */
  @Input() secondarySectionCurrency?: string;
  /**
   * The card additional secondary section amount value.
   */
  @Input() secondarySectionAmount?: number;
  /**
   * The card additional secondary section amount value.
   */
  @Input() secondarySectionTitle?: string;
}
