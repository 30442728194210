import { NgModule } from '@angular/core';
import { NgClass } from '@angular/common';

import { ButtonModule } from '@backbase/ui-ang/button';
import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';

import { LoadButtonComponent } from './load-button.component';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  imports: [NgClass, ButtonModule, LoadingIndicatorModule, KeyboardClickModule],
  declarations: [LoadButtonComponent],
  exports: [LoadButtonComponent],
})
export class LoadButtonModule {}
