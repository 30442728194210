<div class="bb-block bb-block--lg">
  <h4>Default</h4>
  <bb-avatar-ui name="Jane Doe" [attr.data-role]="'avatar-default'"></bb-avatar-ui>
</div>

<div class="bb-block bb-block--lg">
  <h4>Size variants</h4>
  <div class="bb-stack">
    <bb-avatar-ui
      size="sm"
      name="Jane Doe"
      class="bb-stack__item"
      [attr.data-role]="'avatar-initials-sm'"
    ></bb-avatar-ui>
    <bb-avatar-ui
      size="md"
      name="Jane Doe"
      class="bb-stack__item"
      [attr.data-role]="'avatar-initials-md'"
    ></bb-avatar-ui>
    <bb-avatar-ui
      size="lg"
      name="Jane Doe"
      class="bb-stack__item"
      [attr.data-role]="'avatar-initials-lg'"
    ></bb-avatar-ui>
    <bb-avatar-ui
      size="xl"
      name="Jane Doe"
      class="bb-stack__item"
      [attr.data-role]="'avatar-initials-xl'"
    ></bb-avatar-ui>
  </div>
</div>

<div class="bb-block bb-block--lg">
  <h4>With image</h4>
  <div class="bb-stack">
    <bb-avatar-ui
      size="sm"
      image="./assets/images/avatar.jpg"
      imgAlt="image for avatar"
      class="bb-stack__item"
      [attr.data-role]="'avatar-image-sm'"
    ></bb-avatar-ui>
    <bb-avatar-ui
      size="md"
      image="./assets/images/avatar.jpg"
      imgAlt="image for avatar"
      class="bb-stack__item"
      [attr.data-role]="'avatar-image-md'"
    ></bb-avatar-ui>
    <bb-avatar-ui
      size="lg"
      image="./assets/images/avatar.jpg"
      imgAlt="image for avatar"
      class="bb-stack__item"
      [attr.data-role]="'avatar-image-lg'"
    ></bb-avatar-ui>
    <bb-avatar-ui
      size="xl"
      image="./assets/images/avatar.jpg"
      imgAlt="image for avatar"
      class="bb-stack__item"
      [attr.data-role]="'avatar-image-xl'"
    ></bb-avatar-ui>
  </div>
</div>
