<form class="col-6" [formGroup]="vForm">
  <bb-list-multiple-select-ui
    formGroupName="listMultipleSelect"
    [config]="listMultipleSelectItemsConfig"
    aria-label="List multiple select"
  >
    <ng-template bbListMultipleSelectAction>
      <a href="" (click)="onListMultipleSelectClearAll($event)">Clear All</a>
    </ng-template>
  </bb-list-multiple-select-ui>
</form>
