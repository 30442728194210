<form [formGroup]="form">
  <bb-input-inline-edit-ui
    [inputText]="form.get('description')?.value"
    [maxLength]="64"
    [minLength]="2"
    [required]="true"
    (accept)="onAcceptDescription($event)"
  >
  </bb-input-inline-edit-ui>
</form>
