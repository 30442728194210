import { NgModule } from '@angular/core';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { ControlErrorContainerDirective } from './directives/control-error-container.directive';
import { FormSubmitDirective } from './directives/form-submit.directive';
import { ValidationErrorsDirective } from './directives/validation-errors.directive';

const directives = [ValidationErrorsDirective, FormSubmitDirective, ControlErrorContainerDirective];
const components = [ValidationMessageComponent];
@NgModule({
  declarations: [...directives, ...components],
  exports: [...directives, ...components],
})
export class ControlErrorHandlerModule {}
