import { NgModule, ModuleWithProviders } from '@angular/core';

import { MediaQueryModule } from '@backbase/ui-ang/media-query-lib';
import { LayoutConfig, LAYOUT_CONFIG_TOKEN } from './bb-layout-lib/layout.model';
import { LayoutNavDropdownDirective } from './bb-layout-nav-dropdown-directive/layout-nav-dropdown.directive';

@NgModule({
  imports: [MediaQueryModule],
  declarations: [LayoutNavDropdownDirective],
  exports: [LayoutNavDropdownDirective],
})
export class LayoutModule {
  static forRoot(config: LayoutConfig): ModuleWithProviders<LayoutModule> {
    return { ngModule: LayoutModule, providers: [{ provide: LAYOUT_CONFIG_TOKEN, useValue: config }] };
  }
}
