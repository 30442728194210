/* eslint-disable */
export enum KEY_CODES {
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  ENTER = 'Enter',
  SPACE = ' ',
  TAB = 'Tab',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ESCAPE = 'Escape',
  X = 'x',
  V = 'v',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
}

export const KEY_CODE_TO_CODE = {
  9: KEY_CODES.TAB,
  38: KEY_CODES.UP,
  40: KEY_CODES.DOWN,
  32: KEY_CODES.SPACE,
  13: KEY_CODES.ENTER,
  46: KEY_CODES.DELETE,
  8: KEY_CODES.BACKSPACE,
  27: KEY_CODES.ESCAPE,
  88: KEY_CODES.X,
  86: KEY_CODES.V,
} as any;

export function browserIsMsie() {
  return navigator.userAgent.indexOf('MSIE') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
}

export function getKeyCode(event: KeyboardEvent): KEY_CODES {
  // NOTE: detect IE
  if (browserIsMsie()) {
    return KEY_CODE_TO_CODE[event.keyCode] as KEY_CODES;
  }

  return event.code as KEY_CODES;
}
