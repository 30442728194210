<bb-collapsible-ui #collapsible>
  <ng-template
    bbCollapsibleHeader
    let-toggle="toggle"
    let-isOpen="true"
  >
    <button
      bbButton
      (click)="toggle()"
      class="bb-block--md"
      [attr.aria-expanded]="collapsible.isOpen"
      aria-controls="collapseBody"
    >
      Toggle
      <bb-icon-ui
        [name]="collapsible.isOpen ? 'toggle-up' : 'toggle-down'"
        [inverse]="true"
        size="md"
      ></bb-icon-ui>
    </button>
  </ng-template>
  <ng-template bbCollapsibleBody>
    <p id="collapseBody">I am expandable content!</p>
  </ng-template>
</bb-collapsible-ui>
