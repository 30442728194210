<div class="row">
  <div class="col-md-6">
    <h3>Default case</h3>
    <div class="row">
      <div class="col-6">
        <div [bbValueDiff]="newValue" [sr-only-old]="'Old value: '" [sr-only-new]="'New value: '">{{ oldValue }}</div>
        <br />
        <div class="bb-button-bar bb-button-bar--reverse">
          <button class="bb-button-bar__button" (click)="changeData()" bbButton buttonSize="sm">Change data</button>
          <button class="bb-button-bar__button" (click)="reset()" bbButton buttonSize="sm">Make equal</button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <h3>Show new value after old</h3>
    <div class="row">
      <div class="col">
        <div [bbValueDiff]="newValueAfter" [position]="positionAfter">{{ oldValueAfter }}</div>
      </div>
    </div>
  </div>
</div>
<hr />

<div class="row">
  <div class="col-md-6">
    <h3>With arrow right</h3>
    <div class="bb-stack">
      <span [bbValueDiff]="newValue" [position]="positionAfter" [showArrow]="true">{{ oldValue }}</span>
    </div>
  </div>

  <div class="col-md-6">
    <h3>With arrow left</h3>
    <div class="bb-stack">
      <span [bbValueDiff]="newValueAfter" [showArrow]="true">{{ oldValueAfter }}</span>
    </div>
  </div>
</div>
