import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '@backbase/ui-ang/icon';
import { ProductSelectorComponent } from './product-selector.component';
import { ButtonModule } from '@backbase/ui-ang/button';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';

@NgModule({
  imports: [NgbDropdownModule, IconModule, ButtonModule, KeyboardClickModule],
  declarations: [ProductSelectorComponent],
  exports: [ProductSelectorComponent],
})
export class ProductSelectorModule {}
