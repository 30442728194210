<div class="example-ui">
  <div class="example-ui__component-col">
    @if (showAlert) {
      <bb-alert-ui
        title="Example information alert"
        [modifier]="alertValues.dropdown.modifier"
        [dismissible]="alertValues.checkbox.dismissible"
        [primaryActionButtonText]="
          alertValues.input.primaryActionButtonText
        "
        [supportiveLinkText]="alertValues.input.supportiveLinkText"
        (close)="hideAlert()"
      >
        <span>
          This example will be restored after
          {{ restoreDelay }} seconds.
        </span>
      </bb-alert-ui>
    }
  </div>
  <div class="example-ui__configuration-col">
    <bb-configuration-input-ui
      [checkboxList]="alertCheckoxList"
      [dropdownList]="alertDropdownList"
      [inputList]="alertInputList"
    ></bb-configuration-input-ui>
  </div>
</div>
