<div>
  <div class="bb-block bb-block--xl">
    <h2>Default</h2>
    <bb-product-selector-ui [autoClose]="true" id="default">
      <ng-container bbDropdownToggle>
        @if (productSelected) {
          <div
            data-role="product-selector-selected-product"
            class="bb-product-item bb-product-item-basic-account-ui product-item"
          >
            <div class="bb-stack bb-stack--wrap bb-text-align-left product-item-content">
              <div class="bb-stack__item bb-stack__item--fill">
                <span data-role="item-name" class="bb-text-bold"> {{ productSelected?.name }} </span><br />
                <span data-role="item-number" class="bb-text-support bb-subtitle bb-account-info__product-number">
                  {{ productSelected?.productNumber }}
                </span>
              </div>
              <div class="bb-stack__break bb-stack__break--xs"></div>
              <div
                class="bb-stack__item bb-stack__item--push-right bb-stack__item--spacing-none bb-product-item__details"
              >
                <div class="bb-text-align-right">
                  <div class="bb-text-support bb-subheader bb-subheader--regular">text herestas</div>
                  @if (productSelected.amount) {
                    <bb-amount-ui
                      class="bb-highlight"
                      [amount]="productSelected.amount"
                      [currency]="productSelected.currency"
                    >
                    </bb-amount-ui>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        @if (!productSelected) {
          <div class="bb-stack">
            <span data-role="item-empty-state" class="bb-text-support bb-product-selector__placeholder">
              Select one or more accounts
            </span>
          </div>
        }
      </ng-container>
      <ng-container bbDropdownMenu>
        @for (item of productsInterfaced; track item.id; let i = $index) {
          <div
            (click)="productSelected = productsInterfaced[i]"
            #listItem
            role="option"
            tabindex="0"
            class="bb-product-selector__dropdown-item"
            [ngClass]="{ selected: item.id === productSelected?.id }"
          >
            <bb-product-item-basic-account-ui
              [title]="item.name"
              [amount]="item.amount"
              [productNumber]="item.productNumber"
              [currency]="'EUR'"
              [highlight]="true"
              [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
              [active]="item.id === productSelected?.id"
            ></bb-product-item-basic-account-ui>
          </div>
        }
      </ng-container>
    </bb-product-selector-ui>
  </div>
  <div class="bb-block bb-block--xl">
    <h2>Always open bottom</h2>
    <bb-product-selector-ui [autoClose]="true" [position]="'bottom'">
      <ng-container bbDropdownToggle>
        @if (productSelected) {
          <div
            data-role="product-selector-selected-product"
            class="bb-product-item bb-product-item-basic-account-ui product-item"
          >
            <div class="bb-stack bb-stack--wrap bb-text-align-left product-item-content">
              <div class="bb-stack__item bb-stack__item--fill">
                <span data-role="item-name" class="bb-text-bold"> {{ productSelected?.name }} </span><br />
                <span data-role="item-number" class="bb-text-support bb-subtitle bb-account-info__product-number">
                  {{ productSelected?.productNumber }}
                </span>
              </div>
              <div class="bb-stack__break bb-stack__break--xs"></div>
              <div
                class="bb-stack__item bb-stack__item--push-right bb-stack__item--spacing-none bb-product-item__details"
              >
                @if (productSelected?.amount) {
                  <bb-amount-ui
                    class="bb-highlight"
                    [amount]="productSelected?.amount"
                    [currency]="productSelected?.currency"
                  >
                  </bb-amount-ui>
                }
              </div>
            </div>
          </div>
        }
        @if (!productSelected) {
          <div class="bb-stack">
            <span data-role="item-empty-state" class="bb-text-support bb-product-selector__placeholder">
              Select one or more accounts
            </span>
          </div>
        }
      </ng-container>
      <ng-container bbDropdownMenu>
        @for (item of productsInterfaced; track item.id; let i = $index) {
          <div
            (click)="productSelected = productsInterfaced[i]"
            role="option"
            tabindex="0"
            #listItem
            class="bb-product-selector__dropdown-item"
            [ngClass]="{ selected: item.id === productSelected?.id }"
          >
            <bb-product-item-basic-account-ui
              [title]="item.name"
              [amount]="item.amount"
              [productNumber]="item.productNumber"
              [currency]="'EUR'"
              [highlight]="true"
              [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
              [active]="item.id === productSelected?.id"
            ></bb-product-item-basic-account-ui>
          </div>
        }
      </ng-container>
    </bb-product-selector-ui>
  </div>
  <div class="bb-block bb-block--xl">
    <h2>Autoclose disabled</h2>
    <bb-product-selector-ui [autoClose]="false">
      <ng-container bbDropdownToggle>
        @if (productSelected) {
          <div
            data-role="product-selector-selected-product"
            class="bb-product-item bb-product-item-basic-account-ui product-item"
          >
            <div class="bb-stack bb-stack--wrap bb-text-align-left product-item-content">
              <div class="bb-stack__item bb-stack__item--fill">
                <span data-role="item-name" class="bb-text-bold"> {{ productSelected?.name }} </span><br />
                <span data-role="item-number" class="bb-text-support bb-subtitle bb-account-info__product-number">
                  {{ productSelected?.productNumber }}
                </span>
              </div>
              <div class="bb-stack__break bb-stack__break--xs"></div>
              <div
                class="bb-stack__item bb-stack__item--push-right bb-stack__item--spacing-none bb-product-item__details"
              >
                <div class="bb-text-align-right">
                  <div class="bb-text-support bb-subheader bb-subheader--regular">text herestas</div>
                  @if (productSelected.amount) {
                    <bb-amount-ui
                      class="bb-highlight"
                      [amount]="productSelected.amount"
                      [currency]="productSelected.currency"
                    >
                    </bb-amount-ui>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        @if (!productSelected) {
          <div class="bb-stack">
            <span data-role="item-empty-state" class="bb-text-support bb-product-selector__placeholder">
              Select one or more accounts
            </span>
          </div>
        }
      </ng-container>
      <ng-container bbDropdownMenu>
        @for (item of productsInterfaced; track item.id; let i = $index) {
          <div
            (click)="productSelected = productsInterfaced[i]"
            role="option"
            tabindex="0"
            #listItem
            class="bb-product-selector__dropdown-item"
            [ngClass]="{ selected: item.id === productSelected?.id }"
          >
            <bb-product-item-basic-account-ui
              [title]="item.name"
              [amount]="item.amount"
              [productNumber]="item.productNumber"
              [currency]="'EUR'"
              [highlight]="true"
              [productNumberFormat]="{ length: 18, maskRange: [0, 0], segments: 4 }"
              [active]="item.id === productSelected?.id"
            ></bb-product-item-basic-account-ui>
          </div>
        }
      </ng-container>
    </bb-product-selector-ui>
  </div>
</div>
