import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconShowcaseComponent } from './icon-showcase.component';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [IconShowcaseComponent],
})
export class IconShowcaseModule {}
