import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { generatedRoutes } from './app-routing-generated';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { pathV2Guard } from './utils/path-v2-guard';

const appRoutes: Routes = [
  ...generatedRoutes,
  { path: '', pathMatch: 'full', component: HomePageComponent },
  { path: '**', component: NotFoundComponent, canActivate: [pathV2Guard] },
];
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
