<div class="bb-currency-input">
  <div class="bb-stack bb-stack--align-bottom bb-force-ltr">
    @if (curList && curList.length) {
      <div class="bb-currency-input__currency-selector selector bb-stack__item bb-stack__item--no-shrink">
        @if (currencyListAriaLabel || currencyListWithOnItemAriaLabel) {
          <label
            class="visually-hidden"
            for="{{ currencyInputId }}"
            [attr.aria-label]="currencyListWithOnItemAriaLabel"
            id="{{ currencyLabelId }}"
            [attr.data-role]="'currency-input-label'"
          >
            @switch (!!currencyListAriaLabel || !!currencyListWithOnItemAriaLabel) {
              @case (true) {
                {{ curList && curList.length > 1 ? currencyListAriaLabel : currencyListWithOnItemAriaLabel }}
              }
              @default {
                <ng-container
                  i18n="
                    Currency label | Label for currency code dropdown of the Currency Input component
                    @@bb-currency-input-ui.currency-input-label"
                  >Currency</ng-container
                >
              }
            }
          </label>
        }
        @switch (readonly || (curList && curList.length === 1)) {
          @case (true) {
            <input
              type="text"
              (blur)="markAsTouched($event)"
              [id]="currencyInputId"
              [readonly]="true"
              [required]="required"
              [attr.aria-invalid]="ariaInvalid"
              [attr.aria-labelledby]="idListAttr(ariaLabelledby, currencyLabelId)"
              class="form-control bb-text-align-center"
              value="{{ cur }}"
              [attr.data-role]="'currency-input-currency-readonly'"
              [attr.data-id]="currencyInputId + '_currency-input_currency-input'"
            />
          }
          @default {
            <bb-dropdown-single-select-ui
              [id]="currencyInputId"
              class="bb-block"
              [placeholder]="curList[0]"
              [options]="curList"
              [readonly]="readonly"
              [required]="required"
              [aria-invalid]="ariaInvalid"
              [aria-labelledby]="idListAttr(ariaLabelledby, currencyLabelId)"
              (change)="updateCurrency(); markAsTouched($event)"
              [formControl]="currControl"
              [attr.data-role]="'currency-input-currency-selector'"
            ></bb-dropdown-single-select-ui>
          }
        }
      </div>
    }

    <div
      class="bb-currency-input__integer bb-stack__item bb-stack__item--spacing-sm bb-stack__item--fill"
      [class.ng-touched]="intControl.touched || decControl.touched || parentFormControl?.touched"
      [class.ng-invalid]="intControl.invalid || parentFormControl?.invalid"
    >
      <label
        [class.visually-hidden]="!integerLabel || integerLabelSrOnly"
        class="form-label"
        for="{{ integerInputId }}"
        id="{{ integerLabelId }}"
        [attr.data-role]="'currency-input-integer-label'"
      >
        @switch (!!integerLabel) {
          @case (true) {
            {{ integerLabel }}
          }
          @default {
            <ng-container
              i18n="
                Integer label | Label for integer part of the monetary amount of the Currency Input component
                @@bb-currency-input-ui.integer-input-label"
              >Integer</ng-container
            >
          }
        }
      </label>
      <input
        id="{{ integerInputId }}"
        [attr.aria-describedby]="idListAttr(ariaDescribedby, validationMessagesId)"
        [attr.aria-invalid]="ariaInvalid"
        [attr.aria-labelledby]="idListAttr(ariaLabelledby, integerLabelId)"
        name="integer"
        type="text"
        inputmode="numeric"
        size="5"
        role="textbox"
        [readOnly]="readonly"
        [required]="required"
        (keydown.backspace)="onBackspace(integerInput)"
        (keypress)="onPress($event)"
        (input)="onInput()"
        (blur)="correctInputValue($event)"
        [formControl]="intControl"
        class="form-control bb-text-align-right"
        placeholder="{{ placeholder }}"
        #integerInput
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        [attr.data-role]="'currency-input-integer-input'"
        [attr.data-id]="integerInputId + '_currency-input_integer-input'"
      />
    </div>

    @if (!integer && decMaxLen) {
      <span class="bb-stack__item bb-stack__item--spacing-sm">{{ decimalSeparator }}</span>
    }
    @if (!integer && decMaxLen) {
      <div
        class="bb-currency-input__decimal"
        [class.ng-touched]="intControl.touched || decControl.touched || parentFormControl?.touched"
        [class.ng-invalid]="intControl.invalid || parentFormControl?.invalid"
      >
        <label
          [class.visually-hidden]="!decimalLabel || decimalLabelSrOnly"
          class="form-label"
          for="{{ decimalInputId }}"
          id="{{ decimalLabelId }}"
          [attr.data-role]="'currency-input-decimal-label'"
        >
          @switch (!!decimalLabel) {
            @case (true) {
              {{ decimalLabel }}
            }
            @default {
              <ng-container
                i18n="
                  Decimal label | Label for decimal part of the monetary amount of the Currency Input component
                  @@bb-currency-input-ui.decimal-input-label"
              >
                Decimals
              </ng-container>
            }
          }
        </label>
        <input
          id="{{ decimalInputId }}"
          [attr.aria-describedby]="idListAttr(ariaDescribedby, validationMessagesId)"
          [attr.aria-invalid]="ariaInvalid"
          [attr.aria-labelledby]="idListAttr(ariaLabelledby, decimalLabelId)"
          name="decimals"
          type="text"
          inputmode="numeric"
          role="textbox"
          [readOnly]="readonly"
          [required]="required"
          (keypress)="onPress($event)"
          (input)="triggerChange()"
          (blur)="decOnBlur($event)"
          [formControl]="decControl"
          class="form-control"
          [ngClass]="{ 'bb-text-align-right': isRightAligned }"
          maxlength="{{ decMaxLen }}"
          placeholder="{{ decPlaceholder }}"
          #decimalsInput
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          [attr.data-role]="'input-currency-decimals'"
          [attr.data-id]="decimalInputId + '_currency-input_decimal-input'"
        />
      </div>
    }
  </div>
  <div
    class="bb-input-validation-message"
    aria-live="assertive"
    [id]="validationMessagesId"
    [attr.data-role]="'currency-input-validation-message'"
  >
    <ng-content select="[bbValidationMessage]"></ng-content>
  </div>
</div>
