import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, defer, iif, Observable, of, switchMap, tap } from 'rxjs';
import { HtmlRenderService } from '../../../services/html-render.service';

@Component({
  selector: 'bb-render-html',
  templateUrl: './render-html.component.html',
})
export class RenderHtmlComponent {
  private _path: string | undefined = undefined;

  @Output() rendered: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  updateHtml$ = new BehaviorSubject<boolean>(false);

  failed = false;

  @Input()
  set path(pathValue: string | undefined) {
    this._path = pathValue;
    this.updateHtml$.next(true);
  }

  get path() {
    return this._path;
  }

  httpRequest$: Observable<string | null> = defer(() =>
    this.http.get(this.path as string, { responseType: 'text' }).pipe(
      tap(() => (this.failed = false)),
      catchError((error: HttpErrorResponse) => {
        this.failed = true;

        return of(null);
      }),
      tap(() => this.rendered.emit(true)),
      tap(() => this.htmlRender.rendering.next(false)),
    ),
  );

  html$: Observable<string | null> = this.updateHtml$.pipe(
    tap(() => {
      this.htmlRender.rendering.next(true);
    }),
    switchMap((value: boolean) => iif(() => !!this.path, this.httpRequest$, of(null))),
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private htmlRender: HtmlRenderService,
  ) {}

  reload(): void {
    this.updateHtml$.next(true);
  }

  goToHomePage(): void {
    this.router.navigate(['']);
  }
}
