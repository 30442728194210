import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardVendorComponent } from './card-vendor.component';
import { CardVendorTitlePipe } from './card-vendor-title.pipe';

@NgModule({
  imports: [NgClass],
  declarations: [CardVendorComponent, CardVendorTitlePipe],
  exports: [CardVendorComponent],
})
export class CardVendorModule {}
