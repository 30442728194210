<bb-collapsible-ui
  [isOpen]="isOpen"
  class="bb-card bb-card--collapsible bb-card--{{ size }}"
  [ngClass]="{
    'bb-card--highlight': highlight,
    'bb-card--shadowless': !shadow
  }"
  (isOpenChange)="toggle()"
>
  <ng-template bbCollapsibleHeader let-toggle="toggle">
    <div
      data-role="bb-collapsible-card-header"
      class="bb-card__header bb-card__header--only-child"
      role="button"
      [attr.aria-label]="ariaLabel"
      [attr.aria-expanded]="isOpen"
      tabindex="0"
      (bbKeyboardClick)="toggle()"
    >
      <div class="bb-stack">
        <div class="bb-stack__item bb-stack__item--fill"><ng-content select="[bbCollapsibleTitle]"></ng-content></div>
        @if (hasChevron) {
          <bb-icon-ui
            [aria-label]="ariaLabelForIcon"
            class="bb-stack__item"
            name="{{ isOpen ? 'expand-less' : 'expand-more' }}"
            data-role="bb-collapsible-card-header__icon"
          ></bb-icon-ui>
        }
      </div>
    </div>
  </ng-template>

  <ng-template bbCollapsibleBody>
    <div class="bb-card__body" data-role="bb-collapsible-card-body">
      <ng-content select="[bbCollapsibleBody]"></ng-content>
    </div>
  </ng-template>
</bb-collapsible-ui>
