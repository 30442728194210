<div class="bb-list">
  <h2>Modal alertdialog</h2>

  <div class="bb-list__item">
    <h3>Accessibility of modal header via <code>bb-modal-header-ui</code></h3>
    <p>
      The modal header is accessible because <code>bb-modal-header-ui</code> is automatically detected and configured
      via ARIA attributes by <code>bb-modal-ui</code>.
    </p>
    <button bbButton (click)="actionModal = true" [attr.data-role]="'modal-call-to-action'">
      Open modal with call to action
    </button>
  </div>
  <div class="bb-list__item">
    <h3>Accessibility of modal header via <code>aria-lablledby</code></h3>
    <p>
      Modal with custom header, that is made accessible using the <code>aria-lablledby</code> property of
      <code>bb-modal-ui</code>.
    </p>
    <button bbButton (click)="passCodeModal = true" [attr.data-role]="'modal-with-password'">
      Open modal for entering passcode
    </button>
  </div>
  <div class="bb-list__item">
    <h3>Icon placement to the left in the header</h3>
    <button bbButton (click)="leftHeaderModal = true" [attr.data-role]="'modal-with-header-icon'">
      Modal with left header icon
    </button>
  </div>
  <div class="bb-list__item">
    <h3>Modal with three button for call to action</h3>
    <button bbButton (click)="threeBtnModal = true" [attr.data-role]="'modal-with-3-buttons'">
      Open modal with three buttons
    </button>
  </div>

  <h2>Modal dialog</h2>

  <div class="bb-list__item">
    <h3>Modal with big height</h3>
    <button bbButton (click)="longModal = true" [attr.data-role]="'modal-long'">Open modal with big height</button>
  </div>
  <div class="bb-list__item">
    <h3>Scrollable modal</h3>
    <button bbButton (click)="scrollableModal = true" [attr.data-role]="'modal-scrollable'">
      Open scrollable modal
    </button>
  </div>
  <div class="bb-list__item">
    <h3>Size variants</h3>
    <div class="bb-toolbar">
      <button class="bb-toolbar__item" bbButton (click)="smallModal = true" [attr.data-role]="'small-modal'">
        Open small modal
      </button>
      <button class="bb-toolbar__item" bbButton (click)="defaultModal = true" [attr.data-role]="'medium-modal'">
        Open medium modal
      </button>
      <button class="bb-toolbar__item" bbButton (click)="largeModal = true" [attr.data-role]="'large-modal'">
        Open large modal
      </button>
    </div>
  </div>
  <div class="bb-list__item">
    <h3>Modal with image</h3>
    <button bbButton (click)="imageModal = true" [attr.data-role]="'modal-with-image'">Open modal with image</button>
  </div>
  <div class="bb-list__item">
    <h3>Modal with datepicker</h3>
    <button bbButton (click)="datepickerModal = true" [attr.data-role]="'modal-with-datepicker'">
      Open modal with datepicker
    </button>
  </div>
  <div class="bb-list__item">
    <h3>Modal window over the modal window</h3>
    <button bbButton (click)="modalLower = true; modalUpper = true" [attr.data-role]="'modal-over-modal'">
      Open 2 modal windows
    </button>
  </div>
  <div class="bb-list__item">
    <h3>Modal window with dropdown</h3>
    <button bbButton (click)="modalWithDropdown = true" [attr.data-role]="'modal-with-dropdown'">
      Open modal with dropdown
    </button>
  </div>
  <div class="bb-list__item">
    <h3>Modal window with account selector</h3>
    <button bbButton (click)="modalWithAccountSelector = true" [attr.data-role]="'modal-with-account-selector'">
      Open modal with account selector
    </button>
  </div>
  <div class="bb-list__item">
    <h3>Modal window notification after close</h3>
    <button
      bbButton
      (click)="modalWithNotificationAfterClose = true"
      [attr.data-role]="'modal-with-notification-after-close'"
    >
      Open modal that shows notification on close
    </button>
  </div>
</div>

<!-- Small modal -->
<bb-modal-ui
  [isOpen]="smallModal"
  (cancel)="smallModal = false"
  (confirm)="smallModal = false"
  [modalOptions]="{ size: 'sm' }"
>
  <bb-modal-header-ui title="Modal"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>This is a modal.</ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Close"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Default modal -->
<bb-modal-ui [isOpen]="defaultModal" (cancel)="defaultModal = false" (confirm)="defaultModal = false">
  <bb-modal-header-ui title="Modal"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>This is a modal.</ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Close"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Large modal -->
<bb-modal-ui
  [isOpen]="largeModal"
  (cancel)="largeModal = false"
  (confirm)="largeModal = false"
  [modalOptions]="{ size: 'lg' }"
>
  <bb-modal-header-ui title="Modal"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>This is a modal.</ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Close"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Modal with action -->
<bb-modal-ui
  [isOpen]="actionModal"
  (cancel)="actionModal = false"
  (confirm)="console.log('action'); actionModal = false"
  [role]="'alertdialog'"
>
  <bb-modal-header-ui title="Discard payment data?"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody> Are you sure to change payment type? Entered data will be lost. </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Discard"></bb-modal-footer-ui>
</bb-modal-ui>

<p class="visually-hidden" id="customModalLabel">This is an authorisation dialog</p>

<!-- Modal with passcode -->
<bb-modal-ui
  [(isOpen)]="passCodeModal"
  (confirm)="passCodeModal = false"
  [modalOptions]="{ size: 'lg', ariaLabelledBy: 'customModalLabel', ariaDescribedBy: 'customModalDescription' }"
  [role]="'alertdialog'"
>
  <form name="stepUpForm">
    <div
      class="modal-header justify-content-center"
      role="heading"
      aria-level="2"
      id="passCodeModal"
      data-role="modal-header"
    >
      <span class="modal-title"> Authorisation </span>
    </div>
    <bb-modal-body-ui>
      <ng-template bbCustomModalBody>
        <div class="d-flex justify-content-center text-body-secondary pb-3" id="customModalDescription">
          Enter your personal secure code to continue
        </div>
        <div class="row d-flex justify-content-center bb-force-ltr" data-role="password">
          @for (inputChar of charsArray; track inputChar) {
            <div class="col-2 col-sm-1 px-2">
              <input
                type="password"
                class="form-control px-0 text-center"
                aria-label="First character of your secure code"
                [name]="'char-' + inputChar"
              />
            </div>
          }
        </div>
      </ng-template>
    </bb-modal-body-ui>
    <div class="modal-footer justify-content-center">
      <div class="bb-button-bar">
        <button
          bbButton
          class="bb-button-bar__button"
          color="primary"
          (click)="passCodeModal = false"
          data-role="authorise"
        >
          Authorise
        </button>
        <button
          bbButton
          class="bb-button-bar__button"
          color="secondary"
          (click)="passCodeModal = false"
          data-role="cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</bb-modal-ui>

<!-- Modal with left header icon -->
<bb-modal-ui
  [isOpen]="leftHeaderModal"
  (cancel)="leftHeaderModal = false"
  (confirm)="leftHeaderModal = false"
  [aria-describedby]="'customModalDescription'"
  [aria-labelledby]="'leftHeaderIconModal'"
  [role]="'alertdialog'"
>
  <bb-modal-header-ui>
    <ng-template bbCustomModalHeader>
      <div class="bb-stack">
        <div class="bb-stack__item">
          <div class="bb-stack">
            <bb-icon-ui
              class="bb-stack__item"
              color="warning"
              name="warning"
              [attr.data-role]="'warning-icon'"
            ></bb-icon-ui>
            <span class="modal-title" [attr.data-role]="'modal-title'" id="leftHeaderIconModal">
              One line question with icon?
            </span>
          </div>
        </div>
        <div class="bb-stack__item--push-right">
          <div class="bb-stack">
            <button bbButton class="bb-stack__item-sm" color="dark" buttonSize="sm" [circle]="true" aria-label="print">
              <bb-icon-ui color="muted" name="print"></bb-icon-ui>
            </button>
            <button bbButton class="bb-stack__item-sm" color="dark" buttonSize="sm" [circle]="true" aria-label="more">
              <bb-icon-ui color="muted" name="more-vertical"></bb-icon-ui>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <p id="customModalDescription">Are you sure to change payment type? Entered data will be lost.</p>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui>
    <ng-template bbCustomModalFooter>
      <div class="bb-button-bar">
        <button
          bbButton
          class="bb-button-bar__button"
          (click)="console.log('Submitted'); leftHeaderModal = false"
          data-role="discard"
        >
          Discard
        </button>
        <button
          bbButton
          class="bb-button-bar__button"
          (click)="console.log('Cancelled'); leftHeaderModal = false"
          color="secondary"
          data-role="cancel"
        >
          Cancel
        </button>
      </div>
    </ng-template>
  </bb-modal-footer-ui>
</bb-modal-ui>

<!-- Modal with 3 buttons -->
<bb-modal-ui
  [isOpen]="threeBtnModal"
  (cancel)="threeBtnModal = false"
  (confirm)="threeBtnModal = false"
  [role]="'alertdialog'"
>
  <bb-modal-header-ui title="Discard payment data?"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody> Are you sure to change payment type? Entered data will be lost. </ng-template>
  </bb-modal-body-ui>

  <bb-modal-footer-ui>
    <ng-template bbCustomModalFooter>
      <div class="bb-button-bar">
        <button bbButton class="bb-button-bar__button" (click)="threeBtnModal = false" data-role="continue">
          Continue
        </button>
        <button
          bbButton
          class="bb-button-bar__button"
          color="secondary"
          (click)="threeBtnModal = false"
          data-role="back"
        >
          Back
        </button>
        <button
          bbButton
          class="bb-button-bar__button bb-button-bar__button--across"
          color="tertiary"
          (click)="threeBtnModal = false"
          data-role="cancel"
        >
          Cancel
        </button>
      </div>
    </ng-template>
  </bb-modal-footer-ui>
</bb-modal-ui>

<!-- Long modal -->
<bb-modal-ui [isOpen]="longModal" (cancel)="longModal = false" (confirm)="longModal = false">
  <bb-modal-header-ui title="Modal"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <div style="height: 1500px">This is a modal</div>
      <div data-role="out-of-view-text">A very, very tall modal</div>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Close"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Scrollable modal -->
<bb-modal-ui
  [isOpen]="scrollableModal"
  [modalOptions]="{ scrollable: true }"
  (cancel)="scrollableModal = false"
  (confirm)="scrollableModal = false"
>
  <bb-modal-header-ui title="Modal"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <div style="height: 1500px">This is a modal</div>
      <div data-role="out-of-view-text">A very, very tall modal</div>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Close"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Modal with image -->
<bb-modal-ui [isOpen]="imageModal" (cancel)="imageModal = false" (confirm)="imageModal = false">
  <img src="assets/images/modal-image.png" alt="example-image" />
  <bb-modal-header-ui title="Modal"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>This is a modal</ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Close"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Modal with datepicker -->
<bb-modal-ui [isOpen]="datepickerModal" (cancel)="datepickerModal = false" (confirm)="datepickerModal = false">
  <bb-modal-header-ui title="Select a date"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <div class="bb-block bb-block--lg">
        <h4 id="datePickerDefaultLabel">
          <label [attr.for]="defaultDatePicker6?.id">Date</label>
        </h4>
        <div class="datepicker-default" [attr.data-role]="'datepicker-default'">
          <div class="visually-hidden" aria-live="polite" id="customDatepickerDescribedbyLabel">
            @if (focusedDate$$ | async; as focusDate) {
              <div>
                @if (focusDate.isSelecting) {
                  <span
                    >Current date in focus is
                    {{
                      focusDate.date?.year + '-' + focusDate.date?.month + '-' + focusDate.date?.day | date: 'longDate'
                    }}</span
                  >
                }
              </div>
            }
          </div>
          <bb-input-datepicker-ui
            #defaultDatePicker6
            labelClasses="font-weight-bold"
            name="startDate"
            [(ngModel)]="predefinedDate"
            [ariaLabelForButton]="'Show calendar dropdown'"
            [aria-labelledby]="'datePickerDefaultLabel'"
            [firstDayOfWeek]="0"
            (focusedDate)="focusedDate($event)"
            container="body"
          ></bb-input-datepicker-ui>
        </div>
      </div>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Submit"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Modal over modal - lower window -->
<bb-modal-ui
  [modalOptions]="{ size: 'xl' }"
  [isOpen]="modalLower"
  (cancel)="modalLower = false"
  (confirm)="modalLower = false"
>
  <bb-modal-header-ui title="Modal lower"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <h3 style="height: 200px">Lower content</h3>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Close"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Modal over modal - upper window -->
<bb-modal-ui [isOpen]="modalUpper" (cancel)="modalUpper = false" (confirm)="modalUpper = false">
  <bb-modal-header-ui title="Modal upper"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <h3>Upper content</h3>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Close"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Modal with a dropdown -->
<bb-modal-ui [isOpen]="modalWithDropdown" (cancel)="modalWithDropdown = false" (confirm)="modalWithDropdown = false">
  <bb-modal-header-ui title="Modal with a dropdown"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <bb-dropdown-menu-ui
        position="bottom"
        icon="caret-down"
        class="btn-md"
        btnColor="primary"
        [bbDropdownToggleFullWidth]="true"
        iconSize="lg"
        container="body"
      >
        <ng-template bbDropdownLabel>
          <span>Dropdown</span>
        </ng-template>
        <ng-template bbDropdownMenuItem>
          @for (option of dropdownTemplateMenuOptions; track $index) {
            <a
              [id]="option.name"
              rel="noopener noreferrer"
              role="menuitem"
              class="dropdown-item"
              target="_blank"
              [href]="option.url"
            >
              {{ option.name }}
            </a>
          }
        </ng-template>
      </bb-dropdown-menu-ui>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Close"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Modal with an account selector -->
<bb-modal-ui
  [isOpen]="modalWithAccountSelector"
  (cancel)="modalWithAccountSelector = false"
  (confirm)="modalWithAccountSelector = false"
  [modalOptions]="{ modalDialogClass: 'modal-overflow' }"
>
  <bb-modal-header-ui title="Select an account"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <bb-account-selector-ui
        placeholder="Account Selector"
        [items]="accountSelectorItems"
        data-role="account-selector"
      ></bb-account-selector-ui>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Submit"></bb-modal-footer-ui>
</bb-modal-ui>

<!-- Modal with an account selector -->
<bb-modal-ui
  [isOpen]="modalWithNotificationAfterClose"
  (cancel)="modalWithNotificationAfterClose = false; showNotification()"
  (confirm)="modalWithNotificationAfterClose = false; showNotification()"
  [modalOptions]="{ modalDialogClass: 'modal-overflow' }"
>
  <bb-modal-header-ui title="Close to see notification"></bb-modal-header-ui>
  <bb-modal-body-ui>
    <ng-template bbCustomModalBody>
      <p>Modal will show notification after being closed and test make it very long to check something</p>
    </ng-template>
  </bb-modal-body-ui>
  <bb-modal-footer-ui confirmText="Submit"></bb-modal-footer-ui>
</bb-modal-ui>
