<button
  [id]="id + '_load-button'"
  class="bb-load-button"
  [ngClass]="{ 'bb-load-button--is-loading': isLoading }"
  #button
  bbButton
  [color]="color"
  [buttonSize]="buttonSize"
  [autofocus]="autofocus"
  [type]="type"
  [block]="block"
  [disabled]="this.isLoadingDisabled"
  (click)="onClick($event)"
  [circle]="circle"
  [attr.aria-label]="ariaLabel"
>
  @if (totalCount !== 0 && !hasMore() && !isLoading) {
    <span
      class="visually-hidden"
      role="status"
      i18n="All content has been loaded|All content has been loaded text @@bb-load-button-ui.allContentLoaded.label"
    >
      All content has been loaded</span
    >
  }
  <span
    [ngClass]="{ invisible: isLoading }"
    class="bb-load-button__content"
    (bbKeyboardClik)="this.isLoadingDisabled ? $event.stopPropagation() : null"
    [attr.aria-hidden]="!hasMore() || !!ariaLabel ? true : undefined"
  >
    <ng-content></ng-content>
  </span>
  @if (isLoading) {
    <bb-loading-indicator-ui class="bb-load-button__loading-indicator" loaderSize="sm"></bb-loading-indicator-ui>
  }
</button>
