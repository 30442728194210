<div class="heading-widget bb-heading-widget {{ customClassName }}">
  <bb-header-ui
    headingClasses="bb-heading-widget__heading {{ headingClasses }}"
    [headingType]="headingType"
    [heading]="heading"
  ></bb-header-ui>

  @if (showNavigation) {
    @if (menuItems | async; as menu) {
      @if (menu.length) {
        <bb-heading-dropdown
          [menu]="menu"
          [useFirstLinkAsDefault]="useFirstLinkAsDefault"
          [position]="position"
          [buttonLabel]="buttonLabel"
          [buttonIcon]="buttonIcon"
          [buttonColor]="buttonColor"
          [buttonSize]="buttonSize"
          (navigationAction)="onClick($event)"
          data-role="heading-dropdown"
        >
        </bb-heading-dropdown>
      }
    }
  }
</div>
