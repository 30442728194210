import { Directive, ElementRef } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Directive({
  selector: 'form[bbFormSubmit]',
})
export class FormSubmitDirective {
  private readonly element = this.elementRef.nativeElement;
  readonly submit: Observable<Event> = fromEvent<Event>(this.element, 'submit').pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(private readonly elementRef: ElementRef<HTMLFormElement>) {}
}
