import { InjectionToken } from '@angular/core';

export interface InputValidationMessageConfig {
  showIcon?: boolean;
}

/**
 * Input validation message icon visibility Token
 */
export const INPUT_VALIDATION_MESSAGE_CONFIG = new InjectionToken<InputValidationMessageConfig>(
  'INPUT_VALIDATION_MESSAGE_CONFIG',
);
