<div class="bb-common-error-state" role="status">
  <ng-container *ngTemplateOutlet="getErrorTemplate()"></ng-container>
</div>

<ng-template #errorBadRequest>
  <bb-empty-state-ui
    image="ic_portrait_casual_woman_1_neutral"
    i18n-title="User action error title@@errorCommonState.error.badRequest.title"
    title="Bad request error"
    i18n-subtitle="User action error message@@errorCommonState.error.badRequest.message"
    subtitle="There is a problem with the action you are trying to perform."
  ></bb-empty-state-ui>
</ng-template>

<ng-template #errorUnauthorized>
  <bb-empty-state-ui
    image="ic_portrait_casual_woman_1_neutral"
    i18n-title="Unauthorized error title@@errorCommonState.error.unauthorized.title"
    title="Unauthorized error"
    i18n-subtitle="Unauthorized error message@@errorCommonState.error.unauthorized.message"
    subtitle="You must authenticate yourself to get the requested response."
  ></bb-empty-state-ui>
</ng-template>

<ng-template #errorForbidden>
  <bb-empty-state-ui
    image="ic_portrait_casual_woman_1_neutral"
    i18n-title="Forbidden error title@@errorCommonState.error.forbidden.title"
    title="Forbidden error"
    i18n-subtitle="Forbidden error message@@errorCommonState.error.forbidden.message"
    subtitle="You do not have access rights to the content."
  ></bb-empty-state-ui>
</ng-template>

<ng-template #errorNotFound>
  <bb-empty-state-ui
    image="ic_asset_coffee"
    i18n-title="Not found error title@@errorCommonState.error.notFound.title"
    title="Not found error"
    i18n-subtitle="Not found error message@@errorCommonState.error.notFound.message"
    subtitle="No data was found."
  ></bb-empty-state-ui>
</ng-template>

<ng-template #errorConnectivityTemplate>
  <bb-empty-state-ui
    image="ic_asset_coffee"
    i18n-title="Internet connection error title@@errorCommonState.error.connectivity.title"
    title="Internet connection error"
    i18n-subtitle="Internet connection error message@@errorCommonState.error.connectivity.message"
    subtitle="Are you connected to the internet? Please check your connection and try again."
  ></bb-empty-state-ui>
</ng-template>

<ng-template #errorUnexpectedTemplate>
  <bb-empty-state-ui
    image="ic_asset_coffee"
    i18n-title="Unexpected error title@@errorCommonState.error.unexpected.title"
    title="Unexpected error"
    i18n-subtitle="Unexpected error message@@errorCommonState.error.unexpected.message"
    subtitle="Something's gone wrong with our server. Please try again later."
  ></bb-empty-state-ui>
</ng-template>

<ng-template #errorMessageTemplate>
  <bb-empty-state-ui
    image="ic_asset_coffee"
    i18n-title="Unknown error title@@errorCommonState.error.unknown.title"
    title="Unknown error"
    [subtitle]="getMessage()"
  ></bb-empty-state-ui>
</ng-template>

<ng-template #errorTextTemplate>
  <bb-empty-state-ui [title]="getMessage()" image="ic_asset_coffee"></bb-empty-state-ui>
</ng-template>

<ng-template #errorCustomTemplate>
  <ng-container *ngTemplateOutlet="error"></ng-container>
</ng-template>
