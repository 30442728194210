import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  CustomProductItemSavingsAccountDetailsFormatterDirective,
  CustomProductItemSavingsAccountMainSectionDirective,
  CustomProductItemSavingsAccountTitleDirective,
  ProductItemSavingsAccountComponent,
} from './product-item-savings-account.component';
import { AmountModule } from '@backbase/ui-ang/amount';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { HighlightModule } from '@backbase/ui-ang/highlight';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [NgClass, AmountModule, PaymentCardNumberModule, HighlightModule, IconModule, NgTemplateOutlet],
  declarations: [
    ProductItemSavingsAccountComponent,
    CustomProductItemSavingsAccountTitleDirective,
    CustomProductItemSavingsAccountDetailsFormatterDirective,
    CustomProductItemSavingsAccountMainSectionDirective,
  ],
  exports: [
    ProductItemSavingsAccountComponent,
    CustomProductItemSavingsAccountTitleDirective,
    CustomProductItemSavingsAccountDetailsFormatterDirective,
    CustomProductItemSavingsAccountMainSectionDirective,
  ],
})
export class ProductItemSavingsAccountModule {}
