import { NgModule } from '@angular/core';
import '@angular/localize/init';

import { EmptyStateComponent } from './empty-state.component';
import { IllustrationModule } from '@backbase/ui-ang/illustration';
import { PresetTitlePipe } from './preset-title.pipe';
import { PresetSubtitlePipe } from './preset-subtitle.pipe';
import { PresetIllustrationPipe } from './preset-illustration.pipe';
import { ButtonModule } from '@backbase/ui-ang/button';
import { PresetPrimaryActionTextPipe } from './preset-primary-action-text.pipe';
import { IconModule } from '@backbase/ui-ang/icon';
import { NgTemplateOutlet } from '@angular/common';

@NgModule({
  imports: [IllustrationModule, ButtonModule, IconModule, NgTemplateOutlet],
  declarations: [
    EmptyStateComponent,
    PresetTitlePipe,
    PresetSubtitlePipe,
    PresetIllustrationPipe,
    PresetPrimaryActionTextPipe,
  ],
  exports: [EmptyStateComponent],
})
export class EmptyStateModule {}
