<ng-select
  #accountSelector
  #accountSelectorModel="ngModel"
  (change)="onChange($event)"
  (clear)="onClear()"
  (remove)="onRemove($event)"
  (close)="onClose()"
  (open)="onOpen()"
  (scroll)="onScroll($event)"
  (scrollToEnd)="onScrollToEnd()"
  [searchFn]="searchFunc"
  [dropdownPosition]="dropdownPosition"
  [groupBy]="groupByFn"
  [groupValue]="groupValueFn"
  (search)="onSearch($event)"
  [(ngModel)]="selectedItems"
  [items]="items"
  [clearable]="clearable"
  [closeOnSelect]="closeOnSelect"
  [multiple]="multiple"
  [placeholder]="placeholder"
  [loading]="loading"
  [markFirst]="markFirst"
  [searchable]="true"
  [disabled]="disabled"
  [notFoundText]="notFoundTextLabel"
  [ngClass]="accountSelectorSizeClass"
  [compareWith]="compareItemsWith"
  [virtualScroll]="virtualScroll"
  [required]="required"
  [typeahead]="typeahead"
  [minTermLength]="minTermLength"
  [typeToSearchText]="typeToSearchText"
  [inputAttrs]="inputAttrs"
  (focus)="onFocus()"
  (blur)="onBlur()"
  bindLabel="name"
  class="bb-account-selector"
  name="bb-account-selector-ui"
  ngDefaultControl
  [isOpen]="isOpen"
  [attr.aria-disabled]="disabled"
  [ariaLabel]="placeholder || 'Select Account'"
  i18n-ariaLabel="AccountSelector|AccountSelector placeholder@@bb-account-selector-ui.account-selector.ariaLabel"
>
  <!-- Template for item selected (single:ng-label-tmp) ng-label-tmp-->
  @if (!multiple; as item) {
    <ng-template let-item="item" ng-label-tmp>
      @if (customSingleSelectedItemLabel) {
        <ng-container
          *ngTemplateOutlet="
            customSingleSelectedItemLabel?.templateRef;
            context: {
              hostRef: hostRef,
              item: item
            }
          "
        ></ng-container>
      } @else {
        @switch (showAvailableBalance) {
          @case (balanceDetailsViewType.Booked) {
            <bb-product-item-basic-account-ui
              [attr.id]="defaultSelectedItemsElementId"
              [selected]="true"
              [active]="true"
              [amount]="item.balance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [productNumber]="item.number"
              [bankBranchCode]="showBankBranchCode ? selectedItems.bankBranchCode : undefined"
              [showCurrencySymbol]="showCurrencySymbol"
              [title]="item.name"
              [type]="item.type"
              [status]="item.status"
              [highlight]="highlight"
            ></bb-product-item-basic-account-ui>
          }
          @case (balanceDetailsViewType.Available) {
            <bb-product-item-basic-account-ui
              [attr.id]="defaultSelectedItemsElementId"
              [selected]="true"
              [active]="true"
              [amount]="item.availableBalance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [productNumber]="item.number"
              [bankBranchCode]="showBankBranchCode ? selectedItems.bankBranchCode : undefined"
              [showCurrencySymbol]="showCurrencySymbol"
              [title]="item.name"
              [type]="item.type"
              [status]="item.status"
              [highlight]="highlight"
            ></bb-product-item-basic-account-ui>
          }
          @case (balanceDetailsViewType.Ordinary) {
            <bb-product-item-basic-account-ui
              [selected]="true"
              [active]="true"
              [amount]="item.availableBalance"
              [secondaryAmount]="item.balance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [productNumber]="item.number"
              [bankBranchCode]="showBankBranchCode ? selectedItems.bankBranchCode : undefined"
              [showCurrencySymbol]="showCurrencySymbol"
              [title]="item.name"
              [type]="item.type"
              [status]="item.status"
              [highlight]="highlight"
              balanceLabel="Available"
              i18n-balanceLabel="
                Single selected account primary balance label@@bb-account-selector-ui.primary-balance-label.available"
              balanceLabelForSecondaryAmount="Balance"
              i18n-balanceLabelForSecondaryAmount="
                Single selected account secondary balance label@@bb-account-selector-ui.secondary-balance-label.balance"
            ></bb-product-item-basic-account-ui>
          }
          @case (balanceDetailsViewType.Reverse) {
            <bb-product-item-basic-account-ui
              [selected]="true"
              [active]="true"
              [amount]="item.balance"
              [secondaryAmount]="item.availableBalance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [productNumber]="item.number"
              [bankBranchCode]="showBankBranchCode ? selectedItems.bankBranchCode : undefined"
              [showCurrencySymbol]="showCurrencySymbol"
              [title]="item.name"
              [type]="item.type"
              [status]="item.status"
              [highlight]="highlight"
              balanceLabel="Balance"
              i18n-balanceLabel="
                Single selected account primary balance label@@bb-account-selector-ui.primary-balance-label.balance"
              balanceLabelForSecondaryAmount="Available"
              i18n-balanceLabelForSecondaryAmount="
                Single selected account secondary balance
                label@@bb-account-selector-ui.secondary-balance-label.available"
            ></bb-product-item-basic-account-ui>
          }
          @default {
            <bb-product-item-basic-account-ui
              [attr.id]="defaultSelectedItemsElementId"
              [selected]="true"
              [active]="true"
              [amount]="item.balance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [productNumber]="item.number"
              [bankBranchCode]="showBankBranchCode ? selectedItems.bankBranchCode : undefined"
              [showCurrencySymbol]="showCurrencySymbol"
              [title]="item.name"
              [type]="item.type"
              [status]="item.status"
              [highlight]="highlight"
            ></bb-product-item-basic-account-ui>
          }
        }
      }
      <!-- Default template for ng-label-tmp -->
    </ng-template>
  }

  <!-- Template for items selected (multiple:ng-multi-label-tmp) -->
  @if (multiple; as items) {
    <ng-template let-items="items" ng-multi-label-tmp>
      @if (customMultiSelectedItemsLabel) {
        <ng-container
          *ngTemplateOutlet="
            customMultiSelectedItemsLabel?.templateRef;
            context: {
              hostRef: hostRef,
              items: items
            }
          "
        ></ng-container>
      } @else {
        <div class="bb-account-selector__selected" [attr.id]="defaultSelectedItemsElementId">
          <div class="bb-account-selector__selected-accounts-number bb-text-bold">
            <span
              i18n="Selected Accounts|Selected Accounts multi label @@bb-account-selector-ui.multi.label"
              data-role="selected-account-count"
            >
              {items.length, plural,
                =1 {1 account selected}
                other {{{hostRef.items.length === selectedItems.length ? 'All' : items.length}} accounts selected}
              }
            </span>
          </div>
          <div class="bb-account-selector__selected-accounts bb-text-subtitle" data-role="selected-account">
            @if (hostRef.items.length === selectedItems.length) {
              {{ items.length }}
              <span
                i18n="
                  All Selected Accounts|All Selected Accounts label @@bb-account-selector-ui.allSelectedAccounts.label"
                class="bb-account-selector__selected-account"
              >
                accounts
              </span>
            } @else {
              <bb-ellipsis-ui>
                @for (item of items; track item.name) {
                  <span class="bb-account-selector__selected-account"
                    >{{ item.name }}
                    @if (!$last) {
                      <span class="bb-account-selector__selected-account-separator">, </span>
                    }
                  </span>
                }
              </bb-ellipsis-ui>
            }
          </div>
        </div>
      }
      <!-- Default template for ng-multi-label-tmp -->
    </ng-template>
  }

  <!-- Template for header, with search (ng-header-tmp) -->
  <ng-template ng-header-tmp>
    @if (customOptionsHeader) {
      <ng-container class="bb-account-selector-dropdown__header">
        <ng-container
          *ngTemplateOutlet="
            customOptionsHeader?.templateRef;
            context: {
              hostRef: hostRef,
              multiple: multiple,
              filterItems: filterItems
            }
          "
        ></ng-container>
      </ng-container>
    } @else {
      <section
        class="bb-account-selector-dropdown__header"
        [ngClass]="{ 'bb-account-selector-dropdown__header--hidden': !searchable }"
      >
        @if (filterItems) {
          <bb-input-text-ui
            (valueChange)="onFilterChange($event)"
            (focus)="ngSelect?.onInputFocus($event)"
            (blur)="ngSelect?.onInputBlur($event)"
            i18n-placeholder="Filter|Filter placeholder@@bb-account-selector-ui.filter.placeholder"
            placeholder="Filter"
            [autofocus]="true"
            [id]="ngSelect?.labelForId"
            [disabled]="ngSelect?.disabled"
            [readonly]="ngSelect?.itemsList.maxItemsSelected"
            [aria-autocomplete]="'list'"
            [aria-controls]="statusId"
            [aria-invalid]="ariaInvalid"
            [aria-owns]="ngSelect?.isOpen ? ngSelect?.dropdownId : null"
            [aria-activedescendant]="ngSelect?.isOpen ? ngSelect?.itemsList?.markedItem?.htmlId : null"
            [aria-describedby]="ariaDescribedby"
            [aria-expanded]="ngSelect?.isOpen"
            [aria-label]="placeholder || 'Filter'"
            i18n-aria-label="
              Filter Accounts|Fallback accessible label for account
              filter@@bb-account-selector-ui.filter.aria-label-fallback"
          ></bb-input-text-ui>
        }
        <span [id]="instructionsId" [hidden]="true" aria-hidden="true">
          {{ accountSelectorInstructions }}
        </span>
        @if (!filterItems) {
          <bb-search-box-ui
            (clear)="onClear()"
            (valueChange)="onFilterChange($event)"
            (submit)="onSearch($event)"
            (focus)="ngSelect?.onInputFocus($event)"
            (blur)="ngSelect?.onInputBlur($event)"
            placeholder="Search"
            i18n-placeholder="Search|Search placeholder@@bb-account-selector-ui.search.placeholder"
            [showClear]="true"
            showSearch="true"
            [role]="'combobox'"
            [autofocus]="true"
            [id]="ngSelect?.labelForId"
            [disabled]="ngSelect?.disabled"
            [readonly]="ngSelect?.itemsList.maxItemsSelected"
            [aria-autocomplete]="'list'"
            [aria-controls]="statusId"
            [aria-expanded]="ngSelect?.isOpen"
            [aria-invalid]="ariaInvalid"
            [aria-owns]="ngSelect?.isOpen ? ngSelect?.dropdownId : null"
            [aria-activedescendant]="ngSelect?.isOpen ? ngSelect?.itemsList?.markedItem?.htmlId : null"
            [aria-describedby]="instructionsId"
            [aria-label]="placeholder || 'Search'"
            i18n-aria-label="
              Search Accounts|Fallback accessible label for account search
              box@@bb-account-selector-ui.search.aria-label-fallback"
          ></bb-search-box-ui>
        }
      </section>
      @if (multiple) {
        <div class="bb-account-selector--multiple__selected-account-filters">
          <button
            #selectAllButton
            (click)="selectAll()"
            bbButton
            buttonSize="sm"
            color="tertiary"
            i18n="Select all|Select all text@@bb-account-selector-ui.select.all.text"
            data-role="select-all"
          >
            Select all
          </button>
          <button
            #unselectAllButton
            (click)="unselectAll()"
            bbButton
            buttonSize="sm"
            color="tertiary"
            i18n="Unselect all|Unselect all text@@bb-account-selector-ui.unselect.all.text"
            data-role="unselect-all"
          >
            Unselect all
          </button>
        </div>
      }
      <div [id]="statusId" aria-atomic="true" class="visually-hidden" role="status">
        @if (!!resultCount) {
          <ng-container
            i18n="
              Number of options|Screen reader description of the number of options after filtering the
              combobox@@bb-account-selector-ui.options.count"
          >
            {{ resultCount }} option(s)
          </ng-container>
        } @else {
          {{ notFoundTextLabel }}
        }
      </div>
    }
    <!-- Default template for ng-header-tmp -->
  </ng-template>

  <!-- Template for dropdown items list (ng-option-tmp) -->
  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
    @if (customOptionItem) {
      <ng-container
        *ngTemplateOutlet="
          customOptionItem?.templateRef;
          context: {
            hostRef: hostRef,
            multiple: multiple,
            item: item,
            item$: item$,
            index: index
          }
        "
      ></ng-container>
    } @else {
      @if (multiple) {
        <div class="bb-stack bb-account-selector__option">
          <div class="bb-stack__item">
            <bb-input-checkbox-ui
              (bbKeyboardClick)="$event.preventDefault()"
              [id]="index"
              [ngModel]="item$.selected"
              [hideCheckbox]="true"
            >
              <span class="visually-hidden">Select {{ item.name }}</span>
            </bb-input-checkbox-ui>
          </div>
          <div class="bb-stack__item bb-stack__item--fill">
            @switch (showAvailableBalance) {
              @case (balanceDetailsViewType.Booked) {
                <bb-product-item-basic-account-ui
                  [active]="false"
                  [productNumber]="item.number"
                  [amount]="item.balance"
                  [currency]="item.currency"
                  [productNumberFormat]="productNumberFormat"
                  [showCurrencySymbol]="showCurrencySymbol"
                  [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
                  [title]="item.name"
                  [highlight]="highlight"
                ></bb-product-item-basic-account-ui>
              }
              @case (balanceDetailsViewType.Available) {
                <bb-product-item-basic-account-ui
                  [active]="false"
                  [productNumber]="item.number"
                  [amount]="item.availableBalance"
                  [currency]="item.currency"
                  [productNumberFormat]="productNumberFormat"
                  [showCurrencySymbol]="showCurrencySymbol"
                  [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
                  [title]="item.name"
                  [highlight]="highlight"
                ></bb-product-item-basic-account-ui>
              }
              @case (balanceDetailsViewType.Ordinary) {
                <bb-product-item-basic-account-ui
                  [active]="false"
                  [productNumber]="item.number"
                  [amount]="item.availableBalance"
                  [secondaryAmount]="item.balance"
                  [currency]="item.currency"
                  [productNumberFormat]="productNumberFormat"
                  [showCurrencySymbol]="showCurrencySymbol"
                  [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
                  [title]="item.name"
                  [highlight]="highlight"
                  balanceLabel="Available"
                  i18n-balanceLabel="
                    Multiple items option list primary balance
                    label@@bb-account-selector-ui.primary-balance-label.available"
                  balanceLabelForSecondaryAmount="Balance"
                  i18n-balanceLabelForSecondaryAmount="
                    Multiple items option list secondary balance
                    label@@bb-account-selector-ui.secondary-balance-label.balance"
                ></bb-product-item-basic-account-ui>
              }
              @case (balanceDetailsViewType.Reverse) {
                <bb-product-item-basic-account-ui
                  [active]="false"
                  [productNumber]="item.number"
                  [amount]="item.balance"
                  [secondaryAmount]="item.availableBalance"
                  [currency]="item.currency"
                  [productNumberFormat]="productNumberFormat"
                  [showCurrencySymbol]="showCurrencySymbol"
                  [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
                  [title]="item.name"
                  [highlight]="highlight"
                  balanceLabel="Balance"
                  i18n-balanceLabel="
                    Multiple items option list primary balance
                    label@@bb-account-selector-ui.primary-balance-label.balance"
                  balanceLabelForSecondaryAmount="Available"
                  i18n-balanceLabelForSecondaryAmount="
                    Multiple items option list secondary balance
                    label@@bb-account-selector-ui.secondary-balance-label.available"
                ></bb-product-item-basic-account-ui>
              }
              @default {
                <bb-product-item-basic-account-ui
                  [active]="false"
                  [productNumber]="item.number"
                  [amount]="item.balance"
                  [currency]="item.currency"
                  [productNumberFormat]="productNumberFormat"
                  [showCurrencySymbol]="showCurrencySymbol"
                  [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
                  [title]="item.name"
                  [highlight]="highlight"
                ></bb-product-item-basic-account-ui>
              }
            }
          </div>
        </div>
      }
      @if (!multiple) {
        @switch (showAvailableBalance) {
          @case (balanceDetailsViewType.Booked) {
            <bb-product-item-basic-account-ui
              [active]="item$.selected"
              [productNumber]="item.number"
              [amount]="item.balance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [showCurrencySymbol]="showCurrencySymbol"
              [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
              [title]="item.name"
              [status]="item.status"
              [highlight]="highlight"
            ></bb-product-item-basic-account-ui>
          }
          @case (balanceDetailsViewType.Available) {
            <bb-product-item-basic-account-ui
              [active]="item$.selected"
              [productNumber]="item.number"
              [amount]="item.availableBalance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [showCurrencySymbol]="showCurrencySymbol"
              [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
              [title]="item.name"
              [status]="item.status"
              [highlight]="highlight"
            ></bb-product-item-basic-account-ui>
          }
          @case (balanceDetailsViewType.Ordinary) {
            <bb-product-item-basic-account-ui
              [active]="item$.selected"
              [productNumber]="item.number"
              [amount]="item.availableBalance"
              [secondaryAmount]="item.balance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [showCurrencySymbol]="showCurrencySymbol"
              [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
              [title]="item.name"
              [status]="item.status"
              [highlight]="highlight"
              balanceLabel="Available"
              i18n-balanceLabel="
                Single item option list primary balance label@@bb-account-selector-ui.primary-balance-label.available"
              balanceLabelForSecondaryAmount="Balance"
              i18n-balanceLabelForSecondaryAmount="
                Single item option list secondary balance label@@bb-account-selector-ui.secondary-balance-label.balance"
            ></bb-product-item-basic-account-ui>
          }
          @case (balanceDetailsViewType.Reverse) {
            <bb-product-item-basic-account-ui
              [active]="item$.selected"
              [productNumber]="item.number"
              [amount]="item.balance"
              [secondaryAmount]="item.availableBalance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [showCurrencySymbol]="showCurrencySymbol"
              [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
              [title]="item.name"
              [status]="item.status"
              [highlight]="highlight"
              balanceLabel="Balance"
              i18n-balanceLabel="
                Single item option list primary balance label@@bb-account-selector-ui.primary-balance-label.balance"
              balanceLabelForSecondaryAmount="Available"
              i18n-balanceLabelForSecondaryAmount="
                Single item option list secondary balance
                label@@bb-account-selector-ui.secondary-balance-label.available"
            ></bb-product-item-basic-account-ui>
          }
          @default {
            <bb-product-item-basic-account-ui
              [active]="item$.selected"
              [productNumber]="item.number"
              [amount]="item.balance"
              [currency]="item.currency"
              [productNumberFormat]="productNumberFormat"
              [showCurrencySymbol]="showCurrencySymbol"
              [bankBranchCode]="showBankBranchCode ? item.bankBranchCode : undefined"
              [title]="item.name"
              [status]="item.status"
              [highlight]="highlight"
            ></bb-product-item-basic-account-ui>
          }
        }
      }
    }
    <!-- Default template for ng-option-tmp -->
  </ng-template>

  <!-- Template for group option header(ng-optgroup-tmp) -->
  <ng-template let-item="item" ng-optgroup-tmp>
    @if (customGroupItemsHeader) {
      <ng-container
        *ngTemplateOutlet="
          customGroupItemsHeader?.templateRef;
          context: {
            hostRef: hostRef,
            item: item
          }
        "
      ></ng-container>
    } @else {
      @if (!item.name) {
        <span i18n="All|All group By label @@bb-account-selector-ui.groupBy.all.label">All</span>
      }
      @if (item.name) {
        <span i18n="Favorite|Favorite group By label @@bb-account-selector-ui.groupBy.default.label"> Favorite </span>
      }
      ({{ item.total }})
    }
  </ng-template>

  <ng-template ng-loadingtext-tmp>
    @if (customLoadingTemplate) {
      <ng-container *ngTemplateOutlet="customGroupItemsHeader?.templateRef"></ng-container>
    } @else {
      <bb-loading-indicator-ui
        i18n-text="Loading Accounts|Loading Accounts label @@bb-account-selector-ui.loading.accounts"
        text="Loading Accounts"
      ></bb-loading-indicator-ui>
    }
  </ng-template>
</ng-select>
