@if (isNumeric) {
  <span data-role="bb-amount-value" class="bb-amount" dir="ltr">
    <span [innerHtml]="amountHtmlTemplate" class="amount bb-amount__value" data-role="bb-amount-value__amount"></span>
    @if (abbreviationSuffix) {
      &ngsp;<span
        class="visually-hidden"
        i18n="Amount abbreviation long suffix@@bb-amount-ui.abbr.long"
        #abbrTitle
        >{abbreviationSuffix, select, K {Thousand} M {Million} B {Billion} T {Trillion}}</span
      ><abbr
        [title]="abbrTitle.textContent"
        aria-hidden="true"
        data-role="bb-amount-value__abbreviation"
        i18n="Amount abbreviation short suffix@@bb-amount-ui.abbr.short"
        class="abbreviation bb-amount__abbreviation"
        >{abbreviationSuffix, select, K {K} M {M} B {B} T {T}
      }</abbr>
    }
  </span>
}
