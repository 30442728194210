import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'bb-forms-page-ui',
  templateUrl: './bb-forms-page-ui.component.html',
})
export class FormsPageComponent {
  vForm: UntypedFormGroup | undefined;
    amount = {
    amount: 123.45,
    currency: 'EUR',
  };

  isFieldValid(fieldName: string) {
    const control = this.getControl(fieldName);

    return control && (control.valid || control.untouched);
  }

  private getControl(fieldName: string) {
    return this.vForm?.controls[fieldName];
  }

  hasError(field: string, type: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors[type];
  }

  hasRequiredError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors.required;
  }

  hasMinError(field: string) {
    return this.hasError(field, 'min');
  }

  hasMaxError(field: string) {
    return this.hasError(field, 'max');
  }
}
