<div [class.active]="active" class="bb-product-item bb-product-item-basic-account-ui product-item">
  <div class="bb-stack bb-stack--wrap product-item-content">
    <div class="bb-stack__item bb-product-item__title">
      <div class="bb-stack">
        @if (customProductItemBasicAccountType) {
          <ng-container
            *ngTemplateOutlet="customProductItemBasicAccountType?.templateRef; context: hostRef"
          ></ng-container>
        } @else {
          @if (active && !selected) {
            <div class="bb-stack__item bb-product-item__active">
              <bb-icon-ui name="success" size="md" color="primary" cropped></bb-icon-ui>
            </div>
          }
          @if (type && selected) {
            <div class="bb-stack__item bb-product-item__type">
              <bb-icon-ui color="secondary" size="md" [name]="type" cropped></bb-icon-ui>
            </div>
          }
        }
        @if (customProductItemBasicAccountTitle) {
          <ng-container
            *ngTemplateOutlet="customProductItemBasicAccountTitle?.templateRef; context: hostRef"
          ></ng-container>
        } @else {
          <div class="bb-account-info">
            <div
              data-role="card-title"
              class="bb-account-info__title"
              [ngClass]="{ 'bb-text-support': status === 'inactive' || status === 'closed' }"
            >
              <bb-ellipsis-ui [text]="title"></bb-ellipsis-ui>
              @if (favorite) {
                <bb-icon-ui
                  class="bb-account-info__icon"
                  size="sm"
                  [color]="favoriteIconColor"
                  data-role="favorite-icon"
                  name="star"
                ></bb-icon-ui>
              }
              <span class="bb-account-info__status bb-account-status">
                @switch (status) {
                  @case ('closed') {
                    <bb-icon-ui class="bb-account-status__icon" size="sm" name="cancel" color="danger"></bb-icon-ui>
                    <span
                      class="bb-account-status__state"
                      i18n="Account closed status@@product-item-base-account.status.closed"
                    >
                      Closed
                    </span>
                  }
                  @case ('inactive') {
                    <bb-icon-ui class="bb-account-status__icon" size="sm" name="remove-circle-outline"></bb-icon-ui>
                    <span
                      class="bb-account-status__state"
                      i18n="Account closed status@@product-item-base-account.status.inactive"
                    >
                      Inactive
                    </span>
                  }
                }
              </span>
            </div>
            @if (productNumber) {
              <div data-role="card-sub-title" class="bb-stack bb-account-info__product-number">
                @if (bankBranchCode) {
                  <span
                    class="visually-hidden"
                    i18n="Bank branch code screen reader label@@product-item-base-account.bankBranchCode.label"
                  >
                    ; Bank branch code:
                  </span>
                  <span data-role="branch-code-text">{{ bankBranchCode }}</span>
                  <span aria-hidden="true">&nbsp;/&nbsp;</span>
                }
                <bb-ellipsis-ui class="bb-account-info__product-number-content">
                  <span
                    class="visually-hidden"
                    i18n="Account number screen reader label@@product-item-base-account.accountNumber.label"
                  >
                    ; Account number:
                  </span>
                  {{ productNumber | paymentCardNumber: productNumberFormat }}
                </bb-ellipsis-ui>
              </div>
            }
          </div>
        }
      </div>
    </div>
    <div class="bb-stack__break bb-stack__break--xs"></div>
    <div class="bb-stack__item bb-stack__item--push-right bb-stack__item--spacing-none bb-product-item__details">
      @if (customProductItemBasicAccountDetailsFormatter) {
        <ng-container
          *ngTemplateOutlet="customProductItemBasicAccountDetailsFormatter?.templateRef; context: hostRef"
        ></ng-container>
      } @else {
        <div class="bb-stack">
          @if (balanceLabel) {
            <div class="bb-stack__item bb-stack__item--spacing-sm">
              <span class="bb-text-support bb-subheader bb-subheader--regular" data-role="balance-type-label">
                {{ balanceLabel }}
              </span>
            </div>
          }
          @if (!balanceLabel) {
            <span class="visually-hidden" i18n="Balance screen reader label@@product-item-base-account.balance.label"
              >; Balance:</span
            >
          }
          <bb-amount-ui
            class="bb-highlight bb-product-item__amount bb-stack__item"
            [amount]="amount"
            [currency]="currency"
            [mapCurrency]="showCurrencySymbol"
            [bbHighlight]="highlight"
          ></bb-amount-ui>
        </div>
        @if (secondaryAmount || secondaryAmount === 0) {
          <div class="bb-stack">
            @if (balanceLabelForSecondaryAmount) {
              <div class="bb-stack__item bb-stack__item--push-right bb-stack__item--spacing-sm">
                <span
                  class="bb-text-support bb-subheader bb-subheader--regular"
                  data-role="product-item__secondary-amount-label"
                >
                  {{ balanceLabelForSecondaryAmount }}
                </span>
              </div>
            }
            @if (!balanceLabel) {
              <span
                class="visually-hidden"
                i18n="Secondary balance screen reader label@@product-item-base-account.secondary-balance.label"
                >; Secondary balance:</span
              >
            }
            <bb-amount-ui
              class="bb-product-item__amount bb-stack__item"
              [amount]="secondaryAmount"
              [currency]="currency"
              [mapCurrency]="showCurrencySymbol"
              data-role="product-item__secondary-amount"
            ></bb-amount-ui>
          </div>
        }
      }
    </div>
    @if (productDetailsInfo?.owners) {
      <div class="bb-stack__break--xs bb-stack__break--sm-up"></div>
      <div class="bb-stack__item bb-product-item__owners">
        <span data-role="productDetailsInfo-owners" class="bb-text-default">
          {{ productDetailsInfo?.owners }}
        </span>
      </div>
    }
  </div>
</div>
