<form [formGroup]="vForm">
  <bb-rich-text-editor-ui
    label="Remarks"
    formControlName="remarks"
    placeholder="Please, enter your message"
    [maxLength]="300"
    [required]="true"
  >
  </bb-rich-text-editor-ui>
</form>
