<footer class="footer">
  <div class="footer__content-wrapper">
    <div class="footer__content">
      <bb-logo-ui class="footer__content-logo" type="full"></bb-logo-ui>
      <span class="footer__content-text"><strong>&copy;</strong>{{ currentYear }} All rights reserved</span>
    </div>
    <div class="footer__policy">
      <a href="https://backbase.com/legal/" target="_blank" title="Terms and Legal">Terms and Legal</a>
      <a href="https://backbase.com/privacy-policy/" target="_blank" title="Privacy Policy">Privacy Policy</a>
    </div>
  </div>
</footer>
