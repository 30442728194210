<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
  <p>
    <bb-input-password-ui
      label="Password"
      placeholder="Please choose a new password"
      formControlName="password"
      autocomplete="new-password"
      [aria-describedby]="'password-error'"
      [showVisibilityControl]="true"
    >
    </bb-input-password-ui>
  </p>
  <bb-input-validation-message-ui
    id="password-error"
    [showErrors]="
      !changePasswordForm.pristine && !changePasswordForm.valid
    "
  >
    <p>Password is required.</p>
  </bb-input-validation-message-ui>

  <button bbButton type="submit">Change password</button>
</form>
