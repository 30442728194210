import { Component, TemplateRef, ViewChild } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { NotificationService } from '@backbase/ui-ang/notification';

const LOAD_DELAY = 500;

@Component({
  selector: 'bb-notification-example-ui',
  templateUrl: './notification.example.component.html',
})
export class NotificationExampleComponent {
  constructor(
    private readonly notificationService: NotificationService,
  ) {}

  @ViewChild('messageTemplate', { static: true }) messageTemplate!:
    | TemplateRef<any>
    | string;

  checkMail() {
    setTimeout(() => this.showNewMailNotification(), LOAD_DELAY);
  }

  checkMailTemplate() {
    setTimeout(
      () => this.showNewMailNotificationWithTemplateMesage(),
      LOAD_DELAY,
    );
  }

  showNewMailNotification() {
    this.notificationService.showNotification({
      header: 'You’ve got new mail!',
      message:
        'Backbase Release 1.2.3 is now available! What’s new in this release? ...',
      modifier: 'info',
      dismissible: true,
      ttl: 5000,
      primaryActionText: 'Visit website',
      primaryAction: () => {
        window.location.href = 'https://backbase.com/';
      },
    });
  }

  showNewMailNotificationWithTemplateMesage() {
    this.notificationService.showNotification({
      header: 'You’ve got new mail!',
      message: this.messageTemplate,
      modifier: 'info',
      dismissible: true,
      ttl: 5000,
      primaryActionText: 'Visit website',
      primaryAction: () => {
        window.location.href = 'https://backbase.com/';
      },
    });
  }
}
