<bb-heading-ui
  heading="Transfers with permissions"
  customClassName="bb-heading-widget--de-elevated"
  headingType="h1"
  headingClasses="bb-heading-widget__heading"
  [useFirstLinkAsDefault]="true"
  [navigationItems]="navigationHeaderItems"
  (navigationAction)="navigationAction($event)"
>
</bb-heading-ui>

<p>Navigated to - {{ navigationTo }}</p>

<bb-heading-ui
  heading="Items without permissions"
  customClassName="bb-heading-widget--de-elevated"
  headingType="h1"
  headingClasses="bb-heading-widget__heading"
  [useFirstLinkAsDefault]="true"
  [navigationItems]="navigationHeaderItemsNoPermissions"
  (navigationAction)="navigationAction($event)"
>
</bb-heading-ui>

<p>Navigated to - {{ navigationTo }}</p>

<bb-heading-ui
  heading="Simple items"
  customClassName="bb-heading-widget--de-elevated"
  headingType="h1"
  headingClasses="bb-heading-widget__heading"
  buttonLabel="Button with label"
  [navigationItems]="navigationHeaderItemsNoPermissions"
  (navigationAction)="navigationAction($event)"
>
</bb-heading-ui>

<p>Navigated to - {{ navigationTo }}</p>
