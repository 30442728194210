import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownMenuDirective, DropdownPanelComponent, DropdownToggleDirective } from './dropdown-panel.component';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';

@NgModule({
  imports: [AsyncPipe, NgTemplateOutlet, NgbDropdownModule, DropdownMenuModule],
  declarations: [DropdownPanelComponent, DropdownMenuDirective, DropdownToggleDirective],
  exports: [DropdownPanelComponent, DropdownToggleDirective, DropdownMenuDirective, DropdownMenuModule],
})
export class DropdownPanelModule {}
