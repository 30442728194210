import { ArrangementItem } from './account-number-model';
import { AccountNumberType } from '@backbase/ui-ang/account-number-pipe';

const CardProductKindName = ['credit card', 'debit card'];

const isCardNumber = (account: ArrangementItem): boolean =>
  Boolean(account.productKindName && CardProductKindName.includes(account.productKindName.toLowerCase()));

/**
 * Gets a valid account number type for a given account
 *
 * @param account
 *
 * for CreditCard/DebitCard:
 * - returns `number` if exists
 * - else `IBAN` if exists
 * - otherwise `BBAN`
 * for other accounts:
 * - returns `IBAN` if exists
 * - else `BBAN` if exists
 * - otherwise `number`
 */
export const getAccountNumberType = (account: ArrangementItem): AccountNumberType => {
  const isCardFormat = isCardNumber(account);

  if (isCardFormat && 'number' in account) {
    return 'cardNumber';
  } else if ('IBAN' in account) {
    return 'iban';
  } else if ('BBAN' in account || isCardFormat) {
    return 'bban';
  } else {
    return 'cardNumber';
  }
};

/**
 * Gets a valid account number type for a given account
 *
 * @param account
 * @returns Either account number or iban or bban
 */
export const getAccountNumber = (account: ArrangementItem): string => {
  const accountNumberType = getAccountNumberType(account);

  if (accountNumberType === 'iban' && 'IBAN' in account) {
    return account.IBAN as string;
  } else if (accountNumberType === 'bban' && 'BBAN' in account) {
    return account.BBAN as string;
  } else if ('number' in account) {
    return account.number as string;
  }

  return '';
};
