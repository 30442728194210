import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalShowcaseComponent } from './modal-showcase.component';
import { ModalModule } from '@backbase/ui-ang/modal';
import { IconModule } from '@backbase/ui-ang/icon';
import { ButtonModule } from '@backbase/ui-ang/button';
import { InputDatepickerModule } from '@backbase/ui-ang/input-datepicker';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';
import { AccountSelectorModule } from '@backbase/ui-ang/account-selector';
import { NotificationModule } from '@backbase/ui-ang/notification';

@NgModule({
  imports: [
    AccountSelectorModule,
    CommonModule,
    FormsModule,
    ModalModule,
    IconModule,
    ButtonModule,
    InputDatepickerModule,
    DropdownMenuModule,
    NotificationModule,
  ],
  declarations: [ModalShowcaseComponent],
})
export class ModalShowcaseModule {}
