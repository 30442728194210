import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileAttachmentShowcaseComponent } from './file-attachment-showcase.component';
import { IconModule } from '@backbase/ui-ang/icon';
import { FileAttachmentModule } from '@backbase/ui-ang/file-attachment';
import { ButtonModule } from '@backbase/ui-ang/button';
import { KeyboardClickModule } from '@backbase/ui-ang/keyboard-click-directive';
import { LoadingIndicatorModule } from '@backbase/ui-ang/loading-indicator';

const uiModules = [ButtonModule, IconModule, KeyboardClickModule, LoadingIndicatorModule];

@NgModule({
  imports: [CommonModule, FileAttachmentModule, uiModules],
  declarations: [FileAttachmentShowcaseComponent],
})
export class FileAttachmentShowcaseModule {}
