<div class="paragraph">
<p>Forms are used to present data to users, so they can submit, change or enter data.<br>
Forms are made by grouping ui elements for handling data,  such as input fields, selectors and labels, displayed in a specific structure.</p>
</div>
<div class="sect1">
<h2 id="_anatomy_of_a_form">Anatomy of a form</h2>
<div class="sectionbody">
<div class="paragraph">
<p>All forms are made of the following elements:</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-anatomy.png" alt="forms anatomy">
</div>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><strong>Labels</strong><br>
Use to describe to the user what data is needed in the corresponding input field.</p>
</li>
<li>
<p><strong>Input fields</strong><br>
Enable users to provide information. Information can be entered through a variety of different input fields ranging from text fields, checkboxes, and many other types.</p>
</li>
<li>
<p><strong>*Placeholder text</strong> (optional)*<br>
In most cases, placeholder text appears as a hint to the user of what data is expected into a field.</p>
</li>
<li>
<p><strong>*Help text</strong> (optional)*<br>
Used to provide extra information below the form field. It can also be used as a character counter for limit constraints.</p>
</li>
<li>
<p><strong>Validation</strong><br>
This ensures the data filled in or submitted matches expected parameters. When entered data is incorrect, an error message is displayed to the user.</p>
</li>
<li>
<p><strong>Actions</strong><br>
Available actions the user can take on a form, such as saving, submitting or canceling.</p>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_general_guidelines">General Guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When using forms, be as clear as possible and think carefully about what you’re asking the user of each field and input.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Order the form elements in a way that feels logical to the user given the use case you are covering.</p>
</li>
<li>
<p>Group related information together by using group titles, so that groups are easy to find.</p>
</li>
<li>
<p>Place labels above the fields. This is the best way to support an organic reading flow and avoid unnecessary eye movement.</p>
</li>
<li>
<p>Labels are help text are intended for different purposes. Be sure to give each filed a meaningful label.</p>
</li>
<li>
<p>Labels should be short and descriptive.</p>
</li>
<li>
<p>All fields in a form are assumed required, for fields that are optional, use a secondary label indicating this to the user.</p>
</li>
<li>
<p>If data in an input field is incorrect, show an error or warning state with a meaningful message for the user.</p>
</li>
</ul>
</div>
<div class="sect2">
<h3 id="_error_handling_and_validation">Error Handling and Validation</h3>
<div class="paragraph">
<p>In banking, validating data is the bread and butter in a form, ensuring a smooth experience when errors occur, goes a long way for users.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-error.png" alt="forms error">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>Whenever possible, do validation in the UI, before the form is submitted by the user.</p>
</li>
<li>
<p>To help the user guide to an error in a form always use a visual style for validation.</p>
</li>
<li>
<p>Individual input fields should present their own errors following the styles dictated in the Inputs component.</p>
</li>
<li>
<p>The validation should happen when the user has clicked away from the field.</p>
</li>
<li>
<p>When a user corrects an invalid entry, the error message should disappear only once the data is rendered as valid, when the user clicks away from the field.</p>
</li>
<li>
<p>Make error messages clear and informative, their main purpose is to help the user fill the form with the expected data.</p>
</li>
<li>
<p>Present top level errors relevant to the form as a whole above the form with an alert.</p>
</li>
<li>
<p>Validation is linked to specific formats such as IBAN, <a routerLink="/formatters/card-number/">Card</a> or <a routerLink="/formatters/phone-numbers/">Phone</a> number, review <a routerLink="/formatters/">formatters documentation</a> to learn more.</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_fieldset_and_legend">Fieldset and Legend</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Wrap any group of form controls with <em>&lt;fieldset&gt;</em> and use <em>&lt;legend&gt;</em> to label groups of inputs. The <em>&lt;legend&gt;</em> element formally describes the purpose of the <em>&lt;fieldset&gt;</em> element.</p>
</div>
<div class="paragraph">
<p>Many assistive technologies will use the <em>&lt;legend&gt;</em> element as if it is a part of the label of each widget inside the corresponding <em>&lt;fieldset&gt;</em> element. This is the best practice when composing forms where users are submitting data.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_form_controls">Form controls</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When creating a form, you have use different components to handle data. Choose the control that matches the type of data you are requesting.</p>
</div>
<div class="sect2">
<h3 id="_text_field">Text Field</h3>
<div class="paragraph">
<p>Use when the input anticipated by the user is a single line of text or numeric values.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-text-field.png" alt="forms text field">
</div>
</div>
</div>
<div class="sect2">
<h3 id="_text_area">Text Area</h3>
<div class="paragraph">
<p>Use when you anticipate the user to input more than one sentence. With this component you can include a character counter.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Write helper text in full sentences with punctuation.</p>
</li>
<li>
<p>Don’t use text fields to enter more than one line.</p>
</li>
<li>
<p>Use Text Area forms, with a character counter where applicable instead.</p>
</li>
<li>
<p>Don’t use written inputs to enter or select predefined values. Use dropdown inputs instead.</p>
</li>
<li>
<p>Don’t put crucial information as placeholder text, as placeholder text disappears once the user starts typing.</p>
</li>
<li>
<p>Don’t use written inputs to enter or edit numerical values, use number inputs instead.</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_number_input">Number Input</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Used to enter or edit numbers, it is similar to text inputs, but offers control increase or decrease a value.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-number-input.png" alt="forms number input">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>Always use number inputs for numerical values.</p>
</li>
<li>
<p>Do not use Number Inputs when large value changes are expected. They work best for making small, incremental changes that only require a few clicks.</p>
</li>
<li>
<p>Enable the user to also choose to type a number in the field.</p>
</li>
<li>
<p>Ensure that a default value is shown within the field.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Data from this input can be combined with formatters to format text after the form has been filled. These include amounts, card numbers, dates, times and phone numbers and more information on this can be found on the <a routerLink="/formatters/">formatters page</a>.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_date_picker">Date picker</h2>
<div class="sectionbody">
<div class="paragraph">
<p>A datepicker is a text input specific to capture a date. Users can select a single date or a range dates from a calendar component.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-date-picker.png" alt="Forms date picker">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>When possible, provide smart defaults.</p>
</li>
<li>
<p>Close after a single date is selected unless a range with a start and end date is required.</p>
</li>
<li>
<p>Set the start date on first click or tap and the end date on second click or tap if a range is required.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_selectors">Selectors</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Selection inputs ask users to make a definitive choice, or preference from a group of options. There are different types of selectors, intended for different use cases.</p>
</div>
<div class="sect2">
<h3 id="_radio_buttons">Radio Buttons</h3>
<div class="paragraph">
<p>Use when the options in a list are mutually exclusive and the user must select exactly one choice.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-radio-button.png" alt="Forms radio button">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>Clicking a non-selected radio button will deselect whatever other button was previously selected in the list.</p>
</li>
<li>
<p>Don’t use a single radio button on its own as the only selection option.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_checkboxes">Checkboxes</h3>
<div class="paragraph">
<p>Use when a user can choose any number of options, including several, one, or none.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-checkbox.png" alt="Form checkbox">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>Each checkbox is independent in the list, checking one box doesn’t uncheck the others. The exception is when a checkbox is used to make a bulk selection of multiple items.</p>
</li>
<li>
<p>Be listed according to a logical order, whether it’s alphabetical, numerical or time-based.</p>
</li>
<li>
<p>Link to more information or include a subtitle as required to provide more explanation. Don’t rely on tooltips to explain a checkbox.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h3 id="_switch">Switch</h3>
<div class="paragraph">
<p>Use a switch when a user can toggle between two opposing states, such as on and off. The switch is best used for changing the state of functionalities and preferences.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-switch.png" alt="Form switch">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>The switch has always a default value, make sure to deliver immediate results that don’t require the user to click Save or Submit to apply the new state.</p>
</li>
<li>
<p>Keep labels for toggle switches short and direct, don’t use excess phrases. The description should be on what the control will do when the switch is on, don’t make neutral or ambiguous sentences.</p>
</li>
<li>
<p>The switch relies on both color and slide for visual cues, there’s no need to use the labels on/off</p>
</li>
<li>
<p>Be consistent through the platform conventions, look for examples where other widgets have used the switch toggle and maintain uniformity. Don’t make users wonder whether a question or statement with two radio buttons functions the same way as a toggle switch.</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_select_input">Select Input</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Use to allow users to choose one option from a list of values.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-select-input.png" alt="Forms select input">
</div>
</div>
<div class="paragraph">
<p>Use this component only if there is no better way to present the options, for example:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>When there are too many options to list consecutively.</p>
</li>
<li>
<p>When the user might not know what to input, but they would be able to make a choice if they saw it.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>This component is used in conjunction with a Dropdown List, please review <a routerLink="/components/dropdowns/design">Dropdown Menu documentation</a> for more on this.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_complex_inputs">Complex Inputs</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_input_group">Input group</h3>
<div class="paragraph">
<p>A text field input with an additional control to trigger a dropdown list. Use for scenarios where the user would benefit from having the possibility to both, type in the field, or make a choice from a list.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-input-group.png" alt="Forms input group">
</div>
</div>
<div class="paragraph">
<p>This components follows both the input text and Dropdown Menu guidelines.</p>
</div>
</div>
<div class="sect2">
<h3 id="_amount_input">Amount input</h3>
<div class="paragraph">
<p>An amount input is used when users need to enter a numerical value. These can typically be found in a form when users are creating or modifying a payment.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-amount-input.png" alt="Forms input group">
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_modifiers">Modifiers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Form controls will be displayed differently in relation to the state they are in.</p>
</div>
<div class="paragraph">
<p><strong>Default (empty state)</strong><br>
The field can appear with placeholder text that disappears once the user starts typing.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-default.png" alt="Forms default">
</div>
</div>
<div class="paragraph">
<p><strong>Focus / Selected</strong><br>
The focused state is used to show which part of the page the user is interacting with. As a default, the focused state can only appear on one item at a time.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-focus.png" alt="Forms focus">
</div>
</div>
<div class="paragraph">
<p><strong>Filled</strong><br>
Once filled, checked or selected, the border of the form field changes colour to indicate a completed interaction.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-filled.png" alt="Forms filled">
</div>
</div>
<div class="paragraph">
<p><strong>Error</strong><br>
If a failed validation is performed on a field, the field border changes to red, and an error message is displayed under it, in place of the helper text. Error states will stay in place until the user fixes the problem.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-error.png" alt="Forms error">
</div>
</div>
<div class="paragraph">
<p><strong>Disabled</strong><br>
Disabled states can appear disabled and disabled with prefilled content.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/forms-disabled.png" alt="Forms disabled">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="sect2">
<h3 id="_focus_state">Focus state</h3>
<div class="paragraph">
<p>To comply with WCAG standards, focused states are required to aid users in their web browsing journey. Users include people who use screen readers, people with limited mobility and power users, and anyone else who uses a keyboard as a means to browse the internet.</p>
</div>
</div>
<div class="sect2">
<h3 id="_error_handling">Error handling</h3>
<div class="paragraph">
<p>When a form element displays feedback notifying the user of an error, the error string should be linked to the element by adding the aria-describedby attribute to the &lt;input&gt;. The aria-describedby attribute must reference the id of the error message. This configuration allows screen readers to read the associated error message when the invalid field is focused.</p>
</div>
</div>
</div>
</div>