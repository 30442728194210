import { NgModule } from '@angular/core';
import { DatePipe, NgTemplateOutlet } from '@angular/common';

import { ItemLogComponent, ItemLogRecordDirective } from './item-log.component';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [DatePipe, NgTemplateOutlet, IconModule],
  declarations: [ItemLogComponent, ItemLogRecordDirective],
  exports: [ItemLogComponent, ItemLogRecordDirective],
})
export class ItemLogModule {}
