import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'bb-input-number-example-ui',
  templateUrl: './input-number.example.component.html',
})
export class InputNumberExampleComponent {
  vForm: UntypedFormGroup = this.formBuilder.group({
    count: [
      '',
      Validators.compose([
        Validators.required,
        Validators.min(2),
        Validators.max(42),
      ]),
    ],
  });

  constructor(private formBuilder: UntypedFormBuilder) {}

  isFieldValid(fieldName: string) {
    const control = this.getControl(fieldName);

    return control && (control.valid || control.untouched);
  }

  private getControl(fieldName: string) {
    return this.vForm?.controls[fieldName];
  }

  hasError(field: string, type: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors[type];
  }

  hasRequiredError(field: string) {
    const fieldControl = this.getControl(field);
    if (!fieldControl || !fieldControl.errors) {
      return;
    }

    return fieldControl.errors.required;
  }

  hasMinError(field: string) {
    return this.hasError(field, 'min');
  }

  hasMaxError(field: string) {
    return this.hasError(field, 'max');
  }
}
