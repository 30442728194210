<h3>Default Usage</h3>
<bb-locale-selector-ui btnColor="secondary" icon="caret-down" (selectLocale)="onSelectLocale($event)">
</bb-locale-selector-ui>

@if (selectedLanguage) {
  <p>Selected language {{ selectedLanguage }}</p>
}

<h3>Using User context preset</h3>
<div class="user-context-dropdown-menu-item">
  <bb-locale-selector-ui
    [dropdownPlacement]="dropdownPosition"
    btnColor="unstyled"
    (selectLocale)="onSelectLocale($event)"
    [fullWidth]="true"
    [showModalOnMobileView]="true"
  >
    <ng-template bbLocaleSelectorLabel>
      <div aria-label="Select language" class="user-context-dropdown-menu-item bb-stack bb-select-context__item">
        <bb-icon-ui class="bb-stack__item bb-stack__item--spacing-sm" name="language" size="md"></bb-icon-ui>
        <div
          class="bb-stack__item"
          i18n="Language|Click to language label@@locale-selector.label.dropdownSwitchLanguage"
        >
          Language
          @if (selectedLanguage) {
            <span>({{ selectedLanguage }})</span>
          }
        </div>

        <bb-icon-ui [flipOnRtl]="true" class="bb-stack__item--push-right" name="angle-right" size="md"></bb-icon-ui>
      </div>
    </ng-template>
  </bb-locale-selector-ui>
</div>
