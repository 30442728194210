import { Component } from '@angular/core';

@Component({
  selector: 'bb-account-number-example-ui',
  templateUrl: './account-number.example.component.html',
})
export class AccountNumberExampleComponent {
  item = {
    IBAN: 'GB29NWBK60161331926819',
    BBAN: 'BBAN00001',
    number: '1234',
    productKindName: 'Current Account',
  };
}
