import { Component, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SortDirection, SortEvent } from '@backbase/ui-ang/table';
import { COUNTRIES } from './countries';
import { TableDirective } from '@backbase/ui-ang/table';
import { BackbaseDocs } from '@backbase/ui-ang/util';

interface SortOption {
  label: string;
  value: string;
  direction: SortDirection;
}

@Component({
  selector: 'bb-table-ui-showcase',
  templateUrl: './table-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class TableShowcaseComponent {
  countries$?: Observable<any[]>;
  selected?: Set<any>;
  currentPage = 0;
  pageSize = 10;
  rotate: { [key: string]: SortDirection } = { asc: 'asc', desc: 'desc', '': null };
  iconMap: Record<string, string> = { asc: 'caret-up', desc: 'caret-down', '': '' };
  sortDirection: string | null = null;
  activeSortingOption: SortOption | null = null;
  sortingOptions: SortOption[] = [
    { label: 'Capital', value: 'capital', direction: null },
    { label: 'Population', value: 'population', direction: null },
  ];
  sortDisabled = false;

  @ViewChild('tableRef', { static: true, read: TableDirective }) tableRef!: TableDirective;

  constructor() {
    this.countries$ = of(this.getItems(0, this.pageSize));
  }

  onSort({ column, direction }: SortEvent) {
    this.activeSortingOption = null;
    const selectedOption = this.sortingOptions.find((option) => option.value === column);
    if (selectedOption && direction !== null) {
      this.activeSortingOption = selectedOption;
      this.activeSortingOption.direction = direction;
    }
    this.countries$ = of(
      this.sort(
        this.getItems(this.currentPage * this.pageSize, this.currentPage * this.pageSize + this.pageSize),
        column,
        direction,
      ),
    );
  }

  onRowSelect(data: Set<any>) {
    this.selected = data;
  }

  onRowClick(data: any) {
    this.tableRef.toggleItem(data, !this.tableRef.isSelected(data));
  }

  search(value: string) {
    this.countries$ =
      value === ''
        ? of(this.getItems(this.currentPage, this.pageSize))
        : of(COUNTRIES.filter((country) => country.name.toLowerCase().includes(value)));
  }

  sort(countries: any[], column: string, direction: SortDirection): any[] {
    this.sortDirection = this.rotate[direction ?? ''];
    if (direction === null) {
      return countries;
    } else {
      return [...countries].sort((a: any, b: any) => {
        const res = this.compare(a[column], b[column]);

        return direction === 'asc' ? res : -res;
      });
    }
  }

  compare(v1: number | string, v2: number | string) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  getItems(start: number, end: number): any[] {
    return COUNTRIES.slice(start, end);
  }

  toggleSortDisabled() {
    this.sortDisabled = !this.sortDisabled;
  }

  get paginator() {
    return {
      page: this.currentPage,
      boundaryLinks: false,
      directionLinks: true,
      totalItems: COUNTRIES.length,
      itemsPerPage: this.pageSize,
      maxNavPages: 3,
      onPageChange: (page: number) => {
        this.countries$ = of(this.getItems(page * this.pageSize, page * this.pageSize + this.pageSize));
      },
    };
  }
}
