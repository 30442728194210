export interface IProgressTrackerConfig {
  payload: IEventPayload;
  id: string;
  eventStatus: EventStatus;
}

export interface IEventPayload {
  eta: Eta;
  progressStartTime: number;
  current: number;
  size: number;
  name: string;
  actionType: EventType;
  itemId: string;
}

export enum EventStatus {
  IN_PROGRESS,
  COMPLETED,
  CANCELLED,
  TIME_OUT,
}

export enum EventType {
  FILE_UPLOAD,
}

export interface Eta {
  hours: number;
  mins: number;
  secs: number;
}
