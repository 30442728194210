<div class="row">
  <div class="col-6">
    <form #checkboxGroupForm="ngForm">
      <ul class="list-unstyled">
        <li>
          <bb-checkbox-group-ui ngModelGroup="list" label="Parent" class="bb-block bb-block--sm">
          </bb-checkbox-group-ui>
          <ul ngModelGroup="list" class="list-unstyled bb-checkbox-group">
            <li>
              <bb-input-checkbox-ui name="i1" ngModel disabled label="Child" class="bb-block bb-block--sm">
              </bb-input-checkbox-ui>
            </li>
            <li>
              <bb-input-checkbox-ui name="i2" ngModel label="Child" class="bb-block bb-block--sm">
              </bb-input-checkbox-ui>
            </li>
            <li>
              <bb-checkbox-group-ui ngModelGroup="i3" label="Parent" class="bb-block bb-block--sm">
              </bb-checkbox-group-ui>
              <ul ngModelGroup="i3" class="list-unstyled bb-checkbox-group">
                <li>
                  <bb-input-checkbox-ui ngModel name="q1" label="Child" class="bb-block bb-block--sm">
                  </bb-input-checkbox-ui>
                </li>
                <li>
                  <bb-input-checkbox-ui ngModel name="q2" label="Child" class="bb-block bb-block--sm">
                  </bb-input-checkbox-ui>
                </li>
                <li>
                  <bb-checkbox-group-ui ngModelGroup="q3" label="Parent" class="bb-block bb-block--sm">
                  </bb-checkbox-group-ui>
                  <ul ngModelGroup="q3" class="list-unstyled bb-checkbox-group">
                    <li>
                      <bb-input-checkbox-ui ngModel name="w1" label="Child" class="bb-block bb-block--sm">
                      </bb-input-checkbox-ui>
                    </li>
                    <li>
                      <bb-input-checkbox-ui ngModel name="w2" label="Child"> </bb-input-checkbox-ui>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </form>
  </div>
</div>
