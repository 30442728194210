<button
  type="button"
  (click)="checkMail()"
  bbButton
  [buttonSize]="'md'"
  [color]="'primary'"
>
  Check for new mails
</button>

<button
  type="button"
  (click)="checkMailTemplate()"
  bbButton
  [buttonSize]="'md'"
  [color]="'primary'"
>
  Check for new mails (message template)
</button>

<ng-template #messageTemplate>
  <p>Backbase Release <strong>3.8.0</strong> is now available!</p>
</ng-template>
