import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-badge-counter-ui-showcase',
  templateUrl: 'badge-counter-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class BadgeCounterShowcaseComponent {
  public badgeCounterShowCaseSetup = [
    {
      type: 'success',
      count: 5,
    },
    {
      type: 'danger',
      count: 18,
    },
    {
      type: 'danger',
      count: 1900,
    },
    {
      type: 'danger',
      count: 100,
      maxCount: 99,
    },
  ];
}
