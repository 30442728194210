<bb-mode-header-ui
  title="Sarah Conners"
  secondaryTitle="Retail banking user"
  closeButtonAriaLabel="Close mode header to exit the user details section"
  [showCloseButton]="true"
  [reverseTitleOrder]="true"
  (close)="onCloseModeHeaderClick()"
>
  <bb-icon-ui
    mode-header-icon
    name="search"
    class="bb-stack__item--fill"
    size="sm"
    color="primary"
  ></bb-icon-ui>
  <bb-badge-ui mode-header-status text="Sample Text" color="success">
  </bb-badge-ui>
  <bb-dropdown-menu-ui
    mode-header-utility-area
    container="body"
    icon="caret-down"
    btnColor="primary"
    iconSize="md"
  >
    <ng-template bbDropdownLabel> <span>Actions</span></ng-template>
    <ng-template bbDropdownMenuItem>
      <button
        class="dropdown-item"
        type="button"
        (click)="onActionOneClick()"
      >
        Action One
      </button>
      <button
        class="dropdown-item"
        type="button"
        (click)="onActionTwoClick()"
      >
        Action Two
      </button>
    </ng-template>
  </bb-dropdown-menu-ui>
</bb-mode-header-ui>
