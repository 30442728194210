import { Component, ChangeDetectionStrategy, Input, Directive, TemplateRef, ContentChild } from '@angular/core';

@Directive({ selector: 'ng-template[bbItemLogRecord]' })
export class ItemLogRecordDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * An interface representing an individual item log record.
 */
export interface ItemLogRecord {
  /**
   * The date and time the log record was created.
   */
  createdAt: string;
  /**
   * The full name of the user who created the log record.
   */
  userFullName: string;
  /**
   * The status of the item at the time the log record was created.
   */
  status: string;
  /**
   * An optional comment added by the user who created the log record.
   */
  comment?: string;
  /**
   * The ID of the user who created the log record, if it was created externally.
   */
  externalUserId?: string;
}
/**
 * @name ItemLogComponent
 *
 * @description
 * Component that shows the log of the records.
 *
 */
@Component({
  selector: 'bb-item-log-ui',
  templateUrl: './item-log.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemLogComponent<T> {
  @ContentChild(ItemLogRecordDirective, { static: true })
  customItemLog?: ItemLogRecordDirective;
  /**
   * Flag that denotes if item log status is pending. Defaults to false
   */
  @Input() isPending = false;
  /**
   * List of item log records.
   */
  @Input() records?: Array<ItemLogRecord | T>;
}
