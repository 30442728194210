import { Component } from '@angular/core';

@Component({
  selector: 'bb-button-page-ui',
  templateUrl: './bb-button-page-ui.component.html',
})
export class ButtonPageComponent {
  console = console;
  onButtonPress(type: string) {
    console.log(`${type} button was pressed`);
  }
}
