import { Pipe, PipeTransform } from '@angular/core';
import { EmptyStatePresetType, PRESETS } from './type';

@Pipe({
  name: 'presetIllustration',
})
export class PresetIllustrationPipe implements PipeTransform {
  transform(value: EmptyStatePresetType) {
    const result = PRESETS[value]?.image;

    return result ?? 'ic_asset_coffee';
  }
}
