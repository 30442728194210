import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationShowcaseComponent } from './pagination-showcase.component';
import { PaginationModule } from '@backbase/ui-ang/pagination';

@NgModule({
  imports: [CommonModule, PaginationModule],
  declarations: [PaginationShowcaseComponent],
})
export class PaginationShowcaseModule {}
