import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownMenuShowcaseComponent } from './dropdown-menu-showcase.component';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';
import { IconModule } from '@backbase/ui-ang/icon';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [CommonModule, DropdownMenuModule, IconModule, ButtonModule],
  declarations: [DropdownMenuShowcaseComponent],
})
export class DropdownMenuShowcaseModule {}
