import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { CheckContentTruncationBehaviour } from '@backbase/ui-ang/ellipsis';

@Component({
  selector: 'bb-ellipsis-ui-showcase',
  templateUrl: 'ellipsis-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class EllipsisShowcaseComponent implements CheckContentTruncationBehaviour {
  readonly hostRef: EllipsisShowcaseComponent = this;
  isMessageTruncated = false;
  textToEllipse = 'Lorem ipsum dolor sit amet.';

  setIsContentTruncated(isMessageTruncated: boolean) {
    this.isMessageTruncated = isMessageTruncated;
  }
}
