import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-product-item-current-account-ui-showcase',
  templateUrl: './product-item-current-account-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class ProductItemCurrentAccountShowcaseComponent {
  currentAccountProductNumberFormat = {
    length: 16,
    maskRange: [0, 2],
    segments: 4,
  };
}
