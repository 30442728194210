<div class="bb-block bb-block--xl">
  <h2>Default</h2>
  <bb-page-header-ui
    title="Page title"
    subtitle="Page subtitle"
    [primaryAction]="{ text: 'Primary' }"
    [secondaryAction]="{ text: 'Secondary' }"
    navigationLinkText="Back to Page"
  ></bb-page-header-ui>
</div>

<hr />

<div class="bb-block bb-block--xl">
  <h2>Default with content, without link and secondary action</h2>
  <bb-page-header-ui title="Page title" subtitle="Page subtitle" [primaryAction]="{ text: 'Primary' }">
    <div>Content</div>
    <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </div>
    <div>Content</div>
  </bb-page-header-ui>
</div>

<hr />

<div class="bb-block bb-block--xl">
  <h2>Default with content, with custom actions</h2>
  <bb-page-header-ui title="Page title" subtitle="Page subtitle">
    <ng-template bbPageHeaderCustomAction>
      <div class="bb-button-bar">
        <bb-dropdown-menu-ui container="body" icon="caret-down" class="bb-button-bar__button">
          <ng-template bbDropdownLabel>
            <span>Dropdown with buttons</span>
          </ng-template>
          <ng-template bbDropdownMenuItem>
            <ul>
              <li>
                <button class="dropdown-item">Dropdown item 1</button>
              </li>
              <li>
                <button class="dropdown-item">Dropdown item 2</button>
              </li>
            </ul>
          </ng-template>
        </bb-dropdown-menu-ui>

        <button bbButton color="secondary" buttonSize="md" class="bb-button-bar__button">Secondary</button>
      </div>
    </ng-template>
    <div>Content</div>
    <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </div>
    <div>Content</div>
  </bb-page-header-ui>
</div>

<hr />

<div class="bb-block bb-block--xl">
  <h2>With background</h2>
  <bb-page-header-ui
    title="Page title"
    subtitle="Page subtitle"
    [primaryAction]="{ text: 'Primary' }"
    [secondaryAction]="{ text: 'Secondary' }"
    navigationLinkText="Back to Page"
    [showBackground]="true"
  >
  </bb-page-header-ui>
</div>

<hr />

<div class="bb-block bb-block--xl">
  <h2>With background and content, modified icons</h2>
  <bb-page-header-ui
    title="Page title"
    subtitle="A very long page subtitle that is meant to test the limits and overflow of the container"
    [primaryAction]="{ text: 'Primary', icon: 'access-alarms' }"
    [secondaryAction]="{ text: 'Secondary', icon: 'calendar' }"
    navigationLinkText="Back to Page"
    [showBackground]="true"
  >
    <div>Content</div>
    <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </div>
    <div>Content</div>
  </bb-page-header-ui>
</div>
