import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBoxShowcaseComponent } from './search-box-showcase.component';
import { SearchBoxModule } from '@backbase/ui-ang/search-box';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, SearchBoxModule, ReactiveFormsModule],
  declarations: [SearchBoxShowcaseComponent],
})
export class SearchBoxShowcaseModule {}
