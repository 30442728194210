import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownPanelShowcaseComponent } from './dropdown-panel-showcase.component';
import { DropdownPanelModule } from '@backbase/ui-ang/dropdown-panel';
import { IconModule } from '@backbase/ui-ang/icon';

@NgModule({
  imports: [CommonModule, DropdownPanelModule, IconModule],
  declarations: [DropdownPanelShowcaseComponent],
})
export class DropdownPanelShowcaseModule {}
