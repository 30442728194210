import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [NgbTooltipModule],
  declarations: [TooltipDirective],
  exports: [TooltipDirective],
})
export class TooltipModule {}
