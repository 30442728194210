<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="bb-infinite-scroll-container-showcase card-body">
        <bb-infinite-scroll-ui
          class="bb-infinite-scroll"
          (scrollEnd)="loadMore()"
          [disableScrollEnd]="complete || loading"
          [offset]="180"
          [isLoading]="loading"
        >
          <div
            class="bb-list bb-subtitle bb-list--density-sm"
            [attr.aria-busy]="loading"
            [class.bb-list--infinite]="!complete"
          >
            @for (item of items; track item; let i = $index) {
              <div class="bb-list__item">
                <div class="bb-stack">
                  <div class="bb-stack__item">{{ i }}. {{ item }}</div>
                </div>
              </div>
            }
          </div>
          @if (loading) {
            <div class="bb-block bb-block--md">
              <bb-loading-indicator-ui
                loaderSize="md"
              ></bb-loading-indicator-ui>
            </div>
          }
          @if (complete) {
            <div class="bb-text-align-center p-3">All items loaded</div>
          }
        </bb-infinite-scroll-ui>
      </div>
    </div>
  </div>
</div>
