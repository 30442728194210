<div class="row">
  <div class="col-md-6">
    <form [formGroup]="vForm">
      <div class="bb-block bb-block--md">
        <div class="bb-form-field bb-form-field--md">
          <bb-amount-input-ui
            formControlName="amount"
            label="Amount"
            [id]="'bb-amount-input'"
            [currency]="'USD'"
            wrapperClasses="bb-amount-input__field--large"
            placeholder="0.00"
            data-role="amount-input-usd"
          >
          </bb-amount-input-ui>
        </div>
      </div>
      <div class="bb-block bb-block--md">
        <div class="bb-form-field bb-form-field--md">
          <bb-amount-input-ui
            label="Amount with auto decimals"
            formControlName="amount2"
            [id]="'bb-amount-input2'"
            [currency]="'EUR'"
            wrapperClasses="bb-amount-input__field--large"
            [autoDecimal]="true"
            placeholder="0.00"
            data-role="amount-input-euro"
          >
          </bb-amount-input-ui>
        </div>
      </div>
      <div class="bb-block bb-block--md">
        <div class="bb-form-field bb-form-field--md">
          <bb-amount-input-ui
            label="Amount with regular size"
            formControlName="amount3"
            [id]="'bb-amount-input3'"
            [currency]="'EUR'"
            [autoDecimal]="true"
            placeholder="0.00"
            data-role="amount-input-regular"
          >
          </bb-amount-input-ui>
        </div>
      </div>
      <div class="bb-block bb-block--md">
        <div class="bb-form-field bb-form-field--md">
          <bb-amount-input-ui
            label="Amount without mapping currency"
            formControlName="amount3"
            [id]="'bb-amount-input4'"
            [mapCurrency]="false"
            [currency]="'EUR'"
            [autoDecimal]="true"
            placeholder="0.00"
            data-role="amount-input-no-currency-mapping"
          >
          </bb-amount-input-ui>
        </div>
      </div>

      <div class="bb-block bb-block--md">
        <div class="bb-form-field bb-form-field--md">
          <bb-amount-input-ui
            label="Amount with ARIA described-by"
            formControlName="amount3"
            [id]="'bb-amount-input5'"
            [mapCurrency]="false"
            [currency]="'EUR'"
            [autoDecimal]="true"
            aria-describedby="describe-me-please"
            placeholder="0.00"
            data-role="amount-input-aria"
          >
          </bb-amount-input-ui>
          <span class="visually-hidden" id="describe-me-please">Describing the field</span>
        </div>
      </div>

      <div class="bb-block bb-block--md">
        <div class="bb-form-field bb-form-field--md">
          <bb-amount-input-ui
            label="Amount with ARIA described-by and without currency"
            formControlName="amount3"
            [id]="'bb-amount-input6'"
            [mapCurrency]="false"
            [autoDecimal]="true"
            aria-describedby="describe-me-too"
            placeholder="0.00"
            data-role="amount-input-aria-without-currency"
          >
          </bb-amount-input-ui>
          <span class="visually-hidden" id="describe-me-too">I am the field description</span>
        </div>
      </div>

      <div class="bb-block bb-block--md">
        <div class="bb-form-field bb-form-field--md">
          <bb-amount-input-ui
            label="Amount input with text hint"
            formControlName="amount3"
            [id]="'bb-amount-input7'"
            [currency]="'EUR'"
            [autoDecimal]="true"
            [textHint]="'Text hint'"
            placeholder="0.00"
            data-role="amount-input-aria-with-text-hint"
          >
          </bb-amount-input-ui>
        </div>
      </div>

      <div class="bb-block bb-block--md">
        <div class="bb-form-field bb-form-field--md">
          <bb-amount-input-ui
            label="Amount input with swap places for currency and value"
            formControlName="amount3"
            [id]="'bb-amount-input8'"
            [currency]="'EUR'"
            [autoDecimal]="true"
            [swapCurrencyAndValue]="true"
            placeholder="0.00"
            data-role="amount-input-aria-with-swap-currency-and-value"
          >
          </bb-amount-input-ui>
        </div>
      </div>
    </form>
  </div>
</div>
