<form [formGroup]="form">
  <h4 id="selectListLabel">Numbers from one to three</h4>
  <bb-select-list-ui
    id="selectListInput"
    formControlName="number"
    placeholder="Start typing name of numbers. e.g. one"
    [items]="items"
    [minTermLength]="3"
    aria-labelledby="selectListLabel"
  >
  </bb-select-list-ui>
</form>
