import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { NotificationService } from '@backbase/ui-ang/notification';

@Component({
  selector: 'bb-tab-example-ui',
  templateUrl: './tab.example.component.html',
})
export class TabExampleComponent {
  constructor(
    private readonly notificationService: NotificationService,
  ) {}

  onSelect(index: number) {
    this.notificationService.showNotification({
      header: 'Tab Navigation',
      message: `Navigate to Tab #${index + 1}'`,
      modifier: 'info',
    });
  }

  onClick($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}
