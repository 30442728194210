<ngb-progressbar
  class="bb-progress-bar"
  [type]="type"
  [max]="max"
  [value]="value"
  [showValue]="showValue"
  [striped]="striped"
  [animated]="animated"
  [height]="height"
  [ariaLabel]="ariaLabel"
>
  <ng-content></ng-content>
</ngb-progressbar>
