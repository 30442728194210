<div class="example-ui">
  <div class="example-ui__component-col">
    <button
      bbButton
      [color]="buttonService.btnValues.dropdown.color"
      [buttonSize]="buttonService.btnValues.radio.buttonSize"
      [disabled]="buttonService.btnValues.checkbox.disabled"
      [block]="buttonService.btnValues.checkbox.block"
      class="m-2"
    >
      {{ buttonService.btnValues.dropdown.color | titlecase }}
    </button>
  </div>
  <div class="example-ui__configuration-col">
    <bb-configuration-input-ui
      [checkboxList]="buttonService.btnCheckboxList"
      [radioList]="buttonService.btnRadioList"
      [dropdownList]="buttonService.btnDropdownList"
    ></bb-configuration-input-ui>
  </div>
</div>
