<div class="row">
  <div class="col-md-6">
    <form [formGroup]="vForm">
      <div class="bb-block bb-block--md">
        <label class="form-label" id="inputEmailLabel" for="inputEmail">
          E-mail field
          <span class="bb-text-support bb-text-default bb-d-inline-block bb-text-align-left"
            >(max {{ inputEmail?.maxLength }} characters)</span
          >
        </label>
        <bb-input-email-ui
          #inputEmail
          [id]="'inputEmail'"
          formControlName="inputEmail"
          [readonly]="false"
          [required]="true"
          placeholder="Please enter an email address..."
          [maxLength]="70"
          [aria-labelledby]="'inputEmailLabel'"
          [aria-describedby]="'inputEmailErrors'"
          [aria-invalid]="!isFieldValid('inputEmail')"
        >
        </bb-input-email-ui>

        <bb-input-validation-message-ui [showErrors]="!isFieldValid('inputEmail')" id="inputEmailErrors">
          @if (hasRequiredError('inputEmail')) {
            <p data-role="input-email-required-error">Field is required.</p>
          }
          @if (hasEmailError('inputEmail')) {
            <p data-role="input-email-invalid-format-error">Email format is incorrect.</p>
          }
        </bb-input-validation-message-ui>
      </div>

      <div class="bb-block bb-block--md">
        <label class="form-label" id="inputEmailWithPatternLabel" for="inputEmailWithPattern">
          E-mail field with custom pattern validation
          <span class="bb-text-support bb-text-default bb-d-inline-block bb-text-align-left">
            Pattern: {{ pattern }} AND max {{ inputEmail?.maxLength }} characters
          </span>
          <span class="bb-text-support bb-text-default bb-d-inline-block bb-text-align-left">
            <i
              >(Note: pattern is supplied via regular component input for demo purposes, in order to show both
              mechanisms side by side. In practice this would be configured globally via app module provider in the
              retail/business/employee web apps)</i
            >
          </span>
        </label>
        <bb-input-email-ui
          #inputEmailWithPattern
          [id]="'inputEmailWithPattern'"
          formControlName="inputEmailWithPattern"
          [readonly]="false"
          [required]="true"
          placeholder="Please enter an email address..."
          [pattern]="pattern"
          [maxLength]="70"
          [aria-labelledby]="'inputEmailWithPatternLabel'"
          [aria-describedby]="'inputEmailWithPatternErrors'"
          [aria-invalid]="!isFieldValid('inputEmailWithPattern')"
        >
        </bb-input-email-ui>
        <bb-input-validation-message-ui
          [showErrors]="!isFieldValid('inputEmailWithPattern')"
          id="inputEmailWithPatternErrors"
        >
          @if (hasRequiredError('inputEmailWithPattern')) {
            <p data-role="input-email-with-pattern-required-error">Field is required.</p>
          }
          @if (hasPatternError('inputEmailWithPattern')) {
            <p data-role="input-email-with-pattern-invalid-format-error">Email does not match required pattern.</p>
          }
        </bb-input-validation-message-ui>
      </div>
    </form>
  </div>
</div>
