import { Component } from '@angular/core';

@Component({
  selector: 'bb-highlight-example-ui',
  templateUrl: './highlight.example.component.html',
})
export class HighlightExampleComponent {
  balance = '3299.00';
  creditLimit = '-500';
}
