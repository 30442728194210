import { Component, Input } from '@angular/core';
import { BrowserService, NAVIGATOR_TOKEN, navigatorFactory } from '@backbase/ui-ang/services';

/**
 * @name EllipsisComponent
 *
 * @description
 * Component that wraps text and shows ellipsis in case text does not fit.
 */
/** @dynamic */
@Component({
  selector: 'bb-ellipsis-ui',
  templateUrl: './ellipsis.component.html',
  providers: [BrowserService, { provide: NAVIGATOR_TOKEN, useFactory: navigatorFactory }],
})
export class EllipsisComponent {
  /**
   * The text which should be truncated.
   */
  @Input() text: string | undefined;
  isOpen = false;

  /**
   * Specifies the events that should trigger the tooltip (space separated strings). Defaults to 'click'.
   */
  @Input() tooltipTriggers = 'click';

  /**
   * Specifies after what text line apply truncating
   */
  @Input('lineClamp')
  set lineClamp(value: number) {
    this._lineClamp = Math.max(0, Math.floor(value));
  }
  get lineClamp(): number {
    return this._lineClamp;
  }
  private _lineClamp = 0;

  constructor(private readonly browserService: BrowserService) {}

  get isIE(): boolean {
    return this.browserService.browserIsMSIE();
  }

  onMouseEnter(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.offsetWidth < target.scrollWidth || target.offsetHeight < target.scrollHeight) {
      this.isOpen = true;
    }
  }

  onMouseLeave() {
    this.isOpen = false;
  }
}
