<div class="paragraph">
<p>Loading Indicators are used to help users understand that they have triggered an action, process or data, such as submitting a form and or loading of a page/content.</p>
</div>
<div class="paragraph">
<p>There are different loader sizes, adjusted for visibility:</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Small, 24px</strong><br>
This Loader is used in buttons.</p>
</li>
<li>
<p><strong>Medium, 48px</strong><br>
Used in modals and mobile screens.</p>
</li>
<li>
<p><strong>Large, 72px</strong><br>
For widgets and pages, placed in the center of the container.</p>
</li>
</ul>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/loading-indicator.png" alt="Large" width="medium and small loader">
</div>
</div>
<div class="sect1">
<h2 id="_placement">Placement</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Placement of the loader outside of a button, should indicate where you want the users to be looking once the action or process is complete.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_page_loading">Page loading</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When loading a page, content should be hidden with a large loader placed within the center of the container. This applies to any new page and, more content loading like pagination.</p>
</div>
<div class="imageblock img-fluid">
<div class="content">
<img src="assets/images/loading-page.png" alt="Loading page">
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_dos_donts">Do&#8217;s &amp; Don&#8217;ts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When working with loaders, follow this recommendations to keep a consistent experience.</p>
</div>
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use only one loader on a page a time.</p>
</li>
<li>
<p>Disable buttons when they are in loading state.</p>
</li>
<li>
<p>Trigger a loader after a user has performed a previous action or process.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don&#8217;t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use a loader when there is no information to show, for this case use an empty state.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_interaction">Interaction</h2>
<div class="sectionbody">
<div class="paragraph">
<p>A loader should only appear after the user has triggered an action or process and not in any other instance.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_responsiveness">Responsiveness</h2>
<div class="sectionbody">
<div class="paragraph">
<p>On small devices, use the <strong>Medium</strong> loader.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 id="_accessibility">Accessibility</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Spinner accessibility label should accurately explain the state of the requested action.  For example, “Loading” or “Processing.” Use as few words to describe the state as possible.</p>
</div>
<div class="paragraph">
<p>For more on accessibility, please review our <a href="/accessibility/">accessibility page</a>.</p>
</div>
</div>
</div>