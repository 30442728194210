import { Injectable } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class TimepickerStringAdapter extends NgbTimeAdapter<string | null> {
  fromModel(value: string): NgbTimeStruct {
    if (value) {
      const [hour, minute, second] = value.split(':').map((str) => parseInt(str, 10));

      return { hour, minute, second };
    }

    return null as unknown as NgbTimeStruct;
  }

  toModel(time: NgbTimeStruct): string | null {
    if (!time) {
      // eslint-disable-next-line
      return null;
    }

    return `${String(time.hour).padStart(2, '0')}:${String(time.minute).padStart(2, '0')}:${String(
      time.second,
    ).padStart(2, '0')}`;
  }
}
