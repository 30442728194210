import { Pipe, PipeTransform } from '@angular/core';
import { AccountNumberType, AccountsDisplayingFormat } from './constants';
import { AccountsDisplayingFormatService } from './accounts-displaying-format.service';
import { segment } from '@backbase/ui-ang/util';

/**
 * @name bbAccountNumber
 *
 * @description
 * Pipe for formatting account numbers.
 * It applies different format configurations per number type.
 */
@Pipe({
  name: 'bbAccountNumber',
})
export class AccountNumberPipe implements PipeTransform {
  /**
   * @name AccountNumberPipe#constructor
   * @param accountsDisplayingFormatService - AccountsDisplayingFormatService
   *
   * @internal
   */
  constructor(private readonly accountsDisplayingFormatService: AccountsDisplayingFormatService) {}

  /**
   * @name AccountNumberPipe#transform
   * @param value - the string to transform
   * @param configuration - type of the value (iban, bban or number) or a custom numbers format
   * @param cardProviderName - card provider name
   *
   * @description
   * Formats account numbers
   *
   * to use with a global accountsFormat configuration
   * - '12345678' | bbAccountNumber:'iban'
   * to apply a custom configuration
   * - '12345678' | bbAccountNumber:{ segments: [2, 4, 2] }
   * to apply a 'visa' card number configuration
   * - '12345678' | bbAccountNumber:'cardNumber':'visa'
   */
  transform(
    value: string | number,
    configuration: AccountNumberType | AccountsDisplayingFormat,
    cardProviderName?: string,
  ): string {
    const accountNumber = value.toString();
    const displayingFormat =
      typeof configuration === 'object'
        ? (configuration as AccountsDisplayingFormat)
        : this.accountsDisplayingFormatService.getDefaultFormat(configuration, cardProviderName);

    return `\u200e${segment(accountNumber, displayingFormat.segments)}`;
  }
}
