<div class="bb-block bb-block--xl">
  <bb-fieldset-ui heading="Group of two inputs">
    <form [formGroup]="group">
      <div class="row">
        <div class="mb-3 col-4">
          <bb-input-password-ui
            [label]="'Password Input'"
            formControlName="password"
            [showVisibilityControl]="true"
            [maxLength]="5"
            [required]="true"
          >
          </bb-input-password-ui>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-4">
          <div class="bb-block--lg">
            <label for="currency-input-with-decimals-label" class="form-label">Currency input</label>
            <bb-currency-input-ui
              [currencyLabel]="'Currency'"
              formControlName="withDecimalControl"
              [currencies]="currencies"
              [id]="'currency-input-with-decimals-label'"
              [currencyListAriaLabel]="'Currency'"
            >
            </bb-currency-input-ui>
          </div>
        </div>
      </div>
    </form>
  </bb-fieldset-ui>
</div>

<div class="bb-block bb-block--xl">
  <h4>Default</h4>
  <bb-fieldset-ui heading="Fieldset heading">
    <div class="mb-3">
      <label class="bb-label" for="example-default">
        Label <span class="bb-label__annotation">(optional text)</span>
      </label>
      <bb-input-text-ui [id]="'example-default'" placeholder="Input"></bb-input-text-ui>
    </div>
  </bb-fieldset-ui>
</div>

<div class="bb-block bb-block--xl">
  <h4>Without a heading</h4>
  <bb-fieldset-ui>
    <div class="mb-3">
      <label class="bb-label" for="example-without-heading">
        Label <span class="bb-label__annotation">(optional text)</span>
      </label>
      <bb-input-text-ui [id]="'example-without-heading'" placeholder="Input"></bb-input-text-ui>
    </div>
  </bb-fieldset-ui>
</div>

<div class="bb-block bb-block--xl">
  <h4>Disabled</h4>
  <bb-fieldset-ui [disabled]="true" heading="Fieldset heading">
    <div class="mb-3">
      <label class="bb-label" for="example-disabled">
        Label <span class="bb-label__annotation">(optional text)</span>
      </label>
      <bb-input-text-ui [id]="'example-disabled'" placeholder="Input"></bb-input-text-ui>
    </div>
  </bb-fieldset-ui>
</div>

<div class="bb-block bb-block--xl">
  <h4>Input width variations</h4>
  <bb-fieldset-ui heading="Fieldset heading">
    <div class="row">
      <div class="mb-3 col-6">
        <bb-input-text-ui label="50%" [placeholder]="placeholderLeftText"></bb-input-text-ui>
      </div>
      <div class="mb-3 col-6">
        <bb-input-text-ui label="50%" [placeholder]="placeholderRightText"></bb-input-text-ui>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 col-4">
        <bb-input-text-ui label="33%" [placeholder]="placeholderLeftText"></bb-input-text-ui>
      </div>
      <div class="mb-3 col-4">
        <bb-input-text-ui label="33%" placeholder="Middle"></bb-input-text-ui>
      </div>
      <div class="mb-3 col-4">
        <bb-input-text-ui label="33%" [placeholder]="placeholderRightText"></bb-input-text-ui>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 col-4">
        <bb-input-text-ui label="33%" [placeholder]="placeholderLeftText"></bb-input-text-ui>
      </div>
      <div class="mb-3 col-8">
        <bb-input-text-ui label="66%" [placeholder]="placeholderRightText"></bb-input-text-ui>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 col-8">
        <bb-input-text-ui label="66%" [placeholder]="placeholderLeftText"></bb-input-text-ui>
      </div>
      <div class="mb-3 col-4">
        <bb-input-text-ui label="33%" [placeholder]="placeholderRightText"></bb-input-text-ui>
      </div>
    </div>
  </bb-fieldset-ui>
</div>

<div class="bb-block bb-block--xl">
  <h4>Alert message inside fieldset</h4>
  <bb-fieldset-ui heading="Fieldset heading for alert message">
    <bb-alert-ui
      title="Default"
      modifier="info"
      message="Body of a sticky alert.  This alert type is placed above the site header."
      data-role="alert-multiple-lines"
    ></bb-alert-ui>
  </bb-fieldset-ui>
</div>
