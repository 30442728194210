<div
  class="bb-ellipsis"
  [ngClass]="{
    'bb-ellipsis--single-line': !lineClamp || isIE,
    'bb-ellipsis--line-clamp': lineClamp && !isIE
  }"
  [style.-webkit-line-clamp]="lineClamp || 'none'"
  [style.line-clamp]="lineClamp || 'none'"
  [bbTooltip]="text"
  [triggers]="tooltipTriggers"
  [disableTooltip]="!isOpen"
  (mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseLeave()"
>
  @if (text) {
    {{ text }}
  } @else {
    <ng-content></ng-content>
  }
  <div></div>
  <!--fix for safari: removal of display standard tooltip -->
</div>
