<div class="row">
  <div class="col-md-6">
    <section class="showcase__section">
      <form [formGroup]="vForm">
        <div class="bb-stack bb-stack--align-top bb-force-ltr">
          <div class="form-group bb-stack__item">
            <label for="countryCode" class="form-label">Country Code</label>
            <bb-input-text-ui
              [id]="'countryCode'"
              [maxLength]="2"
              [size]="2"
              class="bb-block"
              [inputClassName]="'bb-text-align-center'"
              formControlName="phoneNumberCountryCode"
            ></bb-input-text-ui>
          </div>
          <div class="form-group bb-stack__item bb-stack__item--fill">
            <bb-input-phone-ui
              [id]="'phoneNumber'"
              label="Phone Number"
              formControlName="phoneNumber"
              placeholder="Enter a phone number"
              [required]="true"
              [aria-describedby]="'input-phone-only-error'"
            >
            </bb-input-phone-ui>
            <bb-input-validation-message-ui
              [showErrors]="vForm?.get('phoneNumber')?.touched && vForm?.get('phoneNumber')?.errors"
              [id]="'input-phone-only-error'"
            >
              Phone Number field is required.
            </bb-input-validation-message-ui>
          </div>
        </div>
        @if (vForm) {
          <p>
            <strong>Formatted number:&lrm; </strong><br />
            {{ vForm.get('phoneNumberCountryCode')?.value }}
            {{ vForm.get('phoneNumber')?.value | phoneNumber: vForm.get('phoneNumberCountryCode')?.value }}
          </p>
        }
        <div class="bb-block bb-block--md form-group">
          <bb-input-phone-ui
            [id]="'phoneNumber1'"
            label="Phone Number"
            formControlName="phoneNumber1"
            placeholder="Enter a phone number"
            [required]="true"
            [enableCountryCode]="true"
            defaultCountryIsoCode="BR"
            [countryList]="countries"
            (valueChange)="onValueChange($event)"
            [aria-describedby]="'input-phone-number-1'"
          >
          </bb-input-phone-ui>
          <bb-input-validation-message-ui
            [showErrors]="vForm?.get('phoneNumber1')?.touched && vForm?.get('phoneNumber1')?.invalid"
            [id]="'input-phone-number-1'"
          >
            Phone Number field is required.
          </bb-input-validation-message-ui>
        </div>
        <div class="bb-block bb-block--md form-group">
          <bb-input-phone-ui
            [id]="'phoneNumber2'"
            label="Phone Number"
            formControlName="phoneNumber2"
            placeholder="Enter a phone number"
            [required]="true"
            [enableCountryCode]="true"
            [countryList]="country"
            [aria-describedby]="'input-phone-number-2'"
            data-role="input-phone-with-readonly-dropdown"
          >
          </bb-input-phone-ui>
          <bb-input-validation-message-ui
            [showErrors]="vForm?.get('phoneNumber2')?.touched && vForm?.get('phoneNumber2')?.errors"
            [id]="'input-phone-number-2'"
          >
            Phone Number field is required.
          </bb-input-validation-message-ui>
        </div>
      </form>
    </section>
    <section class="showcase__section">
      <h1>Other examples</h1>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Country Code</th>
            <th scope="col">Unformatted Number</th>
            <th scope="col">Formatted Number</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>CA</td>
            <td>16045555555</td>
            <td>{{ '16045555555' | phoneNumber: 'CA' }}</td>
          </tr>
          <tr>
            <td>NL</td>
            <td>0612345622</td>
            <td>{{ '0612345622' | phoneNumber: 'NL' }}</td>
          </tr>
          <tr>
            <td>GB</td>
            <td>07744556677</td>
            <td>{{ '07744556677' | phoneNumber: 'GB' }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</div>
