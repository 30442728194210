import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * @name InfiniteScrollComponent
 *
 * @description
 * Component that displays an infinite scroll.
 */
@Component({
  selector: 'bb-infinite-scroll-ui',
  templateUrl: './infinite-scroll.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteScrollComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  @ViewChild('scrollWrapper', { static: true }) container!: ElementRef;

  /**
   * Event that is triggered when the user has scrolled to the bottom of the element.
   */
  @Output() public scrollEnd = new EventEmitter<void>();

  /**
   * The number of pixels from the bottom of the element at which to trigger the `scrollEnd` event.
   */
  @Input() public offset = 0;

  /**
   * Specify debounce duration in ms
   */
  @Input() public debounce = 100;

  /**
   * If true then `scrollEnd` event should NOT be emitted
   */
  @Input() public disableScrollEnd = false;

  /**
   * Takes a CSS class selector to identify the scrolling element
   */
  @Input() public scrollContainer = '.infinite-scroll-wrapper';

  /**
   * Takes a boolean value to indicate the loading state of data
   */
  @Input() public isLoading = false;

  /**
   * Sets the focusability of the component. If `value` is true, the component becomes focusable.
   * If `value` is false, the component becomes non-focusable. Component is focusable by default
   */
  @Input() public focusable = true;

  /**
   * Accessible label when control does not need to render label tag.
   */
  @Input('aria-label') ariaLabel = 'Infinite scrolling content';

  /**
   * Emits the distance the user has scrolled as a percentage of the total scrollable distance.
   */
  private scrollDistanceSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0.5);
  /**
   * @deprecated not used in component template anymore. Will be removed in ui-ang@13
   */
  scrollDistance$ = this.scrollDistanceSubject.asObservable();
  protected scrollDistance = signal(0.5);

  private containerMutationObserver?: MutationObserver;

  /**
   * Initial valu, will be set to `true` when `scrollContainer` is set
   */
  fromRoot = false;

  /**
   * Setting window scroll event listener to `false`
   */
  readonly scrollWindow = false;

  constructor(private readonly elRef: ElementRef) {}

  ngOnInit(): void {
    this.fromRoot = this.scrollContainer !== null ? true : false;
  }

  ngAfterViewInit(): void {
    this.updateScrollDistance();
    this.subscribeForMutation();
  }

  ngAfterViewChecked() {
    if (this.isLoading) {
      this.container.nativeElement.scrollTop = this.container.nativeElement.scrollHeight;
    }
  }

  ngOnDestroy() {
    this.containerMutationObserver?.disconnect();
  }

  private updateScrollDistance() {
    this.scrollDistance.set(parseFloat((this.offset / this.elRef.nativeElement.offsetHeight).toFixed(2)));
  }

  private subscribeForMutation() {
    this.containerMutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (this.disableScrollEnd || this.isLoading) {
          return;
        }
        if (!mutation.addedNodes.length) {
          return;
        }
        this.updateScrollDistance();

        if (this.scrollDistance() < 1) {
          this.containerMutationObserver?.disconnect();
        }
      });
    });

    this.containerMutationObserver.observe(this.container.nativeElement, {
      childList: true,
      subtree: true,
    });
  }
}
