import uiAngPackage from '@backbase/ui-ang/package.json';

export const environment = {
  production: true,
  mockProviders: [],
  google_analytics_code: 'UA-134599275-1',
  iubenda_site_id: 1519797,
  iubenda_cookie_policy_id: 98914686,
  uiAngVersion: uiAngPackage.version,
  storybookOrigin: `${window.location.origin}/storybook`,
};
