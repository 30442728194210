<div class="paragraph">
<p>A Header is used to display the title of each major section on an interface. They group related content together and help to manage the expectation of the user around what info they can find in that grouping.</p>
</div>
<div class="sect2">
<h3 id="_dos_donts">Do&#8217;s &amp; Don’ts</h3>
<div class="paragraph">
<p><strong>Do</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Use a naming convention that is concise and descriptive</p>
</li>
<li>
<p>Use it for page titles</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Don’t</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Make headers long</p>
</li>
</ul>
</div>
</div>