<button
  #button
  [id]="id + '_button'"
  [type]="type"
  class="btn bb-button btn-{{ color }}"
  [autofocus]="autofocus"
  [disabled]="disabled"
  [class.btn-block]="block"
  [ngClass]="buttonSize === 'sm' ? 'btn-sm' : 'btn-md'"
>
  <ng-content></ng-content>
</button>
