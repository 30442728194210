import { Component } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-range-ui-showcase',
  templateUrl: './input-range-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputRangeShowcaseComponent {
  form = this.fb.group({
    range1: this.fb.group(
      {
        min: [-10, Validators.min(10)],
        max: [100],
      },
      {
        validator: (g: UntypedFormGroup) => {
          const { errors } = g.get('min') as UntypedFormControl;

          return errors ? { ...errors } : null;
        },
      },
    ),
    range2: this.fb.group(
      {
        min: [30],
        max: [33, Validators.max(40)],
      },
      {
        validator: (g: UntypedFormGroup) => {
          const { errors } = g.get('max') as UntypedFormControl;

          return errors ? { ...errors } : null;
        },
      },
    ),
    range3: this.fb.group(
      {
        min: [2, Validators.min(0)],
        max: [5, Validators.max(10)],
      },
      {
        validator: (g: UntypedFormGroup) => {
          const min = (g.get('min') as UntypedFormControl).errors;
          const max = (g.get('max') as UntypedFormControl).errors;

          return min || max ? { ...min, ...max } : null;
        },
      },
    ),
  });

  range4Min = 5;
  range4Max = 7;

  constructor(private readonly fb: UntypedFormBuilder) {}

  isFieldValid(control: FormControl) {
    return control && (control.valid || control.untouched);
  }

  showErrors(name: string, type?: string): boolean | null {
    const control = this.form.get(name);
    if (control !== null) {
      if (type) {
        return !!control.errors?.[type] && !control.valid;
      }

      return control.errors && !control.valid;
    }

    return null;
  }

  hasError(name: string, type: string) {
    const control = this.form.get(name);
    if (control !== null && control.errors) {
      return control.errors[type];
    }

    return false;
  }
}
