import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

/**
 * @name KeyboardClickDirective
 *
 * @description
 * Directive that add click/keydown.enter/keydown.space events on element
 * Used for adding accessibility(tab navigation events) for element
 */
@Directive({
  selector: '[bbKeyboardClick]',
})
export class KeyboardClickDirective implements OnDestroy {
  /**
   * Stream that should be fired on click/keydown.enter/keydown.space
   */
  @Output() bbKeyboardClick = new EventEmitter<Event>();

  private readonly emit$ = new Subject<Event>();
  private readonly gc$ = new Subject<void>();
  constructor(private readonly elem: ElementRef<HTMLElement>) {
    // Fix for frequent keydown event generation for spacebar in FF and IE
    this.emit$.pipe(throttleTime(100), takeUntil(this.gc$)).subscribe(this.bbKeyboardClick);
  }
  /**
   * Adds default class to the hosting element. This class adds the cursor pointer style.
   */
  @HostBinding('class')
  protected classes = 'bb-keyboard-click-directive';

  @HostListener('keydown.enter', ['$event'])
  @HostListener('keydown.space', ['$event'])
  onActionKeyPressed($event: KeyboardEvent) {
    // Check if the selected element is the current element itself and not its nested selectable children
    if (this.elem.nativeElement !== $event.target) {
      return;
    }
    this.emit$.next($event);
  }

  @HostListener('click', ['$event'])
  onClicked($event: MouseEvent) {
    this.emit$.next($event);
  }

  ngOnDestroy() {
    this.gc$.next();
  }
}
