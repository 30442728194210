import { Component } from '@angular/core';
// Please replace relative path imports e.g. '../*' with '@backbase/ui-ang'.
import { ItemLogRecord } from '@backbase/ui-ang/item-log';

@Component({
  selector: 'bb-item-log-example-ui',
  templateUrl: './item-log.example.component.html',
})
export class ItemLogExampleComponent {
  logRecords: ItemLogRecord[] = [
    {
      userFullName: 'Green Goblet',
      status: 'REJECTED',
      createdAt: '2018-01-23T13:44:02Z',
      comment: 'Limit exceeded',
    },
    {
      userFullName: 'Robin Green',
      status: 'APPROVED',
      createdAt: '2018-01-22T13:44:02Z',
    },
  ];
}
