import { NgModule } from '@angular/core';

import { PageHeaderComponent, PageHeaderCustomActionDirective } from './page-header.component';
import { IconModule } from '@backbase/ui-ang/icon';
import { ButtonModule } from '@backbase/ui-ang/button';
import { NgTemplateOutlet } from '@angular/common';

@NgModule({
  imports: [IconModule, ButtonModule, NgTemplateOutlet],
  declarations: [PageHeaderComponent, PageHeaderCustomActionDirective],
  exports: [PageHeaderComponent, PageHeaderCustomActionDirective],
})
export class PageHeaderModule {}
