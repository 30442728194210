@for (tokenGroup of designTokens; track $index) {
  <section>
    <table class="bb-design-token__table">
      <thead>
        <tr>
          <th>Token</th>
          <th>Default</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        @for (token of tokenGroup.tokens; track $index) {
          <tr class="design-token" [class.design-token--disabled]="!token.configurable">
            <td class="border-bottom-0 font-weight-bold">
              <code>{{ token.token }}</code>
            </td>
            <td rowspan="2">{{ token.default }}</td>
            <td rowspan="2">
              @if (token.stable) {
                <span class="align-middle" title="{{ token.configurable ? 'configurable token' : 'read-only token' }}"
                  ><bb-icon-ui name="{{ token.configurable ? 'tune' : 'lock' }}" size="md"></bb-icon-ui
                ></span>
              }
              @if (!token.stable) {
                <span class="align-middle" title="expirimental feature"
                  ><bb-icon-ui name="warning" size="md"></bb-icon-ui
                ></span>
              }
            </td>
          </tr>
          <tr [class.design-token--disabled]="!token.configurable">
            <td class="font-weight-light">{{ token.description }}</td>
          </tr>
        }
      </tbody>
    </table>
  </section>
}
