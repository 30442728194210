import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountNumberPipePageComponent } from './bb-account-number-pipe-page-ui/bb-account-number-pipe-page-ui.component';
import { AlertPageComponent } from './bb-alert-page-ui/bb-alert-page-ui.component';
import { AmountPageComponent } from './bb-amount-page-ui/bb-amount-page-ui.component';
import { AvatarPageComponent } from './bb-avatar-page-ui/bb-avatar-page-ui.component';
import { ButtonPageComponent } from './bb-button-page-ui/bb-button-page-ui.component';
import { CardVendorPageComponent } from './bb-card-vendor-page-ui/bb-card-vendor-page-ui.component';
import { DropdownsPageComponent } from './bb-dropdowns-page-ui/bb-dropdowns-page-ui.component';
import { IconPageComponent } from './bb-icon-page-ui/bb-icon-page-ui.component';
import { FormsPageComponent } from './bb-forms-page-ui/bb-forms-page-ui.component';
import { LogoPageComponent } from './bb-logo-page-ui/bb-logo-page-ui.component';
import { ModalPageComponent } from './bb-modal-page-ui/bb-modal-page-ui.component';
import { NotificationsPageComponent } from './bb-notifications-page-ui/bb-notifications-page-ui.component';
import { PaymentCardPageComponent } from './bb-payment-card-page-ui/bb-payment-card-page-ui.component';
import { ProductItemsPageComponent } from './bb-product-items-page-ui/bb-product-items-page-ui.component';
import { ProductSelectorPageComponent } from './bb-product-selector-page-ui/bb-product-selector-page-ui.component';
import { TabPageComponent } from './bb-tab-page-ui/bb-tab-page-ui.component';
import { TooltipPageComponent } from './bb-tooltip-page-ui/bb-tooltip-page-ui.component';
import { BbPaginationUiComponent } from './bb-pagination-page-ui/bb-pagination-page-ui.component';
import { BbProgressbarPageUiComponent } from './bb-progressbar-page-ui/bb-progressbar-page-ui.component';
import { BbStatesPageUiComponent } from './bb-states-page-ui/bb-states-page-ui.component';
import { BbCollapsiblesPageUiComponent } from './bb-collapsibles-page-ui/bb-collapsibles-page-ui.component';
import { BbPeriodSelectorPageUiComponent } from './bb-period-selector-page-ui/bb-period-selector-page-ui.component';
import { BbTablePageUiComponent } from './bb-table-page-ui/bb-table-page-ui.component';
import { BbSearchBoxPageUiComponent } from './bb-search-box-page-ui/bb-search-box-page-ui.component';
import { BbRichTextEditorPageUiComponent } from './bb-rich-text-editor-page-ui/bb-rich-text-editor-page-ui.component';
import { BbEllipsisPageUiComponent } from './bb-ellipsis-page-ui/bb-ellipsis-page-ui.component';
import { BbHeaderPageUiComponent } from './bb-header-page-ui/bb-header-page-ui.component';
import { BbHeadingPageUiComponent } from './bb-heading-page-ui/bb-heading-page-ui.component';
import { BbStepperPageUiComponent } from './bb-stepper-page-ui/bb-stepper-page-ui.component';
import { BbLoadersPageUiComponent } from './bb-loaders-page-ui/bb-loaders-page-ui.component';
import { BbAreaChartsPageUiComponent } from './bb-area-charts-page-ui/bb-area-charts-page-ui.component';
import { IbanPageComponent } from './bb-iban-page-ui/bb-iban-page-ui.component';
import { PaymentCardNumberPageComponent } from './bb-payment-card-number-page-ui/bb-payment-card-number-page-ui.component';
import { PhoneNumberPageComponent } from './bb-phone-number-page-ui/bb-phone-number-pipe-page.component';
import { BbItemLogPageUiComponent } from './bb-item-log-page-ui/bb-item-log-page-ui.component';
import { BbBadgesPageUiComponent } from './bb-badges-page-ui/bb-badges-page-ui.component';
import { InfiniteScrollPageUiComponent } from './bb-infinite-scroll-page-ui/infinite-scroll-page-ui.component';
import { DatePageUiComponent } from './bb-date-page-ui/date-page-ui.component';
import { AccountSelectorPageComponent } from './bb-account-selector-page-ui/bb-account-selector-page-ui.component';
import { FileAttachmentPageComponent } from './bb-file-attachment-page-ui/bb-file-attachment-page-ui.component';
import { BbLayoutsPageUiComponent } from './bb-layouts-page-ui/bb-layouts-page-ui.component';
import { BbAccountNumberPageComponent } from './bb-account-number-page-ui/bb-account-number-page-ui.component';

const pages = [
  AccountNumberPipePageComponent,
  AlertPageComponent,
  AmountPageComponent,
  AvatarPageComponent,
  ButtonPageComponent,
  CardVendorPageComponent,
  BbHeadingPageUiComponent,
  DropdownsPageComponent,
  FormsPageComponent,
  IconPageComponent,
  LogoPageComponent,
  ModalPageComponent,
  NotificationsPageComponent,
  PaymentCardPageComponent,
  ProductItemsPageComponent,
  ProductSelectorPageComponent,
  TabPageComponent,
  TooltipPageComponent,
  BbPaginationUiComponent,
  BbProgressbarPageUiComponent,
  BbStatesPageUiComponent,
  BbCollapsiblesPageUiComponent,
  BbPeriodSelectorPageUiComponent,
  BbTablePageUiComponent,
  BbSearchBoxPageUiComponent,
  BbRichTextEditorPageUiComponent,
  BbEllipsisPageUiComponent,
  BbHeaderPageUiComponent,
  BbStepperPageUiComponent,
  BbLoadersPageUiComponent,
  BbAreaChartsPageUiComponent,
  IbanPageComponent,
  PaymentCardNumberPageComponent,
  PhoneNumberPageComponent,
  BbItemLogPageUiComponent,
  BbBadgesPageUiComponent,
  InfiniteScrollPageUiComponent,
  DatePageUiComponent,
  FileAttachmentPageComponent,
  BbLayoutsPageUiComponent,
  BbAccountNumberPageComponent,
];

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [...pages, AccountSelectorPageComponent],
    exports: [...pages, AccountSelectorPageComponent],
})
export class BackbaseUiAngDocPagesModule {}
export const COMPONENTS = [...pages, AccountSelectorPageComponent];
export {
  AccountNumberPipePageComponent,
  AccountSelectorPageComponent,
  AlertPageComponent,
  AmountPageComponent,
  AvatarPageComponent,
  ButtonPageComponent,
  CardVendorPageComponent,
  DropdownsPageComponent,
  FormsPageComponent,
  IconPageComponent,
  LogoPageComponent,
  ModalPageComponent,
  NotificationsPageComponent,
  BbHeadingPageUiComponent,
  PaymentCardPageComponent,
  ProductItemsPageComponent,
  ProductSelectorPageComponent,
  TabPageComponent,
  TooltipPageComponent,
  BbPaginationUiComponent,
  BbProgressbarPageUiComponent,
  BbStatesPageUiComponent,
  BbCollapsiblesPageUiComponent,
  BbPeriodSelectorPageUiComponent,
  BbTablePageUiComponent,
  BbSearchBoxPageUiComponent,
  BbRichTextEditorPageUiComponent,
  BbEllipsisPageUiComponent,
  BbHeaderPageUiComponent,
  BbStepperPageUiComponent,
  BbLoadersPageUiComponent,
  BbAreaChartsPageUiComponent,
  IbanPageComponent,
  PaymentCardNumberPageComponent,
  PhoneNumberPageComponent,
  BbItemLogPageUiComponent,
  BbBadgesPageUiComponent,
  InfiniteScrollPageUiComponent,
  DatePageUiComponent,
  FileAttachmentPageComponent,
  BbLayoutsPageUiComponent,
  BbAccountNumberPageComponent,
};
