import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @name AvatarComponent
 *
 * @description
 * Component that displays an avatar.
 *
 * ### Global configuration token
 * `AVATAR_CONFIG_TOKEN` enables you to globally set the same configuration for all instances of `AvatarComponent` in your project.
 *
 * The following properties can be overwritten using the token:
 * - `initialsFn`
 *
 * #### Usage notes
 * The following is an example of how to use the token:
 * ```
    import { AVATAR_CONFIG_TOKEN } from '@backbase/ui-ang/avatar';
    import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
    import { AppModule } from './app/app.module';

    const avatarConfig = {
      initialsFn: (text) => `${text[0]}${text[1]}`
    }

    platformBrowserDynamic().bootstrapModule(AppModule, {
      providers: [{ provide: AVATAR_CONFIG_TOKEN, useValue: avatarConfig }]
    });
   ```
 *
 */
@Component({
  selector: 'bb-avatar-ui',
  templateUrl: './avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  /**
   * The image  of the avatar. Defaults to empty string.
   */
  @Input() image = '';
  /**
   * The name  of the avatar. Defaults to empty string.
   */
  @Input() name = '';
  /**
   * The imgAlt for provide alt in img of the avatar. Defaults to empty string.
   */
  @Input() imgAlt = '';
  /**
   * Size of the avatar. Possible values sm | md | lg | xl. Defaults to empty string.
   */
  @Input() size = '';
  /**
   * Initials to be displayed. If not provided, initials will be derived from the name input. Supports on up to two characters.
   */
  @Input({ transform: (val: string) => val.replace(/\s/g, '') }) initials = '';
}
