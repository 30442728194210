import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipShowcaseComponent } from './tooltip-showcase.component';
import { TooltipModule } from '@backbase/ui-ang/tooltip-directive';
import { IconModule } from '@backbase/ui-ang/icon';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [CommonModule, TooltipModule, IconModule, InputTextModule, ButtonModule],
  declarations: [TooltipShowcaseComponent],
})
export class TooltipShowcaseModule {}
