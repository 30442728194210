<form [formGroup]="form">
  <h4 id="ariaLabelledby">Example Search Box</h4>
  <div class="bb-block bb-block--lg">
    <h5 id="ariaDescribedby">
      Search box with search and clear button.
    </h5>
    <bb-search-box-ui
      formControlName="query"
      [showClear]="true"
      [showSearch]="true"
      placeholder="Search box with autocomplete. Start typing with letter 'J'"
      [typeaheadOptions]="typeaheadOptions"
      (submit)="startSearching($event)"
      [aria-labelledby]="'ariaLabelledby'"
      [aria-describedby]="'ariaDescribedby'"
    ></bb-search-box-ui>
  </div>
</form>
