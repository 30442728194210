<div class="bb-block bb-block--md">
  <h3 id="inline-edit">Inline Edit</h3>
  <bb-input-inline-edit-ui
    [inputText]="editInlineModel"
    (cancel)="onCancel()"
    (accept)="onAccept($event)"
    [maxLength]="64"
    [minLength]="2"
    [required]="true"
    hint="This is visible to the whole company"
    aria-labelledby="inline-edit"
  >
  </bb-input-inline-edit-ui>
</div>
<div class="bb-block bb-block--md">
  <h3 id="inline-edit-with-character-counter">Inline edit with characters counter</h3>
  <bb-input-inline-edit-ui
    [inputText]="editInlineModelWithCounter"
    (cancel)="onCancel()"
    (accept)="onAcceptWithCounter($event)"
    [maxLength]="25"
    [showCharCounter]="true"
    hint="This is visible to the whole company"
    aria-labelledby="inline-edit-with-character-counter"
  >
  </bb-input-inline-edit-ui>
</div>
<div class="bb-block bb-block--md">
  <h3 id="inline-edit-with-loading-state">Inline edit with loading state</h3>
  <bb-input-inline-edit-ui
    [hasLoadingState]="true"
    [inputText]="editInlineModelWithLoading"
    (accept)="onAcceptWithLoading($event)"
    [maxLength]="64"
    [(state)]="inlineEditState"
    hint="This is visible to the whole company"
    aria-labelledby="inline-edit-with-loading-state"
  >
  </bb-input-inline-edit-ui>
</div>
<div class="bb-block bb-block--md">
  <h3 id="inline-edit-with-currency-formatted-error-messages">Inline edit with currency formatted error messages</h3>
  <bb-input-inline-edit-ui
    [inputText]="editInlineModelCurrency"
    (accept)="onAcceptCurrency($event)"
    [maxValue]="1000"
    [minValue]="500"
    [currency]="'EUR'"
    hint="This is visible to the whole company"
    aria-labelledby="inline-edit-with-currency-formatted-error-messages"
  >
  </bb-input-inline-edit-ui>
</div>
