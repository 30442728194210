<h4 id="ariaLabelledById">Single select Dropdown</h4>
<div id="ariaDescribedById">Default with list of string as options</div>
<div [formGroup]="vForm">
  <bb-dropdown-single-select-ui
    label="Choose your search engine"
    placeholder="No search engine selected"
    formControlName="websiteStrings"
    [options]="stringDropdownOptions"
    [required]="true"
    [id]="'singleDropdown'"
    class="d-block bb-block bb-block--lg"
    [aria-label]="'single select'"
    [aria-labelledby]="'ariaLabelledById'"
    [aria-describedby]="'ariaDescribedById'"
    [aria-owns]="'singleDropdown'"
  >
  </bb-dropdown-single-select-ui>

  <bb-dropdown-single-select-ui
    label="Choose your search engine"
    placeholder="No search engine selected"
    formControlName="websiteObjects"
    displayAttributePath="title"
    [options]="objectDropdownOptions"
    [required]="true"
    class="d-block bb-block bb-block--lg"
  >
  </bb-dropdown-single-select-ui>

  <bb-dropdown-single-select-ui
    [compareWithFn]="compareByValue"
    label="Frequency"
    displayAttributePath="value"
    formControlName="frequency"
    data-role="frequency-selector"
  >
    @for (frequency of frequencies; track $index) {
      <bb-dropdown-single-select-option-ui
        class="text-capitalize"
        [value]="frequency"
        [label]="frequency.value"
      >
      </bb-dropdown-single-select-option-ui>
    }
  </bb-dropdown-single-select-ui>
</div>
