import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

const isFocusable = (el: any): boolean => !!el && typeof el.focus === 'function';

@Injectable({
  providedIn: 'root',
})
export class FocusService implements OnDestroy {
  private readonly focusSubject = new Subject<ElementRef<HTMLElement>>();
  private readonly sub = this.focusSubject.pipe(throttleTime(0, undefined, { leading: true })).subscribe({
    next: (focused) => {
      if (isFocusable(focused.nativeElement)) {
        focused.nativeElement.focus();
      }
    },
  });

  focus(el: ElementRef<HTMLElement>) {
    this.focusSubject.next(el);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
