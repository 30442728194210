@for (config of notificationsConfig; track $index) {
  <div class="row mb-4">
    <div class="col-12">
      <button
        (click)="showNotification(config.notification)"
        [buttonSize]="'md'"
        [color]="config.btn.color"
        bbButton
        [attr.data-role]="'notification-' + config.btn.color + '-' + config.btn.type"
      >
        {{ config.btn.text }}
      </button>
    </div>
  </div>
}

<ng-template #default1>
  <span>Save</span>
</ng-template>

<ng-template #default2>
  <span>Discard</span>
</ng-template>

<ng-template #messageTemplate>
  <p>This is an html message message template</p>
</ng-template>
