import { CountryList } from './input-phone.component';

// Example: Guernsey +44-1481 (8 char)
const longestCountryCode = 8;

// Example: US +1 (2 char)
const shortestCountryCode = 2;

export const extractCountryFromValue = (value: string, countryList?: CountryList) => {
  let result;

  // Selects the country starting from the longest available country code or the value size, whichever is shorter
  for (let size = Math.min(value.length, longestCountryCode); size >= shortestCountryCode; size--) {
    const candidateCountryCode = value.substring(0, size);

    result = countryList?.find((country) => country.countryCode === candidateCountryCode);

    if (result) break;
  }

  return result;
};

export const stripCountryCodeFromValue = (value: string, countryCode?: string) =>
  countryCode ? value.replace(`${countryCode}`, '') : value;
