import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomAttributesService } from '@backbase/ui-ang/services';
import { AlertType } from './alert.constants';

interface AlertOptions {
  warning: string;
  error: string;
  success: string;
  info: string;

  [key: string]: string;
}

/**
 * @name AlertComponent
 *
 * @description
 * Component that displays an alert.
 *
 * ### Accessibility
 * For Alert component it's not required to pass any options for accessibility.
 * It internally handles accessibility for title and message:
 *  - title is linked with aria-labelledby.
 *  - message is linked with aria-describedby.
 */
@Component({
  selector: 'bb-alert-ui',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit, AfterViewInit {
  /**
   * The type modifier for the alert. Defaults to 'error'.
   */
  @Input() modifier: AlertType = 'error';
  /**
   * Whether the alert can be dismissed. Defaults to false.
   */
  @Input() dismissible = false;
  /**
   * Whether the button in the alert is focused on initial load. Defaults to false.
   */
  @Input() autofocus: boolean = false;
  /**
   * The title of the alert.
   * Will throw an error if unset.
   * It is internaly linked with aria-labelledby.
   */
  @Input() title: string | undefined;
  /**
   * The message of the alert.
   * If nothing provided, wouldn't be displayed.
   * It is internally linked with aria-describedby.
   */
  @Input() message: string | undefined;
  /**
   * The title of the primary action button.
   */
  @Input() primaryActionButtonText: string | undefined;
  /**
   * The title of the supportive link button.
   */
  @Input() supportiveLinkText: string | undefined;

  /**
   * Aria label for a "close" button
   */
  @Input() closeButtonAriaLabel = 'Close alert';

  /**
   * The callback fired upon closing the alert.
   */
  @Output() close = new EventEmitter<void>();
  /**
   * The callback fired upon clicking primary action button.
   */
  @Output() primaryActionButtonClick = new EventEmitter<void>();
  /**
   * The callback fired upon clicking supportive link button.
   */
  @Output() supportiveLinkClick = new EventEmitter<void>();

  @ViewChild('contentWrapper') contentWrapper!: ElementRef;

  alertTitleId: string | null = this.domAttrService.generateId();
  alertDescriptionId: string | null = this.domAttrService.generateId();

  isOpen = true;
  isContentProvided = true;

  private previousFocused: HTMLElement | null = null;

  constructor(
    private readonly domAttrService: DomAttributesService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
  ) {}

  ngOnInit() {
    if (typeof this.title === 'undefined') {
      throw new Error(`"title" input is required in "${this.constructor.name}"`);
    }
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      const focusable = this.elementRef.nativeElement.querySelector('button');

      if (focusable) {
        this.previousFocused = document.activeElement as HTMLElement | null;
        focusable.focus();
      }
    }

    if (!!this.message) {
      this.isContentProvided = false;

      return;
    }

    this.isContentProvided = Boolean(this.contentWrapper?.nativeElement.innerText);
    if (!this.isContentProvided) {
      this.alertDescriptionId = null;
      this.changeDetector.detectChanges();
    }
  }

  closeAlert() {
    this.previousFocused?.focus();
    this.close.emit();
  }
}
