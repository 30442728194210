import { Component } from '@angular/core';
import { tabsAnim, BackbaseDocs } from '@backbase/ui-ang/util';
@Component({
  selector: 'bb-tab-ui-showcase',
  templateUrl: './tab-showcase.component.html',
  animations: [tabsAnim],
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class TabShowcaseComponent {
  index = 0;

  onTabSelect(index: number) {
    this.index = index;
    console.log(`Selected tab with index: ${index}`);
  }

  onClick($event: any) {
    $event.stopPropagation();
    $event.preventDefault();
  }
}
