import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { isValidIBANFormControl } from '@backbase/ui-ang/iban-lib';

@Directive({
  selector: '[bbIbanValidatorDir]',
  providers: [{ provide: NG_VALIDATORS, useExisting: IbanValidatorDirective, multi: true }],
})
export class IbanValidatorDirective implements Validator {
  // emits invalidIban
  validate(control: AbstractControl): { [key: string]: any } | null {
    return control?.value ? isValidIBANFormControl(control) : null;
  }
}
