import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgDateStructNullable } from './input-datepicker.model';

const isValidNgbDateStructProp = (struct: any, propName: string): boolean =>
  struct ? Object.prototype.hasOwnProperty.call(struct, propName) && typeof struct[propName] === 'number' : false;

export const isNgbDateStruct = (value: any): value is NgbDateStruct =>
  isValidNgbDateStructProp(value, 'day') &&
  isValidNgbDateStructProp(value, 'month') &&
  isValidNgbDateStructProp(value, 'year') &&
  !isNaN(new Date(value.year, value.month - 1, value.day).getTime());

@Injectable()
export class NgbDateStringAdapter extends NgbDateAdapter<string> {
  fromModel(dateString: string): NgDateStructNullable {
    if (dateString) {
      const d = new Date(dateString);
      const year = d.getFullYear();

      if (year) {
        return {
          year: year,
          month: d.getMonth() + 1,
          day: d.getDate(),
        };
      }
    }

    return null;
  }

  toModel(date: NgbDateStruct | string | undefined | null): string {
    if (!date) {
      return '';
    } else if (typeof date === 'string') {
      return date;
    } else if (isNgbDateStruct(date)) {
      return new Date(date.year, date.month - 1, date.day).toISOString();
    }

    return Object.prototype.toString.call(date);
  }
}
