{
    "global": [
        {
            "variable": "$background-color-neutral",
            "description": "Default background color for all page layouts\n",
            "default": "#f3f6f9",
            "groups": [
                "background-color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-width",
            "description": "Global border width\n",
            "default": "1px",
            "groups": [
                "border",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-style",
            "description": "Global border style\n",
            "default": "solid",
            "groups": [
                "border",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-color",
            "description": "Global border color\n",
            "default": "$color-neutral-grey",
            "groups": [
                "border",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border",
            "description": "Global border (read-only)\n",
            "default": "$border-width $border-style $border-color",
            "groups": [
                "border",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-1",
            "description": "Configures additional palette color 1: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#0062C4",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-2",
            "description": "Configures additional palette color 2: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#00a1e0",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-3",
            "description": "Configures additional palette color 3: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#ffac09",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-4",
            "description": "Configures additional palette color 4: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#f2780c",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-5",
            "description": "Configures additional palette color 5: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#e24b2c",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-6",
            "description": "Configures additional palette color 6: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#c22327",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-7",
            "description": "Configures additional palette color 7: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#49074e",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-8",
            "description": "Configures additional palette color 8: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#181e41",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-9",
            "description": "Configures additional palette color 9: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#260a24",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-10",
            "description": "Configures additional palette color 10: Used in the custom icon colors and part of the data-visualisation palette\n",
            "default": "#2e7d32",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-primary-color",
            "description": "Configures the primary color used in the data-visualisation palette\n",
            "default": "#0062C4",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-negative-color",
            "description": "Configures the color used for negative amounts in data-visualisation\n",
            "default": "#d32f2f",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-positive-color",
            "description": "Configures the color used for positive amounts in data-visualisation\n",
            "default": "#2e7d32",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-secondary-color",
            "description": "Configures the secondary color used in data-visualisation\n",
            "default": "$chart-color-8",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-neutral-color",
            "description": "Configures the neutral color used in data-visualisation\n",
            "default": "#f2f2f2",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-neutral-grey-color",
            "description": "Configures the neutral grey color used in data-visualisation\n",
            "default": "$color-neutral-grey",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-alert-color",
            "description": "Configures the alert color used in data-visualisation. By default a slightly lighter calculation of $chart-negative-color\n",
            "default": "mix(#ffffff, $chart-negative-color, 85%)",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-primary",
            "description": "Configures the brand primary color\nUsed to calculate lighter and darker shade variables\n",
            "default": "#295eff",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-secondary",
            "description": "Configures the brand secondary color\nUsed to calculate lighter and darker shade variables\n",
            "default": "#295eff",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-accent",
            "description": "Configures the brand accent color\nUsed to calculate lighter and darker shade variables\n",
            "default": "#f2780c",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-success",
            "description": "Configures the semantic success color\nUsed to calculate lighter and darker shade variables\n",
            "default": "#2e7d32",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-danger",
            "description": "Configures the semantic danger color\nUsed to calculate lighter and darker shade variables\n",
            "default": "#d32f2f",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-warning",
            "description": "Configures the semantic warning color\nUsed to calculate lighter and darker shade variables\n",
            "default": "#fbc02d",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-info",
            "description": "Configures the semantic info color\nUsed to calculate lighter and darker shade variables\n",
            "default": "#1476cc",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-white",
            "description": "Configures the default white color\n",
            "default": "#fff",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-lightest",
            "description": "Configures the lightest neutral color\n",
            "default": "#f5f5f5",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-lighter",
            "description": "Configures the lighter neutral color\n",
            "default": "#f2f2f2",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-light",
            "description": "Configures the light neutral color\n",
            "default": "#e9eaeb",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-grey",
            "description": "Configures the grey neutral color\n",
            "default": "#dedede",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-greyer",
            "description": "Configures the darker grey neutral color\n",
            "default": "#c5c5c5",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-greyest",
            "description": "Configures the darkest grey neutral color\n",
            "default": "#a2a2a2",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-dark",
            "description": "Configures the dark neutral color\n",
            "default": "#616161",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-darker",
            "description": "Configures the darker neutral color\n",
            "default": "#333333",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-neutral-black",
            "description": "Configures the black neutral color\n",
            "default": "#000000",
            "groups": [
                "color",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$chart-color-1-lighter",
            "description": "The 30% lighter shade of $chart-color-1 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-1, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-1-lightest",
            "description": "The 85% lighter shade of $chart-color-1 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-1, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-1-darker",
            "description": "The 30% darker shade of $chart-color-1 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-1, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-1-darkest",
            "description": "The 45% darker shade of $chart-color-1 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-1, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-2-lighter",
            "description": "The 30% lighter shade of $chart-color-2 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-2, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-2-lightest",
            "description": "The 85% lighter shade of $chart-color-2 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-2, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-2-darker",
            "description": "The 30% darker shade of $chart-color-2 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-2, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-2-darkest",
            "description": "The 45% darker shade of $chart-color-2 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-2, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-3-lighter",
            "description": "The 30% lighter shade of $chart-color-3 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-3, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-3-lightest",
            "description": "The 85% lighter shade of $chart-color-3 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-3, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-3-darker",
            "description": "The 30% darker shade of $chart-color-3 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-3, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-3-darkest",
            "description": "The 45% darker shade of $chart-color-3 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-3, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-4-lighter",
            "description": "The 30% lighter shade of $chart-color-4 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-4, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-4-lightest",
            "description": "The 85% lighter shade of $chart-color-4 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-4, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-4-darker",
            "description": "The 30% darker shade of $chart-color-4 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-4, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-4-darkest",
            "description": "The 45% darker shade of $chart-color-4 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-4, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-5-lighter",
            "description": "The 30% lighter shade of $chart-color-5 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-5, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-5-lightest",
            "description": "The 85% lighter shade of $chart-color-5 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-5, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-5-darker",
            "description": "The 30% darker shade of $chart-color-5 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-5, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-5-darkest",
            "description": "The 45% darker shade of $chart-color-5 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-5, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-6-lighter",
            "description": "The 30% lighter shade of $chart-color-6 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-6, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-6-lightest",
            "description": "The 85% lighter shade of $chart-color-6 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-6, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-6-darker",
            "description": "The 30% darker shade of $chart-color-6 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-6, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-6-darkest",
            "description": "The 45% darker shade of $chart-color-6 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-6, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-7-lighter",
            "description": "The 30% lighter shade of $chart-color-7 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-7, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-7-lightest",
            "description": "The 85% lighter shade of $chart-color-7 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-7, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-7-darker",
            "description": "The 30% darker shade of $chart-color-7 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-7, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-7-darkest",
            "description": "The 45% darker shade of $chart-color-7 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-7, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-8-lighter",
            "description": "The 30% lighter shade of $chart-color-8 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-8, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-8-lightest",
            "description": "The 85% lighter shade of $chart-color-8 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-8, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-8-darker",
            "description": "The 30% darker shade of $chart-color-8 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-8, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-8-darkest",
            "description": "The 45% darker shade of $chart-color-8 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-8, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-9-lighter",
            "description": "The 30% lighter shade of $chart-color-9 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-9, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-9-lightest",
            "description": "The 85% lighter shade of $chart-color-9 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-9, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-9-darker",
            "description": "The 30% darker shade of $chart-color-9 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-9, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-9-darkest",
            "description": "The 45% darker shade of $chart-color-9 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-9, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-10-lighter",
            "description": "The 30% lighter shade of $chart-color-10 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-10, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-10-lightest",
            "description": "The 85% lighter shade of $chart-color-10 (read-only)\n",
            "default": "mix($color-neutral-white, $chart-color-10, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-10-darker",
            "description": "The 30% darker shade of $chart-color-10 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-10, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$chart-color-10-darkest",
            "description": "The 45% darker shade of $chart-color-10 (read-only)\n",
            "default": "mix($color-neutral-black, $chart-color-10, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-primary-lighter",
            "description": "The 30% lighter shade of $color-primary (read-only)\n",
            "default": "mix(#fff, $color-primary, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-primary-lightest",
            "description": "The 85% lighter shade of $color-primary (read-only)\n",
            "default": "mix(#fff, $color-primary, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-primary-darker",
            "description": "The 30% darker shade of $color-primary (read-only)\n",
            "default": "mix(#000, $color-primary, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-primary-darkest",
            "description": "The 45% darker shade of $color-primary (read-only)\n",
            "default": "mix(#000, $color-primary, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-secondary-lighter",
            "description": "The 30% lighter shade of $color-secondary (read-only)\n",
            "default": "mix(#fff, $color-secondary, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-secondary-lightest",
            "description": "The 85% lighter shade of $color-secondary (read-only)\n",
            "default": "mix(#fff, $color-secondary, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-secondary-darker",
            "description": "The 30% darker shade of $color-secondary (read-only)\n",
            "default": "mix(#000, $color-secondary, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-secondary-darkest",
            "description": "The 45% darker shade of $color-secondary (read-only)\n",
            "default": "mix(#000, $color-secondary, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-accent-lighter",
            "description": "The 30% lighter shade of $color-accent (read-only)\n",
            "default": "mix(#fff, $color-accent, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-accent-lightest",
            "description": "The 85% lighter shade of $color-accent (read-only)\n",
            "default": "mix(#fff, $color-accent, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-accent-darker",
            "description": "The 30% darker shade of $color-accent (read-only)\n",
            "default": "mix(#000, $color-accent, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-accent-darkest",
            "description": "The 45% darker shade of $color-accent (read-only)\n",
            "default": "mix(#000, $color-accent, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-success-lighter",
            "description": "The 30% lighter shade of $color-success (read-only)\n",
            "default": "mix(#fff, $color-success, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-success-lightest",
            "description": "The 85% lighter shade of $color-success (read-only)\n",
            "default": "mix(#fff, $color-success, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-success-darker",
            "description": "The 30% darker shade of $color-success (read-only)\n",
            "default": "mix(#000, $color-success, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-success-darkest",
            "description": "The 45% darker shade of $color-success (read-only)\n",
            "default": "mix(#000, $color-success, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-danger-lighter",
            "description": "The 30% lighter shade of $color-danger (read-only)\n",
            "default": "mix(#fff, $color-danger, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-danger-lightest",
            "description": "The 85% lighter shade of $color-danger (read-only)\n",
            "default": "mix(#fff, $color-danger, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-danger-darker",
            "description": "The 30% darker shade of $color-danger (read-only)\n",
            "default": "mix(#000, $color-danger, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-danger-darkest",
            "description": "The 45% darker shade of $color-danger (read-only)\n",
            "default": "mix(#000, $color-danger, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-warning-lighter",
            "description": "The 30% lighter shade of $color-warning (read-only)\n",
            "default": "mix(#fff, $color-warning, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-warning-lightest",
            "description": "The 85% lighter shade of $color-warning (read-only)\n",
            "default": "mix(#fff, $color-warning, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-warning-darker",
            "description": "The 30% darker shade of $color-warning (read-only)\n",
            "default": "mix(#000, $color-warning, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-warning-darkest",
            "description": "The 45% darker shade of $color-warning (read-only)\n",
            "default": "mix(#000, $color-warning, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-info-lighter",
            "description": "The 30% lighter shade of $color-info (read-only)\n",
            "default": "mix(#fff, $color-info, $color-shade-lighter)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-info-lightest",
            "description": "The 85% lighter shade of $color-info (read-only)\n",
            "default": "mix(#fff, $color-info, $color-shade-lightest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-info-darker",
            "description": "The 30% darker shade of $color-info (read-only)\n",
            "default": "mix(#000, $color-info, $color-shade-darker)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$color-info-darkest",
            "description": "The 45% darker shade of $color-info (read-only)\n",
            "default": "mix(#000, $color-info, $color-shade-darkest)",
            "groups": [
                "color",
                "brand-extended",
                "global"
            ]
        },
        {
            "variable": "$focus-box-shadow-offset-x",
            "description": "Configures the horizontal shadow position of focused components\nUsed to compose $focus-box-shadow\n",
            "default": "0",
            "groups": [
                "focus",
                "state",
                "global"
            ]
        },
        {
            "variable": "$focus-box-shadow-offset-y",
            "description": "Configures the vertical shadow position of focused components\nUsed to compose $focus-box-shadow\n",
            "default": "0",
            "groups": [
                "focus",
                "state",
                "global"
            ]
        },
        {
            "variable": "$focus-box-shadow-blur-radius",
            "description": "Configures the shadow blur radius for focused components\nUsed to compose $focus-box-shadow\n",
            "default": "0",
            "groups": [
                "focus",
                "state",
                "global"
            ]
        },
        {
            "variable": "$focus-box-shadow-spread-radius",
            "description": "Configures the shadow spread radius for focused components\nUsed to compose $focus-box-shadow\n",
            "default": "3px",
            "groups": [
                "focus",
                "state",
                "global"
            ]
        },
        {
            "variable": "$focus-box-shadow-color",
            "description": "Configures the shadow color for focused components\nUsed to compose $focus-box-shadow\n",
            "default": "$color-info-lightest",
            "groups": [
                "focus",
                "state",
                "global"
            ]
        },
        {
            "variable": "$focus-box-shadow",
            "description": "Focus state shadow (read-only)\nComposed of the separate shadow properties: offset-x, offset-y, blur-radius, spread-radius and color\n",
            "default": "$focus-box-shadow-offset-x $focus-box-shadow-offset-y\n  $focus-box-shadow-blur-radius $focus-box-shadow-spread-radius $focus-box-shadow-color",
            "groups": [
                "focus",
                "state",
                "global"
            ]
        },
        {
            "variable": "$focus-border-color",
            "description": "Configures the border-color for focused components\n",
            "default": "$color-info-lighter",
            "groups": [
                "focus",
                "state",
                "global"
            ]
        },
        {
            "variable": "$font-family",
            "description": "Configures the default font\n",
            "default": "\"Inter\", \"sans-serif\"",
            "groups": [
                "font",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-family-heading",
            "description": "Configures the font used for headings. Defaults to $font-family\n",
            "default": "$font-family",
            "groups": [
                "font",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-weight-regular",
            "description": "Configures the default font weight\n",
            "default": "400",
            "groups": [
                "font",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-weight-light",
            "description": "Configures the light font weight\n",
            "default": "300",
            "groups": [
                "font",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-weight-semi-bold",
            "description": "Configures the semi-bold font-weight\n",
            "default": "600",
            "groups": [
                "font",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-weight-bold",
            "description": "Configures the bold font-weight\n",
            "default": "700",
            "groups": [
                "font",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-weight-heading",
            "description": "Configures the font-weight used for headings. Defaults to $font-weight-bold\n",
            "default": "$font-weight-bold",
            "groups": [
                "font",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-base",
            "description": "Configures the font size base. NOTE: Use relative sizing like rem. This ensures user-specific settings, which create an accessible experience\nBrowser default of 1rem is typically 16px\n",
            "default": "1rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-hero",
            "description": "Configures the font size used by the Hero font\n",
            "default": "2.5rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-h1",
            "description": "Configures the font size used by page titles\n",
            "default": "2.125rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-h2",
            "description": "Configures the font size used by subsection titles\n",
            "default": "1.5rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-h3",
            "description": "Configures the font size used by sub-subsection titles\n",
            "default": "1.25rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-highlight",
            "description": "Configures the font size used for emphasizing amounts\n",
            "default": "1.125rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-default",
            "description": "Configures the font size used as default. Defaults to $font-size-base\n",
            "default": "$font-size-base",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-subtitle",
            "description": "Configures the font size used for descriptions and secondary text\n",
            "default": "0.875rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-subheader",
            "description": "Configures the font size used for subheaders\n",
            "default": "0.75rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-table-default",
            "description": "Configures the font size used for table cells\n",
            "default": "0.875rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$font-size-table-header",
            "description": "Configures the font size used for table header cells\n",
            "default": "0.75rem",
            "groups": [
                "font-size",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$path-assets",
            "description": "Configures a custom assets path. Defaults to the preset assets folder\n",
            "default": "\"../assets\"",
            "groups": [
                "implementation",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$path-fonts",
            "description": "Configures a custom fonts path. Defaults to the preset $assets/fonts folder\n",
            "default": "\"#{$path-assets}/fonts\"",
            "groups": [
                "implementation",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$path-images",
            "description": "Configures a custom assets path. Defaults to the preset $assets/fonts folder\n",
            "default": "\"#{$path-assets}/images\"",
            "groups": [
                "implementation",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$line-height-base",
            "description": "Configures the default line-height\n",
            "default": "1.5",
            "groups": [
                "line-height",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$line-height-heading",
            "description": "Configures the line-height used by headings\n",
            "default": "$line-height-headings",
            "groups": [
                "line-height",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$motion-delay-slow",
            "description": "Configures the delay in seconds for slow motions (experimental)\n",
            "default": "0.5s",
            "groups": [
                "motion",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$motion-delay-default",
            "description": "Configures the default delay in seconds motions (experimental)\n",
            "default": "0.2s",
            "groups": [
                "motion",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$motion-delay-fast",
            "description": "Configures the delay in seconds for fast motions (experimental)\n",
            "default": "0.1s",
            "groups": [
                "motion",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$motion-duration-slow",
            "description": "Configures the duration in seconds for slow motions (experimental)\n",
            "default": "0.5s",
            "groups": [
                "motion",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$motion-duration-default",
            "description": "Configures the default duration in seconds for motions (experimental)\n",
            "default": "0.2s",
            "groups": [
                "motion",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$motion-duration-fast",
            "description": "Configures the duration in seconds for fast motions (experimental)\n",
            "default": "0.1s",
            "groups": [
                "motion",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$motion-timing-ease-in",
            "description": "Configures the ease-in timing used for motions (experimental)\n",
            "default": "cubic-bezier(0.89, 0.03, 0.68, 0.22)",
            "groups": [
                "motion",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$motion-timing-ease-out",
            "description": "Configures the ease-out timing used for motions (experimental)\n",
            "default": "cubic-bezier(0.16, 0.84, 0.44, 1)",
            "groups": [
                "motion",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$motion-timing-ease-in-out",
            "description": "Configures the ease-in-out timing used for motions (experimental)\n",
            "default": "cubic-bezier(0.42, 0, 0.58, 1)",
            "groups": [
                "motion",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-radius",
            "description": "Border radius default value\n",
            "default": "1rem",
            "groups": [
                "radius",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-radius-none",
            "description": "Border radius none. Fixed to be 0 to reflect no border-radius (read-only)\n",
            "default": "0",
            "groups": [
                "radius",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-radius-circle",
            "description": "Border radius circle. Fixed to be 50% as it reflects all components with circle styling (read-only)\n",
            "default": "50%",
            "groups": [
                "radius",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-radius-xs",
            "description": "Border radius with exta small modifier. Defaults to 25% of the $border-radius.\n",
            "default": "$border-radius * 0.25",
            "groups": [
                "radius",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-radius-sm",
            "description": "Border radius with small modifier. Defaults to 50% of the $border-radius\n",
            "default": "$border-radius * 0.5",
            "groups": [
                "radius",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-radius-md",
            "description": "Border radius with medium modifier. Defaults to 100% of $border-radius\n",
            "default": "$border-radius",
            "groups": [
                "radius",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-radius-lg",
            "description": "Border radius with large modifier. Defaults to a round border\n",
            "default": "$border-radius-circle",
            "groups": [
                "radius",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-radius-pill",
            "description": "Border radius with a pill modifier. Defaults to 20 * $border-radius\n",
            "default": "$border-radius * 20",
            "groups": [
                "radius",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$border-radius-enabled",
            "description": "Global toggle to enable rounded corners (experimental)\n",
            "default": "true",
            "groups": [
                "radius",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-sm-offset-x",
            "description": "Configures the horizontal position of Small shadows\nUsed to compose $box-shadow-sm\n",
            "default": "0px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-sm-offset-y",
            "description": "Configures the vertical position of Small shadows\nUsed to compose $box-shadow-sm\n",
            "default": "4px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-sm-blur-radius",
            "description": "Configures the blur radius for Small shadows\nUsed to compose $box-shadow-sm\n",
            "default": "9px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-sm-spread-radius",
            "description": "Configures the spread radius for Small shadows\nUsed to compose $box-shadow-sm\n",
            "default": "0px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-sm-color",
            "description": "Configures the color for Small shadows\nUsed to compose $box-shadow-sm\n",
            "default": "rgba(0, 0, 0, 0.12)",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-sm",
            "description": "Small shadow (read-only)\nComposed of the separate shadow properties: offset-x, offset-y, blur-radius, spread-radius and color\n",
            "default": "$box-shadow-sm-offset-x $box-shadow-sm-offset-y $box-shadow-sm-blur-radius\n  $box-shadow-sm-spread-radius $box-shadow-sm-color",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-md-offset-x",
            "description": "Configures the horizontal position of Medium shadows\nUsed to compose $box-shadow-md\n",
            "default": "0px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-md-offset-y",
            "description": "Configures the vertical position of Medium shadows\nUsed to compose $box-shadow-md\n",
            "default": "8px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-md-blur-radius",
            "description": "Configures the blur radius for Medium shadows\nUsed to compose $box-shadow-md\n",
            "default": "16px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-md-spread-radius",
            "description": "Configures the spread radius for Medium shadows\nUsed to compose $box-shadow-md\n",
            "default": "0px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-md-color",
            "description": "Configures the color for Medium shadows\nUsed to compose $box-shadow-md\n",
            "default": "rgba(0, 0, 0, 0.1)",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-md",
            "description": "Medium shadow - the default for shadows (read-only)\nComposed of the separate shadow properties: offset-x, offset-y, blur-radius, spread-radius and color\n",
            "default": "$box-shadow-md-offset-x $box-shadow-md-offset-y $box-shadow-md-blur-radius\n  $box-shadow-md-spread-radius $box-shadow-md-color",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-lg-offset-x",
            "description": "Configures the horizontal position of Large shadows\nUsed to compose $box-shadow-lg\n",
            "default": "0px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-lg-offset-y",
            "description": "Configures the vertical position of Large shadows\nUsed to compose $box-shadow-lg\n",
            "default": "15px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-lg-blur-radius",
            "description": "Configures the blur radius for Large shadows\nUsed to compose $box-shadow-lg\n",
            "default": "20px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-lg-spread-radius",
            "description": "Configures the spread radius for Large shadows\nUsed to compose $box-shadow-lg\n",
            "default": "0px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-lg-color",
            "description": "Configures the color for Large shadows\nUsed to compose $box-shadow-lg\n",
            "default": "rgba(0, 0, 0, 0.1)",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-lg",
            "description": "Large shadow (read-only)\nComposed of the separate shadow properties: offset-x, offset-y, blur-radius, spread-radius and color\n",
            "default": "$box-shadow-lg-offset-x $box-shadow-lg-offset-y $box-shadow-lg-blur-radius\n  $box-shadow-lg-spread-radius $box-shadow-lg-color",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-xl-offset-x",
            "description": "Configures the horizontal position of Exta Large shadows\nUsed to compose $box-shadow-xl\n",
            "default": "0px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-xl-offset-y",
            "description": "Configures the vertical position of Exta Large shadows\nUsed to compose $box-shadow-xl\n",
            "default": "32px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-xl-blur-radius",
            "description": "Configures the blur radius for Exta Large shadows\nUsed to compose $box-shadow-xl\n",
            "default": "32px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-xl-spread-radius",
            "description": "Configures the spread radius for Exta Large shadows\nUsed to compose $box-shadow-xl\n",
            "default": "0px",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-xl-color",
            "description": "Configures the color for Exta Large shadows\nUsed to compose $box-shadow-xl\n",
            "default": "rgba(0, 0, 0, 0.25)",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$box-shadow-xl",
            "description": "Exta Large shadow (read-only)\nComposed of the separate shadow properties: offset-x, offset-y, blur-radius, spread-radius and color\n",
            "default": "$box-shadow-xl-offset-x $box-shadow-xl-offset-y $box-shadow-xl-blur-radius\n  $box-shadow-xl-spread-radius $box-shadow-xl-color",
            "groups": [
                "shadow",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$sizer",
            "description": "Configures default padding used globally as sizer. This is used to calculate different sizers (xs, sm, md, lg, xl, xxl)\n",
            "default": "1rem",
            "groups": [
                "sizing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$sizer-xs",
            "description": "Extra Small padding (read-only). Is calculated as 25% of the default $sizer\n",
            "default": "$sizer * 0.25",
            "groups": [
                "sizing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$sizer-sm",
            "description": "Small padding (read-only). Is calculated as 50% of the default $sizer\nFor example, used for vertical button padding\n",
            "default": "$sizer * 0.5",
            "groups": [
                "sizing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$sizer-md",
            "description": "Medium padding (read-only). Is calculated as 50% of the default $sizer\nFor example, used for horizontal button padding\n",
            "default": "$sizer",
            "groups": [
                "sizing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$sizer-lg",
            "description": "Large padding. Is calculated as 1.5 * $sizer (read-only)\n",
            "default": "$sizer * 1.5",
            "groups": [
                "sizing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$sizer-xl",
            "description": "Extra Large padding (read-only). Is calculated as 2 * $sizer\n For example, used in the login widget\n",
            "default": "$sizer * 2",
            "groups": [
                "sizing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$sizer-xxl",
            "description": "Exta Extra Large padding (read-only). Is calculated as 2.5 * $sizer\nFor example, used in the empty/error state container\n",
            "default": "$sizer * 2.5",
            "groups": [
                "sizing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$spacer",
            "description": "Configures default margin used globally as spacer. This is used to calculate different spacers (xs, sm, md, lg, xl)\n",
            "default": "1rem",
            "groups": [
                "spacing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$spacer-xs",
            "description": "Extra Small margin used globally (read-only). Is calculated as 25% of the default $spacer\n",
            "default": "$spacer * 0.25",
            "groups": [
                "spacing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$spacer-sm",
            "description": "Small margin used globally (read-only). Is calculated as 50% of the default $spacer\n",
            "default": "$spacer * 0.5",
            "groups": [
                "spacing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$spacer-md",
            "description": "Medium margin (read-only). For example, used for margin between buttons.. Defaults to $spacer\n",
            "default": "$spacer",
            "groups": [
                "spacing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$spacer-lg",
            "description": "Large margin (read-only). Is calculated as 1.5 * $spacer\n",
            "default": "$spacer * 1.5",
            "groups": [
                "spacing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$spacer-xl",
            "description": "Extra Large padding (read-only). Is calculated as 2 * $spacer\nFor example, used as distance between widgets\n",
            "default": "$spacer * 2",
            "groups": [
                "spacing",
                "brand",
                "global"
            ]
        },
        {
            "variable": "$color-heading",
            "description": "Configures the color used for headings\n",
            "default": "$color-neutral-black",
            "groups": [
                "text-color",
                "typography",
                "global"
            ]
        }
    ],
    "local": [
        {
            "variable": "$account-selector-height",
            "description": "Configures the (minimum) hight of the account-selector\n",
            "default": "$sizer-md * 6",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$account-selector-dropdown-max-height",
            "description": "Configures the maximum height of the account-selector drop-down list\n",
            "default": "$sizer-md * 26.5",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$account-selector-border-radius",
            "description": "Configures the border-radius of both the account-selector and the drop-down list\n",
            "default": "$border-radius",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$account-selector-box-shadow",
            "description": "Configures the shadow for both the account-selector and the drop-down list\n",
            "default": "$box-shadow-sm",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$account-selector-option-checked-background-color",
            "description": "Configures the background-color for selected accounts in the account-selector drop-down list\n",
            "default": "$color-primary-lightest",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$account-selector-option-focus-background-color",
            "description": "Configures the background-color for focused accounts in the account-selector drop-down list\n",
            "default": "$color-neutral-lightest",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$account-selector-option-separator-height",
            "description": "Configures the height of the separator-border between account-selector drop-down options\n",
            "default": "$border-width",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$account-selector-dropdown-spacer",
            "description": "Configures the space between the account-selector and the drop-down list\n",
            "default": "$spacer * 1.25",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$account-selector-icon-background-color",
            "description": "Configures the background color of account-selector icons\n",
            "default": "$color-secondary",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$account-selector-icon-color",
            "description": "Configures the icon color of account-selector icons\n",
            "default": "$color-neutral-white",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$card-vendor-map",
            "description": "Configures the card vendor images used by the card-vendor component\nIt uses a Sass map ([key: card-name]: ( image: [url-to-svg-card-image], width: [a number], height: [a number]))\n",
            "default": "(\n  \"mastercard\": (\n    \"image\": url(#{$path-bb-images}/bb-card-vendor-ui/mastercard.svg),\n    \"width\": 39,\n    \"height\": 24,\n  ),\n  \"visa\": (\n    \"image\": url(#{$path-bb-images}/bb-card-vendor-ui/visa.svg),\n    \"width\": 80,\n    \"height\": 24,\n  ),\n  \"visa-debit\": (\n    \"image\": url(#{$path-bb-images}/bb-card-vendor-ui/visa-debit.svg),\n    \"width\": 48,\n    \"height\": 24,\n  ),\n  \"diners-club\": (\n    \"image\": url(#{$path-bb-images}/bb-card-vendor-ui/diners-club.svg),\n    \"width\": 31,\n    \"height\": 24,\n  ),\n  \"maestro\": (\n    \"image\": url(#{$path-bb-images}/bb-card-vendor-ui/maestro.svg),\n    \"width\": 39,\n    \"height\": 24,\n  ),\n  \"union-pay\": (\n    \"image\": url(#{$path-bb-images}/bb-card-vendor-ui/union-pay.svg),\n    \"width\": 38,\n    \"height\": 24,\n  ),\n  \"visa-white\": (\n    \"image\": url(#{$path-bb-images}/bb-card-vendor-ui/visa-white.svg),\n    \"width\": 80,\n    \"height\": 24,\n  ),\n)",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-color",
            "description": "Configures the color for the payment-card\n",
            "default": "$color-neutral-white",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-box-shadow",
            "description": "Configures the shadow for the payment-card\n",
            "default": "$box-shadow-sm",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-border-radius",
            "description": "Configures the border-radius for the payment-card\n",
            "default": "$border-radius",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-number-font-weight",
            "description": "Configures the font weight for the payment card number\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-number-font-size",
            "description": "Configures the font size for the payment card number\n",
            "default": "$font-size-highlight",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-name-font-size",
            "description": "Configures the font size of the payment card name\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-name-font-weight",
            "description": "Configures the font-weight of the payment card name\n",
            "default": "$font-weight-semi-bold",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-label-font-size",
            "description": "Configures the font size of the payment-card experition label\n",
            "default": "$font-size-subheader",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-label-font-weight",
            "description": "Configures the font weight of the payment-card expiration label\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-expiration-date-font-weight",
            "description": "Configures the font weight of the payment-card expiration date\n",
            "default": "$font-weight-semi-bold",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-expiration-date-font-size",
            "description": "Configures the font size of the payment-card expiration date\n",
            "default": "$font-size-subtitle",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-min-width",
            "description": "Configures the minimum width of the payment-card\n",
            "default": "17.375rem",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-max-width",
            "description": "Configures the maximum width of the payment-card\n",
            "default": "20rem",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$payment-card-custom-background-map",
            "description": "Configures a map of custom colors ([name]: [background value]), use existing keys to override the colors\nTo add new colors, keys can be added, but those need to be added in the html with corresponding class--modifiers as well\n",
            "default": "()",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-title-color",
            "description": "Configures color of the product-item\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-title-font-weight",
            "description": "Configures the font weight of the product-item title\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-title-font-size",
            "description": "Configures the font size of the product-item title\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-account-info-product-number-color",
            "description": "Configures the color of the product-item account-info product-number\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-account-info-product-number-font-weight",
            "description": "Configures the font weight of the product-item account-info product-number\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-account-info-product-number-font-size",
            "description": "Configures the font size of the product-item account-info product-number\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-amount-color",
            "description": "Configures the color of the product-item amount\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-amount-font-weight",
            "description": "Configures the font weight of the product-item amount\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-amount-font-size",
            "description": "Configures the font-size of the product-item amount\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$product-item-owners-color",
            "description": "Configures the color of the product-item owners\n",
            "default": "$optional",
            "groups": [
                "banking",
                "local"
            ]
        },
        {
            "variable": "$button-padding-horizontal",
            "description": "Configures the horizontal button padding\n",
            "default": "$sizer-lg",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-padding-vertical",
            "description": "Configures the vertical button padding\n",
            "default": "$sizer-sm",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-font-size",
            "description": "Configures the button font size\n",
            "default": "$font-size-default",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-font-weight",
            "description": "Configures the button font weight\n",
            "default": "$font-weight-regular",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-disabled-opacity",
            "description": "Configures the button:disabled opacity\n",
            "default": "1",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-border-width",
            "description": "Configures the button border width\n",
            "default": "$border-width",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-border-radius",
            "description": "Configures the button border radius\n",
            "default": "$border-radius-pill",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-box-shadow-offset-x",
            "description": "Configures the button shadow offset-x\nUsed to compose $button-box-shadow\n",
            "default": "0",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-box-shadow-offset-y",
            "description": "Configures the button shadow offset-y\nUsed to compose $button-box-shadow\n",
            "default": "0",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-box-shadow-blur-radius",
            "description": "Configures the button shadow blur-radius\nUsed to compose $button-box-shadow\n",
            "default": "0",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-box-shadow-spread-radius",
            "description": "Configures the button shadow spread-radius\nUsed to compose $button-box-shadow\n",
            "default": "0",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-box-shadow-color",
            "description": "Configures the button shadow color\nUsed to compose $button-box-shadow\n",
            "default": "$color-neutral-white",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-box-shadow",
            "description": "Button shadow (read-only)\nComposed of the separate shadow properties: offset-x, offset-y, blur-radius, spread-radius and color\n",
            "default": "$button-box-shadow-offset-x $button-box-shadow-offset-y\n  $button-box-shadow-blur-radius $button-box-shadow-spread-radius $button-box-shadow-color",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-active-box-shadow-offset-x",
            "description": " Configures the button:active shadow offset-x\n Used to compose $button-box-shadow\n",
            "default": "$focus-box-shadow-offset-x",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-active-box-shadow-offset-y",
            "description": " Configures the button:active shadow offset-y\n Used to compose $button-box-shadow\n",
            "default": "$focus-box-shadow-offset-y",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-active-box-shadow-blur-radius",
            "description": " Configures the button:active shadow blur-radius\n Used to compose $button-box-shadow\n",
            "default": "$focus-box-shadow-blur-radius",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-active-box-shadow-spread-radius",
            "description": " Configures the button:active shadow spread-radius\n Used to compose $button-box-shadow\n",
            "default": "$focus-box-shadow-spread-radius",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-active-box-shadow-color",
            "description": " Configures the button:active shadow color\n Used to compose $button-box-shadow\n",
            "default": "$focus-box-shadow-color",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-active-box-shadow",
            "description": "Button:active shadow (read-only)\nComposed of the separate shadow properties: offset-x, offset-y, blur-radius, spread-radius and color\n",
            "default": "$button-active-box-shadow-offset-x $button-active-box-shadow-offset-y\n  $button-active-box-shadow-blur-radius $button-active-box-shadow-spread-radius\n  $button-active-box-shadow-color",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-color-transition",
            "description": "Configures the button color-transition (experimental)\nUsed to configure the default $button-transition\n",
            "default": "color $motion-duration-default $motion-timing-ease-in-out",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-background-color-transition",
            "description": "Configures the button background-color-transition (experimental)\nUsed to configure the default $button-transition\n",
            "default": "background-color $motion-duration-default\n  $motion-timing-ease-in-out",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-border-color-transition",
            "description": "Configures the button border-color-transition (experimental)\nUsed to configure the default  $button-transition\n",
            "default": "border-color $motion-duration-default $motion-timing-ease-in-out",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-box-shadow-transition",
            "description": "Configures the button shadow-transition (experimental)\nUsed to configure the default $button-transition\n",
            "default": "box-shadow $motion-duration-default $motion-timing-ease-in-out",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$button-transition",
            "description": "Configures all transitions used for button transitions (experimental)\nDefaults to transitions with $button-color-transition, $button-background-color-transition, $button-border-color-transition and $button-box-shadow-transition\n",
            "default": "$button-color-transition, $button-background-color-transition,\n  $button-border-color-transition, $button-box-shadow-transition",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-custom-category-color",
            "description": "Configures the custom-category icon color\n",
            "default": "$color-secondary",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-btn-padding",
            "description": "Configures the padding for font-icons\n",
            "default": "0.5em",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-scale-sm",
            "description": "Configures the scale for Small icons\n",
            "default": "2/3",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-scale-md",
            "description": "Default scale for Medium icons (read-only). To configure the global icon-size use $font-icon-size instead\n",
            "default": "1",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-scale-lg",
            "description": "Configures the scale for Large icons\n",
            "default": "4/3",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-scale-xl",
            "description": "Configures the scale for Extra Large icons\n",
            "default": "5/3",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-scale-xxl",
            "description": "Configures the scale for Extra Extra Large icons\n",
            "default": "3",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-scale-circle-sm",
            "description": "Configures the scale for Small round icons\n",
            "default": "1.6",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-scale-circle-md",
            "description": "Configures the scale for Medium  round icons\n",
            "default": "2",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-contrast-color",
            "description": "Configures the contrasting icon color for icons with a background-color\n",
            "default": "$color-neutral-white",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$icon-square-border-radius",
            "description": "Configures the border-radius for square icons, has some roundness by default\n",
            "default": "$border-radius-sm",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-size",
            "description": "Configures the default icon size\n",
            "default": "1.5rem",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-size-sm",
            "description": "Icon size sm (read-only)\nComposed of $font-icon-size * $icon-scale-sm\n",
            "default": "$font-icon-size * $icon-scale-sm",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-size-md",
            "description": "Icon size md (read-only)\nComposed of $font-icon-size * $icon-scale-md\n",
            "default": "$font-icon-size * $icon-scale-md",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-size-lg",
            "description": "Icon size lg (read-only)\nComposed of $font-icon-size * $icon-scale-lg\n",
            "default": "$font-icon-size * $icon-scale-lg",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-size-xl",
            "description": "Icon size xl (read-only)\nComposed of $font-icon-size * $icon-scale-xl\n",
            "default": "$font-icon-size * $icon-scale-xl",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-size-xxl",
            "description": "Icon size xxl (read-only)\nComposed of $font-icon-size * $icon-scale-xxl\n",
            "default": "$font-icon-size * $icon-scale-xxl",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-circle-size-sm",
            "description": "Round icon size sm (read-only)\nComposed of $font-icon-size * $icon-scale-circle-sm\n",
            "default": "$font-icon-size * $icon-scale-circle-sm",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-circle-size-md",
            "description": "Round icon size md (read-only)\nComposed of $font-icon-size * $icon-scale-circle-md\n",
            "default": "$font-icon-size * $icon-scale-circle-md",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-background-size",
            "description": "Configures the icon background size\n",
            "default": "2.5rem",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-background-size-sm",
            "description": "Icon background size sm (read-only)\nComposed of $font-icon-background-size * $icon-scale-sm\n",
            "default": "$font-icon-background-size * $icon-scale-sm",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-background-size-md",
            "description": "Icon background size md (read-only)\nComposed of $font-icon-background-size * $icon-scale-md\n",
            "default": "$font-icon-background-size * $icon-scale-md",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-background-size-lg",
            "description": "Icon background size lg (read-only)\nComposed of $font-icon-background-size * $icon-scale-lg\n",
            "default": "$font-icon-background-size * $icon-scale-lg",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-background-size-xl",
            "description": "Icon background size xl (read-only)\nComposed of $font-icon-background-size * $icon-scale-xl\n",
            "default": "$font-icon-background-size * $icon-scale-xl",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$font-icon-background-size-xxl",
            "description": "Icon background size xxl (read-only)\nComposed of $font-icon-background-size * $icon-scale-xxl\n",
            "default": "$font-icon-background-size * $icon-scale-xxl",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$enable-outlined-icons",
            "description": "Configures the toggle for outlining icons. Defaults to true\nWhen false, it will use the original icon-map definitions\n",
            "default": "true",
            "groups": [
                "base",
                "local"
            ]
        },
        {
            "variable": "$search-box-border-radius",
            "description": "Configures the border-radius for the search box\n",
            "default": "$border-radius-pill",
            "groups": [
                "forms",
                "local"
            ]
        },
        {
            "variable": "$search-box-box-shadow",
            "description": "Configures the boxshadow for the .bb-search-box. Defaults to $input-box-shadow\n",
            "default": "$input-box-shadow",
            "groups": [
                "forms",
                "local"
            ]
        },
        {
            "variable": "$search-box-padding-horizontal",
            "description": "Configures the horizontal padding for the search box\n",
            "default": "$spacer-xs",
            "groups": [
                "forms",
                "local"
            ]
        },
        {
            "variable": "$search-box-button-padding-horizontal",
            "description": "Configures the horizontal padding for the search box button\n",
            "default": "$spacer-md",
            "groups": [
                "forms",
                "local"
            ]
        },
        {
            "variable": "$search-box-border",
            "description": "Configures the border for search-box. Defaults to $input-border\n",
            "default": "$input-border",
            "groups": [
                "forms",
                "local"
            ]
        },
        {
            "variable": "$search-box-button-color",
            "description": "Configures the color of the search-box buttons\n",
            "default": "$color-neutral-dark",
            "groups": [
                "forms",
                "local"
            ]
        },
        {
            "variable": "$search-box-button-background-color",
            "description": "Configures the background-color of the search box buttons\n",
            "default": "$color-neutral-white",
            "groups": [
                "forms",
                "local"
            ]
        },
        {
            "variable": "$input-background-color",
            "description": "Configures the background color for inputs\n",
            "default": "$color-neutral-white",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-color",
            "description": "Configures the color for inputs\n",
            "default": "$color-neutral-black",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-placeholder-color",
            "description": "Configures the placeholder color for inputs\n",
            "default": "$color-text-support",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-border-width",
            "description": "Configures the border width for inputs\nUsed to compose $input-border\n",
            "default": "$border-width",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-border-style",
            "description": "Configures the border style for inputs\nUsed to compose $input-border\n",
            "default": "$border-style",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-border-color",
            "description": "Configures the border color for inputs\nUsed to compose $input-border\n",
            "default": "$border-color",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-border",
            "description": "Form input border (read-only)\nComposed of the separate border properties: width, style, color\n",
            "default": "$input-border-width $input-border-style $input-border-color",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-border-radius",
            "description": "Configures the border radius for inputs\n",
            "default": "$border-radius-sm",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-padding-horizontal",
            "description": "Configures the horizontal padding for inputs\n",
            "default": "$sizer-md",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-padding-vertical",
            "description": "Configures the vertical padding for inputs\n",
            "default": "$sizer-sm",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-padding",
            "description": "Configures the padding for inputs\nComposed of separate padding properties: input-padding-vertical and input-padding-horizontal\n",
            "default": "$input-padding-vertical $input-padding-horizontal",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-font-size",
            "description": "Configures the font size for inputs\n",
            "default": "$font-size-default",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-font-weight",
            "description": "Configures the font weight for inputs\n",
            "default": "$font-weight-regular",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-box-shadow",
            "description": "Configures the shadow for inputs. Defaults to none\nUse one of the $shadow variables to configure consistantly\n",
            "default": "none",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-focus-border-color",
            "description": "Configures the input:focus border color. Defaults to $focus-border-color\n",
            "default": "$focus-border-color",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-focus-border-width",
            "description": "Configures the input:focus border width. Defaults to $input-border-width\n",
            "default": "$input-border-width",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-focus-border-style",
            "description": "Configures the input:focus border width. Defaults to $input-border-width\n",
            "default": "$input-border-style",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-focus-border",
            "description": "Form input:focus border (read-only)\nComposed of the separate border properties: width, style and color\n",
            "default": "$input-focus-border-width $input-focus-border-style $input-focus-border-color",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-focus-box-shadow",
            "description": "Configures the input:focus shadow. Defaults to $focus-box-shadow\n",
            "default": "$focus-box-shadow",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-invalid-border-color",
            "description": "Configures the input:invalid border color. Defaults to $color-danger\n",
            "default": "$color-danger",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-invalid-border-width",
            "description": "Configures the input:invalid border width. Defaults to $input-border-width\n",
            "default": "$input-border-width",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-invalid-border-style",
            "description": "Configures the input:invalid border style. Defaults to $input-border-style\n",
            "default": "$input-border-style",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-invalid-border",
            "description": "Form input:invalid border (read-only)\nComposed of the separate border properties: width, style and color\n",
            "default": "$input-invalid-border-width $input-invalid-border-style\n  $input-invalid-border-color",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-disabled-background-color",
            "description": "Configures the input:disabled background color\n",
            "default": "$color-neutral-lightest",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-disabled-placeholder-color",
            "description": "Configures the input:disabled placeholder color\n",
            "default": "$color-neutral-greyest",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$input-disabled-color",
            "description": "Configures the input:disabled color\n",
            "default": "$color-neutral-dark",
            "groups": [
                "input",
                "local"
            ]
        },
        {
            "variable": "$navigation-header-color",
            "description": "Configures the header-color for bb-navigation\n",
            "default": "inherit",
            "groups": [
                "navigation",
                "local"
            ]
        },
        {
            "variable": "$navigation-item-border-radius",
            "description": "Configures the border-radius for bb-navigation items\n",
            "default": "$border-radius-sm",
            "groups": [
                "navigation",
                "local"
            ]
        },
        {
            "variable": "$pagination-padding-horizontal",
            "description": "Configures the horizontal padding for pagination\n",
            "default": "$sizer-xs",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-padding-vertical",
            "description": "Configures the vertical padding for pagination\n",
            "default": "0",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-line-height",
            "description": "Configures the pagination line-height\n",
            "default": "$sizer-lg",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-border-width",
            "description": "Configures the pagination border-width\n",
            "default": "0",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-border-radius",
            "description": "Configures the pagination border-radius\n",
            "default": "$sizer-sm",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-border-color",
            "description": "Configures the pagination border-color\n",
            "default": "transparent",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-color",
            "description": "Configures the pagination color\n",
            "default": "$color-primary",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-background-color",
            "description": "Configures the pagination background-color\n",
            "default": "$color-neutral-white",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-disabled-background-color",
            "description": "Configures the pagination:disabled background-color. Defaults to $pagination-background-color\n",
            "default": "$pagination-background-color",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-disabled-color",
            "description": "Configures the pagination:disabled color\n",
            "default": "$color-neutral-grey",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-disabled-border-color",
            "description": "Configures the pagination:disabled border-color. Defaults to $pagination-disabled-color\n",
            "default": "$pagination-disabled-color",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-active-background-color",
            "description": "Configures the pagination:active background-color\n",
            "default": "$color-primary-lightest",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-active-border-color",
            "description": "Configures the pagination:active border-color. Defaults to $pagination-active-background-color\n",
            "default": "$pagination-active-background-color",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$pagination-active-color",
            "description": "Configures the pagination:active color\n",
            "default": "$color-info-darkest",
            "groups": [
                "pagination",
                "local"
            ]
        },
        {
            "variable": "$table-cell-padding-horizontal",
            "description": "Configures the default padding of table cells\n",
            "default": "$sizer-sm",
            "groups": [
                "table",
                "local"
            ]
        },
        {
            "variable": "$table-cell-padding-vertical",
            "description": "Configures the default padding of table cells\n",
            "default": "$sizer-sm",
            "groups": [
                "table",
                "local"
            ]
        },
        {
            "variable": "$table-heading-padding-horizontal",
            "description": "Configures the default horizontal padding of table headers\n",
            "default": "$sizer-md",
            "groups": [
                "table",
                "local"
            ]
        },
        {
            "variable": "$table-heading-padding-vertical",
            "description": "Configures the default vertical padding of table headers\n",
            "default": "$sizer-md",
            "groups": [
                "table",
                "local"
            ]
        },
        {
            "variable": "$table-font-size",
            "description": "Configures the font size for tables. Defaults to $font-size-table-default\n",
            "default": "$font-size-table-default",
            "groups": [
                "table",
                "local"
            ]
        },
        {
            "variable": "$empty-state-container-max-width",
            "description": "Configures the maximum width of the state container\n",
            "default": "$sizer * 25",
            "groups": [
                "ui-components",
                "local"
            ]
        },
        {
            "variable": "$empty-state-container-min-width",
            "description": "Configures the minimum width of the state container\n",
            "default": "$optional",
            "groups": [
                "ui-components",
                "local"
            ]
        },
        {
            "variable": "$empty-state-icon-color",
            "description": "Configures the color of the empty-state icon\n",
            "default": "$color-neutral-darker",
            "groups": [
                "ui-components",
                "local"
            ]
        },
        {
            "variable": "$empty-state-title-color",
            "description": "Configures the color of the empty-state title\n",
            "default": "$color-neutral-black",
            "groups": [
                "ui-components",
                "local"
            ]
        },
        {
            "variable": "$empty-state-title-font-weight",
            "description": "Configures the font weight of the empty-state title\n",
            "default": "$font-weight-semi-bold",
            "groups": [
                "ui-components",
                "local"
            ]
        },
        {
            "variable": "$empty-state-title-font-size",
            "description": "Configures the font size of the empty-state title\n",
            "default": "$optional",
            "groups": [
                "ui-components",
                "local"
            ]
        },
        {
            "variable": "$empty-state-message-color",
            "description": "Configures the color of the empty-state message\n",
            "default": "$color-neutral-dark",
            "groups": [
                "ui-components",
                "local"
            ]
        },
        {
            "variable": "$empty-state-message-font-size",
            "description": "Configures the font size of the empty-state message\n",
            "default": "$font-size-subtitle",
            "groups": [
                "ui-components",
                "local"
            ]
        },
        {
            "variable": "$empty-state-message-font-weight",
            "description": "Configures the font weight of the empty-state message\n",
            "default": "$optional",
            "groups": [
                "ui-components",
                "local"
            ]
        },
        {
            "variable": "$zindex-dropdown",
            "description": "Z-index for drop-down components (read-only)\n",
            "default": "1000",
            "groups": [
                "undefined"
            ]
        },
        {
            "variable": "$zindex-sticky",
            "description": "Z-index of sticky components (read-only)\n",
            "default": "1020",
            "groups": [
                "undefined"
            ]
        },
        {
            "variable": "$zindex-fixed",
            "description": "Z-index of fixed components (read-only)\n",
            "default": "1030",
            "groups": [
                "undefined"
            ]
        },
        {
            "variable": "$zindex-modal-backdrop",
            "description": "Z-index for the backdrop of modal components (read-only)\n",
            "default": "1040",
            "groups": [
                "undefined"
            ]
        },
        {
            "variable": "$zindex-modal",
            "description": "Z-index for modal components (read-only)\n",
            "default": "1050",
            "groups": [
                "undefined"
            ]
        },
        {
            "variable": "$zindex-popover",
            "description": "Z-index of pop-over components (read-only)\n",
            "default": "1060",
            "groups": [
                "undefined"
            ]
        },
        {
            "variable": "$zindex-tooltip",
            "description": "Z-index of tooltip components (read-only)\n",
            "default": "1070",
            "groups": [
                "undefined"
            ]
        },
        {
            "variable": "$heading-widget-background",
            "description": "Configures the background for the bb-heading-widget. Uses a linear gradient by default\n",
            "default": "linear-gradient(\n    -135deg,\n    rgba($color-neutral-white, 0.05) 0%,\n    rgba($color-neutral-black, 0.05) 100%\n  ),\n  linear-gradient(33.7deg, rgb(212, 237, 255) 0%, $color-neutral-white 100%)",
            "groups": [
                "universal-collection"
            ]
        },
        {
            "variable": "$heading-widget-color",
            "description": "Configures the color for the bb-heading-widget heading\n",
            "default": "$color-neutral-black",
            "groups": [
                "universal-collection"
            ]
        },
        {
            "variable": "$page-layout-topbar-height",
            "description": "Configures the height of the page-layout-topbar\n",
            "default": "4.5rem",
            "groups": [
                "universal-collection",
                "local"
            ]
        }
    ]
}
