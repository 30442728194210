import { Pipe, PipeTransform } from '@angular/core';
import { AlertType } from './alert.constants';

@Pipe({ name: 'alertType' })
export class AlertTypePipe implements PipeTransform {
  transform(alertModifier: AlertType) {
    switch (alertModifier) {
      case 'error':
        return 'danger';

      case 'success':
        return 'success';

      case 'info':
        return 'info';

      case 'warning':
        return 'warning';

      default:
        return alertModifier;
    }
  }
}
