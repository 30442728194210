import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'bb-common-error-state-ui-showcase',
  templateUrl: 'common-error-state-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class CommonErrorStateShowcaseComponent {
  getErrorFromString(errorType: string) {
    switch (errorType) {
      case 'badRequestError':
        return new HttpErrorResponse({ status: 400 });
      case 'unauthorizedError':
        return new HttpErrorResponse({ status: 401 });
      case 'forbiddenError':
        return new HttpErrorResponse({ status: 403 });
      case 'notFoundError':
        return new HttpErrorResponse({ status: 404 });
      case 'internalServerError':
        return new HttpErrorResponse({ status: 500 });
      case 'connectivityError':
        return new HttpErrorResponse({ status: 0 });
      case 'textError':
      default:
        return 'Some text error';
    }
  }
}
