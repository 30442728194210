import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

@Component({
  selector: 'bb-dropdown-multi-select-example-ui',
  templateUrl: './dropdown-multi-select.example.component.html',
})
export class DropdownMultiSelectExampleComponent {
  form: UntypedFormGroup = this.formBuilder.group({
    days: new UntypedFormControl(['1', '2', '3', '4', '5']),
  });

  constructor(private formBuilder: UntypedFormBuilder) {}
}
