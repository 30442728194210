import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BadgeModule } from '@backbase/ui-ang/badge';
import { ButtonModule } from '@backbase/ui-ang/button';
import { DropdownMenuModule } from '@backbase/ui-ang/dropdown-menu';
import { IconModule } from '@backbase/ui-ang/icon';
import { ModeHeaderModule } from '@backbase/ui-ang/mode-header';
import { ModeHeaderShowcaseComponent } from './mode-header-showcase.component';

const uiModules = [ModeHeaderModule, IconModule, BadgeModule, DropdownMenuModule, ButtonModule];

@NgModule({
  imports: [CommonModule, ...uiModules],
  declarations: [ModeHeaderShowcaseComponent],
})
export class ModeHeaderShowcaseModule {}
