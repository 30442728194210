import { NgModule } from '@angular/core';

import { ErrorCommonStateComponent } from './common-error-state.component';
import { EmptyStateModule } from '@backbase/ui-ang/empty-state';
import { NgTemplateOutlet } from '@angular/common';

@NgModule({
  imports: [EmptyStateModule, NgTemplateOutlet],
  declarations: [ErrorCommonStateComponent],
  exports: [ErrorCommonStateComponent],
})
export class ErrorCommonStateModule {}
