import { Component } from '@angular/core';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-tooltip-ui-showcase',
  templateUrl: './tooltip-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class TooltipShowcaseComponent {}
