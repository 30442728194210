import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDateDividedShowcaseComponent } from './input-date-divided-showcase.component';
import { InputDateDividedModule } from '@backbase/ui-ang/input-date-divided';
import { InputValidationMessageModule } from '@backbase/ui-ang/input-validation-message';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlErrorHandlerModule } from '@backbase/ui-ang/control-error-handler';
@NgModule({
  imports: [
    CommonModule,
    InputDateDividedModule,
    InputValidationMessageModule,
    ReactiveFormsModule,
    ControlErrorHandlerModule,
  ],
  declarations: [InputDateDividedShowcaseComponent],
})
export class InputDateDividedShowcaseModule {}
