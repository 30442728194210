import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { ProductItemBaseComponent } from '@backbase/ui-ang/base-classes';

@Directive({ selector: 'ng-template[bbCustomProductItemTermDepositTitle]' })
export class CustomProductItemTermDepositTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemTermDepositDetailsFormatter]' })
export class CustomProductItemTermDepositDetailsFormatterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[bbCustomProductItemTermDepositMainSection]' })
export class CustomProductItemTermDepositMainSectionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * @name ProductItemTermDepositComponent
 *
 * @description
 * A component that renders a styled-container or card for term deposit.
 */
@Component({
  selector: 'bb-product-item-term-deposit-ui',
  templateUrl: './product-item-term-deposit.component.html',
})
export class ProductItemTermDepositComponent extends ProductItemBaseComponent {
  @ContentChild(CustomProductItemTermDepositTitleDirective, { static: true })
  customProductItemTermDepositTitle?: CustomProductItemTermDepositTitleDirective;

  @ContentChild(CustomProductItemTermDepositDetailsFormatterDirective, { static: true })
  customProductItemTermDepositDetailsFormatter?: CustomProductItemTermDepositDetailsFormatterDirective;

  @ContentChild(CustomProductItemTermDepositMainSectionDirective, { static: true })
  customProductItemTermDepositMainSection?: CustomProductItemTermDepositMainSectionDirective;

  readonly hostRef: ProductItemTermDepositComponent = this;
  /**
   * The card currency.
   */
  @Input() currency?: string;
  /**
   * The card amount value.
   */
  @Input() amount?: number;
  /**
   * The card additional main section currency.
   */
  @Input() mainSectionCurrency?: string;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionAmount?: number;
  /**
   * The card additional main section amount value.
   */
  @Input() mainSectionTitle?: string;
}
