<div class="bb-empty-state" role="status">
  @if (!preset) {
    <bb-illustration-ui [name]="image" [alt]="''" size="sm" [priority]="true"></bb-illustration-ui>
  } @else {
    <bb-illustration-ui
      [name]="preset | presetIllustration"
      [alt]="''"
      size="sm"
      [priority]="true"
    ></bb-illustration-ui>
  }
  <div class="bb-empty-state__content">
    <p class="bb-empty-state__title" data-role="empty-state-title">
      @if (!preset) {
        {{ title }}
      } @else {
        {{ preset | presetTitle }}
      }
    </p>
    @if (!preset) {
      @if (!!subtitle) {
        <p class="bb-empty-state__message" data-role="empty-state-message" [innerHTML]="subtitle"></p>
      }
    } @else {
      <p class="bb-empty-state__message" data-role="empty-state-message" [innerHTML]="preset | presetSubtitle"></p>
    }
  </div>
  @if (preset || showPrimaryAction || showSecondaryAction) {
    <div class="bb-empty-state__footer">
      @if (!preset) {
        @if (showPrimaryAction) {
          <button bbButton color="primary" (click)="primaryActionClick.emit()">
            @if (primaryActionText) {
              {{ primaryActionText }}
            } @else {
              <ng-container *ngTemplateOutlet="primaryBtnTemplate"></ng-container>
            }
          </button>
        }

        @if (showSecondaryAction) {
          <button bbButton color="secondary" (click)="secondaryActionClick.emit()">
            @if (secondaryActionText) {
              {{ secondaryActionText }}
            } @else {
              <ng-container *ngTemplateOutlet="secondaryBtnTemplate"></ng-container>
            }
          </button>
        }
      } @else {
        <button bbButton color="primary" (click)="primaryActionClick.emit()">
          {{ preset | presetPrimaryActionText }}
        </button>
      }
    </div>
  }
</div>
