<form [formGroup]="vForm">
  <div class="mb-3">
    <h4 id="dropdownSingleSelect">Default with list of string as options</h4>
    <bb-dropdown-single-select-ui
      [aria-labelledby]="'dropdownSingleSelect'"
      [options]="stringDropdownOptions"
      formControlName="stringDropdownOptions"
      [required]="false"
      [attr.data-role]="'dropdown-single-select'"
    ></bb-dropdown-single-select-ui>
  </div>

  <div class="mb-3">
    <h4 id="dropdownSingleSelectObject">Default with list of object as options</h4>
    <bb-dropdown-single-select-ui
      [aria-labelledby]="'dropdownSingleSelectObject'"
      [options]="objectDropdownOptions"
      displayAttributePath="title"
      formControlName="objectDropdownOptions"
      [required]="false"
      [attr.data-role]="'dropdown-single-select'"
    ></bb-dropdown-single-select-ui>
  </div>

  <div class="mb-3">
    <h4>With a label</h4>
    <div class="form-group">
      <bb-dropdown-single-select-ui
        [id]="'dropdownSingleSelectWithLabel'"
        label="Label"
        [options]="stringDropdownOptions"
        formControlName="stringDropdownOptions"
        [required]="false"
        [attr.data-role]="'dropdown-single-select-with-label'"
      ></bb-dropdown-single-select-ui>
    </div>
  </div>

  <div class="mb-3">
    <h4 id="dropdownSingleSelectI18n">Translatable options</h4>
    <div class="form-group">
      <bb-dropdown-single-select-ui
        [aria-labelledby]="'dropdownSingleSelectI18n'"
        displayAttributePath="label"
        formControlName="i18nSingleSelect"
      >
        <bb-dropdown-single-select-option-ui value="one" label="Option One"></bb-dropdown-single-select-option-ui>
        <bb-dropdown-single-select-option-ui value="two" label="Option Two"></bb-dropdown-single-select-option-ui>
      </bb-dropdown-single-select-ui>
    </div>
  </div>

  <div class="mb-3">
    <h4 id="dropdownSingleSelectFromModel">Validation error</h4>
    <div class="form-group">
      <bb-dropdown-single-select-ui
        [aria-labelledby]="'dropdownSingleSelectFromModel'"
        [aria-describedby]="'dropdownSingleSelectValidationError'"
        [options]="modelDropdownOptions"
        formControlName="modelSingleSelect"
        displayAttributePath="model.displayNames[0]"
        [required]="true"
        placeholder="- Select An Item -"
        [attr.data-role]="'dropdown-single-select-with-validation'"
      ></bb-dropdown-single-select-ui>
      <bb-input-validation-message-ui
        [showErrors]="!isFieldValid('modelSingleSelect')"
        [id]="'dropdownSingleSelectValidationError'"
      >
        @if (hasRequiredError('modelSingleSelect')) {
          <span> Field is required. </span>
        }
      </bb-input-validation-message-ui>
    </div>
  </div>

  <div class="mb-3">
    <h4 id="dropdownSingleSelectDisabled">Disabled</h4>
    <div class="form-group">
      <bb-dropdown-single-select-ui
        [aria-labelledby]="'dropdownSingleSelectDisabled'"
        [options]="modelDropdownOptions"
        formControlName="modelSingleSelectDisabled"
        displayAttributePath="model.displayNames[0]"
        [required]="true"
      ></bb-dropdown-single-select-ui>
    </div>
  </div>

  <div class="mb-3">
    <h4 id="dropdownSingleSelectPlaceholder">With placeholder</h4>
    <div class="form-group">
      <bb-dropdown-single-select-ui
        [aria-labelledby]="'dropdownSingleSelectPlaceholder'"
        [options]="assetClassesOptions"
        placeholder="All"
        displayAttributePath="name"
        formControlName="customSingleSelect"
        [attr.data-role]="'dropdown-single-select-with-placeholder'"
      ></bb-dropdown-single-select-ui>
    </div>
  </div>

  <div class="mb-3">
    <h4 id="dropdownSingleSelectPlaceholderDefault">With default option as placeholder</h4>
    <div class="form-group">
      <bb-dropdown-single-select-ui
        [aria-labelledby]="'dropdownSingleSelectPlaceholderDefault'"
        [options]="assetClassesOptions"
        [defaultOptionAsPlaceholder]="true"
        placeholder="All"
        displayAttributePath="name"
        formControlName="customSingleSelect"
      ></bb-dropdown-single-select-ui>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-12 col-md-4 mb-4">
      <bb-dropdown-single-select-ui
        [compareWithFn]="compareByValue"
        label="Frequency"
        displayAttributePath="value"
        formControlName="frequency"
        data-role="frequency-selector"
      >
        @for (frequency of frequencies; track $index) {
          <bb-dropdown-single-select-option-ui
            class="text-capitalize"
            [value]="frequency"
            [label]="frequency.value"
          ></bb-dropdown-single-select-option-ui>
        }
      </bb-dropdown-single-select-ui>
    </div>
  </div>
</form>
