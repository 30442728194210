<div class="paragraph">
<p>The <strong>International Bank Account Number</strong> (<strong>IBAN</strong>) is an internationally agreed system of identifying bank accounts across national borders to facilitate the communication and processing of cross border transactions with a reduced risk of transcription errors. Initially developed to facilitate payments within the European Union, it has been implemented by most European countries and numerous countries in the other parts of the world, mainly in the Middle East and in the Caribbean.</p>
</div>
<div class="paragraph">
<p>The IBAN consists of up to 34 alphanumeric characters:</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>A country code<br>
</strong>Two letter ISO code for the country</p>
</li>
<li>
<p><strong>Two check digits<br>
</strong>To enable a sanity check of the bank account number to confirm its integrity before submitting a transaction.</p>
</li>
<li>
<p><strong>Basic Bank Account Number (BBAN)<br>
</strong>Up to 30 alphanumeric characters that are country-specific</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<img src="assets/images/iban-format.png" alt="IBAN example from The Netherlands">
</div>
</div>
<div class="sect1">
<h2 id="_grouping_iban_digits">Grouping IBAN digits</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The IBAN should not contain spaces when transmitted electronically,<br>
however, when printed, it is expressed in groups of four characters<br>
separated by a single space, the last group being of variable length.</p>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 50%;">
<col style="width: 50%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top">Country</th>
<th class="tableblock halign-left valign-top">IBAN</th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">France</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">FR76 3000 6000 0112 3456 7890 189</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Belgium</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">BE71 0961 2345 6769</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Germany</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">DE91 1000 0000 0123 4567 89</p></td>
</tr>
</tbody>
</table>
</div>
</div>