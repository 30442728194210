import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoShowcaseComponent } from './logo-showcase.component';
import { LogoModule } from '@backbase/ui-ang/logo';

@NgModule({
  imports: [CommonModule, LogoModule],
  declarations: [LogoShowcaseComponent],
})
export class LogoShowcaseModule {}
