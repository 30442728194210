<div class="container d-block">
  <h5>Timepicker with 10 minute step and validation errors</h5>
  <form [formGroup]="vForm">
    <bb-input-timepicker-ui
      id="timePicker-validation"
      [readonly]="false"
      [minuteStep]="10"
      formControlName="time"
      [min]="min"
      [max]="max"
    >
    </bb-input-timepicker-ui>

    <bb-input-validation-message-ui [showErrors]="showErrors()">
      @if (hasError('minTime')) {
        <span>Time should be after {{ min }}</span>
      }
      @if (hasError('maxTime')) {
        <span>Time should not exceed {{ max }}</span>
      }
      @if (hasError('minMaxTime')) {
        <span>Time should be between {{ min }} and {{ max }}</span>
      }
    </bb-input-validation-message-ui>
  </form>
  <br />

  <h5>Readonly timepicker</h5>
  <bb-input-timepicker-ui id="timepicker-readonly" [readonly]="true"> </bb-input-timepicker-ui>
  <br />

  <h5>Timepicker with seconds and meridian</h5>
  <bb-input-timepicker-ui
    id="timepicker-meridian"
    [readonly]="false"
    [seconds]="true"
    clock="12h"
    [(ngModel)]="timeModel"
    [min]="min"
  >
  </bb-input-timepicker-ui>
</div>
