<form [formGroup]="form">
  <div class="bb-block bb-block--xl">
    <h2>Small</h2>
    <bb-search-box-ui
      formControlName="name"
      [showClear]="true"
      [showSearch]="true"
      placeholder="Search box with autocomplete. Start typing with letter 'J'"
      [typeaheadOptions]="typeaheadOptions"
      size="sm"
      [attr.data-role]="'searchbox-small'"
      [label]="'Default search box'"
      [id]="'default-search-box'"
    >
    </bb-search-box-ui>
  </div>
  <div class="bb-block bb-block--xl">
    <h2>Medium</h2>
    <div class="bb-block bb-block--lg">
      <bb-search-box-ui
        formControlName="name"
        [showClear]="true"
        [showSearch]="false"
        placeholder="Search box with autocomplete. Start typing with letter 'J'"
        [typeaheadOptions]="typeaheadOptions"
        [attr.data-role]="'searchbox-medium'"
        [label]="'Hide search button'"
        [id]="'hide-search-button'"
        [containerAriaLabel]="'Hide search button'"
      >
      </bb-search-box-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-search-box-ui
        formControlName="name"
        [showClear]="false"
        [showSearch]="true"
        placeholder="Search box with autocomplete. Start typing with letter 'J'"
        [typeaheadOptions]="typeaheadOptions"
        [attr.data-role]="'searchbox-medium'"
        [label]="'Hide clear button'"
        [id]="'hide-clear-button'"
        [containerAriaLabel]="'Hide clear button'"
      >
      </bb-search-box-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-search-box-ui
        formControlName="name"
        [showClear]="false"
        [showSearch]="false"
        placeholder="Search box with autocomplete. Start typing with letter 'J'"
        [typeaheadOptions]="typeaheadOptions"
        [attr.data-role]="'searchbox-medium'"
        [label]="'Hide clear and search button'"
        [id]="'hide-clear-and-search-button'"
        [containerAriaLabel]="'Hide search & clear button'"
      >
      </bb-search-box-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-search-box-ui
        formControlName="name"
        [showClear]="false"
        [showSearch]="false"
        placeholder="Search box with autocomplete. Start typing with letter 'J'"
        [typeaheadOptions]="typeaheadOptions"
        [disabled]="true"
        [attr.data-role]="'searchbox-disabled'"
        [label]="'Disabled search box'"
        [id]="'disabled-search-box'"
      >
      </bb-search-box-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-search-box-ui
        formControlName="name"
        [showClear]="true"
        [showSearch]="true"
        placeholder="Search box with autocomplete. Start typing with letter 'J'"
        [typeaheadOptions]="typeaheadOptionsWithTemplate"
        [attr.data-role]="'searchbox-with-autocomplete'"
        [label]="'Search box with custom template'"
        [id]="'search-box-with-custom-template'"
        [containerRole]="'presentation'"
      >
      </bb-search-box-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-search-box-ui
        formControlName="name"
        [showClear]="true"
        [showSearch]="true"
        [squareBorder]="true"
        placeholder="Search box with autocomplete. Start typing with letter 'J'"
        [typeaheadOptions]="typeaheadOptions"
        [attr.data-role]="'searchbox-medium'"
        [label]="'Search box with square borders and inner button'"
        [id]="'search-box-with-square-borders-and-inner-button'"
        [containerAriaLabel]="'Square borders and inner button'"
      >
      </bb-search-box-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-search-box-ui
        formControlName="name"
        [showSearch]="true"
        [squareBorder]="true"
        placeholder="Search box with autocomplete. Start typing with letter 'J'"
        [typeaheadOptions]="typeaheadOptions"
        [attr.data-role]="'searchbox-medium'"
        [label]="'Search box with square borders and search button only'"
        [id]="'search-box-with-square-borders-and-search-button-only'"
        [containerAriaLabel]="'Square borders and search button only'"
      >
      </bb-search-box-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-search-box-ui
        formControlName="name"
        [showClear]="true"
        [squareBorder]="true"
        placeholder="Search box with autocomplete. Start typing with letter 'J'"
        [typeaheadOptions]="typeaheadOptions"
        [attr.data-role]="'searchbox-medium'"
        [label]="'Search box with square borders and clear button only'"
        [id]="'search-box-with-square-borders-and-clear-button-only'"
        [containerAriaLabel]="'Square borders and clear button only'"
      >
      </bb-search-box-ui>
    </div>
  </div>
</form>

<ng-template #rt let-r="result">
  <div class="p-2 w-50">
    <div class="fw-bold">{{ r.fullName }}</div>
    <div class="text-support">{{ r.legalEntityName }}</div>
  </div>
</ng-template>
