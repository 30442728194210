<!-- should display all 'general guideline' pages -->
<div class="doc-showcase-pages dso__layout" [class.dso__layout--content-right]="hideMiniMenu">
  <div class="dso__side doc-showcase-pages__side">
    <bb-side-menu></bb-side-menu>
  </div>

  <main #mainContent aria-labelledby="dso-page-heading" class="dso__content dso__content-docs">
    <router-outlet></router-outlet>
  </main>

  <aside #miniMenu class="dso__mini-menu">
    @if (contentHeadersElements$ | async; as contentHeadersElements) {
      @if (contentHeadersElements?.length) {
        <bb-mini-menu
          [contentReference]="mainContent"
          [pageContentHeaders]="contentHeadersElements"
          [miniMenuReference]="miniMenu"
        ></bb-mini-menu>
      }
    }
  </aside>
</div>
