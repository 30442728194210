import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressbarShowcaseComponent } from './progressbar-showcase.component';
import { ProgressbarModule } from '@backbase/ui-ang/progressbar';

@NgModule({
  imports: [CommonModule, ProgressbarModule],
  declarations: [ProgressbarShowcaseComponent],
})
export class ProgressbarShowcaseModule {}
