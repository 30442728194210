import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseClassesModule } from '@backbase/ui-ang/base-classes';
import { InputTextModule } from '@backbase/ui-ang/input-text';
import { InputDateDividedComponent } from './input-date-divided.component';
import { provideNgxMask } from 'ngx-mask';

/**
 * @name InputDateDividedModule
 *
 * @deprecated Module is deprecated as of ui-ang v11. It will be removed in ui-ang v13.
 */
@NgModule({
  imports: [FormsModule, BaseClassesModule, ReactiveFormsModule, InputTextModule],
  declarations: [InputDateDividedComponent],
  exports: [InputDateDividedComponent],
  providers: [provideNgxMask()],
})
export class InputDateDividedModule {}
