<div class="row">
  <div class="col-md-6">
    <form [formGroup]="passwordForm" novalidate (ngSubmit)="onSubmit(passwordForm)">
      <div class="bb-block bb-block--md">
        <bb-input-password-ui
          label="Password"
          [aria-describedby]="'input-password-error-1'"
          formControlName="inputPassword"
          [showVisibilityControl]="true"
          [maxLength]="5"
          [required]="true"
          placeholder="Please enter password..."
          [autocomplete]="'current-password'"
        >
        </bb-input-password-ui>
        <bb-input-validation-message-ui [showErrors]="!isFieldValid('inputPassword')" [id]="'input-password-error-1'">
          <span>Password is required.</span>
        </bb-input-validation-message-ui>
      </div>
      <div class="bb-block bb-block--md">
        <bb-input-password-ui
          label="Password with no visibility button"
          [aria-describedby]="'input-password-error-2'"
          formControlName="inputPasswordNoIcon"
          [showVisibilityControl]="false"
          [maxLength]="5"
          [required]="true"
          placeholder="Please enter password..."
          [autocomplete]="'current-password'"
        >
        </bb-input-password-ui>
        <bb-input-validation-message-ui
          [showErrors]="!isFieldValid('inputPasswordNoIcon')"
          [id]="'input-password-error-2'"
        >
          <span>Password is required.</span>
        </bb-input-validation-message-ui>
      </div>
    </form>
  </div>
</div>
