import { NgModule } from '@angular/core';

import {
  CustomProductItemDebitCardTitleDirective,
  ProductItemDebitCardComponent,
} from './product-item-debit-card.component';
import { AmountModule } from '@backbase/ui-ang/amount';
import { PaymentCardNumberModule } from '@backbase/ui-ang/payment-card-number-pipe';
import { IconModule } from '@backbase/ui-ang/icon';
import { NgTemplateOutlet } from '@angular/common';

@NgModule({
  imports: [AmountModule, PaymentCardNumberModule, IconModule, NgTemplateOutlet],
  declarations: [ProductItemDebitCardComponent, CustomProductItemDebitCardTitleDirective],
  exports: [ProductItemDebitCardComponent, CustomProductItemDebitCardTitleDirective],
})
export class ProductItemDebitCardModule {}
