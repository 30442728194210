import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FocusShowcaseComponent } from './focus-showcase.component';
import { FocusModule } from '@backbase/ui-ang/focus';
import { ButtonModule } from '@backbase/ui-ang/button';

@NgModule({
  imports: [CommonModule, FocusModule, ButtonModule],
  declarations: [FocusShowcaseComponent],
})
export class FocusShowcaseModule {}
