import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputRadioGroupComponent } from './input-radio-group.component';
import { InputRadioComponent } from './input-radio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FocusModule } from '@backbase/ui-ang/focus';

@NgModule({
  imports: [NgClass, NgTemplateOutlet, FormsModule, ReactiveFormsModule, FocusModule],
  declarations: [InputRadioGroupComponent, InputRadioComponent],
  exports: [InputRadioGroupComponent, InputRadioComponent],
})
export class InputRadioGroupModule {}
