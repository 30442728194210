export enum RichTextEditorActions {
  Bold = 'Bold',
  Italic = 'Italic',
  Strike = 'Strikethrough',
  Underline = 'Underline',
  OrderedList = 'Ordered List',
  UnorderedList = 'Unordered List',
  Code = 'Code',
  Link = 'Link',
  Indent = 'Paragraph',
  Image = 'Image',
}

export enum QuillEditorActionsAdapter {
  Bold = 'bold',
  Italic = 'italic',
  Strikethrough = 'strike',
  Underline = 'underline',
  /* eslint-disable @typescript-eslint/no-duplicate-enum-values */
  'Ordered List' = 'list',
  'Unordered List' = 'list',
  /* eslint-enable @typescript-eslint/no-duplicate-enum-values */
  Code = 'code',
  Link = 'link',
  Paragraph = 'indent',
  Image = 'image',
}

export interface TextEditorFormatGroupValue {
  key: string;
  translation: string;
  value?: string;
  hidden?: () => boolean;
}

export interface TextEditorFormatGroup {
  [key: string]: TextEditorFormatGroupValue[];
}
