import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BackbaseDocs } from '@backbase/ui-ang/util';

@Component({
  selector: 'bb-input-phone-ui-showcase',
  templateUrl: './input-phone-showcase.component.html',
})
@BackbaseDocs({
  isTheme2Stable: true,
})
export class InputPhoneShowcaseComponent implements OnInit, AfterViewInit {
  vForm: UntypedFormGroup | undefined;
  country = [
    {
      countryCode: '+91',
      isoCode: 'IN',
      countryName: 'India',
    },
  ];
  countries = [
    {
      countryCode: '+1',
      isoCode: 'US',
      countryName: 'United States',
    },
    {
      countryCode: '+1',
      isoCode: 'CA',
      countryName: 'Canada',
    },
    {
      countryCode: '+31',
      isoCode: 'NL',
      countryName: 'Netherlands',
    },
    {
      countryCode: '+34',
      isoCode: 'NZ',
      countryName: 'New Zealand',
    },
    {
      countryCode: '+355',
      isoCode: 'AL',
      countryName: 'Albania',
    },
    {
      countryCode: '+91',
      isoCode: 'IN',
      countryName: 'India',
    },
    {
      countryCode: '+55',
      isoCode: 'BR',
      countryName: 'Brazil',
    },
    {
      countryCode: '+420',
      isoCode: 'CZ',
      countryName: 'Czech Republic',
    },
    {
      countryCode: '+49',
      isoCode: 'DE',
      countryName: 'Germany',
    },
    {
      countryCode: '+1684',
      isoCode: 'AS',
      countryName: 'American Samoa',
    },
  ];
  private readonly defaultCountryCode = 'CA';

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.vForm = this.formBuilder.group({
      phoneNumberCountryCode: this.defaultCountryCode,
      phoneNumber: '',
      phoneNumber1: '',
      phoneNumber2: '',
    });
    this.vForm.controls.phoneNumber.setValue('16045555555');
    this.vForm.controls.phoneNumber1.setValue({
      countryCode: '+1',
      isoCode: 'CA',
      number: '16045555555',
    });
    this.vForm.controls.phoneNumber2.setValue('16045555555');
  }

  onValueChange(data: any): void {
    this.vForm?.patchValue({ phoneNumberCountryCode: data.isoCode });
  }

  ngAfterViewInit(): void {
    this.vForm?.controls.phoneNumberCountryCode.setValue(this.defaultCountryCode);
  }
}
