import { AccountStatus } from '@backbase/ui-ang/base-classes';

export interface AccountSelectorDefaultItem {
  id?: string;
  name?: string;
  balance?: number;
  availableBalance?: number;
  number?: string;
  currency?: string;
  favorite?: boolean;
  status?: AccountStatus;
  bankBranchCode?: string;
  type?: string;
}

/**
 * The type to describe rendering balances data option in the account selector view:
 * - `BalanceDetailsView.Booked` - only booked balance data is rendered, OOTB behavior;
 * - `BalanceDetailsView.Available` - only available balance data is rendered;
 * - `BalanceDetailsView.Ordinary` - available balance data is rendered as primary with label `Available`, booked balance as secondary with label `Balance`;
 * - `BalanceDetailsView.Reverse ` - booked balance data is rendered as primary with label `Balance`, available balance as secondary with label `Available`.
 */
export enum BalanceDetailsView {
  Booked = 'booked',
  Available = 'available',
  Ordinary = 'ordinary',
  Reverse = 'reverse',
}
