import { Pipe, PipeTransform } from '@angular/core';
import { EmptyStatePresetType, PRESETS } from './type';

@Pipe({
  name: 'presetTitle',
})
export class PresetTitlePipe implements PipeTransform {
  transform(value: EmptyStatePresetType) {
    const result = PRESETS[value]?.title;

    return result ?? $localize`:@@bb-empty-state-ui.bb-empty-state__title.unknown_error_personal:Something went wrong`;
  }
}
