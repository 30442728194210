<div class="row">
  <div class="col-3">
    <bb-dropdown-panel-ui>
      <ng-template bbDropdownToggle> Toggle without arrow </ng-template>
      <ng-template bbDropdownMenu>
        <div class="p-2">The content of dropdown</div>
      </ng-template>
    </bb-dropdown-panel-ui>
  </div>

  <div class="col-3">
    <bb-dropdown-panel-ui>
      <ng-template bbDropdownToggle let-isOpen="isOpen">
        <div class="d-flex align-items-center">
          Dropdown with arrow
          <bb-icon-ui [name]="isOpen ? 'toggle-up' : 'toggle-down'"></bb-icon-ui>
        </div>
      </ng-template>
      <ng-template bbDropdownMenu>
        <div class="p-2">The content of dropdown</div>
      </ng-template>
    </bb-dropdown-panel-ui>
  </div>
</div>
