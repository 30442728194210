import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[bbDropdownToggleFullWidth]',
})
export class DropdownMenuFullWidthDirective implements OnDestroy, AfterViewInit {
  private dropdownMenuChanges!: MutationObserver;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const dropdownBtn = this.elementRef.nativeElement.querySelector('[ngbDropdownToggle]');
    const dropdownMenu = this.elementRef.nativeElement.querySelector('[ngbDropdownMenu]');
    const splitBtnWrapper = dropdownBtn?.closest('div.btn-group');
    if (this.bbDropdownToggleFullWidth && dropdownBtn.attributes['aria-expanded'].value === 'true' && dropdownMenu) {
      this.setDropdownMenuWidth(splitBtnWrapper, dropdownBtn, dropdownMenu);
    }
  }
  /**
   * If true it will stretch the dropdown menu to 100% width
   */
  @Input() bbDropdownToggleFullWidth = false;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const dropdownBtn = this.elementRef.nativeElement.querySelector('[ngbDropdownToggle]');
    const splitBtnWrapper = dropdownBtn?.closest('div.btn-group');

    if (this.bbDropdownToggleFullWidth) {
      this.dropdownMenuChanges = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          const dropdownMenu = mutation.target.parentNode?.querySelector('[ngbDropdownMenu]');
          if (mutation.attributeName === 'aria-expanded' && dropdownMenu) {
            this.setDropdownMenuWidth(splitBtnWrapper, dropdownBtn, dropdownMenu);
          }
        });
      });

      this.dropdownMenuChanges.observe(dropdownBtn, {
        attributeFilter: ['aria-expanded'],
        childList: false,
      });
    }
  }

  private setDropdownMenuWidth(splitBtnWrapper: HTMLElement, dropdownBtn: HTMLElement, dropdownMenu: Element) {
    const minWidth = splitBtnWrapper ? splitBtnWrapper.clientWidth : dropdownBtn.clientWidth;
    this.renderer.setStyle(dropdownMenu, 'min-width', `${minWidth}px`);
  }

  ngOnDestroy(): void {
    this.dropdownMenuChanges?.disconnect();
  }
}
