<div class="bb-block bb-block--lg">
  <h2>Success</h2>
  <bb-action-status-ui [state]="successExample"></bb-action-status-ui>
</div>
<div class="bb-block bb-block--lg">
  <h2>Error</h2>
  <bb-action-status-ui [state]="errorExample"></bb-action-status-ui>
</div>
<div class="bb-block bb-block--lg">
  <h2>Warning</h2>
  <bb-action-status-ui [state]="warningExample"></bb-action-status-ui>
</div>
<div class="bb-block bb-block--lg">
  <h2>Info</h2>
  <bb-action-status-ui [state]="infoExample"></bb-action-status-ui>
</div>
