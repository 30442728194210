<div class="bb-page-header" [class.bb-page-header--with-background]="showBackground">
  <div class="bb-page-header__hero">
    <div class="bb-page-header__hero-navigation-link">
      @if (navigationLinkText) {
        <button bbButton role="link" color="link-text" (click)="navigationLinkClick.emit()">
          <bb-icon-ui name="arrow-back" size="md" flipOnRtl="true" cropped></bb-icon-ui>
          <span>{{ navigationLinkText }}</span>
        </button>
      }
    </div>

    <div class="bb-page-header__hero-content">
      <div>
        <h1 class="bb-page-header__hero-content-title">{{ title }}</h1>

        @if (subtitle) {
          <small class="bb-page-header__hero-content-subtitle">{{ subtitle }}</small>
        }
      </div>

      @if (primaryAction || secondaryAction) {
        <div class="bb-button-bar">
          @if (primaryAction) {
            <button
              bbButton
              class="bb-button-bar__button"
              color="primary"
              buttonSize="md"
              (click)="primaryActionClick.emit()"
            >
              <bb-icon-ui [name]="primaryAction.icon ?? 'add'" size="md" cropped></bb-icon-ui>
              {{ primaryAction.text }}
            </button>
          }

          @if (secondaryAction) {
            <button
              bbButton
              class="bb-button-bar__button"
              color="secondary"
              buttonSize="md"
              (click)="secondaryActionClick.emit()"
            >
              <bb-icon-ui [name]="secondaryAction.icon ?? 'add'" size="md" cropped></bb-icon-ui>
              {{ secondaryAction.text }}
            </button>
          }
        </div>
      } @else if (pageHeaderCustomAction) {
        <ng-container *ngTemplateOutlet="pageHeaderCustomAction"></ng-container>
      }
    </div>
  </div>

  <ng-content></ng-content>
</div>
