<div
  class="bb-attachment-ui bb-card bb-card--sm"
  [class.bb-attachment-ui--block]="block"
  [attr.role]="disabled || deletable ? null : 'button'"
  [tabindex]="disabled ? -1 : 0"
  data-role="download-attachment"
  [attr.aria-describedby]="ariaDescribedby"
  (bbKeyboardClick)="!disabled && onDownload($event)"
>
  <div class="bb-stack bb-stack--align-top bb-card__body" [attr.id]="cardId">
    <div class="bb-stack__item">
      <em
        data-role="file-type-icon"
        [ngClass]="'bb-icon-file-' + fileType"
        class="bb-icon bb-icon--md bb-message-attachment__content"
        [class.bb-message-attachment__content--disabled]="loading"
      ></em>
    </div>
    <div class="bb-stack__item text-truncate" [class.bb-message-attachment__content--disabled]="loading">
      <span class="bb-block bb-block--xs" data-role="attachment-name">{{ name }}</span>
      @if (sizeInUnits) {
        <div>
          <span class="bb-text-support" data-role="attachment-size"
            >{{ sizeInUnits.value }}
            <span i18n="File size|Attachment file size in units@@fileAttachment.fileSize">{sizeInUnits.unit, select,
              Bytes {Bytes}
              KB {KB}
              MB {MB}
              GB {GB}
              TB {TB}
            }</span
          ></span>
        </div>
      }
    </div>
    @if (loading || deletable) {
      <div class="bb-stack__item bb-stack__item--push-right">
        @if (deletable && !loading) {
          <button
            bbButton
            color="link-text"
            class="bb-text-support"
            [attr.aria-label]="ariaLabel"
            data-role="file-attachment-delete-button"
            (click)="onDelete($event)"
          >
            <bb-icon-ui name="times"></bb-icon-ui>
          </button>
        }
        @if (loading) {
          <bb-loading-indicator-ui
            class="bb-message-attachment__loader"
            loaderSize="sm"
            aria-label="Loading"
            i18n-aria-label="Loading|File attachment loading indicator@@fileAttachment.loadingIndicator.ariaLabel"
          >
          </bb-loading-indicator-ui>
        }
      </div>
    }
  </div>
</div>
