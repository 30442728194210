import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputInlineEditShowcaseComponent } from './input-inline-edit-showcase.component';
import { InputInlineEditModule } from '@backbase/ui-ang/input-inline-edit';

@NgModule({
  imports: [CommonModule, InputInlineEditModule],
  declarations: [InputInlineEditShowcaseComponent],
})
export class InputInlineEditShowcaseModule {}
