import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarComponent } from './avatar.component';
import { AvatarInitialsPipe } from './avatar-initials.pipe';

@NgModule({
  imports: [NgClass],
  declarations: [AvatarComponent, AvatarInitialsPipe],
  exports: [AvatarComponent],
})
export class AvatarModule {}
