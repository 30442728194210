import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bb-progressbar-page-ui',
  templateUrl: './bb-progressbar-page-ui.component.html',
})
export class BbProgressbarPageUiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
