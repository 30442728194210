import { Component, Input, ElementRef, Renderer2, AfterViewInit, ViewChild, HostBinding } from '@angular/core';

import { ButtonColor } from './button.directive';

export type ButtonType = 'button' | 'submit' | 'reset';

let _nextId = 0;

export function getButtonNextId() {
  return `bb_button_${_nextId++}`;
}

/**
 * @name ButtonComponent
 *
 * @description
 * Component that displays a button.
 */
@Component({
  selector: 'bb-button-ui',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements AfterViewInit {
  private _id = getButtonNextId();

  /** The id for the button. Defaults to unique string */
  @Input() set id(value: string) {
    if (value !== '') {
      this._id = value;
    }
  }

  get id(): string {
    return this._id;
  }

  /**
   * This Boolean attribute lets you specify that the button should have input focus when the page
   * loads, unless the user overrides it, for example by typing in a different control.  Only one
   * form-associated element in a document can have this attribute specified.
   */
  @Input() autofocus = false;
  /**
   * The HTML5 button type.  Defaults to 'button'.
   */
  @Input() type: ButtonType = 'button';
  /**
   * This Boolean attribute indicates that the user cannot interact with the button.  Defaults to
   * false.
   */
  @Input() disabled = false;
  /**
   * Size of the button.
   */
  @Input() buttonSize: 'sm' | 'md' = 'md';
  /**
   * The color for the button.  Defaults to 'primary'.
   */
  @Input() color: ButtonColor | string = 'primary';
  /**
   * The flag to indicate whether the button should fill the container that it is in. Defaults to 'false'.
   */
  @HostBinding('class.btn-block')
  @Input()
  block = false;

  @ViewChild('button', { static: true }) buttonEl: ElementRef | undefined;

  constructor(private readonly elRef: ElementRef, private readonly renderer2: Renderer2) {}

  protected moveAriaAttributes(hostEl: HTMLElement, childEl: HTMLElement) {
    const nodeMap: NamedNodeMap = hostEl.attributes;
    // CUT every aria attribute from the host element and PASTE it into the button element
    Object.keys(nodeMap).forEach((key: string) => {
      const item: Attr | null = nodeMap.item(Number(key));
      let attrName;
      let attrVal;
      if (item) {
        attrName = item.name;
        attrVal = item.value;
        if (attrName.includes('aria')) {
          this.renderer2.setAttribute(childEl, attrName, attrVal);
          this.renderer2.removeAttribute(hostEl, attrName);
        }
      }
    });
  }

  ngAfterViewInit() {
    this.moveAriaAttributes(this.elRef.nativeElement, this.buttonEl && this.buttonEl.nativeElement);
  }
}
