<div class="col-6">
  <section class="bb-block bb-block--xl">
    <h4>Small</h4>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui
        type="primary"
        height="4px"
        [showValue]="false"
        [value]="0"
        ariaLabel="custom progress tracker"
      >
      </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" height="4px" [showValue]="false" [value]="value"> </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" height="4px" [showValue]="false" [value]="fulfilledValue"> </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" height="4px" [striped]="true" [showValue]="false" [value]="value">
      </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" height="4px" [striped]="true" [showValue]="false" [value]="fulfilledValue">
      </bb-progressbar-ui>
    </div>
  </section>
  <section class="bb-block bb-block--xl">
    <h4>Medium</h4>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" height="8px" [showValue]="false" [value]="0"> </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" height="8px" [showValue]="false" [value]="value"> </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" height="8px" [showValue]="false" [value]="fulfilledValue"> </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" height="8px" [striped]="true" [showValue]="false" [value]="value">
      </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" height="8px" [striped]="true" [showValue]="false" [value]="fulfilledValue">
      </bb-progressbar-ui>
    </div>
  </section>
  <section class="bb-block bb-block--xl">
    <h4>Large</h4>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" [showValue]="false" [value]="0"> </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" [showValue]="false" [value]="value"> </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" [showValue]="false" [value]="fulfilledValue"> </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" [showValue]="false" [value]="value" [striped]="true"> </bb-progressbar-ui>
    </div>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" [striped]="true" [showValue]="false" [value]="fulfilledValue">
      </bb-progressbar-ui>
    </div>
  </section>
  <section class="bb-block bb-block--xl">
    <h4>Types</h4>
    <h5>Primary</h5>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="primary" [striped]="true" [showValue]="false" [value]="value"> </bb-progressbar-ui>
    </div>
    <h5>Success</h5>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="success" [striped]="true" [showValue]="false" [value]="value"> </bb-progressbar-ui>
    </div>
    <h5>Info</h5>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="info" [striped]="true" [showValue]="false" [value]="value"> </bb-progressbar-ui>
    </div>
    <h5>Warning</h5>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="warning" [striped]="true" [showValue]="false" [value]="value"> </bb-progressbar-ui>
    </div>
    <h5>Danger</h5>
    <div class="bb-block bb-block--lg">
      <bb-progressbar-ui type="danger" [striped]="true" [showValue]="false" [value]="value"> </bb-progressbar-ui>
    </div>
  </section>
</div>
